import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from "@material-ui/core";
import React from "react";


export const BasicDialog = ({ title, open, onClose, children, maxWidth, actions }) => {

    const handleClose = () => {
        onClose();
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={maxWidth}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
                     {typeof children === "function" ? children() : children} }
                    
                </DialogContentText> */}
                {children}
            </DialogContent>
            <DialogActions>
                {actions && actions()}
                <Button onClick={() => {
                    onClose();
                }} color="primary">
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog >
    )

}