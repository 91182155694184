export const INV_LOAD_BEGIN = "@@inv/INV_LOAD_BEGIN";
export const INV_LOAD_SUCCESS = '@@inv/LOAD_SUCCESS';
export const INV_LOAD_ERROR = '@@inv/INV_LOAD_ERROR';
export const OPEN_STOCK_IN_DIALOG = '@@inv/OPEN_STOCK_IN_DIALOG';
export const CLOSE_STOCK_IN_DIALOG = '@@inv/CLOSE_STOCK_IN_DIALOG';
export const FILTER_INV_SUCCESS = '@@inv/FILTER_INV_SUCCESS';
export const UPDATE_SEARCH_KEY = '@@inv/UPDATE_SEARCH_KEY';
export const BRAND_FILTER_UPDATED = '@@product/BRAND_FILTER_UPDATED';
export const SUPPLIER_FILTER_UPDATED = '@@product/SUPPLIER_FILTER_UPDATED';
export const TO_FILTER_INV = '@@inv/TO_FILTER_INV';
// export const FILTER_FAIL = '@@inv/FILTER_FAIL';
export const PROVISION_FILTER_UPDATED = '@@inv/PROVISION_FILTER_UPDATED';
export const CONDITION_FILTER_UPDATED = '@@inv/CONDITION_FILTER_UPDATED';
export const HOLDER_FILTER_UPDATED = '@@inv/HOLDER_FILTER_UPDATED';
export const COUNTRY_FILTER_UPDATED = '@@inv/COUNTRY_FILTER_UPDATED';
export const OPEN_INVENTORY_NOTICE = '@@inv/OPEN_INVENTORY_NOTICE';
export const CLOSE_INVENTORY_NOTICE = "@@inv/CLOSE_INVENTORY_NOTICE";
export const INV_RELOAD_BEGIN = "@@inv/INV_RELOAD_BEGIN";
export const CANCEL_INV_LOAD = "@@inv/CANCEL_INV_LOAD";

export const loadInvSuccess = payload => ({ type: INV_LOAD_SUCCESS, payload });
export const loadInvFail = err => ({ type: INV_LOAD_ERROR, err });

export const BEGIN_CHANGE_LABEL = "@@inv/BEGIN_CHANGE_LABEL";
export const CLOSE_CHANGE_LABEL = "@@inv/CLOSE_CHANGE_LABEL";
export const SEARCH_BY_SERIAL = "@@inv/SEARCH_BY_SERIAL";
export const UPDATE_SEARCHED_STOCK = "@@inv/UPDATE_SEARCHED_STOCK";
export const SEARCH_STOCK_FAIL = "@@inv/SEARCH_STOCK_FAIL";
export const ENTER_NEW_LABEL = "@@inv/ENTER_NEW_LABEL";
export const SEARCH_LABEL_FAIL = "@@inv/SEARCH_LABEL_FAIL";
export const SEARCH_LABEL_SUCCESS = "@@inv/SEARCH_LABEL_SUCCESS";
export const SUBMIT_CHANGE_LABEL = "@@inv/SUBMIT_CHANGE_LABEL";
export const SUBMIT_CHANGE_LABEL_ERROR = "@@inv/SUBMIT_CHANGE_LABEL_ERROR";
export const SUBMIT_CHANGE_LABEL_SUCCESS = "@@inv/SUBMIT_CHANGE_LABEL_SUCCESS";

export const OPEN_IMAGE_DIALOG = "@@inv/OPEN_IMAGE_DIALOG";
export const CLOSE_IMAGE_DIALOG = "@@inv/CLOSE_IMAGE_DIALOG";
export const OPEN_STOCK_IMAGES = "@@inv/OPEN_STOCK_IMAGES";
export const OPEN_PROD_IMAGES = "@@inv/OPEN_PROD_IMAGES";
export const SET_DATA_URIS = "@@inv/SET_DATA_URIS";

export const LOAD_CHANGE_LABEL_IMG = "@@inv/LOAD_CHANGE_LABEL_IMG";
