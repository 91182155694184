import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { AuthStore } from '@cosmos/chaos';
import { posReducer } from "./pos";
import { purchaseReducer } from './purchase';
import { systemReducer } from './system';
import { traderReducer } from './trader';
import { catalogReducer } from './catalog';
import { priceReducer } from './price';
import { inventoryReducer } from './inventory'
import { printingReducer } from './printing';
import { gearReducer } from "./gear";
import { tenderReducer } from './tender';
import { reservationReducer } from './reservation';
import { stockcheckReducer } from './stockcheck';
import { deliveryReducer } from './delivery';
import { dutyFreeReducer } from './dutyFree';
import { marketingReducer } from "./marketing";
import { bulkImportReducer } from './bulkImport'
import { matchStockInReducer } from './matchStockIn'
import { thirdPartyComingReducer } from './thirdPartyComing'
import { priceRuleReducer } from './priceRule';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth: AuthStore,
  pos: posReducer,
  purchase: purchaseReducer,
  system: systemReducer,
  trader: traderReducer,
  catalog: catalogReducer,
  price: priceReducer,
  inventory: inventoryReducer,
  printing: printingReducer,
  gear: gearReducer,
  tender: tenderReducer,
  reservation: reservationReducer,
  stockcheck: stockcheckReducer,
  delivery: deliveryReducer,
  dutyfree: dutyFreeReducer,
  marketing: marketingReducer,
  bulkImport: bulkImportReducer,
  matchStockIn: matchStockInReducer,
  thirdPartyComing: thirdPartyComingReducer,
  priceRule: priceRuleReducer
});