export const GEAR_SELECT_ITEM="@@gear/GEAR_SELECT_ITEM"; 
export const OPEN_GEAR_NOTICE = "@@gear/OPEN_GEAR_NOTICE"; 
export const CLOSE_GEAR_NOTICE = "@@gear/CLOSE_GEAR_NOTICE";  

export const FIND_GEAR_UP_STK = "@@gear/FIND_GEAR_UP_STK"; 
export const GEAR_UP_ITEM_SUCCESS="@@gear/GEAR_UP_ITEM_SUCCESS";
export const TOGGLE_GEARUP_DIALOG = "@@gear/TOGGLE_GEARUP_DIALOG";  
export const GEAR_UP_REMOVE_ITEM_BEGIN = "@@gear/GEAR_UP_REMOVE_ITEM_BEGIN"; 
export const GEAR_UP_REMOVE_ITEM_SUCCESS = "@@gear/GEAR_UP_REMOVE_ITEM_SUCCESS";
export const GEAR_UP_REMOVE_ITEM_ERROR = "@@gear/GEAR_UP_REMOVE_ITEM_ERROR";
export const SAVE_GEAR_UP = "@@gear/SAVE_GEAR_UP"; 
export const RESET_GEAR_UP_FORM = "@@gear/RESET_GEAR_UP_FORM"; 

export const FIND_GEAR_DOWN_STK = "@@gear/FIND_GEAR_DOWN_STK"; 
export const GEAR_DOWN_ITEM_SUCCESS="@@gear/GEAR_DOWN_ITEM_SUCCESS";
export const TOGGLE_GEARDOWN_DIALOG = "@@gear/TOGGLE_GEARDOWN_DIALOG";  
export const GEAR_DOWN_REMOVE_ITEM_BEGIN = "@@gear/GEAR_DOWN_REMOVE_ITEM_BEGIN"; 
export const GEAR_DOWN_REMOVE_ITEM_SUCCESS = "@@gear/GEAR_DOWN_REMOVE_ITEM_SUCCESS";
export const GEAR_DOWN_REMOVE_ITEM_ERROR = "@@gear/GEAR_DOWN_REMOVE_ITEM_ERROR";
export const SAVE_GEAR_DOWN = "@@gear/SAVE_GEAR_DOWN"; 
export const RESET_GEAR_DOWN_FORM = "@@gear/RESET_GEAR_DOWN_FORM"; 

export const UPDATE_GEAR_LABEL = "@@gear/UPDATE_GEAR_LABEL";
