export const OPEN_STOCKCHECK_NOTICE = "@@stockcheck/OPEN_STOCKCHECK_NOTICE";
export const CLOSE_STOCKCHECK_NOTICE = "@@stockcheck/CLOSE_STOCKCHECK_NOTICE";

export const SCAN_STOCK_CHECK = "@@stockcheck/SCAN_STOCK_CHECK";
export const UPDATE_SEARCH_LABEL = "@@stockcheck/UPDATE_SEARCH_LABEL";
export const CLEAR_STOCK_LABEL = "@@stockcheck/CLEAR_STOCK_LABEL";
export const SEARCH_STOCK_BY_LABEL = "@@stockcheck/SEARCH_STOCK_BY_LABEL";

export const ADD_SCANNED_STOCK = "@@stockcheck/ADD_SCANNED_STOCK";
export const REMOVE_EXPECTED_STOCK = "@@stockcheck/REMOVE_EXPECTED_STOCK";

export const UPDATE_SELECTED_DATE = "@@stockcheck/UPDATE_SELECTED_DATE";
export const UPDATE_HISTORY = "@@stockcheck/UPDATE_HISTORY";


