import axios from 'axios'
import { useState, useCallback, useEffect } from 'react'
import useHeaders from './useHeaders'

/**
 * @param {string} query The graphql query to execute
 * @param {any} initialData Initial data before the query runs
 * @param {object} variables The variables to use for the graphql query
 * @param {boolean} immediate Whether the query runs on mount or not
 * 
 * @return {} result
 * @return {(variables) => void} result.execute - function to run query again 
 * @return {boolean} result.isLoading - Whether data is currently being fetched
 * @return {any} result.data
 * @return {string | null} result.error
 * 
 * @example const { execute, isLoading, data, error } = useQuery(`query ($input: Input){ updatePO(input: $input) {id}}`, 
 * {updatePO: null}, 
 * {}, 
 * false)
 * const submit  = () => execute({inputs: {...}}) 
 */
export default (query, initialData = null, variables = {}, immediate = true) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(initialData);
    const [error, setError] = useState(null);
    const [_variables] = useState(variables)
    const headers = useHeaders()

    const execute = useCallback((variables) => {
        setIsLoading(true)
        setError(null)
        return axios.post(`${process.env.REACT_APP_API_URL}api`, { query, variables }, { headers })
            .then(response => {
                if (response.data.errors && response.data.errors.length > 0) {
                    return setError(response.data.errors[0].message)
                }

                setData(response.data.data)
                return response.data.data
            })
            .catch(error => {
                if (error.response && error.response.data) return setError(error.response.data.errors[0].message)
                setError(error)
            })
            .finally(() => setIsLoading(false))
    }, [headers, query]);

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute(_variables);
        }
    }, [execute, immediate, _variables]);

    return { execute, isLoading, data, error };
};
