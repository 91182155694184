import {
    THIRD_PARTY_CREATE_PO_BEGIN,
    THIRD_PARTY_CREATE_PO_SUCCESS,
    THIRD_PARTY_CREATE_PO_FAIL,
    THIRD_PARTY_UPCOMING_RESET
} from "../actions/marketing"

const initialState = {
    isLoading: false,
    createdPO: null,
    error: null
}

export const thirdPartyComingReducer = (state = initialState, action) => {
    switch (action.type) {
        case THIRD_PARTY_CREATE_PO_BEGIN:
            return {
                ...state,
                isLoading: true,
                error: null,
                createdPO: null
            }
        case THIRD_PARTY_CREATE_PO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                createdPO: action.payload
            }
        case THIRD_PARTY_CREATE_PO_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case THIRD_PARTY_UPCOMING_RESET:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}