import moment from "moment";

export class FilterUnit {
  static andOperator(data, filterArgs) {
    let result = data;
    for (let f in filterArgs) {
      let { key, exactMatch, search } = filterArgs[f];

      search = search.map(item => String(item).toUpperCase().replace(/[.-/]/g, ''));
      if (exactMatch) {
        result = result.filter(r => {
          return search.indexOf(String(r[key]).toUpperCase().replace(/[.-/]/g, '')) > -1;
        });
      }
      else {
        result = result.filter(r => {
          let matched = false;
          for (let s in search) {
            matched = String(r[key]).toUpperCase().replace(/[.-/]/g, '').includes(search[s]);
            if (matched) { break; }
          }
          return matched;
        })
      }
    }
    return result;
  }

  static orOperator(data, filterArgs) {
    let result = data;
    result = result.filter(r => {
      let matched = false;
      for (let f in filterArgs) {
        let { key, exactMatch, search } = filterArgs[f];
        search = search.map(item => String(item).toUpperCase().replace(/[.-/]/g, ''));
        if (exactMatch) {
          matched = search.indexOf(String(r[key]).toUpperCase().replace(/[.-/]/g, '')) > -1;
        }
        else {
          for (let s in search) {
            matched = String(r[key]).toUpperCase().replace(/[.-/]/g, '').includes(search[s]);
            if (matched) { break; }
          }
        }
        if (matched) { break; }
      }
      return matched;
    });
    return result;
  }

  static binaryAndOperator(data, filterArgs) {
    let result = data;
    for (let f in filterArgs) {
      let { key, exactMatch, search } = filterArgs[f];
      if (exactMatch) {
        //new anything but 16 or 32
        //second hand = 16
        //consignment  = 32
        let temp = []
        let newValues = result.filter(r => !((r.provision & 16) || (r.provision & 32)))
        let secondHand = result.filter(r => r.provision & 16)
        let consign = result.filter(r => r.provision & 32)
        for (let s in search) {
          if (search[s] === "16") {
            temp = temp.concat(secondHand)
          } else if (search[s] === "32") {
            temp = temp.concat(consign)
          } else if (search[s] !== "32" || search[s] !== "16") {
            temp = temp.concat(newValues)
          }
        }
        result = temp
      }
      else {
        result = result.filter(r => {
          let matched = false;
          for (let s in search) {
            matched = String(r[key]).toUpperCase().replace(/[.-/]/g, '').includes(search[s]);
            if (matched) { break; }
          }
          return matched
        })
      }
    }
    return result;
  }

  static dateAndOperator(data, filterArgs) {
    let result = data;
    for (let f in filterArgs) {
      let { key, search } = filterArgs[f];

      if (moment(search.start).isValid()) {
        result = result.filter(r => {
          return moment.utc(search.start).isSameOrBefore(moment.utc(r[key]).format('YYYY-MM-DD'));
        });
      }

      if (moment(search.end).isValid()) {
        result = result.filter(r => {
          return moment.utc(search.end).isSameOrAfter(moment.utc(r[key]).format('YYYY-MM-DD'));
        });
      }
    }
    return result;
  }
}