import React, {
    useEffect,
    useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableToolbar } from "@cosmos/chaos";
import { DataTable } from "@cosmos/chaos/cjs/DataTable";
import Paper from "@material-ui/core/Paper";
import AutoSizer from "react-virtualized-auto-sizer";
import { isEmpty } from "lodash";
import {
    LinearProgress,
    makeStyles,
    Input,
    alpha,
    lighten,
} from "@material-ui/core";
import { PriceTagEditor } from "./PriceTagEditor";
import {
    LOAD_SUPPLIES,
    UPDATE_SUPPLIES_SEARCH,
    IMPORT_SUMMARY,
    RESET_SUPPLIES_PROVISONS_FILTER,
    UPDATE_MARKETING_SECTION,
    SELECT_SUPPLY_ROW,
    SELECT_SUPPLIES,
    SELECT_SUPPLY_CLEAR,
    SELECT_SUPPLY_REFRESH,
    SELECT_SUPPLY_REMOVE, SELECT_SUPPLY_ALL_ROWS, DESELECT_SUPPLY_ALL_ROWS,
} from "../../actions";
import NumberFormat from "react-number-format";
import { INIT_TRADER_LOAD } from "../../actions";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import classNames from "classnames";
import { push } from "connected-react-router";
import { CalendarRefresh, CloseCircle, Plus } from "mdi-material-ui";
import Checkbox from "@material-ui/core/Checkbox";
import { LOAD_SUPPLIES_PROVISIONS } from "../../actions";
import Fab from "@material-ui/core/Fab";
import { isAllSelected } from "@cosmos/chaos/esm";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        padding: "0.5em",
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flex: 1,
        display: "flex",
    },
    dataTable: {
        flex: 1,
    },
    aside: {
        width: 0,
        "&.open": {
            width: "420px",
        },
    },
    header: {
        position: "relative",
    },
    toolbar: {
        paddingLeft: "2em",
    },
    toolbar2: {
        "& .message": {
            padding: "0.5em 0",
        },
    },
    opClear: {
        marginRight: "0.5em",
    },
    opRefresh: {
        backgroundColor: theme.palette.success.dark,
        marginRight: "0.5em",
        "&:hover": {
            backgroundColor: theme.palette.success.light,
        },
    },
    opRemove: {
        backgroundColor: theme.palette.secondary.dark,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    currentRow: {
        backgroundColor: lighten(theme.palette.warning.light, 0.5),
        "&:hover": {
            backgroundColor: lighten(theme.palette.warning.light, 0.3),
        },
    },
    sectorRoot: {
        backgroundColor: alpha(theme.palette.primary.light, 0.25),
        "&$sectorSelected": {
            backgroundColor: theme.palette.success.light,
        },
    },
    sectorSelected: {},
    progress: {},
    searchBox: {
        padding: "4px 4px 4px 60px",
    },
    filterBox: {
        marginRight: "1em",
    },
    filterItem: {
        margin: "0 1em",
    },
    controlCell: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    productCell: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
    },
    productBox: {
        display: "flex",
        flexDirection: "column",
    },
    productSKU: {
        fontWeight: "500",
    },
    productRef: {
        fontSize: "70%",
    },
    colorBox: {
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    even: { backgroundColor: theme.palette.grey["50"] },
    odd: {},
    button: {
        margin: "0.5em",
    },
    icon: {
        margin: "10px",
        fontSize: "3em",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgb(100, 181, 246, .3)",
            borderRadius: "50%",
        },
    },
    checkBox: {
        height: 10,
    },
    actionButtons: {
        height: 8,
    },
}));

const CheckBoxHeader = ({ className, data }) => {
    const dispatch = useDispatch();
    const selected = useSelector((state) => state.marketing.selected);
    const [index, setIndex] = useState([]);
    useEffect(() => {
        if (data) {
            setIndex(data.map(d => d.id))
        }
    }, [data, setIndex])
    const allSelected = isAllSelected(index, selected);
    const handleChange = (e) => {
        if (allSelected) {
            dispatch({ type: DESELECT_SUPPLY_ALL_ROWS, payload: index })
        } else {
            dispatch({ type: SELECT_SUPPLY_ALL_ROWS, payload: index })
        }
    }
    return <div className={className}>
        <Checkbox color="primary" checked={selected.length > 0}
            onChange={handleChange}
            indeterminate={selected.length > 0 && !allSelected}
        />
    </div>
}

export const SuppliesComponent = ({ props }) => {
    const styles = useStyles();
    const [columns, setColumns] = useState([]);
    const dispatch = useDispatch();
    const section = useSelector((state) => state.marketing.section);
    const indexes = useSelector(state => state.marketing.index);
    const supplies = useSelector(state => state.marketing.supplies);
    const selected = useSelector((state) => state.marketing.selected);
    const searchText = useSelector((state) => state.catalog.searchText);
    const loading = useSelector((state) => state.catalog.loading || state.marketing.loading);
    const currencies = useSelector((state) => state.system.currencies);
    const products = useSelector((state) => state.catalog.products);
    const brands = useSelector((state) => state.catalog.brands);
    const sectors = useSelector((state) => state.marketing.sectors);
    const targetId = useSelector((state) => state.marketing.target.id);
    const importInput = useSelector((state) => state.marketing.import);
    const traders = useSelector((state) => state.trader.traders);
    const suppliesFilter = useSelector((state) => state.marketing.suppliesFilter);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!isEmpty(supplies) && indexes.length > 0) {
            const temp = indexes.map((idx) => supplies[idx]);
            setData(temp);
        }
    }, [indexes, supplies]);
    useEffect(() => {
        dispatch({
            type: LOAD_SUPPLIES,
            payload: { section, ...suppliesFilter },
        });
        dispatch({ type: IMPORT_SUMMARY });
        dispatch({ type: INIT_TRADER_LOAD });
        dispatch({ type: RESET_SUPPLIES_PROVISONS_FILTER });
    }, [section, dispatch, suppliesFilter]);

    useEffect(() => {
        if (importInput.traderId && importInput.importDate) {
            dispatch({ type: LOAD_SUPPLIES_PROVISIONS, payload: importInput });
        }
    }, [dispatch, importInput]);

    useEffect(() => {
        const columns = [
            {
                name: "control",
                title: " ",
                width: 64,
                columnClass: styles.controlCell,
                header: ({ className, data }) => {
                    return <CheckBoxHeader className={className} data={data} />
                },
                cell: ({ className, rowData }) => {
                    return (
                        <div className={className}>
                            <Checkbox
                                disableRipple
                                color="primary"
                                checked={selected.indexOf(rowData.id) > -1}
                                onChange={(e) => {
                                    dispatch({
                                        type: SELECT_SUPPLY_ROW,
                                        payload: rowData.id,
                                    });
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                name: "brandId",
                title: "Brand",
                width: 96,
                options: brands,
                sortBy: "brand",
                filter: "brandId",
            },
            {
                name: "productId",
                title: "Product",
                columnClass: styles.productCell,
                cell: ({ className, rowData }) => {
                    const p = products[rowData.productId];
                    if (!p) return <Skeleton variant="rect" />;
                    return (
                        <div
                            className={classNames(className, styles.productBox)}
                        >
                            <div className={styles.productSKU}>{p.SKU}</div>
                            <div className={styles.productRef}>
                                {p.reference}
                            </div>
                        </div>
                    );
                },
                options: products,
                optionKey: "reference",
                sortBy: "reference",
                width: 160,
            },
            {
                name: "productId",
                key: "color",
                title: "Color",
                options: products,
                optionKey: "dialColor",
                sortBy: "dialColor",
                width: 72,
            },
            {
                name: "traderId",
                title: "Supplier",
                filter: "traderId",
                options: traders,
                sortBy: "traderName",
                width: 160,
            },
            {
                name: "msrp",
                title: "MSRP",
                cell: ({ className, rowData }) => {
                    const c = currencies[rowData.currencyId];
                    const s = c ? c.symbol : "";
                    const msrp = rowData["msrp"];
                    const display =
                        msrp > 0 ? (
                            <NumberFormat
                                value={msrp}
                                prefix={`${s} `}
                                displayType="text"
                                thousandSeparator
                            />
                        ) : (
                                <span>&nbsp;</span>
                            );
                    return <div className={className}>{display}</div>;
                },
                sortBy: "msrp",
            },
            {
                name: "discount",
                title: "Dis",
                width: 50,
                cell: ({ className, rowData }) => {
                    const dis = rowData["discount"];
                    const discountComp =
                        dis > 0 ? (
                            <NumberFormat
                                value={dis}
                                suffix={"%"}
                                displayType="text"
                                thousandSeparator
                            />
                        ) : (
                                <span>&nbsp;</span>
                            );
                    return <div className={className}>{discountComp}</div>;
                },
                sortBy: "discount",
            },
            {
                name: "price",
                title: "Price",
                cell: ({ className, rowData }) => {
                    const c = currencies[rowData.currencyId];
                    const s = c ? c.symbol : "";
                    return (
                        <div className={className}>
                            <NumberFormat
                                value={rowData["price"]}
                                prefix={`${s} `}
                                displayType="text"
                                thousandSeparator
                            />
                        </div>
                    );
                },
                sortBy: "price",
            },
            {
                name: "tax",
                price: "Tax",
                cell: ({ className, rowData }) => {
                    const c = currencies[rowData.currencyId];
                    const s = c ? c.symbol : "";
                    return (
                        <div className={className}>
                            <NumberFormat
                                value={rowData["tax"]}
                                prefix={`${s} `}
                                displayType="text"
                                thousandSeparator
                            />
                        </div>
                    );
                },
                sortBy: "tax",
            },
            {
                name: "amount",
                title: "Amount",
                cell: ({ className, rowData }) => {
                    const c = currencies[rowData.currencyId];
                    const s = c ? c.symbol : "";
                    return (
                        <div className={className}>
                            <NumberFormat
                                value={rowData["amount"]}
                                prefix={`${s} `}
                                displayType="text"
                                thousandSeparator
                            />
                        </div>
                    );
                },
                sortBy: "amount",
            },
        ];
        if (section === 20401) {
            columns.push({
                name: "validTo",
                title: "Expire",
                width: 96,
                cell: ({ className, rowData }) => {
                    const expire = moment(rowData["validTo"]).fromNow();
                    return <div className={className}>{expire}</div>;
                },
                sortBy: "validTo",
            });
        }
        columns.push({
            name: "lastSeenAt",
            title: 'Verified At',
            width: 96,
            cell: ({ className, rowData }) => {
                return <div className={className}>
                    {moment(rowData['lastSeenAt']).format("YYYY-MM-DD")}
                </div>
            },
            sortBy: 'lastSeenAt'
        })
        setColumns(columns);
    }, [setColumns, products, brands, selected, section]);

    const handleSelectRow = ({ rowData }) => {
        dispatch({ type: SELECT_SUPPLIES, payload: rowData });
    };

    // #region Handlers
    const handleSearch = (e) => {
        dispatch({ type: UPDATE_SUPPLIES_SEARCH, payload: e.target.value });
    };

    const handleClearSelection = (e) => {
        dispatch({ type: SELECT_SUPPLY_CLEAR });
    };

    const handleRefreshSelection = (e) => {
        dispatch({ type: SELECT_SUPPLY_REFRESH });
    };

    const handleRemoveSelection = (e) => {
        dispatch({ type: SELECT_SUPPLY_REMOVE });
    };

    return (
        <Paper
            className={classNames(
                styles.root,
                section === 20201 && styles.minRoot
            )}
        >
            <div className={styles.header}>
                <TableToolbar
                    numSelected={selected.length}
                    title="Supplies"
                    styles={{
                        root: styles.toolbar,
                        messageBox: styles.toolbar2,
                    }}
                    searchBox={() => (
                        <Input
                            placeholder="Search..."
                            disableUnderline
                            value={searchText}
                            onChange={handleSearch}
                            className={styles.searchBox}
                            fullWidth
                        />
                    )}
                    filterBox={() => (
                        <div className={styles.filterBox}>
                            {section === 20201 && (
                                <Fab
                                    className={styles.filterItem}
                                    variant="Extended"
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        dispatch(push(`supplies/upcoming`))
                                    }
                                >
                                    <Plus /> 3P Coming
                                </Fab>
                            )}
                            <ToggleButtonGroup
                                size="small"
                                value={section}
                                onChange={(event, newSection) => {
                                    dispatch({
                                        type: UPDATE_MARKETING_SECTION,
                                        payload: newSection,
                                    });
                                }}
                                exclusive
                            >
                                {sectors.map((sector) => (
                                    <ToggleButton
                                        key={`sec-${sector.value}`}
                                        value={sector.value}
                                        classes={{
                                            root: styles.sectorRoot,
                                            selected: styles.sectorSelected,
                                        }}
                                    >
                                        {sector.label}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </div>
                    )}
                    refreshTrigger={() => {
                        dispatch({ type: LOAD_SUPPLIES, payload: { section } });
                    }}
                    selectedItemHandler={() => {
                        return (
                            selected.length > 0 && (
                                <>
                                    <Fab
                                        size="small"
                                        variant="extended"
                                        className={styles.opClear}
                                        onClick={handleClearSelection}
                                    >
                                        Clear
                                    </Fab>
                                    {section === 20401 && (
                                        <Fab
                                            size="small"
                                            variant="extended"
                                            className={styles.opRefresh}
                                            onClick={handleRefreshSelection}
                                        >
                                            <CalendarRefresh />
                                            Refresh
                                        </Fab>
                                    )}
                                    <Fab
                                        size="small"
                                        variant="extended"
                                        className={styles.opRemove}
                                        onClick={handleRemoveSelection}
                                    >
                                        <CloseCircle />
                                        Remove
                                    </Fab>
                                </>
                            )
                        );
                    }}
                />
                {loading && (
                    <LinearProgress
                        className={styles.progress}
                        color="secondary"
                    />
                )}
            </div>
            <div className={classNames(styles.content)}>
                <div className={styles.dataTable}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <DataTable
                                data={data}
                                columns={columns}
                                height={height}
                                width={width}
                                rowOnClick={handleSelectRow}
                                isRowHighlight={({ rowData }) => {
                                    if (
                                        rowData && rowData.id === targetId
                                    )
                                        return styles.currentRow;
                                }}
                            />
                        )}
                    </AutoSizer>
                </div>
                <div
                    className={classNames(styles.aside, {
                        open: section === 20401,
                    })}
                >
                    <PriceTagEditor className={classNames(styles.aside)} />
                </div>
            </div>
        </Paper>
    );
};
