import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../components/inputs";
import AutoComplete from "@material-ui/lab/Autocomplete";
import {
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Button,
  Box
} from "@material-ui/core";
import { isEmpty } from "lodash";
import { BasicDialog } from "../../../components/dialog/BasicDialog";
import {
  TRADER_CREATE_NEW,
} from "../../../actions";

export default function VendorInformation({
  country,
  taxRate,
  vendor,
  currency,
  expectedArrivalDate,
  recipientCompany,
  priceIncludesTax,
  onChange,
  className,
}) {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.system.countries);
  const vendors = useSelector((state) => {
    const allTraders = state.trader.traders
      ? Object.values(state.trader.traders)
      : [];
    const filteredTraders = allTraders
      .filter((trader) => trader.countryId) // remove traders without countryId
      .filter(
        (trader) =>
          !country || trader.countryId.toString() === country.id.toString()
      );
    const result = {};
    filteredTraders.forEach((trader) => (result[trader.id] = trader));
    return result;
  });
  const vendorRef = useRef();
  const currencies = useSelector((state) => state.system.currencies);
  const companies = useSelector((state) => state.system.stores);

  const handleChange = (name) => (e) => {
    try {
      e.persist();
    } catch (e) { }
    onChange(name)(e);
    if (name === "country" && e.target.value) {
      // make sure e.target.value not null. It is null when cancelling
      // if country is being set, set default tax rate and currency
      onChange("taxRate")({ target: { value: e.target.value.vat } });
      onChange("currency")({
        target: { value: currencies[e.target.value.currencyId] },
      });
    }
  };

  return (
    <div className={className}>
      <h1>Upcoming Invoice</h1>
      <AutoComplete
        autoHighlight
        options={Object.values(countries)}
        getOptionLabel={(option) => option.name || ""}
        onChange={(event, value, reason) =>
          handleChange("country")({ target: { value } })
        }
        value={country}
        renderInput={(params) => (
          <Input {...params} separateLabel label="Country" />
        )}
      />
      <AutoComplete
        autoHighlight
        disabled={isEmpty(country)}
        options={Object.values(vendors)}
        getOptionLabel={(option) => option.name || ""}
        onBlur={(e) => {
          const name = e.target.value;
          if (
            !Object.values(vendors).find((vendor) => vendor.name.includes(name))
          ) {
            handleChange("vendor")({ target: { value: { id: null, name } } });
          }
        }}
        onChange={(event, value, reason) => {
          if (typeof value === "string")
            return handleChange("vendor")({
              target: { value: { id: null, name: value } },
            });
          handleChange("vendor")({ target: { value } });
        }}
        value={vendor}
        renderInput={(params) => (
          <Input {...params} separateLabel label="Vendor" inputRef={vendorRef} />
        )}
        noOptionsText={
          !isEmpty(country) ? <Box display="flex" justifyContent="space-between" alignItems="center">
            <span style={{ paddingRight: "5px" }}>Trader not found!</span>
            <Button
              variant="outlined"
              color="primary"
              onMouseDown={(e) => {
                e.preventDefault();
                dispatch({ type: TRADER_CREATE_NEW, payload: { name: vendorRef.current.value, countryName: country.name } })
              }} //onClick does not work
            >
              Add New Trader
        </Button>
          </Box> : "Select country first"
        }
      />
      <AutoComplete
        autoHighlight
        disabled={!country}
        options={Object.values(currencies)}
        getOptionLabel={(option) => option.code || ""}
        onChange={(event, value, reason) =>
          handleChange("currency")({ target: { value } })
        }
        value={currency}
        renderInput={(params) => (
          <Input {...params} separateLabel label="Currency" />
        )}
      />
      <Input
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        separateLabel
        value={taxRate.toString()}
        disabled={!country}
        onChange={handleChange("taxRate")}
        label="Tax Rate"
        readOnly
      />
      <Input
        separateLabel
        value={expectedArrivalDate}
        disabled={!country}
        onChange={handleChange("expectedArrivalDate")}
        label="Expected Arrival Date"
        type="date"
        readOnly
      />
      <AutoComplete
        autoHighlight
        disabled={!country}
        options={Object.values(companies).sort((companyA, companyB) =>
          companyA.name > companyB.name ? 1 : -1
        )}
        getOptionLabel={(option) => option.name || ""}
        onChange={(event, value, reason) =>
          handleChange("recipientCompany")({ target: { value } })
        }
        value={recipientCompany}
        renderInput={(params) => (
          <Input {...params} separateLabel label="Recipient Company" />
        )}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={priceIncludesTax}
            onChange={(e) =>
              handleChange("priceIncludesTax")({
                target: { value: e.target.checked },
              })
            }
            name="priceIncludesTax"
          />
        }
        label="Price Includes Tax"
      />
    </div>
  );
}
