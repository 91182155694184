import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'

export default function CustomCheckbox({checked, onChange, label}) {
    return (
        <FormControlLabel
            control={<Tooltip title={label}>
                <Checkbox checked={checked} onChange={e => onChange({ target: { value: e.target.checked } })} size="small" />
            </Tooltip>}
            label={<small>{label[0]}</small>}
        />
    )
}
