import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import styles from './numbers.module.scss';
import classNames from 'classnames';
import Typography from "@material-ui/core/Typography/Typography";
import InputBase from "@material-ui/core/InputBase/InputBase";

export class NumberBox extends Component {
    static defaultProps = { styles: {} };

    constructor(props) {
        super(props);
        this.state = {
            focus: false
        };
    }

    handleFocus = () => {
        this.setState({ focus: true });
        this.editor.focus();
    };

    handleBlur = () => {
        this.props.onBlur();
        this.setState({ focus: false })
    };

    render() {
        const { focus } = this.state;
        const {
            displayValue,
            editValue,
            displayProps = {},
            editorProps = {},
            onChange
        } = this.props;
        return (
            <div
                className={classNames(styles["number-box"], this.props.styles.container)}
                onClick={this.handleFocus}
            >
                <div className={classNames(styles.control, { focus })}>
                    <div
                        className={styles.display}
                    >
                        {
                            displayProps.prefix &&
                            (<div className={styles["front-ado"]}>
                                <Typography>
                                    {displayProps.prefix}
                                </Typography>
                            </div>)
                        }
                        <NumberFormat value={displayValue}
                            displayType="text"
                            thousandSeparator={true}
                            renderText={value => (
                                <div className={styles["display-field"]}>
                                    <Typography className={classNames(styles.align, styles.body1)}>
                                        {value}
                                    </Typography>
                                </div>
                            )}
                        />
                        {
                            displayProps.suffix &&
                            (<div className={styles["end-ado"]}>
                                <Typography>
                                    {displayProps.suffix}
                                </Typography>
                            </div>)
                        }
                    </div>
                    <div
                        className={styles.editor}
                    >
                        {
                            editorProps.prefix &&
                            (<div className={styles["front-ado"]}>
                                <Typography>
                                    {editorProps.prefix}
                                </Typography>
                            </div>)
                        }
                        <NumberFormat className={styles["editor-field"]}
                            value={editValue}
                            inputRef={(ref) => {
                                this.editor = ref
                            }}
                            onBlur={this.handleBlur}
                            thousandSeparator={true}
                            customInput={InputBase}
                            onValueChange={values => {
                                const { value } = values;
                                onChange(value);
                            }}
                        />
                        {
                            editorProps.suffix &&
                            (<div className={styles["end-ado"]}>
                                <Typography>
                                    {editorProps.suffix}
                                </Typography>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        )
    }
}