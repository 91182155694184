
import {
    addCartVibes, checkoutVibes, disablePosVibes, removeCartVibes,
    applyIdvDiscRateVibes, applyDiscRateVibes, updateItemPriceVibes,
    updateTotalVibes, applyTaxRateVibes, applyDutyFreeVibes,
    verifyDeliveryLabelVibes, scanActionCardVibes, reinitCartVibes, applyTaxModeVibes,
    checkBirthVibes, saveWarrantyPhoto, getTempImgVibes, displayReceiptVibes,
    voidItemVibes,
} from "./pos";
import {
    authInit,
    authLogin,
    authLogout,
    authRefresh
} from '@cosmos/chaos';
import {
    initStoreVibes, clearStorageVibes, handleRayLensUpdateVibes, handleExtensions, handleNotify
} from './system';
import {
    disablePOFormVibes, searchStockBySerial,
    searchLabelVibes, handleUpdateLabelVibes, fetchImagesVibes,
} from "./inventoryVibes";
import { dispatchFilterVibes, filterHandlerVibes } from "./filter";
import {
    defineStageVibes, verifyLabelVibes, verifyInfoVibes, saveImageVibes, createPOVibes,
    populateDiscRateVibes, stockInVibes, beginPurchaseEditVibes, savePurchaseVibes, saveInvoicePhoto,
    disableStockInFormVibes, removeDetailsVibes, createVendorVibes, fetchInvoiceVibes, fetchTempInvVibes,
    fetchTempImgVibes, retrievePOCheckStocksVibes
} from './purchase';
import {
    loadCatalogVibes, loadPriceVibes, loadPurchaseVibes, loadTraderVibes,
    reloadInventoryVibes, loadInventoryVibes, loadTenderVibes, loadCartVibes,
    loadReservationVibes, loadSuggestionVibes, loadUserVibes, loadDeliveryVibes,
} from "./load";
import { submitPrintVibes, loadPrintersVibes } from "./printing";
import {
    findGearStockVibes, gearUpVibes, removeGearUpVibes,
    gearDownVibes, removeGearDownVibes
} from "./gear"
import {
    disableTenderVibes, submitTenderVibes, selectTenderVibes, recordDeletedTenderVibes,
    updateTenderVibes, handleDeleteFulfilmentVibes, handleUpdateFulfilmentVibes,
} from "./tender";
import { updateProcurementVibes, changeProcurementSuppliesVibes } from "./reservation";
import { searchStockVibes, filterHistoryVibes } from "./stockcheck";
import { updateDeliveryVibes, confirmBoxOpenedVibes, displayInvoiceVibes, } from "./delivery";
import {
    disableProcessVibes, scanCartLabelVibes, scanStockLabelVibes,
    checkDfBirthVibes, saveDfWarrantyPhoto, removeItemVibes, printBlankDFVibes,
    submitDutyFreeVibes, parseMrzVibes, loadDfPhotos, submitCdpVibes,
} from "./dutyfree";
import {
    initPaymentVibes,
} from "./payment";
import { wsVibes } from "./websocket";
import { APP_INIT, APP_LOADED } from "../actions";
import {
    loadSuppliesVibes,
    saveSupplyVibes,
    searchSupplies,
    saveNewProductVibes,
    sendSuppliesVibes,
    loadSuppliesProvisionsVibes,
    loadOverviewSuppliesVibes,
    loadImportSummaryVibes,
    removeSuppliesVibes,
    renewSuppliesVibes,
    selectSupply,
    refreshSuppliesVibes,
    loadSuppliesDataFromFile,
    loadSuppliesDataFromText,
    importSuppliesTablePrepare,
    selectSupplyAll,
    deselectSupplyAll,
    createTrader

} from "./marketing";
import {
    matchStockInVibes
} from './matchStockIn'
import { createThirdPartyVibes } from './thirdPartyComing'
import {infosProductVibes} from "./info-products";
import { priceRuleVibes } from "./priceRule";

const verifier = async (token, axios) => {
    const data = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            query: `query {heartBeatCall}`
        }
    });
    return { status: (data) ? 0 : -1 };
};

export const vibes = [
    authInit(APP_INIT, verifier, APP_LOADED),
    authLogin(verifier),
    authRefresh,
    authLogout,
    wsVibes,
    handleExtensions,
    initStoreVibes,
    clearStorageVibes,
    defineStageVibes,
    verifyLabelVibes,
    verifyInfoVibes,
    saveImageVibes,
    populateDiscRateVibes,
    loadPurchaseVibes,
    createPOVibes,
    addCartVibes,
    beginPurchaseEditVibes,
    loadInventoryVibes,
    dispatchFilterVibes,
    filterHandlerVibes,
    retrievePOCheckStocksVibes,
    disablePOFormVibes,
    checkoutVibes,
    disablePosVibes,
    removeCartVibes,
    loadCatalogVibes,
    stockInVibes,
    saveWarrantyPhoto,
    loadPriceVibes,
    loadTraderVibes,
    submitPrintVibes,
    savePurchaseVibes,
    loadPrintersVibes,
    applyIdvDiscRateVibes,
    applyDiscRateVibes,
    updateItemPriceVibes,
    updateTotalVibes,
    applyTaxRateVibes,
    applyDutyFreeVibes,
    verifyDeliveryLabelVibes,
    scanActionCardVibes,
    saveInvoicePhoto,
    disableStockInFormVibes,
    reinitCartVibes,
    findGearStockVibes,
    gearUpVibes,
    removeGearUpVibes,
    gearDownVibes,
    removeGearDownVibes,
    reloadInventoryVibes,
    handleRayLensUpdateVibes,
    // handleExtensionUpdateVibes,
    removeDetailsVibes,
    loadTenderVibes,
    loadReservationVibes,
    disableTenderVibes,
    submitTenderVibes,
    selectTenderVibes,
    recordDeletedTenderVibes,
    updateTenderVibes,
    handleDeleteFulfilmentVibes,
    loadSuggestionVibes,
    handleUpdateFulfilmentVibes,
    loadUserVibes,
    updateProcurementVibes,
    changeProcurementSuppliesVibes,
    createVendorVibes,
    searchStockVibes,
    filterHistoryVibes,
    loadDeliveryVibes,
    updateDeliveryVibes,
    confirmBoxOpenedVibes,
    displayInvoiceVibes,
    searchStockBySerial,
    searchLabelVibes,
    handleUpdateLabelVibes,
    disableProcessVibes,
    scanCartLabelVibes,
    scanStockLabelVibes,
    checkBirthVibes,
    checkDfBirthVibes,
    saveDfWarrantyPhoto,
    removeItemVibes,
    submitDutyFreeVibes,
    printBlankDFVibes,
    fetchImagesVibes,
    getTempImgVibes,
    fetchInvoiceVibes,
    parseMrzVibes,
    fetchTempInvVibes,
    fetchTempImgVibes,
    loadCartVibes,
    displayReceiptVibes,
    loadDfPhotos,
    submitCdpVibes,
    initPaymentVibes,
    loadSuppliesVibes,
    loadImportSummaryVibes,
    loadSuppliesProvisionsVibes,
    saveSupplyVibes,
    searchSupplies,
    voidItemVibes,
    applyTaxModeVibes,
    saveNewProductVibes,
    sendSuppliesVibes,
    loadOverviewSuppliesVibes,
    matchStockInVibes,
    createThirdPartyVibes,
    selectSupply,
    selectSupplyAll,
    deselectSupplyAll,
    refreshSuppliesVibes,
    removeSuppliesVibes,
    loadSuppliesDataFromFile,
    importSuppliesTablePrepare,
    loadSuppliesDataFromText,
    createTrader,
    handleNotify,
    ...infosProductVibes,
    ...priceRuleVibes
];