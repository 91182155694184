import { createVibes } from "@cosmos/vibes";
import { 
    LOAD_PRICE_RULES_BEGIN,
    LOAD_PRICE_RULES_SUCCESS,
    SUBMIT_PRICE_RULE_BEGIN,
    SUBMIT_PRICE_RULE_SUCCESS,
    SUBMIT_PRICE_RULE_FAIL,
    CLEAR_PRICE_RULE_VALUE,
    UPDATE_PRICE_RULE_BOX_OPEN_STATUS,
    SEARCH_PRODUCT_PRICE_TAG,
    SEARCH_PRODUCT_PRICE_TAG_SUCCESS,
    SEARCH_PRODUCT_PRICE_TAG_FAIL,
    CLEAR_SEARCH_PRODUCT_PRICE_TAG
} from "../actions";
import _ from "lodash";

const loadPriceRules = createVibes({
    type: LOAD_PRICE_RULES_BEGIN,
    processOptions: {
        successType: LOAD_PRICE_RULES_SUCCESS,
    },
    async process({ getState, action, axios }) {
        try {			
			const { data } = await axios.request({
                url: '/aprs', // TODO(PRICERULE): need to use this url 
                // url: 'http://127.0.0.1:9100/v1/api',
                method: "post",
                data: {
                    query: `query($filter: PriceRuleFilter,$filterInfoProduct: InfoProductFilter){
                        priceRules(PriceRuleFilter: $filter){
                          id type targetId markup status createdBy reviewedBy reviewedAt validFrom validTo mode currencyId
                        }
                        infoProducts(filter: $filterInfoProduct){
                            id brandId brand name reference dialColor onHand stockIn status officialRef upc ean jan
                        }
                      }
                    `,
                    variables: {
                        filter:{},
                        filterInfoProduct: {}
                    },
                },
            });
			const { priceRules, infoProducts } = data;

            const uniqueBrandList = _.uniqBy(infoProducts, 'brandId');

			return  {
				data: _.keyBy(priceRules,'id'),
				index: priceRules.map(pr=>pr.id),
                brands: _.keyBy(uniqueBrandList, 'brandId'),
				products: _.keyBy(infoProducts, "id")
			}
		} catch (err) {
            throw err;
        }
    },    
});

const submitPriceRule = createVibes({
    type: SUBMIT_PRICE_RULE_BEGIN,
    async process({ getState, action, axios }, dispatch, done) {
        try {	
            const input = getState().priceRule.priceRuleDetail;
 
            ["validTo", "status", "reviewedAt", "reviewedBy", "createdBy"].map(x => {
                return delete input[x];
            })

			const response = await axios.request({
                url: '/aprs', // TODO(PRICERULE): need to use this url 
                // url: 'http://127.0.0.1:9100/v1/api',
                method: "post",
                data: {
                    query: `mutation ($input: PriceRuleInput) { 
                        upsertPriceRule(input: $input) { 
                          id type status markup mode currencyId
                        } 
                    }`,
                    variables: {
						input
                    }
                },
            });

            const { upsertPriceRule } = response.data;
            
            if (upsertPriceRule && upsertPriceRule.id) {
                // console.log("111-success-here....")
				const action = input && input.id ? "updated" : "created";
                dispatch({ type: SUBMIT_PRICE_RULE_SUCCESS,  
                    payload: {
                        notice: "Price rule "+action+" successfully",
                    },
                });
                dispatch({ type: UPDATE_PRICE_RULE_BOX_OPEN_STATUS, payload: false });
                dispatch({ type: CLEAR_PRICE_RULE_VALUE });
                dispatch({ type: LOAD_PRICE_RULES_BEGIN });
            }else {
                let msg = "Failed to insert/update price rule";
                if(response?.errors[0].message) {
                    msg = response?.errors[0].message;
                }
                dispatch({
                    type: SUBMIT_PRICE_RULE_FAIL,
                    payload: msg,
                });
            }
		} catch (error) {
            if (error?.response) {
                dispatch({
                    type: SUBMIT_PRICE_RULE_FAIL,
                    payload: error.response?.data.errors[0]?.message,
                });
            } else {
                dispatch({
                    type: SUBMIT_PRICE_RULE_FAIL,
                    payload: typeof error === "string" ? error : error?.message,
                });
            }
        }
    },    
});

const searchProductPriceTag = createVibes({
    type: SEARCH_PRODUCT_PRICE_TAG,
    async process({ getState, action, axios }, dispatch, done) {
        try {	
            const input = action.payload;

			const response = await axios.request({
                url: '/aprs', // TODO(PRICERULE): need to use this url 
                // url: 'http://127.0.0.1:9100/v1/api',
                method: "post",
                data: {
                    query: `query($clientId: Int, $productId: Int, $filter: PriceTagFilter){
                        priceTag(clientId: $clientId, productId:$productId, filter: $filter){
                            id userId channelId price tax amount type currencyId
                        }
                    }`,
                    variables: {
                        clientId: 1000,
                        productId: input.id,
                        filter: {
                            type: "CALL"
                            // channelId: 662
                        }
                    }
                },
            });

            const { priceTag } = response.data;

            if(priceTag && priceTag.id) {
                dispatch({ type: SEARCH_PRODUCT_PRICE_TAG_SUCCESS,  
                    payload: {
                        id: input.id,
                        brandId: input.brandId,
                        reference: input.reference,
                        price: priceTag.price,
                        currencyId: priceTag.currencyId
                    },
                });
            }else {
                dispatch({
                    type: SEARCH_PRODUCT_PRICE_TAG_FAIL,
                    payload: "Price Tag not found",
                });

                dispatch({ type: CLEAR_SEARCH_PRODUCT_PRICE_TAG});
            }

		} catch (error) {
            if (error?.response) {
                dispatch({
                    type: SEARCH_PRODUCT_PRICE_TAG_FAIL,
                    payload: error.response?.data.errors[0]?.message,
                });
            } else {
                dispatch({
                    type: SEARCH_PRODUCT_PRICE_TAG_FAIL,
                    payload: typeof error === "string" ? error : error?.message,
                });
            }
        }
    },    
});

export const priceRuleVibes =
[
    loadPriceRules,
    submitPriceRule,
    searchProductPriceTag
];