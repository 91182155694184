import React from 'react';
import {Switch} from "react-router";
import {PrivateRoute} from "@cosmos/chaos";
import {features as Features, Functions} from "../../constants";
import {InventoryView} from "./inventory";

export const InventoryComponent = ()=>{
    // TODO this one should come from same data source.
    const func = Functions.inventory;

    const features = Features.filter(f => func.features.includes(f.name));
 
    return (
        <Switch>
            <PrivateRoute
                path={func.path}
                exact
                allowed={func.permission}
                component={InventoryView}
            />
            {features.map(f=>(
                <PrivateRoute
                    key={`inv-${f.name}`}
                    path={`${func.path}${f.path}`}
                    allowed={f.permission}
                    component={f.component}
                />
            ))}
        </Switch>
    )
};
