import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
	InputLabel,
	Paper,
	ListItem,
	TextField,
	InputAdornment,
	withStyles,
	List,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CHANGE_CURRENCY } from "../../../actions";
import { NumberFormatCustom } from "../../../components";
import { Abacus } from '@cosmos/harmony'

const styles = theme => ({
	flexContainer: {
		display: "flex",
		flexDirection: "row",
		padding: "1em",
		marginBottom: "1em"
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	labelColumn: {
		display: "flex",
		flexDirection: "column"
	}
});

class CurrencyComponent_ extends Component {

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		let currency = this.props.selectedStore.currencyId;
		this.props.dispatch({ type: CHANGE_CURRENCY, payload: currency });
	}

	handleChange(event, value) {
		if (value && value.id) {
			this.props.dispatch({ type: CHANGE_CURRENCY, payload: parseInt(value.id) });
		}
	}

	render() {

		const { totalValue, currencies, classes, currencyID, selectedStore } = this.props;
		
		return (
			<Paper>
				<List className={classes.flexContainer}>
					<ListItem>
						<div className={classes.labelColumn}>
							<InputLabel shrink>Currency</InputLabel>
							<Autocomplete
								disabled={(selectedStore.countryId === 1) ? false : true}
								options={Object.values(currencies)}
								getOptionLabel={option => option.code || ''} 
								value={currencyID ? currencies[currencyID] : ''}
								onChange={this.handleChange}
								renderInput={params => (<TextField
										{...params} 
										variant="standard"
									/>
								)}
							/>
						</div>
					</ListItem>

					<ListItem>
						<TextField
							disabled
							label="Net Total"
							value={totalValue && Abacus.round(totalValue, 2)}
							className={clsx(classes.margin, classes.textField)}
							InputProps={{
								inputComponent: NumberFormatCustom,
								startAdornment: (
									<InputAdornment position="start">
										{(currencyID && currencies[currencyID]) && currencies[currencyID].symbol}
									</InputAdornment>
								)
							}}
						/>
					</ListItem>
				</List>
			</Paper>
		);
	}
}

export const CurrencyComponent = connect(state => ({
	taxValue: state.pos.tax,
	totalValue: state.pos.total,
	currencies: state.system.currencies,
	selectedStore: state.system.stores && state.system.stores[state.system.rayLens],
	currencyID: state.pos.currency
}))(withStyles(styles)(CurrencyComponent_));
