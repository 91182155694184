import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { PhotoDialog } from '../../../../components/dialog'
import { makeStyles } from '@material-ui/styles'
import { TrashCanOutline, Camera } from 'mdi-material-ui'
import useHeaders from '../../../../hooks/useHeaders'
import axios from 'axios'
import shortid from 'shortid'
import { dataURItoBlob } from "../../../../services/dataUriToBlob";

const useStyles = makeStyles({
    cameraInput: {
        textAlign: 'center'
    },
    information: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: '10px'
    }
})

/**
 * Input to upload image and display errors if it occurs
 * @param {Object} props 
 * @param {string} props.label - Button label `Take picture - ${label}`
 * @param {string} props.value - Input's current value
 * @param {function} props.handleChange - Function to fire when this input's value changes
 * @param {string} props.uploadUrl - URL to upload the image to
 * @param {function} props.handleError - Function to handle error if error occurs
 */
export default function CameraInput({ label, value, handleChange, uploadUrl, handleError }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const deleteImage = () => {
        handleChange({ target: { value: '' } })
    }

    const headers = useHeaders()
    const submitImage = async image => {
        setIsDialogOpen(false)
        setIsLoading(true)
        // upload image
        const { id, error } = await uploadFile(image, uploadUrl, headers)
        setIsLoading(false)
        if (error) {
            handleError(error)
            return;
        }

        handleChange({ target: { value: id } })
    }

    const classes = useStyles()
    return (
        <div className={classes.cameraInput}>
            <PhotoDialog
                dialogOpen={isDialogOpen}
                handleSubmit={submitImage}
                closeDialog={() => setIsDialogOpen(false)}
                ableToCancel
                renderActions={({ handleClose, handleSubmit, image }) => (
                    <>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!image}>Use</Button>
                    </>
                )}
            />
            <Button variant="contained" onClick={() => setIsDialogOpen(true)} disabled={isLoading}><Camera />{isLoading ? 'Loading' : label}</Button>
            {value ? (
                <div className={classes.information}>
                    <p>{value}</p>
                    <Button variant="outlined" color="secondary" onClick={deleteImage}>
                        <TrashCanOutline />Delete
                    </Button>
                </div>
            ) : null}
        </div>
    )
}

/**
 * Uploads a photo to an endpoint, and return the id of that created file
 * @param {string} file - Base64 encoded file
 * @param {string} url - URL endpoint to upload to, e.g. /v1/upload/walkin
 * @param {object} headers - Headers to pass in. {authorization: `Bearer ${token}`, rayLens: -1}
 * @param {string} headers.token - JWT Token using bearer authentication: e.g. authorization: `Bearer ${token}`
 * @param {string} headers.rayLens - rayLens ID, from state.auth.system
 */
async function uploadFile(file, url, headers) {
    const blob = dataURItoBlob(file, {})

    let form = new FormData();
    const id = shortid.generate()
    form.append('fileData', blob)

    try {
        await axios.request({
            url: `${url}/${id}`,
            data: form,
            headers,
            method: 'post'
        })

        return { id }
    } catch (e) {
        return { error: e.message }
    }

}
