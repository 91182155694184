import React, { useState, useEffect, useRef } from 'react'
import OrderDetail from './OrderDetail'
import Button from '@material-ui/core/Button'
import { generateOrderDetail } from '../functions/formServices'
import { makeStyles } from '@material-ui/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
    orderDetails: {
        width: '100%',
        border: '1px solid #ccc',
        borderCollapse: 'collapse',
        position: 'relative',
        height: '500px'
    },
    orderDetailsHeader: {
        position: 'sticky',
        top: 0,
        '& th': {
            padding: '10px 0'
        }
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})

export default function OrderDetails({ orderDetails, currency, onChange, errors, updateErrors, showProductDetails }) {
    const [totalPrice, setTotalPrice] = useState(0)

    const lastDetailRef = useRef(null)
    const addOrderDetail = () => {
        const updatedOrderDetails = [...orderDetails, generateOrderDetail()]
        onChange({ target: { value: updatedOrderDetails } })
        setTimeout(() => lastDetailRef.current.scrollIntoView(), 0)
    }

    const deleteOrderDetail = index => () => {
        const updatedOrderDetails = [...orderDetails]
        updatedOrderDetails.splice(index, 1)
        onChange({ target: { value: updatedOrderDetails } })

        const updatedErrors = [...errors]
        updatedErrors.splice(index, 1)
        updateErrors(updatedErrors)
    }

    const updateOrderDetail = index => updatedOrderDetail => {
        const updatedOrderDetails = [...orderDetails.map(orderDetail => ({ ...orderDetail }))]; // deep copy
        updatedOrderDetails.splice(index, 1, updatedOrderDetail)
        onChange({ target: { value: updatedOrderDetails } })
    }

    useEffect(() => {
        let updatedPrice = 0;
        for (let i = 0; i < orderDetails.length; i++) {
            const currentOrderDetailPrice = Number(orderDetails[i].price)
            if (typeof currentOrderDetailPrice !== 'number' || Number.isNaN(currentOrderDetailPrice)) {
                setTotalPrice('Invalid')
                return
            } else {
                updatedPrice += currentOrderDetailPrice
            }
        }

        setTotalPrice(updatedPrice)
    }, [orderDetails])

    const classes = useStyles()
    return (
        <div>
            <TableContainer className={classes.orderDetails}>
                <Table stickyHeader size="small" padding="none">
                    <TableHead className={classes.orderDetailsHeader}>
                        <TableRow>
                            <TableCell style={{padding: '3px', textAlign: 'center'}}>S/N</TableCell>
                            <TableCell>Model</TableCell>
                            <TableCell style={{paddingLeft: '20px'}}>Condition</TableCell>
                            <TableCell>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderDetails.map((orderDetail, index) => (
                            <OrderDetail {...orderDetail} index={index + 1} key={index}
                                deleteOrderDetail={deleteOrderDetail(index)}
                                updateOrderDetail={updateOrderDetail(index)}
                                showProductDetails={showProductDetails}
                                errors={errors[index]}
                            />
                        ))}
                        <tr ref={lastDetailRef} />
                    </TableBody>
                </Table>
            </TableContainer>

            <div className={classes.footer}>
                <h2>Total Price: {`${currency ? currency.symbol : ''} ${totalPrice}`}</h2>
                <Button variant="contained" onClick={addOrderDetail}>Add Order Detail</Button>
            </div>
        </div>
    )
}
