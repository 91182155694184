import {
  LOAD_RESERVATION, LOAD_RESERVATION_SUCCESS, LOAD_RESERVATION_ERROR,
  CLOSE_RESERVATION_NOTICE, UPDATE_RESERVATION_SEARCH_TEXT,
  UPDATE_FILTERED_RESERVATION, UPDATE_SELECTED_PERSON, STATE_FILTER_UPDATED,
  OPEN_PROCUREMENT_DRAWER, CLOSE_PROCUREMENT_DRAWER, SELECT_PROCUREMENT,
  UPDATE_PROCUREMENT_STATE_SUCCESS, UPDATE_PROCUREMENT_STATE_FAIL,
} from "../actions";

const initialState = {
  procurements: {},
  loading: false,
  filteredProcurements: [],
  notify: false,
  noticeType: 'info',
  notice: '',
  searchText: '',
  selectedPersons: [],
  selectedStates: [],
  reservationStateList: {
    0: { name: 'Opened' },
    1: { name: 'Pending' },
    2: { name: 'Ordered' },
    3: { name: 'Cancelled' },
    4: { name: 'Failed' },
    5: { name: 'Settled' },
    6: { name: 'Reserved' }
  },
  procurementStateList: {
    0: { id: 0, name: 'Opened' },
    1: { id: 1, name: 'Ordered' },
    2: { id: 2, name: 'Stock In' },
    3: { id: 3, name: 'Cancelled' },
  },
  procurementDrawer: false,
  selectedProcurementId: -1,
};

export const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RESERVATION:
      return { ...state, loading: true }
    case LOAD_RESERVATION_SUCCESS:
    case LOAD_RESERVATION_ERROR:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case CLOSE_RESERVATION_NOTICE:
      return {
        ...state,
        notify: false,
        noticeType: 'info',
        notice: '',
      }
    case UPDATE_RESERVATION_SEARCH_TEXT:
    case UPDATE_FILTERED_RESERVATION:
    case UPDATE_SELECTED_PERSON:
    case STATE_FILTER_UPDATED:
    case OPEN_PROCUREMENT_DRAWER:
    case CLOSE_PROCUREMENT_DRAWER:
    case SELECT_PROCUREMENT:
    case UPDATE_PROCUREMENT_STATE_SUCCESS:
    case UPDATE_PROCUREMENT_STATE_FAIL:
      return { ...state, ...action.payload }
    default:
      return state;
  }
};