import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import {
    TextField,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import {
    LOAD_SUPPLIES,
    SAVE_OVERVIEW_FILTER,
} from "../../actions/marketing";
import classNames from "classnames";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BrandInput from './BrandInput'

const useStyles = makeStyles((theme) => ({
    header: {},
    item: {
        borderRadius: "0.25em",
        marginBottom: "0.25em",
    },
    label: {
        minWidth: "6em",
        flex: 1,
    },
    fields: {
        display: "flex",
        flex: 3,
    },
    input: {
        marginLeft: "1em",
    },
    actionPanel: {
        padding: "16px",
        justifyContent: "flex-end",
    },
    actionButtons: {
        color: "white",
    },
    card: {
        margin: "1em"
    }
}));
export const SuppliesFilter = ({ className, showBrand } ) => {
    // Hooks for dispatch
    const dispatch = useDispatch();

    const brands = useSelector((state) => state.catalog.brands);
    const traders = useSelector((state) => state.trader.traders);
    const suppliesFilterState = useSelector((state) => state.marketing.suppliesFilter);
    const [suppliesFilter, setSuppliesFilter] = useState(null);

    useEffect(() => {
        setSuppliesFilter(suppliesFilterState);
    }, [suppliesFilterState])

    const [isDisableBtn, setIsDisableBtn] = useState(true);

    const updateFilter = (type, value) => {
        let filterData = {
            ...suppliesFilter,
            [type]: value,
        }
        setSuppliesFilter(filterData)
        // dispatch({ type: SAVE_OVERVIEW_FILTER, payload: filterData });
    };

    useEffect(() => {
        if (suppliesFilter && Object.keys(suppliesFilter).length > 0) {
            setIsDisableBtn(false);
        } else {
            setIsDisableBtn(true);
        }
    }, [suppliesFilter])

    const styles = useStyles();
    return (
        <div >
            <Card className={styles.card}>
                <CardHeader
                    className={styles.header}
                    title={"Filter"}
                />
                <CardContent>
                    <List>
                        <ListItem
                            className={classNames(styles.item)}
                        >
                            <ListItemText className={styles.label}>
                                Supplier
                            </ListItemText>
                            <div className={styles.fields}>
                                <Autocomplete
                                    disabled={Object.keys(traders).length > 0 ? false : true}
                                    style={{ flex: 1 }}
                                    value={suppliesFilter && suppliesFilter.traderId ? traders[suppliesFilter.traderId] : null}
                                    options={Object.values(traders)}
                                    getOptionLabel={(option) => {
                                        if (typeof option === "string") {
                                            return option;
                                        }
                                        return option.name;
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = options.filter((o) => {
                                            return o.name
                                                .toUpperCase()
                                                .startsWith(
                                                    params.inputValue.toUpperCase()
                                                );
                                        });
                                        return filtered;
                                    }}
                                    onChange={(event, value) => {
                                        if (value && value.id) {
                                            updateFilter("traderId", value.id)
                                        } else {
                                            updateFilter("traderId", null);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Supplier"}
                                            margin="dense"
                                        />
                                    )}
                                />
                            </div>
                        </ListItem>
                        {showBrand && <ListItem
                            className={styles.item}
                        >
                            <ListItemText className={styles.label}>
                                Brand
                            </ListItemText>
                            <div className={styles.fields}>
                            <BrandInput
                                disabled={Object.keys(brands).length > 0 ? false : true}
                                style={{ flex: 1 }}
                                loading={false}
                                value={suppliesFilter && suppliesFilter.brandId ? brands[suppliesFilter.brandId] : {}}
                                onChange={brand => {
                                    if (brand && brand.id) {
                                        updateFilter("brandId", parseInt(brand.id))
                                    } else {
                                        updateFilter("brandId", null);
                                    }
                                }}
                            />
                            </div>
                        </ListItem>}
                        <ListItem
                            className={styles.item}
                        >
                            <ListItemText className={styles.label}>
                                Entered
                            </ListItemText>
                            <div className={styles.fields}>
                                <TextField
                                    style={{ flex: 1 }}
                                    label="Date Entered"
                                    type="date"
                                    className={styles.picker}
                                    InputLabelProps={{ shrink: true }}
                                    value={(suppliesFilter && suppliesFilter.validFrom) ? moment(suppliesFilter.validFrom).format("YYYY-MM-DD") : ''}
                                    onChange={(e) => { updateFilter("validFrom", moment(e.target.value).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")) }}
                                />
                            </div>
                        </ListItem>
                        <ListItem
                            className={styles.item}
                        >
                            <ListItemText className={styles.label}>
                                Expired
                            </ListItemText>
                            <div className={styles.fields}>
                                <TextField
                                    style={{ flex: 1 }}
                                    label="Expiry Date"
                                    type="date"
                                    className={styles.picker}
                                    InputLabelProps={{ shrink: true }}
                                    value={(suppliesFilter && suppliesFilter.validTo) ? moment(suppliesFilter.validTo).format("YYYY-MM-DD") : ''}
                                    onChange={(e) => { updateFilter("validTo", moment(e.target.value).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")) }}
                                />
                            </div>
                        </ListItem>
                    </List>
                </CardContent>
                <CardActions className={styles.actionPanel}>
                    <Button
                        className={styles.actionButtons}
                        onClick={(e) => {
                            setSuppliesFilter({});
                            dispatch({ type: SAVE_OVERVIEW_FILTER, payload: {} });
                        }}
                        disabled={isDisableBtn}
                        variant="contained"
                        color="primary"
                    >
                        Reset
                    </Button>
                    <Button
                        className={styles.actionButtons}
                        onClick={(e) => {
                            dispatch({ type: SAVE_OVERVIEW_FILTER, payload: suppliesFilter });
                        }}
                        disabled={isDisableBtn}
                        variant="contained"
                        color="primary"
                    >
                        Submit
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
};
