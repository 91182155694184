import React from "react";
import {AppBar, Toolbar, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {AUTH_LOGOUT, Authorization, isAuthorized} from "@cosmos/chaos";
import {makeStyles} from "@material-ui/styles";
import {MiniProfile} from "./profile";
import {UPDATE_RAY_STORE} from "../../actions";
import {MiniDateTime} from "./datetime";
import {NavLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import {Menu} from 'mdi-material-ui';
import {MENU_OP, CHANGE_CURRENCY} from "../../actions";
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    nav: {zIndex: theme.zIndex.appBar + 50},
    toolbar: {paddingLeft: `${theme.spacing(4)}px`},
    spacer: {flex: 1},
    menu: {
        backgroundColor: theme.palette.primary.light,
        '&.active': {
            fontWeight: "bold",
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.dark
        }
    },
    menuButton: {
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(2)
    }
}));

export const Navigation = ({classes, ...rest}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(state=>state.auth.username);
    const permission = useSelector(state=>state.auth.permission);
    const isAuth = useSelector(state=>state.auth.isAuthenticated);
    const currentStore = useSelector(state=>state.system.rayLens);
    const menu = useSelector(state=>state.system.menu);
    const stores = Object.values(useSelector(state=>state.system.stores))
        .filter(r => !!r)
        .sort((a, b) => {
            const nA = a.name.toUpperCase();
            const nB = b.name.toUpperCase();
            if (nA < nB) return -1;
            if (nA > nB) return 1;
            return 0;
        });
    const sysFeatures = useSelector(state=>state.system.features);
    const features = (sysFeatures).filter(f=>f.menu);
    const handleAction = (action) => {
        switch (action) {
            case 'logout': {
                dispatch({type: AUTH_LOGOUT});
                return;
            }
            default:
                return;
        }
    };
    const handleMenu = () => {
        dispatch({type: MENU_OP, payload: (menu === 0) ? 1 : 0})
    };
    const handleSelect = (item) => {
        dispatch({type: UPDATE_RAY_STORE, payload: item});
        
        let currency = stores.find(x => x.id === item).currencyId;
        dispatch({type: CHANGE_CURRENCY, payload: currency})
    };
    const admin = sysFeatures.find(f=>f.name === 'admin');
    console.log("SF", sysFeatures);
    console.log("F", features);
    return (
        <AppBar position="static"
                className={classNames(styles.nav, ((classes && classes.root) ? classes.root : rest.className))}>
            <Toolbar variant="dense" className={styles.toolbar}>
                <IconButton edge="start" className={styles.menuButton} color="inherit"
                            onClick={e => {
                                handleMenu()
                            }}
                >
                    <Menu/>
                </IconButton>
                <Typography variant="h5" color="inherit">
                    Cosmos System
                </Typography>
                <div style={{width: '56px'}}/>
                {isAuth && <ButtonGroup>
                    {features
                        .filter(func => isAuthorized(permission || [], ...func.permission))
                        .map(func => (
                            <Button className={styles.menu}
                                    component={NavLink}
                                    to={func.path}
                                    key={func.name}
                            >
                                {func.name}
                            </Button>
                        ))}
                </ButtonGroup>}
                <div className={styles.spacer}/>
                {isAuth &&
                <Authorization allowed={admin.permission}>
                    {({permit}) => (permit(permission) &&
                        <Button className={styles.menu}
                                component={NavLink} to="/admin">
                            Admin
                        </Button>
                    )}
                </Authorization>}
                <div style={{width: '8px'}}/>
                <MiniDateTime/>
                {
                    isAuth && (
                        <MiniProfile
                            name={user}
                            stores={stores}
                            currentStore={currentStore}
                            onAction={handleAction}
                            onSelect={handleSelect}
                        />
                    )
                }
            </Toolbar>
        </AppBar>
    );
};