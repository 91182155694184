import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { Notification } from '../notification';

class FocusDialogComponent extends Component {

  render() {
    const { open, message, variant, onClose } = this.props;

    return (
      <Dialog maxWidth={'lg'} open={open}>
        <Notification variant={variant} message={message} onClose={onClose} />
      </Dialog>
    )
  }
}

export const FocusDialog = FocusDialogComponent;