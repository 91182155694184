export const LOAD_RESERVATION = "@@RESERVATION/LOAD_RESERVATION";
export const LOAD_RESERVATION_SUCCESS = "@@RESERVATION/LOAD_RESERVATION_SUCCESS";
export const LOAD_RESERVATION_ERROR = "@@RESERVATION/LOAD_RESERVATION_ERROR";
export const CLOSE_RESERVATION_NOTICE = "@@RESERVATION/CLOSE_TENDER_NOTICE";
export const UPDATE_RESERVATION_SEARCH_TEXT = "@@RESERVATION/UPDATE_RESERVATION_SEARCH_TEXT";
export const TO_FILTER_RESERVATION = "@@RESERVATION/TO_FILTER_RESERVATION";
export const UPDATE_FILTERED_RESERVATION = "@@RESERVATION/UPDATE_FILTERED_RESERVATION";
export const UPDATE_SELECTED_PERSON = "@@RESERVATION/UPDATE_SELECTED_PERSON";
export const STATE_FILTER_UPDATED = "@@RESERVATION/STATE_FILTER_UPDATED"
export const UPDATE_SELECTED_STOCK_PERSON = "@@RESERVATION/UPDATE_SELECTED_STOCK_PERSON";
export const UPDATE_SELECTED_STOCK_STATE = "@@RESERVATION/UPDATE_SELECTED_STOCK_STATE";

export const OPEN_PROCUREMENT_DRAWER = "@@RESERVATION/OPEN_PROCUREMENT_DRAWER";
export const CLOSE_PROCUREMENT_DRAWER = "@@RESERVATION/CLOSE_PROCUREMENT_DRAWER";
export const SELECT_PROCUREMENT = "@@RESERVATION/SELECT_PROCUREMENT";
export const UPDATE_PROCUREMENT_STATE = "@@RESERVATION/UPDATE_PROCUREMENT_STATE";
export const UPDATE_PROCUREMENT_STATE_FAIL = "@@RESERVATION/UPDATE_PROCUREMENT_STATE_FAIL";
export const UPDATE_PROCUREMENT_STATE_SUCCESS = "@@RESERVATION/UPDATE_PROCUREMENT_STATE_SUCCESS";

export const CHANGE_PROCUREMENT_SUPPLIES = "@@RESERVATION/CHANGE_PROCUREMENT_SUPPLIES";

