import { createVibes } from "@cosmos/vibes";
import {
  CREATE_PURCHASE_ORDER_SUCCESS,
  CART_CHECKOUT_BEGIN, CART_CHECKOUT_SUCCESS, CART_CHECKOUT_ERROR, enqueuePosNotice,
  CART_ADD_ITEM_SUCCESS, CART_REMOVE_ITEM_SUCCESS, CART_RESET, UPDATE_POS_DISABLE_BOOL, CART_UPDATE_TOTAL_SUCCESS,
  SCAN_ACTION_CARD_SUCCESS, SCAN_ACTION_CARD_FAILED, DELIVERY_LABEL_SUCCESS, DELIVERY_LABEL_ERROR, INIT_PAYSAFE
} from "../../actions";

export const checkoutVibes = createVibes({
  type: [CART_CHECKOUT_BEGIN],
  latest: true,
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const pos = getState().pos;
      const { stores, rayLens } = getState().system;
      const { token } = getState().auth;

      const goods = pos.goods.map((g, idx) => {
        return {
          stockId: g.id,
          price: pos.prices[idx].price,
          discountRate: g.discountRate ? g.discountRate : 0,
          birthYear: g.birthYear,
          birthMonth: g.birthMonth ? String(g.birthMonth) : null,
          birthDate: g.birthDate,
          cardFront: g.cardFront,
          cardBack: g.cardBack,
          reservation: g.reservation
        }
      });

      if (goods.length === 0) {
        dispatch(enqueuePosNotice('warning', 'The Cart is empty, please add some stock before check out'));
        dispatch({ type: CART_CHECKOUT_ERROR, payload: new Error('Not allow to submit empty cart') });
        done();
      }
      else {
        const cart = {
          date: pos.date,
          // customer: pos.company, // original code
          customer: pos.company.id ? pos.company : pos.customer,
          contact: pos.contact,
          currency: stores[rayLens] ? stores[rayLens].currencyId : -1,
          receipt: pos.receipt,
          type: pos.type,
          tracking: pos.tracking,
          taxRate: pos.taxRate ? parseFloat(pos.taxRate) : 0,
          discountRate: pos.discountRate ? parseFloat(pos.discountRate) : 0,
          remarks: pos.remarks,
          goods,
          once: pos.once
        };

        try {
          let query = `mutation($cart: CartInput){
            updateCart(input: $cart){
                id storeId reference 
              }
          }`;
          let result;
          if (getState().pos.company && getState().pos.company.id) {
            const axiosResult = await axios.request({
              url: '/api',
              method: 'post',
              headers: {
                'Authorization': `Bearer ${token}`,
                rayLens
              },
              data: {
                query: query,
                variables: {
                  cart: {
                    ...cart,
                    target: String(getState().pos.company.id),
                    origin: String(pos.originId),
                    deliveryLabel: getState().pos.delivery.label,
                    command: 'LGS'
                  }
                }
              }
            });

            let data = axiosResult.data;
            result = data.updateCart;
            if (result) {
              dispatch({ type: CREATE_PURCHASE_ORDER_SUCCESS, payload: result.purchase });
              dispatch({ type: CART_CHECKOUT_SUCCESS });
              dispatch({ type: CART_RESET });
              dispatch(enqueuePosNotice('success', `Trading Process Completed.`));
              const data = await axios.request({
                url: `/prc/${result.cart.storeId}/${result.cart.reference}`,
                method: 'get',
                headers: {
                  'Authorization': `Bearer ${token}`,
                  rayLens,
                },
                responseType: "blob"
              });
              const response = data.request && data.request.response;
              const file = new Blob([response], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              let nwin = window.open(fileURL, '_blank');
              nwin.focus();
            }
          }
          else {
            const axiosResult = await axios.request({
              url: '/api',
              method: 'post',
              headers: {
                'Authorization': `Bearer ${token}`,
                rayLens
              },
              data: {
                query: query,
                variables: { cart }
              }
            });
            let data = axiosResult.data;
            result = data.updateCart;
            
            if (result && action.payload.ProformaSales === true) {
              dispatch({ type: CART_CHECKOUT_SUCCESS });
              dispatch({ type: CART_RESET });
              dispatch(enqueuePosNotice('success', `Order ${result.reference} proceed completed.`));
              const data = await axios.request({
                url: `/prc/${result.storeId}/${result.reference}`,
                method: 'get',
                headers: {
                  'Authorization': `Bearer ${token}`,
                  rayLens,
                },
                responseType: "blob"
              });
              const response = data.request && data.request.response;
              const file = new Blob([response], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              let nwin = window.open(fileURL, '_blank');
              nwin.focus();
            
          }
          else if (result && action.payload.ProformaSales === false) {
            dispatch({ type: INIT_PAYSAFE, payload: { proformaCartId: result.id, type: 0 } }); //type -> 0: tax required, 1: tax free, 2: export 
          }
          }
          if (!result) {
            dispatch(enqueuePosNotice('error', `Order process not complete`));
            dispatch({ type: CART_CHECKOUT_ERROR, payload: new Error('Order process not complete') });
          }
          done();
        }
        catch (e) {
          let error = e[0];
          //TODO: check whether the error message from backend is correct
          if (error && error.message !== 'Do not resubmit the same sales') {
            dispatch(enqueuePosNotice('error', `Order process not complete`));
          }
          dispatch({ type: CART_CHECKOUT_ERROR, payload: error });
          done();
        }
      }
    } catch (err) {
      throw err;
    }
  }
});


export const disablePosVibes = createVibes({
  type: [
    CART_ADD_ITEM_SUCCESS,
    CART_REMOVE_ITEM_SUCCESS,
    CART_CHECKOUT_BEGIN,
    CART_CHECKOUT_SUCCESS,
    CART_CHECKOUT_ERROR,
    CART_UPDATE_TOTAL_SUCCESS,
    SCAN_ACTION_CARD_SUCCESS,
    SCAN_ACTION_CARD_FAILED,
    DELIVERY_LABEL_SUCCESS,
    DELIVERY_LABEL_ERROR,
  ],
  latest: true,
  async process({ getState }, dispatch, done) {
    try {
      const { submitted, goods, originId, delivery } = getState().pos;
      let disableCart = false;
      if (submitted || goods.length < 1) {
        disableCart = true;
      }

      if (originId && delivery && !delivery.label) {
        disableCart = true;
      }

      dispatch({ type: UPDATE_POS_DISABLE_BOOL, payload: { disableCart } });
      done();
    } catch (err) {
      throw err;
    }
  }
});
