import React, { Component } from "react";
import { connect } from "react-redux";
import { withCmdCode } from '@cosmos/chaos';
import styles from './gearDialog.module.scss';
import {
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    Input,
    DialogTitle,
    List,
    ListItem,
    Typography,
    IconButton,
} from '@material-ui/core';
import { GEAR_SELECT_ITEM } from "../../../../actions";
import { Delete } from "mdi-material-ui";
import Avatar from "@material-ui/core/Avatar";

export class GearDialogComponent extends Component {
    state = { scannedLabel: "" };

    componentDidMount() {
        this.cmdSource = this.props.source.subscribe(code => {
            if (code.cmd) {
                switch (code.cmd) {
                    case 'STK':
                        this.searchStock(code.val);
                        break;
                    default:
                }
            }
        });
    }

    updateSearchedLabel = (e) => {
        this.setState({ scannedLabel: e.target.value });
    };

    searchStock = (label) => {
        if (label) {
            this.props.searchStock(label);
            this.setState({ scannedLabel: "" });
        }
    };

    selectItem = (idx) => {
        this.props.dispatch({ type: GEAR_SELECT_ITEM, payload: { selected: idx } });
    };

    render() {
        const { dialogOpen, handleSubmit, closeDialog, selected, items, brands, products, removeItem, title } = this.props
        const { scannedLabel } = this.state
        return (
            <div >
                <Dialog
                    fullWidth
                    className={styles['dialog']}
                    open={dialogOpen}
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <div className={styles['content']}>
                            <Input
                                className={styles['search-bar']}
                                value={scannedLabel}
                                fullWidth
                                placeholder={title}
                                onChange={this.updateSearchedLabel}
                            />
                            <Button
                                className={styles.btn} variant="contained" color="primary"
                                onClick={e => this.searchStock(scannedLabel)}>
                                Search
                            </Button>
                        </div>
                        <List className={styles.items}>
                            {items.map((item, idx) => (
                                <ItemView key={`gear-stock-${idx}`}
                                    idx={idx}
                                    selected={selected}
                                    trigger={() => { removeItem(idx) }}
                                    onSelect={() => this.selectItem(idx)}
                                    brand={brands[item.brandId]}
                                    product={products[item.productId]}
                                />
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} color='primary' variant='outlined' size='small'>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color='primary' variant='contained' size='small'>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export const GearDialog = connect(state => ({
    brands: state.catalog.brands,
    products: state.catalog.products,
    selected: state.gear.selected,
}))(withCmdCode(GearDialogComponent));

class ItemView extends Component {
    render() {
        const { idx, trigger, onSelect, selected, product, brand } = this.props;
        return (
            <ListItem selected={idx === selected}
                className={styles['item-view']}>
                <IndexView idx={idx} trigger={() => {
                    trigger(idx)
                }} />
                <div
                    onClick={onSelect}
                    className={styles["product-view"]}>
                    <div className={styles["product-primary"]}>
                        <Typography component="span">{product && product.name}</Typography>
                    </div>
                    <div className={styles["product-secondary"]}>
                        <Typography component="span">{brand.name}</Typography>
                        <Typography component="span">{product && product.reference}</Typography>
                    </div>
                </div>
            </ListItem >
        )
    }
}

class IndexView extends Component {
    state = { hover: false };

    handleMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleMouseLeave = () => {
        this.setState({ hover: false });
    };

    handleRemove = () => {
        this.props.trigger();
    };

    render() {
        const { hover } = this.state;
        const { idx } = this.props;

        return (
            <div className={styles['idx-view']}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {hover ?
                    (
                        <IconButton className={styles.remove} onClick={this.handleRemove}>
                            <Delete />
                        </IconButton>
                    ) :
                    (
                        <Avatar className={styles.avatar}>
                            {idx + 1}
                        </Avatar>
                    )
                }
            </div>
        )
    }
}


