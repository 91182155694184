import React, { Component } from "react";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import { Typography, InputAdornment, TextField, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { CartArrowUp } from "mdi-material-ui";
import IconButton from "@material-ui/core/IconButton";
import { alpha }from "@material-ui/core";
import { Cart, Tag, Store } from "mdi-material-ui";
import { NumberFormatCustom } from "../../../components";
import { Abacus } from "@cosmos/harmony";
import { PhotoPopper } from "../../../components/Popper/PopperPhoto";
import { Image } from "mdi-material-ui";
import clsx from "clsx";

const styles = theme => ({
    "idx-view": {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "2.5em"
    },
    remove: {
        backgroundColor: `${theme.palette.warning.main}`
    },
    avatar: {
        backgroundColor: `${theme.palette.primary.main}`
    },
    "item-view": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "0.5em",
        border: "1px solid darken(white, 3)",
        marginBottom:" 4px"
    },
    "void-item-view": {
        backgroundColor: alpha(theme.palette.error.light, 0.5)
    },
    "price-tag": {
        marginRight: "0.25em",
        alignSelf: "center"
    },
    "product-primary": {
        fontSize: "90%",
    },
    "product-secondary": {
        display: "flex"
    },
    "product-view": {
        flex: 1,
        marginLeft: "1em"
    },
    "discount-view": {
        width: "5em",
        marginRight: "1em"
    },
    icon: {
        width: "2em",
        marginRight: "1em",
        color: `${theme.palette.primary.dark}`
    },
    "price-view": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly"
      },
      "price-amount": {
        height: "100%",
        marginRight: "1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }
});

class ItemView_ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPhoto: false,
            anchorElPhoto: null,
            placementPhoto: null,
            flip: true,
            preventOverflow: "scrollParent"
        };
    }

    handleMouseEnter = (placementPhoto, imageIndicator) => event => {
        this.props.loadTempImage(imageIndicator);
        const { currentTarget } = event;
        this.setState({
            anchorElPhoto: currentTarget,
            openPhoto: true,
            placementPhoto: placementPhoto
        });
    };

    handleMouseLeave = () => {
        this.setState({
            anchorElPhoto: null,
            openPhoto: false,
            placementPhoto: null
        });
    };

    render() {
        const { dataUri, idx, trigger, onSelect, selected, price, goods, product, brand, currencies, cartPrice, stores, classes, currencyID } = this.props;
        const { openPhoto, anchorElPhoto, placementPhoto, flip, preventOverflow } = this.state;

        return (
            <ListItem selected={!goods[idx].isVoidItem && (idx === selected)} className={clsx(classes["item-view"], (goods[idx].isVoidItem ? classes["void-item-view"] : ''))}>
                <IndexView
                    idx={idx}
                    classes={classes}
                    trigger={() => {
                        trigger(idx);
                    }}
                />
                <div onClick={onSelect} className={classes["product-view"]}>
                    <div className={classes["product-primary"]}>
                        <Typography component="span">{product.name}</Typography>
                    </div>
                    <div className={classes["product-secondary"]}>
                        <Typography component="span">{brand.name}</Typography>
                        <Typography component="span">{product.reference}</Typography>
                    </div>
                    <div className={classes["product-secondary"]}>
                        <div className={classes.icon}>
                            <Tag />
                        </div>
                        <div className={classes["price-tag"]}>
                            <Typography component="span">
                                {price && Abacus.round(price.price, 2)}
                            </Typography>
                        </div>
                        <div className={classes.icon}>
                            <Store />
                        </div>
                        <div className={classes["price-tag"]}>
                            <Typography component="span">
                                {stores &&
                                    goods[idx] &&
                                    goods[idx].storeId &&
                                    stores[goods[idx].storeId] &&
                                    stores[goods[idx].storeId].name}
                            </Typography>
                        </div>

                        {goods[idx] && goods[idx].cardFront && (
                            <div className={classes.image} >
                                <div
                                    key={`card-front-popper-${idx}`}
                                    onMouseEnter={this.handleMouseEnter('right-end', goods[idx].cardFront)}
                                >
                                    <Image />
                                </div >
                            </div>
                        )}
                        {goods[idx] && goods[idx].cardBack && (
                            <div className={classes.image} >
                                <div
                                    key={`card-back-popper-${idx}`}
                                    onMouseEnter={this.handleMouseEnter('right-end', goods[idx].cardBack)}
                                >
                                    <Image />
                                </div >
                            </div>
                        )}

                    </div>
                </div>

                <PhotoPopper
                    onMouseLeave={this.handleMouseLeave}
                    openPhoto={openPhoto}
                    anchorElPhoto={anchorElPhoto}
                    assetReference={dataUri}
                    placementPhoto={placementPhoto}
                    flip={flip}
                    preventOverflow={preventOverflow}
                />

                <div className={classes["price-view"]} onClick={onSelect}>
                    <div className={classes["discount-view"]} onClick={onSelect}>
                        <Typography component="div">Discount</Typography>
                        <TextField
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                disableUnderline: true
                            }}
                            value={
                                goods[idx].discountRate &&
                                Abacus.round(goods[idx].discountRate, 2)
                            }
                            onBlur={e => {
                                this.props.updateIdvDiscRate(e.target.value, idx);
                            }}
                        />
                    </div>
                    <div className={classes.icon}>
                        <Cart />
                    </div>
                    <div className={classes["price-amount"]}>
                        <Typography component="div">
                            { currencies[currencyID] && currencies[currencyID].symbol + ` wo Tax`}
                        </Typography>
                        <TextField
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                disableUnderline: true
                            }}
                            value={cartPrice.priceWithDiscount && Abacus.round(cartPrice.priceWithDiscount, 2)}
                            onBlur={e => {
                                this.props.updatePrice(e.target.value, idx);
                            }}
                        />
                    </div>
                    <div className={classes["price-amount"]}>
                        <Typography component="div">
                            { currencies[currencyID] && currencies[currencyID].symbol}
                        </Typography>
                        <TextField
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                disableUnderline: true,
                                readOnly: true
                            }}
                            value={cartPrice.amount && Abacus.round(cartPrice.amount, 2)}
                        />
                    </div>
                </div>
            </ListItem>
        );
    }
}
export const ItemView = connect(state => ({}))(withStyles(styles)(ItemView_));

class IndexView extends Component {
    state = { hover: false };

    handleMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleMouseLeave = () => {
        this.setState({ hover: false });
    };

    handleRemove = () => {
        this.props.trigger();
    };

    render() {
        const { hover } = this.state;
        const { idx, classes } = this.props;

        return (
            <div
                className={classes["idx-view"]}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {hover ? (
                    <IconButton className={classes.remove} onClick={this.handleRemove}>
                        <CartArrowUp />
                    </IconButton>
                ) : (
                        <Avatar className={classes.avatar}>{idx + 1}</Avatar>
                    )}
            </div>
        );
    }
}
