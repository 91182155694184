import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: "10px 20px",
      backgroundColor: theme.palette.error.light,
      borderLeft: `2px solid ${theme.palette.error.dark}`,
      color: theme.palette.error.contrastText,
      "& *": {
        color: theme.palette.error.contrastText,
      },
    },
  };
});

export default function ErrorText({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}
