import React, { Component } from "react";
import { DialogContent, Button, Dialog, DialogTitle, DialogActions, TextField, InputLabel, Select, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
    "flex-row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    "birth-opt": {
      width: "10rem",
      marginLeft: "1em",
      marginRight: "1em"
    }
});

class BirthDialog_ extends Component {

  render() {
    const { dialogOpen, goods, monthOptions, keyName, selected, closeDialog, updateField, handleNext, classes } = this.props;
    return (
      <Dialog key={keyName} maxWidth={"lg"} open={dialogOpen}>
        <DialogTitle>Please write down the birthday on the warranty card and enter as follow: </DialogTitle>
        <DialogContent className={classes.birth}>
          <div className={classes["flex-row"]}>
            <TextField
              className={classes["birth-opt"]}
              id="birthYear"
              label="Birth Year"
              value={goods[selected] && goods[selected].birthYear ? goods[selected].birthYear : ""}
              InputLabelProps={{ shrink: true }}
              onChange={e => { updateField({ birthYear: e.target.value }, selected); }}
              type={"number"}
              inputProps={{ min: "1000", step: "1" }}
            />
            <div>
              <InputLabel shrink>Birth Month</InputLabel>
              <Select
                className={classes["birth-opt"]}
                value={goods[selected] && goods[selected].birthMonth ? goods[selected].birthMonth : ""}
                onChange={e => { updateField({ birthMonth: e.target.value }, selected); }}
              >
                {Object.values(monthOptions).map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <TextField
              className={classes["birth-opt"]}
              id="birthDate"
              label="Birth Date"
              value={goods[selected] && goods[selected].birthDate ? goods[selected].birthDate : ""}
              InputLabelProps={{ shrink: true }}
              onChange={e => { updateField({ birthDate: e.target.value }, selected); }}
              type={"number"}
              inputProps={{ min: "1", max: "31", step: "1" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined" size="small" >
            cancel
          </Button>
          <Button onClick={handleNext} disabled={goods[selected] && (!goods[selected].birthYear && !goods[selected].birthMonth && !goods[selected].birthDate)} color="primary" variant="contained" size="small">
            next
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const BirthDialog = withStyles(styles)(BirthDialog_);