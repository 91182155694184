import React, { Component } from 'react';
// import styles from './legend.module.scss';
import { Grow, Button, Typography } from "@material-ui/core";
import { Heart, Star, Recycle, CheckboxMarkedCircleOutline } from "mdi-material-ui";
import classNames from 'classnames';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({

    root: {
        display: "flex",
        alignItems: "center",
        margin: "0 0.5em"
    }, 
    content: {
        marginRight: "1em",
        borderRadius: "0.5em",
        display: "flex",
        flexDirection: "row",
        padding: "0 0.5em"
    },
    section: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    item: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "0 0.5em"
    },
    indicator: {
        padding: "2px"
    },
    label: {
        padding: "2px"
    },
    secondhand: {
        color: theme.palette.heart.secondHand
    },
    consignment: {
        color: theme.palette.heart.consignment
    },
    noUsedEmpty: {
        color: theme.palette.heart.noUsed
    },
    noUsedFull: {
        color: theme.palette.heart.used
    },
    usedEmpty: {
        color: theme.palette.heart.noUsed
    },
    noUsed: {
        color: theme.palette.heart.used
    }
})

export class LegendViewComponent extends Component {

    state = {
        open: false
    };

    toggleFilter = () => {
        this.setState(prevState => ({ open: !prevState.open }))
    };

    render() {
        const { report, classes } = this.props;

        return (
            <div className={classes.root}>
                {
                    this.state.open &&
                    <Grow appear in={true} timeout={300}>
                        <div className={classes.content}>
                            <div className={classes.section}>
                                {!report &&
                                    <div className={classes.item}>
                                        <div className={classes.indicator}>
                                            <CheckboxMarkedCircleOutline color="primary" />
                                        </div>
                                        <div className={classes.label}>
                                            <Typography variant="body1">
                                                Verified
                                </Typography>
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className={classes.section}>
                                <div className={classes.item}>
                                    <div className={classes.indicator}>
                                        <Heart className={classes["usedEmpty"]} />
                                    </div>
                                    <div className={classes.label}>
                                        <Typography variant="body1" noWrap>
                                            Never Used
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.indicator}>
                                        <Heart className={classes["noUsed"]} />
                                    </div>
                                    <div className={classes.label}>
                                        <Typography variant="body1" noWrap>
                                            Used
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.section}>
                                <div className={classes.item}>
                                    <div className={classes.indicator}>
                                        <Recycle className={classes["secondhand"]} />
                                    </div>
                                    <div className={classes.label}>
                                        <Typography variant="body1" noWrap>
                                            Second Hand
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.indicator}>
                                        <Star className={classes["consignment"]} />
                                    </div>
                                    <div className={classes.label}>
                                        <Typography variant="body1" noWrap>
                                            Consignment
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grow>
                }
                <Button className={classNames(classes['filter-control'], { 'active': this.state.open })}
                    color="primary"
                    variant="contained"
                    size="small" onClick={this.toggleFilter}>
                    LEGEND
                </Button>
            </div>

        )
    }
}

export const LegendView = withStyles(styles)(LegendViewComponent);