import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
  Paper,
  List,
  ListItem,
  TextField,
  InputAdornment,
  withStyles
} from "@material-ui/core";
import { NumberFormatCustom } from "../../../components";
import { UPDATE_DISC_RATE } from "../../../actions";
import { Abacus } from '@cosmos/harmony'

const styles = theme => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "1em",
    marginBottom: "1em"
  }
});

class DiscountComponent_ extends Component {
  updateDiscRateField = value => {
    this.props.dispatch({
      type: UPDATE_DISC_RATE,
      payload: { discountRate: { ...this.props.discountRate, value } }
    });
  };

  render() {
    const {
      discountRate,
      currencies,
      classes,
      currencyID,
      discountValue,
    } = this.props;


    return (
      <Paper>
        <List className={classes.flexContainer}>
          <ListItem>
            <TextField
              label="Discount Rate"
              id="standard-discount-rate"
              className={clsx(classes.margin, classes.textField)}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                )
              }}
              value={discountRate}
              onFocus={e => e.target.select()}
              // onChange={e => { // original code
              //   this.updateDiscRateField({ discountRate: e.target.value });
              // }}
              onBlur={e => {
                this.updateDiscRateField({ discountRate: e.target.value });
            }}
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Discount Value"
              value={discountValue && Abacus.round(discountValue, 2)}
              className={clsx(classes.margin, classes.textField)}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    { currencies[currencyID] && currencies[currencyID].symbol}
                  </InputAdornment>
                )
              }}
            />
          </ListItem>
        </List>
      </Paper>
    );
  }
}

export const DiscountComponent = connect(state => ({
  discountRate: state.pos.discountRate,
  discountValue: state.pos.discountValue,
  currencies: state.system.currencies,
  selectedStore: state.system.stores && state.system.stores[state.system.rayLens],
  currencyID: state.pos.currency,
  prices: state.pos.prices
}))(withStyles(styles)(DiscountComponent_));
