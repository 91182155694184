import React from 'react'
import { Route, Switch } from 'react-router'
import { PrivateRoute } from '@cosmos/chaos'
import { Navigation } from '../../components/index'
import { MainMenu } from "../../components/menu";
import { AutoSizer } from "react-virtualized";
import classNames from 'classnames';
import { useSelector } from "react-redux";
import { Landing } from "./landing";
import { makeStyles } from "@material-ui/styles";
import {Notifier} from "../../components/Notifier";

const useStyles = makeStyles(theme => ({
    nav: {
        gridRowStart: 'top',
        gridRowEnd: 'head',
        gridColumnStart: 'left',
        gridColumnEnd: 'right'
    },
    content: {
        gridRowStart: 'head',
        gridRowEnd: 'bottom',
        gridColumnStart: 'bar',
        gridColumnEnd: 'right',
        display: "flex",
    },
    menu: {
        gridRowStart: 'head',
        gridRowEnd: 'bottom',
        gridColumnStart: 'left',
        gridColumnEnd: 'bar',
        zIndex: 200
    },
    'menu-open': {
        gridColumnEnd: 'aside'
    },
    'content-open': {
        gridColumnStart: 'aside'
    },
    major: {
        flex: 1,
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        overflow: 'scroll'
    }
}));
export const App = () => {
    const styles = useStyles();
    const features = useSelector(state => state.system.features);
    const menu = useSelector(state => state.system.menu);
    return (
        <>
            <Navigation className={styles.nav} />
            <MainMenu className={classNames(styles.menu, menu === 1 && styles['menu-open'])} />
            <article className={classNames(styles.content, menu === 1 && styles['content-open'])}>
                <AutoSizer>
                    {({ height, width }) => (
                        <section className={styles.wrapper} style={{ height, width }}>
                            <Switch>
                                {
                                    features.map(feature => {
                                        return (
                                            <PrivateRoute
                                                key={feature.name}
                                                exact={feature.exact}
                                                path={feature.path}
                                                allowed={feature.permission}
                                                component={feature.component} />
                                        )
                                    })
                                }
                                <Route path="*" component={Landing} />
                            </Switch>
                        </section>)}
                </AutoSizer>
            </article>
            <Notifier/>
        </>
    )
};


