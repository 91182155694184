// DEPRECATED

import { DataTable } from "@cosmos/chaos/cjs/DataTable";
import { Abacus } from "@cosmos/harmony";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import { TrashCanOutline } from "mdi-material-ui";
import React, { useRef, useState, useEffect, useCallback } from "react";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from 'react-redux'
import AutoSizer from "react-virtualized-auto-sizer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { BULK_IMPORT_INPUT_CHANGE } from '../../../actions'
import { Input } from "../../../components/inputs";
import SelectModel from "../../../components/inputs/SelectModel2";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
// import { generateBlankStock } from '../CreateUpcomingSupply/index' 

const useStyles = makeStyles({
    productBox: {
        display: "flex",
        flexDirection: "column",
    },
    productTableLayout: {
        display: "grid",
        gridAutoRows: "1fr min-content",
        height: "100%",
        overflow: "hidden",
        gridAutoFlow: "row",
    },
    productTable: {
        height: "100%",
    },
    deleteButton: {
        padding: 0,
        outline: "none",
        border: "none",
        background: "none",
        "&:hover": {
            cursor: "pointer",
        },
    },
    tableFooter: {
        display: "flex",
        paddingTop: "1em",
        paddingBottom: "1em",
        alignItems: "center",
        "& > *": {
            margin: "0 1em",
        },
        "& *:first-child": {
            marginRight: "auto",
        },
    },
    hidden: {
        visibility: "hidden",
    },
});


export default function ProductTable({
    supplies = [],
    taxRate,
    setSupplies,
    focusedIndex,
    setFocusedIndex,
    currency,
    className,
    setProductCard,
    isFromRedux
}) {
    const classes = useStyles();
    const [hover, setHover] = useState(-1);
    //const { findModel } = useProduct();
    const dispatch = useDispatch();
    //const [cols, setCols] = useState([]);
    const [dragOver, setDragOver] = useState("");
    const [columns, setColumns] = useState([]);
    const mappedColumns = useSelector(state => state.marketing.import.columns.all);
    const brands = useSelector(state => state.catalog.brands);
    const products = useSelector(state => state.catalog.products);

    //product and brands is a map so 

    let productsAndBrands = useRef(null);
    useEffect(() => {
        productsAndBrands.current = Object.values(products).map((product) => ({
            brand: brands[product.brandID],
            product,
        }));
    }, [brands, products]);


    const handleSupplyChange =
        useCallback((index, colIdx, colRawName) => (e) => {
            try {
                e.persist();
            } catch (e) { }

            //get id of item == index
            //mappedColumns
            dispatch({ type: BULK_IMPORT_INPUT_CHANGE, payload: { id: index, column: colRawName, value: e.target.value } })

        }, [dispatch])

    //#region Drag and Drop
    const handleDragStart = (e) => {
        const { id } = e.target; //key name

        e.dataTransfer.setData("colIdx", id);
    };

    const handleDragOver = (e) => e.preventDefault();
    const handleDragEnter = (e) => {
        const { id } = e.target;
        setHover(parseInt(id));
        setDragOver(id);
    };

    const handleOnDrop = (e) => {
        setHover(-1);
        const { id } = e.target;
        if (!id) return false;
        const droppedColIdx = id;
        const draggedColIdx = e.dataTransfer.getData("colIdx");

        if (droppedColIdx !== draggedColIdx) {
            //swapProp(cols, draggedColIdx, droppedColIdx, "label");
            //setCols(cols);
            setDragOver("");
            const da = swap(supplies, draggedColIdx - 1, droppedColIdx - 1);
            for (const d of da) {
                if (!Number.isNaN(d[1]) && d[1] !== d[3]) continue;
                if (d[3] && d[4]) {
                    const p = Math.floor(d[3] * (1 - Math.abs(d[4]) / 100));
                    d[1] = p;
                }
            }
            setSupplies(da);
        }
    };

    //#endregion

    useEffect(() => {
        if (Object.keys(mappedColumns).length > 0 && supplies.length > 0) {
            setColumns(prepRows(supplies[0], mappedColumns));
        }
    }, [supplies, mappedColumns]);



    // const addStock = () => {
    //     setSupplies(prevSupplies => [...prevSupplies, generateBlankStock()])
    //     setFocusedIndex(supplies.length)
    //     if (bottomRef.current) setTimeout(() => bottomRef.current.scrollIntoView(), 0)
    // }

    const handleFocus = (event) => {
        if (event.target) event.target.select();
    }

    const deleteStock = useCallback((index) =>
        //TODO handle delete
        setSupplies((prevSupplies) => [
            ...prevSupplies.slice(0, index),
            ...prevSupplies.slice(index + 1),
        ]), [setSupplies]);
    const tableRef = useRef(null);
    const bottomRef = useRef(null);

    useOnClickOutside(tableRef, (event) => {
        setTimeout(() => {
            setFocusedIndex((-1), [setFocusedIndex])
        }, 0);
    });



    const prepRows = (data, mappedCols) => {

        //columns define what column is mapped to reference and price
        //object not array { Brand: "rolex", DTO: "50"}
        //mapping table from redux;
        const columns = new Map();

        // columns.set("item", {
        //     name: 'item',
        //     value: idx,
        //     type: "id",
        //     cell: ({ className, rowData }) => {
        //         return (
        //             <div className={className}>
        //                 {rowData.id}
        //             </div>
        //         );
        //     },
        // });

        columns.set("product", {
            name: 'productId',
            title: "Product",
            rawName: 'productId',
            type: "product",
            cell: ({ className, rowData }) => {
                const p = products[rowData.productId];
                const display = p ? `${p.SKU} - ${p.name}` : 'Product Not Found'
                return <div className={classNames(className, classes.productBox)}>{display}</div>
            },
        })

        const dynamicCols = new Map(Object.entries(data));
        for (let [k, v] of dynamicCols) {

            if (k !== "productId") {
                columns.set(k, { //item[0] is header 
                    title: k,
                    name: k,
                    type: "string"
                });
            }
        }

        let unionCols = [...columns.values()];
        return unionCols;
    };

    const handleClicking = (e) => {
        //e.preventDefault();
    };


    return (
        <Paper className={`${className} ${classes.productTableLayout}`}>
            <div className={classes.dataTable}>
                {supplies.length > 0 && columns.length > 0 && <AutoSizer>
                    {({ width, height }) => (
                        <DataTable
                            data={supplies}
                            columns={columns}
                            height={height}
                            width={width}
                        //rowOnClick={handleSelectRow}
                        // isRowHighlight={({ rowData }) => {
                        //     if (
                        //         rowData &&
                        //         target &&
                        //         rowData.id === target.id
                        //     )
                        //         return styles.currentRow;
                        // }}
                        />
                    )}
                </AutoSizer>}
            </div>

            <div className={classes.tableFooter}>
                {/* <Button variant="contained" color="primary" onClick={addStock}>Add supply</Button>  */}
                {/* <p>Qty: <strong style={{ fontSize: '1.2rem' }}>{formatMoney(supplies.reduce((total, currentStock) => total + currentStock.quantity, 0), 0)}</strong></p> */}
                {/* <p>Total: <strong style={{ fontSize: '1.2rem' }}>{currency} {formatMoney(supplies.reduce((total, currentStock) => total + currentStock.quantity * currentStock.unitPrice, 0))}</strong></p> */}
            </div>
        </Paper>
    );
}

// function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
//   try {
//     decimalCount = Math.abs(decimalCount);
//     decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

//     const negativeSign = amount < 0 ? "-" : "";

//     let i = parseInt(
//       (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
//     ).toString();
//     let j = i.length > 3 ? i.length % 3 : 0;

//     return (
//       negativeSign +
//       (j ? i.substr(0, j) + thousands : "") +
//       i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
//       (decimalCount
//         ? decimal +
//           Math.abs(amount - i)
//             .toFixed(decimalCount)
//             .slice(2)
//         : "")
//     );
//   } catch (e) {
//   }
// }

function addDefaults(col) {
    if (!col) return [];
    const result = [
        {
            name: "first",
            label: col.item,
            draggable: false,
        },
        {
            name: "second",
            label: col.product,
            draggable: true,
        },
        {
            name: "third",
            label: col.price,
            draggable: true,
        },
        {
            name: "fourth",
            label: col.quantity,
            field: "quantity",
            draggable: true,
        },
        {
            name: "fifth",
            label: col.msrp,
            field: "retailPrice",
            draggable: true,
        },
        {
            name: "sixth",
            label: col.discount,
            draggable: true,
        },
    ];

    if (col.length > result.length) {
        col.slice(result.length).forEach(unknownColumn => {
            result.push({
                name: unknownColumn,
                label: unknownColumn,
                draggable: true
            })
        })
    }

    return result
}





function swap(arr, former, latter) {
    const swapped = arr.map((item) => {
        const temp = item[former];
        item[former] = item[latter];
        item[latter] = temp;

        return item;
    });

    return swapped;
}

function swapProp(arr, keyformer, keylatter, prop) {
    const temp = arr[keyformer][prop];
    try {
        arr[keyformer][prop] = arr[keylatter][prop];
        arr[keylatter][prop] = temp;
    }
    catch (e) {
    }

}

function isSupplyValid(row) {
    //if (!findModel(supply[0])) return 1;
    // if (row[columns] <= 0) return 1;
    // if (row.quantity.value <= 0) return 1;
    // if (row.product.value == null) return 1;

    //if (Numbesr.isNaN(row[1]) || !(row.price) || (!row[2])) return 1;

    return 0;
}
