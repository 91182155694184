import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, CircularProgress } from '@material-ui/core';
import { WebcamComponent } from '../webcam';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  "webcam-container": {
    margin: "0 1.5rem"
  },
  wrapper: {
    margin: 0,
    position: "relative"
  },
  buttonProgress: {
    color: "green",
    position: "absolute",
    top: "4px",
    left: "25px"
  },
  "cancel-btn": {
    marginRight: "0.5rem"
  }
})

/**
 * @param {object} prop
 * @param {(base64Image: string) => void} props.handleSubmit
 * @param {() => void} props.closeDialog 
 * @param {boolean} props.dialogOpen
 * @param {number} props.uploadProgress
 * @param {string} props.title
 * @param {boolean} [props.ableToCancel=true]
 * @param {({handleClose, handleSubmit, ableToCancel, image}) => JSX.Element} [props.renderActions]
 */
export const PhotoDialog = withStyles(styles)(class PhotoDialogComponent extends Component {
  state = {
    image: null
  }

  updateImage = (image) => {
    this.setState({ image });
  }

  submitImage = () => {
    if (this.state.image) {
      this.props.handleSubmit(this.state.image);
    }
    this.props.closeDialog()
  }

  closeDialog = () => {
    this.setState({ image: null });
    this.props.closeDialog();
  }

  render() {
    const { dialogOpen, uploadProgress, title, ableToCancel = true, renderActions, classes } = this.props;
    const { image } = this.state;

    return (
      <Dialog
        maxWidth={"xl"}
        fullWidth
        open={dialogOpen}
        className={classes['photo-dialog']}
      >
        <DialogTitle>Take a photo{title}</DialogTitle>
        <div className={classes['webcam-container']}>
          {dialogOpen && <WebcamComponent show={dialogOpen} updateImage={this.updateImage} />}
        </div>
        <DialogActions>
          {renderActions ? renderActions({ handleSubmit: this.submitImage, handleClose: this.closeDialog, ableToCancel, image }) : (
            <>
              {
                ableToCancel &&
                <div>
                  <Button className={classes['cancel-btn']} onClick={this.closeDialog} color='primary' variant='outlined' size='small'>
                    Cancel
            </Button>
                </div>
              }
              <div className={classes['wrapper']}>
                <Button disabled={!image} onClick={this.submitImage} color='primary' variant='contained' size='small'>
                  Upload
              </Button>
                {uploadProgress !== 100 && uploadProgress !== -1 && (<CircularProgress size={24} className={classes.buttonProgress} variant="determinate" value={uploadProgress} />)}
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    )
  }
})