import { createVibes } from "@cosmos/vibes";
import {
  LOAD_RESERVATION,
  LOAD_RESERVATION_SUCCESS,
  LOAD_RESERVATION_ERROR,
} from "../../actions";
import { reduce } from "lodash";
import moment from 'moment';

export const loadReservationVibes = createVibes({
  type: [LOAD_RESERVATION],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query {
            procurements  {
             id productId brandId price
             quantity expireAt remarks state
             userId createdAt updatedAt deletedAt
             reservation {
               id pic  reference state remarks
               createdAt updatedAt deletedAt userId
               fulfilment { id selectedSuggestion }
             }
           }
          }`,
        }
      });

      let data = result.data;

      if (data.procurements) {
        const { reservationStateList, procurementStateList } = getState().reservation;
        const { brands, products } = getState().catalog;

        let payload = {
          procurements: reduce(data.procurements, (r, d) => {
            r[d.id] = {
              ...d,
              brandName: brands[d.brandId] && brands[d.brandId].name,
              productName: products[d.productId] && products[d.productId].name,
              productReference: products[d.productId] && products[d.productId].reference,
              stateName: procurementStateList[d.state] && procurementStateList[d.state].name,
              createdAt: d.createdAt ? moment(d.createdAt).format("YYYY-MM-DD") : null,
              expireAt: d.expireAt ? moment(d.expireAt).format("YYYY-MM-DD") : null,
              reservationPic: d.reservation && d.reservation.pic,
              reservationReference: d.reservation && d.reservation.reference,
              reservationState: d.reservation && d.reservation.state,
              reservationStateName: d.reservation && reservationStateList[d.reservation.state] && reservationStateList[d.reservation.state].name,
            }
            return r
          }, {}),
          filteredProcurements: data.procurements.map(el => el.id)
        }

        dispatch({ type: LOAD_RESERVATION_SUCCESS, payload });
      }
      else {
        dispatch({ type: LOAD_RESERVATION_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to load reservation' } });
      }
      done();

    } catch (err) {
      dispatch({ type: LOAD_RESERVATION_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to load reservation' } });
      done();
    }
  }
});
