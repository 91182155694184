import React from "react";
import Fab from "@material-ui/core/Fab";
import { FilterVariant } from "mdi-material-ui";
import Grow from "@material-ui/core/Grow";
import { BrandFilter } from "./brand";
import { HolderFilter } from "./holder";
import { DateRangeFilter } from "./dateRange";
import { ProvisionFilter } from "./provisionFilter";
import { ConditionFilter } from "./conditionFilter";
import { TypeFilter } from "./type";
import { StatusFilter } from "./status";
import { PersonFilter } from "./person";
import { SupplierFilter } from "./supplierFilter";
import { CountryFilter } from "./country";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  root: {
    marginRight: "0.5em",
    display: "flex",
    alignItems: "center"
  },
  "filter-bar": {
    marginRight: "0.5em",
    backgroundColor: "rgba(255,255,255,0.15)",
    padding: "0 0.5em",
    borderRadius: "0.5em",
    display: "flex"
  },
  "filter-control": {
        "&.active": {
            color: "#fff"
        }
    }
});

const filterUnits = {
  holder: HolderFilter,
  brand: BrandFilter,
  dateRange: DateRangeFilter,
  provision: ProvisionFilter,
  condition: ConditionFilter,
  supplier: SupplierFilter,
  type: TypeFilter,
  status: StatusFilter,
  pic: PersonFilter,
  country: CountryFilter
};

export class FilterBarComponent extends React.Component {
  constructor(props) {
    super(props);
    if (props.defaultOpen) {
      this.state.open = true;
    }
  }

  state = {
    open: false
  };

  toggleFilter = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render() {
    const { units, classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.open && (
          <Grow appear in={true} timeout={300}>
            <div className={classes["filter-bar"]}>
              {Object.keys(units).map(unit => {
                const Unit = filterUnits[unit];
                return <Unit key={unit} {...units[unit]} />;
              })}
            </div>
          </Grow>
        )}
        <Fab
          className={`${classes["filter-control"]} ${this.state.open && "active"}`}
          color="primary"
          size="small"
          onClick={this.toggleFilter}
        >
          <FilterVariant />
        </Fab>
      </div>
    );
  }
}

export const FilterBar = withStyles(styles)(FilterBarComponent);
