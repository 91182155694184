import React from 'react';
import { Component } from 'react';

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { Delete } from "mdi-material-ui";
import { SmartBox } from '@cosmos/chaos';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  fulfilment: {
    padding: "0.5 rem",
    border: "solid 1 px lightgrey",
    borderRadius: "0.5 rem",
    margin: "0.5 rem 0"
  },
  "flex-row": {
      display: "flex",
      flexDirection: "row",
      marginBottom: "0.25 rem"
  },
  field: {
      flex: 1
  },
  "fulfilment-dustbin": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
  }
})

class FulfilmentComponent_ extends Component {

  render() {
    const {
      fulfilment, idx, fidx, suggestionOptions, users, updateFFField, deleteFulfilment,
      // selectedSuggestions, 
      addSelectedSuggestions, removeSelectedSuggestions, updateFulfilment,
      canUpdateFulfilment, canUpdateTender, tenderDetailId, classes
    } = this.props;
    return (
      <div className={classes['fulfilment']}>
        <div className={classes['item']}>
          <div className={classes['flex-row']}>
            <TextField
              id="reference" label="Reference"
              value={fulfilment.reference ? fulfilment.reference : ''}
              className={classes['field']}
              InputLabelProps={{ shrink: true }}
              InputProps={{ disabled: true }}
            />
            <TextField
              id="type" label="Type"
              value={fulfilment.type ? fulfilment.type : ''}
              className={classes['field']}
              InputLabelProps={{ shrink: true }}
              InputProps={{ disabled: true }}
            />
            <TextField
              id="state" label="State"
              value={fulfilment.state ? fulfilment.state : ''}
              className={classes['field']}
              InputLabelProps={{ shrink: true }}
              InputProps={{ disabled: true }}
            />
          </div>
          <div className={classes['flex-row']}>
            <FormControl className={classes['field']}>
              <InputLabel shrink> Person In Charge </InputLabel>
              <Select
                value={fulfilment.pic ? fulfilment.pic : ''}
                onChange={(e) => { updateFFField({ index: idx, fulfilmentIndex: fidx, payload: { pic: e.target.value } }); }}
                onBlur={e => {
                  if (canUpdateFulfilment) {
                    updateFulfilment({ fulfilmentIdx: fidx, tenderDetailIdx: idx });
                  }
                }}
                name="pic" readOnly={!canUpdateFulfilment}
              >
                {Object.values(users).map((el, index) => (
                  <MenuItem value={el.id} key={`user_${index}`}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="remarks" label="Remarks"
              value={fulfilment.remarks ? fulfilment.remarks.comments : ''}
              className={classes['field']}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => { updateFFField({ index: idx, fulfilmentIndex: fidx, payload: { remarks: { comments: e.target.value } } }); }}
              onBlur={e => {
                if (canUpdateFulfilment) {
                  updateFulfilment({ fulfilmentIdx: fidx, tenderDetailIdx: idx });
                }
              }}
              inputProps={{ readOnly: !canUpdateFulfilment, disabled: !canUpdateFulfilment }}
            />
          </div>
          <div className={classes['flex-row']}>
            {
              canUpdateFulfilment ?
                (
                  <div className={classes['field']}>
                    <InputLabel shrink>Suggestions</InputLabel>
                    <SmartBox
                      suggestionProvider={val => {
                        // let filteredOptions = suggestionOptions.filter(el => { return selectedSuggestions.indexOf(el.id) === -1 });
                        let filteredOptions = suggestionOptions;
                        let count = 0;
                        let result = filteredOptions && filteredOptions.filter(el => {
                          const keep = el.name.toUpperCase().replace(/[ .-/]/g, '').includes(val.toUpperCase().replace(/[ .-/]/g, '')) && count < 10;
                          if (keep) {
                            count += 1;
                          }
                          return keep;
                        }).map(el => ({ label: el.name, value: el.id, ...el }));
                        return result;
                      }}
                      searchText={fulfilment.selectedSuggestion ? fulfilment.selectedSuggestion.name : ''}
                      updateSearchText={val => {
                        if (fulfilment.selectedSuggestion && fulfilment.selectedSuggestion.id) {
                          removeSelectedSuggestions(fulfilment.selectedSuggestion.id);
                        }
                        if (typeof val === 'object') {
                          addSelectedSuggestions(val.id);
                          updateFFField({ index: idx, fulfilmentIndex: fidx, payload: { selectedSuggestion: val } });
                        }
                        else {
                          updateFFField({ index: idx, fulfilmentIndex: fidx, payload: { selectedSuggestion: { name: val } } });
                        }
                      }}
                      updateSelectedItem={val => {
                        updateFFField({ index: idx, fulfilmentIndex: fidx, payload: { selectedSuggestion: val } });
                      }}
                      onOuterClick={() => { updateFulfilment({ fulfilmentIdx: fidx, tenderDetailIdx: idx }); }}
                    />
                  </div>
                )
                :
                (
                  <TextField
                    id="selectedSuggestion" label="Suggestion"
                    value={fulfilment.selectedSuggestion ? fulfilment.selectedSuggestion.name : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true, disabled: true }}
                  />
                )
            }
          </div>
          <div className={classes['fulfilment-dustbin']}>
            <IconButton onClick={e => { deleteFulfilment({ tenderDetailIdx: idx, fulfilmentIdx: fidx, fulfilmentId: fulfilment.id }) }} disabled={!canUpdateTender || !tenderDetailId}>
              <Delete fontSize='small' color='secondary' />
            </IconButton>
          </div>

        </div>
      </div>
    )
  }
}
export const FulfilmentComponent = withStyles(styles)(FulfilmentComponent_);