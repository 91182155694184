import {
  DF_NOTICE_DISMISS, DF_UPDATE_FIELD, DF_UPDATE_INNER_FIELD, DF_DISABLE_PROCESS,
  DF_SCAN_CART_LABEL_FAIL, DF_ADD_CART_LABEL, DF_PROCESS_CART, DF_UPDATE_CART_FIELD,
  DF_UPDATE_CART_INNER_FIELD, DF_ADD_ITEM_FAIL, UPDATE_DF_LABEL_LIST, DF_ADD_ITEM_SUCCESS,
  OPEN_DF_RESERVED_DIALOG, CLOSE_DF_RESERVED_DIALOG, CANCEL_DF_RESERVED_DIALOG,
  TOGGLE_DF_BIRTH_DIALOG, UPDATE_BIRTH_FIELD, DF_CLEAR_BIRTH, UPDATE_DF_CARD_PROGRESS,
  DF_CARD_PROGRESS_COMPLETED, UPLOADED_DF_WARRANTY_PHOTO, DF_POS_WARRANTY_DIALOG,
  OPEN_DF_WARRANTY_NOTICE, UPDATE_DF_PRICE, DF_REMOVE_ITEM_SUCCESS, DF_REMOVE_ITEM_ERROR,
  SELECT_DF_ITEM, SUBMIT_DF_ITEMS_FAIL, SUBMIT_DF_ITEMS_SUCCESS, OPEN_SAM_DIALOG,
  CLOSE_SAM_DIALOG, SCANNED_MRZ_FAIL, SCANNED_MRZ_SUCCESS,
  DF_OPEN_RESERVE_NOTICE, TOGGLE_BLANK_DIALOG, PRINT_DF_BLANK_FORM_ERROR,
  DF_TOGGLE_PASSPORT_DIALOG, UPLOADED_DF_PASSPORT_PHOTO,
  DF_SHOW_TEMP_IMG, OPEN_DF_NOTICE, DF_CLOSE_TEMP_DIALOG,
  DF_TOGGLE_VISA_DIALOG, UPLOADED_DF_VISA_PHOTO, DF_SCAN_CART_LABEL_SUCCESS,
  TOGGLE_CDP_DIALOG, UPLOADED_CDP_PHOTO, SUBMIT_CDP_FAIL, PAYMENT_FAILED, PAYMENT_SUCCEED,
} from '../actions';
import * as shortId from "shortid";
import moment from "moment";

const initialState = {
  notify: false,
  noticeType: 'info',
  notice: '',
  residenceStatus: [
    "短期滞在", "留学", "就学", "興行", "文化活動",
  ],
  orderInput: {
    documentType: 'Passport',
    once: shortId.generate(),
  },
  disableProcess: true,
  processCart: false,
  itemCode: '',
  labels: [],
  goods: [],
  prices: [],
  cartInput: {
    once: shortId.generate(),
    discountRate: 0,
    taxRate: 0,
    date: moment().format('YYYY-MM-DD'),
    type: 34,
  },
  selected: -1,
  reservedDialog: false,
  reservedFor: '',
  reservation: '',
  birthDialog: false,
  captureCardFront: false,
  captureCardBack: false,
  uploadProgress: -1,
  samDialog: false,
  blankFormDialog: false,
  passportDialog: false,
  visaDialog: false,
  proformaCartId: null,
  tempUri: null,
  tempTitle: null,
  tempDialog: false,
  cdpDialog: false,
}

export const dutyFreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case DF_NOTICE_DISMISS:
      return { ...state, notify: false, noticeType: 'info', notice: '', }
    case DF_UPDATE_FIELD:
      return { ...state, orderInput: { ...state.orderInput, ...action.payload } }
    case DF_UPDATE_INNER_FIELD:
      return { ...state, orderInput: { ...state.orderInput, [action.payload.outerField]: { ...state.orderInput[action.payload.outerField], [action.payload.innerField]: action.payload.value } } }
    case DF_UPDATE_CART_FIELD:
      return { ...state, cartInput: { ...state.cartInput, ...action.payload } }
    case DF_UPDATE_CART_INNER_FIELD:
      return { ...state, cartInput: { ...state.cartInput, [action.payload.outerField]: { ...state.cartInput[action.payload.outerField], [action.payload.innerField]: action.payload.value } } }
    case DF_DISABLE_PROCESS:
    case DF_SCAN_CART_LABEL_FAIL:
    case DF_ADD_ITEM_FAIL:
    case OPEN_DF_RESERVED_DIALOG:
    case DF_ADD_ITEM_SUCCESS:
    case UPDATE_BIRTH_FIELD:
    case DF_POS_WARRANTY_DIALOG:
    case UPDATE_DF_CARD_PROGRESS:
    case DF_CARD_PROGRESS_COMPLETED:
    case UPLOADED_DF_WARRANTY_PHOTO:
    case OPEN_DF_WARRANTY_NOTICE:
    case DF_REMOVE_ITEM_SUCCESS:
    case DF_REMOVE_ITEM_ERROR:
    case SELECT_DF_ITEM:
    case OPEN_SAM_DIALOG:
    case CLOSE_SAM_DIALOG:
    case SCANNED_MRZ_FAIL:
    case SCANNED_MRZ_SUCCESS:
    case DF_ADD_CART_LABEL:
    case DF_OPEN_RESERVE_NOTICE:
    case TOGGLE_BLANK_DIALOG:
    case PRINT_DF_BLANK_FORM_ERROR:
    case DF_TOGGLE_PASSPORT_DIALOG:
    case DF_TOGGLE_VISA_DIALOG:
    case UPLOADED_DF_PASSPORT_PHOTO:
    case UPLOADED_DF_VISA_PHOTO:
    case DF_SHOW_TEMP_IMG:
    case OPEN_DF_NOTICE:
    case DF_CLOSE_TEMP_DIALOG:
    case DF_SCAN_CART_LABEL_SUCCESS:
    case TOGGLE_CDP_DIALOG:
    case UPLOADED_CDP_PHOTO:
    case SUBMIT_CDP_FAIL: 
    case PAYMENT_FAILED:
    case PAYMENT_SUCCEED:
      return { ...state, ...action.payload }
    case DF_PROCESS_CART:
      return { ...state, processCart: true }
    case UPDATE_DF_LABEL_LIST:
      return { ...state, labels: [action.payload, ...state.labels] };
    case CANCEL_DF_RESERVED_DIALOG:
      return {
        ...state,
        reservedDialog: false, reservedFor: '', reservation: '',
        goods: [{ ...state.goods[0], reservation: null }, ...state.goods.slice(1)],
      }
    case CLOSE_DF_RESERVED_DIALOG:
      return {
        ...state, reservedDialog: false, reservedFor: '', reservation: '',
      };
    case TOGGLE_DF_BIRTH_DIALOG:
      return { ...state, birthDialog: !state.birthDialog };
    case DF_CLEAR_BIRTH:
      return {
        ...state,
        goods: [{ ...state.goods[0], birthYear: null, birthMonth: null, birthDate: null }, ...state.goods.slice(1)],
      }
    case UPDATE_DF_PRICE:
      const idx = state.goods.findIndex(el => { return el.id === action.payload.dataId });
      return {
        ...state,
        selected: idx,
        prices: [...state.prices.slice(0, idx), { ...state.prices[idx], priceAfterDisc: action.payload.newValue }, ...state.prices.slice(idx + 1)],
      }
    case SUBMIT_DF_ITEMS_FAIL:
      return {
        ...state, ...action.payload,
        orderInput: {
          ...state.orderInput, once: shortId.generate(),
        },
        cartInput: {
          ...state.cartInput, once: shortId.generate(),
        },
      }
    case SUBMIT_DF_ITEMS_SUCCESS:
      return {
        ...state, ...action.payload,
        orderInput: {
          documentType: 'Passport',
          once: shortId.generate(),
        },
        disableProcess: true,
        processCart: false,
        itemCode: '',
        labels: [],
        goods: [],
        prices: [],
        cartInput: {
          once: shortId.generate(),
          discountRate: 0,
          taxRate: 0,
          date: moment().format('YYYY-MM-DD'),
          type: 34,
        },
        selected: -1,
        reservedDialog: false,
        reservedFor: '',
        reservation: '',
        birthDialog: false,
        captureCardFront: false,
        captureCardBack: false,
        uploadProgress: -1,
        samDialog: false,
        blankFormDialog: false,
        passportDialog: false,
        visaDialog: false,
        proformaCartId: null,
        tempUri: null,
        tempTitle: null,
        tempDialog: false,
        cdpDialog: false,
      }
    default:
      return state
  }
}