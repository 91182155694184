export const UPDATE_PO_INPUT = '@@purchase/UPDATE_PO_INPUT';

export const CREATE_PURCHASE_DETAIL = '@@purchase/CREATE_PURCHASE_DETAIL';
export const REMOVE_PURCHASE_DETAIL = '@@purchase/REMOVE_PURCHASE_DETAIL';
export const PURCHASE_DETAIL_REMOVED = '@@purchase/PURCHASE_DETAIL_REMOVED';
export const UPDATE_DELETED_DETAILS = '@@purchase/UPDATE_DELETED_DETAILS';

export const UPDATE_PD_INPUT = '@@purchase/UPDATE_PD_INPUT';
export const UPDATE_PD_REMARKS = '@@purchase/UPDATE_PD_REMARKS';
export const UPDATE_PD_PACKAGE_REMARKS = '@@purchase/UPDATE_PD_PACKAGE_REMARKS'
export const POPULATE_DISC_RATE = '@@purchase/POPULATE_DISC_RATE';
export const SELECT_PURCHASE_DETAIL = '@@purchase/SELECT_PURCHASE_DETAIL';

export const CLOSE_STOCK_IN_NOTICE = '@@purchase/CLOSE_STOCK_IN_NOTICE';
export const OPEN_STOCK_IN_NOTICE = '@@purchase/OPEN_STOCK_IN_NOTICE';

export const CLOSE_PURCHASE_NOTICE = '@@purchase/CLOSE_PURCHASE_NOTICE';
export const OPEN_PURCHASE_NOTICE = '@@purchase/OPEN_PURCHASE_NOTICE';
export const UPDATE_PURCHASE_ORDER_ERROR = '@@purchase/UPDATE_PURCHASE_ORDER_ERROR';

export const BEGIN_STOCK_IN = '@@purchase/BEGIN_STOCK_IN';
export const ADD_NEW_STOCK = '@@purchase/ADD_NEW_STOCK';
export const ADD_NEXT_STOCK = '@@purchase/ADD_NEXT_STOCK';
export const UPDATE_STOCK_INPUT = '@@purchase/UPDATE_STOCK_INPUT';
export const SELECT_STOCK_INPUT = '@@purchase/SELECT_STOCK_INPUT';
export const REMOVE_STOCK_INPUT = '@@purchase/REMOVE_STOCK_INPUT';
export const EDIT_STOCK_INFO = '@@purchase/EDIT_STOCK_INFO';
export const EDIT_STOCK_IMG = '@@purchase/EDIT_STOCK_IMG';

export const CLOSE_STAGE_1 = '@@purchase/CLOSE_STAGE_1';
export const VERIFIED_STAGE_1 = '@@purchase/VERIFIED_STAGE_1';
export const VERIFIED_STAGE_2 = '@@purchase/VERIFIED_STAGE_2';
export const VERIFIED_STAGE_3 = '@@purchase/VERIFIED_STAGE_3';
export const VERIFIED_STAGE_4 = '@@purchase/VERIFIED_STAGE_4';
export const VERIFIED_STAGE_5 = '@@purchase/VERIFIED_STAGE_5';

export const VERIFY_ITEM_LABEL = '@@purchase/VERIFY_ITEM_LABEL';
export const SUBMIT_STOCK_INFO = '@@purchase/SUBMIT_STOCK_INFO';
export const SUBMIT_WATCH_FACE = '@@purchase/SUBMIT_WATCH_FACE';
export const SUBMIT_CARD_FRONT = '@@purchase/SUBMIT_CARD_FRONT';
export const SUBMIT_CARD_BACK = '@@purchase/SUBMIT_CARD_BACK';
export const SUBMIT_STOCKS = '@@purchase/SUBMIT_STOCKS';
export const SUBMIT_STOCKS_ERROR = '@@purchase/SUBMIT_STOCKS_ERROR';

export const INIT_PURCHASE_LOAD = '@@purchase/INIT_PURCHASE_LOAD';
export const LOAD_PURCHASE_SUCCESS = '@@purchase/LOAD_PURCHASE_SUCCESS';
export const LOAD_PURCHASE_ERROR = '@@purchase/LOAD_PURCHASE_ERROR';

export const CREATE_PURCHASE_ORDER_BEGIN = '@@purchase/CREATE_PURCHASE_ORDER_BEGIN';
export const CREATE_PURCHASE_ORDER_SUCCESS = '@@purchase/CREATE_PURCHASE_ORDER_SUCCESS';
export const CREATE_PURCHASE_ORDER_ERROR = '@@purchase/CREATE_PURCHASE_ORDER_ERROR';
export const BEGIN_CREATE_PURCHASE_STOCK = '@@purchase/BEGIN_CREATE_PURCHASE_STOCK';

export const UPDATE_SELECTED_PURCHASE = '@@purchase/UPDATE_SELECTED_PURCHASE';
export const RESET_PURCHASE_FORM = '@@purchase/RESET_PURCHASE_FORM';

export const ASK_INIT_PURCHASE = '@@purchase/ASK_INIT_PURCHASE';
export const CANCEL_INIT_PURCHASE = '@@purchase/CANCEL_INIT_PURCHASE';
export const CONFIRM_INIT_PURCHASE = '@@purchase/CONFIRM_INIT_PURCHASE';

export const ASK_INIT_STOCK_IN = '@@purchase/ASK_INIT_STOCK_IN';
export const CANCEL_INIT_STOCK_IN = '@@purchase/CANCEL_INIT_STOCK_IN';
export const CONFIRM_INIT_STOCK_IN = '@@purchase/CONFIRM_INIT_STOCK_IN';

export const REVIEW_PURCHASE = '@@purchase/REVIEW_PURCHASE';
export const CLOSE_REVIEW_PURCHASE = '@@purchase/CLOSE_REVIEW_PURCHASE';
export const EDIT_PURCHASE = '@@purchase/EDIT_PURCHASE';
export const SWAP_MODE = '@@purchase/SWAP_MODE';

export const SAVE_PURCHASE = '@@purchase/SAVE_PURCHASE';
export const UPDATE_PURCHASE_THEN_STOCKIN = '@@purchase/UPDATE_PURCHASE_THEN_STOCKIN';
export const RELOAD_PURCHASE = '@@purchase/RELOAD_PURCHASE';
export const UPDATE_FILTERED_PURCHASES = '@@purchase/UPDATE_FILTERED_PURCHASES';

export const UPDATE_SEARCH_BAR = '@@purchase/UPDATE_SEARCH_BAR';
export const TO_FILTER_PURCHASE = '@@purchase/TO_FILTER_PURCHASE';
export const UPDATE_SELECTED_STATUS = '@@purchase/UPDATE_SELECTED_STATUS';
export const UPDATE_SELECTED_TYPE = '@@purchase/UPDATE_SELECTED_TYPE';
export const UPDATE_SELECTED_RANGE = '@@purchase/UPDATE_SELECTED_RANGE';

export const RESET_STOCK_IN_FORM = '@@purchase/RESET_STOCK_IN_FORM';  

export const TOGGLE_INVOICE_DIALOG = '@@purchase/TOGGLE_INVOICE_DIALOG';  
export const SUBMIT_INVOICE_PHOTO = '@@purchase/SUBMIT_INVOICE_PHOTO';  
export const UPLOADED_INVOICE_PHOTO = '@@purchase/UPLOADED_INVOICE_PHOTO';  
export const UPDATE_PROGRESS = '@@purchase/UPDATE_PROGRESS';  
export const PROGRESS_COMPLETED = '@@purchase/PROGRESS_COMPLETED';  

export const UPDATE_PO_FORM_DISABLE_BOOL= '@@purhcase/UPDATE_PO_FORM_DISABLE_BOOL';
export const UPDATE_STOCK_IN_DISABLE= '@@purhcase/UPDATE_STOCK_IN_DISABLE';
export const FILTER_PURCHASES = '@@purhcase/FILTER_PURCHASES';

export const CREATE_VENDOR = '@@purhcase/CREATE_VENDOR';
export const ASK_CREATE_VENDOR = '@@purhcase/ASK_CREATE_VENDOR';
export const CLOSE_CREATE_VENDOR = '@@purhcase/CLOSE_CREATE_VENDOR';
export const CREATE_VENDOR_SUCCESS = '@@purhcase/CREATE_VENDOR_SUCCESS';
export const CREATE_VENDOR_FAIL = '@@purhcase/CREATE_VENDOR_FAIL';

export const UPDATE_PC_DATA_URI = '@@purhcase/UPDATE_PC_DATA_URI';
export const LOAD_IMAGE_TAKEN_SUCCESS = '@@purhcase/LOAD_IMAGE_TAKEN_SUCCESS';

export const OPEN_PO_STOCK_IN_DIALOG = '@@purchase/OPEN_PO_STOCK_IN_DIALOG';
export const CLOSE_PO_STOCK_IN_DIALOG = '@@purchase/CLOSE_PO_STOCK_IN_DIALOG';
export const RETRIEVE_PO_STOCKS = '@@purchase/RETRIEVE_PO_STOCKS';
export const RETRIEVE_PO_STOCKS_FAIL = '@@purchase/RETRIEVE_PO_STOCKS_FAIL';
export const UPDATE_PO_STOCK_INPUT = '@@purchase/UPDATE_PO_STOCK_INPUT';


