import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
    container: {
        border: '1px solid #ccc',
        padding: '1em',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
    },
    strongText: {
        fontSize: '1.1rem'
    },
})

export default function MatchCard({ purchaseId, product, quantity, quantityExpected, unitPrice, discountRate, eligibleStocks, selectedStockIds, handleSelect }) {
    const { products, brands } = useSelector(state => ({ products: state.catalog.products, brands: state.catalog.brands }))

    const selectedProduct = {
        product: products[product],
        name: products[product] ? products[product].name : null,
        sku: products[product] ? products[product].SKU : null
    }
    selectedProduct.brand = selectedProduct.product && brands[selectedProduct.product.brandID] ? brands[selectedProduct.product.brandID].name : null

    const classes = useStyles()
    return (
        <div className={classes.container}>
            <div>
                <LabelValue label={"ID"} value={purchaseId} />
                <Typography style={{ marginBottom: '0.4em' }}><span>Model: </span> <strong>{selectedProduct.brand}</strong> - {selectedProduct.sku} <small><em>{selectedProduct.name}</em></small></Typography>
                <LabelValue label={"Qty"} value={quantity} />
                <LabelValue label={"Qty Expected"} value={quantityExpected} />
                <LabelValue label={"Unit Price"} value={unitPrice} />
                <LabelValue label={"Discount Rate"} value={discountRate} />
            </div>
            <div>
                {eligibleStocks.length === 0 ?
                    <p style={{ textAlign: 'center' }}><em>No matching stocks available</em></p> :
                    eligibleStocks.map(stock => (
                        <StockCard
                            purchaseId={purchaseId}
                            isSelected={selectedStockIds.includes(stock.id)}
                            onChange={() => handleSelect(stock.id, purchaseId, selectedStockIds.includes(stock.id))}
                            id={stock.id}
                            stockId={stock.stockId}
                            productId={stock.productId}
                            key={stock.id}
                        />
                    ))}
            </div>
        </div>
    )
}

const useStockCardStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    checkbox: {
        display: 'none',
        '& + label': {
            backgroundColor: '#fafafa',
            border: '1px solid #cacece',
            padding: '9px',
            width: '60px',
            borderRadius: '0.3rem 0 0 0.3rem',
            display: 'inline-block',
            position: 'relative',
            '&:hover': {
                cursor: 'pointer'
            }
        },
        '&:checked + label': {
            background: '#bada55',
            border: '3px solid #bbb'
        }
    },
    stockLabel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '0.8em 1em',
        border: '1px solid #ccc',
        '* > *': {
            margin: '0 1em important!'
        }
    }
})

function StockCard({ isSelected, onChange, id, stockId, productId, purchaseId }) {
    const { products, brands } = useSelector(state => ({ products: state.catalog.products, brands: state.catalog.brands }))

    const selectedProduct = {
        product: products[productId],
        name: products[productId] ? products[productId].name : null,
        sku: products[productId] ? products[productId].SKU : null
    }
    selectedProduct.brand = selectedProduct.product && brands[selectedProduct.product.brandID] ? brands[selectedProduct.product.brandID].name : null

    const classes = useStockCardStyles()
    return (
        <div className={classes.root}>
            <input
                id={`${id}-${purchaseId}`}
                type="checkbox"
                checked={isSelected}
                onChange={onChange}
                color="primary"
                className={classes.checkbox}
            />
            <label htmlFor={`${id}-${purchaseId}`}></label>
            <div className={classes.stockLabel}>
                <LabelValue label={"Stock ID"} value={stockId} />
                <Typography><strong>Model: </strong> <strong>{selectedProduct.brand}</strong> - {selectedProduct.sku} <small><em>{selectedProduct.name}</em></small></Typography>
            </div>
        </div>
    )
}

function LabelValue({ label, value }) {
    return <p style={{ margin: 0, marginBottom: '0.5rem' }}>{label}: <strong style={{ fontSize: '1.1rem' }}>{value}</strong></p>
}
