import React from 'react';
import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Checkbox,
  TextField,
} from '@material-ui/core';
import IndexCell from './indexCell';
import NumberFormat from "react-number-format";
import SelectProduct from './SelectProduct'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const styles = {
  noBorder: {
    '& *': {
      borderBottom: 'none'
    }
  },
  noPadding: {
    padding: '0'
  }
}

const PACKAGE_REMARKS = ['warrantyCard', 'box', 'cardCase', 'manual', 'tag']

export class DetailRowComponent_ extends Component {
  updatePDInputField = (value) => {
    this.props.updatePDField(value);
  }

  updatePdRemarksField = (value) => {
    this.props.updatePdRemarks(value);
  }

  updatePdPackageRemarksField = (value) => {
    this.props.updatePdPackageRemarks(value);
  }

  render() {
    const { product, idx, handleDeleteRow, products, handleSelectRow, readOnly, conditionOptions, classes, type } = this.props;
    const shouldFixQuantity = ['CONSIGNMENT', 'USED GOODS'].includes(type)
    const conditionRow = <TableRow onClick={readOnly ? () => { } : handleSelectRow} className={classes.noPadding}>
      <TableCell colSpan={7}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <RadioGroup
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
            name="condition"
            value={String(product.remarks && product.remarks.condition)}
            onChange={(e) => { this.updatePdRemarksField({ index: idx, payload: { condition: parseInt(e.target.value) } }); }}
          >
            {Object.values(conditionOptions).map(c => {
              return (
                <Tooltip key={c.label} title={c.label} placement="bottom">
                  <FormControlLabel value={String(c.value)}
                    control={<Radio disabled={readOnly} size="small" style={{padding: 0}} />}
                    label={<small>{c.shortForm}</small>} />
                </Tooltip>
              )
            })}
          </RadioGroup>
          <div
            name="package"
            style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
          >
            {PACKAGE_REMARKS.map(condition => (
              <Tooltip key={condition} title={splitCamelCase(condition)} placement="bottom">
                <FormControlLabel label={<small>{condition[0].toUpperCase()}</small>}
                  control={
                    <Checkbox size="small"
                      style={{padding: 0}}
                      checked={product.remarks && product.remarks.package && product.remarks.package[condition] !== undefined ? product.remarks.package[condition] : false} value={condition} disabled={readOnly}
                      onChange={(e) => { this.updatePdPackageRemarksField({ index: idx, payload: { package: { [condition]: e.target.checked } } }) }} />
                  }
                />
              </Tooltip>
            ))}
          </div>
          <div>
            <TextField
              size="small"
              label={"Serial"}
              value={product.remarks && product.remarks.serial ? product.remarks.serial : ''}
              onChange={(e) => { this.updatePdRemarksField({ index: idx, payload: { serial: e.target.value } }); }}
              inputProps={{ readOnly: readOnly, disabled: readOnly }}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
    return (
      <>
        <TableRow
          className={classes.noBorder}
          onClick={readOnly ? () => { } : handleSelectRow}
        >
          <TableCell>
            <IndexCell
              index={idx}
              handleDeleteRow={handleDeleteRow}
              readOnly={readOnly}
            />
          </TableCell>
          <TableCell>
            <SelectProduct
              readOnly={readOnly}
              selectedProduct={product}
              products={products}
              idx={idx}
              label="Products"
              updatePDInputField={this.updatePDInputField}
            />
          </TableCell>
          <TableCell>
            <TextField
              type="number"
              disabled={shouldFixQuantity}
              value={!product.quantityExpected ? '' : product.quantityExpected}
              onChange={(e) => { this.updatePDInputField({ index: idx, payload: { quantityExpected: parseInt(e.target.value) } }); }}
              inputProps={{ disabled: readOnly || shouldFixQuantity }}
              label={"Quantity"}
            />
          </TableCell>
          <TableCell>
            <NumberFormat
              label="Price"
              value={product.unitPrice  ? product.unitPrice : ''}
              customInput={TextField}
              decimalScale={0}
              fixedDecimalScale
              thousandSeparator={true}
              onValueChange={(value, e) => {
                this.updatePDInputField({ index: idx, payload: { unitPrice: value.floatValue } });
              }}
            />
          </TableCell>
          <TableCell>
            <NumberFormat
              label="Tax"
              value={product.taxRate ? product.taxRate : ''}
              customInput={TextField}
              decimalScale={0}
              fixedDecimalScale
              thousandSeparator={true}
              suffix={'%'}
              onValueChange={(value, e) => {
                this.updatePDInputField({ index: idx, payload: { taxRate: value.floatValue } });
              }}
              inputProps={{ readOnly: readOnly, disabled: readOnly }}
            />
          </TableCell>
          <TableCell>
            <NumberFormat
              label="Discount"
              value={product.discountRate ? product.discountRate : ''}
              customInput={TextField}
              decimalScale={0}
              suffix={'%'}
              onValueChange={(value, e) => {
                this.updatePDInputField({ index: idx, payload: { discountRate: value.floatValue } });
              }}
              inputProps={{ readOnly: readOnly, disabled: readOnly }}
            />
          </TableCell>
          <TableCell>
            <NumberFormat
              label="Amount"
              value={product.unitPrice && product.quantityExpected ? product.unitPrice * product.quantityExpected : ''}
              customInput={TextField}
              decimalScale={0}
              fixedDecimalScale
              thousandSeparator={true}
              inputProps={{ readOnly: true, disabled: true }}
              onValueChange={(value, e) => {
                this.updatePDInputField({ index: idx, payload: { amount: value.floatValue } });
              }}
            />
          </TableCell>
        </TableRow>
        {product.quantityExpected <= 1 && conditionRow}
      </>
    )
  }
}
export const DetailRowComponent = withStyles(styles)(DetailRowComponent_)

function splitCamelCase(string) {
  return string.replace(/([A-Z])/g, ' $1')
    .replace(/^./, function (str) { return str.toUpperCase(); })
}