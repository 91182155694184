import React from 'react';
import { Component } from 'react';
import {
  IconButton,
  TextField,
  InputLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import { ChevronUpCircle, ChevronDownCircle, Delete, Plus } from "mdi-material-ui";
import { SmartBox } from '@cosmos/chaos';
import NumberFormat from "react-number-format";
import classNames from 'classnames';
import { FulfilmentComponent } from '../components';

const styles = theme => ({
  "drawer-root": {
    flex: 1,
    display: "flex",
    overflowY: "scroll"
  },
  "drawer-content": {
    padding: "0.75rem",
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  "btn-row": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  field: {
    flex: 1
  },
  container: {
    flex: 1
  },
  "flex-row": {
    display: "flex",
    flexDirection: "row",
    marginBottom: "0.25rem"
  },
  "fulfilments": {
    display: "none",
    "&.expanded": {
      display: "block"
    }
  },
  "add-btn": {
    margin:" 0.5rem 0"
  },
  "detail": {
    padding: "0.5rem",
    border: "solid 1px lightgrey",
    borderRadius: "0.5rem",
    marginBottom: "1rem",
    "&.selected": {
      backgroundColor: "darken(white, 5)"
    }
  } 
})

class TenderDetailComponent_ extends Component {
  render() {
    const {
      classes, selectedTdIdx, users, products, 
      // currencySymbol, 
      idx, td,
      updateTDField, selectDetail, handleDeleteRow, toggleFulfilments,
      updateFFField, addFulfilment, deleteFulfilment, suggestionOptions,
      // selectedSuggestions, 
      addSelectedSuggestions, removeSelectedSuggestions,
      updateFulfilment, canUpdateTender, canUpdateFulfilment,
    } = this.props;
    return (
      <div
        onClick={e => { selectDetail(idx) }}
        className={classNames(classes['detail'], { 'selected': selectedTdIdx === idx })}
      >
        <div className={classes['flex-row']}>
          {
            canUpdateTender ?
              (
                <div className={classes['field']}>
                  <InputLabel shrink>Product</InputLabel>
                  <SmartBox
                    suggestionProvider={val => {
                      let count = 0;
                      let result = Object.values(products).filter(el => {
                        const keep = el.reference && el.reference.toUpperCase().replace(/[ .-/]/g, '').includes(val.toUpperCase().replace(/[ .-/]/g, '')) && count < 10;
                        if (keep) {
                          count += 1;
                        }
                        return keep;
                      }).map(el => ({ label: el.reference, value: el.id, ...el }));
                      return result;
                    }}
                    searchText={td.productDisplay ? td.productDisplay : ''}
                    updateSearchText={val => {
                      if (typeof val === 'object') {
                        updateTDField({ index: idx, payload: { productId: val.id, productDisplay: val.reference, brandId: val.brandID } });
                      }
                      else {
                        updateTDField({ index: idx, payload: { productId: null, productDisplay: val, brandId: null } });
                      }
                    }}
                    updateSelectedItem={val => {
                      updateTDField({ index: idx, payload: { productId: val.id, productDisplay: val.reference, brandId: val.brandID } });
                    }}
                  />
                </div>

              ) :
              (
                <TextField
                  id="product" label="Product"
                  value={td.productDisplay ? td.productDisplay : ''}
                  className={classes['field']}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true, disabled: true }}
                />
              )
          }
          <TextField
            id="remarks" label="Remarks"
            value={td.remarks ? td.remarks.comments : ''}
            className={classes['field']}
            InputLabelProps={{ shrink: true }}
            inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
            onChange={(e) => { updateTDField({ index: idx, payload: { remarks: { comments: e.target.value } } }); }}
          />
        </div>

        <div className={classes['flex-row']}>
          <div className={classes['field']}>
            <InputLabel shrink>Price</InputLabel>
            <NumberFormat
              value={(!td.price && td.price !== 0) ? '' : td.price}
              customInput={TextField}
              decimalScale={0}
              fixedDecimalScale
              // prefix={currencySymbol + " "}
              thousandSeparator={true}
              onValueChange={(value, e) => {
                updateTDField({ index: idx, payload: { price: value.floatValue } });
              }}
              inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
            />
          </div>
          <div className={classes['field']}>
            <InputLabel shrink>Tax</InputLabel>
            <NumberFormat
              value={(!td.tax && td.tax !== 0) ? '' : td.tax}
              customInput={TextField}
              decimalScale={0}
              fixedDecimalScale
              // prefix={currencySymbol + " "}
              thousandSeparator={true}
              onValueChange={(value, e) => {
                updateTDField({ index: idx, payload: { tax: value.floatValue } });
              }}
              inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
            />
          </div>
          <div className={classes['field']}>
            <InputLabel shrink>Amount</InputLabel>
            <NumberFormat
              value={!(isNaN(td.price)) && !(isNaN(td.tax)) ? td.price + td.tax : ''}
              customInput={TextField}
              decimalScale={0}
              fixedDecimalScale
              // prefix={currencySymbol + " "}
              thousandSeparator={true}
              onValueChange={(value, e) => {
                updateTDField({ index: idx, payload: { amount: value.floatValue } });
              }}
              inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
            />
          </div>
        </div>

        <div className={classes['"btn-row"']}>
          <div>
            <IconButton onClick={e => { toggleFulfilments(idx, td.expanded) }} disabled={!td.id}>
              {
                td.expanded ?
                  (<ChevronUpCircle fontSize='small' color='action' />)
                  : (<ChevronDownCircle fontSize="small" color='action' />)
              }
            </IconButton>
            <Typography variant="overline">Qty: {td.quantity ? td.quantity : 0}</Typography>
          </div>
          <IconButton onClick={e => { handleDeleteRow({ tenderDetailId: td.id, idx: idx }) }} disabled={!canUpdateTender}>
            <Delete fontSize='small' color='secondary' />
          </IconButton>
        </div>
        {
          td.expanded && (
            <div className={classes['"btn-row"']}>
              <IconButton onClick={e => { addFulfilment(idx) }} disabled={!canUpdateTender || !td.id}>
                <Plus />
              </IconButton>
            </div>
          )
        }

        <div className={classNames(classes['fulfilments'], { 'expanded': td.expanded })}>
          {
            td.id && td.fulfilments && td.fulfilments.map((fulfilment, fidx) => (
              <div key={`td-${idx}-fulfilment-${fidx}`}>
                <FulfilmentComponent
                  fulfilment={fulfilment}
                  fidx={fidx}
                  idx={idx}
                  suggestionOptions={suggestionOptions}
                  users={users}
                  updateFFField={updateFFField}
                  deleteFulfilment={deleteFulfilment}
                  // selectedSuggestions={selectedSuggestions}
                  addSelectedSuggestions={addSelectedSuggestions}
                  removeSelectedSuggestions={removeSelectedSuggestions}
                  updateFulfilment={updateFulfilment}
                  canUpdateFulfilment={canUpdateFulfilment}
                  canUpdateTender={canUpdateTender}
                  tenderDetailId={td.id}
                />
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

export const TenderDetailComponent = withStyles(styles)(TenderDetailComponent_);