import { createVibes } from "@cosmos/vibes";
import {
  INIT_PAYSAFE, PAYMENT_FAILED, PAYMENT_SUCCEED,
} from "../actions";
import { reduce } from 'lodash';
import * as shortId from 'shortid';

export const initPaymentVibes = createVibes({
  type: [INIT_PAYSAFE],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { products } = getState().catalog;
      const { rayLens, currencies, stores, users } = getState().system;
      const { proformaCartId, type } = action.payload;
      
      const request = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `
            query($proformaCartId: ID){
              proformaCart(input: $proformaCartId){
                id reference storeId date customer contact
                type receipt tracking taxRate discountRate 
                status currency forexSet remarks userId state
                cartStocks {
                  id price discount tax orderDiscount discountRate discountValue
                  taxWithOrderDiscount amount currency forexSet 
                  stock {
                    id serial productId
                  }
                }
              }
            }
          `,
          variables: {
            proformaCartId: proformaCartId
          }
        }
      })

      const proformaCart = request.data && request.data.proformaCart;

      const paymentInput = {
        tax: reduce(proformaCart.cartStocks, (r, d) => { return r + d.tax }, 0),
        price: reduce(proformaCart.cartStocks, (r, d) => { return r + d.price }, 0),
        currency: currencies && currencies[parseInt(proformaCart.currency)] && currencies[parseInt(proformaCart.currency)].code.trim(),
        tax_rate: proformaCart.taxRate,
        entity_id: proformaCart.storeId,
        item_list: proformaCart.cartStocks.map(el => {
          return {
            tax: el.tax,
            code: el.stock && el.stock.id,
            price: el.price,
            serial: el.stock && el.stock.serial,
            reference: products && el.stock && el.stock.productId && products[el.stock.productId] && products[el.stock.productId].reference,
          }
        }),
        shop_name: stores && stores[parseInt(proformaCart.storeId)] && stores[parseInt(proformaCart.storeId)].name,
        staff_name: users && users[parseInt(proformaCart.userId)] && users[parseInt(proformaCart.userId)].name,
        customer_name: proformaCart.customer,
        tax_free_indicator: type,
        amount: reduce(proformaCart.cartStocks, (r, d) => { return r + d.amount }, 0),
      };

      const paymentCall = await window.initPayment(paymentInput);
      const result = await paymentCall.result;
      
      if (result && result.status) {
        const executionRequest = await axios.request({
          url: '/api',
          method: 'post',
          headers: {
            'Authorization': `Bearer ${token}`,
            rayLens
          },
          data: {
            query: `
              mutation($input: ExecuteCartInput){
                executeCart(input: $input){ id }
              }
            `,
            variables: {
              input: {
                target: proformaCart.id,
                once: shortId.generate()
              }
            }
          }
        })
        const executeCart = executionRequest.data && executionRequest.data.executeCart;

        if (executeCart.id) {
          dispatch({ type: PAYMENT_SUCCEED, payload: { notify: true, noticeType: 'success', notice: 'Successful to process the payment' } });
        }
        else {
          dispatch({ type: PAYMENT_FAILED, payload: { notify: true, noticeType: 'error', notice: 'Fail to process the payment' } });
        }
      }
      else {
        dispatch({ type: PAYMENT_FAILED, payload: { notify: true, noticeType: 'error', notice: 'Fail to process the payment' } });
      }

      done();
    }
    catch (err) {
      dispatch({ type: PAYMENT_FAILED, payload: { notify: true, noticeType: 'error', notice: 'Fail to process the payment' } });
      done();
    }
  }
});
