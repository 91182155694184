import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
// import useQuery from '../../hooks/useQuery';
import { makeStyles } from '@material-ui/styles';
import { INIT_TRADER_LOAD } from "../../actions";
import StockInListDetail from './stockInListDetail';
import { useSelector, useDispatch } from "react-redux";
import { FocusDialog } from '../../components';
import Paper from "@material-ui/core/Card";
import useQuery from '../../hooks/useQuery';
import useOnce from '../../hooks/useOnce';
import useDialog from '../../hooks/useDialog'

const useStyles = makeStyles(theme => ({
	backgroundInfo: {
		padding: '20px',
	},
	vendorInfo: {
		display: 'flex',
		flexDirection: 'row',
		listStyle: 'none',
		paddingLeft: '0px',
	},
	vendorInfoDetail: {
		flexBasis: "30%"
	},
	content: {
		flex: 1,
		padding: "0 0 1em 0"
	},
	btnGroup: {
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2em',
		justifyContent: 'flex-end'
	},
	btn: {
		marginRight: '1.5em',
	},
	dialog: {
		'max-width': '60%',
		'height': '70%'
	},
	section: {
		padding: '20px',
	}
}))

export default function StockInList() {
	const { state: { stock } } = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const traders = useSelector(state => state.trader.traders);
	let products = useSelector(state => state.catalog.products);
	let brands = useSelector(state => state.catalog.brands);
	const currencies = useSelector(state => state.system.currencies);
	const [openDialog, setOpenDialog] = useState(false);
	const [imgPath, setImagePath] = useState(null);
	const [disabledGenerate, setDisabledGenerate] = useState(true);
	const [data, setData] = useState({});
	const [notify, setNotify] = useState(false);
	const [notice, setNotice] = useState(null);
	const [noticeType, setNoticeType] = useState("error");

	useEffect(() => {
		dispatch({ type: INIT_TRADER_LOAD });
	}, [dispatch]);
	useEffect(() => {

		let groups = Object.create(null);
		let groupedStock = [];
		let stockInDetails = stock.stockInDetails;

		let vendorCountryId = traders[stock.vendorId] ? traders[stock.vendorId].countryId : null;
		const currency = currencies[vendorCountryId] && currencies[vendorCountryId].symbol;
		stock.currency = currency
		if (stockInDetails.length > 0) {
			stockInDetails.forEach(function (o) {
				let productName = products[o.productId] ? products[o.productId].name : null;
				let productSKU = products[o.productId] ? products[o.productId].SKU : null;
				let brandID = products[o.productId] ? products[o.productId].brandID : null;
				let brandName = brands[brandID] ? brands[brandID].name : null;
				if (!groups[o.productId]) {
					groups[o.productId] = [];
					let productAssets = (products[o.productId] && products[o.productId].assets) ? products[o.productId].assets[0] : {};
					let imgPath = productAssets ? `/images/${productAssets.reference}` : null;
					groupedStock.push({
						productID: o.productId,
						productDetail: groups[o.productId],
						productName: productName,
						productSKU: productSKU,
						brandName: brandName,
						image: imgPath,
						currency: currency,
					});
				}
				o.currency = currency;
				o.productSKU = productSKU;
				o.brandName = brandName;
				o.productName = productName;

				groups[o.productId].push(o);
			});
		}
		if (groupedStock.length > 0) {
			groupedStock.map(x => {
				let length = x.productDetail.length;
				x.quantity = length;
				var subTotal = x.productDetail.reduce(function (sum, item) {
					return sum = sum + item.price;
				}, 0);
				x.subTotal = subTotal.toFixed(2);
				return x;
			});
		}
		stock.groupedStock = groupedStock;
		stock.currencyID = currencies[vendorCountryId] ? currencies[vendorCountryId].id : null;
		setData(stock);
	}, [brands, currencies, products, stock, traders]);

	const createPOQuery = `mutation
	( $purchaseInput: GeneratePurchaseInput) {
		generatePO(input: $purchaseInput){
			id,
			reference,
			date,
			vendor,
			contact,
			invoice,
			currency
		}
	}`

	const { execute: executeQuery, isLoading: isSubmitting, data: { generatePO: generatePOData }, error } = useQuery(createPOQuery, { purchaseInput: null }, {}, false)
	const [once, changeOnce] = useOnce();
	const { openDialog: openErrorDialog, Dialog: ErrorDialog } = useDialog()

	const isAuthenticated = useSelector(state => state.auth && state.auth.isAuthenticated)
	const generatePO = () => {
		if (!isAuthenticated) return openErrorDialog('Not authenticated');

		let stockArr = [];
		if (stock.stockInDetails && stock.stockInDetails.length > 0) {
			stockArr = stock.stockInDetails.map(item => {
				return {
					stockInDetailId: item.id,
					quantity: 1,
					remarks: null
				};
			})
		}

		const purchaseInput = {
			once,
			contact: null,
			currency: traders[stock.vendorId].currencyId,
			stocks: stockArr
		}

		executeQuery({ purchaseInput: purchaseInput });
		changeOnce();
	}

	useEffect(() => {
		if (error) openErrorDialog(JSON.stringify(error))
	}, [error, openErrorDialog])

	useEffect(() => {
		if (generatePOData) {
			setNotify(true)
			if (generatePOData.id) {
				setNoticeType("success");
				setNotice("Successful to generate Purchase Order");
			} else {
				setNoticeType("error");
				setNotice("Failed to generate Purchase Order");
			}
		}
	}, [generatePOData, setNotify, setNoticeType, setNotice])

	const closeNotification = () => {
		setNotify(false);
		history.goBack();
	}

	const stockImg = (img) => {
		setOpenDialog(true)
		if (img) {
			setImagePath(img);
		}
	}

	useEffect(() => {
		if (data && (Object.entries(data).length > 0)) {
			setDisabledGenerate(false);
		}
	}, [data]);

	const closeDialog = () => {
		setOpenDialog(false);
		setImagePath(null);
	}

	const getName = (vendorId) => {
		let name;
		if (vendorId && Object.entries(traders).length > 0) {
			name = traders[vendorId].name;
		} else {
			name = "Vendor Name";
		}
		return name;
	}

	const classes = useStyles();
	return (
		<div className={classes.backgroundInfo}>
			<Paper className={classes.section}>
				<h2>Stock In List</h2>
				<ul className={classes.vendorInfo}>
					<li className={classes.vendorInfoDetail}><h3>Vendor Name: {getName(data.vendorId)}</h3></li>
					<li className={classes.vendorInfoDetail}><h3>Date: {data.date ? data.date : "Date"}</h3></li>
					<li className={classes.vendorInfoDetail}><h3>Invoice: {data.invoice ? data.invoice : "Invoice"}</h3></li>
				</ul>
				<Dialog open={openDialog} fullWidth classes={{ paperFullWidth: classes.dialog }}>
					<DialogTitle>Photo</DialogTitle>
					<DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
						<div>
							<img src={imgPath} alt={'Preview Photos'} />
						</div>
					</DialogContent>
					<DialogActions>
						<Button color="primary" variant="contained" size="small" onClick={closeDialog}>
							Cancel
					</Button>
					</DialogActions>
				</Dialog>
				<ErrorDialog />
				<FocusDialog open={notify} message={notice} variant={noticeType} onClose={closeNotification} />
				<StockInListDetail
					stocks={data.groupedStock}
					getStockImg={stockImg}
				/>
				<div className={classes.btnGroup}>
					<Button className={classes.btn}
						onClick={() => history.goBack()}
						color="primary" variant="contained" >Cancel</Button>
					<Button className={classes.btn}
						disabled={disabledGenerate || isSubmitting}
						onClick={generatePO}
						color="primary" variant="contained" >Generate</Button>
				</div>
			</Paper>
		</div>
	)
}
