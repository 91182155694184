import { createVibes } from "@cosmos/vibes";
import { APP_INIT } from "../actions";
import { webSocket } from "rxjs/webSocket";
import { map } from "rxjs/operators";
const url = process.env.REACT_APP_WS_URL;
const enabled = process.env.REACT_APP_WS_ENABLED === "YES";
export const wsVibes = createVibes({
  type: [APP_INIT],
  latest: true,
  warnTimeout: 0,
  process() {
    if (enabled)
      return webSocket({ url }).pipe(
        map((msg) => {
          return msg;
        })
      );
  },
});
