import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';

class CreateVendorDialogComponent extends Component {

  render() {
    const { closeDialog, confirmCreation, vendorName, dialogOpen } = this.props;

    return (
      <Dialog
        maxWidth={'xl'}
        open={dialogOpen}
        onClose={closeDialog}
      >
        <DialogTitle>{`Do you want to create the vendor ${vendorName}?`} </DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog} color='primary' variant='outlined' size='small'>
            Close
          </Button>
          <Button onClick={confirmCreation} color='primary' variant='contained' size='small'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const CreateVendorDialog = CreateVendorDialogComponent;