import { createVibes } from "@cosmos/vibes";
import { FILTER_PURCHASES, RELOAD_PURCHASE, INIT_PURCHASE_LOAD, LOAD_PURCHASE_SUCCESS, LOAD_PURCHASE_ERROR, UPDATE_SELECTED_STATUS } from "../../actions";
import { reduce } from 'lodash';
import moment from 'moment';

export const loadPurchaseVibes = createVibes({
  type: [INIT_PURCHASE_LOAD, UPDATE_SELECTED_STATUS, RELOAD_PURCHASE],
  debounce: 300,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      let { selectedStatuses } = getState().purchase;
      const products = getState().catalog.products;
      const { stores } = getState().system;
      const traders = getState().trader.traders;
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      let variables = {};
      if (selectedStatuses.length > 0) {
        variables = { filter: { state: selectedStatuses } };
      }

      const axiosResult = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query ($filter: purchaseFilter){
            purchaseOrders (filter: $filter) {
              id
              storeId
              reference
              date
              vendor
              contact
              invoice
              tracking
              type
              taxRate
              discountRate
              state
              currency
              remarks
              userId
              createdAt
              updatedAt
              deletedAt
              purchaseDetails{
                  id
                  brandId
                  productId
                  unitPrice
                  quantityExpected
                  quantity
                  price
                  discountRate
                  discountValue
                  priceWithDiscount
                  taxRate
                  taxValue
                  amount
                  taxValueWithOrderDiscount
                  amountWithOrderDiscount
                  total
                  remarks
                  createdAt
                  updatedAt
                  deletedAt
                  currency
                  exchangeSet
                  purchaseId
                  userId
                }
                restQuantity
              }
            }`,
          variables
        }
      });

      let data = axiosResult.data;

      let result = data.purchaseOrders;

      if (result) {
        const loadedPurchases = reduce(result, (r, d) => {
          r[d.id] = {
            ...d,
            store: stores[d.storeId],
            storeName: stores[d.storeId] && stores[d.storeId].name,
            vendorName: traders[d.vendor] && traders[d.vendor].name,
            vendorCountryId: traders[d.vendor] && traders[d.vendor].countryId,
            statusName: d.state,
            createdAt: d.createdAt ? moment(d.createdAt).format("YYYY-MM-DD") : null,
            remarks: d.remarks ? JSON.parse(d.remarks).comments : d.remarks,
            purchaseDetails: reduce(d.purchaseDetails, (a, b) => {
              a.push({
                ...b,
                remarks: b.remarks ? JSON.parse(b.remarks) : b.remarks,
                productDisplay: products[b.productId] && products[b.productId].reference,
              })
              return a;
            }, [])
          };
          return r;
        }, {});

        dispatch({
          type: LOAD_PURCHASE_SUCCESS,
          payload: {
            purchases: loadedPurchases,
            filteredPurchases: result.map(el => el.id)
          }
        });
        dispatch({ type: FILTER_PURCHASES });
      }
      else {
        dispatch({ type: LOAD_PURCHASE_ERROR, payload: Error('Fail to load purchase orders') });
      }
      done();
    } catch (err) {
      dispatch({ type: LOAD_PURCHASE_ERROR, payload: err });
      done();
    }
  }
});