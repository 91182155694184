import React, {useRef} from "react";
import { connect, useDispatch } from "react-redux";
import { Button, withStyles, Paper } from "@material-ui/core";
import { TOGGLE_CART_TYPE_DIALOG, CART_RESET, CHANGE_VOID_MODE, CART_REMOVE_ITEM_SUCCESS, TOGGLE_CART_TYPE_DIALOG_ERROR, CART_CHECKOUT_BEGIN } from "../../../actions";
import classNames from "classnames";

const styles = theme => ({
    button: {
        margin: "1rem"
    },
    content: {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end"
    }
});

const ControlComponent_ = props => {
    const voidModeRef = useRef();
    const { voidLabels, goods, labels, prices, disableCart, classes, voidMode, customer, contact, company } = props;
    const dispatch = useDispatch();
    const checkOut = () => {
        if (customer && customer.name && contact) {
            dispatch({
                type: TOGGLE_CART_TYPE_DIALOG,
                payload: { typeDialog: true }
            });
        }
        else if(company && company.id) {
            dispatch({ type: CART_CHECKOUT_BEGIN, payload: {ProformaSales : true} });
        }
        else {
            dispatch({ type: TOGGLE_CART_TYPE_DIALOG_ERROR });
        }
    };

    const resetCart = () => {
        dispatch({ type: CART_RESET });
    }

    const changeVoidMode = () => {
        let updVoidMode = !voidMode;
        dispatch({ type: CHANGE_VOID_MODE, payload: updVoidMode });

        if (!updVoidMode) {
            if (voidLabels.length > 0) {
                voidLabels.forEach(voidLabel => {
                    const idxGoods = labels.indexOf(voidLabel);
                    labels.splice(idxGoods, 1);
                    prices.splice(idxGoods, 1);
                    goods.splice(idxGoods, 1);
                });
                dispatch({
                    type: CART_REMOVE_ITEM_SUCCESS,
                    payload: {
                        goods: goods,
                        labels: labels,
                        prices: prices,
                        voidGoods: [],
                        voidLabels: [],
                        voidPrices: [],
                    }
                });
            }
        }
        voidModeRef.current.blur();
    };

    return (
        <div className={classes.control}>
            <Paper className={classNames(classes.content)}>
                <Button
                    className={classNames(classes['button'])}
                    color="primary" variant="contained"
                    onClick={checkOut}
                    disabled={(disableCart || voidMode) ? true : false}
                >
                    Confirm
                        </Button>
                <Button
                    className={classNames(classes['button'])}
                    color="primary" variant="outlined"
                    onClick={resetCart}
                    disabled={voidMode}
                >
                    Reset
                        </Button>
                <Button
                    className={classNames(classes['button'])}
                    color={voidMode ? "secondary" : "primary"} variant="contained"
                    onClick={changeVoidMode} 
                    ref={voidModeRef}
                >
                    Void
                        </Button>
            </Paper>
        </div>
    );
}

export const ControlComponent = connect(
    state => ({
        disableCart: state.pos.disableCart,
        customer: state.pos.customer,
        voidMode: state.pos.voidMode,
        goods: state.pos.goods,
        labels: state.pos.labels,
        prices: state.pos.prices,
        voidGoods: state.pos.voidGoods,
        voidLabels: state.pos.voidLabels,
        contact: state.pos.contact,
        company: state.pos.company
    })
)(withStyles(styles)(ControlComponent_));
