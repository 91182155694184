import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import styles from './invoice.module.scss';
import {
    Button,
} from '@material-ui/core';
import { TOGGLE_INVOICE_DIALOG, SUBMIT_INVOICE_PHOTO } from '../../../actions/purchase';
import { PhotoDialog } from '../../../components/dialog/photoDialog';

class InvoiceComponent_ extends Component {

    toggleDialog = () => {
        this.props.dispatch({ type: TOGGLE_INVOICE_DIALOG });
    }

    handleSubmit = (image) => {
        this.props.dispatch({ type: SUBMIT_INVOICE_PHOTO, payload: image });
    }

    render() {
        const { dataUri, captureInvoice, uploadProgress, purchaseInput } = this.props;

        return (
            <div className={styles['invoice']}>
                <div className={styles['button']}>
                    <Button color="primary" variant="contained" onClick={this.toggleDialog}>
                        Take Photo
                    </Button>
                </div>
                <PhotoDialog
                    dialogOpen={captureInvoice}
                    uploadProgress={uploadProgress}
                    closeDialog={this.toggleDialog}
                    handleSubmit={this.handleSubmit}
                    ableToCancel={false}
                />
                {
                    purchaseInput && purchaseInput.invoiceIndicator && dataUri &&
                    (
                        <div className={styles.slide}>
                            <img src={dataUri} className={styles.picture} alt={'invoice'}/>
                        </div>
                    )
                }
            </div>
        )
    } 
}

export const InvoiceComponent = connect(state => ({
    captureInvoice: state.purchase.captureInvoice,
    uploadProgress: state.purchase.uploadProgress,
    purchaseInput: state.purchase.purchaseInput,
    dataUri: state.purchase.dataUri,
}))(InvoiceComponent_);