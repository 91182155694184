import {
    UPDATE_EXTENSION,
    UPDATE_RAY_STORE,
    LOAD_USER_SUCCESS,
    LOAD_CATALOG_SUCCESS,
    INV_LOAD_SUCCESS,
    LOAD_PRICE_SUCCESS,
    LOAD_PRINTERS_SUCCESS,
    LOAD_PURCHASE_SUCCESS,
    LOAD_RESERVATION_SUCCESS,
    LOAD_TENDER_SUCCESS,
    APP_INIT,
    APP_LOADED,
    MENU_OP,
    CHANGE_TAX_SETTING,
    ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR
} from "../actions";
import moment from "moment";
import {Currencies, features, Nationalities} from "../constants";

const initialState = {
    client: {},
    server: {},
    mode: 0,
    menu: 0,
    features: features.filter(f => f.enabled),
    currencies: Currencies,
    paymentChannels: {},
    rayLens: localStorage.getItem('rayLens') ? JSON.parse(localStorage.getItem('rayLens')) : null,
    stores: {},
    users: {},
    countries: {},
    countryCodes: {
        'HKG':1,
        'DNK':2,
        'SVK':3,
        'NOR':4,
        'ITA':5,
        'GRC':6,
        'ESP':7,
        'JPN':8,
        'CAN':9,
        'PRT':10,
        'NZL':11,
        'TWN':12,
        'ARE':16,
        'VNM':17,
        'MAC':18,
        'BHR':19,
        'QAT':20,
        'GBR':22,
        'AUT':24,
        'SGP':26,
        'MYS':28,
        'FIN':30,
        'SWE':32,
        'POL':34,
        'MLT':36,
        'BRN':38,
        'CZE':42,
        'SAU':44,
        'USA':46,
        'EST':48,
        'DEU':54,
        'LVA':56,
        'CHE':58,
        'SXM':60,
        'LTU':64,
        'BGR':66,
        'MNE':68,
        'CYP':70,
        'BEL':96,
        'LUX':98,
        'ROU':100,
        'NLD':102,
        'FRA':104,
        'SVN':106,
        'IRL':108,
        'HRV':110,
        'HUN':112
    },
    isTaxIncluded: null,
    generationTime: {},
    nationalities: Nationalities,
    notifications: [],
};

export const systemReducer = (state = initialState, action) => {
    switch (action.type) {
        case APP_INIT:
            return {...state, mode: 0};
        case APP_LOADED:
            const loadMode = state.mode | 1;
            return {...state, mode: loadMode};
        case MENU_OP:
            return {...state, menu: action.payload};
        case UPDATE_EXTENSION:
        case LOAD_USER_SUCCESS:
            return {...state, ...action.payload};
        case UPDATE_RAY_STORE:
            return {
                ...state,
                rayLens: action.payload
            };
        case LOAD_CATALOG_SUCCESS:
            const catalogLoadMode = state.mode | 2;
            return {
                ...state,
                mode: catalogLoadMode,
                generationTime: {
                    ...state.generationTime,
                    catalog: moment().format(),
                }
            }
        case INV_LOAD_SUCCESS:
            return {
                ...state,
                generationTime: {
                    ...state.generationTime,
                    inventory: moment().format(),
                }
            }
        case LOAD_PRICE_SUCCESS:
            return {
                ...state,
                generationTime: {
                    ...state.generationTime,
                    price: moment().format(),
                }
            }
        case LOAD_PURCHASE_SUCCESS:
            return {
                ...state,
                generationTime: {
                    ...state.generationTime,
                    purchase: moment().format(),
                }
            }
        case LOAD_RESERVATION_SUCCESS:
            return {
                ...state,
                generationTime: {
                    ...state.generationTime,
                    reservation: moment().format(),
                }
            }
        case LOAD_TENDER_SUCCESS:
            return {
                ...state,
                generationTime: {
                    ...state.generationTime,
                    tender: moment().format(),
                }
            }
        case LOAD_PRINTERS_SUCCESS:
            return {
                ...state,
                generationTime: {
                    ...state.generationTime,
                    printer: moment().format(),
                }
            }
        case CHANGE_TAX_SETTING:
            return {
                ...state,
                isTaxIncluded: action.payload
            }
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };
        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };
        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };
        default:
            return state;
    }
};
