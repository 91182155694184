import { createVibes } from "@cosmos/vibes";
import { UPDATE_IDV_DISC_RATE, applyIdvDiscRateSuccess, applyIdvDiscRateFail } from "../../actions";
import { Abacus } from "@cosmos/harmony";

export const applyIdvDiscRateVibes = createVibes({
  type: [UPDATE_IDV_DISC_RATE],
  latest: true,
  processOptions: {
    successType: applyIdvDiscRateSuccess,
    failType: applyIdvDiscRateFail
  },
  async process({ getState, action }) {
    try {
      const { goods, taxRate, prices } = getState().pos;
      const oriPrices = getState().price.prices;
      const { discountRate, idx } = action.payload;
      const selectedGood = { ...goods[idx] };

      let oriSellPrice; // orignal code
      if(!selectedGood.priceAdjustment && prices[idx].amount) {
        oriSellPrice = prices[idx].amount
      }else {
        // original code - start
        if (selectedGood.priceAdjustment === null || selectedGood.priceAdjustment === undefined) {
          oriSellPrice = oriPrices[selectedGood.productId] ? oriPrices[selectedGood.productId].price : 0;
        }
        else {
          oriSellPrice = selectedGood.priceAdjustment;
        }
        // original code - end
      }

      let calculatedOutput = Abacus.calcPriceWithRatio(oriSellPrice, 1, parseFloat(discountRate), taxRate, 2);

      let gds = [...goods.slice(0, idx), { ...goods[idx], discountRate: parseFloat(discountRate) }, ...goods.slice(idx + 1)];
      let pcs = [...prices.slice(0, idx), calculatedOutput, ...prices.slice(idx + 1)];

      return { goods: gds, prices: pcs }; 
    } catch (err) {
      return { error: err};
    }
  }
});