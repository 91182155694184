import { LOAD_PRICE_SUCCESS, LOAD_PRICE_ERROR } from "../actions";
import moment from 'moment';

const initialState = {
    prices: {},
};

export const priceReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PRICE_SUCCESS:
            return { ...state, ...action.payload, loading: false, update: moment() };
        case LOAD_PRICE_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
};