import axios from "axios";

export default async function checkStockLabelExists(stockLabel, headers) {
  try {
    await axios.request({
      url: `/v1/STK/${stockLabel}`,
      method: "get",
      headers,
    });
    return true;
  } catch (e) {
    return false;
  }
}
