import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    TableToolbar,
    DataGrid,
    authorized,
    Authorization,
} from '@cosmos/chaos';
import {
    Paper,
    LinearProgress,
    Input,
    withStyles
} from '@material-ui/core';
import {
    LOAD_TENDER, CLOSE_TENDER_NOTICE, UPDATE_TENDER_SEARCH_TEXT, UPDATE_SELECTED_PERSONIC, SELECT_TENDER,
    INIT_TRADER_LOAD, OPEN_TENDER_DRAWER, CLOSE_TENDER_DRAWER, INIT_USER_LOAD,
} from "../../actions";
import {FilterBar} from '../../components/tableFilter';
import {AutoSizer} from 'react-virtualized';
import {push} from 'connected-react-router';
import classNames from 'classnames';
import {DrawerComponent} from "./components";
import {FocusDialog} from '../../components';
import {Route, Switch} from "react-router";
import {TenderForm} from "./form";

const styles = theme => ({
    view: {
        flexGrow: 1,
        display: "flex",
        padding: "0.5em"
    },
    feature: {
      flex: 1,
      display: "flex",
      flexDirection: "column"
    },
    toolbar: {
        backgroundColor: theme.palette.primary.light
    },
    toolset: {
        display: "flex"
    },
    messageBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: "0.5em"
    },
    "search-input": {
        padding: "4px 4px 4px 60px"
    },
    content: {
        flex: 1,
        display: "flex",
        flexDirection: "row"
    },
    "auto-size": {
        flex: 1
    },
    drawer: {
        minWidth: "35rem",
        display: "flex",
        "&.hide" :{
            display: "none"
        }
    },
    "header-sortable": {
        display: "flex",
        flexWrap: "wrap",
        color: "#333",
        justifyContent: "flex-start"
    },
    "header-sortable-index": {
        display: "none",
        fontSize: "80%"
    },
    "header-sortable-active": {
        color: "#000"
    },
    "header-sortable-icon": {
        width: "12px",
        height: "12px",
        color: "#3333EE"
    },
    form: {
        display: "flex",
        flexDirection: "column"
    },
    title: {
      marginLeft: "1.5rem",
      marginTop: "1rem"
    },
    "flex-row": {
      display: "flex",
      flexDirection: "row"
    },
    "flex-1": {
      flex: 1
    },
    "left-component": {
      borderRadius: "1em",
      padding: "1rem",
      margin: "1rem"
    },
    "flex-column": {
      display: "flex",
      flexDirection: "column"
    },
    "left-container": {
      display: "flex",
      flex: 1
    },
    "right-container": {
      display: "flex",
      borderRadius: "1em",
      flex: 2,
      margin: "1rem"
    },
    "flex-end": {
      justifyContent: "flex-end"
    },
    "button-group": {
      marginRight: "1rem",
      marginBottom: "1rem"
    },
    button: {
      marginLeft: "0.5rem"
    }
});

class TenderListComponent extends Component {

    state = {
        orders: new Map(),
    }

    componentDidMount() {
        this.props.dispatch({type: LOAD_TENDER});
        this.props.dispatch({type: INIT_USER_LOAD});
        this.props.dispatch({type: INIT_TRADER_LOAD, payload: {filter: {tier: 'CHANNEL'}}}); //for seller and channel name, list and drawer
    }

    closeNotification = () => {
        this.props.dispatch({type: CLOSE_TENDER_NOTICE});
    };

    updateSorting(key) {
        let orders = this.state.orders;
        if (orders.has(key)) {
            const dir = orders.get(key);
            if (dir === 'asc') {
                orders.set(key, 'desc');
            } else {
                orders.delete(key);
            }
        } else {
            orders.set(key, 'asc');
        }
        this.setState({orders});
    }

    updateSearchText = (event) => {
        if (event.target.value !== undefined)
            this.props.dispatch({type: UPDATE_TENDER_SEARCH_TEXT, payload: {searchText: event.target.value}});
    }

    handlePersonFilter = (e) => {
        this.props.dispatch({type: UPDATE_SELECTED_PERSONIC, payload: {selectedPersons: e.target.value}});
    };

    createTender = () => {
        this.props.dispatch(push('/orders/tender/new'));
    }

    closeDrawer = (id) => {
        this.props.dispatch({type: CLOSE_TENDER_DRAWER});
    }

    openDrawer = (id) => {
        this.props.dispatch({type: SELECT_TENDER, payload: id});
        this.props.dispatch({type: OPEN_TENDER_DRAWER});
    }

    canCreateTender = authorized(['cosmos.tender.create']);

    render() {
        const {
            permission, loading, marshalledTenders, notice, notify, noticeType, searchText,
            selectedPersons, users, currencies, paymentChannels,
            countries, tenderStates, selectedTenderId, drawer, traders, classes
        } = this.props;
        const {orders} = this.state;

        const canCreateTender = this.canCreateTender(permission);
        let newItem = null;
        if (canCreateTender) {
            newItem = () => {
                this.createTender();
            }
        }

        const columns = [
            {
                name: 'indicator',
                header: '',
                width: 30,
            },
            {
                name: 'reference',
                header: "Reference",
                onHeaderClick: () => {
                    this.updateSorting('reference');
                },
            },
            {
                name: 'channel',
                header: "Channel",
                onHeaderClick: () => {
                    this.updateSorting('channel');
                },
                cell: rowData => (
                    <div>
                        {rowData.channel && traders[rowData.channel] && traders[rowData.channel].name}
                    </div>
                )
            },
            {
                name: 'customer',
                header: "Customer",
                onHeaderClick: () => {
                    this.updateSorting('customer');
                },
            },
            {
                name: 'deliveryCountry',
                header: "Delivery To",
                onHeaderClick: () => {
                    this.updateSorting('deliveryCountry');
                },
                cell: rowData => (
                    <div>
                        {rowData.deliveryCountry && countries && countries[rowData.deliveryCountry] && countries[rowData.deliveryCountry].name}
                    </div>
                )
            },
            {
                name: 'state',
                header: "State",
                onHeaderClick: () => {
                    this.updateSorting('state');
                },
                cell: rowData => (
                    <div>
                        {tenderStates && tenderStates[rowData.state]}
                    </div>
                )
            },
            {
                name: 'sum',
                header: "Total",
                onHeaderClick: () => {
                    this.updateSorting('sum');
                },
                cell: rowData => (
                    <div>
                        {currencies && currencies[rowData.currency] && rowData.sum ?
                            `${currencies[rowData.currency].symbol} ${rowData.sum}` : ''
                        }
                    </div>
                )
            },
            {
                name: 'paidBy',
                header: "Paid By",
                onHeaderClick: () => {
                    this.updateSorting('paidBy');
                },
                cell: rowData => (
                    <div>
                        {rowData.paidBy && paymentChannels && paymentChannels[rowData.paidBy] && paymentChannels[rowData.paidBy].name}
                    </div>
                )
            },
            {
                name: 'expireAt',
                header: "Expire At",
                onHeaderClick: () => {
                    this.updateSorting('expireAt');
                },
            },
            {
                name: 'pic',
                header: "Person In Charge",
                cell: rowData => (
                    <div>
                        {users[rowData.pic] && users[rowData.pic].name}
                    </div>
                ),
                onHeaderClick: () => {
                    this.updateSorting('pic');
                },
            },
        ];

        return (
            <Switch>
                <Route path="/orders/tender/new">
                    <TenderForm/>
                </Route>
                <Route path="/orders/tender">
                    <Authorization allowed={['cosmos.tender.view']}>
                        {({permit}) => (permit(permission) &&
                            <div className={classes['view']}>
                                <Paper className={classes.feature}>
                                    <TableToolbar
                                        numSelected={0}
                                        title="Tender"
                                        styles={{
                                            root: classes.toolbar,
                                            toolset: classes.toolset,
                                            messageBox: classes.messageBox
                                        }}
                                        newItemTrigger={newItem}
                                        searchBox={() => (
                                            <Input
                                                placeholder="Reference"
                                                disableUnderline
                                                value={searchText}
                                                onChange={this.updateSearchText}
                                                className={classes['search-input']}
                                                fullWidth
                                            />
                                        )}
                                        filterBox={() => <FilterBar
                                            units={{
                                                pic: {
                                                    data: users,
                                                    selected: selectedPersons,
                                                    handleChange: this.handlePersonFilter
                                                },
                                            }}
                                        />}
                                    />
                                    {loading && <LinearProgress/>}
                                    <div className={classes.content}>
                                        <div className={classes['auto-sizer']}>
                                            <AutoSizer>
                                                {({width, height}) => (
                                                    <DataGrid
                                                        data={marshalledTenders}
                                                        columns={columns}
                                                        width={width}
                                                        maxHeight={height}
                                                        height={height}
                                                        includeHeaders={true}
                                                        fixedRowCount={1}
                                                        cellProps={{style: {paddingLeft: 2, paddingRight: 4}}}
                                                        orderBy={orders}
                                                        classes={{
                                                            sortableHeader: classes['header-sortable'],
                                                            sortableHeaderActive: classes['header-sortable-active'],
                                                            sortableHeaderIcon: classes['header-sortable-icon'],
                                                            sortIndex: classes['header-sortable-index']
                                                        }}
                                                        onCellClick={(column, rowData) => {
                                                            this.openDrawer(rowData.id)
                                                        }}
                                                        isCellHovered={(column, rowData, hoveredColumn, hoveredRowData,) => {
                                                            return (rowData && rowData.id === hoveredRowData.id)
                                                        }}
                                                        isCellSelected={(column, rowData) => {
                                                            return rowData.id === selectedTenderId
                                                        }}
                                                    />
                                                )}
                                            </AutoSizer>
                                        </div>
                                        <div className={classNames(classes['drawer'], {hide: !drawer})}>
                                            <AutoSizer>
                                                {({width, height}) => (
                                                    <DrawerComponent height={height} width={width}
                                                                     closeDrawer={this.closeDrawer}
                                                    />
                                                )}
                                            </AutoSizer>
                                        </div>
                                    </div>
                                </Paper>
                                <FocusDialog open={notify} message={notice} variant={noticeType}
                                             onClose={this.closeNotification}/>
                            </div>
                        )}
                    </Authorization>
                </Route>
            </Switch>
        )
    }
}

export const TenderView = connect(state => {
    const {tenders, filteredTenders} = state.tender;
    const marshalledTenders = Object.values(tenders).filter(el => filteredTenders.indexOf(el.id) > -1).map(el => el);
    return {
        permission: state.auth.permission,
        loading: state.tender.loading,
        marshalledTenders: marshalledTenders,
        notice: state.tender.notice,
        notify: state.tender.notify,
        noticeType: state.tender.noticeType,
        searchText: state.tender.searchText,
        selectedPersons: state.tender.selectedPersons,
        users: state.system.users,
        currencies: state.system.currencies,
        paymentChannels: state.system.paymentChannels,
        tenderStates: state.tender.tenderStates,
        countries: state.system.countries,
        selectedTenderId: state.tender.selectedTenderId,
        drawer: state.tender.drawer,
        traders: state.trader.traders,
    }
})(withStyles(styles)(TenderListComponent));