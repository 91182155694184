import React from 'react';
import { Component } from 'react';
import {
  ListItem,
  InputBase,
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import IndexCell from './indexCell';
import { SmartBox } from '@cosmos/chaos';
import NumberFormat from "react-number-format";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  "detail-row": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    "&.selected": {
        backgroundColor: "#90EE90"
    },
    "&:hover": {
        background: "#a7ffeb"
    }
},
    "flex-1": {
        flex: 1
    },
    list: {
        flex: 1,
        padding: 0,
        justifyContent: "flex-start"
    },
    "idx-delete": {
        minWidth: "5.0625rem",
        minHeight: "3.0625rem",
        width: "3rem",
        height: "1rem",
        padding: "1rem",
        borderRadius: 0,
        borderLeft: "1px solid black",
        borderBottom: "1px solid black",
        display: "none",
        "&.hover": {
            display: "block"
        }
    },
    "idx-text": {
        height: "1rem",
        borderLeft: "1px solid black",
        borderBottom: "1px solid black",
        padding: "1rem",
        width: "3rem",
        display: "block",
        "&.hover": {
            display: "none"
        }
    },
    "padding-1rem": {
        padding: "1rem"
    },
    "content-cell": {
        height: "1rem",
        borderLeft: "1px solid black",
        borderBottom: "1px solid black",
        "&:last-child": {
            borderRight: "1px solid black"
        },
        "&.with-product": {
            backgroundColor: "lavender"
        }
    },
    "radio-group": {
        padding: "1.5rem",
        display: "flex",
        justifyContent: "space-evenly"
    },
    "radio-btn": {
        margin: 0
    },
    idx: {
        width: "3rem"
    },
    product: {
        flex: 1
    },
    qty: {
        width: "2rem"
    },
    price: {
        width: "6rem"
    },
    tax: {
        width: "6rem"
    },
    amount: {
        width: "6rem"
    },
    remarks: {
        width: "10rem"
    }
})

export class DetailRowComponent_ extends Component {

  render() {
    const { 
      td, idx, handleDeleteRow, products, handleSelectRow, selectedTdIdx, 
      // currencySymbol, 
      updateTDField, classes } = this.props;
    return (
      <div
        className={classNames(classes['detail-row'], { 'selected': selectedTdIdx === idx })}
      >
        <ListItem className={classNames(classes['list'])} onClick={handleSelectRow}>
          <IndexCell
            buttonClass={classNames(classes['idx-delete'])}
            textClass={classNames(classes['idx-text'])}
            index={idx}
            handleDeleteRow={handleDeleteRow}
          />
          <div className={classNames(classes['content-cell'], classes['product'], classes['padding-1rem'], { 'with-product': td.productId && td.productId > -1 })}>
            <SmartBox
              suggestionProvider={val => {
                let count = 0;
                let result = Object.values(products).filter(el => {
                  const keep = el.reference && el.reference.toUpperCase().replace(/[ .-/]/g, '').includes(val.toUpperCase().replace(/[ .-/]/g, '')) && count < 10;
                  if (keep) {
                    count += 1;
                  }
                  return keep;
                }).map(el => ({ label: el.reference, value: el.id, ...el }));
                return result;
              }}
              searchText={td.productDisplay ? td.productDisplay : ''}
              updateSearchText={val => {
                if (typeof val === 'object') {
                  updateTDField({ index: idx, payload: { productId: val.id, productDisplay: val.reference, brandId: val.brandID } });
                }
                else {
                  updateTDField({ index: idx, payload: { productId: null, productDisplay: val, brandId: null } });
                }
              }}
              updateSelectedItem={val => {
                updateTDField({ index: idx, payload: { productId: val.id, productDisplay: val.reference, brandId: val.brandID } });
              }}
            />
          </div>
          <div className={classNames(classes['content-cell'], classes['qty'], classes['padding-1rem'])}>
            <InputBase
              type="number"
              inputProps={{ min: 1 }}
              value={!td.quantity && td.quantity !== 0 ? '' : td.quantity}
              onChange={(e) => { updateTDField({ index: idx, payload: { quantity: parseInt(e.target.value) } }); }}
            />
          </div>
          <div className={classNames(classes['content-cell'], classes['price'], classes['padding-1rem'])}>
            <NumberFormat
              value={(!td.price && td.price !== 0) ? '' : td.price}
              customInput={InputBase}
              decimalScale={0}
              fixedDecimalScale
              // prefix={currencySymbol + " "}
              thousandSeparator={true}
              onValueChange={(value, e) => {
                updateTDField({ index: idx, payload: { price: value.floatValue } });
              }}
            />
          </div>
          <div className={classNames(classes['content-cell'], classes['tax'], classes['padding-1rem'])}>
            <NumberFormat
              value={(!td.tax && td.tax !== 0) ? '' : td.tax}
              customInput={InputBase}
              decimalScale={0}
              fixedDecimalScale
              // prefix={currencySymbol + " "}
              thousandSeparator={true}
              onValueChange={(value, e) => {
                updateTDField({ index: idx, payload: { tax: value.floatValue } });
              }}
            />
          </div>
          <div className={classNames(classes['content-cell'], classes['amount'], classes['padding-1rem'])}>
            <NumberFormat
              value={!(isNaN(td.price)) && !(isNaN(td.tax)) ? td.price + td.tax : ''}
              customInput={InputBase}
              decimalScale={0}
              fixedDecimalScale
              // prefix={currencySymbol + " "}
              thousandSeparator={true}
              onValueChange={(value, e) => {
                updateTDField({ index: idx, payload: { amount: value.floatValue } });
              }}
              inputProps={{ readOnly: true, disabled: true }}
            />
          </div>
          <div className={classNames(classes['content-cell'], classes['remarks'], classes['padding-1rem'])}>
            <TextField
              id="remarks"
              value={td.remarks ? td.remarks.comments : ''}
              onChange={(e) => { updateTDField({ index: idx, payload: { remarks: {comments: e.target.value} } }); }}
            />
          </div>
        </ListItem>

      </div >
    )
  }
}
export const DetailRowComponent = withStyles(styles)(DetailRowComponent_);