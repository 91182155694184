import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  LinearProgress,
  Card,
} from '@material-ui/core';
import { DataGrid, TableToolbar } from '@cosmos/chaos';
import { AutoSizer } from 'react-virtualized';
import { FocusDialog } from '../../components';
import { INIT_USER_LOAD, CLOSE_STOCKCHECK_NOTICE, UPDATE_SELECTED_DATE } from '../../actions';
import { withCmdCode } from '@cosmos/chaos';
import { AlertOutline, CheckboxMarkedCircleOutline } from 'mdi-material-ui';
import { FilterBar } from '../../components/tableFilter'
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  stockcheck: {
    flexGrow: 1,
    display: "flex",
    padding: "0.5em"
  },
  feature: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  "action-button": {
    float: "right",
    margin: "1rem"
  },
  toolset: {
    display: "flex"
  },
  messageBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    marginLeft: "0.5em"
  },
  toolbar: {
    backgroundColor: theme.palette.primary.light
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  card: {
    flex: 1,
    margin: "0.75rem",
    display: "flex",
    flexDirection: "column"
  },
  title: {
    padding: "1rem"
  },
  table: {
    flex: 1
  },
  "error-icon": {
    color: "red"
  },
  "warn-icon": {
    color: "#ff9800"
  },
  "success-icon": {
    color: "#4caf50"
  },
  "spacer":{
    width: "2em"
  },
  "search-input":{
    padding: "6px 6px 6px 60px"
  },
  "header-sortable":{
    display: "flex",
    flexWrap: 'wrap',
    color: "# 333 ",
    justifyContent: "flex-start"
  },
  "header-sortable-index": {
    display: "none",
    fontSize: "80%"
  },
  "header-sortable-active": {
    color: "#000",
    "&.header-sortable-index":{
    display: "block",
    fontSize: "80%"
  }
  },
  "header-sortable-icon": {
  width: '12px',
  height: "12px",
  color: "#3333EE"
  }
})

class HistoryComponent_ extends Component {
  state = {
    orders: new Map(),
  }

  componentDidMount() {
    this.props.dispatch({ type: INIT_USER_LOAD });
    this.props.dispatch({ type: UPDATE_SELECTED_DATE, payload: { selectedDateRange: { start: null, end: null } } });
  }

  updateSorting(key) {
    const orders = this.state.orders;
    if (orders.has(key)) {
      const dir = orders.get(key);
      if (dir === 'asc') {
        orders.set(key, 'desc');
      } else {
        orders.delete(key);
      }
    } else {
      orders.set(key, 'asc');
    }
    this.setState({ orders });
  }

  closeNotification = () => {
    this.props.dispatch({ type: CLOSE_STOCKCHECK_NOTICE });
  }

  handleDateRangeFilter = range => {
    let dRange;
    if (range.start && range.end) {
      dRange = { start: range.start, end: range.end }
    }
    else if (range.start) {
      dRange = { start: range.start, end: null }
    }
    else if (range.end) {
      dRange = { start: null, end: range.end }
    }
    else{
      dRange = { start: null, end: null }
    }
    this.props.dispatch({ type: UPDATE_SELECTED_DATE, payload: { selectedDateRange: dRange } });
  }

  render() {
    const { loading, notify, notice, noticeType, histories, selectedDateRange } = this.props;
    const { orders } = this.state;

    const columns = [
      {
        name: 'state',
        header: 'Status',
        onHeaderClick: () => {
          this.updateSorting('state');
        },
        cell: rowData => {
          switch (rowData.state) {
            case 0:
              return (<CheckboxMarkedCircleOutline className={styles['success-icon']} />)
            case -1:
              return (<AlertOutline className={styles['warn-icon']} />)
            default:
              return (<AlertOutline className={styles['error-icon']} />)
          }
        },
        cellProps: {
          style: { paddingLeft: 20 },
        }
      },
      {
        name: 'stockId',
        header: 'Stock ID',
        onHeaderClick: () => {
          this.updateSorting('stockId');
        },
        cellProps: {
          style: { paddingLeft: 20 },
        }
      },
      {
        name: 'brandName',
        header: 'Brand',
        onHeaderClick: () => {
          this.updateSorting('brandName');
        }
      },
      {
        name: 'productName',
        header: 'Model',
        onHeaderClick: () => {
          this.updateSorting('productName');
        },
      },
      {
        name: 'productReference',
        header: 'Reference',
        onHeaderClick: () => {
          this.updateSorting('productReference');
        }
      },
      {
        name: 'serial',
        header: 'Serial',
        onHeaderClick: () => {
          this.updateSorting('serial');
        }
      },
      {
        name: 'storeName',
        header: 'Location',
        onHeaderClick: () => {
          this.updateSorting('storeName');
        }
      },
      {
        name: 'checker',
        header: 'Checker',
        onHeaderClick: () => {
          this.updateSorting('checker');
        }
      },
      {
        name: 'updatedAt',
        header: 'Checked At',
        onHeaderClick: () => {
          this.updateSorting('updatedAt');
        }
      },
    ]

    return (
      <div className={styles.stockcheck}>
        <Paper className={styles.feature}>
          <TableToolbar
            numSelected={0}
            title="Stockcheck History"
            styles={{ root: styles.toolbar, toolset: styles.toolset, messageBox: styles.messageBox }}
            filterBox={() => <FilterBar
              units={{
                dateRange: {
                  range: selectedDateRange,
                  handleChange: this.handleDateRangeFilter
                }
              }}
            />}
          />

          <FocusDialog open={notify} message={notice} variant={noticeType} onClose={this.closeNotification} />

          {loading && <LinearProgress />}

          <div className={styles.content}>
            <Card className={styles.card}>
              <div className={styles.table}>
                <AutoSizer>
                  {({ width, height }) => (
                    <DataGrid
                      data={histories}
                      columns={columns}
                      width={width}
                      maxHeight={height}
                      height={height}
                      includeHeaders={true}
                      fixedRowCount={1}
                      cellProps={{
                        style: { paddingLeft: 2, paddingRight: 4 },
                      }}
                      isCellHovered={(column, rowData, hoveredColumn, hoveredRowData, ) => {
                        return (rowData && rowData.id === hoveredRowData.id)
                      }}
                      orderBy={orders}
                      classes={{
                        sortableHeader: styles['header-sortable'],
                        sortableHeaderActive: styles['header-sortable-active'],
                        sortableHeaderIcon: styles['header-sortable-icon'],
                        sortIndex: styles['header-sortable-index']
                      }}
                    />
                  )}
                </AutoSizer>
              </div>
            </Card>
          </div>
        </Paper>
      </div >
    )
  }
}

export const HistoryComponent = connect(state => {
  return {
    loading: state.stockcheck.loading,
    notify: state.stockcheck.notify,
    notice: state.stockcheck.notice,
    noticeType: state.stockcheck.noticeType,
    histories: state.stockcheck.histories,
    selectedDateRange: state.stockcheck.selectedDateRange,
  }
})(withCmdCode(withStyles(styles)(HistoryComponent_)));