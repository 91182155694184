import { createVibes } from "@cosmos/vibes";
import { UPDATE_DISC_RATE, applyDiscRateSuccess, applyDiscRateFail } from "../../actions";
import { Abacus } from "@cosmos/harmony";

export const applyDiscRateVibes = createVibes({
  type: [UPDATE_DISC_RATE],
  latest: true,
  processOptions: {
    successType: applyDiscRateSuccess,
    failType: applyDiscRateFail
  },
  async process({ getState, action }) {
    try {
      const discRate = action.payload.discountRate.value.discountRate;
      const newDiscRate = discRate ? parseFloat(discRate) : 0;
      // const { goods, taxRate } = getState().pos; // orignal code
      const { goods, taxRate, prices } = getState().pos;
      const oriPrices = getState().price.prices;

      let pcs = [];
      // let gds = goods.map(gd => {  // orignal code
      let gds = goods.map((gd, idx )=> {
        let oriSellPrice; // orignal code
        if(!gd.priceAdjustment && prices[idx].amount) {
          oriSellPrice = prices[idx].amount
        }else {
          // orignal code - start
          if (gd.priceAdjustment === null || gd.priceAdjustment === undefined) {
            oriSellPrice = oriPrices[gd.productId] ? oriPrices[gd.productId].price : 0;
          }
          else {
            oriSellPrice = gd.priceAdjustment;
          }
          // orignal code - end
        }
        let calculatedOutput = Abacus.calcPriceWithRatio(oriSellPrice, 1, newDiscRate, taxRate, 2);
        pcs.push(calculatedOutput);

        return { ...gd, discountRate: newDiscRate };
      });
      return { discountRate: newDiscRate, goods: gds, prices: pcs };
    } catch (err) {
      return { error: err }
    }
  }
});