import { createVibes } from "@cosmos/vibes";
import {
  OPEN_WARRANTY_NOTICE, UPDATE_CARD_PROGRESS, CARD_PROGRESS_COMPLETED,
  UPLOADED_WARRANTY_PHOTO, SUBMIT_WARRANTY_BACK_PHOTO, SUBMIT_WARRANTY_FRONT_PHOTO, POS_WARRANTY_DIALOG,
} from "../../actions";
import { dataURItoBlob } from "../../services/dataUriToBlob";
import * as shortId from 'shortid';

export const saveWarrantyPhoto = createVibes({
  type: [SUBMIT_WARRANTY_FRONT_PHOTO, SUBMIT_WARRANTY_BACK_PHOTO],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { goods, selected } = getState().pos;

      let dataUri = action.payload;
      let blob = dataURItoBlob(dataUri, {});

      let form = new FormData();
      form.append('fileData', blob);

      let photoIdentifier = shortId.generate();

      const response = await axios.request({
        url: `/upload/STOCK/${photoIdentifier}`,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens,
          'Content-Type': 'multipart/form-data',
        },
        data: form,
        onUploadProgress: progressEvent => {
          let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch({ type: UPDATE_CARD_PROGRESS, payload: { uploadProgress: progress } });
          let msg = '';
          if (progress === 100) {
            switch (action.type) {
              case SUBMIT_WARRANTY_FRONT_PHOTO:
                msg = 'Uploaded card front photo successfully, take a photo for card back now.'
                break;
              case SUBMIT_WARRANTY_BACK_PHOTO:
                msg = 'Uploaded card back photo successfully.'
                break;
              default:
                break;
            }
            dispatch({ type: CARD_PROGRESS_COMPLETED, payload: { notify: true, notice: msg, noticeType: 'success' } });
          }
        }
      })

      if (response.status === 200) {
        let newGoods = goods.map(el => el);
        switch (action.type) {
          case SUBMIT_WARRANTY_FRONT_PHOTO:
            newGoods[selected].cardFront = photoIdentifier;
            dispatch({ type: UPLOADED_WARRANTY_PHOTO, payload: { goods: newGoods } });
            dispatch({ type: POS_WARRANTY_DIALOG, payload: { captureCardBack: true, captureCardFront: false } });
            break;
          case SUBMIT_WARRANTY_BACK_PHOTO:
            newGoods[selected].cardBack = photoIdentifier;
            dispatch({ type: UPLOADED_WARRANTY_PHOTO, payload: { goods: newGoods } });
            dispatch({ type: POS_WARRANTY_DIALOG, payload: { captureCardBack: false } });
            break;
          default:
        }
      }
      else {
        dispatch({ type: OPEN_WARRANTY_NOTICE, payload: { notify: true, notice: 'Fail to  upload warranty photo!', noticeType: 'error' } });
      }
      done();
    } catch (err) {
      dispatch({ type: OPEN_WARRANTY_NOTICE, payload: { notify: true, notice: 'Fail to upload warranty photo!', noticeType: 'error' } });
      done();
    }
  }
});

