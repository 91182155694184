import { createVibes } from "@cosmos/vibes";
import {
  VOID_ADD_ITEM,
  VOID_ADD_ITEM_ERROR,
  VOID_ADD_ITEM_SUCCESS
} from "../../actions";

export const voidItemVibes = createVibes({
  type: [VOID_ADD_ITEM],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action }, dispatch, done) {
    try {
      const label = action.payload.search;
      let { labels, voidMode, voidLabels } = getState().pos;
      if(voidMode && !labels.includes(label)) {
        dispatch({ type: VOID_ADD_ITEM_ERROR, payload: "Stock is not in the cart" });
      }else {
        if (labels.indexOf(label) > -1) {  
          const labels = getState().pos.labels;
          const goods = getState().pos.goods;
          const prices = getState().pos.prices;
    
          let voidPrices = getState().pos.voidPrices;
          let voidGoods = getState().pos.voidGoods;

          if(voidLabels.includes(label)) {
            dispatch({ type: VOID_ADD_ITEM_ERROR, payload: "Stock is already voided" });
          }else {
            voidLabels.push(label);
            const idxGoods = labels.indexOf(label);
            const goodsData = goods[idxGoods];
            const pricesData = prices[idxGoods];
            voidGoods.push(goodsData);
            voidPrices.push(pricesData);
            dispatch({ type: VOID_ADD_ITEM_SUCCESS, payload: { voidLabels, voidGoods, voidPrices } });
          }
          done();
        } 
        done();
      }
    } catch (err) {
      dispatch({ type: VOID_ADD_ITEM_ERROR, payload: "Stock is not found" });
      done();
    }
  }
});