import { read, utils } from "xlsx";

/**
 *
 * @param {File} file
 */
export default async function parseFile(file) {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            //res(e.target.result)
            res(reader.result);
        };
        reader.readAsBinaryString(file);
    })
}
