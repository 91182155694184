import {
    UPDATE_PO_INPUT,
    CREATE_PURCHASE_DETAIL,
    PURCHASE_DETAIL_REMOVED,
    UPDATE_PD_INPUT,
    UPDATE_PD_REMARKS,
    UPDATE_PD_PACKAGE_REMARKS,
    SELECT_PURCHASE_DETAIL,
    UPDATE_STOCK_INPUT, SELECT_STOCK_INPUT,
    CLOSE_STOCK_IN_NOTICE, OPEN_STOCK_IN_NOTICE,
    LOAD_PURCHASE_SUCCESS, LOAD_PURCHASE_ERROR,
    REMOVE_STOCK_INPUT,
    CREATE_PURCHASE_ORDER_ERROR, CREATE_PURCHASE_ORDER_SUCCESS,
    RESET_PURCHASE_FORM, UPDATE_SELECTED_PURCHASE,
    ASK_INIT_PURCHASE, CANCEL_INIT_PURCHASE, CONFIRM_INIT_PURCHASE,
    ASK_INIT_STOCK_IN, CANCEL_INIT_STOCK_IN, CONFIRM_INIT_STOCK_IN,
    REVIEW_PURCHASE, CLOSE_REVIEW_PURCHASE,
    EDIT_PURCHASE, SWAP_MODE, UPDATE_FILTERED_PURCHASES,
    UPDATE_SEARCH_BAR, UPDATE_SELECTED_STATUS, UPDATE_SELECTED_TYPE, UPDATE_SELECTED_RANGE,
    RESET_STOCK_IN_FORM, SUBMIT_STOCKS_ERROR,
    OPEN_PURCHASE_NOTICE, CLOSE_PURCHASE_NOTICE, UPDATE_PURCHASE_ORDER_ERROR,
    TOGGLE_INVOICE_DIALOG,
    UPDATE_PROGRESS, UPLOADED_INVOICE_PHOTO, PROGRESS_COMPLETED, BEGIN_STOCK_IN,
    UPDATE_PO_FORM_DISABLE_BOOL,
    UPDATE_STOCK_IN_DISABLE, UPDATE_DELETED_DETAILS,
    ASK_CREATE_VENDOR, CLOSE_CREATE_VENDOR, CREATE_VENDOR_FAIL, CREATE_VENDOR_SUCCESS,
    UPDATE_PC_DATA_URI, LOAD_IMAGE_TAKEN_SUCCESS,
    CLOSE_PO_STOCK_IN_DIALOG, RETRIEVE_PO_STOCKS_FAIL, UPDATE_PO_STOCK_INPUT, OPEN_PO_STOCK_IN_DIALOG
} from "../actions/purchase";
import * as shortId from 'shortid';

export const completed = "completed";
export const invalid = "invalid";
export const pending = "pending";
export const opening = "opening";

const initialState = {
    purchases: {},
    loading: false,
    purchaseTypes: { 0: { value: 0, label: 'General', name: 'General' }, 1: { value: 1, label: 'Consignment', name: 'Consignment' }, 2: { value: 2, label: 'Used Goods', name: 'Used Goods' } },
    purchaseInput: { taxRate: 0, type: 'EXTERNAL' },
    deletedDetails: [],
    notify: false,
    noticeType: 'info',
    notice: '',
    stockInputs: [],
    selectedStockInput: -1,
    selectedPdIdx: -1,
    selectedPurchaseId: -1,
    conditionOptions: { 0: { label: 'New', value: 0, shortForm: 'N' }, 1: { label: 'Never Used', value: 1, shortForm: 'V' }, 2: { label: 'Brand New', value: 2, shortForm: 'B' }, 3: { label: 'Used', value: 3, shortForm: 'U' } },
    monthOptions: { 1: { label: 'Jan', value: 1 }, 2: { label: 'Feb', value: 2 }, 3: { label: 'Mar', value: 3 }, 4: { label: 'Apr', value: 4 }, 5: { label: 'May', value: 5 }, 6: { label: 'Jun', value: 6 }, 7: { label: 'Jul', value: 7 }, 8: { label: 'Aug', value: 8 }, 9: { label: 'Sep', value: 9 }, 10: { label: 'Oct', value: 10 }, 11: { label: 'Nov', value: 11 }, 12: { label: 'Dec', value: 12 } },
    purchaseStatusList: {
        // 'PREPARE': { name: 'PREPARE' },
        // 'PROFORMA': { name: 'PROFORMA' },
        // 'PAID': { name: 'PAID' },
        // 'RECEIVED': { name: 'RECEIVED' },
        // 'CHECKED': { name: 'CHECKED' },
        // 'CLOSED': { name: 'CLOSED' },
        // 'CANCELLED': { name: 'CANCELLED' }
        'PREPARE': { name: 'PREPARE' }, // proforma purchase status
        'CONFIRMED': { name: 'CONFIRMED' }, // proforma purchase status
        'STOCKIN': { name: 'STOCKIN' }, // proforma purchase status
        'SETTLED': { name: 'SETTLED' },  // proforma purchase status
        'CLOSED': { name: 'CLOSED' },   // proforma purchase status
        'CANCELLED': { name: 'CANCELLED' }, // proforma purchase status
        'CONSIGNED': { name: 'CONSIGNED' }, // proforma purchase status
    },
    initPurchaseDialog: false,
    initStockInDialog: false,
    reviewPurchaseDialog: false,
    reviewedPurchaseId: -1,
    editMode: false,
    filteredPurchases: [],
    searchText: '',
    selectedTypes: [],
    selectedStatuses: [],
    selectedDateRange: { start: null, end: null },
    purchaseOnce: shortId.generate(),
    stockInOnce: shortId.generate(),
    captureInvoice: false,
    uploadProgress: -1,
    disablePo: true,
    disableStockIn: true,
    askCreateVendor: false,
    dataUri: null,
    imageBoxUris: [],
    stockInInput: {
        isConsigned: false
    },
    stockInDialogOpen: false,
};

export const purchaseReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PO_INPUT: {
            let updatedPurchaseInput = { ...state.purchaseInput, ...action.payload }
            const shouldFixQuantity = ['CONSIGNMENT', 'USED GOODS'].includes(action.payload.type)
            if (shouldFixQuantity) {
                updatedPurchaseInput.purchaseDetails = updatedPurchaseInput.purchaseDetails ?
                    updatedPurchaseInput.purchaseDetails.map(purchaseDetail => ({ ...purchaseDetail, quantityExpected: 1 }))
                    : []
            }
            localStorage.setItem('purchaseInput', JSON.stringify(updatedPurchaseInput));
            return { ...state, purchaseInput: updatedPurchaseInput }
        }
        case CREATE_PURCHASE_DETAIL: {
            let newPurchaseDetails = state.purchaseInput.purchaseDetails ? state.purchaseInput.purchaseDetails : [];
            if (state.purchaseInput && state.purchaseInput.discountRate !== undefined) {
                newPurchaseDetails.push({ discountRate: state.purchaseInput.discountRate });
            } else {
                newPurchaseDetails.push({});
            }
            localStorage.setItem('purchaseInput', JSON.stringify({ ...state.purchaseInput, purchaseDetails: newPurchaseDetails }));
            localStorage.setItem('selectedPdIdx', JSON.stringify(newPurchaseDetails.length - 1));
            return { ...state, purchaseInput: { ...state.purchaseInput, purchaseDetails: newPurchaseDetails }, selectedPdIdx: newPurchaseDetails.length - 1 };
        }
        case UPDATE_PD_INPUT: {
            let newDetailInput = { ...state.purchaseInput.purchaseDetails[action.payload.index], ...action.payload.payload };
            const updatedPurchaseInput = { ...state.purchaseInput, purchaseDetails: [...state.purchaseInput.purchaseDetails.slice(0, action.payload.index), newDetailInput, ...state.purchaseInput.purchaseDetails.slice(action.payload.index + 1)] }
            localStorage.setItem('purchaseInput', JSON.stringify(updatedPurchaseInput));
            return { ...state, purchaseInput: updatedPurchaseInput };
        }
        case UPDATE_PD_PACKAGE_REMARKS: {
            let newPd = { ...state.purchaseInput.purchaseDetails[action.payload.index] };
            let newRm = { ...newPd.remarks }
            if (newRm.package) {
                newRm.package = { ...newRm.package, ...action.payload.payload.package };
            }
            else {
                newRm.package = action.payload.payload.package;
            }
            newPd.remarks = newRm;
            const updatedState = {
                ...state,
                purchaseInput: {
                    ...state.purchaseInput,
                    purchaseDetails: [...state.purchaseInput.purchaseDetails.slice(0, action.payload.index),
                        newPd,
                    ...state.purchaseInput.purchaseDetails.slice(action.payload.index + 1)]
                }
            }
            localStorage.setItem('purchaseInput', JSON.stringify(updatedState.purchaseInput));
            return updatedState;
        }
        case UPDATE_PD_REMARKS: {
            const updatedState = {
                ...state,
                purchaseInput: {
                    ...state.purchaseInput,
                    purchaseDetails: [...state.purchaseInput.purchaseDetails.slice(0, action.payload.index),
                    {
                        ...state.purchaseInput.purchaseDetails[action.payload.index],
                        remarks: {
                            ...state.purchaseInput.purchaseDetails[action.payload.index].remarks,
                            ...action.payload.payload
                        }
                    }, ...state.purchaseInput.purchaseDetails.slice(action.payload.index + 1)]
                }
            }
            localStorage.setItem('purchaseInput', JSON.stringify(updatedState.purchaseInput));
            return updatedState;
        }
        case SELECT_PURCHASE_DETAIL: {
            localStorage.setItem('selectedPdIdx', JSON.stringify((state.purchaseInput.purchaseDetails && action.payload >= state.purchaseInput.purchaseDetails.length) ? state.selectedPdIdx : action.payload));
            return { ...state, selectedPdIdx: (state.purchaseInput.purchaseDetails && action.payload >= state.purchaseInput.purchaseDetails.length) ? state.selectedPdIdx : action.payload }
        }
        case SELECT_STOCK_INPUT: {
            localStorage.setItem('selectedStockInput', JSON.stringify(action.payload));
            return { ...state, selectedStockInput: action.payload }
        }
        case UPDATE_STOCK_INPUT: {
            let originalStockInput = (state.stockInputs && state.stockInputs[action.payload.idx]) ? state.stockInputs[action.payload.idx] : {};
            let newStockInput;
            if (action.payload.value.package) {
                let updatedPackage = { ...(originalStockInput.package || {}), ...action.payload.value.package }
                newStockInput = { ...originalStockInput, ...action.payload.value, package: updatedPackage };
            } else {
                newStockInput = { ...originalStockInput, ...action.payload.value };
            }

            const updatedStockInputs = [...state.stockInputs.slice(0, action.payload.idx), newStockInput, ...state.stockInputs.slice(action.payload.idx + 1)]
            localStorage.setItem('stockInputs', JSON.stringify(updatedStockInputs));
            return { ...state, stockInputs: updatedStockInputs };
        }
        case CLOSE_STOCK_IN_NOTICE:
        case CLOSE_PURCHASE_NOTICE:
            return { ...state, notify: false, notice: '', noticeType: 'info' }
        case UPDATE_STOCK_IN_DISABLE:
        case OPEN_STOCK_IN_NOTICE:
        case OPEN_PURCHASE_NOTICE:
        case UPDATE_FILTERED_PURCHASES:
        case UPDATE_SEARCH_BAR:
        case UPDATE_SELECTED_STATUS:
        case UPDATE_PROGRESS:
        case UPDATE_PO_FORM_DISABLE_BOOL:
        case PROGRESS_COMPLETED:
        case PURCHASE_DETAIL_REMOVED:
        case UPDATE_DELETED_DETAILS:
        case LOAD_IMAGE_TAKEN_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        case UPLOADED_INVOICE_PHOTO:
            localStorage.setItem('purchaseInput', JSON.stringify(action.payload.purchaseInput));
            return { ...state, ...action.payload };
        case LOAD_PURCHASE_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case UPDATE_SELECTED_TYPE:
        case UPDATE_SELECTED_RANGE:
        case CREATE_VENDOR_FAIL:
        case CREATE_VENDOR_SUCCESS:
        case UPDATE_PC_DATA_URI:
            return { ...state, ...action.payload }
        case LOAD_PURCHASE_ERROR:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case REMOVE_STOCK_INPUT:
            let newStockInputs = state.stockInputs ? state.stockInputs : [];
            newStockInputs = [...newStockInputs.slice(0, action.payload), ...newStockInputs.slice(action.payload + 1)];
            const newSelectedStk = (state.selectedStockInput < action.payload) ? state.selectedStockInput : ((state.selectedStockInput > action.payload) ? state.selectedStockInput - 1 : ((state.selectedStockInput === 0) ? -1 : state.selectedStockInput - 1));
            localStorage.setItem('stockInputs', JSON.stringify(newStockInputs));
            localStorage.setItem('selectedStockInput', JSON.stringify(newSelectedStk));
            return { ...state, stockInputs: newStockInputs, selectedStockInput: newSelectedStk };
        case CREATE_PURCHASE_ORDER_ERROR:
            return { ...state, notify: true, notice: 'Purchase creation is failed', noticeType: 'error', purchaseOnce: shortId.generate() };
        case UPDATE_PURCHASE_ORDER_ERROR:
            return { ...state, notify: true, notice: 'Purchase update is failed', noticeType: 'error', purchaseOnce: shortId.generate() };
        case CREATE_PURCHASE_ORDER_SUCCESS:
            let newPoList = { ...state.purchase };
            newPoList[action.payload.id] = action.payload;
            return { ...state, purchases: newPoList, notify: true, notice: 'Purchase is created successfully', noticeType: 'success' };
        case RESET_PURCHASE_FORM:
            localStorage.removeItem('purchaseInput');
            localStorage.removeItem('deletedDetails');
            localStorage.removeItem('selectedPdIdx');
            localStorage.removeItem('editMode');
            return {
                ...state,
                purchaseInput: { taxRate: 0, type: 0 },
                selectedPdIdx: -1,
                notify: false, notice: '', noticeType: 'info',
                purchaseOnce: shortId.generate(),
                captureInvoice: false,
                uploadProgress: -1,
                disablePo: true,
                disableStockIn: true,
                deletedDetails: [],
                askCreateVendor: false,
                dataUri: null,
            }
        case UPDATE_SELECTED_PURCHASE:
            localStorage.setItem('selectedPurchaseId', JSON.stringify(action.payload.selectedPurchaseId));
            return { ...state, ...action.payload }
        case SUBMIT_STOCKS_ERROR:
            return { ...state, stockInOnce: shortId.generate() }
        case BEGIN_STOCK_IN:
            localStorage.removeItem('stockInputs');
            localStorage.removeItem('selectedStockInput');
            return {
                ...state,
                stockInOnce: shortId.generate(),
                stockInputs: [],
                selectedStockInput: -1,
            }
        case RESET_STOCK_IN_FORM:
            localStorage.removeItem('stockInputs');
            localStorage.removeItem('selectedPurchaseId');
            localStorage.removeItem('selectedStockInput');
            localStorage.removeItem('purchaseInput');
            localStorage.removeItem('deletedDetails');
            localStorage.removeItem('selectedPdIdx');
            localStorage.removeItem('editMode');
            return {
                ...state,
                stockInOnce: shortId.generate(),
                stockInputs: [],
                selectedStockInput: -1,
                selectedPurchaseId: -1,
                purchaseInput: { taxRate: 0, type: 0 },
                selectedPdIdx: -1,
                notify: false, notice: '', noticeType: 'info',
                purchaseOnce: shortId.generate(),
                captureInvoice: false,
                uploadProgress: -1,
                disablePo: true,
                disableStockIn: true,
                deletedDetails: [],
                askCreateVendor: false,
                dataUri: null,
            }
        case ASK_INIT_PURCHASE:
            return { ...state, initPurchaseDialog: true }
        case CANCEL_INIT_PURCHASE:
            localStorage.removeItem('purchaseInput');
            localStorage.removeItem('deletedDetails');
            localStorage.removeItem('selectedPdIdx');
            localStorage.removeItem('editMode');
            return {
                ...state,
                initPurchaseDialog: false,
                editMode: false,
                purchaseInput: { taxRate: 0, type: 0 },
                selectedPdIdx: -1,
                notify: false, notice: '', noticeType: 'info',
                purchaseOnce: shortId.generate(),
                captureInvoice: false,
                uploadProgress: -1,
                disablePo: true,
                disableStockIn: true,
                deletedDetails: [],
                askCreateVendor: false,
                dataUri: null,
            }
        case CONFIRM_INIT_PURCHASE:
            return {
                ...state,
                initPurchaseDialog: false,
                purchaseInput: JSON.parse(localStorage.getItem('purchaseInput')),
                selectedPdIdx: JSON.parse(localStorage.getItem('selectedPdIdx')),
                editMode: JSON.parse(localStorage.getItem('editMode'))
            }
        case ASK_INIT_STOCK_IN:
            return { ...state, initStockInDialog: true }
        case CANCEL_INIT_STOCK_IN:
            localStorage.removeItem('stockInputs');
            localStorage.removeItem('selectedPurchaseId');
            localStorage.removeItem('selectedStockInput');
            localStorage.removeItem('purchaseInput');
            localStorage.removeItem('deletedDetails');
            localStorage.removeItem('selectedPdIdx');
            localStorage.removeItem('editMode');
            localStorage.removeItem('stockInInput');
            return { ...state, 
                initStockInDialog: false, 
                stockInOnce: shortId.generate(), 
                stockInputs: [], 
                selectedStockInput: -1, 
                selectedPurchaseId: -1,
                purchaseInput: { taxRate: 0, type: 0 },
                selectedPdIdx: -1,
                notify: false, notice: '', noticeType: 'info',
                purchaseOnce: shortId.generate(),
                captureInvoice: false,
                uploadProgress: -1,
                disablePo: true,
                disableStockIn: true,
                deletedDetails: [],
                askCreateVendor: false,
                dataUri: null,
                stockInInput: {
                    isConsigned: false
                }
            }

        case CONFIRM_INIT_STOCK_IN:
            return {
                ...state,
                initStockInDialog: false,
                stockInputs: JSON.parse(localStorage.getItem('stockInputs')),
                selectedPurchaseId: JSON.parse(localStorage.getItem('selectedPurchaseId')),
                selectedStockInput: JSON.parse(localStorage.getItem('selectedStockInput')),
                stockInInput: JSON.parse(localStorage.getItem('stockInInput')),
            }
        case REVIEW_PURCHASE:
            return { ...state, reviewPurchaseDialog: true, reviewedPurchaseId: action.payload }
        case CLOSE_REVIEW_PURCHASE:
            return { ...state, reviewPurchaseDialog: false, reviewedPurchaseId: -1, dataUri: null }
        case EDIT_PURCHASE:
            return { ...state, reviewPurchaseDialog: false }
        case SWAP_MODE:
            localStorage.setItem('editMode', JSON.stringify(action.payload));
            return { ...state, editMode: action.payload }
        case TOGGLE_INVOICE_DIALOG:
            return { ...state, captureInvoice: !state.captureInvoice };
        case ASK_CREATE_VENDOR:
            return { ...state, askCreateVendor: true };
        case CLOSE_CREATE_VENDOR:
            return { ...state, askCreateVendor: false, stockInInput:{...state.stockInInput, vendor:null}  };
        case OPEN_PO_STOCK_IN_DIALOG:
            return { ...state, stockInDialogOpen: true }
        case CLOSE_PO_STOCK_IN_DIALOG:
            return { ...state, stockInDialogOpen: false }
        case RETRIEVE_PO_STOCKS_FAIL:
            return { ...state, notify: true, notice: 'Purchase order reference is invalid', noticeType: 'error' }
        case UPDATE_PO_STOCK_INPUT:
            const newPurchaseInputStock = { ...state.stockInInput, ...action.payload }
            localStorage.setItem('stockInInput', JSON.stringify(newPurchaseInputStock));
            return { ...state, stockInInput: newPurchaseInputStock }
        default:
            return state;
    }
};