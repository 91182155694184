import React from 'react';
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Login } from "@cosmos/chaos";
import { makeStyles } from "@material-ui/styles";
import { getEntryPoint } from "../../constants";

const useStyles = makeStyles(theme => ({
    header: {
        background: `${theme.palette.primary.main}`,
        borderBottom: `${theme.palette.primary.dark}`
    },
}));

export const Landing = (
    {
        location,
        ...props
    }) => {
    const styles = useStyles();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const permission = useSelector(state => state.auth.permission);
    const features = useSelector(state => state.system.features);
    if (!isAuthenticated) {
        return <Login vibes
            title={'Cosmos System'}
            styles={styles} />
    }

    const entry = getEntryPoint(features, permission);
    if (entry) {
        return <Redirect to={entry.location} />
    } else {
        // Should be 404
        return <p>404 not found</p>
    }
};