import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import useHeaders from '../../../hooks/useHeaders'
import NoImagePlaceholder from '../../../components/NoImagePlaceholder'

// SAME FUNCTION AS WALK IN PURCHASE
const useStyles = makeStyles({
    root: {
        padding: '20px',
        textAlign: 'center'
    }
})

export default function ProductDisplay({ product }) {
    const classes = useStyles()
    const [imageURLs, setImageURLs] = useState([])
    const headers = useHeaders()

    useEffect(() => {
        async function fetchImages() {
            const assets = product.product.assets
            if (!assets) return
            try {
                const blobArray = await Promise.all(assets.map((asset, index) => axios.request({
                    url: `/file/product/${product.id}/${index}`,
                    method: 'get',
                    headers,
                    responseType: "blob"
                })
                ))
                const urls = blobArray.map(blob => new Blob([blob], { type: 'image/png' })).map(file => URL.createObjectURL(file))
                setImageURLs(urls)
            } catch (e) {
            }
        }
        fetchImages()
    }, [headers, product.id, product.product.assets])
    
    return (
        <div className={classes.root}>
            {imageURLs.length > 0 ? imageURLs.map((imageURL, index) => <img src={imageURL} alt={''} style={{margin: '0 auto'}} />) : <NoImagePlaceholder width={500} height={500} />}
        </div>
    )
}