import moment from "moment";
import {
    INV_LOAD_BEGIN,
    INV_LOAD_SUCCESS,
    INV_LOAD_ERROR,
    OPEN_STOCK_IN_DIALOG,
    CLOSE_STOCK_IN_DIALOG,
    FILTER_INV_SUCCESS,
    UPDATE_SEARCH_KEY,
    BRAND_FILTER_UPDATED,
    PROVISION_FILTER_UPDATED,
    CONDITION_FILTER_UPDATED,
    HOLDER_FILTER_UPDATED,
    OPEN_INVENTORY_NOTICE,
    CLOSE_INVENTORY_NOTICE,
    UPDATE_SELECTED_STOCK_PERSON, UPDATE_SELECTED_STOCK_STATE,
    REMOVE_EXPECTED_STOCK,
    BEGIN_CHANGE_LABEL,
    CLOSE_CHANGE_LABEL,
    UPDATE_SEARCHED_STOCK,
    SEARCH_STOCK_FAIL,
    SEARCH_LABEL_FAIL,
    SEARCH_LABEL_SUCCESS,
    SUBMIT_CHANGE_LABEL_ERROR,
    SUBMIT_CHANGE_LABEL_SUCCESS,
    SUPPLIER_FILTER_UPDATED,
    COUNTRY_FILTER_UPDATED,
    OPEN_IMAGE_DIALOG,
    CLOSE_IMAGE_DIALOG,
    SET_DATA_URIS,
} from '../actions'

const initialState = {
    loading: false,
    stocks: {},
    stocksDetail: {},
    stocksExtra: {},
    stockInDialogOpen: false,
    dataRenderedTime: moment().format(),
    search: '',
    filteredStocks: [],
    holderFilter: [],
    brandFilter: [],
    provisionFilter: [],
    conditionFilter: [],
    supplierFilter: [],
    countryFilter: [],
    notify: false,
    noticeType: 'info',
    notice: '',
    conditionList: {
        0: { value: 0, name: 'New', label: 'New', code: 'N' },
        1: { value: 1, name: 'Never Used', label: 'Never Used', code: 'NU' },
        2: { value: 2, name: 'Brand New', label: 'Brand New', code: 'BN' },
        3: { value: 3, name: 'Used', label: 'Used', code: 'U' }
    },
    provisionList: {
        0: { value: 0, name: 'New', label: 'New' },
        1: { value: 1, name: 'Second Hand', label: 'Second Hand' },
        2: { value: 2, name: 'Consignment', label: 'Consignment' },
    },
    selectedStockPics: [],
    selectedStockStates: [],
    changeLabelDialog: false,
    searchedStock: null,
    newLabel: null,
    imageDialog: false,
    dataUris: [],
}

export const inventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case INV_LOAD_BEGIN:
            return {
                ...state,
                loading: true,
                search: '',
                holderFilter: [],
                brandFilter: [],
                supplierFilter: [],
                provisionFilter: [],
                conditionFilter: [],
                selectedStockPics: [],
                selectedStockStates: [],
            }
        case INV_LOAD_SUCCESS:
            return {
                ...state,
                ...action.payload,
                dataRenderedTime: moment().format(),
                loading: false
            }
        case INV_LOAD_ERROR:
            return { ...state, loading: false }
        case OPEN_STOCK_IN_DIALOG:
            return { ...state, stockInDialogOpen: true }
        case CLOSE_STOCK_IN_DIALOG:
            return { ...state, stockInDialogOpen: false }
        case OPEN_INVENTORY_NOTICE:
        case UPDATE_SELECTED_STOCK_PERSON:
        case UPDATE_SELECTED_STOCK_STATE:
        case UPDATE_SEARCHED_STOCK:
        case SEARCH_STOCK_FAIL:
        case SEARCH_LABEL_FAIL:
        case SEARCH_LABEL_SUCCESS:
        case SUBMIT_CHANGE_LABEL_ERROR:
        case SUBMIT_CHANGE_LABEL_SUCCESS:
        case SET_DATA_URIS:
            return { ...state, ...action.payload }
        case CLOSE_INVENTORY_NOTICE:
            return { ...state, notify: false, noticeType: 'info', notice: '' }
        case UPDATE_SEARCH_KEY:
            return { ...state, search: action.payload }
        case FILTER_INV_SUCCESS:
            return { ...state, ...action.payload }
        case BRAND_FILTER_UPDATED:
            return { ...state, brandFilter: action.payload }
        case PROVISION_FILTER_UPDATED:
            return { ...state, provisionFilter: action.payload }
        case CONDITION_FILTER_UPDATED:
            return { ...state, conditionFilter: action.payload }
        case SUPPLIER_FILTER_UPDATED:
            return { ...state, supplierFilter: action.payload }
        case HOLDER_FILTER_UPDATED:
            return { ...state, holderFilter: action.payload }
        case COUNTRY_FILTER_UPDATED:
            return { ...state, countryFilter: action.payload }
        case REMOVE_EXPECTED_STOCK:
            return { ...state, ...action.payload }
        case BEGIN_CHANGE_LABEL:
            return { ...state, changeLabelDialog: true }
        case CLOSE_CHANGE_LABEL:
            return { ...state, changeLabelDialog: false, searchedStock: null, newLabel: null }
        case OPEN_IMAGE_DIALOG:
            return { ...state, imageDialog: true }
        case CLOSE_IMAGE_DIALOG:
            return { ...state, imageDialog: false }
        default:
            return state
    }
}