import { useSelector } from 'react-redux'
import { useMemo } from 'react'

// SAME IMPLEMENTAITON AS WALK IN PURCHASE

/**
 * @typedef {Object} Headers
 * @property {string} headers.authorization - Bearer authorization - authorization: `Bearer ${token}`
 * @property {string} headers.rayLens - rayLens ID
 */

/**
 * Custom hook to get headers from redux store
 * @return {Headers} headers
 */
export default function useHeaders() {
    const token = useSelector(state => state.auth.token)
    const rayLens = useSelector(state => state.system.rayLens)
    const headers = useMemo(() => ({
        authorization: `Bearer ${token}`,
        rayLens
    }), [token, rayLens])
    return headers
}