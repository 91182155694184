import React from 'react';
import { Component } from 'react';
import {
  Fab
} from '@material-ui/core';
import { Plus } from "mdi-material-ui";
import { DetailRowComponent } from './detailRow';
import { HeaderRow } from './header';

import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    "flex-row": {
        display: "flex",
        flexDirection: "row"
    },
    "flex-column": {
        display: "flex",
        flexDirection: "column"
    }
})

export class DetailComponent_ extends Component {

  createDetail = () => {
    this.props.createNewDetail();
  }

  handleDeleteRow = (idx) => {
    this.props.removeDetail(idx);
  }

  handleSelectRow = (idx) => {
    this.props.selectDetail(idx);
  }

  render() {
    const { tenderInput, products, updateTDField, selectedTdIdx, currencySymbol, classes } = this.props;
    return (
      <div className={classes['detail']}>
        <div className={classes['new-button']}>
          <Fab color="primary" size="small" onClick={this.createDetail}>
            <Plus />
          </Fab>
        </div>
        <div className={classes['list']}>
          <HeaderRow />
          {
            tenderInput.details && tenderInput.details.map((td, idx) =>
              (
                <DetailRowComponent
                  key={`td_${idx}`}
                  td={td}
                  idx={idx}
                  products={products}
                  handleDeleteRow={() => { this.handleDeleteRow(idx) }}
                  handleSelectRow={() => { this.handleSelectRow(idx) }}
                  updateTDField={updateTDField}
                  selectedTdIdx={selectedTdIdx}
                  currencySymbol={currencySymbol}
                />
              )
            )
          }
        </div>
      </div>
    )
  }
}
export const DetailComponent = withStyles(styles)(DetailComponent_);