import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Paper from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
	contentList: {
        flex: 1,
        padding: '1.5em',
        marginBottom: '1em',
        display: 'flex',
		flexDirection: 'row',
    },
    contentProduct: {
        flexBasis: '25%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentImage: {
        flexBasis: '25%',
        justifyContent: 'center',
        display: 'flex',
    },
    contentStock: {
        flexBasis: '50%'
    },
    stock: {
        flexBasis: '60%'
    },
    stockImg: {
        flexBasis: '40%',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
	photo: {
		background: 'red',
		width: '30px',
        height: '30px',
        cursor: 'pointer',
    },
    quantity: {
		paddingTop: '10px',
    },
    label: {
        paddingRight: '10px'
    }
}))

const Stock = ({ stock, classes, isHover }) => {
    // const setHover = (status) => {
    //     isHover(status);
    // }
    return (
    <Paper className={classes.contentList}>
        <div className={classes.contentProduct}>
            <Typography><strong>Model: </strong> <strong>{ stock.brandName }</strong> - {stock.productSKU} <small><em>{ stock.productName }</em></small></Typography>
            <Typography><strong>Quantity: </strong>{ stock.quantity }</Typography>
            <Typography><strong>Price: </strong>{ stock.currency } { stock.subTotal }</Typography>
        </div>
        <div className={classes.contentImage}>
            {stock.image ? <img src={stock.image} height={300} width={300} alt={'Preview Product'} /> : <p>No image found</p>}
        </div>
        <div className={classes.contentStock}>
            {stock.productDetail && stock.productDetail.map((productData) => { 
                return (
                <StockData
                    key={productData.stockId}
                    stockData={productData}
                    classes={classes}
                    isHover={isHover}
                />)
            })}
        </div>
    </Paper>)
};

const StockData = ({ stockData, classes, isHover }) => {
    const setHover = (imgPath) => {
        isHover(imgPath);
    }
    return (
    <Paper className={classes.contentList}>
        <div className={classes.stock}>
            <Typography ><strong>Stock ID: </strong> { stockData.stockId }</Typography>
            <Typography><strong>Model: </strong><strong>{ stockData.brandName }</strong> - {stockData.productSKU} <small><em>{ stockData.productName }</em></small></Typography>
            <Typography><strong>Price: </strong>{ stockData.currency } { stockData.price ? stockData.price : "-" }</Typography>
        </div>
        <div className={classes.stockImg}>
            <img src={stockData["front-img"]} height={100} width={100} alt={'Preview Watch Front'} 
                onMouseOver={() => {
                    if(stockData["front-img"]) {
                        return setHover(stockData["front-img"])
                    }
                }}
            />
        </div>
    </Paper>
)};

const StockInListDetail = ({ stocks, getStockImg }) => {

    const isHover = (status) => {
        getStockImg(status);
    }
    const classes = useStyles();
    return (
        <div>
            {stocks ? stocks.map((stock) => (
                <Stock
                    key={stock.productID ? stock.productID : 0}
                    stock={stock}
                    classes={classes}
                    isHover={isHover}
                />
            )) : <p>No stock found</p>}
        </div>
    );
};

export default StockInListDetail;
