import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    TextField,
    List,
    ListItem,
    Card,
    CardHeader,
    CardContent,
    CardMedia,
    CardActions,
    Button,
    IconButton,
    ListItemText,
    makeStyles,
    LinearProgress,
    alpha,
    Box,
    Input,
} from "@material-ui/core";
import {
    SAVE_PRICE_SUPPLY,
    SELECT_PRICE_BRAND,
    SELECT_PRICE_CURRENCY,
    SELECT_PRICE_PRODUCT,
    SELECT_PRICE_PRICE,
    SELECT_TAX_RATE,
    SELECT_PRICE_SECTOR,
    SELECT_PRICE_QUANTITY,
    SELECT_PRICE_SUPPLIER,
    RESET_TARGET,
    SELECT_PRICE_AMOUNT,
    TRADER_CREATE_NEW,
    TRADER_CLEAR_ERRORS
} from "../../actions";
import { NumberFormatCustom } from "../../components/numbers";
import classNames from "classnames";
import { lighten } from "@material-ui/core/styles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ContentSave, MinusBoxOutline, PlusBoxOutline } from "mdi-material-ui";
import Skeleton from "@material-ui/lab/Skeleton";
import { push } from "connected-react-router";
import { isEmpty } from "lodash"
import { BasicDialog } from "../../components/dialog/BasicDialog";
import BrandInput from "./BrandInput";

const useStyles = makeStyles((theme) => ({
    header: {},
    headerOn: {
        backgroundColor: lighten(theme.palette.primary.light, 0.8),
    },
    item: {
        borderRadius: "0.25em",
        marginBottom: "0.25em",
    },
    itemReady: {
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
    },
    mediaBox: {
        position: "relative",
    },
    media: {
        height: "200px",
    },
    mediaBig: {
        height: "400px",
    },
    mediaZoom: {
        position: "absolute",
        right: "4px",
        bottom: "4px",
    },
    sectorRoot: {
        backgroundColor: alpha(theme.palette.primary.light, 0.25),
        "&$sectorSelected": {
            backgroundColor: theme.palette.success.light,
        },
    },
    sectorSelected: {},
    label: {
        minWidth: "6em",
        flex: 1,
    },
    fields: {
        display: "flex",
        flex: 3,
    },
    input: {
        marginLeft: "1em",
    },
    actionPanel: {
        padding: "16px",
        justifyContent: "flex-end",
    },
    actionButtons: {
        color: "white",
    },
    pdLabel: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
    },
    pdBrand: {
        marginRight: "1em",
    },
    pdReference: {
        flex: 1,
    },
    pdColor: {
        fontSize: "80%",
        marginLeft: "1em",
        width: "6em",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    newProductCard: {
        marginTop: "1.5em",
    },
}));
export const PriceTagEditor = ({ className }) => {
    // Hooks for dispatch
    const dispatch = useDispatch();
    // Hooks for state data
    const loading = useSelector(
        (state) => state.catalog.loading || state.marketing.loading
    );
    const processing = useSelector((state) => state.marketing.processing);
    const currencies = useSelector((state) => state.system.currencies);
    const products = useSelector((state) => state.catalog.products);
    const brands = useSelector((state) => state.catalog.brands);
    const sectors = useSelector((state) => state.marketing.sectors);
    const traders = useSelector((state) => state.trader.traders);
    const countryCodes = useSelector(state => state.system.countryCodes);
    const countryList = useSelector(state => state.system.countries);

    // Hooks for variable
    const target = useSelector((state) => state.marketing.target);

    const [product, setProduct] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [country, setCountry] = useState({});
    const [currency, setCurrency] = useState(null);
    const [zoom, setZoom] = useState(false);
    const [dirty, setDirty] = useState(false);
    const vendorRef = useRef();
    const [countryDialogToggle, setCountryDialogToggle] = useState(false);
    const [newTrader, setNewTrader] = useState("");
    const error = useSelector(state => state.trader.error);
    const [countries, setCountries] = useState({});
    useEffect(() => {
        if (!isEmpty(countryCodes) && !isEmpty(countryList)) {
            let cc = {};
            for (const [c, i] of Object.entries(countryCodes)) {
                cc[c] = { ...countryList[i], isoCode: c };
            }
            setCountries(cc);
        }

    }, [countryCodes, countryList]);

    useEffect(() => {
        const supplier = traders[target.supplier];
        if (supplier) {
            setSupplier(supplier);
            const cc = countries[supplier.isoCode]; //{id, name, currencyId}
            setCountry({ ...cc, isoCode: supplier.isoCode });
        } else {
            setSupplier(null);
        }
    }, [target, traders, countries, dispatch]);

    useEffect(() => {
        const currency = currencies[target.currencyId];
        setCurrency(currency);
    }, [setCurrency, target, currencies])

    const [imageURL, setImageURL] = useState("");
    useEffect(() => {
        if (loading) return;

        const product = products[target.productId];
        if (product) {
            setProduct(product);
        } else {
            return setProduct(null);
        }
        const asset = product.assets && product.assets[0];
        if (!asset) {
            return setImageURL("");
        } else {
            setImageURL(`/images/${asset.reference}`);
        }
    }, [target, loading, products, setProduct, setImageURL]);

    const [readyForSubmit, setReadyForSubmit] = useState(false);
    useEffect(()=>{
        setReadyForSubmit(
            target.supplier > 0 &&
            target.productId > 0 &&
            target.quantity > 0 &&
            (target.price && target.price > 0)

        )
    }, [setReadyForSubmit, target]);

    const styles = useStyles();
    return (
        <>
            <BasicDialog
                title={"Error"}
                open={!!error}
                fullScreen={false}
                fullWidth={true}
                maxWidth="lg"
                onClose={() => {
                    dispatch({ type: TRADER_CLEAR_ERRORS })
                }}
            >{error}
            </BasicDialog>
            <BasicDialog
                title={`Select country for ${newTrader}`}
                open={!!countryDialogToggle}
                fullScreen={false}
                fullWidth={true}
                maxWidth="lg"
                actions={() => (
                    <Button
                        color="primary"
                        onClick={() => {
                            setCountryDialogToggle(false);
                            dispatch({ type: TRADER_CREATE_NEW, payload: { name: newTrader, isoCode: country.isoCode } });
                        }}
                        disabled={false}
                    >
                        CREATE
                    </Button>
                )
                }
                onClose={() => {
                    setCountryDialogToggle(false);
                    dispatch({ type: TRADER_CLEAR_ERRORS })
                }}
            > <div>
                    <div className={styles.root}>
                        <div>
                            <Autocomplete
                                disableClearable
                                loading={Object.values(countries).length === 0}
                                autoHighlight
                                filterOptions={(options, params) => options.filter(x => x.name)}
                                options={Object.entries(countries).reduce((set, [k, v]) => ([...set, { isoCode: k, ...v }]), [])}
                                getOptionLabel={(option) => {
                                    if (isEmpty(option)) return "";
                                    if (typeof option === "string") {
                                        return option;
                                    }
                                    return option.name;
                                }}
                                onChange={(event, value, reason) => {
                                    setCountry(value);
                                }}
                                value={country || {}} //code -> {}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country"
                                        margin="dense"
                                    />)}
                            />
                            {error && <div style={{ color: "red" }}>Error: {error}</div>}
                        </div>
                    </div>
                </div>
            </BasicDialog >
            <div>
                <Card>
                    <CardHeader
                        className={classNames(
                            styles.header,
                            dirty && styles.headerOn
                        )}
                        title={
                            !!target.id
                                ? `Edit Supply Offer: ${target.productId}`
                                : "New Supply Offer"
                        }
                        action={
                            Object.keys(traders).length > 0 &&
                            Object.keys(products).length > 0 &&
                            Object.keys(brands).length > 0 && (
                                <Button
                                    className={styles.filterItem}
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        dispatch(
                                            push(`supplies/importsupplyoffers`)
                                        )
                                    }
                                >
                                    Import
                                </Button>
                            )
                        }
                    />
                    {processing && <LinearProgress variant="indeterminate" />}
                    {imageURL ? (
                        <div className={styles.mediaBox}>
                            <CardMedia
                                className={classNames(
                                    styles.media,
                                    zoom && styles.mediaBig
                                )}
                                image={imageURL}
                            />
                            <IconButton
                                className={styles.mediaZoom}
                                onClick={(e) => {
                                    setZoom(!zoom);
                                }}
                            >
                                {zoom ? <MinusBoxOutline /> : <PlusBoxOutline />}
                            </IconButton>
                        </div>
                    ) : (
                            <Skeleton variant="rect" height={200} />
                        )}

                    <CardContent>
                        <List>
                            <ListItem
                                className={classNames(
                                    styles.item,
                                    styles.itemReady
                                )}
                            >
                                <ListItemText className={styles.label}>
                                    Sector
                            </ListItemText>
                                <div className={styles.fields}>
                                    <ToggleButtonGroup
                                        value={target.channelId}
                                        onChange={(event, newChannel) =>
                                            dispatch({
                                                type: SELECT_PRICE_SECTOR,
                                                payload: newChannel,
                                            })
                                        }
                                        exclusive
                                    >
                                        {sectors.map((sector) => {
                                            return (
                                                sector.value === 20401 && (
                                                    <ToggleButton
                                                        key={`sec-${sector.value}`}
                                                        value={sector.value}
                                                        classes={{
                                                            root: styles.sectorRoot,
                                                            selected:
                                                                styles.sectorSelected,
                                                        }}
                                                    >
                                                        {sector.label}
                                                    </ToggleButton>
                                                )
                                            );
                                        })}
                                    </ToggleButtonGroup>
                                </div>
                            </ListItem>
                            <ListItem
                                className={classNames(
                                    styles.item,
                                    target.supplier > 0 && styles.itemReady
                                )}
                            >
                                <ListItemText className={styles.label}>
                                    Supplier
                            </ListItemText>
                                <div className={styles.fields}>
                                    <Autocomplete
                                        style={{ flex: 1 }}
                                        // freeSolo
                                        value={supplier}
                                        loading={Object.values(traders).length === 0}
                                        options={Object.values(traders)}
                                        getOptionLabel={(option) => {
                                            if (option) {
                                                if (typeof option === "string") {
                                                    return option;
                                                }
                                                return option.name;
                                            }
                                            return "";

                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = options.filter((o) => {
                                                return o.name
                                                    .toUpperCase()
                                                    .startsWith(
                                                        params.inputValue.toUpperCase()
                                                    );
                                            });
                                            return filtered;
                                        }}
                                        openOnFocus
                                        onChange={(event, value, reason) => {
                                            if (value.id) {
                                                setDirty(true);
                                                setSupplier(value);
                                                dispatch({ type: SELECT_PRICE_SUPPLIER, payload: parseInt(value.id) });
                                            } else {
                                                dispatch({ type: SELECT_PRICE_SUPPLIER, payload: null });
                                            }
                                        }}
                                        noOptionsText={
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <span style={{ paddingRight: "5px" }}>Trader not found!</span>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onMouseDown={(e) => {
                                                        setNewTrader(vendorRef.current.value);
                                                        setCountryDialogToggle(true);
                                                    }} //onClick does not work
                                                >
                                                    Add New Trader
                                    </Button>
                                            </Box>
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputRef={vendorRef}
                                                label={"Supplier"}
                                                margin="dense"
                                            />
                                        )}
                                    />
                                </div>
                            </ListItem>
                            <ListItem
                                className={classNames(
                                    styles.item,
                                    target.brandId > 0 && styles.itemReady
                                )}
                            >
                                <ListItemText className={styles.label}>
                                    Brand
                            </ListItemText>
                                <BrandInput
                                    disabled={false}
                                    style={{ flex: 3 }}
                                    loading={loading}
                                    value={
                                        brands && target && target.brandId > 0
                                            ? brands[target.brandId]
                                            : {}
                                    }
                                    onChange={(brand) => {
                                        if (!brand) return;
                                        const newBrand = parseInt(brand.id);
                                        if (target.brandId !== newBrand) {
                                            setDirty(true);
                                            dispatch({
                                                type: SELECT_PRICE_BRAND,
                                                payload: newBrand,
                                            });
                                        }
                                    }}
                                />
                            </ListItem>
                            <ListItem
                                className={classNames(
                                    styles.item,
                                    target.productId > 0 && styles.itemReady
                                )}
                            >
                                <ListItemText className={styles.label}>
                                    Product
                            </ListItemText>
                                <div className={styles.fields}>
                                    <Autocomplete
                                        disabled={target.brandId < 0}
                                        value={product}
                                        style={{ flex: 1 }}
                                        loading={loading}
                                        options={Object.values(products)}
                                        getOptionLabel={(option) => {
                                            const brand = brands[option.brandID];
                                            const brandName =
                                                brand.name.length > 8
                                                    ? brand.code
                                                    : brand.name;
                                            return `${brandName} ${option.SKU} ${option.dialColor}`.substr(
                                                0,
                                                48
                                            );
                                        }}
                                        filterOptions={(options, params) => {
                                            let count = 0;
                                            return options
                                                .filter(
                                                    (o) =>
                                                        o.brandID === target.brandId
                                                )
                                                .filter((o) => {
                                                    const keep =
                                                        count < 20 &&
                                                        o.reference &&
                                                        o.SKU.toUpperCase()
                                                            .replace(/[\s.-/]/g, "")
                                                            .includes(
                                                                params.inputValue
                                                                    .toUpperCase()
                                                                    .replace(
                                                                        /[\s.-/]/g,
                                                                        ""
                                                                    )
                                                            );
                                                    if (keep) count++;
                                                    return keep;
                                                });
                                        }}
                                        renderOption={(option) => {
                                            const brand = brands[option.brandID];
                                            const brandName =
                                                brand.name.length > 8
                                                    ? brand.code
                                                    : brand.name;
                                            return (
                                                <div className={styles.pdLabel}>
                                                    <div className={styles.pdBrand}>
                                                        {brandName}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.pdReference
                                                        }
                                                    >
                                                        {option.SKU}
                                                    </div>
                                                    <div className={styles.pdColor}>
                                                        {option.dialColor}
                                                    </div>
                                                </div>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Reference"
                                                margin="dense"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            if (!value) return;
                                            setProduct(value);
                                            const newProduct = parseInt(value.id);
                                            if (target.productId !== newProduct) {
                                                setDirty(true);
                                                dispatch({
                                                    type: SELECT_PRICE_PRODUCT,
                                                    payload: newProduct,
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </ListItem>
                            {supplier &&
                                ["JPN", "SGP"].includes(supplier.isoCode) ? (
                                    <ListItem
                                        className={classNames(
                                            styles.item,
                                            target.currencyId > 0 &&
                                            target.amount > 0 &&
                                            styles.itemReady
                                        )}
                                    >
                                        <ListItemText className={styles.label}>
                                            Amount
                                    <br />
                                    (with Tax)
                                </ListItemText>
                                        <div className={styles.fields}>
                                            <Autocomplete
                                                options={Object.values(currencies)}
                                                value={currency}
                                                getOptionLabel={(option) => option.code}
                                                filterOptions={(options, params) => {
                                                    return options.filter((o) => {
                                                        return (
                                                            o.code &&
                                                            o.code
                                                                .toUpperCase()
                                                                .startsWith(
                                                                    params.inputValue.toUpperCase()
                                                                )
                                                        );
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Currency"
                                                        margin="dense"
                                                    />
                                                )}
                                                onChange={(event, value) => {
                                                    setDirty(true);
                                                    dispatch({
                                                        type: SELECT_PRICE_CURRENCY,
                                                        payload: value
                                                            ? parseInt(value.id)
                                                            : -1,
                                                    });
                                                }}
                                                disableClearable
                                                defaultValue={
                                                    currencies[target.currencyId]
                                                }
                                            />
                                            <TextField
                                                className={styles.input}
                                                label="Price"
                                                margin="dense"
                                                fullWidth
                                                value={target.amount}
                                                onChange={(event) => {
                                                    setDirty(true);
                                                    const amount = parseFloat(event.target.value);
                                                    const vat = (country.vat / 100) + 1;
                                                    const price = Math.round((amount/vat + Number.EPSILON) * 100) / 100
                                                    dispatch({
                                                        type: SELECT_PRICE_AMOUNT,
                                                        payload: {
                                                            price,
                                                            amount
                                                        },
                                                    });
                                                }}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </div>
                                    </ListItem>
                                ) : (
                                    <ListItem
                                        className={classNames(
                                            styles.item,
                                            target.currencyId > 0 &&
                                            target.price > 0 &&
                                            styles.itemReady
                                        )}
                                    >
                                        <ListItemText className={styles.label}>
                                            Price
                                    <br />
                                    (w/o Tax)
                                </ListItemText>
                                        <div className={styles.fields}>
                                            <Autocomplete
                                                options={Object.values(currencies)}
                                                value={currency}
                                                getOptionLabel={(option) => option.code}
                                                filterOptions={(options, params) => {
                                                    return options.filter((o) => {
                                                        return (
                                                            o.code &&
                                                            o.code
                                                                .toUpperCase()
                                                                .startsWith(
                                                                    params.inputValue.toUpperCase()
                                                                )
                                                        );
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Currency"
                                                        margin="dense"
                                                    />
                                                )}
                                                onChange={(event, value) => {
                                                    setDirty(true);
                                                    setCurrency(value);
                                                    dispatch({
                                                        type: SELECT_PRICE_CURRENCY,
                                                        payload: value
                                                            ? parseInt(value.id)
                                                            : -1,
                                                    });
                                                }}
                                                disableClearable
                                                defaultValue={
                                                    currencies[target.currencyId]
                                                }
                                            />
                                            <TextField
                                                className={styles.input}
                                                label="Price"
                                                margin="dense"
                                                fullWidth
                                                value={target.price}
                                                onChange={(event) => {
                                                    setDirty(true);
                                                    dispatch({
                                                        type: SELECT_PRICE_PRICE,
                                                        payload: parseFloat(
                                                            event.target.value
                                                        ),
                                                    });
                                                }}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </div>
                                    </ListItem>
                                )}
                            <ListItem
                                className={classNames(
                                    styles.item,
                                    target.quantity > 0 && styles.itemReady
                                )}
                            >
                                <ListItemText className={styles.label}>
                                    Quantity
                            </ListItemText>
                                <div className={styles.fields}>
                                    <TextField
                                        label="Quantity"
                                        margin="dense"
                                        fullWidth
                                        value={target.quantity}
                                        onChange={(event) => {
                                            setDirty(true);
                                            dispatch({
                                                type: SELECT_PRICE_QUANTITY,
                                                payload: parseInt(
                                                    event.target.value
                                                ),
                                            });
                                        }}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                    />
                                </div>
                            </ListItem>
                        </List>
                    </CardContent>
                    <CardActions className={styles.actionPanel}>
                        <Button
                            className={styles.actionButtons}
                            onClick={(e) => {
                                setDirty(false);
                                dispatch({ type: RESET_TARGET });
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            Cancel
                    </Button>
                        <Button
                            className={styles.actionButtons}
                            disabled={!readyForSubmit}
                            onClick={(e) => {
                                setDirty(false);
                                dispatch({ type: SAVE_PRICE_SUPPLY });
                            }}
                            // disabled={!selectedForEdit}
                            startIcon={<ContentSave />}
                            variant="contained"
                            color="primary"
                        >
                            Save
                    </Button>
                    </CardActions>
                </Card>
            </div>
        </>
    );
};
