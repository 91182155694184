import React from 'react'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default function CustomSwitch({checked, onChange, label, ...props }) {
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={onChange}
                    {...props}
                />
            }
            label={label}
        />
    )
}
