import {
    LOAD_PRICE_RULES_BEGIN,
    LOAD_PRICE_RULES_SUCCESS,
    EDIT_PRICE_RULE_VALUE,
    CLEAR_PRICE_RULE_VALUE,
    SUBMIT_PRICE_RULE_BEGIN,
    SUBMIT_PRICE_RULE_SUCCESS,
    SUBMIT_PRICE_RULE_FAIL,
    CLOSE_PRICE_RULE_NOTIFICATION,
    UPDATE_PRICE_RULE_BOX_OPEN_STATUS,
    SEARCH_PRODUCT_PRICE_TAG,
    SEARCH_PRODUCT_PRICE_TAG_SUCCESS,
    SEARCH_PRODUCT_PRICE_TAG_FAIL,
    EDIT_PRICE_INFO_FILTER,
    UPDATE_APPLIED_PRICE_RULE,
    CLEAR_SEARCH_PRODUCT_PRICE_TAG
} from "../actions";
import moment from "moment";

const initialState = {
    loading: false,
    brands: {},
    products: {},
    data: {},
    index: [],
    priceRuleDetail: {
        id: null,
        type: "OVERALL",
        targetId: 0,
        markup: 0,
        mode: "RATIO",
        currencyId: 0,
        validFrom: moment(new Date()).format("YYYY-MM-DD"),
        validTo: ""
    },
    productInfo: {
        id: null,
        brandId: null,
        reference: null,
        price: null,
        currencyId: null
    },
    appliedRules: {},
    totalMarkupValue: 0,
    filter: {
        productRef: ""
    },
    notice: "",
    notify: false,
    noticeType: "",
    openInsertBox: false
};

export const priceRuleReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PRICE_RULES_BEGIN:
            return {
                ...state,
                loading: true
            };
        case LOAD_PRICE_RULES_SUCCESS:
            return {
                ...state, 
                ...action.payload, 
                loading: false
            };
        case EDIT_PRICE_RULE_VALUE:
            return {
                ...state,
                priceRuleDetail: {
                    ...state.priceRuleDetail,
                    ...action.payload
                }
            };
        case CLEAR_PRICE_RULE_VALUE:
            return {
                ...state,
                priceRuleDetail: initialState.priceRuleDetail
            };
        case SUBMIT_PRICE_RULE_BEGIN:
            return {
                ...state,
                loading: true,
        };
        case SUBMIT_PRICE_RULE_SUCCESS:
            return {
                ...state,
                loading: false,
                notify: true,
                notice: action.payload.notice,
                noticeType: "success",
            };
        case SUBMIT_PRICE_RULE_FAIL:
            return {
                ...state,
                loading: false,
                notify: true,
                notice: action.payload,
                noticeType: "error",
            };
        case CLOSE_PRICE_RULE_NOTIFICATION:
            return {
                ...state,
                notify: false,
                notice: null,
                noticeType: "error",
            };
        case UPDATE_PRICE_RULE_BOX_OPEN_STATUS:
            return {
                ...state,
                openInsertBox: action.payload,
            };
        case SEARCH_PRODUCT_PRICE_TAG:
            return {
                ...state,
                loading: true
            };
        case SEARCH_PRODUCT_PRICE_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
                productInfo: {
                    ...state.productInfo,
                    ...action.payload
                },
            };
        case SEARCH_PRODUCT_PRICE_TAG_FAIL:
            return {
                ...state,
                loading: false,
                notify: true,
                notice: action.payload,
                noticeType: "error",
            };
        case CLEAR_SEARCH_PRODUCT_PRICE_TAG:
            return {
                ...state,
                productInfo: initialState.productInfo,
                appliedRules: initialState.appliedRules,
                totalMarkupValue: initialState.totalMarkupValue
            };
        case EDIT_PRICE_INFO_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload
                }
            };
        case UPDATE_APPLIED_PRICE_RULE:
            return {
                ...state,
                appliedRules: action.payload.rules,
                totalMarkupValue: action.payload.totalMarkupValue
            };            
        default:
            return state;
    }
};