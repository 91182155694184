import axios from 'axios'

/**
 * 
 * @param {Object} input 
 * @param {string} id - Proforma purchase ID
 * @param {string} invoiceIndicator - ID of the invoice image
 * @param {string} once - Idempotency token
 * @param {Object} headers 
 * @param {string} headers.authorization - Bearer authorization - {authorization: `Bearer ${token}`}
 * @param {string} headers.rayLens - rayLens ID
 */
const executeProforma = async (input, headers) => {
    const query = `
        mutation ($input: PurchaseInput) {
            executeProformaPO (input: $input) {
                id
                storeId
                reference
                invoice
                createdAt
                updatedAt
                deletedAt
            }
        }
    `

    try {
        const response = await axios.request({
            url: '/v1/api',
            method: 'post',
            data: {
                query,
                variables: {input}
            },
            headers
        })

        if (response.data.hasOwnProperty('errors')) {
            return {error: response.data.errors[0]}
        }
        
        return {data: response.data.data.executeProformaPO}
    } catch(e) {
        const response = e.response

        if (!e.response) {
            return {error: {message: e.message}}
        }

        if (response.data.hasOwnProperty('errors')) {
            return {error: response.data.errors[0]}
        }

        return {error: {message: response.data}}
    }
}

export default executeProforma