import { createVibes } from "@cosmos/vibes";
import {
  DF_UPDATE_FIELD, DF_UPDATE_INNER_FIELD,
  DF_DISABLE_PROCESS, DF_SCAN_CART_LABEL, DF_SCAN_CART_LABEL_FAIL,
  DF_ADD_CART_LABEL, SEARCH_DF_STOCK, DF_ADD_ITEM_FAIL,
  UPDATE_DF_LABEL_LIST, DF_ADD_ITEM_SUCCESS, OPEN_DF_RESERVED_DIALOG,
  TOGGLE_DF_BIRTH_DIALOG, CANCEL_DF_RESERVED_DIALOG, CLOSE_DF_RESERVED_DIALOG,
  UPDATE_DF_CARD_PROGRESS, DF_CARD_PROGRESS_COMPLETED,
  SUBMIT_DF_WARRANTY_BACK_PHOTO, SUBMIT_DF_WARRANTY_FRONT_PHOTO,
  DF_POS_WARRANTY_DIALOG, OPEN_DF_WARRANTY_NOTICE, UPLOADED_DF_WARRANTY_PHOTO,
  REMOVE_DF_ITEM, DF_REMOVE_ITEM_ERROR, DF_REMOVE_ITEM_SUCCESS,
  SUBMIT_DF_ITEMS, SUBMIT_DF_ITEMS_SUCCESS, SUBMIT_DF_ITEMS_FAIL,
  SCANNED_MRZ, SCANNED_MRZ_FAIL, SCANNED_MRZ_SUCCESS, PRINT_DF_BLANK_FORM,
  CLOSE_SAM_DIALOG, PRINT_DF_BLANK_FORM_ERROR, DF_OPEN_RESERVE_NOTICE,
  UPLOADED_DF_PASSPORT_PHOTO, DF_TOGGLE_PASSPORT_DIALOG, DF_SUBMIT_PASSPORT,
  DF_SHOW_PASSPORT, OPEN_DF_NOTICE, DF_SHOW_TEMP_IMG, DF_TOGGLE_VISA_DIALOG,
  DF_SUBMIT_VISA, UPLOADED_DF_VISA_PHOTO, DF_SHOW_VISA, DF_SCAN_CART_LABEL_SUCCESS,
  TOGGLE_CDP_DIALOG, DF_SUBMIT_CDP, UPLOADED_CDP_PHOTO, SUBMIT_CDP_FAIL,
  INIT_PAYSAFE,
} from "../actions";
import { dataURItoBlob } from "../services/dataUriToBlob";
import * as shortId from 'shortid';

export const disableProcessVibes = createVibes({
  type: [DF_UPDATE_FIELD, DF_UPDATE_INNER_FIELD],
  latest: true,
  async process({ getState }, dispatch, done) {
    const { orderInput } = getState().dutyfree;

    let bool = false;
    if (orderInput) {
      if (!orderInput.customer) {
        bool = true;
      }

      if (!bool && orderInput.customer && !orderInput.customer.name) {
        bool = true;
      }

      if (!bool && (!orderInput.documentNumber || !orderInput.nationality || !orderInput.documentType || !orderInput.dateOfBirth || !orderInput.dateOfLanding || !orderInput.residenceStatus)) {
        bool = true;
      }
    }
    else {
      bool = true;
    }

    dispatch({ type: DF_DISABLE_PROCESS, payload: { disableProcess: bool } });
    done();
  }
});

export const scanCartLabelVibes = createVibes({
  type: [DF_SCAN_CART_LABEL],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { search } = action.payload;
      const token = getState().auth.token;
      const { rayLens } = getState().system;

      const query = await axios.request({
        url: `/SAM/${search}`,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          rayLens
        }
      });

      if (query.status === 200 && query.request && query.request.response) {
        const response = JSON.parse(query.request.response);
        const proformaCartId = response ? response.proformaCartId : null;
        const cartId = response ? response.cartId : null;
        const dutyFreeId = response ? response.dutyFreeOrderId : null;
        const dutyFreeState = response ? response.dutyFreeOrderState : null;

        if (cartId) {
          dispatch({ type: DF_SCAN_CART_LABEL_FAIL, payload: { notify: true, noticeType: 'error', notice: 'This cart is already completed' } });
        }
        else {
          if (!dutyFreeId) {
            dispatch({ type: DF_SCAN_CART_LABEL_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The cart label is not duty-free, please proceed in POS screen.' } });
          }
          else {
            if (proformaCartId) {
              if (dutyFreeState === 'READY') {
                const { orderInput } = getState().dutyfree;
                dispatch({ type: TOGGLE_CDP_DIALOG, payload: { cdpDialog: true, proformaCartId: proformaCartId, orderInput: { ...orderInput, id: dutyFreeId, attachments: {} } } });
              }
              else {
                dispatch({ type: DF_ADD_CART_LABEL, payload: { proformaCartId } });
                dispatch({ type: DF_SCAN_CART_LABEL_SUCCESS, payload: { notify: true, noticeType: 'success', notice: 'Recorded the cart label.' } });
              }
            }
            else {
              dispatch({ type: DF_SCAN_CART_LABEL_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The cart label is invalid' } });
            }
          }
        }
      }
      else {
        dispatch({ type: DF_SCAN_CART_LABEL_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The cart label is invalid' } });
      }
      dispatch({ type: CLOSE_SAM_DIALOG, payload: { samDialog: false } });
      done();
    } catch (err) {
      dispatch({ type: DF_SCAN_CART_LABEL_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The cart label is invalid' } });
      dispatch({ type: CLOSE_SAM_DIALOG, payload: { samDialog: false } });
      done();
    }
  }
});

export const scanStockLabelVibes = createVibes({
  type: [SEARCH_DF_STOCK],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const label = action.payload.search;
      let labs = getState().dutyfree.labels;

      if (labs.indexOf(label) > -1) {
        dispatch({ type: DF_ADD_ITEM_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The stock is already in cart' } });
        done();
      } else {
        const { token } = getState().auth;
        const { rayLens } = getState().system;

        const query = await axios.request({
          url: `/STK/${label}`,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            rayLens
          }
        });

        if (query.status < 400) {
          if (query.status === 200) {
            if (query.request && query.request.response) {
              const response = JSON.parse(query.request.response);

              if (response.item) {
                const result = await axios.request({
                  url: '/api',
                  method: 'post',
                  headers: {
                    'Authorization': `Bearer ${token}`,
                    rayLens
                  },
                  data: {
                    query: `query($filter: SingleStockFilter) {
                      stock(filter: $filter)
                      {
                        id brandId productId serial state birthYear birthMonth birthDate
                      }
                    }`,
                    variables: { filter: { id: response.item } }
                  }
                });

                let data = result.data;

                const stock = data.stock;

                if (stock) {
                  let sellPrice = 0;
                  if (!stock.priceAdjustment) {
                    //TODO: check if the client id should be 1000
                    try {
                      const result1 = await axios.request({
                        url: '/api',
                        method: 'post',
                        headers: {
                          'Authorization': `Bearer ${token}`,
                          rayLens
                        },
                        data: {
                          query: `query($clientId: Int!, $productId: ID!, $filter: PriceTagFilter) {
                            priceTag(clientId: $clientId, productId: $productId, filter: $filter)
                            {
                                id productId forexSetId currencyId price tax amount discount
                            }
                          }`,
                          variables: { clientId: 1000, productId: stock.productId, filter: { type: "SELL" } }
                        }
                      });

                      let data1 = result1.data;

                      if (data1 && data1.priceTag) {
                        sellPrice = data1.priceTag.price;
                      }
                    }
                    catch (err) {
                      dispatch({ type: DF_ADD_ITEM_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to get price tag' } });
                    }
                  }
                  else {
                    sellPrice = stock.priceAdjustment;
                  }
                  let goods = [];
                  if (response.reservation) {
                    const { traders } = getState().trader;
                    let reservedForName = response.reservedFor;
                    if (!isNaN(parseInt(reservedForName))) {
                      if (traders[parseInt(reservedForName)]) {
                        reservedForName = traders[parseInt(reservedForName)].name;
                      }
                    }
                    const payload = {
                      reservedDialog: true,
                      reservedFor: reservedForName,
                      reservation: response.reservation,
                    }
                    dispatch({ type: OPEN_DF_RESERVED_DIALOG, payload })
                    goods = [{ ...stock, discountRate: 0, reservation: response.reservation }, ...getState().dutyfree.goods];
                  }
                  else {
                    if (response.proformaId) {
                      dispatch({ type: DF_OPEN_RESERVE_NOTICE, payload: { notify: true, notice: 'This stock has been put in another cart.', noticeType: 'warning' } });
                    }
                    goods = [{ ...stock, discountRate: 0, reservation: null }, ...getState().dutyfree.goods];
                    if (response.actions && response.actions.indexOf("birth") > -1) {
                      dispatch({ type: TOGGLE_DF_BIRTH_DIALOG });
                    }
                  }
                  const prices = [{ price: sellPrice, priceAfterDisc: sellPrice }, ...getState().dutyfree.prices];
                  const selected = 0;
                  dispatch({ type: UPDATE_DF_LABEL_LIST, payload: label });
                  dispatch({ type: DF_ADD_ITEM_SUCCESS, payload: { goods, prices, selected, itemCode: '' } });
                }
                else {
                  dispatch({ type: DF_ADD_ITEM_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The stock is invalid', itemCode: '' } });
                }
              } else {
                dispatch({ type: DF_ADD_ITEM_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The stock is invalid', itemCode: '' } });
              }
            }
            else {
              dispatch({ type: DF_ADD_ITEM_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The stock is invalid', itemCode: '' } });
            }
          } else {
            dispatch({ type: DF_ADD_ITEM_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The label is not yet attached to any stock', itemCode: '' } });
          }
        } else {
          dispatch({ type: DF_ADD_ITEM_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Stock not found', itemCode: '' } });
        }
      }
      done();
    } catch (err) {
      dispatch({ type: DF_ADD_ITEM_FAIL, payload: { notify: true, noticeType: 'error', notice: 'The stock label is invalid' } });
      done();
    }
  }
});

export const checkDfBirthVibes = createVibes({
  type: [CANCEL_DF_RESERVED_DIALOG, CLOSE_DF_RESERVED_DIALOG],
  latest: true,
  async process({ getState }, dispatch, done) {
    const { goods } = getState().dutyfree;
    if (goods && goods[0] && (!goods[0].birthYear && !goods[0].birtMonth && !goods[0].birthDate)) {
      dispatch({ type: TOGGLE_DF_BIRTH_DIALOG });
    }
    done();
  }
});

export const saveDfWarrantyPhoto = createVibes({
  type: [
    SUBMIT_DF_WARRANTY_BACK_PHOTO, SUBMIT_DF_WARRANTY_FRONT_PHOTO,
    DF_SUBMIT_PASSPORT, DF_SUBMIT_VISA, DF_SUBMIT_CDP,
  ],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { goods, selected, orderInput } = getState().dutyfree;

      const { dataUri, type } = action.payload;
      let blob = dataURItoBlob(dataUri, {});

      let form = new FormData();
      form.append('fileData', blob);

      let photoIdentifier = shortId.generate();

      const response = await axios.request({
        url: `/upload/${type}/${photoIdentifier}`,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens,
          'Content-Type': 'multipart/form-data',
        },
        data: form,
        onUploadProgress: progressEvent => {
          let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch({ type: UPDATE_DF_CARD_PROGRESS, payload: { uploadProgress: progress } });
          let msg = '';
          if (progress === 100) {
            switch (action.type) {
              case SUBMIT_DF_WARRANTY_FRONT_PHOTO:
                msg = 'Uploaded card front photo successfully, take a photo for card back now.'
                break;
              case SUBMIT_DF_WARRANTY_BACK_PHOTO:
                msg = 'Uploaded card back photo successfully.'
                break;
              case DF_SUBMIT_PASSPORT:
                msg = 'Uploaded passport photo successfully.'
                break;
              case DF_SUBMIT_VISA:
                msg = 'Uploaded visa photo successfully.'
                break;
              case DF_SUBMIT_CDP:
                msg = 'Uploaded signed customer declaration form photo successfully.'
                break;
              default:
                break;
            }
            dispatch({ type: DF_CARD_PROGRESS_COMPLETED, payload: { notify: true, notice: msg, noticeType: 'success' } });
          }
        }
      })

      if (response.status === 200) {
        let newGoods = goods.map(el => el);
        switch (action.type) {
          case SUBMIT_DF_WARRANTY_FRONT_PHOTO:
            newGoods[selected].cardFront = photoIdentifier;
            dispatch({ type: UPLOADED_DF_WARRANTY_PHOTO, payload: { goods: newGoods } });
            dispatch({ type: DF_POS_WARRANTY_DIALOG, payload: { captureCardBack: true, captureCardFront: false } });
            break;
          case SUBMIT_DF_WARRANTY_BACK_PHOTO:
            newGoods[selected].cardBack = photoIdentifier;
            dispatch({ type: UPLOADED_DF_WARRANTY_PHOTO, payload: { goods: newGoods } });
            dispatch({ type: DF_POS_WARRANTY_DIALOG, payload: { captureCardBack: false } });
            break;
          case DF_SUBMIT_PASSPORT:
            dispatch({ type: UPLOADED_DF_PASSPORT_PHOTO, payload: { orderInput: { ...orderInput, attachments: { ...orderInput.attachments, passport: photoIdentifier } } } });
            dispatch({ type: DF_TOGGLE_PASSPORT_DIALOG, payload: { passportDialog: false } });
            break;
          case DF_SUBMIT_VISA:
            dispatch({ type: UPLOADED_DF_VISA_PHOTO, payload: { orderInput: { ...orderInput, attachments: { ...orderInput.attachments, visaLanding: photoIdentifier } } } });
            dispatch({ type: DF_TOGGLE_VISA_DIALOG, payload: { visaDialog: false } });
            break;
          case DF_SUBMIT_CDP:
            dispatch({ type: UPLOADED_CDP_PHOTO, payload: { cdpDialog: false, orderInput: { ...orderInput, attachments: { ...orderInput.attachments, receipt: photoIdentifier } } } });
            break;
          default:
        }
      }
      else {
        dispatch({ type: OPEN_DF_WARRANTY_NOTICE, payload: { notify: true, notice: 'Fail to  upload photo!', noticeType: 'error' } });
      }
      done();
    } catch (err) {
      dispatch({ type: OPEN_DF_WARRANTY_NOTICE, payload: { notify: true, notice: 'Fail to upload photo!', noticeType: 'error' } });
      done();
    }
  }
});

export const removeItemVibes = createVibes({
  type: REMOVE_DF_ITEM,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      const { itemIndex } = action.payload;
      const { goods, labels, prices, selected } = getState().dutyfree;

      if (itemIndex > goods.length) {
        dispatch({ type: DF_REMOVE_ITEM_ERROR, payload: { error: new Error('Index out of range') } });
      }
      else {
        const sele = (selected < itemIndex) ? selected : (selected > itemIndex) ? selected - 1 : 0;
        const gds = [...goods.slice(0, itemIndex), ...goods.slice(itemIndex + 1)];
        const labs = [...labels.slice(0, itemIndex), ...labels.slice(itemIndex + 1)];
        const pcs = [...prices.slice(0, itemIndex), ...prices.slice(itemIndex + 1)];
        dispatch({ type: DF_REMOVE_ITEM_SUCCESS, payload: { goods: gds, labels: labs, prices: pcs, selected: sele } });
      }
      done();
    } catch (err) {
      dispatch({ type: DF_REMOVE_ITEM_ERROR, payload: { error: err } });
      done();
    }
  }
});

export const submitDutyFreeVibes = createVibes({
  type: [SUBMIT_DF_ITEMS],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const token = getState().auth.token;
      const { rayLens } = getState().system;
      const { orderInput, cartInput, goods, prices, proformaCartId } = getState().dutyfree;
      const { stores } = getState().system;

      let goodsInput = goods.map((el, idx) => {
        return {
          stockId: el.id,
          price: prices[idx].price,
          discountValue: prices[idx].price - prices[idx].priceAfterDisc,
          cardFront: el.cardFront,
          cardBack: el.cardBack,
          birthYear: el.birthYear,
          birthMonth: el.birthMonth ? String(el.birthMonth) : null,
          birthDate: el.birthDate,
          reservation: el.reservation
        }
      });

      const request = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `
            mutation createDutyFree($dutyFreeInput: DutyFreeInput, $cartInput: CartInput){
              updateDutyFree(input: $dutyFreeInput){
                id customerId nationality reference
                documentType documentNumber dateOfBirth dateOfLanding 
                residenceStatus state storeId proformaCartId
              }
              updateCart(input: $cartInput) {
                id reference storeId
              }
            }
          `,
          variables: {
            dutyFreeInput: orderInput,
            cartInput: {
              ...cartInput,
              id: proformaCartId ? proformaCartId : null,
              goods: goodsInput,
              currency: stores && stores[rayLens] && stores[rayLens].currencyId ? stores[rayLens].currencyId : null,
            }
          }
        }
      })

      if (request.status === 200 && request.data && request.data.updateDutyFree && request.data.updateCart) {
        dispatch({ type: SUBMIT_DF_ITEMS_SUCCESS, payload: { notify: true, noticeType: 'success', notice: 'Successful to submit duty free form' } });
        const result = request.data.updateCart;
        const resultDutyfree = request.data.updateDutyFree;
        const fileData = await axios.request({
          url: `/duf/${result.storeId}/${resultDutyfree.reference}`,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${token}`,
            rayLens,
          },
          responseType: "blob"
        });
        const response = fileData.request && fileData.request.response;
        const file = new Blob([response], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        let nwin = window.open(fileURL, '_blank');
        nwin.focus();

        const dutyFreeId = request.data.updateDutyFree.id;
        dispatch({ type: TOGGLE_CDP_DIALOG, payload: { cdpDialog: true, orderInput: { documentType: 'Passport', once: shortId.generate(), id: dutyFreeId, attachments: {} } } });
      }
      else {
        dispatch({ type: SUBMIT_DF_ITEMS_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to submit duty free form' } });
      }
      done();
    }
    catch (err) {
      dispatch({ type: SUBMIT_DF_ITEMS_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to submit duty free form' } });
      done();
    }
  }
});

export const parseMrzVibes = createVibes({
  type: [SCANNED_MRZ],
  debounce: 200,
  latest: true,
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const { code } = action.payload;
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const query = await axios.request({
        url: `/service/mrz`,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          rayLens
        },
        data: { code }
      });

      if (query.status === 200) {
        if (query.request && query.request.response) {
          const response = JSON.parse(query.request.response);

          const { orderInput } = getState().dutyfree;
          let name = null;
          if (response.names) {
            name = response.names.lastName;
            if (response.names.names && response.names.names.length > 0) {
              for (let k = 0; k < response.names.names.length; k++) {
                name = name.concat(' ', response.names.names[k])
              }
            }
          }

          let newOrderInput = {
            once: orderInput.once,
            documentType: orderInput.documentType,
            residenceStatus: orderInput.residenceStatus,
            dateOfLanding: orderInput.dateOfLanding,
            customer: { name },
            nationality: response.nationality && response.nationality.full,
            documentNumber: response.documentNumber,
            dateOfBirth: response.dob ? response.dob.year + '-' + response.dob.month + '-' + response.dob.day : null,
          }
          dispatch({ type: SCANNED_MRZ_SUCCESS, payload: { orderInput: newOrderInput } });
        }
        else {
          dispatch({ type: SCANNED_MRZ_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to scan MRZ' } });
        }
      }
      else {
        dispatch({ type: SCANNED_MRZ_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to scan MRZ' } });
      }
      done();
    } catch (err) {
      dispatch({ type: SCANNED_MRZ_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to scan MRZ' } });
      done();
    }
  }
});

export const printBlankDFVibes = createVibes({
  type: [PRINT_DF_BLANK_FORM],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { number, type } = action.payload;

      for (let k = 0; k < number; k++) {
        const request = await axios.request({
          url: '/api',
          method: 'post',
          headers: {
            'Authorization': `Bearer ${token}`,
            rayLens
          },
          data: {
            query: `
              mutation createDutyFree($dutyFreeInput: DutyFreeInput, $cartInput: CartInput){
                updateDutyFree(input: $dutyFreeInput){
                  id
                }
                updateCart(input: $cartInput) {
                  id reference storeId
                }
              }
            `,
            variables: {
              dutyFreeInput: { once: shortId.generate() },
              cartInput: { once: shortId.generate() }
            }
          }
        })

        if (request.data.updateCart && request.data.updateCart.id) {
          const fileData = await axios.request({
            url: `/${type}/${request.data.updateCart.storeId}/${request.data.updateCart.reference}`,
            method: 'get',
            headers: {
              'Authorization': `Bearer ${token}`,
              rayLens,
            },
            responseType: "blob"
          });
          const response = fileData.request && fileData.request.response;
          const file = new Blob([response], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        }
        else {
          dispatch({ type: PRINT_DF_BLANK_FORM_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to print duty free form' } });
        }
      }
      done();
    } catch (err) {
      dispatch({ type: PRINT_DF_BLANK_FORM_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to print duty free form' } });
      done();
    }
  }
});

export const loadDfPhotos = createVibes({
  type: [
    DF_SHOW_PASSPORT, DF_SHOW_VISA
  ],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { type, key, title } = action.payload

      const fileData = await axios.request({
        url: `/upload/${type}/${key}`,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens,
        },
        responseType: "blob"
      });
      const response = fileData.request && fileData.request.response;
      const file = new Blob([response], { type: 'image/png' });
      const fileURL = URL.createObjectURL(file);
      dispatch({ type: DF_SHOW_TEMP_IMG, payload: { tempUri: fileURL, tempTitle: title, tempDialog: true } });
      done();
    } catch (err) {
      dispatch({ type: OPEN_DF_NOTICE, payload: { notify: true, notice: 'Fail to upload photo!', noticeType: 'error' } });
      done();
    }
  }
});

export const submitCdpVibes = createVibes({
  type: [UPLOADED_CDP_PHOTO],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { orderInput, proformaCartId } = getState().dutyfree;

      const request = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `
            mutation($dutyFreeInput: DutyFreeInput){
              updateDutyFree(input: $dutyFreeInput){
                id customerId nationality reference
                documentType documentNumber dateOfBirth dateOfLanding 
                residenceStatus state storeId proformaCartId
              }
            }
          `,
          variables: {
            dutyFreeInput: orderInput
          }
        }
      })
      if (request.status === 200 && request.data && request.data.updateDutyFree) {
        dispatch({ type: INIT_PAYSAFE, payload: { proformaCartId, type: 1 } }); //type -> 0: tax required, 1: tax free, 2: export 
      }
      else {
        dispatch({ type: SUBMIT_CDP_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to save signed customer declaration form photo' } });
      }

      done();
    }
    catch (err) {
      dispatch({ type: SUBMIT_CDP_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to save signed customer declaration form photo' } });
      done();
    }
  }
});
