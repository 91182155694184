import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'
import useQuery from '../../hooks/useQuery'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { INIT_TRADER_LOAD } from "../../actions";
import { useHistory, useRouteMatch } from 'react-router'

const getPurchaseOrdersQuery = `query purchaseOrders($filter: purchaseFilter) {
    purchaseOrders(filter: $filter) {
        id
        reference
        date
        vendor
        invoice
        state
        purchaseDetails {
            id
            brandId
            productId
            unitPrice
            quantityExpected
            discountRate
            taxRate
            quantity
            currency
            state
        }
    }
}`
const getStocksQuery = `query stockInList {
    stockInList {
        id
        vendorId
        date
        invoice
        stockInDetails {
            id
            stockId
            stockInId
            state
            articleId
            productId
            price
        }
    }
}`

const useStyles = makeStyles(theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: '1fr auto',
        gridTemplateAreas: `
            'po stock'
            'footer footer'
        `,
        gridGap: '1em',
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`
    },
    section: {
        border: '1px solid #ccc',
        height: '100%',
        overflow: 'scroll',
        '& > *:first-child': {
            margin: 0,
            background: 'white',
            borderBottom: '1px solid #ccc',
            padding: '10px',
            position: 'sticky',
            top: 0
        }
    },
    purchaseOrders: {
        gridArea: 'po'
    },
    stocks: {
        gridArea: 'stock'
    },
    list: {
        listStyleType: 'none',
        margin: 0,
        padding: 0
    },
    listItem: {
        padding: '0.2em',
        '&:hover': {
            cursor: 'pointer',
            background: 'rgba(0,0,0,0.1)'
        }
    },
    activeListItem: {
        border: '2px solid black'
    },
    footer: {
        gridArea: 'footer',
        padding: '1em',
        textAlign: 'right'
    }
}))

// const matchData = {
//     "stockInDetail": {
//         "stockInPurchaseMatch": [
//             {
//                 "stockInDetailId": "60",
//                 "purchaseDetailId": "339",
//                 "stockId": "123"
//             },
//             {
//                 "stockInDetailId": "58",
//                 "purchaseDetailId": "339",
//                 "stockId": "121"
//             },
//             {
//                 "stockInDetailId": "57",
//                 "purchaseDetailId": "339",
//                 "stockId": "120"
//             }
//         ],
//         "extraStockIn": [
//             {
//                 "id": "59",
//                 "stockId": "122",
//                 "stockInId": "68",
//                 "state": "CHECKIN"
//             }
//         ],
//         "restPOD": []
//     }
// }

export default function IncompletePOandStockIn() {
    const { data: { purchaseOrders } } = useQuery(getPurchaseOrdersQuery, { purchaseOrders: [] }, { filter: {} }, true)
    let { data: { stockInList } } = useQuery(getStocksQuery, { stockInList: [] }, {}, true)
    const dispatch = useDispatch();
    const traders = useSelector(state => state.trader.traders);

    const [focusedPO, setFocusedPO] = useState(null)
    const [focusedStocks, setFocusedStocks] = useState([])

    useEffect(() => {
        dispatch({ type: INIT_TRADER_LOAD });
    }, [dispatch]);

    const incompletePurchaseOrders = purchaseOrders
        .filter(po => Object.values(po).some(value => value))
        .filter(po => po.state !== 'SETTLED')
        .filter(po => po.vendor)
        .filter(po => stockInList.find(stockIn => stockIn.vendorId === po.vendor))
        .filter(po => stockInList.find(stockIn => {
            const stockIndate = moment(stockIn.date)
            const poDate = moment(po.date)
            return stockIndate.isSame(poDate)
        }))

    const incompletePO = focusedPO ? incompletePurchaseOrders.find(po => focusedPO === po.id) : null;

    const incompleteStocks = stockInList
        .filter(stockIn => stockIn.state !== 'MATCHED')
        .filter(stockIn => { return focusedPO ? stockIn.vendorId === incompletePO.vendor : true })
        .filter(stockIn => stockIn.stockInDetails.some(stockDetail => stockDetail.state === "CHECKIN"))
        .filter(stockIn => focusedPO ? incompletePO.purchaseDetails.map(purchaseDetail => purchaseDetail.productId)
            .some(productId => stockIn.stockInDetails.map(stockDetail => stockDetail.productId).includes(productId))
            : true)

    const handleSelectPO = (poId) => {
        setFocusedStocks([])
        if (focusedPO === poId) return setFocusedPO(null)
        return setFocusedPO(poId)
    }

    const handleSelectStock = (stockId) => {
        if (focusedStocks.includes(stockId)) return setFocusedStocks(prevStocks => prevStocks.filter(stock => stock !== stockId))
        return setFocusedStocks(prevStocks => [...prevStocks, stockId])
    }

    const handleClearSelection = () => {
        setFocusedStocks([])
        setFocusedPO(null)
    }

    const history = useHistory()
    const { url } = useRouteMatch()
    const confirmPOandStock = () => {
        const selectedPO = purchaseOrders.find(purchaseOrder => purchaseOrder.id === focusedPO)
        const selectedStocks = stockInList.filter(stockIn => focusedStocks.includes(stockIn.id))

        if (selectedStocks.length > 0 && !selectedPO) return history.push(`${url}/stockinlist`, { stock: selectedStocks[0] })
        return history.push(`${url}/purchaseorderlist`, { stocks: selectedStocks, purchaseOrder: selectedPO })
    }

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={`${classes.section} ${classes.purchaseOrders}`}>
                <h2>Purchase Orders</h2>
                <ul className={classes.list}>
                    {incompletePurchaseOrders.map((po) => (
                        <li
                            key={po.id}
                            onClick={() => handleSelectPO(po.id)}
                            className={`${classes.listItem} ${po.id === focusedPO ? classes.activeListItem : ''}`}
                        ><PurchaseOrderCard id={po.id} reference={po.reference} vendor={traders[po.vendor] ? traders[po.vendor].name : ""} date={po.date} invoice={po.invoice} /></li>)
                    )}
                </ul>
            </div>
            <div className={`${classes.section} ${classes.stocks}`}>
                <h2>Stock In</h2>
                <ul className={classes.list}>
                    {incompleteStocks.map((stock) => (
                        <li
                            key={stock.id}
                            onClick={() => handleSelectStock(stock.id)}
                            className={`${classes.listItem} ${focusedStocks.includes(stock.id) ? classes.activeListItem : ''}`}
                        ><StockCard id={stock.id} vendorId={traders[stock.vendorId] ? traders[stock.vendorId].name : ""} date={stock.date} invoice={stock.invoice} /></li>
                    ))}
                    {incompleteStocks.length === 0 && <p><em>No matching stocks found</em></p>}
                </ul>
            </div>
            <div className={classes.footer}>
                <Button onClick={handleClearSelection}>Clear selection</Button>
                <Button variant="contained" color="primary" onClick={confirmPOandStock} disabled={focusedStocks.length === 0}>Next</Button>
            </div>
        </div>
    )
}

const useCardStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: '0.2rem 0.8rem'
    },
    emphasised: {
        fontSize: '1.2rem'
    },
    gridItem: {
        padding: '10px',
    },
})

function PurchaseOrderCard({ id, reference, date, vendor, invoice }) {
    const classes = useCardStyles()
    return (
        <Grid container>
            {/* <Grid item><p><small>ID: <strong className={classes.emphasised}>{id}</strong></small></p></Grid> */}
            <Grid item xs className={classes.gridItem}><p><small>Reference: </small></p><p><small><strong className={classes.emphasised}>{reference}</strong></small></p></Grid>
            <Grid item xs className={classes.gridItem}><p><small>Date: </small></p><p><small><strong className={classes.emphasised}>{date}</strong></small></p></Grid>
            <Grid item xs className={classes.gridItem}><p><small>Vendor ID: </small></p><p><small><strong className={classes.emphasised}>{vendor}</strong></small></p></Grid>
            <Grid item xs className={classes.gridItem}><p><small>Invoice number: </small></p><p><small><strong className={classes.emphasised}>{invoice}</strong></small></p></Grid>
        </Grid >
    )
}

function StockCard({ id, vendorId, date, invoice }) {
    date = moment(date);
    const classes = useCardStyles()
    return (
        <Grid container>
            {/* <p><small>ID: <strong className={classes.emphasised}>{id}</strong></small></p> */}
            <Grid item xs className={classes.gridItem}><p><small>Invoice: </small></p><p><small><strong className={classes.emphasised}>{invoice}</strong></small></p></Grid>
            <Grid item xs className={classes.gridItem}><p><small>Date: </small></p><p><small><strong className={classes.emphasised}>{date.format('YYYY-MM-DD')}</strong></small></p></Grid>
            <Grid item xs className={classes.gridItem}><p><small>Vendor ID: </small></p><p><small><strong className={classes.emphasised}>{vendorId}</strong></small></p></Grid>
        </Grid>
    )
}
