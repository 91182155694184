import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableToolbar,
  DataGrid,
  authorized,
  Authorization,
} from "@cosmos/chaos";
import { Paper, LinearProgress, Input } from "@material-ui/core";
import {
  LOAD_RESERVATION,
  INV_LOAD_BEGIN,
  CLOSE_RESERVATION_NOTICE,
  UPDATE_RESERVATION_SEARCH_TEXT,
  UPDATE_SELECTED_PERSON,
  STATE_FILTER_UPDATED,
  UPDATE_SEARCH_KEY,
  UPDATE_SELECTED_STOCK_PERSON,
  UPDATE_SELECTED_STOCK_STATE,
  INIT_USER_LOAD,
  OPEN_PROCUREMENT_DRAWER,
  SELECT_PROCUREMENT,
  CANCEL_INV_LOAD,
} from "../../actions";
import { FilterBar } from "../../components/tableFilter";
import { AutoSizer } from "react-virtualized";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import classNames from "classnames";
import { DrawerComponent } from "../reservation/components";
import { FocusDialog } from "../../components";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  reservation: {
    flexGrow: 1,
    display: "flex",
    padding: "0.5em",
  },
  feature: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    backgroundColor: theme.palette.primary.light,
  },
  toolset: {
    display: "flex",
  },
  appBar: {
    listStyle: "none",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "stretch",
    margin: 0,
    padding: 0,
    backgroundColor: theme.palette.primary.light,
  },
  tab: {
    flex: 1,
    textAlign: "center",
    textDecorationColor: "ghostwhite",
    backgroundColor: "#33ab9f",
  },
  messageBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "0.5em",
  },
  "search-input": {
    padding: "4px 4px 4px 60px",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  "auto-size": {
    flex: 1,
  },
  drawer: {
    minWidth: "35rem",
    display: "flex",
    flexDirection: "row",
    borderLeft: "1px solid darken(white, 3)",
    "&.hide": {
      display: "none",
    },
  },
  "header-sortable": {
    display: "flex",
    flexWrap: "wrap",
    color: "#333",
    justifyContent: "flex-start",
  },
  "header-sortable-index": {
    display: "none",
    fontSize: "80%",
  },
  "header-sortable-active": {
    color: "#000",
    "&.header-sortable-index": {
      display: "block",
      fontSize: "80%",
    },
  },
  "header-sortable-icon": {
    width: "12px",
    height: "12px",
    color: "#3333ee",
  },
  "product-row": {
    width: "auto",
    overflowX: "auto",
    scrollBehavior: "smooth",
  },
  scroll: {
    background: "transparent",
  },
  "birth-col": {
    display: "flex",
    flexDirection: "row",
  },
  birth: {
    width: "2rem",
    textAlign: "center",
  },
});

class ReservationComponent extends Component {
  state = {
    orders: new Map(),
    tabValue: 0,
  };

  componentDidMount() {
    this.props.dispatch({ type: LOAD_RESERVATION });
    this.props.dispatch({ type: INIT_USER_LOAD });
    this.props.dispatch({ type: CANCEL_INV_LOAD });
    this.props.dispatch({ type: INV_LOAD_BEGIN, payload: { filter: {} } });
  }

  closeNotification = () => {
    this.props.dispatch({ type: CLOSE_RESERVATION_NOTICE });
  };

  updateSorting(key) {
    let orders = this.state.orders;
    if (orders.has(key)) {
      const dir = orders.get(key);
      if (dir === "asc") {
        orders.set(key, "desc");
      } else {
        orders.delete(key);
      }
    } else {
      orders.set(key, "asc");
    }
    this.setState({ orders });
  }

  searchStock = (event) => {
    const searchText = event.target.value;
    if (searchText !== "undefined") {
      this.props.dispatch({ type: UPDATE_SEARCH_KEY, payload: searchText });
    }
  };

  searchProcurement = (event) => {
    const searchText = event.target.value;
    if (searchText !== "undefined") {
      this.props.dispatch({
        type: UPDATE_RESERVATION_SEARCH_TEXT,
        payload: { searchText },
      });
    }
  };

  handlePersonFilter = (e) => {
    this.props.dispatch({
      type: UPDATE_SELECTED_PERSON,
      payload: { selectedPersons: e.target.value },
    });
  };

  handleStateFilter = (e) => {
    this.props.dispatch({
      type: STATE_FILTER_UPDATED,
      payload: { selectedStates: e.target.value },
    });
  };

  handleStockPersonFilter = (e) => {
    this.props.dispatch({
      type: UPDATE_SELECTED_STOCK_PERSON,
      payload: { selectedStockPics: e.target.value },
    });
  };

  handleStockStateFilter = (e) => {
    this.props.dispatch({
      type: UPDATE_SELECTED_STOCK_STATE,
      payload: { selectedStockStates: e.target.value },
    });
  };

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue, orders: new Map() });
  };

  openProcurementDrawer = (id) => {
    this.props.dispatch({
      type: OPEN_PROCUREMENT_DRAWER,
      payload: { procurementDrawer: true },
    });
    this.props.dispatch({
      type: SELECT_PROCUREMENT,
      payload: { selectedProcurementId: id },
    });
  };

  canCreateReservation = authorized(["cosmos.reservation.create"]);

  render() {
    const {
      permission,
      procurementLoading,
      inventoryLoading,
      marshalledStocks,
      marshalledProcurements,
      notice,
      notify,
      noticeType,
      procurementSearchText,
      inventorySearchText,
      selectedPersons,
      users,
      reservationStateList,
      selectedStates,
      selectedStockPics,
      selectedStockStates,
      procurementDrawer,
      selectedProcurementId,
      classes,
    } = this.props;
    const { orders, tabValue } = this.state;
    const canCreateReservation = this.canCreateReservation(permission);
    let newItem = null;
    if (canCreateReservation) {
      newItem = () => {
        //TODO
      };
    }

    const stockColumns = [
      {
        name: "id",
        header: "ID",
        onHeaderClick: () => {
          this.updateSorting("id");
        },
        cellProps: {
          style: { paddingLeft: 20 },
        },
      },
      {
        name: "brandName",
        header: "Brand",
        onHeaderClick: () => {
          this.updateSorting("brandName");
        },
      },
      {
        name: "productName",
        header: "Model",
        cell: (rowData) => (
          <div className={classes["product-row"]}>
            <div className={classes["scroll"]}>{rowData.productName}</div>
          </div>
        ),
        onHeaderClick: () => {
          this.updateSorting("productName");
        },
      },
      {
        name: "productReference",
        header: "Reference",
        cell: (rowData) => (
          <div className={classes["product-row"]}>
            <div className={classes["scroll"]}>{rowData.productReference}</div>
          </div>
        ),
        onHeaderClick: () => {
          this.updateSorting("productReference");
        },
      },
      {
        name: "serial",
        header: "Serial",
        onHeaderClick: () => {
          this.updateSorting("serial");
        },
      },
      {
        name: "state",
        header: "Status",
        onHeaderClick: () => {
          this.updateSorting("state");
        },
      },
      {
        name: "birth",
        header: "Birthday",
        cell: (rowData) => (
          <div className={classes["birth-col"]}>
            <div className={classes["birth"]}>
              {" "}
              {rowData.birthYear < 9999 ? rowData.birthYear : null}{" "}
            </div>
            <div className={classes["birth"]}>
              {" "}
              {rowData.birthMonth < 99 ? rowData.birthMonth : null}{" "}
            </div>
            <div className={classes["birth"]}>
              {" "}
              {rowData.birthDate < 99 ? rowData.birthDate : null}{" "}
            </div>
          </div>
        ),
        onHeaderClick: () => {
          this.updateSorting("birth");
        },
      },
      {
        name: "storeName",
        header: "Store",
        onHeaderClick: () => {
          this.updateSorting("storeName");
        },
      },
      {
        name: "purchasedAt",
        header: "Purchased At",
        onHeaderClick: () => {
          this.updateSorting("purchasedAt");
        },
      },
      {
        name: "reservationStateName",
        header: "Reservation State",
        onHeaderClick: () => {
          this.updateSorting("reservationStateName");
        },
      },
      {
        name: "reservationPic",
        header: "Person In Charge",
        cell: (rowData) => (
          <div>
            {users[rowData.reservationPic] &&
              users[rowData.reservationPic].name}
          </div>
        ),
        onHeaderClick: () => {
          this.updateSorting("reservationPic");
        },
      },
    ];

    const procurementColumns = [
      {
        name: "indicator",
        header: "",
        width: 30,
      },
      {
        name: "id",
        header: "ID",
        width: 80,
        onHeaderClick: () => {
          this.updateSorting("id");
        },
      },
      {
        name: "brandName",
        header: "Brand",
        onHeaderClick: () => {
          this.updateSorting("brandName");
        },
      },
      {
        name: "productName",
        header: "Model",
        cell: (rowData) => (
          <div className={classes["product-row"]}>
            <div className={classes["scroll"]}>{rowData.productName}</div>
          </div>
        ),
        onHeaderClick: () => {
          this.updateSorting("productName");
        },
      },
      {
        name: "productReference",
        header: "Reference",
        cell: (rowData) => (
          <div className={classes["product-row"]}>
            <div className={classes["scroll"]}>{rowData.productReference}</div>
          </div>
        ),
        onHeaderClick: () => {
          this.updateSorting("productReference");
        },
      },
      {
        name: "price",
        header: "Price",
        onHeaderClick: () => {
          this.updateSorting("price");
        },
      },
      {
        name: "stateName",
        header: "Status",
        onHeaderClick: () => {
          this.updateSorting("stateName");
        },
      },
      {
        name: "createdAt",
        header: "Created At",
        onHeaderClick: () => {
          this.updateSorting("createdAt");
        },
      },
      {
        name: "expireAt",
        header: "Expire At",
        onHeaderClick: () => {
          this.updateSorting("expireAt");
        },
      },
      {
        name: "reservationStateName",
        header: "Reservation State",
        onHeaderClick: () => {
          this.updateSorting("reservationStateName");
        },
      },
      {
        name: "reservationPic",
        header: "Person In Charge",
        cell: (rowData) => (
          <div>
            {users[rowData.reservationPic] &&
              users[rowData.reservationPic].name}
          </div>
        ),
        onHeaderClick: () => {
          this.updateSorting("reservationPic");
        },
      },
    ];

    return (
      <Authorization allowed={["cosmos.reservation.view"]}>
        {({ permit }) =>
          permit(permission) && (
            <div className={classes["reservation"]}>
              <Paper className={classes.feature}>
                <AppBar className={classes.appBar} position="static">
                  <Tabs
                    value={tabValue}
                    onChange={this.handleTabChange}
                    indicatorColor="secondary"
                    variant="fullWidth"
                  >
                    <Tab
                      className={classes.tab}
                      label="Reserved Stock"
                      id={`tab-${0}`}
                    />
                    <Tab
                      className={classes.tab}
                      label="Procurement"
                      id={`tab-${1}`}
                    />
                  </Tabs>
                </AppBar>
                {tabValue === 0 && (
                  <React.Fragment>
                    <TableToolbar
                      numSelected={0}
                      title="Reserved Stocks"
                      styles={{
                        root: classes.toolbar,
                        toolset: classes.toolset,
                        messageBox: classes.messageBox,
                      }}
                      newItemTrigger={newItem}
                      searchBox={() => (
                        <Input
                          placeholder="ID/ Serial/ Reference"
                          disableUnderline
                          value={inventorySearchText}
                          onChange={this.searchStock}
                          className={classes["search-input"]}
                          fullWidth
                        />
                      )}
                      filterBox={() => (
                        <FilterBar
                          units={{
                            pic: {
                              data: users,
                              selected: selectedStockPics,
                              handleChange: this.handleStockPersonFilter,
                            },
                            status: {
                              data: Object.values(reservationStateList),
                              selected: selectedStockStates,
                              handleChange: this.handleStockStateFilter,
                            },
                          }}
                        />
                      )}
                    />
                    {inventoryLoading && <LinearProgress />}
                    <div className={classes.content}>
                      <AutoSizer>
                        {({ width, height }) => (
                          <DataGrid
                            data={marshalledStocks}
                            columns={stockColumns}
                            width={width}
                            maxHeight={height}
                            height={height}
                            includeHeaders={true}
                            fixedRowCount={1}
                            cellProps={{
                              style: { paddingLeft: 2, paddingRight: 4 },
                            }}
                            isCellHovered={(
                              column,
                              rowData,
                              hoveredColumn,
                              hoveredRowData
                            ) => {
                              return (
                                rowData && rowData.id === hoveredRowData.id
                              );
                            }}
                            orderBy={orders}
                            classes={{
                              sortableHeader: classes["header-sortable"],
                              sortableHeaderActive:
                                classes["header-sortable-active"],
                              sortableHeaderIcon:
                                classes["header-sortable-icon"],
                              sortIndex: classes["header-sortable-index"],
                            }}
                          />
                        )}
                      </AutoSizer>
                    </div>
                  </React.Fragment>
                )}
                {tabValue === 1 && (
                  <React.Fragment>
                    <TableToolbar
                      numSelected={0}
                      title="Procurement"
                      styles={{
                        root: classes.toolbar,
                        toolset: classes.toolset,
                        messageBox: classes.messageBox,
                      }}
                      newItemTrigger={newItem}
                      searchBox={() => (
                        <Input
                          placeholder="Reference"
                          disableUnderline
                          value={procurementSearchText}
                          onChange={this.searchProcurement}
                          className={classes["search-input"]}
                          fullWidth
                        />
                      )}
                      filterBox={() => (
                        <FilterBar
                          units={{
                            pic: {
                              data: users,
                              selected: selectedPersons,
                              handleChange: this.handlePersonFilter,
                            },
                            status: {
                              data: Object.values(reservationStateList),
                              selected: selectedStates,
                              handleChange: this.handleStateFilter,
                            },
                          }}
                        />
                      )}
                    />
                    {procurementLoading && <LinearProgress />}
                    <div className={classes.content}>
                      <div className={classes["auto-sizer"]}>
                        <AutoSizer>
                          {({ width, height }) => (
                            <DataGrid
                              data={marshalledProcurements}
                              columns={procurementColumns}
                              width={width}
                              maxHeight={height}
                              height={height}
                              includeHeaders={true}
                              fixedRowCount={1}
                              cellProps={{
                                style: { paddingLeft: 2, paddingRight: 4 },
                              }}
                              onCellClick={(column, rowData) => {
                                this.openProcurementDrawer(rowData.id);
                              }}
                              isCellHovered={(
                                column,
                                rowData,
                                hoveredColumn,
                                hoveredRowData
                              ) => {
                                return (
                                  rowData && rowData.id === hoveredRowData.id
                                );
                              }}
                              orderBy={orders}
                              classes={{
                                sortableHeader: classes["header-sortable"],
                                sortableHeaderActive:
                                  classes["header-sortable-active"],
                                sortableHeaderIcon:
                                  classes["header-sortable-icon"],
                                sortIndex: classes["header-sortable-index"],
                              }}
                              isCellSelected={(column, rowData) => {
                                return rowData.id === selectedProcurementId;
                              }}
                            />
                          )}
                        </AutoSizer>
                      </div>
                      <div
                        className={classNames(classes["drawer"], {
                          hide: !procurementDrawer,
                        })}
                      >
                        <AutoSizer>
                          {({ width, height }) => (
                            <DrawerComponent height={height} width={width} />
                          )}
                        </AutoSizer>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Paper>
              <FocusDialog
                open={notify}
                message={notice}
                variant={noticeType}
                onClose={this.closeNotification}
              />
            </div>
          )
        }
      </Authorization>
    );
  }
}

export const ReservationForm = connect((state) => {
  const { procurements, filteredProcurements } = state.reservation;
  const marshalledProcurements = Object.values(procurements)
    .filter((el) => filteredProcurements.indexOf(el.id) > -1)
    .map((el) => el);
  const { stocks, stocksDetail, stocksExtra, filteredStocks } = state.inventory;
  const marshalledStocks = Object.values(stocks)
    .filter((el) => filteredStocks.indexOf(el.id) > -1)
    .map((el) => {
      return { ...el, ...stocksDetail[el.id], ...stocksExtra[el.id] };
    });

  return {
    permission: state.auth.permission,
    procurementLoading: state.reservation.loading,
    inventoryLoading: state.inventory.loading,
    marshalledStocks: marshalledStocks,
    marshalledProcurements: marshalledProcurements,
    notice: state.reservation.notice,
    notify: state.reservation.notify,
    noticeType: state.reservation.noticeType,
    procurementSearchText: state.reservation.searchText,
    inventorySearchText: state.inventory.search,
    selectedPersons: state.reservation.selectedPersons,
    selectedStates: state.reservation.selectedStates,
    users: state.system.users,
    reservationStateList: state.reservation.reservationStateList,
    selectedStockPics: state.inventory.selectedStockPics,
    selectedStockStates: state.inventory.selectedStockStates,
    procurementDrawer: state.reservation.procurementDrawer,
    selectedProcurementId: state.reservation.selectedProcurementId,
  };
})(withStyles(styles)(ReservationComponent));
