import { createVibes } from "@cosmos/vibes";
import {
  BEGIN_STOCK_IN,
  UPDATE_STOCK_INPUT,
  SELECT_STOCK_INPUT,
  CLOSE_STAGE_1,
  VERIFY_ITEM_LABEL,
  VERIFIED_STAGE_1,
  SUBMIT_STOCK_INFO,
  VERIFIED_STAGE_2,
  OPEN_STOCK_IN_NOTICE,
  SUBMIT_WATCH_FACE,
  VERIFIED_STAGE_3,
  SUBMIT_CARD_FRONT,
  VERIFIED_STAGE_4,
  SUBMIT_CARD_BACK,
  VERIFIED_STAGE_5,
  ADD_NEW_STOCK,
  ADD_NEXT_STOCK,
  CREATE_PURCHASE_ORDER_BEGIN,
  CREATE_PURCHASE_ORDER_SUCCESS,
  CREATE_PURCHASE_ORDER_ERROR,
  EDIT_STOCK_INFO,
  EDIT_STOCK_IMG,
  REMOVE_STOCK_INPUT,
  POPULATE_DISC_RATE,
  UPDATE_PD_INPUT,
  RESET_PURCHASE_FORM,
  BEGIN_CREATE_PURCHASE_STOCK,
  UPDATE_SELECTED_PURCHASE,
  SUBMIT_STOCKS,
  EDIT_PURCHASE,
  SELECT_PURCHASE_DETAIL,
  UPDATE_PO_INPUT,
  SWAP_MODE,
  SAVE_PURCHASE,
  RESET_STOCK_IN_FORM,
  SUBMIT_STOCKS_ERROR,
  OPEN_INVENTORY_NOTICE,
  OPEN_PURCHASE_NOTICE,
  UPDATE_PURCHASE_ORDER_ERROR,
  SUBMIT_INVOICE_PHOTO,
  UPDATE_PROGRESS,
  UPLOADED_INVOICE_PHOTO,
  PROGRESS_COMPLETED,
  UPDATE_STOCK_IN_DISABLE,
  UPDATE_PURCHASE_THEN_STOCKIN,
  PURCHASE_DETAIL_REMOVED,
  REMOVE_PURCHASE_DETAIL,
  UPDATE_DELETED_DETAILS,
  CREATE_VENDOR,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAIL,
  APPEND_NEW_TRADER,
  REVIEW_PURCHASE,
  UPDATE_PC_DATA_URI,
  LOAD_IMAGE_TAKEN_SUCCESS,
  RETRIEVE_PO_STOCKS_FAIL,
  RETRIEVE_PO_STOCKS,
  CLOSE_PO_STOCK_IN_DIALOG,
  UPDATE_PO_STOCK_INPUT,
} from "../actions";
import { push } from "connected-react-router";
import { completed, invalid, pending, opening } from "../store/purchase";
import { dataURItoBlob } from "../services/dataUriToBlob";
import * as shortId from "shortid";
import { trim } from "lodash";

export const defineStageVibes = createVibes({
  type: [
    BEGIN_STOCK_IN,
    CLOSE_STAGE_1,
    VERIFIED_STAGE_1,
    VERIFIED_STAGE_2,
    VERIFIED_STAGE_3,
    VERIFIED_STAGE_4,
    VERIFIED_STAGE_5,
    ADD_NEW_STOCK,
    ADD_NEXT_STOCK,
    EDIT_STOCK_INFO,
    EDIT_STOCK_IMG,
  ],
  latest: true,
  process({ getState, action }, dispatch, done) {
    let { selectedStockInput, stockInputs } = getState().purchase;
    switch (action.type) {
      case BEGIN_STOCK_IN:
        dispatch(push("/orders/purchase/stockin"));
        dispatch({
          type: UPDATE_STOCK_INPUT,
          payload: {
            idx: 0,
            value: {
              condition: 0,
              package: {
                warrantyCard: false,
                cardCase: false,
                box: false,
                tag: false,
                manual: false,
              },
              stage1: opening,
              stage2: pending,
              stage3: pending,
              stage4: invalid,
              stage5: invalid,
            },
          },
        });
        dispatch({ type: SELECT_STOCK_INPUT, payload: stockInputs.length });
        break;
      case ADD_NEW_STOCK:
      case ADD_NEXT_STOCK:
        dispatch({
          type: UPDATE_STOCK_INPUT,
          payload: {
            idx: stockInputs.length,
            value: {
              condition: 0,
              stage1: opening,
              stage2: pending,
              stage3: pending,
              stage4: invalid,
              stage5: invalid,
              package: {
                warrantyCard: false,
                cardCase: false,
                box: false,
                tag: false,
                manual: false,
              },
            },
          },
        });
        dispatch({ type: SELECT_STOCK_INPUT, payload: stockInputs.length });
        break;
      case CLOSE_STAGE_1:
        dispatch({ type: REMOVE_STOCK_INPUT, payload: selectedStockInput });
        break;
      case VERIFIED_STAGE_1:
        dispatch({
          type: UPDATE_STOCK_INPUT,
          payload: {
            idx: selectedStockInput,
            value: { stage1: completed, stage2: opening },
          },
        });
        break;
      case VERIFIED_STAGE_2:
        dispatch({
          type: UPDATE_STOCK_INPUT,
          payload: { idx: selectedStockInput, value: { stage2: completed } },
        });
        if (
          stockInputs[selectedStockInput].package &&
          stockInputs[selectedStockInput].package.warrantyCard
        ) {
          dispatch({
            type: UPDATE_STOCK_INPUT,
            payload: {
              idx: selectedStockInput,
              value: { stage4: pending, stage5: pending },
            },
          });
        } else {
          dispatch({
            type: UPDATE_STOCK_INPUT,
            payload: {
              idx: selectedStockInput,
              value: { stage4: invalid, stage5: invalid },
            },
          });
        }
        if (stockInputs[selectedStockInput].stage3 !== completed) {
          dispatch({
            type: UPDATE_STOCK_INPUT,
            payload: { idx: selectedStockInput, value: { stage3: opening } },
          });
        }
        break;
      case VERIFIED_STAGE_3:
        dispatch({
          type: UPDATE_STOCK_INPUT,
          payload: { idx: selectedStockInput, value: { stage3: completed } },
        });
        if (
          stockInputs[selectedStockInput].stage4 !== invalid &&
          stockInputs[selectedStockInput].stage4 !== completed
        ) {
          dispatch({
            type: UPDATE_STOCK_INPUT,
            payload: { idx: selectedStockInput, value: { stage4: opening } },
          });
        }
        break;
      case VERIFIED_STAGE_4:
        dispatch({
          type: UPDATE_STOCK_INPUT,
          payload: { idx: selectedStockInput, value: { stage4: completed } },
        });
        if (
          stockInputs[selectedStockInput].stage5 !== invalid &&
          stockInputs[selectedStockInput].stage5 !== completed
        ) {
          dispatch({
            type: UPDATE_STOCK_INPUT,
            payload: { idx: selectedStockInput, value: { stage5: opening } },
          });
        }
        break;
      case VERIFIED_STAGE_5:
        dispatch({
          type: UPDATE_STOCK_INPUT,
          payload: { idx: selectedStockInput, value: { stage5: completed } },
        });
        break;
      case EDIT_STOCK_INFO:
        dispatch({
          type: UPDATE_STOCK_INPUT,
          payload: { idx: selectedStockInput, value: { stage2: opening } },
        });
        break;
      case EDIT_STOCK_IMG:
        switch (action.payload) {
          case 3:
            dispatch({
              type: UPDATE_STOCK_INPUT,
              payload: { idx: selectedStockInput, value: { stage3: opening } },
            });
            break;
          case 4:
            dispatch({
              type: UPDATE_STOCK_INPUT,
              payload: { idx: selectedStockInput, value: { stage4: opening } },
            });
            break;
          case 5:
            dispatch({
              type: UPDATE_STOCK_INPUT,
              payload: { idx: selectedStockInput, value: { stage5: opening } },
            });
            break;
          default:
        }
        break;
      default:
    }
    done();
  },
});

export const verifyLabelVibes = createVibes({
  type: VERIFY_ITEM_LABEL,
  debounce: 500,
  latest: true,
  validate({ getState, action, client, axios }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, axios }, dispatch, done) {
    try {
      const { stockInputs, selectedStockInput } = getState().purchase;
      const label = trim(stockInputs[selectedStockInput].label);
      const currentLabels = stockInputs.map((el) => trim(el.label));

      if (
        currentLabels.indexOf(label) > -1 &&
        currentLabels.indexOf(label) !== selectedStockInput
      ) {
        dispatch({
          type: OPEN_STOCK_IN_NOTICE,
          payload: {
            notify: true,
            notice: "The label cannot be duplicated",
            noticeType: "error",
          },
        });
      } else {
        let alphacode = "STK";

        const { token } = getState().auth;
        const { rayLens } = getState().system;

        const query = await axios.request({
          url: `/${alphacode}/${label}`,
          method: "get",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            rayLens,
          },
        });

        if (query.status < 400) {
          if (query.status === 204) {
            dispatch({ type: VERIFIED_STAGE_1 });
          } else {
            dispatch({
              type: OPEN_STOCK_IN_NOTICE,
              payload: {
                notify: true,
                notice: "The label is already used",
                noticeType: "error",
              },
            });
          }
        } else {
          dispatch({
            type: OPEN_STOCK_IN_NOTICE,
            payload: {
              notify: true,
              notice: "The label is not valid",
              noticeType: "error",
            },
          });
        }
      }
      done();
    } catch (err) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "The label is not valid",
          noticeType: "error",
        },
      });
      done();
    }
  },
});

export const verifyInfoVibes = createVibes({
  type: SUBMIT_STOCK_INFO,
  latest: true,
  process({ getState, action }, dispatch, done) {
    const { selectedStockInput, stockInputs } = getState().purchase;
    let currentDate = new Date();
    let birthYear;
    let birthMonth;
    let birthDate;
    if (
      stockInputs[selectedStockInput] &&
      stockInputs[selectedStockInput].birthYear
    ) {
      birthYear = parseInt(stockInputs[selectedStockInput].birthYear);
    }
    if (
      stockInputs[selectedStockInput] &&
      stockInputs[selectedStockInput].birthMonth
    ) {
      birthMonth = parseInt(stockInputs[selectedStockInput].birthMonth);
    }
    if (
      stockInputs[selectedStockInput] &&
      stockInputs[selectedStockInput].birthDate
    ) {
      birthDate = parseInt(stockInputs[selectedStockInput].birthDate);
    }

    if (
      stockInputs[selectedStockInput] &&
      stockInputs[selectedStockInput].productId === ""
    ) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "Product field is required",
          noticeType: "error",
        },
      });
    } else if (
      stockInputs[selectedStockInput] &&
      stockInputs[selectedStockInput].serial === ""
    ) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "Serial field is required",
          noticeType: "error",
        },
      });
    } else if (
      birthYear &&
      (birthYear > currentDate.getFullYear() || birthYear < 1000)
    ) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "Birth year is invalid",
          noticeType: "error",
        },
      });
    } else if (birthDate && (birthDate > 31 || birthDate < 1)) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "Birth date is invalid",
          noticeType: "error",
        },
      });
    } else if (
      birthDate &&
      (birthMonth === 4 ||
        birthMonth === 6 ||
        birthMonth === 9 ||
        birthMonth === 11) &&
      (birthDate > 30 || birthDate < 1)
    ) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "Birth date is invalid",
          noticeType: "error",
        },
      });
    } else if (
      birthDate &&
      birthMonth === 2 &&
      (birthDate > 29 || birthDate < 1)
    ) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "Birth date is invalid",
          noticeType: "error",
        },
      });
    } else if (
      birthYear &&
      birthDate &&
      birthMonth === 2 &&
      birthYear % 4 !== 0 &&
      (birthDate > 28 || birthDate < 1)
    ) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "Birth date is invalid",
          noticeType: "error",
        },
      });
    } else {
      dispatch({ type: VERIFIED_STAGE_2 });
    }
    done();
  },
});

export const saveImageVibes = createVibes({
  type: [SUBMIT_WATCH_FACE, SUBMIT_CARD_FRONT, SUBMIT_CARD_BACK],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { selectedStockInput } = getState().purchase;

      let dataUri = action.payload;
      let blob = dataURItoBlob(dataUri, {});
      let form = new FormData();
      form.append("fileData", blob);

      let photoInput = shortId.generate();

      const response = await axios.request({
        url: `/upload/stock/${photoInput}`,
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          rayLens,
          "Content-Type": "multipart/form-data",
        },
        data: form,
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch({
            type: UPDATE_PROGRESS,
            payload: { uploadProgress: progress },
          });
          if (progress === 100) {
            dispatch({
              type: PROGRESS_COMPLETED,
              payload: {
                notify: true,
                notice: "Succeed to upload stock photo!",
                noticeType: "success",
              },
            });
          }
        },
      });

      let value;
      if (response.status === 200) {
        switch (action.type) {
          case SUBMIT_WATCH_FACE:
            value = { watchFace: photoInput };
            dispatch({
              type: UPDATE_STOCK_INPUT,
              payload: { idx: selectedStockInput, value: value },
            });
            dispatch({ type: VERIFIED_STAGE_3 });
            break;
          case SUBMIT_CARD_FRONT:
            value = { cardFront: photoInput };
            dispatch({
              type: UPDATE_STOCK_INPUT,
              payload: { idx: selectedStockInput, value: value },
            });
            dispatch({ type: VERIFIED_STAGE_4 });
            break;
          case SUBMIT_CARD_BACK:
            value = { cardBack: photoInput };
            dispatch({
              type: UPDATE_STOCK_INPUT,
              payload: { idx: selectedStockInput, value: value },
            });
            dispatch({ type: VERIFIED_STAGE_5 });
            break;
          default:
        }
      } else {
        dispatch({
          type: OPEN_STOCK_IN_NOTICE,
          payload: {
            notify: true,
            notice: "Fail to upload photo",
            noticeType: "error",
          },
        });
      }
      done();
    } catch (err) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "Fail to upload photo",
          noticeType: "error",
        },
      });
      done();
    }
  },
});

export const populateDiscRateVibes = createVibes({
  type: [POPULATE_DISC_RATE],
  latest: true,
  process({ getState, action }, dispatch, done) {
    const purchaseInput = getState().purchase.purchaseInput;
    let pdLength = purchaseInput.purchaseDetails
      ? purchaseInput.purchaseDetails.length
      : 0;
    if (pdLength > 0) {
      for (let k = 0; k < pdLength; k++) {
        dispatch({
          type: UPDATE_PD_INPUT,
          payload: { index: k, payload: action.payload },
        });
      }
    }
    done();
  },
});

export const createPOVibes = createVibes({
  type: [CREATE_PURCHASE_ORDER_BEGIN, BEGIN_CREATE_PURCHASE_STOCK],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      let purchaseInput = { ...getState().purchase.purchaseInput };
      const once = getState().purchase.purchaseOnce;

      purchaseInput.once = once;
      purchaseInput.state = action.payload.state;
      if (!purchaseInput.discountRate) {
        purchaseInput.discountRate = 0;
      }
      purchaseInput.discountRate = parseFloat(purchaseInput.discountRate);
      purchaseInput.remarks = { comments: purchaseInput.remarks };

      if (
        purchaseInput.purchaseDetails &&
        purchaseInput.purchaseDetails.length > 0
      ) {
        purchaseInput.purchaseDetails = purchaseInput.purchaseDetails.map(
          (rawPd) => {
            let pd = { ...rawPd };
            delete pd.productDisplay;
            pd.quantity = 0;
            pd.quantityExpected = parseInt(pd.quantityExpected);
            if (!pd.discountRate) {
              pd.discountRate = 0;
            }
            pd.discountRate = parseFloat(pd.discountRate);
            delete pd.price;
            delete pd.amount;
            return pd;
          }
        );
        delete purchaseInput.vendorCountryId;

        const { token } = getState().auth;
        const { rayLens } = getState().system;

        const result = await axios.request({
          url: "/api",
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
            rayLens,
          },
          data: {
            query: `mutation ($purchaseInput: PurchaseInput) {
              updatePO(input: $purchaseInput) {
                id
                storeId
                reference
                date
                vendor
                contact
                invoice
                tracking
                type
                taxRate
                discountRate
                remarks
                userId
                currency
                forexSet
                purchaseDetails {
                  id
                  brandId
                  productId
                  unitPrice
                  quantityExpected
                  quantity
                  price
                  discountRate
                  discountValue
                  priceWithDiscount
                  taxRate
                  taxValue
                  amount
                  taxValueWithOrderDiscount
                  amountWithOrderDiscount
                  total
                  remarks
                  currency
                  exchangeSet
                  purchaseId
                  userId
                  createdAt
                  updatedAt
                  deletedAt
                  state
                }
                restQuantity
                createdAt
                updatedAt
                deletedAt
                state
                attachments {
                  id
                  type
                  fid
                }
                stockInDetail {
                  extraStockIn {
                    id
                    stockId
                    stockInId
                    state
                  }
                }
              }
            }`,
            variables: { purchaseInput },
          },
        });

        let data = result.data;
        let createPurchase = data.updatePO;

        if (createPurchase) {
          dispatch({ type: RESET_PURCHASE_FORM });
          dispatch({
            type: CREATE_PURCHASE_ORDER_SUCCESS,
            payload: createPurchase,
          });
          switch (action.type) {
            case CREATE_PURCHASE_ORDER_BEGIN:
              dispatch(push("/orders/purchase"));
              break;
            case BEGIN_CREATE_PURCHASE_STOCK:
              dispatch({
                type: UPDATE_SELECTED_PURCHASE,
                payload: { selectedPurchaseId: createPurchase.id },
              });
              dispatch({ type: BEGIN_STOCK_IN });
              break;
            default:
          }
        } else {
          dispatch({ type: CREATE_PURCHASE_ORDER_ERROR });
        }
      } else {
        dispatch({ type: CREATE_PURCHASE_ORDER_ERROR });
      }
      done();
    } catch (err) {
      dispatch({ type: CREATE_PURCHASE_ORDER_ERROR });
      done();
    }
  },
});

export const stockInVibes = createVibes({
  type: SUBMIT_STOCKS,
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const {
        stockInputs,
        selectedPurchaseId,
        stockInOnce,
      } = getState().purchase;
      let stockList = stockInputs.map((el) => {
        return {
          productId: el.productId,
          brandId: el.brandId,
          serial: el.serial,
          label: el.label,
          birthYear: el.birthYear ? String(el.birthYear) : "",
          birthMonth: el.birthMonth ? String(el.birthMonth) : "",
          birthDate: el.birthDate ? String(el.birthDate) : "",
          package: el.package,
          cardFront: el.cardFront,
          cardBack: el.cardBack,
          watchFront: el.watchFace,
        };
      });

      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const result = await axios.request({
        url: "/api",
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          rayLens,
        },
        data: {
          query: `mutation($purchaseId: ID, $stocks: [StockInput], $once: String!){
            stockIn(purchaseId: $purchaseId, stocks: $stocks, once: $once){
              id
              storeId
              reference
              date
              vendor
              contact
              invoice
              tracking
              type
              taxRate
              discountRate
              state
              remarks
              userId
              createdAt
              updatedAt
              deletedAt
              purchaseDetails{
                id
                brandId
                productId
                unitPrice
                quantityExpected
                quantity
                price
                discountRate
                discountValue
                priceWithDiscount
                taxRate
                taxValue
                amount
                taxValueWithOrderDiscount
                amountWithOrderDiscount
                total
                remarks
                createdAt
                updatedAt
                deletedAt
                currency
                exchangeSet
                purchaseId
                userId
              }
              restQuantity
            }
          }`,
          variables: {
            purchaseId: selectedPurchaseId,
            stocks: stockList,
            once: stockInOnce,
          },
        },
      });

      let data = result.data;

      let stockIn = data.stockIn;

      if (stockIn) {
        dispatch(push("/inventory"));
        dispatch({
          type: OPEN_INVENTORY_NOTICE,
          payload: {
            notify: true,
            notice: "Stock in successfully!",
            noticeType: "success",
          },
        });
        dispatch({ type: RESET_STOCK_IN_FORM });
      } else {
        dispatch({
          type: OPEN_STOCK_IN_NOTICE,
          payload: {
            notify: true,
            notice: "Fail to stock in",
            noticeType: "error",
          },
        });
      }
      done();
    } catch (err) {
      dispatch({
        type: OPEN_STOCK_IN_NOTICE,
        payload: {
          notify: true,
          notice: "Fail to stock in",
          noticeType: "error",
        },
      });
      dispatch({ type: SUBMIT_STOCKS_ERROR });
      done();
    }
  },
});

export const beginPurchaseEditVibes = createVibes({
  type: EDIT_PURCHASE,
  latest: true,
  process({ getState }, dispatch, done) {
    const { purchases, reviewedPurchaseId } = getState().purchase;

    if (reviewedPurchaseId && purchases[reviewedPurchaseId]) {
      dispatch({ type: RESET_PURCHASE_FORM });
      dispatch(push("/purchase/new"));
      dispatch({ type: SWAP_MODE, payload: true });
      dispatch({
        type: UPDATE_PO_INPUT,
        payload: purchases[reviewedPurchaseId],
      });
      dispatch({ type: SELECT_PURCHASE_DETAIL, payload: 0 });
    }

    done();
  },
});

export const savePurchaseVibes = createVibes({
  type: [SAVE_PURCHASE, UPDATE_PURCHASE_THEN_STOCKIN],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { purchaseInput, deletedDetails } = getState().purchase;
      let updatePurchaseInput = {};
      updatePurchaseInput.date = purchaseInput.date;
      updatePurchaseInput.contact = purchaseInput.contact;
      updatePurchaseInput.invoice = purchaseInput.invoice;
      updatePurchaseInput.tracking = purchaseInput.tracking;
      updatePurchaseInput.type = purchaseInput.type;
      updatePurchaseInput.taxRate = purchaseInput.taxRate;
      updatePurchaseInput.discountRate = purchaseInput.discountRate;
      updatePurchaseInput.once = getState().purchase.purchaseOnce;
      updatePurchaseInput.vendor = purchaseInput.vendor;
      updatePurchaseInput.remarks = { comments: purchaseInput.remarks };
      updatePurchaseInput.currency = purchaseInput.currency;

      if (!purchaseInput.discountRate) {
        updatePurchaseInput.discountRate = 0;
      }

      updatePurchaseInput.purchaseDetails = [];

      if (
        purchaseInput.purchaseDetails &&
        purchaseInput.purchaseDetails.length > 0
      ) {
        updatePurchaseInput.purchaseDetails = purchaseInput.purchaseDetails.map(
          (rawPd) => {
            let pd = {};
            pd.id = rawPd.id;
            pd.productId = rawPd.productId;
            pd.brandId = rawPd.brandId;
            pd.unitPrice = rawPd.unitPrice;
            pd.quantityExpected = parseInt(rawPd.quantityExpected);
            pd.quantity = rawPd.quantity;
            pd.discountRate = rawPd.discountRate;
            pd.remarks = rawPd.remarks;
            return pd;
          }
        );

        const { token } = getState().auth;
        const { rayLens } = getState().system;

        const result = await axios.request({
          url: "/api",
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
            rayLens,
          },
          data: {
            query: `mutation ($updatePurchaseInput: PurchaseInput, $purchaseId: ID, $deletedDetails: [ID] ) {
              updatePurchase(input: $updatePurchaseInput, purchaseId: $purchaseId, deletedDetails: $deletedDetails) {
                id
                storeId
                reference
                date
                vendor
                contact 
                invoice
                tracking
                type
                taxRate
                discountRate
                state
                currency
                remarks
                userId
                purchaseDetails{
                  id
                  brandId
                  productId
                  unitPrice
                  quantityExpected
                  quantity
                  price
                  discountRate
                  discountValue
                  priceWithDiscount
                  taxRate
                  taxValue
                  amount
                  taxValueWithOrderDiscount
                  amountWithOrderDiscount
                  total
                  remarks
                  createdAt
                  updatedAt
                  deletedAt
                  currency
                  exchangeSet
                  userId
                }
                restQuantity
              }
            }`,
            variables: {
              updatePurchaseInput: updatePurchaseInput,
              purchaseId: purchaseInput.id,
              deletedDetails,
            },
          },
        });

        let data = result.data;

        let updatePurchase = data.updatePurchase;

        if (updatePurchase) {
          dispatch(push("/purchase"));
          dispatch({ type: RESET_PURCHASE_FORM });
          dispatch({
            type: OPEN_PURCHASE_NOTICE,
            payload: {
              notify: true,
              notice: "Update Purchase Successfully!",
              noticeType: "success",
            },
          });
          if (action.type === UPDATE_PURCHASE_THEN_STOCKIN) {
            dispatch({
              type: UPDATE_SELECTED_PURCHASE,
              payload: {
                selectedPurchaseId: getState().purchase.reviewedPurchaseId,
              },
            });
            dispatch({ type: BEGIN_STOCK_IN });
          }
        } else {
          dispatch({
            type: OPEN_PURCHASE_NOTICE,
            payload: {
              notify: true,
              notice: "Fail to Update Purchase Order!",
              noticeType: "error",
            },
          });
        }
      } else {
        dispatch({ type: UPDATE_PURCHASE_ORDER_ERROR });
      }
      done();
    } catch (err) {
      dispatch({ type: UPDATE_PURCHASE_ORDER_ERROR });
      done();
    }
  },
});

export const saveInvoicePhoto = createVibes({
  type: SUBMIT_INVOICE_PHOTO,
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { purchaseInput } = getState().purchase;

      let dataUri = action.payload;
      let blob = dataURItoBlob(dataUri, {});

      let form = new FormData();
      form.append("fileData", blob);

      let fileIndicator = shortId.generate();

      const response = await axios.request({
        url: `/upload/invoice/${fileIndicator}`,
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          rayLens,
          "Content-Type": "multipart/form-data",
        },
        data: form,
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch({
            type: UPDATE_PROGRESS,
            payload: { uploadProgress: progress },
          });
          if (progress === 100) {
            dispatch({
              type: PROGRESS_COMPLETED,
              payload: {
                captureInvoice: false,
                notify: true,
                notice: "Succeed to upload invoice photo!",
                noticeType: "success",
              },
            });
          }
        },
      });

      if (response.status === 200) {
        dispatch({
          type: UPLOADED_INVOICE_PHOTO,
          payload: {
            purchaseInput: {
              ...purchaseInput,
              invoiceIndicator: fileIndicator,
            },
          },
        });
      } else {
        dispatch({
          type: OPEN_PURCHASE_NOTICE,
          payload: {
            captureInvoice: false,
            notify: true,
            notice: "Fail to upload invoice photo!",
            noticeType: "error",
            purchaseInput: { ...purchaseInput, invoiceIndicator: null },
            dataUri: null,
          },
        });
      }
      done();
    } catch (err) {
      const { purchaseInput } = getState().purchase;
      dispatch({
        type: OPEN_PURCHASE_NOTICE,
        payload: {
          captureInvoice: false,
          notify: true,
          notice: "Fail to upload invoice photo!",
          noticeType: "error",
          purchaseInput: { ...purchaseInput, invoiceIndicator: null },
          dataUri: null,
        },
      });
      done();
    }
  },
});

export const disableStockInFormVibes = createVibes({
  type: [UPDATE_STOCK_INPUT, SUBMIT_STOCK_INFO, ADD_NEW_STOCK, CLOSE_STAGE_1],
  latest: true,
  async process({ getState }, dispatch, done) {
    try {
      const { stockInputs, selectedPurchaseId } = getState().purchase;
      let disableStockIn = false;

      if (selectedPurchaseId < 0) {
        disableStockIn = true;
      }

      if (!disableStockIn && (!stockInputs || stockInputs.length < 1)) {
        disableStockIn = true;
      }

      if (!disableStockIn && stockInputs) {
        for (let k = 0; k < stockInputs.length; k++) {
          if (!stockInputs[k].productId || !stockInputs[k].serial) {
            disableStockIn = true;
            break;
          }
        }
      }

      dispatch({ type: UPDATE_STOCK_IN_DISABLE, payload: { disableStockIn } });
      done();
    } catch (err) {
      throw err;
    }
  },
});

export const removeDetailsVibes = createVibes({
  type: REMOVE_PURCHASE_DETAIL,
  latest: true,
  async process({ getState, action, client }, dispatch, done) {
    try {
      const {
        purchaseInput,
        selectedPdIdx,
        editMode,
        deletedDetails,
      } = getState().purchase;
      let updatedPurchaseDetails = purchaseInput.purchaseDetails
        ? purchaseInput.purchaseDetails
        : [];

      if (editMode) {
        const deletedDetail = updatedPurchaseDetails[action.payload];
        if (deletedDetail.id) {
          const newDeletedDetails = deletedDetails.map((el) => el);
          newDeletedDetails.push(deletedDetail.id);
          dispatch({
            type: UPDATE_DELETED_DETAILS,
            payload: { deletedDetails: newDeletedDetails },
          });
        }
      }

      updatedPurchaseDetails = [
        ...updatedPurchaseDetails.slice(0, action.payload),
        ...updatedPurchaseDetails.slice(action.payload + 1),
      ];
      const newSelected =
        selectedPdIdx < action.payload
          ? selectedPdIdx
          : selectedPdIdx > action.payload
            ? selectedPdIdx - 1
            : selectedPdIdx === 0
              ? 0
              : selectedPdIdx - 1;
      localStorage.setItem(
        "purchaseInput",
        JSON.stringify({
          ...purchaseInput,
          purchaseDetails: updatedPurchaseDetails,
        })
      );
      localStorage.setItem("selectedPdIdx", JSON.stringify(newSelected));
      dispatch({
        type: PURCHASE_DETAIL_REMOVED,
        payload: {
          selectedPdIdx: newSelected,
          purchaseInput: {
            ...purchaseInput,
            purchaseDetails: updatedPurchaseDetails,
          },
        },
      });
      done();
    } catch (err) {
      done();
    }
  },
});

export const createVendorVibes = createVibes({
  type: [CREATE_VENDOR],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { purchaseInput, stockInInput } = getState().purchase;

      let name;
      let isPurchaseInput;
      if (purchaseInput.vendor) {
        name = purchaseInput.vendor;
        isPurchaseInput = true;
      } else {
        name = stockInInput.vendor;
        isPurchaseInput = false;
      }

      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const traderInput = {
        name: trim(name),
        type: 3, //TODO: check how to determine this, now set as 3 by default
      };

      const result = await axios.request({
        url: "/api",
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          rayLens,
        },
        data: {
          query: `mutation ($traderInput: TraderInput) {
            createTrader(input: $traderInput) {
              id name type isoCode address email phone reputation status handlerId kkkId countryId currencyId
            }
          }`,
          variables: { traderInput },
        },
      });

      let data = result.data;

      let createTrader = data.createTrader;

      if (createTrader) {
        if (isPurchaseInput) {
          dispatch({
            type: UPDATE_PO_INPUT,
            payload: { vendor: createTrader.id },
          });
        } else {
          dispatch({
            type: UPDATE_PO_STOCK_INPUT,
            payload: { vendor: createTrader.id },
          });
        }
        dispatch({ type: APPEND_NEW_TRADER, payload: createTrader });
        dispatch({
          type: CREATE_VENDOR_SUCCESS,
          payload: {
            notify: true,
            notice: "Successful to create the vendor",
            noticeType: "success",
            askCreateVendor: false,
          },
        });
      } else {
        dispatch({
          type: CREATE_VENDOR_FAIL,
          payload: {
            notify: true,
            notice: "Fail to create the vendor",
            noticeType: "error",
            askCreateVendor: false,
          },
        });
      }
      done();
    } catch (err) {
      dispatch({
        type: CREATE_VENDOR_FAIL,
        payload: {
          notify: true,
          notice: "Fail to create the vendor",
          noticeType: "error",
          askCreateVendor: false,
        },
      });
      done();
    }
  },
});

export const fetchInvoiceVibes = createVibes({
  type: [REVIEW_PURCHASE],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const selectedPurchaseId = action.payload;
      const { purchases } = getState().purchase;
      const selectedPurchase = purchases[selectedPurchaseId];
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      if (selectedPurchase.type) {
        switch (selectedPurchase.type) {
          case "EX":
            const imageData = await axios.request({
              url: `file/purchase/${selectedPurchase.id}/0`,
              method: "get",
              headers: { Authorization: `Bearer ${token}`, rayLens },
              responseType: "blob",
            });
            const dataResponse =
              imageData.request && imageData.request.response;
            const imageFile = new Blob([dataResponse], { type: "image/png" });
            const imageFileURL = URL.createObjectURL(imageFile);
            dispatch({
              type: UPDATE_PC_DATA_URI,
              payload: { dataUri: imageFileURL },
            });
            break;

          case "WI":
            const fileData = await axios.request({
              url: `/wpi/${selectedPurchase.storeId}/${selectedPurchase.reference}`,
              method: "get",
              headers: {
                Authorization: `Bearer ${token}`,
                rayLens,
              },
              responseType: "blob",
            });
            const fileResponse = fileData.request && fileData.request.response;
            const pdfFile = new Blob([fileResponse], {
              type: "application/pdf",
            });
            const pdfFileURL = URL.createObjectURL(pdfFile);
            dispatch({
              type: UPDATE_PC_DATA_URI,
              payload: { dataUri: pdfFileURL },
            });
            break;
          default:
        }
      }
      done();
    } catch (err) {
      done();
    }
  },
});

export const fetchTempInvVibes = createVibes({
  type: [UPLOADED_INVOICE_PHOTO],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, axios }, dispatch, done) {
    try {
      const { purchaseInput } = getState().purchase;
      if (purchaseInput && purchaseInput.invoiceIndicator) {
        const { token } = getState().auth;
        const { rayLens } = getState().system;
        const fileData = await axios.request({
          url: `/upload/invoice/${purchaseInput.invoiceIndicator}`,
          method: "get",
          headers: {
            Authorization: `Bearer ${token}`,
            rayLens,
          },
          responseType: "blob",
        });
        const response = fileData.request && fileData.request.response;
        const file = new Blob([response], { type: "image/png" });
        const fileURL = URL.createObjectURL(file);
        dispatch({ type: UPDATE_PC_DATA_URI, payload: { dataUri: fileURL } });
      }
      done();
    } catch (err) {
      done();
    }
  },
});

export const fetchTempImgVibes = createVibes({
  type: [VERIFIED_STAGE_3, VERIFIED_STAGE_4, VERIFIED_STAGE_5],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const {
        selectedStockInput,
        stockInputs,
        imageBoxUris,
      } = getState().purchase;

      if (stockInputs[selectedStockInput]) {
        const { token } = getState().auth;
        const { rayLens } = getState().system;
        let newUris = { ...imageBoxUris[selectedStockInput] };

        switch (action.type) {
          case VERIFIED_STAGE_3:
            if (stockInputs[selectedStockInput].watchFace) {
              const fileData = await axios.request({
                url: `/upload/stock/${stockInputs[selectedStockInput].watchFace}`,
                method: "get",
                headers: {
                  Authorization: `Bearer ${token}`,
                  rayLens,
                },
                responseType: "blob",
              });
              const response = fileData.request && fileData.request.response;
              const file = new Blob([response], { type: "image/png" });
              const fileURL = URL.createObjectURL(file);
              newUris.watchFace = fileURL;
            }
            break;
          case VERIFIED_STAGE_4:
            if (stockInputs[selectedStockInput].cardFront) {
              const fileData = await axios.request({
                url: `/upload/stock/${stockInputs[selectedStockInput].cardFront}`,
                method: "get",
                headers: {
                  Authorization: `Bearer ${token}`,
                  rayLens,
                },
                responseType: "blob",
              });
              const response = fileData.request && fileData.request.response;
              const file = new Blob([response], { type: "image/png" });
              const fileURL = URL.createObjectURL(file);
              newUris.cardFront = fileURL;
            }
            break;
          case VERIFIED_STAGE_5:
            if (stockInputs[selectedStockInput].cardBack) {
              const fileData = await axios.request({
                url: `/upload/stock/${stockInputs[selectedStockInput].cardBack}`,
                method: "get",
                headers: {
                  Authorization: `Bearer ${token}`,
                  rayLens,
                },
                responseType: "blob",
              });
              const response = fileData.request && fileData.request.response;
              const file = new Blob([response], { type: "image/png" });
              const fileURL = URL.createObjectURL(file);
              newUris.cardBack = fileURL;
            }
            break;
          default:
        }
        let newUrisArr = imageBoxUris.map((el) => el);
        newUrisArr[selectedStockInput] = newUris;
        dispatch({
          type: LOAD_IMAGE_TAKEN_SUCCESS,
          payload: { imageBoxUris: newUrisArr },
        });
      }
      done();
    } catch (err) {
      done();
    }
  },
});

export const retrievePOCheckStocksVibes = createVibes({
  type: RETRIEVE_PO_STOCKS,
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const reference = action.payload;

      const { token } = getState().auth;
      const { rayLens } = getState().system;

      if (reference) {
        const variables = {
          filter: { reference },
        };

        const result = await axios.request({
          url: "/api",
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
            rayLens,
          },
          data: {
            query: `query ($filter: purchaseFilter){
                  purchaseOrder(filter: $filter) {
                   id reference
                  }
              }`,
            variables,
          },
        });

        let data = result.data;
        let purchaseOrder = data.purchaseOrder;

        if (purchaseOrder) {
          dispatch({ type: BEGIN_STOCK_IN });
          dispatch({
            type: UPDATE_SELECTED_PURCHASE,
            payload: { selectedPurchaseId: purchaseOrder.id },
          });
          dispatch({ type: CLOSE_PO_STOCK_IN_DIALOG });
        } else {
          dispatch({ type: RETRIEVE_PO_STOCKS_FAIL });
        }
      } else {
        dispatch({ type: BEGIN_STOCK_IN });
        dispatch({ type: CLOSE_PO_STOCK_IN_DIALOG });
      }
      done();
    } catch (err) {
      dispatch({ type: RETRIEVE_PO_STOCKS_FAIL });
      done();
    }
  },
});
