import React, { useState, useCallback } from "react";
import { FocusDialog } from "../components/dialog";

/**
 *
 * @param {'error' | 'success'} variant
 */
export default function useDialog(variant = "error", onClose = () => {}) {
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const closeHandler = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [onClose]);

  const openDialog = useCallback(
    /**
     * @param {string} message
     */
    (message) => {
      setMessage(message);
      setIsOpen(true);
    },
    []
  );

  const Dialog = useCallback(() => {
    return (
      <FocusDialog
        open={isOpen}
        message={message}
        variant={variant}
        onClose={closeHandler}
      />
    );
  }, [message, isOpen, variant, closeHandler]);

  return { openDialog, Dialog };
}
