import React, { Component } from "react";
import Paper from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import {
  CART_SELECT_ITEM,
  CART_REMOVE_ITEM_BEGIN,
  UPDATE_ITEM_PRICE,
  UPDATE_IDV_DISC_RATE,
  SUBMIT_WARRANTY_BACK_PHOTO,
  SUBMIT_WARRANTY_FRONT_PHOTO,
  TOGGLE_BIRTH_DIALOG,
  UPDATE_POS_FIELD,
  CLEAR_BIRTH,
  POS_WARRANTY_DIALOG,
  GET_TEMP_IMAGE,
} from "../../../actions";
import { TextField } from "@material-ui/core";
import classNames from "classnames";
import { InputAdornment, ListItemText } from "@material-ui/core";
import { NumberFormatCustom } from "../../../components";
import { ItemView } from "./itemView";
import { PhotoDialog, BirthDialog } from "../../../components/dialog";
import { Abacus } from "@cosmos/harmony";

const styles = theme => ({
  cart: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "30em",
  },
  items: {
    flex: 1,
    overflowY: "scroll",
  },
  "item-view": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "0.5em",
    border: "1px solid darken(white, 3)",
    marginBottom: "4px",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column-reverse",
  },
  details: {
    display: "flex",
    flexFlow: "column",
  }
  
});

class CartComponent_ extends Component {

  selectItem = idx => {
    this.props.dispatch({ type: CART_SELECT_ITEM, payload: { selected: idx } });
  };

  removeItem = idx => {
    this.props.dispatch({ type: CART_REMOVE_ITEM_BEGIN, payload: idx });
  };

  updateIdvDiscRate = (value, idx) => {
    let prevDiscountRate = this.props.items[idx].discountRate && this.props.items[idx].discountRate.toString();
    if(prevDiscountRate !== value) {
      // original code - start
      this.props.dispatch({
        type: UPDATE_IDV_DISC_RATE,
        payload: { discountRate: value, idx: idx }
      });
      // original code - end
    }
  };

  updatePrice = (value, idx) => {
    this.props.dispatch({
      type: UPDATE_ITEM_PRICE,
      payload: { price: value, idx: idx }
    });
  };

  toggleDialog = () => {
    this.props.dispatch({ type: CLEAR_BIRTH });
    this.props.dispatch({ type: TOGGLE_BIRTH_DIALOG });
  };

  updateField = (value, idx) => {
    let gds = this.props.items.map(e => e);
    gds[idx] = { ...this.props.items[idx], ...value };
    this.props.dispatch({ type: UPDATE_POS_FIELD, payload: { goods: gds } });
  };

  handleSubmitFront = image => {
    this.props.dispatch({ type: SUBMIT_WARRANTY_FRONT_PHOTO, payload: image });
  };

  handleSubmitBack = image => {
    this.props.dispatch({ type: SUBMIT_WARRANTY_BACK_PHOTO, payload: image });
  };

  handleNext = () => {
    this.props.dispatch({ type: POS_WARRANTY_DIALOG, payload: { captureCardFront: true } });
    this.props.dispatch({ type: TOGGLE_BIRTH_DIALOG });
  };

  loadTempImage = (indicator) => {
    this.props.dispatch({ type: GET_TEMP_IMAGE, payload: { indicator }});
  }

  render() {
    const {
      items,
      prices,
      cartPrices,
      brands,
      products,
      total,
      currencies,
      selectedStore,
      stores,
      birthDialog,
      captureCardFront,
      captureCardBack,
      uploadProgress,
      monthOptions, selected, dataUri, notify,
      classes,
      currencyID,
    } = this.props;
    return (
      <div className={classNames(classes.cart)}>
        <Paper className={classNames(classes.content, classes.details)}>
          <List className={classes.items}>
            {items.map((item, idx) => (
              <ItemView
                key={`stock-${idx}`}
                idx={idx}
                selected={selected}
                trigger={this.removeItem}
                onSelect={() => this.selectItem(idx)}
                brand={brands[item.brandId]}
                product={products[item.productId]}
                price={prices[item.productId]}
                cartPrice={cartPrices[idx]}
                goods={items}
                stores={stores}
                updateIdvDiscRate={this.updateIdvDiscRate}
                updatePrice={this.updatePrice}
                selectedStore={selectedStore}
                currencies={currencies}
                dataUri={dataUri}
                loadTempImage={this.loadTempImage}
                currencyID={currencyID}
              />
            ))}
            {items.map((item, idx) => {
              return (
                <BirthDialog
                  key={idx}
                  keyName={`birth-${idx}`}
                  dialogOpen={!notify && birthDialog}
                  closeDialog={this.toggleDialog}
                  updateField={this.updateField}
                  goods={items}
                  selected={selected}
                  monthOptions={monthOptions}
                  handleNext={this.handleNext}
                />
              );
            })}
            <PhotoDialog
              dialogOpen={captureCardFront}
              title={" - Card Front"}
              uploadProgress={uploadProgress}
              handleSubmit={this.handleSubmitFront}
              ableToCancel={false}
            />

            <PhotoDialog
              dialogOpen={captureCardBack}
              title={" - Card Back"}
              uploadProgress={uploadProgress}
              handleSubmit={this.handleSubmitBack}
              ableToCancel={false}
            />
          </List>
          <List>
            {/* <ListItem className={classes.info}>
              <ListItemText className={classes.label} primary="Tax" />
              <TextField
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      <div>
                        { currencies[currencyID] && currencies[currencyID].symbol}
                      </div>
                    </InputAdornment>
                  )
                }}
                className={classes.value}
                value={tax}
              />
            </ListItem> */}
            <ListItem className={classes.info}>
              <ListItemText className={classes.label} primary="Total" />
              <TextField
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      <div>
                        { currencies[currencyID] && currencies[currencyID].symbol }
                      </div>
                    </InputAdornment>
                  )
                }}
                className={classes.value}
                value={total && Abacus.round(total, 2)}
              />
            </ListItem>
          </List>
        </Paper>
      </div>
    );
  }
}

export const CartComponent = connect(state => {
  
  let goods = state.pos.goods;
  let voidGoods = state.pos.voidGoods;

  if(goods.length > 0) {
    goods.map((el, idx) => {
      el.isVoidItem = false;
      if(voidGoods.length > 0) {
        voidGoods.forEach(x => {
          if(x.id === el.id) {
            el.isVoidItem = true;
          }
        });  
      }
      return el;
    })
  }

  return {
    items: goods,
    prices: state.price.prices,
    cartPrices: state.pos.prices,
    selected: state.pos.selected,
    brands: state.catalog.brands,
    products: state.catalog.products,
    currencies: state.system.currencies,
    selectedStore: state.system.stores && state.system.stores[state.system.rayLens],
    stores: state.system.stores,
    tax: state.pos.tax,
    total: state.pos.total,
    captureCardFront: state.pos.captureCardFront,
    captureCardBack: state.pos.captureCardBack,
    uploadProgress: state.pos.uploadProgress,
    birthDialog: state.pos.birthDialog,
    monthOptions: state.purchase.monthOptions,
    dataUri: state.pos.dataUri,
    notify: state.pos.notify,
    currencyID: state.pos.currency,
    voidGoods: state.pos.voidGoods
  }
})(withStyles(styles)(CartComponent_));
