import { createVibes } from "@cosmos/vibes";
import { OPEN_RECEIPT, DISPLAY_RECEIPT_ERROR } from "../../actions";

export const displayReceiptVibes = createVibes({
  type: [OPEN_RECEIPT],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { cartId } = action.payload;
      const { latestCarts } = getState().pos;

      const cart = latestCarts && latestCarts[cartId];

      if (cart && cart.storeId && cart.reference) {
        const data = await axios.request({
          url: `/rcp/${cart.storeId}/${cart.reference}`,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${token}`,
            rayLens,
          },
          responseType: "blob"
        });

        const response = data.request && data.request.response;
        const file = new Blob(
          [response],
          { type: 'application/pdf' }
        );
        const fileURL = URL.createObjectURL(file);

        let nwin = window.open(fileURL, '_blank');
        nwin.focus();
      } else {
        dispatch({ type: DISPLAY_RECEIPT_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to print receipt. ' } });
      }
      done();
    } catch (err) {
      dispatch({ type: DISPLAY_RECEIPT_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to print receipt. ' } });
      done();
    }
  }
});
