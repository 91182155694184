import React, {useEffect, useState} from "react";
import {
    AppBar, Button,
    Card, CardActions,
    CardContent,
    CardHeader, CircularProgress, Fab, FormControlLabel,
    InputBase,
    LinearProgress, List, ListItem, MenuItem,
    Paper, Select, Switch, TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import {alpha, makeStyles} from "@material-ui/core/styles";
import {DataTable} from "@cosmos/chaos/esm/DataTable";
import AutoSizer from "react-virtualized-auto-sizer";
import classnames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {
    INFOS_SEARCH,
    LOAD_INFOS,
    SELECT_INFOS_PRODUCT,
    UPDATE_GTIN_TYPE,
    UPDATE_GTIN_VALUE,
    SUBMIT_GTIN, INFOS_MODE_CHANGE, UPDATE_BARCODE, SUBMIT_BARCODE, INFOS_MODE_PRIORITY
} from "../../actions";
import {KeyboardReturn, Search, Check, Save, RefreshSharp} from "@material-ui/icons";
import {useKeySource} from "@cosmos/chaos";
import {buffer, filter, map} from "rxjs/operators";
import {deburr} from "lodash";
import {BarcodeScan} from "mdi-material-ui";
import {green} from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(0.5),
        display: "flex",
        flexDirection: "column"
    },
    grow: {
        flexGrow: 1
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
            minWidth: '32em'
        },
        '&.barcode': {
            minWidth: '24em',
            marginLeft: 0
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    searchRoot: {
        color: 'inherit',
        width: '100%',
        fontSize: '150%'
    },
    searchInput: {
        padding: theme.spacing(2, 2, 2, 9),
        transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('md')]: {
        //     width: '20ch',
        // },
    },
    toolButton: {
      marginRight: '1em'
    },
    container: {
        display: "flex"
    },
    major: {display: 'flex', flexGrow: 1},
    minor: {display: "flex", flexDirection: "column", width: "24rem"},
    highlighted: {
        '&:after': {
            display: "block",
            content: "\" \"",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'rgba(196,241,198,0.68)',
            zIndex: -1
        }
    },
    row: {
        fontSize: '120%',
        borderBottom: '1px solid #DDEEDD'
    },
    label: {
        width: '6em',
        padding: theme.spacing(2),
        backgroundColor: '#b3f8dd',
        '&.const': {
            backgroundColor: '#d5f5f1'
        }
    },
    value: {
        padding: theme.spacing(2)
    },
    inputSector: {
        backgroundColor: "#f6d9e5"
    },
    sectorOn: {
        backgroundColor: "#c9ffff"
    },
    submitWrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    submitButton: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700]
        }
    },
    submitProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
}))

const SearchBar = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const searchText = useSelector(state => state.catalog.search);
    const handleSearch = (e) => {
        e.preventDefault();
        dispatch({type: INFOS_SEARCH, payload: e.target.value})
    }
    return <div className={styles.search}>
        <div className={styles.searchIcon}>
            <Search/>
        </div>
        <InputBase
            onChange={e => handleSearch(e)}
            placeholder="Search... (Reference, SKU)"
            classes={{
                root: styles.searchRoot,
                input: styles.searchInput
            }}
            inputProps={{'aria-label': 'search'}}
            value={searchText}
        />
    </div>
}
const BarcodeBar = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const barcodeText = useSelector(state => state.catalog.barcodeText);
    const keySource = useKeySource();
    useEffect(() => {
        const trigger = keySource.pipe(filter(e => e.keyCode === 13));
        const sub = keySource.pipe(
            filter(e => e.keyCode > 31),
            map(e => e.key),
            buffer(trigger),
            map(b => deburr(b.join('').trim())),
            filter(c => c.search(/\(DI\)/) > -1),
            map(c => c.split(/\(DI\)/)[1])
        ).subscribe(code => {
            dispatch({type: SUBMIT_BARCODE, payload: code});
        })
        return () => {
            if (sub) sub.unsubscribe();
        }
    }, [keySource])
    const handleBarcode = (e) => {
        e.preventDefault();
        dispatch({type: UPDATE_BARCODE, payload: e.target.value})
    }
    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            dispatch({type: SUBMIT_BARCODE, payload: barcodeText})
        }
    }
    const handleClick = (e) => {
        e.preventDefault();
        dispatch({type: SUBMIT_BARCODE, payload: barcodeText})
    }
    return <>
        <div className={classnames(styles.search, 'barcode')}>
            <div className={styles.searchIcon}>
                <BarcodeScan/>
            </div>
            <InputBase
                onChange={e => handleBarcode(e)}
                placeholder="Stock Label or Barcode"
                classes={{
                    root: styles.searchRoot,
                    input: styles.searchInput
                }}
                inputProps={{'aria-label': 'search'}}
                onKeyDown={e => handleEnter(e)}
                value={barcodeText}
            />
        </div>
        <Button onClick={e => handleClick(e)} color="primary" variant="contained">
            <KeyboardReturn/>
        </Button>
    </>
}
export const GtinUnit = () => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const selectedInfo = useSelector(state => state.catalog.selectedInfo);
    const gtinType = useSelector(state => state.catalog.gtinType);
    const gtinValue = useSelector(state => state.catalog.gtinValue);
    const submitting = useSelector(state => state.catalog.submitting);
    const updateSuccess = useSelector(state => state.catalog.updateSuccess);
    return <CardActions className={classnames(styles.inputSector, {[styles.sectorOn]: gtinValue.match(/^\d{12,13}$/)})}>
        <Select value={gtinType} onChange={e => {
            dispatch({type: UPDATE_GTIN_TYPE, payload: e.target.value})
        }}>
            <MenuItem value={"ean"}>EAN</MenuItem>
            <MenuItem value={"upc"}>UPC</MenuItem>
            <MenuItem value={"jan"}>JAN</MenuItem>
        </Select>
        <TextField fullWidth placeholder="...Scan Barcode or Type here" value={gtinValue}
                   onChange={e => {
                       dispatch({type: UPDATE_GTIN_VALUE, payload: e.target.value})
                   }}
        />
        <div className={styles.submitWrapper}>
            <Fab color="primary"
                 onClick={e => dispatch({type: SUBMIT_GTIN})}
                 disabled={selectedInfo.id < 0 || gtinValue.length === 0}
                 className={classnames({[styles.submitButton]: updateSuccess})}>
                {updateSuccess ? <Check/> : <Save/>}
            </Fab>
            {submitting && <CircularProgress size={68} className={styles.submitProgress}/>}
        </div>
    </CardActions>
}

export const IANUnit = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.catalog.infosLoading);
    const infos = useSelector(state => state.catalog.infos);
    const idx = useSelector(state => state.catalog.infosIdx);
    const selected = useSelector(state => state.catalog.selectedInfo);
    const barcodeMode = useSelector(state => state.catalog.barcodeMode);
    const priorityMode = useSelector(state => state.catalog.priorityMode);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        dispatch({type: LOAD_INFOS});
    }, [dispatch]);
    useEffect(() => {
        const d = idx.map(id => {
            return infos[id]
        });
        setData(d);
    }, [setData, idx, infos]);
    useEffect(() => {
        const cols = [
            {name: "id", title: 'Product ID', width: 128, sortBy: 'id'},
            {name: "brand", title: "Brand", width: 128, sortBy: 'brand', filter: 'brand'},
            {name: "reference", title: 'Reference'},
            {name: "ean", title: "EAN", width: 160, sortBy: 'ean'},
            {name: "upc", title: "UPC", width: 160, sortBy: 'upc'},
            {name: "jan", title: "JAN", width: 160, sortBy: 'jan'}
        ];
        setColumns(cols);
    }, [data, setColumns])
    return <Paper className={styles.root} elevation={0} variant="outlined">
        <AppBar position="static">
            <Toolbar variant="dense">
                <Typography variant="h6">Product GTIN</Typography>
                <SearchBar/>
                <div className={styles.grow}/>
                <FormControlLabel
                    className={styles.toolButton}
                    control={
                        <Switch
                            checked={priorityMode}
                            onChange={e => {
                                dispatch({type: INFOS_MODE_PRIORITY})
                            }}
                            name="Barcode"
                            color="secondary"
                        />}
                    label="Priority"/>
                <FormControlLabel
                    className={styles.toolButton}
                    control={
                        <Switch
                            checked={barcodeMode}
                            onChange={e => {
                                dispatch({type: INFOS_MODE_CHANGE})
                            }}
                            name="Barcode"
                            color="secondary"
                        />}
                    label="Barcode"/>
                <Fab
                    size="small"
                    color='primary' onClick={()=>{dispatch({type:LOAD_INFOS})}}><RefreshSharp/></Fab>
            </Toolbar>
        </AppBar>
        {loading && <LinearProgress color="secondary"/>}
        <div className={classnames(styles.grow, styles.container)}>
            <div className={classnames(styles.major)}>
                <AutoSizer>
                    {({width, height}) => (
                        <DataTable
                            data={data}
                            width={width}
                            height={height}
                            columns={columns}
                            rowOnClick={({rowData}) => {
                                dispatch({type: SELECT_INFOS_PRODUCT, payload: rowData})
                            }}
                            isRowHighlight={({rowData}) => {
                                if (rowData && selected && rowData.id === selected.id) {
                                    return styles.highlighted
                                }
                            }}
                        />
                    )}
                </AutoSizer>
            </div>
            <div className={classnames(styles.minor)}>
                <AppBar position="static" color="secondary">
                    <Toolbar>
                        {barcodeMode && <BarcodeBar/>}
                    </Toolbar>
                </AppBar>
                <Card className={styles.grow}>
                    <CardHeader title={(selected.id > 0) ? `Product: ${selected.id}` : 'Please scan label'}/>
                    <CardContent className={styles.grow}>
                        <List>
                            <ListItem className={styles.row}>
                                <div className={classnames(styles.label, 'const')}>Brand</div>
                                <div className={styles.value}>{selected.brand}</div>
                            </ListItem>
                            <ListItem className={styles.row}>
                                <div className={classnames(styles.label, 'const')}>Reference</div>
                                <div className={styles.value}>{selected.reference}</div>
                            </ListItem>
                            <ListItem className={styles.row}>
                                <div className={styles.label}>EAN</div>
                                <div className={styles.value}>{selected.ean}</div>
                            </ListItem>
                            <ListItem className={styles.row}>
                                <div className={styles.label}>UPC</div>
                                <div className={styles.value}>{selected.upc}</div>
                            </ListItem>
                            <ListItem className={styles.row}>
                                <div className={styles.label}>JAN</div>
                                <div className={styles.value}>{selected.jan}</div>
                            </ListItem>
                        </List>
                    </CardContent>
                    <GtinUnit/>
                </Card>
            </div>
        </div>
    </Paper>
}