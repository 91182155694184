import {
    GTIN_ERROR,
    GTIN_SUCCESS, INFOS_DATA_ERROR,
    INFOS_DATA_UPDATE,
    INFOS_LOADED, INFOS_MODE_PRIORITY,
    INFOS_SEARCH,
    LOAD_INFOS,
    LOAD_INFOS_ERROR, SUBMIT_BARCODE,
    SUBMIT_GTIN,
    UPDATE_INFOS
} from "../actions";
import {keyBy} from "lodash";
import {createVibes} from "@cosmos/vibes";
import moment from "moment";
import {isAuthorized} from "@cosmos/chaos";

const loadInfoProducts = createVibes({
    type: LOAD_INFOS,
    latest: true,
    processOptions: {
        successType: INFOS_LOADED,
        failType: LOAD_INFOS_ERROR
    },
    async process({axios, getState}) {
        const {permission} = getState().auth;
        const result = await axios.request({
            url: '/aprs',
            method: 'post',
            data: {
                query: `query infoProducts($params: InfoProductFilter) {
  infos:infoProducts(filter: $params) {
    id
    brand
    reference
    onHand
    stockIn
    ean
    upc
    jan
  }
}`,
                variables: {params: {}}
            }
        });
        const isMerchant = isAuthorized(permission, ['cosmos.operation.merchant']);
        console.log("MERCH", isMerchant);
        const data = result.data.infos.filter(p => !isMerchant ? !!p.stockIn : true);
        return {
            infosIdx: data.map(p => p.id),
            infos: keyBy(data, 'id')
        }
    }
});

const searchAndFilterInfosProduct = createVibes({
    type: [INFOS_LOADED, INFOS_SEARCH, INFOS_MODE_PRIORITY],
    processOptions: {
        successType: UPDATE_INFOS
    },
    async process({getState}) {
        const {infos, search, priorityMode} = getState().catalog;
        if (Object.keys(infos).length === 0) return [];
        if (search && search.length === 0) return Object.keys(infos);
        const key = (search ? search.toLowerCase() : '').replace(/[\s.-/]/g, '');
        const result = Object
            .values(infos)
            .filter(p => {return (!priorityMode)? true : !p.ean && !p.upc && !p.jan})
            .filter(p => {
                // return (p.SKU ? p.SKU.toLowerCase() : '').replace(/[\s.-/]/g,'').startsWith(key) ||
                return (p.reference ? p.reference.toLowerCase() : '').replace(/[\s.-/]/g, '').startsWith(key);
            })
            .sort((a, b) => {
                return moment(b.stockIn) - moment(a.stockIn)
            })
            .map(p => p.id)
        return result;
    }
})

const saveGtin = createVibes({
    type: SUBMIT_GTIN,
    processOptions: {
        successType: GTIN_SUCCESS,
        failType: GTIN_ERROR,
    },
    async process({getState, axios}) {
        const {/*gtinType,*/ gtinValue, selectedInfo} = getState().catalog;
        const res = await axios.request({
            url: '/aprs',
            method: 'post',
            data: {
                query: `mutation updateGtin($productId: Int, $gtin: String) {
                    updateGTIN(productId: $productId, gtin: $gtin)
                }`,
                variables: {
                    productId: selectedInfo.id,
                    gtin: gtinValue
                }
            }
        });
        const {data, errors} = res;
        if (!errors) {
            const type = (gtinValue.length === 12) ? 'upc' : (gtinValue.startsWith(45) || gtinValue.startsWith(49)) ? 'jan' : 'ean';
            return {id: data.updateGTIN, [type]: gtinValue};
        } else {
            throw new Error(errors[0].message);
        }
    }
})

const submitBarcode = createVibes({
    type: SUBMIT_BARCODE,
    processOptions: {
        successType: INFOS_DATA_UPDATE,
        failType: INFOS_DATA_ERROR
    },
    async process({getState, action, axios}) {
        const {payload} = action;
        const isGtin = payload.match(/^\d{12,13}$/);
        if (isGtin) {
            return {gtinValue: payload};
        } else {
            const res = await axios.request({
                url: '/aprs',
                method: 'post',
                data: {
                    query: 'query infoProducts($params: String) {\n' +
                        '  resolveStockProduct(label: $params)\n' +
                        '}',
                    variables: {
                        params: payload.replace('STK', '').trim()
                    }
                }
            });
            const code = res.data;
            const idx = code.resolveStockProduct;
            if (idx > 0) {
                const {infos} = getState().catalog;
                const selectedInfo = infos[idx];
                if (selectedInfo) {
                    return {selectedInfo}
                } else {
                    throw new Error("Product not found")
                }
            } else {
                throw new Error("Label not found")
            }
        }
    }
})
export const infosProductVibes = [loadInfoProducts, searchAndFilterInfosProduct, saveGtin, submitBarcode];

