import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CANCEL_BOX_OPEN,
  CONFIRM_BOX_OPEN,
} from "../../../actions";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@material-ui/core";

class BoxOpenDialog_ extends Component {

  handleClose = () => {
    this.props.dispatch({ type: CANCEL_BOX_OPEN, payload: { targetDeliveryId: null, boxOpenDialog: false } });
  }

  handleConfirm = () => {
    this.props.dispatch({ type: CONFIRM_BOX_OPEN });

  }

  render() {
    const { dialogOpen, deliveries, targetDeliveryId, } = this.props;
    return (
      <Dialog maxWidth={"lg"} open={dialogOpen}>
        <DialogTitle>
          Are you sure to mark this delivery box as opened before?
        </DialogTitle>
        <DialogContent>
          <div>
            Delivery reference is <b> {deliveries[targetDeliveryId] && deliveries[targetDeliveryId].reference} </b>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" variant="outlined" size="small" >
            No
          </Button>
          <Button onClick={this.handleConfirm} color="primary" variant="contained" size="small">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const BoxOpenDialog = connect(state => ({
  dialogOpen: state.delivery.boxOpenDialog,
  deliveries: state.delivery.deliveries,
  targetDeliveryId: state.delivery.targetDeliveryId,
}))(BoxOpenDialog_);