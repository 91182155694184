import React, { useEffect, useState } from 'react'
import useHeaders from '../../../hooks/useHeaders'
import { makeStyles } from '@material-ui/core/styles'
import NoImagePlaceholder from '../../../components/NoImagePlaceholder'

const useStyles = makeStyles({
    root: {
        padding: '10px'
    }
})

export default function DisplayImage({ product, className }) {
    const [images, setImages] = useState([])
    const headers = useHeaders()
    const classes = useStyles()
    useEffect(() => {
        async function fetch() {
            if (!product || !product.id || !product.assets) return setImages([])
            const imageURLs = product.assets.filter(asset => !!asset).map((asset, index) => `/images/${asset.reference}`)
            setImages(imageURLs)
        }
        fetch()
    }, [product, headers])
    if (!product) return <p style={{ padding: '1em' }}>No product selected</p>
    if (!product.assets || product.assets.length === 0) return <p>No images</p>
    return <div className={`${classes.root} ${className}`}>
        {images[0] ? <img src={images[0]} alt={images[0]} style={{ maxWidth: '100%' }} /> : <NoImagePlaceholder width={'100%'} height={'300px'} />}
    </div>
}
