import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Paper,
    Card,
    CardContent,
    CardActions,
    Typography,
    TextField,
    List,
    ListItem
} from "@material-ui/core";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    SEARCH_PRODUCT_PRICE_TAG,
    UPDATE_APPLIED_PRICE_RULE,
    CLEAR_SEARCH_PRODUCT_PRICE_TAG
} from "../../actions";
import {Abacus} from '@cosmos/harmony';
import * as classNames from 'classnames';
import _ from "lodash";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        padding: "0.5em",
        display: "flex",
        flexDirection: "column",
    },
    cardAction: {
        justifyContent: "center",
        background: lighten(theme.palette.primary.light, 0.6)
    },
    titleLabel: {
        fontWeight: "bolder",
        fontSize: "larger",
        paddingRight: "5px"
    },
    spacing: {
        marginBottom: '1em'
    },
    dataRow: {
        justifyContent: 'space-between'
    },
    symbolDiv: {
        paddingRight: "0.5em"
    },
    rateUnit: {
        display: "flex",
        paddingLeft: '0.5em'
    },
    totalUnit: {
        borderTop: "2px solid #ababab"
    },
    listRow: {
        display: 'flex'
    },
    listView: {
        justifyContent: 'space-between'
    },
    listViewLeft: {
        display: "flex",
        flexDirection: "column"
    },
    listViewRight: {
        display: "flex",
    },
    values: {

    },
    searchBox: {
        width: "70%",
        padding: "5px",
        borderRadius: "5px",
        background: lighten(theme.palette.primary.light, 0.8)
    }
}));

export const PriceRuleView = () => {
    const styles = useStyles();
    const dispatch = useDispatch();

    const currencies = useSelector(state => state.system.currencies); // // TODO(PRICERULE) need to use this code
    // const currencies = {
    //     1: {
    //         "symbol": "USD"
    //     }
    // }
    const products = useSelector((state) => state.priceRule.products);
    const productInfo = useSelector((state) => state.priceRule.productInfo);
    const brands = useSelector((state) => state.priceRule.brands);
    const priceRules = useSelector((state) => state.priceRule.data);
    const appliedRules = useSelector((state) => state.priceRule.appliedRules);
    const totalMarkupValue = useSelector((state) => state.priceRule.totalMarkupValue);

    // useEffect(() => {
    //     dispatch({ type: LOAD_SUPPLIES });
    // }, []);

    useEffect(() => {
        const priceRulesArr = Object.values(priceRules);
        let rules = [];
        let total = 0;
        if (productInfo && productInfo.id) {
            ["OVERALL", "BRAND", "PRODUCT", "LEVEL", "EXTRA"].map(type => {
                if (["OVERALL", "EXTRA", "LEVEL"].includes(type)) {

                    const otherRule = priceRulesArr.filter(x => x.type === type);
                    if (otherRule.length > 0) {
                        const obj = otherRule[0];
                        const markupValue = calculateMarkupValue(obj);
                        obj.markupValue = markupValue;
                        total += markupValue;
                        rules.push(obj);
                    }
                } else {
                    if (type === "BRAND") {
                        const brandRule = priceRulesArr.filter(x => x.type === type && x.targetId === productInfo?.brandId);
                        if (brandRule.length > 0) {
                            const obj = brandRule[0];
                            const markupValue = calculateMarkupValue(obj);
                            obj.markupValue = markupValue;
                            total += markupValue;
                            rules.push(obj);
                        }
                    }

                    if (type === "PRODUCT") {
                        const productRule = priceRulesArr.filter(x => x.type === type && x.targetId === productInfo?.id);
                        if (productRule.length > 0) {
                            const obj = productRule[0];
                            const markupValue = calculateMarkupValue(obj);
                            obj.markupValue = markupValue;
                            total += markupValue;
                            rules.push(obj);
                        }
                    }
                }
            })
            dispatch({
                type: UPDATE_APPLIED_PRICE_RULE,
                payload: {rules: _.keyBy(rules, 'id'), totalMarkupValue: Abacus.round(total, 2)}
            });
        }
    }, [productInfo, priceRules]);

    const calculateMarkupValue = (obj) => {
        let markupValue = obj.markup;

        if (obj.mode === "RATIO") {
            if (markupValue) {
                markupValue = Abacus.round((obj.markup/100) * productInfo.price, 2);
            }
        }
        return markupValue;
    }

    const priceRule = () => {
        return Object.values(appliedRules).map(rule => {
            if (rule) {
                return (
                    <ListItem className={styles.listView}>
                        <div className={styles.listViewLeft}>
                            <div className={styles.listRow}>
                                <Typography className={classnames(styles.titleLabel)}>
                                    Rule:
                                </Typography>
                                <Typography>
                                    {rule.type}
                                </Typography>
                                { rule.mode === "RATIO" &&
                                    <div className={styles.rateUnit}>
                                    (
                                    <Typography>
                                        {rule.markup}
                                    </Typography>
                                    <Typography>
                                        %
                                    </Typography>
                                    )
                                    </div>
                                }
                                {/* <div className={styles.rateUnit}>
                                (
                                <Typography>
                                    {rule.mode === "RATIO" ? "" : (currencies && currencies[rule.currencyId] && currencies[rule.currencyId].symbol)}
                                </Typography>
                                <Typography>
                                    {rule.markup}
                                </Typography>
                                <Typography>
                                    {rule.mode === "RATIO" ? "%" : ""}
                                </Typography>
                                )
                                </div> */}
                            </div>
                        </div>
                        <div className={styles.listViewRight}>
                            <Typography className={styles.symbolDiv}>
                                {currencies && currencies[productInfo.currencyId] && currencies[productInfo.currencyId].symbol}
                            </Typography>
                            <Typography className={styles.values}>
                                {Abacus.round(rule.mode === 'RATIO' ? productInfo.price * (rule.markup / 100) : rule.markup, 2).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                        </div>
                    </ListItem>
                )
            }
        })
    }

    return (
        <Paper className={styles.root}>
            <Card className={styles.card}>
                <CardActions className={styles.cardAction}>
                    <Typography className={styles.titleLabel}>
                        Search:
                    </Typography>
                    <Autocomplete
                        freeSolo
                        className={styles.searchBox}
                        disabled={Object.values(products).length < 1}
                        options={Object.values(products)}
                        getOptionLabel={option => option.reference || ""}
                        // getOptionLabel={(option) =>
                        //     option ? `
                        //         ${option.reference ? option.reference + " - " : ""} 
                        //         ${brands && brands[option.brandId] && brands[option.brandId].name ? brands[option.brandId].name : ""}` : ""
                        // }
                        value={productInfo}
                        onChange={(event, value) => {
                            if (value) {
                                dispatch({type: SEARCH_PRODUCT_PRICE_TAG, payload: value});
                            } else {
                                dispatch({type: CLEAR_SEARCH_PRODUCT_PRICE_TAG});
                            }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Product Reference" fullWidth/>}
                    />
                </CardActions>
                <CardContent>
                    <List>
                        <ListItem className={classnames(styles.dataRow)}>
                            <Typography className={styles.titleLabel}>
                                Brand Name:
                            </Typography>
                            <Typography>
                                {brands[productInfo?.brandId]?.brand || "-"}
                            </Typography>
                        </ListItem>
                        <ListItem className={classnames(styles.dataRow, styles.spacing)}>
                            <Typography className={styles.titleLabel}>
                                Product Reference:
                            </Typography>
                            <Typography>
                                {productInfo.reference || "-"}
                            </Typography>
                        </ListItem>
                        <ListItem className={classnames(styles.dataRow)}>
                            <Typography className={styles.titleLabel}>
                                Product Cost:
                            </Typography>
                            <div className={styles.listRow}>
                                <Typography className={styles.symbolDiv}>
                                    {currencies && currencies[productInfo.currencyId] && currencies[productInfo.currencyId].symbol}
                                </Typography>
                                <Typography>
                                    {productInfo.price?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') || "-"}
                                </Typography>
                            </div>
                        </ListItem>
                        {priceRule()}
                        <ListItem className={classnames(styles.dataRow, styles.spacing, styles.totalUnit)}>
                            <Typography className={styles.titleLabel}>
                                Suggestion Price:
                            </Typography>
                            <div className={styles.listRow}>
                                <Typography className={styles.symbolDiv}>
                                    {currencies && currencies[productInfo.currencyId] && currencies[productInfo.currencyId].symbol}
                                </Typography>
                                <Typography>
                                    {Abacus.round((totalMarkupValue + productInfo.price), 2).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </Paper>
    );
};