import React from 'react';
import { Component } from 'react';
import styles from './info.module.scss';
import {
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { SmartBox } from '@cosmos/chaos';

export class InfoComponent extends Component {

    updateInputField = (value) => {
        this.props.updateField(value);
    }

    updateDiscountRate = (value) => {
        this.updateInputField(value);
        this.props.populateDiscountRate(value);
    }

    updateTaxRate = (store) => {
        const countries = this.props.countries;
        const taxRate = store && store.countryId && countries[store.countryId] ? countries[store.countryId].vat : 0;
        this.updateInputField({ taxRate });
    }

    render() {
        const { countries, purchaseTypes, currencies, purchaseInput, traders, readOnly, createVendor } = this.props;

        return (
            <div className={styles['info']}>
                <div className={styles['flex-column']}>
                    <div className={styles['flex-row']}>
                        <FormControl className={styles['field']}>
                            <InputLabel shrink> Vendor Country </InputLabel>
                            <Select
                                value={purchaseInput.vendorCountryId ? purchaseInput.vendorCountryId : ''}
                                onChange={(e) => { this.updateInputField({ vendorCountryId: e.target.value, vendor: null }) }}
                                name="vendorCountryId"
                                inputProps={{ readOnly: readOnly, disabled: readOnly }}
                            >
                                {Object.values(countries).map((el, index) => (
                                    <MenuItem value={el.id} key={`country_${index}`}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {
                            readOnly ?
                                <TextField
                                    id="vendorStore" label="Vendor Store"
                                    value={purchaseInput.vendor ? purchaseInput.vendorName : ''}
                                    className={styles['field']}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true, disabled: true }}
                                />
                                :
                                <div className={styles['field']}>
                                    <InputLabel shrink>Vendor Store</InputLabel>
                                    <SmartBox
                                        suggestionProvider={val => {
                                            let filteredTraders = Object.values(traders);
                                            if(purchaseInput.vendorCountryId){
                                                filteredTraders = filteredTraders.filter(el => {return String(el.countryId) === purchaseInput.vendorCountryId});
                                            }
                                            let count = 0;
                                            let result = filteredTraders.filter(el => {
                                                const keep = el.name.includes(val) && count < 10;
                                                if (keep) {
                                                    count += 1;
                                                }
                                                return keep;
                                            }).map(el => ({ label: el.name, value: el.id, ...el }));
                                            return result;
                                        }}
                                        searchText={purchaseInput.vendor && traders[purchaseInput.vendor] ? traders[purchaseInput.vendor].name : (purchaseInput.vendor ? purchaseInput.vendor : '')}
                                        updateSearchText={val => {
                                            if (typeof val === 'object') {
                                                this.updateInputField({ vendor: val.id, currency: val.currencyId });
                                                this.updateTaxRate(val)
                                            }
                                            else {
                                                this.updateInputField({ vendor: val, currency: '' });
                                            }
                                        }}
                                        updateSelectedItem={val => {
                                            this.updateInputField({ vendor: val.id, currency: val.currencyId });
                                            this.updateTaxRate(val)
                                        }}
                                        onOuterClick={createVendor}
                                    />
                                </div>
                        }
                    </div>
                    <div className={styles['flex-row']}>
                        <TextField
                            id="contact" label="Contact"
                            value={purchaseInput.contact ? purchaseInput.contact : ''}
                            className={styles['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.updateInputField({ contact: e.target.value }) }}
                            inputProps={{ readOnly: readOnly, disabled: readOnly }}
                        />
                        <TextField
                            id="date" label="Date"
                            value={purchaseInput.date ? purchaseInput.date : ''}
                            type="date"
                            className={styles['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.updateInputField({ date: e.target.value }) }}
                            inputProps={{ readOnly: readOnly, disabled: readOnly }}
                        />
                    </div>
                    <div className={styles['flex-row']}>
                        <TextField
                            id="invoice" label="Invoice"
                            value={purchaseInput.invoice ? purchaseInput.invoice : ''}
                            className={styles['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.updateInputField({ invoice: e.target.value }) }}
                            inputProps={{ readOnly: readOnly, disabled: readOnly }}
                        />
                        <TextField
                            id="tracking" label="Tracking"
                            value={purchaseInput.tracking ? purchaseInput.tracking : ''}
                            className={styles['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.updateInputField({ tracking: e.target.value }) }}
                            inputProps={{ readOnly: readOnly, disabled: readOnly }}
                        />
                    </div>
                    <div className={styles['flex-row']}>
                        <FormControl className={styles['field']}>
                            <InputLabel shrink> Type </InputLabel>
                            <Select
                                value={purchaseInput.type !== undefined ? purchaseInput.type : ''}
                                onChange={(e) => { this.updateInputField({ type: e.target.value }) }}
                                name="type"
                                inputProps={{ readOnly: readOnly, disabled: readOnly }}
                            >
                                {Object.values(purchaseTypes).map((el, index) => (
                                    <MenuItem value={el.name.toUpperCase()} key={`option_${index}`}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={styles['field']}>
                            <InputLabel shrink> Currency </InputLabel>
                            <Select
                                value={purchaseInput.currency ? purchaseInput.currency : ''}
                                onChange={(e) => {
                                    this.updateInputField({ currency: e.target.value })
                                }}
                                name="currency"
                                inputProps={{ readOnly: readOnly, disabled: readOnly }}
                            >
                                {Object.values(currencies).map((el, index) => (
                                    <MenuItem value={el.id} key={`option_${index}`}>
                                        {el.symbol}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={styles['flex-row']}>
                        <TextField
                            id="taxRate" label="Tax Rate"
                            value={purchaseInput.taxRate}
                            className={styles['field']}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end"> % </InputAdornment>
                                )
                            }}
                            type="number"
                            onChange={(e) => { this.updateInputField({ taxRate: parseFloat(e.target.value) }) }}
                            inputProps={{ readOnly: readOnly, disabled: readOnly }}
                        />
                        <TextField
                            id="discountRate" label="Discount Rate"
                            value={purchaseInput.discountRate !== undefined ? purchaseInput.discountRate : ''}
                            className={styles['field']}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end"> % </InputAdornment>
                                )
                            }}
                            type="number"
                            onChange={(e) => { this.updateDiscountRate({ discountRate: e.target.value }) }}
                            inputProps={{ readOnly: readOnly, disabled: readOnly }}
                        />
                    </div>
                    <div className={styles['flex-row']}>
                        <TextField
                            id="remarks" label="Remarks"
                            value={purchaseInput.remarks ? purchaseInput.remarks : ''}
                            className={styles['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.updateInputField({ remarks: e.target.value }) }}
                            inputProps={{ readOnly: readOnly, disabled: readOnly }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
