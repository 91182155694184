import React, {useEffect} from "react";
import { connect, useDispatch } from "react-redux";
import { UPDATE_TAX_MODE_BEGIN } from "../../../actions";
import clsx from 'clsx';
import {
  InputLabel,
  Paper,
  ListItem,
  TextField,
  InputAdornment, 
  withStyles,
  MenuItem,
  List,
  Select
} from "@material-ui/core";

const styles = theme => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "1em",
    marginBottom: "1em"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  labelColumn: {
    display: "flex",
    flexDirection: "column"
  }
});

const TaxComponent_ = props => {
  const {
    taxRate,
    taxValue,
    currencies,
    currencyID,
    classes
  } = props

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: UPDATE_TAX_MODE_BEGIN, payload: { select: 'NORMAL' } });
  },[dispatch]);


  const handleChange = e =>  { 
    dispatch({ type: UPDATE_TAX_MODE_BEGIN, payload: { select: e.target.value } });
  }

  return (
    <Paper>
        <List className={classes.flexContainer}>
          <ListItem>
            <TextField
              disabled
              label="Tax Rate"
              value = {taxRate}
              className={clsx(classes.margin, classes.textField)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                )
              }}
            />
          </ListItem>

          <ListItem>
            <div className={classes.labelColumn}>
              <InputLabel shrink>Tax Type</InputLabel>
              <Select defaultValue = 'NORMAL' onChange={handleChange}>
                <MenuItem value='NA'>N/A</MenuItem>
                <MenuItem value='NORMAL'>Normal</MenuItem>
                <MenuItem value='RECLAIM2'>Reclaim 2</MenuItem>
              </Select>
            </div>
          </ListItem>

          <ListItem>
            <TextField
              disabled
              label="Tax Value"
              value = {taxValue}
              className={clsx(classes.margin, classes.textField)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    { currencies[currencyID] ? currencies[currencyID].symbol : '' }
                  </InputAdornment>
                )
              }}
            />
          </ListItem>
        </List>
      </Paper>
  )
}

const mapStateToProps = state => {
  const { pos, system } = state
  const { taxRate, tax } = pos
  const { currencies, currency } = system
  
  return {
    taxRate,
    taxValue: tax,
    currencyID: currency,
    currencies
  }
}

export const TaxComponent = connect(mapStateToProps)(withStyles(styles)(TaxComponent_));
