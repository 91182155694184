import React from 'react';

export const AdminComponent = class Admin extends React.Component {
    render() {
        return (
            <div>
                Admin Module
            </div>
        )
    }
};