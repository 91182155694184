import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';

import {
  IconButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles
} from '@material-ui/core';
import { ChevronRightCircle, Plus } from "mdi-material-ui";
import {
  UPDATE_TENDER_INPUT, UPDATE_TD_INPUT, CREATE_TENDER_DETAIL,
  UPDATE_FF_INPUT, CREATE_FULFILMENT, TRIGGER_DELETE_FULFILMENT,
  UPDATE_DELETE_TENDER_DETAIL, SELECT_TENDER_DETAIL, SAVE_UPDATED_TENDER,
  ADD_SELECTED_SUGGESTIONS, REMOVE_SELECTED_SUGGESTIONS, UPDATE_FULFILMENT,
} from '../../../actions';
import { SmartBox, Authorization, authorized, } from '@cosmos/chaos';
import { TenderDetailComponent } from '../components';


const styles = theme => ({
  "drawer-root": {
    flex: 1,
    display: "flex",
    overflowY: "scroll"
  },
  "drawer-content": {
    padding: "0.75rem",
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  "btn-row": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  field: {
    flex: 1
  },
  container: {
    flex: 1
  },
  "flex-row": {
    display: "flex",
    flexDirection: "row",
    marginBottom: "0.25rem"
  },
  "fulfilments": {
    display: "none",
    "&.expanded": {
      display: "block"
    }
  },
  "add-btn": {
    margin:" 0.5rem 0"
  },
  "detail": {
    padding: "0.5rem",
    border: "solid 1px lightgrey",
    borderRadius: "0.5rem",
    marginBottom: "1rem",
    "&.selected": {
      backgroundColor: "darken(white, 5)"
    }
  } 
})

class DrawerComponent_ extends Component {
  updateField = (value) => {
    this.props.dispatch({ type: UPDATE_TENDER_INPUT, payload: value });
  }

  updateTDField = (value) => {
    this.props.dispatch({ type: UPDATE_TD_INPUT, payload: value });
  }

  updateFFField = (value) => {
    this.props.dispatch({ type: UPDATE_FF_INPUT, payload: value });
  }

  addSelectedSuggestions = (id) => {
    this.props.dispatch({ type: ADD_SELECTED_SUGGESTIONS, payload: id });
  }

  removeSelectedSuggestions = (id) => {
    this.props.dispatch({ type: REMOVE_SELECTED_SUGGESTIONS, payload: id });
  }

  createDetail = () => {
    this.props.dispatch({ type: CREATE_TENDER_DETAIL });
  }

  selectDetail = (idx) => {
    this.props.dispatch({ type: SELECT_TENDER_DETAIL, payload: idx });
  }

  handleDeleteRow = (obj) => {
    this.props.dispatch({ type: UPDATE_DELETE_TENDER_DETAIL, payload: obj });
  }

  toggleFulfilments = (idx, currentStatus) => {
    this.props.dispatch({ type: UPDATE_TD_INPUT, payload: { index: idx, payload: { expanded: !currentStatus } } });
  }

  saveTender = () => {
    this.props.dispatch({ type: SAVE_UPDATED_TENDER });
  }

  addFulfilment = idx => {
    this.props.dispatch({ type: CREATE_FULFILMENT, payload: { tenderDetailIdx: idx } });
  }

  deleteFulfilment = obj => {
    this.props.dispatch({ type: TRIGGER_DELETE_FULFILMENT, payload: obj });
  }

  updateFulfilment = (obj) => {
    this.props.dispatch({ type: UPDATE_FULFILMENT, payload: obj });

  }

  canUpdateTender = authorized(['cosmos.tender.update']);
  canUpdateFulfilment = authorized(['cosmos.fulfilment.update']);


  render() {
    const {
      closeDrawer, tenderInput, currencies, selectedTdIdx, disableTender,
      traders, countries, paymentChannels, permission, stores,
      users, products, currencySymbol, fulfilmentSuggestions,
      classes, height, width
    } = this.props;

    const canUpdateTender = this.canUpdateTender(permission);
    const canUpdateFulfilment = this.canUpdateFulfilment(permission);

    return (
      <div className={classes['drawer-root']} style={{width, height}}>

        <div className={classes['drawer-content']}>
          <div className={classes['btn-row']}>
            <IconButton onClick={closeDrawer}>
              < ChevronRightCircle color='primary' />
            </IconButton>
            <Authorization allowed={['cosmos.tender.update']}>
              {({ permit }) => (permit(permission) &&
                <div>
                  <Button color="primary" variant="contained" onClick={this.saveTender} disabled={disableTender}>
                    Save
                  </Button>
                </div>
              )}
            </Authorization>
          </div>

          {
            tenderInput && (
              <div key={`review-${tenderInput.id}`} className={classes['container']}>
                <div>

                  <div className={classes['flex-row']}>
                    {
                      canUpdateTender ?
                        (
                          <div className={classes['field']}>
                            <InputLabel shrink required>Channel</InputLabel>
                            <SmartBox
                              suggestionProvider={val => {
                                let count = 0;
                                let result = Object.values(traders).filter(el => {
                                  const keep = el.name.includes(val) && count < 10;
                                  if (keep) {
                                    count += 1;
                                  }
                                  return keep;
                                }).map(el => ({ label: el.name, value: el.id, ...el }));
                                return result;
                              }}
                              searchText={tenderInput.channel && traders[tenderInput.channel] ? traders[tenderInput.channel].name : (tenderInput.channel ? tenderInput.channel : '')}
                              updateSearchText={val => {
                                if (typeof val === 'object') {
                                  this.updateField({ channel: val.id, currency: val.currencyId, deliveryCountry: val.countryId, originalCountry: val.countryId, seller: val.handlerId });
                                }
                                else {
                                  this.updateField({ channel: val, currency: '', deliveryCountry: '', originalCountry: '', seller: null });
                                }
                              }}
                              updateSelectedItem={val => {
                                this.updateField({ channel: val.id, currency: val.currencyId, deliveryCountry: val.countryId, originalCountry: val.countryId, seller: val.handlerId });
                              }}
                            />
                          </div>
                        ) :
                        (
                          <TextField
                            id="channel" label="Channel"
                            value={tenderInput.channel && traders[tenderInput.channel] ? traders[tenderInput.channel].name : ''}
                            className={classes['field']}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ readOnly: true, disabled: true }}
                          />
                        )
                    }
                    <TextField
                      id="channelReference" label="Channel Reference"
                      value={tenderInput.channelReference ? tenderInput.channelReference : ''}
                      className={classes['field']}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
                      onChange={(e) => { this.updateField({ channelReference: e.target.value }) }}
                    />
                  </div>

                  <div className={classes['flex-row']}>
                    <TextField
                      id="seller" label="Seller Store" required
                      value={tenderInput.seller && stores[tenderInput.seller] ? stores[tenderInput.seller].name : ''}
                      className={classes['field']}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: true, disabled: true }}
                    />
                    <FormControl className={classes['field']}>
                      <InputLabel shrink required> Currency </InputLabel>
                      <Select
                        value={tenderInput.currency ? tenderInput.currency : ''}
                        onChange={(e) => { this.updateField({ currency: e.target.value }) }}
                        name="currency" readOnly={!canUpdateTender}
                      >
                        {Object.values(currencies).map((el, index) => (
                          <MenuItem value={el.id} key={`option_${index}`}>
                            {el.symbol}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className={classes['flex-row']}>
                    <TextField
                      id="customer" label="Customer" required
                      value={tenderInput.customer ? tenderInput.customer.name : ''}
                      className={classes['field']}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
                      onChange={(e) => { this.updateField({ customer: { name: e.target.value } }) }}
                    />
                    <FormControl className={classes['field']}>
                      <InputLabel shrink>Delivery Country </InputLabel>
                      <Select
                        value={tenderInput.deliveryCountry ? tenderInput.deliveryCountry : ''}
                        onChange={(e) => { this.updateField({ deliveryCountry: e.target.value }) }}
                        name="deliveryCountry" readOnly={!canUpdateTender}
                      >
                        {Object.values(countries).map((el, index) => (
                          <MenuItem value={el.id} key={`user_${index}`}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className={classes['flex-row']}>
                    <FormControl className={classes['field']}>
                      <InputLabel shrink required>Paid By </InputLabel>
                      <Select
                        value={tenderInput.paidBy ? tenderInput.paidBy : ''}
                        onChange={(e) => { this.updateField({ paidBy: e.target.value }) }}
                        name="paidBy" readOnly={!canUpdateTender}
                      >
                        {Object.values(paymentChannels).map((el, index) => (
                          <MenuItem value={el.id} key={`user_${index}`}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      id="filedAt" label="Filed At"
                      value={tenderInput.filedAt ? tenderInput.filedAt : ''}
                      type="date"
                      className={classes['field']}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
                      onChange={(e) => { this.updateField({ filedAt: e.target.value }) }}
                    />
                  </div>

                  <div className={classes['flex-row']}>
                    <TextField
                      id="confirmedAt" label="Confirmed At"
                      value={tenderInput.confirmedAt ? tenderInput.confirmedAt : ''}
                      type="date"
                      className={classes['field']}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
                      onChange={(e) => { this.updateField({ confirmedAt: e.target.value }) }}
                    />
                    <TextField
                      id="expireAt" label="Expire At"
                      value={tenderInput.expireAt ? tenderInput.expireAt : ''}
                      type="date"
                      className={classes['field']}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
                      onChange={(e) => { this.updateField({ expireAt: e.target.value }) }}
                      required
                    />
                  </div>

                  <div className={classes['flex-row']}>
                    <FormControl className={classes['field']}>
                      <InputLabel shrink required> Person In Charge </InputLabel>
                      <Select
                        value={tenderInput.pic ? tenderInput.pic : ''}
                        onChange={(e) => { this.updateField({ pic: e.target.value }) }}
                        name="pic" readOnly={!canUpdateTender}
                      >
                        {Object.values(users).map((el, index) => (
                          <MenuItem value={el.id} key={`user_${index}`}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      id="remarks" label="Remarks"
                      value={tenderInput.remarks ? tenderInput.remarks.comments : ''}
                      className={classes['field']}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: !canUpdateTender, disabled: !canUpdateTender }}
                      onChange={(e) => { this.updateField({ remarks: { comments: e.target.value } }) }}
                    />
                  </div>
                </div>

                <div className={classes['btn-row']}>
                  <Button color="primary" variant="contained" className={classes['add-btn']} onClick={this.createDetail} disabled={!canUpdateTender}>
                    <Plus /> Item
                  </Button>
                </div>

                <div>
                  {
                    tenderInput.details && tenderInput.details.map((td, idx) =>
                      (
                        <TenderDetailComponent
                          key={`td-${idx}`}
                          selectedTdIdx={selectedTdIdx}
                          products={products}
                          users={users}
                          currencySymbol={currencySymbol}
                          idx={idx}
                          td={td}
                          updateTDField={this.updateTDField}
                          updateFFField={this.updateFFField}
                          selectDetail={this.selectDetail}
                          handleDeleteRow={this.handleDeleteRow}
                          toggleFulfilments={this.toggleFulfilments}
                          addFulfilment={this.addFulfilment}
                          deleteFulfilment={this.deleteFulfilment}
                          // suggestionOptions={td.productId && fulfilmentSuggestions[td.productId] ? fulfilmentSuggestions[td.productId] : []}
                          suggestionOptions={fulfilmentSuggestions ? fulfilmentSuggestions : []}
                          // selectedSuggestions={selectedSuggestions}
                          addSelectedSuggestions={this.addSelectedSuggestions}
                          removeSelectedSuggestions={this.removeSelectedSuggestions}
                          updateFulfilment={this.updateFulfilment}
                          canUpdateTender={canUpdateTender}
                          canUpdateFulfilment={canUpdateFulfilment}
                        />
                      )
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}


export const DrawerComponent = connect(state => {
  let currencies = state.system.currencies;
  let tenderInput = state.tender.tenderInput;
  let currencySymbol = currencies[tenderInput.currency] ? currencies[tenderInput.currency].symbol : ""
  return {
    tenderInput: tenderInput,
    traders: state.trader.traders,
    currencies: currencies,
    countries: state.system.countries,
    paymentChannels: state.system.paymentChannels,
    users: state.system.users,
    products: state.catalog.products,
    currencySymbol: currencySymbol,
    selectedTdIdx: state.tender.selectedTdIdx,
    fulfilmentSuggestions: state.tender.fulfilmentSuggestions,
    // selectedSuggestions: state.tender.selectedSuggestions,
    disableTender: state.tender.disableTender,
    permission: state.auth.permission,
    stores: state.system.stores,
  }
})(withStyles(styles)(DrawerComponent_)); 