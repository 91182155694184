import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    secondaryText: {
        color: theme.palette.text.secondary
    }
}))

export default function StockItem({model, label, serial, birthDay, birthMonth, birthYear, condition, conditionDetails, className}) {
    const classes = useStyles()
    return (
        <div className={className}>
            <h2>{model.brand.name} - {model.product.SKU} <br /> <small className={classes.secondaryText}>{model.product.name}</small></h2>
            <p style={{margin: 0, padding: 0}}>Serial: {serial}</p>
            <p style={{margin: 0, padding: 0}}>Label: <strong>{label}</strong></p>
        </div>
    )
}
