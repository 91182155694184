import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Paper,
    Card,
    CardContent,
    CardActions,
    Button,
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    List,
    ListItem,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    alpha,
    InputAdornment,
    Tooltip
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {lighten, makeStyles} from "@material-ui/core/styles";
import {Done, Close} from "@material-ui/icons";
import { 
    LOAD_PRICE_RULES_BEGIN,
    EDIT_PRICE_RULE_VALUE,
    CLEAR_PRICE_RULE_VALUE,
    SUBMIT_PRICE_RULE_BEGIN,
    UPDATE_PRICE_RULE_BOX_OPEN_STATUS
} from "../../actions";
import moment from "moment";
import * as classNames from 'classnames';
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    cardAction: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0.5em 10em 0.5em 0.5em"
    },
    actionTotalNo: {
        display: "flex",
        alignItems: "center"
    },
    totalNoLabel: {
        fontWeight: "bolder",
        fontSize: '120%',
    },
    totalNoText: {
        fontWeight: "bolder",
        paddingLeft: "0.5em"
    },
    priceRuleBox: {
        backgroundColor: alpha(theme.palette.warning.main, 0.6),
        display: "flex",
        flexDirection: "row",
        padding: "0px"
    },
    modeTxt: {
        paddingRight: "2px"
    },
    tableHead: {
        backgroundColor: theme.palette.primary.light
    },
    tableRow:{
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&.Mui-selected": {
            backgroundColor: lighten(theme.palette.primary.light, 0.5),
        },
        "&:hover": {
            backgroundColor: "rgb(48, 55, 49, 0.15) !important",
        },
    },
    tableRowMatch: {
        backgroundColor: `${lighten(theme.palette.secondary.light, 0.1)} !important`
    },
    targetBox: {
        width: "290%",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    typeCell: {
        width: "13%"
    },
    actionCell: {
        display: "flex",
        justifyContent: "space-around",
        paddingLeft: "0px",
        paddingRight: "3px"
    },
    targetCell: {
        width: "25%",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    markupCell: {
        width: "12%",
    },
    validFromCell: {
        width: "15%"
    },
    noSidePadding: {
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    modeButton: {
        textTransform: "none",
    },
    ratioMode: {
        backgroundColor: theme.palette.primary.light
    },
    actualMode: {
        backgroundColor: theme.palette.secondary.light
    },
    doneBtn: {
        color: "green",
        fontSize: "27px",
        '&:hover': {
            color: "black"
        }
    },
    closeBtn: {
        color: "red",
        fontSize: "27px",
        '&:hover': {
            color: "black"
        }
    }
}));

export const PriceRuleTable = () => {
    const styles = useStyles();
    const dispatch = useDispatch();

    const currencies = useSelector(state => state.system.currencies); // // TODO(PRICERULE) need to use this code
    // const currencies = {
    //     1: {
    //         "symbol": "USD"
    //     }
    // }
    const priceRules = useSelector((state) => state.priceRule.data);
    const products = useSelector((state) => state.priceRule.products);
    const brands = useSelector((state) => state.priceRule.brands);
    const priceRuleDetail = useSelector((state) => state.priceRule.priceRuleDetail);
    const index = useSelector(state => state.priceRule.index);
    const openInsertBox = useSelector(state => state.priceRule.openInsertBox);
    const appliedRules = useSelector(state => state.priceRule.appliedRules);

    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch({ type: LOAD_PRICE_RULES_BEGIN });
    }, []);

    useEffect(() => {
        let priceRuleArr = index.map(idx => priceRules[idx]);
        setData(priceRuleArr);
    }, [priceRules, index]);

    const markupComponent = (rowData) => {
        const obj = (rowData.id === priceRuleDetail.id) ? priceRuleDetail : rowData;
        let modeSymbol = null;
        if(["OVERALL", "LEVEL", "EXTRA", "BRAND"].includes(obj.type) || obj.mode === "RATIO") {
            modeSymbol = "%";
        }else {
            if(currencies && currencies[obj.currencyId] && currencies[obj.currencyId].symbol) {
                modeSymbol = currencies[obj.currencyId].symbol;
            }else {
                modeSymbol = "";
            }
        }

        return (
            <TextField
                fullWidth
                id="markup"
                label={obj.id ? "" : "Markup"}
                onChange={e => {
                    dispatch({type: EDIT_PRICE_RULE_VALUE, payload: {markup: parseFloat(e.target.value)}})
                }}
                value={obj.markup}
                type={"number"}
                InputProps={{
                    inputProps: { min: 0, step: 1 },
                    endAdornment: (
                        <InputAdornment position="end">
                            <div>{modeSymbol}</div>
                        </InputAdornment>
                    )
                }}
            />
        )
    }

    const targetComponent = (rowData) => {
        const obj = (rowData.id === priceRuleDetail.id) ? priceRuleDetail : rowData;
        let target = [];
        let targetValue = {};
        if(obj.type === "PRODUCT") {
            target = Object.values(products);
            targetValue = products[obj.targetId];
        } else if(obj.type === "BRAND") {
            target = Object.values(brands);
            targetValue = brands[obj.targetId];
        }

        let label = "Target";
        if(obj.id && ["BRAND", "PRODUCT"].includes(obj.type)) {
            label = ""
        }

        return (
            <Autocomplete
                fullWidth
                disabled={!["BRAND", "PRODUCT"].includes(obj.type) || target.length === 0}
                options={target}
                getOptionLabel={(option) => {
                    let opt = option.brand;

                    if(obj.type === "PRODUCT") {
                        opt = option.name;
                    }

                    return opt;
                }}
                value={targetValue}
                onChange={(event, value) => {
                    if(value) {
                        let targetId = value.brandId;
                        if(obj.type === "PRODUCT") {
                            targetId = value.id;
                        }
                        dispatch({ type: EDIT_PRICE_RULE_VALUE, payload: {targetId: parseInt(targetId)}});
                    }
                }}
                renderInput={(params) => <TextField {...params} label={label} fullWidth/>}
            />
        )
    }

    const validFromComponent = (rowData) => {
        const date = (rowData.id === priceRuleDetail.id) ? priceRuleDetail.validFrom : rowData.validFrom;
        return (
            <TextField
                label={rowData.id ? "" : "ValidFrom"}
                type="date"
                value={moment(date).format("YYYY-MM-DD")}
                disabled={true} // due to backend only accept current date
                onChange={(event)=>{
                    if(event){
                        dispatch({ type: EDIT_PRICE_RULE_VALUE, payload: {validFrom: event.target.value}});
                    }
                }}
            />
        )
    };

    return (
        <Card className={styles.card}>
            <CardActions className={styles.cardAction}>
                <Button
                    color="primary" 
                    variant="contained"
                    onClick={(e) => {
                        dispatch({type: UPDATE_PRICE_RULE_BOX_OPEN_STATUS, payload: true});
                        dispatch({type: CLEAR_PRICE_RULE_VALUE});
                    }}
                >
                    New
                </Button>
                <Box className={styles.actionTotalNo}>
                    <Typography className={styles.totalNoLabel}>Total Number of Rules: </Typography>
                    <Typography className={styles.totalNoText}>{data.length}</Typography>
                </Box>
            </CardActions>
            <CardContent>
                {/* ------ Insert Box Start ------ */}
                {openInsertBox && 
                <List className={styles.priceRuleBox}>
                    <ListItem>
                        <TextField
                            value={priceRuleDetail.type}
                            onChange={(e) => {
                                if (e.target.value) {
                                    dispatch({ type: EDIT_PRICE_RULE_VALUE, payload: {type: e.target.value}});
                                }

                                if(priceRuleDetail.targetId && ["OVERALL", "LEVEL", "EXTRA"].includes(e.target.value)) {
                                    dispatch({ type: EDIT_PRICE_RULE_VALUE, payload: {targetId: 0}});
                                }

                                if(priceRuleDetail.mode === "ACTUAL" && ["OVERALL", "LEVEL", "EXTRA", "BRAND"].includes(e.target.value)) {
                                    dispatch({ type: EDIT_PRICE_RULE_VALUE, payload: {mode: "RATIO"}});
                                }
                            }}
                            select
                            label="Type"
                            fullWidth
                        >
                            <MenuItem value={"OVERALL"}>OVERALL</MenuItem>
                            <MenuItem value={"BRAND"}>BRAND</MenuItem>
                            <MenuItem value={"PRODUCT"}>PRODUCT</MenuItem>
                            <MenuItem value={"LEVEL"}>LEVEL</MenuItem>
                            <MenuItem value={"EXTRA"}>EXTRA</MenuItem>
                        </TextField>
                    </ListItem>
                    <ListItem className={styles.targetBox}>
                        {targetComponent(priceRuleDetail)}
                    </ListItem>
                    <ListItem>
                        {markupComponent(priceRuleDetail)}
                    </ListItem>
                    <ListItem>
                        {/* <Typography className={styles.modeTxt}>Mode</Typography> */}
                        <Button 
                            onClick={(e) => {
                                dispatch({type: EDIT_PRICE_RULE_VALUE, payload: {mode: priceRuleDetail.mode === "RATIO" ? "ACTUAL" : "RATIO"}})
                            }}
                            disabled={!["PRODUCT"].includes(priceRuleDetail.type)}
                            color={priceRuleDetail.mode === "RATIO" ? "primary": "secondary"}
                            variant="contained" 
                            size="small">
                            {priceRuleDetail.mode}
                        </Button>
                    </ListItem>
                    <ListItem>
                        {validFromComponent(priceRuleDetail)}
                    </ListItem>
                    <ListItem>
                        <TextField
                            label="ValidTo"
                            disabled={true}
                            // defaultValue={""}
                            value={"NEVER"}
                            InputLabelProps={{shrink: true}}
                        />
                    </ListItem>
                    <ListItem className={styles.actionCell}>
                        <Tooltip title="Save">
                            <Done 
                                className={styles.doneBtn}
                                onClick={(e) => {
                                    dispatch({type: SUBMIT_PRICE_RULE_BEGIN});
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Cancel">
                            <Close 
                                className={styles.closeBtn}
                                onClick={(e) => {
                                    dispatch({type: CLEAR_PRICE_RULE_VALUE});
                                    dispatch({type: UPDATE_PRICE_RULE_BOX_OPEN_STATUS, payload: false});
                                }}
                            />
                        </Tooltip>
                    </ListItem>
                </List>
                }
                {/* ------ Insert Box End ------ */}
                <Table>
                    <TableHead className={styles.tableHead}>
                        <TableCell className={styles.typeCell}>Type</TableCell>
                        <TableCell className={styles.targetCell}>Target</TableCell>
                        <TableCell className={styles.markupCell}>Markup Value</TableCell>
                        <TableCell>Markup<br/>Method</TableCell>
                        <TableCell className={styles.validFromCell}>Start</TableCell>
                        <TableCell>Expire</TableCell>
                        <TableCell> &nbsp; </TableCell>
                    </TableHead >
                    <TableBody className={styles.tableBody}>
                        {data.map((row) => (
                            <TableRow
                                className={classNames(styles.tableRow, (appliedRules.hasOwnProperty(row.id) ? styles.tableRowMatch : "") )}
                                key={row.id}
                                onClick={() => {
                                    if(priceRuleDetail.id !== row.id) {
                                        dispatch({ type: EDIT_PRICE_RULE_VALUE, payload: row});
                                    }
                                    if(openInsertBox) {
                                        dispatch({type: UPDATE_PRICE_RULE_BOX_OPEN_STATUS, payload: false});
                                    }
                                }}
                                selected={priceRuleDetail.id === row.id}
                            >
                                <TableCell component="th" scope="row">{row.type}</TableCell>
                                <TableCell className={styles.targetCell}>{targetComponent(row)}</TableCell>
                                <TableCell className={styles.markupCell}>{markupComponent(row)}</TableCell>
                                <TableCell>
                                    <Button
                                        className={classnames(styles.modeButton, row.mode === "RATIO" ? styles.ratioMode: styles.actualMode)}
                                        variant="contained" 
                                        size="small">
                                        {row.mode === 'RATIO'? "By Percentage" : "Add Amount"}
                                    </Button>
                                </TableCell>
                                <TableCell>{validFromComponent(row)}</TableCell>
                                <TableCell>{moment(row.validTo).isSame("2100-01-01","day") ? "NEVER" : moment(row.validTo).format("YYYY-MM-DD")}</TableCell>
                                <TableCell>
                                    <Tooltip title="Save">
                                        <Done 
                                            className={styles.doneBtn}
                                            onClick={(e) => {
                                                dispatch({type: SUBMIT_PRICE_RULE_BEGIN});
                                            }}
                                        />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};