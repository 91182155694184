import { createVibes } from "@cosmos/vibes";
import {
    TO_FILTER_INV, FILTER_INV_SUCCESS, filterSuccess, filterFail,
    TO_FILTER_PURCHASE, UPDATE_FILTERED_PURCHASES,
    TO_FILTER_TENDER, UPDATE_FILTERED_TENDERS, 
    TO_FILTER_RESERVATION,UPDATE_FILTERED_RESERVATION,
} from "../../actions";
import { FilterUnit } from "./filterUnit";

export const filterHandlerVibes = createVibes({
    type: [TO_FILTER_INV, TO_FILTER_PURCHASE, TO_FILTER_TENDER, TO_FILTER_RESERVATION],
    latest: true,
    processOptions: {
        successType: filterSuccess,
        failType: filterFail
    },
    async process({ getState, action, client }) {
        try {
            let { dataset, andOperator, orOperator } = action.payload;

            let result = dataset;

            if (action.payload && action.payload.binaryAndOperator && action.payload.binaryAndOperator.length > 0) {
                result = FilterUnit.binaryAndOperator(result, action.payload.binaryAndOperator);
            }

            if (action.payload && action.payload.dateAndOperator && action.payload.dateAndOperator.length > 0) {
                result = FilterUnit.dateAndOperator(result, action.payload.dateAndOperator);
            }

            if (andOperator.length > 0) {
                result = FilterUnit.andOperator(result, andOperator);
            }

            if (orOperator.length > 0) {
                result = FilterUnit.orOperator(result, orOperator);
            }

            switch (action.type) {
                case TO_FILTER_INV:
                    return { type: FILTER_INV_SUCCESS, payload: { filteredStocks: result.map(el => el.id) } };
                case TO_FILTER_PURCHASE: 
                    return { type: UPDATE_FILTERED_PURCHASES, payload: { filteredPurchases: result.map(el => el.id) } };
                case TO_FILTER_TENDER: 
                    return { type: UPDATE_FILTERED_TENDERS, payload: { filteredTenders: result.map(el => el.id) } };
                case TO_FILTER_RESERVATION: 
                    return { type: UPDATE_FILTERED_RESERVATION, payload: { filteredProcurements: result.map(el => el.id) } };
                default:

            }
        } catch (err) {
            throw err;
        }
    }
});