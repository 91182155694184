import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import styles from './country.module.scss';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";

export class CountryFilter extends Component {
    render() {
        const { selected, data, handleChange } = this.props;

        return (
            <div className={styles.root}>
                <FormControl className={styles.form}>
                    <InputLabel className={styles.label} classes={{ shrink: styles.shrink }}>
                        Country
                    </InputLabel>
                    <Select
                        autoWidth
                        multiple
                        onChange={handleChange}
                        renderValue={() => (
                            (selected.length > 0) &&
                            <div className={styles['chip-bar']}>
                                {Object.values(selected).map(s => (
                                    <Chip className={styles['chip']}
                                        label={data[s].name}
                                        variant="outlined"
                                        key={`chip_${s}`} />
                                ))}
                            </div>
                        )}
                        value={selected}
                        className={styles['select-ctrl']}
                        disableUnderline
                    >
                        {Object.values(data).map(el => (
                            <MenuItem className={styles['menu-item']} key={`condition-${el.id}`} value={el.id}>
                                <Checkbox checked={selected.indexOf(el.id) > -1} />
                                <ListItemText className={styles['menu-item-text']} primary={el.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}
CountryFilter.propTypes = {
    data: PropTypes.object.isRequired,
    selected: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired
};
