export const LOAD_TENDER = "@@TENDER/LOAD_TENDER";
export const LOAD_TENDER_SUCCESS = "@@TENDER/LOAD_TENDER_SUCCESS";
export const LOAD_TENDER_ERROR = "@@TENDER/LOAD_TENDER_ERROR";
export const CLOSE_TENDER_NOTICE = "@@TENDER/CLOSE_TENDER_NOTICE";
export const OPEN_TENDER_NOTICE = "@@TENDER/OPEN_TENDER_NOTICE";
export const UPDATE_TENDER_SEARCH_TEXT = "@@TENDER/UPDATE_TENDER_SEARCH_TEXT";
export const TO_FILTER_TENDER = "@@TENDER/TO_FILTER_TENDER";
export const UPDATE_FILTERED_TENDERS = "@@TENDER/UPDATE_FILTERED_TENDERS";
export const UPDATE_SELECTED_PERSONIC = "@@TENDER/UPDATE_SELECTED_PERSONIC";

export const UPDATE_TENDER_INPUT = "@@TENDER/UPDATE_TENDER_INPUT";
export const RESET_TENDER_INPUT = "@@TENDER/RESET_TENDER_INPUT";
export const CREATE_TENDER_DETAIL = "@@TENDER/CREATE_TENDER_DETAIL";
export const REMOVE_TENDER_DETAIL = "@@TENDER/REMOVE_TENDER_DETAIL";
export const UPDATE_DELETE_TENDER_DETAIL = "@@TENDER/UPDATE_DELETE_TENDER_DETAIL";
export const RECORD_DELETE_TENDER_DETAIL = "@@TENDER/RECORD_DELETE_TENDER_DETAIL";
export const SELECT_TENDER_DETAIL = "@@TENDER/SELECT_TENDER_DETAIL";
export const UPDATE_TD_INPUT = "@@TENDER/UPDATE_TD_INPUT";

export const UPDATE_FF_INPUT = "@@TENDER/UPDATE_FF_INPUT";
export const CREATE_FULFILMENT = "@@TENDER/CREATE_FULFILMENT";
export const UPDATE_FULFILMENT = "@@TENDER/UPDATE_FULFILMENT";
export const UPDATE_FULFILMENT_SUCCESS = "@@TENDER/UPDATE_FULFILMENT_SUCCESS";
export const UPDATE_FULFILMENT_FAIL = "@@TENDER/UPDATE_FULFILMENT_FAIL";
export const DELETE_FULFILMENT_SUCCESS = "@@TENDER/DELETE_FULFILMENT_SUCCESS";
export const TRIGGER_DELETE_FULFILMENT = "@@TENDER/TRIGGER_DELETE_FULFILMENT";
export const DELETE_FULFILMENT_FAIL = "@@TENDER/DELETE_FULFILMENT_FAIL";

export const UPDATE_TENDER_DISABLE_BOOL = "@@TENDER/UPDATE_TENDER_DISABLE_BOOL";
export const SUBMIT_TENDER_FORM = "@@TENDER/SUBMIT_TENDER_FORM";
export const SUBMIT_TENDER_FORM_SUCCESS = "@@TENDER/SUBMIT_TENDER_FORM_SUCCESS";
export const SUBMIT_TENDER_FORM_ERROR = "@@TENDER/SUBMIT_TENDER_FORM_ERROR";
export const ASK_INIT_TENDER = "@@TENDER/ASK_INIT_TENDER";
export const CANCEL_INIT_TENDER = "@@TENDER/CANCEL_INIT_TENDER";
export const CONFIRM_INIT_TENDER = "@@TENDER/CONFIRM_INIT_TENDER";

export const SELECT_TENDER = "@@TENDER/SELECT_TENDER";
export const POPULATE_TENDER = "@@TENDER/POPULATE_TENDER";
export const SAVE_UPDATED_TENDER = "@@TENDER/SAVE_UPDATED_TENDER";
export const UPDATE_TENDER_FORM_SUCCESS = "@@TENDER/UPDATE_TENDER_FORM_SUCCESS";
export const UPDATE_TENDER_FORM_ERROR = "@@TENDER/UPDATE_TENDER_FORM_ERROR";

export const OPEN_TENDER_DRAWER = "@@TENDER/OPEN_TENDER_DRAWER";
export const CLOSE_TENDER_DRAWER = "@@TENDER/CLOSE_TENDER_DRAWER";

export const LOAD_SUGGESTIONS = "@@TENDER/LOAD_SUGGESTIONS";
export const LOAD_SUGGESTIONS_SUCCESS = "@@TENDER/LOAD_SUGGESTIONS_SUCCESS";
export const LOAD_SUGGESTIONS_ERROR = "@@TENDER/LOAD_SUGGESTIONS_ERROR";

export const ADD_SELECTED_SUGGESTIONS = "@@TENDER/ADD_SELECTED_SUGGESTIONS";
export const REMOVE_SELECTED_SUGGESTIONS = "@@TENDER/REMOVE_SELECTED_SUGGESTIONS";
