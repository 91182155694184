import React, { Component } from "react";
import { Recycle, Heart, Star } from "mdi-material-ui";
import classNames from "classnames";
import { withStyles } from "@material-ui/styles";

const conditions = theme => ({
  secondhand: {
    color: theme.palette.heart.secondHand
  },
  consignment: {
    color: theme.palette.heart.consignment
  },
  "never-used": {
    color: theme.palette.heart.noUsed
  },
  used: {
    color: theme.palette.heart.used
  }
});

export class ProvisionCondition extends Component {
  provisionProvider = (classes, provision) => {
    if (provision === 1) {
      return (
        <div>
          <Recycle className={classes["secondhand"]} />
        </div>
      );
    } else if (provision === 2) {
      return (
        <div>
          <Star className={classes["consignment"]} />
        </div>
      );
    } else if (provision === 0) {
      return " ";
    } else {
      return " ";
    }
  };

  conditionProvider = (classes, condition) => {
    if (condition === 1 || condition === 2 || condition === 3) {
      return (
        <div>
          <Heart
            className={classNames(
              classes.heart,
              { "never-used": condition === 1 || condition === 2 },
              { used: condition === 3 }
            )}
          />
        </div>
      );
    } else {
      return " ";
    }
  };

  render() {
    const { provision, condition, classes } = this.props;

    return (
      <div>
        {this.provisionProvider(classes, provision)}
        {this.conditionProvider(classes, condition)}
      </div>
    );
  }
}
export const ProvisionConditionComponent = withStyles(conditions)(
  ProvisionCondition
);
