import axios from 'axios'

/**
 * 
 * @param {string} url - Endpoint to download the file
 * @param {Object} headers 
 * @param {String} headers.authorization - Bearer authorization - {authorization: `Bearer ${token}`} 
 * @param {String} headers.rayLens - Ray Lens ID
 */
const downloadFile = async (url, headers, fileName="File") => {
    try {
        const response = await axios.request({
            url,
            method: 'GET',
            headers,
            responseType: 'blob'
        })
    
        const contentType = response.headers['content-type']
        const blob = new Blob([response.data], {type: contentType})
        const fileUrl = window.URL.createObjectURL(blob)
    
        window.open(fileUrl, '_blank')
        return {}
    } catch(e) {
        return {error: {message: e.message}}
    }
}

export default downloadFile