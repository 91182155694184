import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Switch } from "react-router";
import { PrivateRoute } from "@cosmos/chaos";
import { features as Features, Functions } from "../../constants";

export const OrdersComponent = connect(state => ({
    rayLens: state.system.rayLens,
    stores: state.system.stores,
    countries: state.system.countries,
}))(({ rayLens, stores, countries }) => {
    const func = Functions.orders;
    const features = Features.filter(f => func.features.includes(f.name));
    const storeCountryId = stores[rayLens] && stores[rayLens].countryId;
    const japanCountryId = Object.values(countries).find(country => country.name === 'Japan') && Object.values(countries).find(country => country.name === 'Japan').id;
    const [inJapan, setInJapan] = useState(storeCountryId && japanCountryId ? String(storeCountryId) === String(japanCountryId) : false);
    useEffect(() => {
        const newStoreCountryId = stores[rayLens] && stores[rayLens].countryId;
        const newJapanCountryId = Object.values(countries).find(country => country.name === 'Japan') && Object.values(countries).find(country => country.name === 'Japan').id;
        if (newStoreCountryId && newJapanCountryId) {
            setInJapan(String(newStoreCountryId) === String(newJapanCountryId));
        }
    }, [rayLens, stores, countries]);
    return (
        <Switch>
            {features
                .filter(f => {
                    if (!inJapan) {
                        return f.path !== '/dutyfree';
                    }
                    return f;
                })
                .map(f => (
                    <PrivateRoute
                        key={`ord-${f.name}`}
                        path={`${func.path}${f.path}`}
                        allowed={f.permission}
                        component={f.component}
                    />
                ))}
            <Redirect to={`${func.path}/purchase`} />
        </Switch>
    )
});