import { createVibes } from "@cosmos/vibes";
import { INIT_USER_LOAD, LOAD_USER_SUCCESS, LOAD_USER_FAIL } from "../../actions";
import { reduce } from 'lodash';
import 'cross-fetch/polyfill';

export const loadUserVibes = createVibes({
  type: [INIT_USER_LOAD],
  debounce: 200,
  latest: true,
  validate({ getState, action, dispatch }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(new Error('User does not login yet'));
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query {
            users { 
              id name fullname
            }
          }`,
        }
      });

      let data = result.data;
      
      let users = data.users;
      if (users) {
        dispatch({ type: LOAD_USER_SUCCESS, payload: { users: reduce(users, (r, d) => { r[d.id] = d; return r }, {}) } });
      }
      else {
        dispatch({ type: LOAD_USER_FAIL });
      }
      done();
    } catch (err) {
      dispatch({ type: LOAD_USER_FAIL });
      done();
    }
  }
});