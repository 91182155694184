import React, {useState} from "react";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import {MenuDown, CardAccountDetails, ExitToApp} from "mdi-material-ui";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import {ClickAwayListener} from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/styles";
import {darken, alpha, lighten} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    root: {},
    button: {
        // backgroundColor: alpha(theme.palette.primary.dark, 0.5),
        backgroundColor: alpha(theme.palette.primary.light, 0.5),
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.light, 0.3),
        }
    },
    icon: {
        color: darken(theme.palette.primary.dark, 0.3),
        marginLeft: `${theme.spacing(1)}px`
    },
    iconSize: {
        '& > *:first-child': {
            fontSize: '28px',
        }
    },
    infos: {
        minWidth: '6em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    userLabel: {
        color: darken(theme.palette.primary.dark, 0.3),
        fontSize: '110%',
        fontWeight: 'bold',
        lineHeight: '1.25'
    },
    storeLabel: {
        color: darken(theme.palette.secondary.dark, 0.8),
        fontWeight: 'bolder',
        lineHeight: '1.25'
    },
    toggle: {
        color: darken(theme.palette.primary.dark, 0.3),
    },
    menu: {
        backgroundColor: lighten(theme.palette.primary.light, 0.8)
    },
    actionItem: {
        backgroundColor: lighten(theme.palette.secondary.light, 0.6)
    },
    storeItem: {
        backgroundColor: lighten(theme.palette.primary.light, 0.6)
    },
    scrollMenu: {
        maxHeight: '16em',
        overflow: 'auto'
    }
}));

export function MiniProfile(props) {
    const {name, stores, currentStore, onSelect, onAction, classes} = props;
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const selectedIndex = stores.findIndex(s => s.id === currentStore);

    const handleActionClick = (event, actionKey) => {
        onAction && onAction(actionKey);
        setOpen(false);
    };

    const handleMenuItemClick = (event, index, sid) => {
        onSelect && onSelect(sid);
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const styles = useStyles();
    return (
        <div className={classNames(styles.root, (classes.root) ? classes.root : '')}>
            <Button
                className={styles.button}
                classes={{startIcon: styles.icon, iconSizeMedium: styles.iconSize, endIcon: styles.toggle}}
                disableRipple
                onClick={handleToggle}
                startIcon={<CardAccountDetails/>}
                endIcon={<MenuDown/>}
                ref={anchorRef}
            >
                <div className={styles.infos}>
                    <div className={styles.userLabel}>{name}</div>
                    <div
                        className={styles.storeLabel}>{selectedIndex > -1 && (stores[selectedIndex].code || stores[selectedIndex].name)}</div>
                </div>
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" transition disablePortal>
                {({TransitionProps}) => (
                    <Grow {...TransitionProps}
                          style={{transformOrigin: 'center top'}}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList className={styles.menu}>
                                    <MenuItem className={styles.actionItem}
                                              onClick={event => handleActionClick(event, 'logout')}>
                                        <ListItemIcon>
                                            <ExitToApp/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Logout
                                        </ListItemText>
                                    </MenuItem>
                                    <Divider/>
                                    <div className={styles.scrollMenu}>
                                        {stores.map((store, index) => (
                                            <MenuItem className={styles.storeItem}
                                                      key={`store-${store.id}`}
                                                      selected={index === selectedIndex}
                                                      onClick={event => handleMenuItemClick(event, index, store.id)}
                                            >
                                                <ListItemText>
                                                    {store.name}
                                                </ListItemText>
                                            </MenuItem>
                                        ))}
                                    </div>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

MiniProfile.defaultProps = {
    stores: {},
    classes: {}
};