import axios from 'axios'

/**
 * 
 * @param {string} scanLabel 
 * @param {Object} headers
 * @param {string} headers.authorization - Bearer Authorization - {authorization: `Bearer ${token}`}
 * @param {string} headers.rayLens - rayLens ID
 * @returns {string} proformaPurchaseId
 */
const getProforma = async (scanLabel, headers) => {
    try {
        const response = await axios.request({
            url: `/v1/pcs/${scanLabel}`,
            method: 'get',
            headers
        })
    
        return {id: response.data.proformaPurchaseOrderId}
    } catch(e) {
       if (e.response.status === 404) {
            return {error: 'Proforma purchase not found'}
       }
        
       return {error: e.message}
    }
}

export default getProforma