import React, { Component } from "react";
import { DialogContent } from "@material-ui/core";
import { Button, Dialog, DialogTitle, DialogActions } from "@material-ui/core";

class ReservedDialog_ extends Component {

  render() {
    const { reservedDialog, reservedFor, reservation, handleClose, handleConfirm } = this.props;
    return (
      <Dialog maxWidth={"lg"} open={reservedDialog}>
        <DialogTitle>
          A reserved stock is scanned
        </DialogTitle>

        <DialogContent>
          <div>
            This stock is reserved for <b> {reservedFor} </b> 
          </div>
          <div>
            The reservation reference is <b> {reservation} </b>.
          </div>
          <div>
            <b>Are you confirm to sell this stock? </b>.
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined" size="small" >
            No
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained" size="small">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const ReservedDialog = ReservedDialog_;