import {createVibes} from "@cosmos/vibes";
import {AUTH_LOGOUT} from "@cosmos/chaos";
import {
    UPDATE_EXTENSION,
    EXTENSION_RECEIVED,
    UPDATE_TAX_RATE_BEGIN,
    UPDATE_RAY_STORE,
    INV_RELOAD_BEGIN,
    RELOAD_PURCHASE,
    APP_INIT,
    APP_API_EXTENSIONS,
    LOAD_CATALOG_SUCCESS, INFOS_DATA_ERROR, enqueueSnackbar, GTIN_ERROR, GTIN_SUCCESS,
} from "../actions";
import {features as Features} from "../constants";
import {distinctUntilChanged, filter, map, delay} from "rxjs/operators";
import {keyBy, reduce, isEqual} from "lodash";

export const initStoreVibes = createVibes({
    type: [LOAD_CATALOG_SUCCESS, UPDATE_EXTENSION], //change from AUTH_ATTEMPT_SUCCESS to LOAD_CATALOG_SUCCESS
    latest: true,
    process({getState, action}, dispatch, done) {
        const permission = getState().auth.permission;
        if (permission) {
            if (permission.includes("*")) {
                if (action.type === LOAD_CATALOG_SUCCESS) {
                    dispatch({type: UPDATE_RAY_STORE, payload: "-1"});
                    localStorage.setItem("rayLens", JSON.stringify("-1"));
                }
            } else {
                const list = Object.keys(getState().system.stores);
                if (list && list.length > 0) {
                    if (
                        action.type === LOAD_CATALOG_SUCCESS ||
                        list.indexOf(getState().system.rayLens) < 0
                    ) {
                        dispatch({type: UPDATE_RAY_STORE, payload: list[0]});
                        localStorage.setItem("rayLens", JSON.stringify(list[0]));
                    }
                } else {
                    //TODO No Store Should Logout,
                    // except later need to have new
                    // function for person do other task
                }
            }
        }
        done();
    },
});

export const clearStorageVibes = createVibes({
    type: AUTH_LOGOUT,
    latest: true,
    process({getState}, dispatch, done) {
        localStorage.removeItem("cartInput");
        localStorage.removeItem("purchaseInput");
        localStorage.removeItem("deletedDetails");
        localStorage.removeItem("selectedPdIdx");
        localStorage.removeItem("selectedStockInput");
        localStorage.removeItem("stockInputs");
        localStorage.removeItem("selectedPurchaseId");
        localStorage.removeItem("rayLens");
        localStorage.removeItem("editMode");
        localStorage.removeItem("tenderInput");
        localStorage.removeItem("selectedTdIdx");
        localStorage.removeItem("submitted");
        localStorage.removeItem("disableTender");
        done();
    },
});

export const handleRayLensUpdateVibes = createVibes({
    type: [UPDATE_RAY_STORE],
    latest: true,
    process({getState}, dispatch, done) {
        const path = getState().router.location.pathname;
        switch (path) {
            case "/inventory":
                dispatch({type: INV_RELOAD_BEGIN, payload: {filter: {}}});
                break;
            case "/purchase":
                dispatch({type: RELOAD_PURCHASE});
                break;
            case "/pos":
                dispatch({type: UPDATE_TAX_RATE_BEGIN});
                break;
            default:
        }
        done();
    },
});

export const handleExtensionUpdateVibes = createVibes({
    type: [EXTENSION_RECEIVED],
    latest: true,
    process({getState, action}, dispatch, done) {
        const result = {
            ...action.payload,
            currencies: reduce(
                action.payload.currencies,
                (r, d) => {
                    r[d.id] = d;
                    return r;
                },
                {}
            ),
            countries: reduce(
                action.payload.countries,
                (r, d) => {
                    r[d.id] = d;
                    return r;
                },
                {}
            ),
            stores: reduce(
                action.payload.stores,
                (r, d) => {
                    r[d.id] = d;
                    return r;
                },
                {}
            ),
            paymentChannels: reduce(
                action.payload.paymentChannels,
                (r, d) => {
                    r[d.id] = d;
                    return r;
                },
                {}
            ),
        };
        const currentStoreLength = Object.values(getState().system.stores).length;
        // when previously no stores loaded and now got the stores, re-update the tax rate of the cart
        if (!currentStoreLength) {
            const path = getState().router.location.pathname;
            switch (path) {
                case "/pos":
                    dispatch({type: UPDATE_TAX_RATE_BEGIN});
                    break;
                default:
            }
        }

        dispatch({type: UPDATE_EXTENSION, payload: result});
        done();
    },
});
const always = ['admin', 'priceRule']
export const handleExtensions = createVibes({
    type: APP_INIT,
    latest: true,
    warnTimeout: 0,
    process({action$}) {
        return action$.pipe(
            delay(10),
            filter((action) => action.type === APP_API_EXTENSIONS),
            distinctUntilChanged(),
            map((action) => {
                const {payload} = action;
                const {
                    currencies,
                    countries,
                    stores,
                    paymentChannels,
                    features: enabled = [
                        "marketing",
                        "supplies",
                        "createSupplyUpcoming",
                        "priceRule",
                        "admin",
                    ],
                    ...rest
                } = payload;

                const result = {
                    ...rest,
                    // should check for difference
                    currencies: keyBy(currencies, (c) => c.id),
                    countries: keyBy(countries, (c) => c.id),
                    stores: keyBy(stores, (s) => s.id),
                    paymentChannels: keyBy(paymentChannels, (p) => p.id),
                };
                // TODO feature controls
                if (!isEqual(always, enabled)) {
                    const fns = [...enabled, ...always];
                    console.log(fns)
                    result.features = Features.filter((f) => {
                        return fns.includes(f.name);
                    });
                }
                console.log(result)
                return {type: UPDATE_EXTENSION, payload: result};
            })
        );
    },
});

export const handleNotify = createVibes({
    type: [INFOS_DATA_ERROR, GTIN_SUCCESS, GTIN_ERROR],
    processOptions: {
        successType: enqueueSnackbar
    },
    async process({action}) {
        console.log("MESSAGE NOTIFY", action);
        switch(action.type) {
            case GTIN_ERROR:
            case INFOS_DATA_ERROR:
                return {
                    message: action.payload.message,
                    options: {
                        variant: 'error'
                    }
                }
            case GTIN_SUCCESS:
                return {
                    message: `GTIN For Product ${action.payload} updated.`,
                    options: {
                        variant: 'success'
                    }
                }
            default:
                return null;
        }
    }
})


