import { createVibes } from "@cosmos/vibes";
import {
  VERIFY_DELIVERY_LABEL, DELIVERY_LABEL_SUCCESS, DELIVERY_LABEL_ERROR, UPDATE_POS_FIELD
} from "../../actions";


export const verifyDeliveryLabelVibes = createVibes({
  type: VERIFY_DELIVERY_LABEL,
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const deliveryLabel = action.payload;

      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const query = await axios.request({
        url: `/DEL/${deliveryLabel}`,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          rayLens
        }
      });

      if(query.status < 400){
        if (query.status === 204) {
          dispatch({ type: UPDATE_POS_FIELD, payload: { delivery: { label: deliveryLabel } } })
          dispatch({ type: DELIVERY_LABEL_SUCCESS });
        }
        else{
          dispatch({ type: DELIVERY_LABEL_ERROR, payload: {notice: 'The delivery label is already used '} });
        }
      }
      else{
        dispatch({ type: DELIVERY_LABEL_ERROR, payload: {notice: 'The delivery label is invalid'} });
      }
      done();
    } catch (err) {
      dispatch({ type: DELIVERY_LABEL_ERROR, payload: {notice: 'The delivery label is invalid'} });
      done();
    }
  }
});

