import { createVibes } from "@cosmos/vibes";
import { DEL_LOAD_BEGIN, DEL_LOAD_SUCCESS, DEL_LOAD_ERROR, } from "../../actions";
import { reduce } from 'lodash';
import moment from 'moment';

export const loadDeliveryVibes = createVibes({
  type: [DEL_LOAD_BEGIN],
  debounce: 300,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const variables = {
        filter: action.payload
      }

      const data = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query($filter: DeliveryFilter){
            deliveries(filter: $filter) {
              id
              reference
              state
              tracking
              date
              price
              amount
              createdAt
              updatedAt
              deletedAt
              userId
              sender
              receiver
              remarks
              goods {
                id productId serial
                sellCurrency sellAmount
              }
              courierId
              docCourierId
              docTracking
              airway
              houseAirways
              cart {
                id reference storeId
              }
            }

            couriers {
              id name
            }

            docCouriers {
              id name
            }

          }`,
          variables
        }
      });

      let result = data.data;

      if (result.deliveries) {
        dispatch({
          type: DEL_LOAD_SUCCESS, payload: {
            deliveries: reduce(result.deliveries, (r, d) => {
                r[d.id] = { 
                  ...d, 
                  date: d.date ? moment(d.date).format("YYYY-MM-DD") : null,
                  amount: d.amount ? d.amount.toFixed(2) : 0, 
                };
                return r;
              }, {}),
              couriers: result.couriers,
              docCouriers: result.docCouriers,
          }
        })
      }
      else {
        dispatch({ type: DEL_LOAD_ERROR, payload: { notify: true, notice: 'Fail to load deliveries', noticeType: 'error' } });
      }
      done();
    } catch (err) {
      dispatch({ type: DEL_LOAD_ERROR, payload: { notify: true, notice: 'Fail to load deliveries', noticeType: 'error' } });
      done();
    }
  }
});