export default function matchPurchaseOrders(purchaseDetails, stockInDetails) {
    const matches = []

    const stockInDetailsCopy = [...stockInDetails.map(a => ({ ...a }))]

    purchaseDetails.forEach(purchaseDetail => {
        for (let i = 0; i < purchaseDetail.quantityExpected; i++) {
            const matchingStockIndex = stockInDetailsCopy.findIndex(stock => stock.productId === purchaseDetail.productId)

            if (matchingStockIndex === -1) continue
            const matchingStock = stockInDetailsCopy.splice(matchingStockIndex, 1).map(stock => stock.id)[0]

            matches.push({ stock: matchingStock, purchase: purchaseDetail.id })

        }
    })


    return [...matches, ...stockInDetailsCopy.map(stock => ({ stock: stock.id, purchase: null }))]
}