import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './reviewPurchase.module.scss';
import classNames from 'classnames';
import { Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';
import { CLOSE_REVIEW_PURCHASE, EDIT_PURCHASE } from '../../../../actions';
import { InfoComponent, DetailComponent } from '../../components';

class ReviewPurchaseDialogComponent extends Component {

  closeDialog = () => {
    this.props.dispatch({ type: CLOSE_REVIEW_PURCHASE });
  }

  editPurchase = () => {
    this.props.dispatch({ type: EDIT_PURCHASE });
  }

  render() {
    const { dataUri, countries, purchases, dialogOpen, reviewedPurchaseId, purchaseTypes, currencies, stores, products, conditionOptions } = this.props;
    let selectedPurchaseOrder = {};

    if (reviewedPurchaseId && purchases[reviewedPurchaseId]) {
      selectedPurchaseOrder = purchases[reviewedPurchaseId];
    }

    return (
      <Dialog
        maxWidth={'xl'}
        open={dialogOpen}
        onClose={this.closeDialog}
      >
        <DialogTitle>Purchase Order Information</DialogTitle>
        <DialogContent>
          <div className={styles.root}>
            <div className={classNames(styles['flex-row'], styles['flex-1'])}>
              <div className={classNames(styles['flex-column'], styles['left-container'])}>
                <div className={classNames(styles['flex-1'], styles['left-component'])}>
                  <InfoComponent
                    purchaseTypes={purchaseTypes}
                    currencies={currencies}
                    purchaseInput={selectedPurchaseOrder}
                    stores={stores}
                    countries={countries}
                    readOnly={true}
                  />
                </div>
                <div className={classNames(styles['flex-1'], styles['left-component'])}>
                  {
                    dataUri &&
                    (
                      <img src={dataUri} alt={'invoice'} />
                    )
                  }
                </div>
              </div>
              <div className={classNames(styles['flex-column'], styles['right-container'])}>
                <DetailComponent
                  purchaseInput={selectedPurchaseOrder}
                  products={products}
                  readOnly={true}
                  currencySymbol={currencies[selectedPurchaseOrder.currency] ? currencies[selectedPurchaseOrder.currency].symbol : ""}
                  conditionOptions={conditionOptions}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={styles['button-group']}>
          <Button onClick={this.closeDialog} color='primary' variant='outlined' size='small'>
            Close
          </Button>
          {purchases[reviewedPurchaseId] && purchases[reviewedPurchaseId].state !== 0 ? null : <Button onClick={this.editPurchase} color='primary' variant='contained' size='small'>
            Edit
          </Button>}
        </DialogActions>
      </Dialog>
    )
  }
}

export const ReviewPurchaseDialog = connect(state => ({
  purchases: state.purchase.purchases,
  dialogOpen: state.purchase.reviewPurchaseDialog,
  dataUri: state.purchase.dataUri,
  reviewedPurchaseId: state.purchase.reviewedPurchaseId,
  purchaseTypes: state.purchase.purchaseTypes,
  currencies: state.system.currencies,
  stores: state.system.stores,
  products: state.catalog.products,
  conditionOptions: state.purchase.conditionOptions,
  countries: state.system.countries,
}))(ReviewPurchaseDialogComponent);