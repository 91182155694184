import {
    LOAD_CATALOG_SUCCESS,
    LOAD_CATALOG_ERROR,
    LOAD_CATALOG_START,
    APPEND_NEW_PRODUCT,
    UPDATE_INFOS,
    LOAD_INFOS,
    LOAD_INFOS_ERROR,
    INFOS_SEARCH,
    INFOS_LOADED,
    SELECT_INFOS_PRODUCT,
    UPDATE_GTIN_TYPE,
    UPDATE_GTIN_VALUE,
    INFOS_MODE_CHANGE,
    GTIN_SUCCESS,
    UPDATE_BARCODE, INFOS_DATA_UPDATE, SUBMIT_GTIN, GTIN_ERROR, INFOS_MODE_PRIORITY,
} from "../actions";
import moment from "moment";
import data from "./data.json";
import {defaults} from "lodash";

const products = {};
const brands = {};
data.data.products.forEach((product) => (products[product.id] = product));
data.data.brands.forEach((brand) => (brands[brand.id] = brand));
const initialState = {
    loading: false,
    products: {...products},
    brands: {...brands},
    infosLoading: false,
    infos: {},
    infosIdx: [],
    selectedInfo: {
        id: -1,
        brand: "",
        reference: "",
        ean: "",
        upc: "",
        jan: ""
    },
    gtinType: 'ean',
    gtinValue: '',
    submitting: false,
    updateSuccess: false,
    stockLabel: '',
    barcodeMode: true,
    priorityMode: true,
    barcodeText: '',
    search: "",
    selectedBrands: [],
    update: -1,
    newProduct: {},
};

export const catalogReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CATALOG_START:
            return {
                ...state,
                loading: true
            };
        case LOAD_CATALOG_SUCCESS:
            return {...state, ...action.payload, loading: false, update: moment()};
        case LOAD_CATALOG_ERROR:
            return {...state, loading: false};
        case APPEND_NEW_PRODUCT:
            return {
                ...state,
                loading: false,
                products: {
                    ...state.products,
                    [action.payload.id]: action.payload
                }
            };
        case LOAD_INFOS:
            return {
                ...state, infosLoading: true
            }
        case INFOS_LOADED:
            return {
                ...state,
                ...action.payload
            }
        case UPDATE_INFOS:
            return {
                ...state,
                infosLoading: false,
                infosIdx: action.payload
            }
        case LOAD_INFOS_ERROR:
            return {
                ...state,
                infosLoading: false
            }
        case INFOS_SEARCH:
            return {
                ...state,
                search: action.payload
            }
        case SELECT_INFOS_PRODUCT:
            return {
                ...state,
                selectedInfo: defaults(action.payload, {
                    id: -1,
                    brand: "",
                    reference: "",
                    ean: "",
                    upc: "",
                    jan: ""
                }),
                updateSuccess: false,
            }
        case UPDATE_GTIN_TYPE:
            return {
                ...state,
                gtinType: action.payload
            }
        case UPDATE_GTIN_VALUE:
            return {
                ...state,
                gtinValue: action.payload
            }
        case INFOS_MODE_CHANGE:
            return {
                ...state, barcodeMode: !state.barcodeMode
            }
        case INFOS_MODE_PRIORITY:
            return {
                ...state, priorityMode: !state.priorityMode
            }
        case INFOS_DATA_UPDATE:
            return {
                ...state,
                barcodeText: "",
                ...action.payload,
                updateSuccess: false
            }
        case UPDATE_BARCODE:
            return {
                ...state,
                barcodeText: action.payload,
                updateSuccess: false
            }
        case SUBMIT_GTIN: {
            return {
                ...state,
                submitting: true,
                updateSuccess: false
            }
        }
        case GTIN_ERROR:
            return {
                ...state,
                submitting: false,
                updateSuccess: false
            }
        case GTIN_SUCCESS:
            return {
                ...state,
                infos: {...state.infos,
                    [action.payload.id]: {...state.infos[action.payload.id], ...action.payload}},
                stockLabel: '',
                gtinValue: '',
                selectedInfo: {
                    id: -1,
                    brand: "",
                    reference: "",
                    ean: "",
                    upc: "",
                    jan: ""
                },
                submitting: false,
                updateSuccess: true
            }
        default:
            return state;
    }
};