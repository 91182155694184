import React from "react";
import { connect, useDispatch } from "react-redux";
import {
  Paper,
  Switch,
  TextField,
  List,
  ListItem,
  Typography,
  withStyles
} from "@material-ui/core";
import { UPDATE_POS_FIELD } from "../../../actions";

const styles = theme => ({
  delivery: {
    flex: 1,
    display: "flex"
  },
  field: {
    minWidth: "10em",
    marginRight: "1.5em",
    marginLeft: "1.5em",
    flex: 1
  },
  switch: {
    marginLeft: "1.5em"
  },
  content: {
    flex: 1,
    padding: "0 0 1em 0"
  },
  label: {
    minWidth: "5em",
    marginLeft: "1.5em"
  }
});

const DeliveryComponent_ = props => {
  const { tracking, delivery, classes } = props;
  const dispatch = useDispatch();
  const updateField = (event) => {
    dispatch({ type: UPDATE_POS_FIELD, payload: { tracking: event.target.value }});
  };
  return (
    <div className={classes.delivery}>
      <Paper className={classes.content}>
        <List>
          <ListItem>
            <Typography className={classes["label"]}>Tracking Code</Typography>
            <TextField
              aria-label="Tracking Code"
              className={classes["field"]}
              value={tracking ? tracking : ""}
              onChange={(e)=> {updateField(e)}}>
              </TextField>             
          </ListItem>
          <ListItem>
            <Typography className={classes["label"]}>Delivery Label</Typography>
            <Switch
              className={classes["switch"]}
              checked={Boolean(delivery && delivery.label)}
              value="deliveryLabel"
            />
          </ListItem>
        </List>
      </Paper>
    </div>
  );
};

export const DeliveryComponent = connect(state => ({
  tracking: state.pos.tracking,
  delivery: state.pos.delivery
}))(withStyles(styles)(DeliveryComponent_));
