import React, { useState } from 'react'
import createPOandWI from '../functions/createPOandWI'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import downloadFile from '../functions/downloadFile'
import useHeaders from '../../../hooks/useHeaders'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import { makeStyles } from '@material-ui/styles'
import shortid from 'shortid'
import ErrorText from '../components/ErrorText'

const useStyles = makeStyles(theme => ({
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    error: {
        textAlign: 'center',
        color: theme.palette.warning[400]
    }
}))

export default function PrintBlankFormDialog({isOpen, handleClose}) {
    const headers = useHeaders()
    const [value, setValue] = useState("1")
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handlePrint = async () => {
        setError(null)
        setIsLoading(true)

        for (let i=0; i<value; i++) {
            const { error, data } = await createPOandWI(null, shortid.generate(), headers)
            if (error) {
                setIsLoading(false)
                return setError('Failed to create blank form: ' + error.message)
            }

            const {downloadUrl} = data.purchaseOrderData;
            const {error: downloadFileError} = await downloadFile(downloadUrl, headers)

            if (downloadFileError) {
                setIsLoading(false)
                return setError('Failed to print form: ' + downloadFileError.message)
            }
        }

        setIsLoading(false)
        handleClose()
    }

    const classes = useStyles()
    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Print Blank Forms</DialogTitle>
            <DialogContent>
                <DialogContentText>Choose the number of blank forms to print</DialogContentText>
                <RadioGroup name="copies" value={value} onChange={e => setValue(e.target.value)} row className={classes.spaceBetween}>
                    {["1", "2", "3"].map(opt => {
                    return (
                        <FormControlLabel key={opt} value={opt} control={<Radio />} label={opt} />
                    )
                    })}
                </RadioGroup>
                {error ? <ErrorText className={classes.error}><strong>{error}</strong></ErrorText> : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handlePrint} disabled={isLoading}>{isLoading ? 'Loading' : 'Print'}</Button>
            </DialogActions>
        </Dialog>
    )
}
