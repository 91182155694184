import { createVibes } from "@cosmos/vibes";
import {
  UPDATE_DEL_FIELD, UPDATE_DEL_FIELD_SUCCESS, UPDATE_DEL_FIELD_ERROR,
  CONFIRM_BOX_OPEN, DISPLAY_DEL_RECEIPT, DISPLAY_DEL_RECEIPT_ERROR,
  DISPLAY_DEL_RECEIPT_SUCCESS, 
} from "../actions";

export const updateDeliveryVibes = createVibes({
  type: [UPDATE_DEL_FIELD],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { deliveries } = getState().delivery;

      const variables = {
        input: action.payload
      }

      const data = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `mutation($input: UpdateDeliveryInput){
            updateDelivery(input: $input) {
              id
              reference
              state
              tracking
              date
              price
              amount
              createdAt
              updatedAt
              deletedAt
              userId
              sender
              receiver
              remarks
              goods {
                id productId serial
                sellCurrency sellAmount
              }
              courierId
              docCourierId
              docTracking
              airway
              houseAirways
              cart {
                id reference storeId
              }
            }
          }`,
          variables
        }
      });
      let result = data.data;

      if (result.updateDelivery) {
        let newDeliveries = { ...deliveries, [action.payload.id]: result.updateDelivery };
        dispatch({ type: UPDATE_DEL_FIELD_SUCCESS, payload: { deliveries: newDeliveries, notify: true, noticeType: 'success', notice: 'Successful to update delivery.' } });
      }
      else {
        dispatch({ type: UPDATE_DEL_FIELD_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to update delivery.' } });
      }
      done();
    } catch (err) {
      dispatch({ type: UPDATE_DEL_FIELD_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to update delivery.' } });
      done();
    }
  }
});

export const confirmBoxOpenedVibes = createVibes({
  type: [CONFIRM_BOX_OPEN],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { targetDeliveryId, deliveries } = getState().delivery;

      const data = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `mutation($input: ID){
            updateBoxOpened(input: $input) {
              id
              reference
              state
              tracking
              date
              price
              amount
              createdAt
              updatedAt
              deletedAt
              userId
              sender
              receiver
              remarks
              goods {
                id productId serial
                sellCurrency sellAmount
              }
              courierId
              docCourierId
              docTracking
              airway
              houseAirways
              cart {
                id reference storeId
              }
            }
          }`,
          variables: {
            input: targetDeliveryId
          }
        }
      });
      let result = data.data;

      if (result.updateBoxOpened) {
        let newDeliveries = { ...deliveries, [targetDeliveryId]: result.updateBoxOpened };
        dispatch({
          type: UPDATE_DEL_FIELD_SUCCESS, payload: {
            deliveries: newDeliveries,
            notify: true,
            noticeType: 'success',
            notice: 'Successful to update delivery status.',
            targetDeliveryId: null,
            boxOpenDialog: false,
          }
        });
      }
      else {
        dispatch({
          type: UPDATE_DEL_FIELD_ERROR, payload: {
            notify: true,
            noticeType: 'error',
            notice: 'Fail to update delivery status.',
            targetDeliveryId: null,
            boxOpenDialog: false,
          }
        });
      }
      done();
    } catch (err) {
      dispatch({
        type: UPDATE_DEL_FIELD_ERROR, payload: {
          notify: true,
          noticeType: 'error',
          notice: 'Fail to update delivery status.',
          targetDeliveryId: null,
          boxOpenDialog: false,
        }
      });
      done();
    }
  }
});

export const displayInvoiceVibes = createVibes({
  type: [DISPLAY_DEL_RECEIPT],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { deliveries } = getState().delivery;

      const cart = action.payload.id && deliveries[action.payload.id] ? deliveries[action.payload.id].cart : null;

      if (cart && cart.storeId && cart.reference) {
        const data = await axios.request({
          url: `/rcp/${cart.storeId}/${cart.reference}`,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${token}`,
            rayLens,
          },
          responseType: "blob"
        });

        const response = data.request && data.request.response;
        const file = new Blob(
          [response],
          { type: 'application/pdf' }
        );
        const fileURL = URL.createObjectURL(file);

        let nwin = window.open(fileURL, '_blank');
        nwin.focus();

        dispatch({ type: DISPLAY_DEL_RECEIPT_SUCCESS });

      } else {
        dispatch({ type: DISPLAY_DEL_RECEIPT_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to print invoice. ' } });
      }
      done();
    } catch (err) {
      dispatch({ type: DISPLAY_DEL_RECEIPT_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to print invoice. ' } });
      done();
    }
  }
});
