import React from "react";
import * as PropTypes from 'prop-types';
import { MenuItem, Select, Chip, Checkbox, ListItemText, InputLabel, FormControl, withStyles } from "@material-ui/core";

const styles = theme => ({
    root: {
        minWidth: "8em",
        display: "flex"
    },
    form: {
        width: "100%"
    },
    label: {
        color: "white"
    },
    shrink: {
        color: "white",
        marginTop: "0.25em"
    },
    "chip-bar": {
        color: "white"
    },
    chip: {
        color: "white",
        marginLeft: "4px",
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light
    }
})

export class TypeFilter_ extends React.Component {
    render() {
        const {selected, data, handleChange, classes} = this.props;
        return (
            <div className={classes.root}>
                <FormControl className={classes.form}>
                    <InputLabel className={classes.label} classes={{shrink: classes.shrink}}>
                        Type
                    </InputLabel>
                    <Select autoWidth
                            multiple
                            onChange={handleChange}
                            renderValue={() => (
                                (selected.length > 0) &&
                                <div className={classes['chip-bar']}>
                                    {selected.map(s => (
                                        <Chip className={classes.chip}
                                              label={data[s].name}
                                              variant="outlined"
                                              key={`chip_${s}`}/>
                                    ))}
                                </div>
                            )}
                            value={selected}
                            className={classes['select-ctrl']}
                            disableUnderline
                    >
                        {Object.keys(data).map(type => (
                            <MenuItem className={classes['menu-item']} key={`type-${type}`} value={type}>
                                <Checkbox checked={selected.indexOf(type) > -1}/>
                                <ListItemText className={classes['menu-item-text']} primary={data[type].name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}

export const TypeFilter = withStyles(styles)(TypeFilter_);

TypeFilter.propTypes = {
    data: PropTypes.object.isRequired,
    selected: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired
};

