import React from 'react'
import { SmartBox } from '@cosmos/chaos';
import TextField from '@material-ui/core/TextField'
import InputBase from '@material-ui/core/InputBase'

export default function SelectProduct({className, readOnly, selectedProduct, products, idx, updatePDInputField, label}) {
    return (
        <div className={className} style={{minWidth: '300px'}}>
            {readOnly ?
              <InputBase
                value={selectedProduct.productDisplay ? selectedProduct.productDisplay : ''}
                inputProps={{ readOnly: readOnly, disabled: readOnly }}
              />
              :
              <SmartBox
                inputRenderer={props => <TextField {...props} label={label} />}
                suggestionProvider={val => {
                  let count = 0;
                  let result = Object.values(products).filter(el => {
                    const keep = el.reference && el.reference.toUpperCase().replace(/[ .-/]/g, '').includes(val.toUpperCase().replace(/[ .-/]/g, '')) && count < 10;
                    if (keep) {
                      count += 1;
                    }
                    return keep;
                  }).map(el => ({ label: el.reference, value: el.id, ...el }));
                  return result;
                }}
                searchText={selectedProduct.productDisplay ? selectedProduct.productDisplay : ''}
                updateSearchText={val => {
                  if (typeof val === 'object') {
                    updatePDInputField({ index: idx, payload: { productId: val.id, productDisplay: val.reference, brandId: val.brandID } });
                  }
                  else {
                    updatePDInputField({ index: idx, payload: { productId: null, productDisplay: val, brandId: null } });
                  }
                }}
                updateSelectedItem={val => {
                  updatePDInputField({ index: idx, payload: { productId: val.id, productDisplay: val.reference, brandId: val.brandID } });
                }}
              />}
          </div>
    )
}
