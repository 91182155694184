import { createVibes } from "@cosmos/vibes";
import {
  UPDATE_PD_PACKAGE_REMARKS,
  UPDATE_PO_INPUT,
  CONFIRM_INIT_PURCHASE,
  UPDATE_PO_FORM_DISABLE_BOOL,
  UPDATE_PD_INPUT,
  CREATE_PURCHASE_DETAIL,
  UPDATE_PD_REMARKS,
  PURCHASE_DETAIL_REMOVED,
  SEARCH_BY_SERIAL,
  UPDATE_SEARCHED_STOCK,
  SEARCH_STOCK_FAIL,
  ENTER_NEW_LABEL,
  SEARCH_LABEL_FAIL,
  SEARCH_LABEL_SUCCESS,
  SUBMIT_CHANGE_LABEL,
  SUBMIT_CHANGE_LABEL_ERROR,
  SUBMIT_CHANGE_LABEL_SUCCESS,
  OPEN_STOCK_IMAGES,
  OPEN_IMAGE_DIALOG,
  SET_DATA_URIS,
  OPEN_PROD_IMAGES,
  LOAD_CHANGE_LABEL_IMG,
} from "../actions";

export const disablePOFormVibes = createVibes({
  type: [
    UPDATE_PO_INPUT,
    UPDATE_PD_INPUT,
    CONFIRM_INIT_PURCHASE,
    CREATE_PURCHASE_DETAIL,
    PURCHASE_DETAIL_REMOVED,
    UPDATE_PD_REMARKS,
    UPDATE_PD_PACKAGE_REMARKS,
  ],
  latest: true,
  async process({ getState }, dispatch, done) {
    try {
      const { purchaseInput } = getState().purchase;
      let disablePo = false;
      if (!purchaseInput.vendor || isNaN(purchaseInput.vendor)) {
        disablePo = true;
      }

      if (!disablePo && !purchaseInput.currency) {
        disablePo = true;
      }

      if (!disablePo && !purchaseInput.type && purchaseInput.type !== 0) {
        disablePo = true;
      }

      if (
        !disablePo &&
        (!purchaseInput.purchaseDetails ||
          purchaseInput.purchaseDetails.length === 0)
      ) {
        disablePo = true;
      }

      if (
        !disablePo &&
        purchaseInput.purchaseDetails &&
        purchaseInput.purchaseDetails.length > 0
      ) {
        let dis = purchaseInput.purchaseDetails.find((pd) => {
          return (
            !pd.productId ||
            !pd.quantityExpected ||
            (!pd.unitPrice && pd.unitPrice !== 0)
          );
        });
        if (dis) {
          disablePo = true;
        }
      }

      dispatch({ type: UPDATE_PO_FORM_DISABLE_BOOL, payload: { disablePo } });
      done();
    } catch (err) {
      throw err;
    }
  },
});

export const searchStockBySerial = createVibes({
  type: [SEARCH_BY_SERIAL],
  latest: true,
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { serial } = action.payload;
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const result = await axios.request({
        url: "/api",
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          rayLens,
        },
        data: {
          query: `query($filter: SingleStockFilter) {
                    stock(filter: $filter)
                    {
                        id brandId productId serial state storeId 
                        attachments {
                            id fid type
                        }
                    }
                  }`,
          variables: { filter: { serial } },
        },
      });

      if (result.data && result.data.stock) {
        const stock = result.data.stock;
        if (stock.storeId === rayLens) {
          dispatch({
            type: UPDATE_SEARCHED_STOCK,
            payload: { searchedStock: stock },
          });
          dispatch({ type: LOAD_CHANGE_LABEL_IMG, payload: { id: stock.id } });
        } else {
          dispatch({
            type: SEARCH_STOCK_FAIL,
            payload: {
              notice: "This stock is not belonged to the current store. ",
              noticeType: "error",
              notify: true,
            },
          });
        }
      } else {
        dispatch({
          type: SEARCH_STOCK_FAIL,
          payload: {
            notice: "Fail to find the stock. ",
            noticeType: "error",
            notify: true,
          },
        });
      }
      done();
    } catch (err) {
      dispatch({
        type: SEARCH_STOCK_FAIL,
        payload: {
          notice: "Fail to find the stock. ",
          noticeType: "error",
          notify: true,
        },
      });
      done();
    }
  },
});

export const searchLabelVibes = createVibes({
  type: [ENTER_NEW_LABEL],
  latest: true,
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { newLabel } = action.payload;
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const query = await axios.request({
        url: `/STK/${newLabel}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          rayLens,
        },
      });

      if (query.status < 400) {
        if (query.status === 204) {
          dispatch({ type: SEARCH_LABEL_SUCCESS, payload: { newLabel } });
        } else {
          dispatch({
            type: SEARCH_LABEL_FAIL,
            payload: {
              notify: true,
              notice: "This label is already used",
              noticeType: "error",
            },
          });
        }
      } else {
        dispatch({
          type: SEARCH_LABEL_FAIL,
          payload: {
            notify: true,
            notice: "This label is not valid",
            noticeType: "error",
          },
        });
      }
      done();
    } catch (err) {
      dispatch({
        type: SEARCH_LABEL_FAIL,
        payload: {
          notify: true,
          notice: "This label is not valid",
          noticeType: "error",
        },
      });
      done();
    }
  },
});

export const handleUpdateLabelVibes = createVibes({
  type: [SUBMIT_CHANGE_LABEL],
  latest: true,
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { searchedStock, newLabel } = getState().inventory;

      const result = await axios.request({
        url: "/api",
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          rayLens,
        },
        data: {
          query: `mutation($articleId: ID, $label: String) {
                    updateLabel(articleId: $articleId, label: $label)
                    {
                        id 
                    }
                  }`,
          variables: {
            filter: {
              articleId: searchedStock.id,
              label: newLabel,
            },
          },
        },
      });
      if (
        result.data &&
        result.data.updateLabel &&
        result.data.updateLabel.id
      ) {
        dispatch({
          type: SUBMIT_CHANGE_LABEL_SUCCESS,
          payload: {
            notice: "Successful to change the label. ",
            notify: true,
            noticeType: "success",
            changeLabelDialog: false,
            searchedStock: null,
            newLabel: null,
          },
        });
      } else {
        dispatch({
          type: SUBMIT_CHANGE_LABEL_ERROR,
          payload: {
            notice: "Fail to change the label. ",
            notify: true,
            noticeType: "error",
          },
        });
      }
      done();
    } catch (err) {
      dispatch({
        type: SUBMIT_CHANGE_LABEL_ERROR,
        payload: {
          notice: "Fail to change the label. ",
          notify: true,
          noticeType: "error",
        },
      });
      done();
    }
  },
});

export const fetchImagesVibes = createVibes({
  type: [OPEN_STOCK_IMAGES, OPEN_PROD_IMAGES, LOAD_CHANGE_LABEL_IMG],
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    const { id } = action.payload;
    const { token } = getState().auth;
    const { rayLens } = getState().system;

    let type = null;
    let length = 0;
    switch (action.type) {
      case OPEN_STOCK_IMAGES:
      case LOAD_CHANGE_LABEL_IMG:
        const { stocks } = getState().inventory;
        if (
          stocks &&
          stocks[id] &&
          stocks[id].attachments &&
          stocks[id].attachments.length > 0
        ) {
          length = stocks[id].attachments.length;
          type = "stock";
        }
        break;
      case OPEN_PROD_IMAGES:
        const { products } = getState().catalog;
        if (
          products &&
          products[id] &&
          products[id].assets &&
          products[id].assets.length > 0
        ) {
          length = products[id].assets.length;
          type = "product";
        }
        break;
      default:
    }

    if (length > 0) {
      let newDataUris = [];
      for (let k = 0; k < length; k++) {
        try {
          const data = await axios.request({
            url: `file/${type}/${id}/${k}`,
            method: "get",
            headers: { Authorization: `Bearer ${token}`, rayLens },
            responseType: "blob",
          });
          const response = data.request && data.request.response;
          const file = new Blob([response], { type: "image/png" });
          let fileURL = URL.createObjectURL(file);
          newDataUris.push(fileURL);
        } catch (err) {}
      }

      if (newDataUris.length > 0) {
        dispatch({ type: SET_DATA_URIS, payload: { dataUris: newDataUris } });

        if (
          action.type === OPEN_STOCK_IMAGES ||
          action.type === OPEN_PROD_IMAGES
        ) {
          dispatch({ type: OPEN_IMAGE_DIALOG });
        }
      }
    }
    done();
  },
});
