import {
    TOGGLE_GEARUP_DIALOG, 
    GEAR_UP_ITEM_SUCCESS,
    GEAR_UP_REMOVE_ITEM_ERROR, 
    GEAR_SELECT_ITEM,
    GEAR_UP_REMOVE_ITEM_SUCCESS,
    TOGGLE_GEARDOWN_DIALOG, 
    GEAR_DOWN_ITEM_SUCCESS, 
    RESET_GEAR_DOWN_FORM,
    OPEN_GEAR_NOTICE, 
    CLOSE_GEAR_NOTICE,
    RESET_GEAR_UP_FORM,
    GEAR_DOWN_REMOVE_ITEM_SUCCESS,
    UPDATE_GEAR_LABEL
} from "../actions";

const initialState = {
    notify: false,
    noticeType: 'info',
    notice: '',
    selected: -1,
    gearUpStocks: [],
    gearUpState: false,
    gearDownStocks: [],
    gearDownState: false,
    labels: [],
};

export const gearReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLOSE_GEAR_NOTICE:
            return { ...state, notify: false, noticeType: 'info', notice: '' };
        case TOGGLE_GEARUP_DIALOG:
            return { ...state, gearUpState: !state.gearUpState };
        case TOGGLE_GEARDOWN_DIALOG:
            return { ...state, gearDownState: !state.gearDownState };
        case UPDATE_GEAR_LABEL:
            return { ...state, labels: [action.payload, ...state.labels] };    
        case GEAR_UP_ITEM_SUCCESS:
        case GEAR_SELECT_ITEM:
        case GEAR_UP_REMOVE_ITEM_SUCCESS:
        case GEAR_UP_REMOVE_ITEM_ERROR:
        case OPEN_GEAR_NOTICE:
        case GEAR_DOWN_ITEM_SUCCESS:
        case GEAR_DOWN_REMOVE_ITEM_SUCCESS:
            return { ...state, ...action.payload };
        case RESET_GEAR_DOWN_FORM:
            return {
                ...state,
                gearDownStocks: [],
                selected: -1,
                gearDownState: false,
                labels: [],
            };
        case RESET_GEAR_UP_FORM:
            return {
                ...state,
                gearUpStocks: [],
                selected: -1,
                gearUpState: false,
                labels: [],
            };
        default:
            return state;
    }
};