import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableToolbar } from "@cosmos/chaos";
import Paper from "@material-ui/core/Paper";
import { LinearProgress, makeStyles, Input, alpha } from "@material-ui/core";
import { LOAD_SUPPLIES, UPDATE_SUPPLIES_SEARCH, IMPORT_SUMMARY, RESET_SUPPLIES_PROVISONS_FILTER, UPDATE_MARKETING_SECTION, SAVE_OVERVIEW_FILTER } from "../../../actions/marketing";
import { INIT_TRADER_LOAD } from "../../../actions";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import classNames from "classnames";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { SuppliesOverview } from "./datagrid";
import { ImportSummaryComponent } from "../importSummary";
import { useHistory } from "react-router-dom";
import { LOAD_SUPPLIES_PROVISIONS, SAVE_FAILED_SUPPLIES } from "../../../actions/marketing";
import { ChevronRightCircle, ChevronLeftCircle } from "mdi-material-ui";
import { SuppliesFilter } from "../SuppliesFilter";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "grid",
        gridTemplateColumns: "[left]3.5fr[aside]1.2fr[right]",
        gridTemplateRows: "[top]64px[head]auto[bottom]45px[footer]",
        padding: "0.5em",
    },
    minRoot: {
        gridTemplateColumns: "[left]4.6fr[aside]0.1fr[right]",
        transition: "linear",
    },
    leftAside: {
        gridTemplateColumns: "[right]1.2fr[aside]3.5fr[left]",
        // visibility: "hidden",
        // opacity: 0,
        // transition: "visibility 200s, opacity 100s linear",
    },
    content: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gridRowStart: "head",
        gridRowEnd: "bottom",
        gridColumnStart: "left",
        gridColumnEnd: "aside",
        "&$hideFooter": {
            gridRowEnd: "footer",
        },
        "&$hideAside": {
            gridColumnEnd: "right",
        },
    },
    hideFooter: {},
    hideAside: {},
    aside: {
        gridRowStart: "head",
        gridRowEnd: "bottom",
        gridColumnStart: "aside",
        gridColumnEnd: "right",
    },
    header: {
        position: "relative",
        gridRowStart: "top",
        gridRowEnd: "head",
        gridColumnStart: "left",
        gridColumnEnd: "right",
    },
    footer: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        gridRowStart: "bottom",
        gridRowEnd: "footer",
        gridColumnStart: "left",
        gridColumnEnd: "aside",
        justifyContent: "flex-end",
        alignItems: "center",
        "&$hideAside": {
            gridColumnEnd: "right",
        },
    },
    toolbar: {
        paddingLeft: "2em",
    },
    sectorRoot: {
        backgroundColor: alpha(theme.palette.primary.light, 0.25),
        "&$sectorSelected": {
            backgroundColor: theme.palette.success.light,
        },
    },
    sectorSelected: {},
    progress: {
        position: "absolute",
        bottom: "0",
        left: "0",
        width: "100%",
    },
    searchBox: {
        padding: "4px 4px 4px 60px",
    },
    filterBox: {
        marginRight: "1em",
    },
    filterItem: {
        marginLeft: "1em",
    },
    productSKU: {
        fontWeight: "500",
    },
    productRef: {
        fontSize: "50%",
    },
    colorBox: {
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    even: { backgroundColor: theme.palette.grey["50"] },
    odd: {},
    button: {
        margin: "0.5em"
    },
    icon: {
        margin: "10px",
        fontSize: "3em",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgb(100, 181, 246, .3)",
            borderRadius: "50%",
        }
    }
}));

export const SuppliesOverviewComponent = ({ props }) => {
    const styles = useStyles();
    const history = useHistory();
    // const location = useLocation();
    // const [section, setSection] = useState(
    //     location.state ? location.state.section : 20401
    // );

    const dispatch = useDispatch();
    const [showMore, setShowMore] = useState(true);
    const section = useSelector((state) => state.marketing.section);
    // const failedSupplies = useSelector((state) => state.marketing.failedSupplies);
    const suppliesProvisions = useSelector((state) => state.marketing.suppliesProvisions);
    const importInput = useSelector((state) => state.marketing.import);
    const searchText = useSelector((state) => state.catalog.searchText);
    const suppliesFilter = useSelector((state) => state.marketing.suppliesFilter);
    const loading = useSelector(
        (state) => state.catalog.loading || state.marketing.loading
    );
    const resetFilter = (type) => {
        dispatch({ type: RESET_SUPPLIES_PROVISONS_FILTER });
    };

    const [failedData, setFailedData] = useState([]);
    useEffect(() => {
        if (suppliesProvisions.length > 0) {
            const failedSuppliesData = suppliesProvisions.filter(f => (!f.productId));
            setFailedData(failedSuppliesData)
        }

    }, [suppliesProvisions]);

    const saveFailSupplies = () => {
        dispatch({ type: SAVE_FAILED_SUPPLIES, payload: failedData });
        history.push("/marketing/supplies/importsupplyoffers");
    };

    const sectors = useSelector((state) => state.marketing.sectors);

    useEffect(() => {
        //dispatch({ type: LOAD_SUPPLIES, payload: section });
        dispatch({ type: IMPORT_SUMMARY });
        dispatch({ type: INIT_TRADER_LOAD });
        dispatch({ type: RESET_SUPPLIES_PROVISONS_FILTER });
    }, [section, dispatch]);

    useEffect(() => {
        if (importInput.traderId && importInput.importDate) {
            dispatch({ type: LOAD_SUPPLIES_PROVISIONS, payload: importInput })
        }
    }, [dispatch, importInput])

    const handleSearch = (e) => {
        dispatch({ type: UPDATE_SUPPLIES_SEARCH, payload: e.target.value });
    };

    return (
        // <Paper className={classNames(styles.root)}>
        <Paper className={classNames(styles.root, ((!showMore && section === 20201) && styles.minRoot))}>
            <div className={styles.header}>
                <TableToolbar
                    numSelected={0}
                    title="Overview"
                    styles={{ root: styles.toolbar }}
                    searchBox={() => (
                        <Input
                            placeholder="Reference"
                            disableUnderline
                            value={searchText}
                            onChange={handleSearch}
                            className={styles.searchBox}
                            fullWidth
                        />
                    )}
                    filterBox={() => (
                        <div className={styles.filterBox}>
                            <ToggleButtonGroup
                                size="small"
                                value={section}
                                onChange={(event, newSection) => {
                                    // setSection(newSection);
                                    dispatch({ type: UPDATE_MARKETING_SECTION, payload: newSection })
                                }}
                                exclusive
                            >
                                {sectors.map((sector) => (
                                    <ToggleButton
                                        key={`sec-${sector.value}`}
                                        value={sector.value}
                                        classes={{
                                            root: styles.sectorRoot,
                                            selected: styles.sectorSelected,
                                        }}
                                    >
                                        {sector.label}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                            {section === 20201 && (
                                <Button
                                    className={styles.filterItem}
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        dispatch(push(`supplies/upcoming`))
                                    }
                                >
                                    + 3P Coming
                                </Button>
                            )}
                        </div>
                    )}
                    refreshTrigger={() => {
                        dispatch({ type: SAVE_OVERVIEW_FILTER, payload: {} });
                        dispatch({ type: LOAD_SUPPLIES, payload: section });
                    }}
                />
                {loading && <LinearProgress className={styles.progress} />}
            </div>
            <div
                className={classNames(
                    styles.content,
                    styles.hideAside,
                    (section === 20201) && styles.hideFooter,
                )}
            >
                {(section === 20401) ? <ImportSummaryComponent />
                    : <SuppliesOverview />
                }
            </div>
            {/* {section === 20201 && (
                <div>
                    {showMore ?
                        <div>
                            <ChevronRightCircle onClick={() => setShowMore(false)} className={classNames(styles.icon)} fontSize='small' color='primary' />
                            <SuppliesFilter showBrand={false}/>
                        </div>
                        :
                        <ChevronLeftCircle onClick={() => setShowMore(true)} className={classNames(styles.icon)} fontSize='small' color='primary' />
                    }
                </div>
            )} */}
            {section === 20401 &&
                (<div className={classNames(
                    styles.hideAside,
                    styles.footer
                )}>

                    <Button className={classNames(styles.button)} disabled={(importInput.traderId && importInput.importDate) ? false : true} onClick={() => resetFilter()} color="primary" variant="contained" size="small" >
                        Clear Filter
                        </Button>
                    <Button className={classNames(styles.button)} disabled={(importInput.traderId && importInput.importDate && failedData.length > 0) ? false : true} onClick={() => saveFailSupplies()} color="primary" variant="contained" size="small" >
                        Reimport Supplies Provisons
                        </Button>
                </div>)
            }
        </Paper>
    );
};
