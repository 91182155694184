import { createVibes } from "@cosmos/vibes";
import { SUBMIT_PRINTING, PRINTING_SUCCESS, PRINTING_ERROR, LOAD_PRINTERS_SUCCESS, LOAD_PRINTERS_FAIL, LOAD_PRINTERS } from "../actions";

export const submitPrintVibes = createVibes({
  type: SUBMIT_PRINTING,
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { selectedPrinter, selectedType } = getState().printing;
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const query = await axios.request({
        url: `/label/${selectedType}/${selectedPrinter}`,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          rayLens
        }
      });

      if (query.status < 400) {
        if (query.status === 200) {
          dispatch({ type: PRINTING_SUCCESS, payload: { notify: true, notice: 'The labels are printed successfully', noticeType: 'success' } });
        }
        else {
          dispatch({ type: PRINTING_ERROR, payload: { notify: true, notice: 'Fail to print the labels', noticeType: 'error' } });
        }
      }
      else {
        dispatch({ type: PRINTING_ERROR, payload: { notify: true, notice: 'Fail to print the labels', noticeType: 'error' } });
      }

      done();
    } catch (err) {
      dispatch({ type: PRINTING_ERROR, payload: { notify: true, notice: 'Fail to print the labels', noticeType: 'error' } });
      done();
    }
  }
});

export const loadPrintersVibes = createVibes({
  type: [LOAD_PRINTERS],
  latest: true,
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query{
            printers { id name type address model status companyId warehouseId }
          }`,
        }
      });

      let data = result.data;

      let printers = data.printers;
      if (printers) {
        dispatch({ type: LOAD_PRINTERS_SUCCESS, payload: { printers: printers } });
      }
      else {
        dispatch({ type: LOAD_PRINTERS_FAIL, payload: { notice: "Fail to load printers", notify: true, noticeType: 'error' } });
      }
      done();
    }
    catch (err) {
      dispatch({ type: LOAD_PRINTERS_FAIL, payload: { notice: "Fail to load printers", notify: true, noticeType: 'error' } });
      done();
    }
  }
});