import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './samDialog.module.scss';
import { CLOSE_SAM_DIALOG, DF_SCAN_CART_LABEL } from '../../../actions';
import { Dialog, DialogTitle, DialogActions, Button, TextField } from '@material-ui/core';

class SamDialogComponent extends Component {
  state = {
    cartLabel: ''
  }

  handleCancel = () => {
    this.setState({ cartLabel: '' });
    this.props.dispatch({ type: CLOSE_SAM_DIALOG, payload: { samDialog: false } });
  }

  handleConfirm = () => {
    this.props.dispatch({ type: DF_SCAN_CART_LABEL, payload: { search: this.state.cartLabel } });
    this.setState({ cartLabel: '' });
  }

  updateLabel = e => {
    this.setState({ cartLabel: e.target.value });
  }

  render() {
    const { samDialog } = this.props;
    const { cartLabel } = this.state;

    return (
      <Dialog maxWidth={'xl'} open={samDialog} className={styles["dialog"]}>
        <DialogTitle>Please enter the cart label: </DialogTitle>
        <div className={styles["content"]}>
          <TextField
            className={styles["text-field"]}
            label="Cart Label"
            InputLabelProps={{ shrink: true }}
            value={cartLabel}
            onChange={this.updateLabel}
          />
        </div>
        <DialogActions>
          <Button onClick={this.handleCancel} color='primary' variant='outlined' size='small'>
            Cancel
          </Button>
          <Button onClick={this.handleConfirm} color='primary' variant='contained' size='small' disabled={!cartLabel}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const SamDialog = connect(state => ({
  samDialog: state.dutyfree.samDialog,
}))(SamDialogComponent);