import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './tender.module.scss';
import classNames from 'classnames';
import {
  Authorization,
} from '@cosmos/chaos';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  INIT_TRADER_LOAD,
  CLOSE_TENDER_NOTICE,
  UPDATE_TENDER_INPUT,
  RESET_TENDER_INPUT,
  CREATE_TENDER_DETAIL,
  REMOVE_TENDER_DETAIL,
  SELECT_TENDER_DETAIL,
  UPDATE_TD_INPUT,
  SUBMIT_TENDER_FORM,
  ASK_INIT_TENDER,
  CANCEL_INIT_TENDER,
  CONFIRM_INIT_TENDER,
  INIT_USER_LOAD,
} from "../../actions";
import { InfoComponent, DetailComponent } from './components';
import { push } from 'connected-react-router';
import { PopulateDataDialog } from '../../components';
import { FocusDialog } from '../../components';

class TenderFormComponent extends Component {

  componentDidMount(){
    this.props.dispatch({ type: INIT_TRADER_LOAD, payload: {filter: { tier: 'CHANNEL'}} }); //for seller and channel name, info component
    if (localStorage.getItem('tenderInput')) {
      this.props.dispatch({ type: ASK_INIT_TENDER });
    }
    this.props.dispatch({ type: INIT_USER_LOAD });
  }

  closeNotification = () => {
    this.props.dispatch({ type: CLOSE_TENDER_NOTICE });
  };

  updateField = (value) => {
    this.props.dispatch({ type: UPDATE_TENDER_INPUT, payload: value });
  }

  handleCancel = () => {
    this.props.dispatch({ type: RESET_TENDER_INPUT });
    this.props.dispatch(push('/orders/tender'));
  }

  handleSubmit = () => {
    this.props.dispatch({ type: SUBMIT_TENDER_FORM });
  }

  createNewDetail = () => {
    this.props.dispatch({ type: CREATE_TENDER_DETAIL });
  }

  removeDetail = (idx) => {
    this.props.dispatch({ type: REMOVE_TENDER_DETAIL, payload: idx });
  }

  selectDetail = (idx) => {
    this.props.dispatch({ type: SELECT_TENDER_DETAIL, payload: idx });
  }

  updateTDField = (value) => {
    this.props.dispatch({ type: UPDATE_TD_INPUT, payload: value });
  }

  closeInitDialog = () => {
    this.props.dispatch({ type: CANCEL_INIT_TENDER });
  }

  confirmInit = () => {
    this.props.dispatch({ type: CONFIRM_INIT_TENDER });
  }

  render() {
    const {
      permission, tenderInput, currencies, users, products,
      selectedTdIdx, traders, paymentChannels, stores,
      countries, disableTender, initTenderDialog,
      notify, notice, noticeType,
    } = this.props;

    return (
      <Authorization allowed={['cosmos.tender.create']}>
        {({ permit }) => (permit(permission) &&
          <div className={styles.form}>
            <div className={styles.title}>
              <Typography variant="h6"> {'Create Tender'} </Typography>
            </div>
            <div className={classNames(styles['flex-row'], styles['flex-1'])}>
              <div className={classNames(styles['flex-column'], styles['left-container'])}>
                <div className={classNames(styles['flex-1'], styles['left-component'])}>
                  <InfoComponent
                    readOnly={false}
                    currencies={currencies}
                    updateField={this.updateField}
                    tenderInput={tenderInput}
                    users={users}
                    traders={traders}
                    stores={stores}
                    paymentChannels={paymentChannels}
                    countries={countries}
                  />
                </div>
              </div>
              <div className={classNames(styles['flex-column'], styles['right-container'])}>
                <DetailComponent
                  tenderInput={tenderInput}
                  createNewDetail={this.createNewDetail}
                  removeDetail={this.removeDetail}
                  products={products}
                  updateTDField={this.updateTDField}
                  selectDetail={this.selectDetail}
                  selectedTdIdx={selectedTdIdx}
                  currencySymbol={currencies[tenderInput.currency] ? currencies[tenderInput.currency].symbol : ""}
                />
              </div>
            </div>
            <div className={classNames(styles['flex-row'], styles['flex-1'], styles['flex-end'], styles['button-group'])}>
              <Button color="primary" variant="outlined" className={styles['button']} onClick={this.handleCancel}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" className={styles['button']} onClick={this.handleSubmit} disabled={disableTender}>
                Submit
              </Button>
            </div>
            <FocusDialog open={notify} message={notice} variant={noticeType} onClose={this.closeNotification}/>
            <PopulateDataDialog
              dialogOpen={initTenderDialog}
              closeDialog={this.closeInitDialog}
              handleConfirm={this.confirmInit}
            />
          </div>
        )}
      </Authorization>
    )
  }
}

export const TenderForm = connect(state => {
  return {
    permission: state.auth.permission,
    tenderInput: state.tender.tenderInput,
    selectedTdIdx: state.tender.selectedTdIdx,
    currencies: state.system.currencies,
    users: state.system.users,
    products: state.catalog.products,
    traders: state.trader.traders,
    paymentChannels: state.system.paymentChannels,
    countries: state.system.countries,
    disableTender: state.tender.disableTender,
    notify: state.tender.notify,
    notice: state.tender.notice,
    noticeType: state.tender.noticeType,
    initTenderDialog: state.tender.initTenderDialog,
    stores: state.system.stores,
  }
})(TenderFormComponent);