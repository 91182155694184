import { createVibes } from "@cosmos/vibes";
import {
  UPDATE_PROCUREMENT_STATE,
  UPDATE_PROCUREMENT_STATE_FAIL,
  UPDATE_PROCUREMENT_STATE_SUCCESS,
  CHANGE_PROCUREMENT_SUPPLIES,
} from "../actions";
import moment from "moment";

export const updateProcurementVibes = createVibes({
  type: [UPDATE_PROCUREMENT_STATE],
  latest: true,
  validate({ getState, action, client }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { state } = action.payload;
      const {
        selectedProcurementId,
        procurements,
        procurementStateList,
        reservationStateList,
      } = getState().reservation;

      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const variables = { procurementId: selectedProcurementId, state };

      const result = await axios.request({
        url: "/api",
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          rayLens,
        },
        data: {
          query: `mutation($procurementId: ID, $state: Int){
            updateProcurement(procurementId: $procurementId, state: $state){
              id productId brandId price quantity
              expireAt remarks state userId
              createdAt updatedAt deletedAt
              reservation {
                id pic userId reference 
                createdAt updatedAt deletedAt
                remarks state
                article { id }
                fulfilment { id selectedSuggestion}
              }
            }
          }`,
          variables,
        },
      });

      let data = result.data;

      let updateProcurement = data.updateProcurement;

      if (updateProcurement) {
        let newProcurements = { ...procurements };
        const { brands, products } = getState().catalog;

        newProcurements[selectedProcurementId] = {
          ...updateProcurement,
          brandName:
            brands[updateProcurement.brandId] &&
            brands[updateProcurement.brandId].name,
          productName:
            products[updateProcurement.productId] &&
            products[updateProcurement.productId].name,
          productReference:
            products[updateProcurement.productId] &&
            products[updateProcurement.productId].reference,
          stateName:
            procurementStateList[updateProcurement.state] &&
            procurementStateList[updateProcurement.state].name,
          createdAt: updateProcurement.createdAt
            ? moment(updateProcurement.createdAt).format("YYYY-MM-DD")
            : null,
          expireAt: updateProcurement.expireAt
            ? moment(updateProcurement.expireAt).format("YYYY-MM-DD")
            : null,
          reservationPic:
            updateProcurement.reservation && updateProcurement.reservation.pic,
          reservationReference:
            updateProcurement.reservation &&
            updateProcurement.reservation.reference,
          reservationState:
            updateProcurement.reservation &&
            updateProcurement.reservation.state,
          reservationStateName:
            updateProcurement.reservation &&
            reservationStateList[updateProcurement.reservation.state] &&
            reservationStateList[updateProcurement.reservation.state].name,
        };
        dispatch({
          type: UPDATE_PROCUREMENT_STATE_SUCCESS,
          payload: {
            notice: "Successful to update the state of procurement",
            notify: true,
            noticeType: "success",
            procurements: newProcurements,
          },
        });
      } else {
        dispatch({
          type: UPDATE_PROCUREMENT_STATE_FAIL,
          payload: {
            notice: "Fail to update the state of procurement",
            notify: true,
            noticeType: "error",
          },
        });
      }
      done();
    } catch (err) {
      dispatch({
        type: UPDATE_PROCUREMENT_STATE_FAIL,
        payload: {
          notice: "Fail to update the state of procurement",
          notify: true,
          noticeType: "error",
        },
      });
      done();
    }
  },
});

//todo
export const changeProcurementSuppliesVibes = createVibes({
  type: [CHANGE_PROCUREMENT_SUPPLIES],
  latest: true,
  validate({ getState, action, client }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client }, dispatch, done) {
    try {
      // const { selectedAlternative, procurementId } = action.payload;
      //TODO: submit this to the server
    } catch (err) {
      // dispatch({ type: UPDATE_PROCUREMENT_STATE_FAIL, payload: { notice: 'Fail to update the state of procurement', notify: true, noticeType: 'error' } });
      done();
    }
  },
});
