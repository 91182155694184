import 'cross-fetch/polyfill';

export class GraphQLClient {

  constructor(url, options) {
    this.url = url
    this.options = options || {}
    this.extensionHandler = options.extensionHandler;
  }

  async rawRequest(query, variables) {
    const { headers, ...others } = this.options

    const body = JSON.stringify({ query, variables: variables ? variables : undefined })

    const response = await fetch(this.url, {
      method: 'POST',
      headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
      body,
      ...others,
    })

    const result = await getResult(response)

    if (response.ok && !result.errors && result.data) {
      const { headers, status } = response
      return { ...result, headers, status }
    } else {
      const errorResult =
        typeof result === 'string' ? { error: result } : result
      throw new Error(
        { ...errorResult, status: response.status, headers: response.headers },
        { query, variables },
      )
    }
  }

  async request(query, variables) {
    return await new Promise(async (resolve, reject) => {
      const { headers, tokenInjector, extensionHandler, ...others } = this.options
      let transformedHeaders = headers;
      if (tokenInjector) {
        transformedHeaders = tokenInjector();
      }
      const body = JSON.stringify({ query, variables: variables ? variables : undefined })

      const response = await fetch(this.url, {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, transformedHeaders),
        body,
        ...others,
      })
      const result = await getResult(response)

      if (response.ok) {
        if (!result.errors) {
          if (result.extensions) {
            extensionHandler(result.extensions);
          }
          if (result.data) {
            resolve(result.data);
          } else {
            resolve();
          }
        } else {
          reject(result.errors);
        }
      } else {
        switch(response.status){
          case 401: 
            reject(response.status);
            break;
          case 500: 
            reject(response.status);
            break;
          default: 
            reject("NOT OK");
        }
      }
    }
    );
  }

  setHeaders(headers) {
    this.options.headers = headers

    return this
  }

  setHeader(key, value) {
    const { headers } = this.options

    if (headers) {
      headers[key] = value
    } else {
      this.options.headers = { [key]: value }
    }
    return this
  }
}

export async function rawRequest(url, query, variables) {
  const client = new GraphQLClient(url)

  return client.rawRequest(query, variables)
}

export async function request(url, query, variables) {
  const client = new GraphQLClient(url)

  return client.request(query, variables)
}

export default request

async function getResult(response) {
  const contentType = response.headers.get('Content-Type')
  if (contentType && contentType.startsWith('application/json')) {
    return response.json()
  } else {
    return response.text()
  }
}