import React, { Component } from "react";
import Paper from "@material-ui/core/Card";
import { connect } from "react-redux";
import Input from "@material-ui/core/Input";
import { Magnify, ChartDonut } from "mdi-material-ui";
import { CART_ADD_ITEM_BEGIN } from "../../../actions";
import { withStyles } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";
import { VOID_ADD_ITEM } from "../../../actions";

const styles = theme => ({ 
    search: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    header: {
        backgroundColor: `${theme.palette.primary.main}`,
        display: "flex",
        alignItems: "center",
        padding: "0.5em"
    },
    "search-bar": {
        flex: 1,
        display: "flex",
        alignItems: "center",
        borderRadius: "1em",
        paddingTop: "0.5em",
        paddingBottom: "0.5em"
    },
    "search-icon": {
        color: lighten(theme.palette.primary.light, 0.7),
        marginLeft: "1em",
        marginRight: "0.5em"
    },
    "search-input": {
        padding: "4px 4px 4px 10px"
    }
});

class SearchComponent_ extends Component {
    state = { searchText: "" };

    updateSearchText = (e) => {
        this.setState({ searchText: e.target.value });
    };

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            if(this.props.voidMode) {
                this.props.dispatch({ type: VOID_ADD_ITEM, payload: { search: this.state.searchText } });
            }else {
                this.props.dispatch({ type: CART_ADD_ITEM_BEGIN, payload: { search: this.state.searchText } });
            }
            this.setState({ searchText: "" });
        }
    };

    render() {
        const { searchText } = this.state;
        const { loading, classes } = this.props;
        return (
            <div className={classes.search}>
                <Paper className={classes.header}>
                    <div className={classes['search-bar']}>
                        <div className={classes['search-icon']}>
                            <Magnify />
                        </div>
                        <Input
                            placeholder="Search By Label"
                            disableUnderline
                            value={searchText}
                            onChange={this.updateSearchText}
                            onKeyDown={this.handleKeyDown}
                            className={classes['search-input']}
                            fullWidth
                        />
                    </div>
                    {/* <div className={classes['date-section']}>
                        <Typography variant="body1">
                            Date:
                        </Typography>
                        <Typography variant="body1">
                            {moment().format('DD-MMM-YYYY')}
                        </Typography>
                    </div> */}
                    {loading && <ChartDonut></ChartDonut>}
                </Paper>
            </div>
        );
    }
}

export const SearchComponent = (connect(state => ({
    loading: state.pos.loading,
    voidMode: state.pos.voidMode
}))(withStyles(styles)(SearchComponent_)));

