export const INIT_TRADER_LOAD = '@@trader/INIT_TRADER_LOAD';
export const LOAD_TRADER_SUCCESS = '@@trader/LOAD_TRADER_SUCCESS';
export const LOAD_TRADER_ERROR = '@@trader/LOAD_TRADER_ERROR';
export const TRADER_CREATE_NEW = '@@trader/TRADER_CREATE_NEW';
export const TRADER_CREATE_NEW_SUCCESS = '@@trader/TRADER_CREATE_NEW_SUCCESS';
export const TRADER_CREATE_NEW_FAIL = '@@trader/TRADER_CREATE_NEW_FAIL';
export const TRADER_CLEAR_ERRORS = '@@trader/TRADER_CLEAR_ERRORS';

export const loadTraderSuccess = payload => ({ type: LOAD_TRADER_SUCCESS, payload });
export const loadTraderFail = err => ({ type: LOAD_TRADER_ERROR, err });

export const APPEND_NEW_TRADER = '@@trader/APPEND_NEW_TRADER';
