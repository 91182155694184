import { createVibes } from "@cosmos/vibes";
import { INIT_PRICE_LOAD, loadPriceSuccess, loadPriceFail } from "../../actions";
import { reduce } from 'lodash';

export const loadPriceVibes = createVibes({
    type: [INIT_PRICE_LOAD],
    debounce: 500,
    latest: true,
    processOptions: {
        successType: loadPriceSuccess,
        failType: loadPriceFail
    },
    validate({ getState, action }, allow, reject) {
        let isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action);
        }
        else {
            reject(loadPriceFail(new Error('User does not login yet')));
        }
    },
    async process({ getState, action, client, axios }) {
        try {
            const { token } = getState().auth;
            const { rayLens } = getState().system;
      
            const result = await axios.request({
              url: '/api',
              method: 'post',
              headers: {
                'Authorization': `Bearer ${token}`,
                rayLens
              },
              data: {
                query: `query ($clientId: Int!, $productId: [ID], $filter: PriceTagFilter) {
                    priceTags(clientId: $clientId, productId: $productId, filter: $filter) {
                        id
                        userId
                        clientId
                        sourceId
                        channelId
                        type
                        productId
                        currencyId
                        currency { id name }
                        price
                        tax
                        amount
                        discount
                        targetId
                        validFrom
                        validTo
                    }
                }`,
                variables: {
                    clientId: 1000,
                    filter: { type: "SELL" }
                }
              }
            });
      
            let data = result.data;

            let payload = {};
            if (data.priceTags) {
                payload = { prices: reduce(data.priceTags, (r, p) => { r[p.productId] = p; return r }, {}) }
            }
            return payload;
        }
        catch (err) {
            throw err;
        }
    }
});
