import { createVibes } from "@cosmos/vibes";
import { INIT_TRADER_LOAD , loadTraderSuccess, loadTraderFail } from "../../actions";
import { reduce } from 'lodash';

export const loadTraderVibes = createVibes({
  type: [INIT_TRADER_LOAD],
  debounce: 500,  // normally used for HTTP request / db query / button clicking
  latest: true,
  processOptions: {
    successType: loadTraderSuccess,
    failType: loadTraderFail
  },
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(loadTraderFail(new Error('User does not login yet')));
    }
  },
  async process({ getState, action, client, axios }) {
    try {
      const result = await axios.request({
        url: '/api',
        method: 'post',
        data: {
          query: `query($filter: TraderFilter){
            traders(filter: $filter){
              id
              name
              type
              isoCode
              address
              email
              phone
              reputation
              status
              handlerId
              countryId 
              currencyId
            }
          }`,
          variables: action.payload
        }
      });

      let data = result.data;

      let traders = data.traders;
      if (traders) {
        return {
          traders: reduce(traders, (r, d) => { r[d.id] = d; return r }, {})
        }
      }
    } catch (err) {
      throw err;
    }
  }
});