import { createVibes } from "@cosmos/vibes";
import {
  UPDATE_TENDER_INPUT,
  CREATE_TENDER_DETAIL,
  REMOVE_TENDER_DETAIL,
  UPDATE_TD_INPUT,
  UPDATE_FF_INPUT,
  UPDATE_TENDER_DISABLE_BOOL,
  SUBMIT_TENDER_FORM,
  SUBMIT_TENDER_FORM_SUCCESS,
  SUBMIT_TENDER_FORM_ERROR,
  RESET_TENDER_INPUT,
  SELECT_TENDER,
  POPULATE_TENDER,
  UPDATE_DELETE_TENDER_DETAIL,
  RECORD_DELETE_TENDER_DETAIL,
  SAVE_UPDATED_TENDER,
  UPDATE_TENDER_FORM_SUCCESS,
  UPDATE_TENDER_FORM_ERROR,
  TRIGGER_DELETE_FULFILMENT,
  DELETE_FULFILMENT_SUCCESS,
  LOAD_SUGGESTIONS,
  CREATE_FULFILMENT,
  DELETE_FULFILMENT_FAIL,
  UPDATE_FULFILMENT,
  UPDATE_FULFILMENT_SUCCESS,
  UPDATE_FULFILMENT_FAIL,
  OPEN_TENDER_NOTICE,
} from "../actions";
import { push } from 'connected-react-router';
import { reduce } from 'lodash';
import moment from 'moment';

export const disableTenderVibes = createVibes({
  type: [
    UPDATE_TENDER_INPUT,
    CREATE_TENDER_DETAIL,
    REMOVE_TENDER_DETAIL,
    UPDATE_TD_INPUT,
    UPDATE_FF_INPUT,
    SUBMIT_TENDER_FORM,
    SUBMIT_TENDER_FORM_ERROR,
    SAVE_UPDATED_TENDER,
    CREATE_FULFILMENT,
    DELETE_FULFILMENT_SUCCESS,
  ],
  latest: true,
  async process({ getState }, dispatch, done) {
    try {
      const { submitted, tenderInput, selectedTdIdx, selectedTenderId } = getState().tender;
      let disableTender = false;

      if (submitted) {
        disableTender = true;
      }

      if (!disableTender && !tenderInput) {
        disableTender = true;
      }

      if (!disableTender && tenderInput && (!tenderInput.seller)) {
        disableTender = true;
      }

      if (!disableTender && tenderInput && (!tenderInput.currency)) {
        disableTender = true;
      }

      if (!disableTender && tenderInput && (!tenderInput.customer || !tenderInput.customer.name)) {
        disableTender = true;
      }

      if (!disableTender && tenderInput && (!tenderInput.channel)) {
        disableTender = true;
      }

      if (!disableTender && tenderInput && (!tenderInput.paidBy)) {
        disableTender = true;
      }

      if (!disableTender && tenderInput && (!tenderInput.expireAt)) {
        disableTender = true;
      }

      if (!disableTender && tenderInput && (!tenderInput.pic)) {
        disableTender = true;
      }

      if (!disableTender && tenderInput && (!tenderInput.details || tenderInput.details.length < 1)) {
        disableTender = true;
      }

      if (!disableTender && tenderInput && tenderInput.details) {
        for (let k = 0; k < tenderInput.details.length; k++) {
          if (selectedTenderId > 0) {
            if (!tenderInput.details[k].productId) {
              disableTender = true;
              break;
            }

            if (!disableTender && tenderInput.details[k].id && !tenderInput.details[k].quantity) {
              disableTender = true;
              break;
            }
          }
          else {
            if (!tenderInput.details[k].productId || !tenderInput.details[k].quantity) {
              disableTender = true;
              break;
            }
          }
        }
      }
      localStorage.setItem('tenderInput', JSON.stringify(tenderInput));
      localStorage.setItem('selectedTdIdx', JSON.stringify(selectedTdIdx));
      localStorage.setItem('submitted', JSON.stringify(submitted));
      localStorage.setItem('disableTender', JSON.stringify(disableTender));
      dispatch({ type: UPDATE_TENDER_DISABLE_BOOL, payload: { disableTender } });
      done();
    } catch (err) {
      throw err;
    }
  }
});

export const submitTenderVibes = createVibes({
  type: [SUBMIT_TENDER_FORM],
  latest: true,
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { tenderInput, tenderOnce, tenders, filteredTenders } = getState().tender;

      const { token } = getState().auth;
      const { rayLens } = getState().system;

      let marshalledTenderInput = {
        ...tenderInput,
        once: tenderOnce,
        details: tenderInput.details.map(el => {
          let newEl = { ...el, productId: el.productId }
          delete newEl.productDisplay;
          delete newEl.fulfilments;
          delete newEl.expanded;
          return newEl;
        }),
      };

      delete marshalledTenderInput.id;

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `mutation($tenderInput: TenderInput){
            createTender(tenderInput: $tenderInput){
              id reference channel channelReference customer
              seller userId pic 
              state currency forexSet
              tracking originalCountry deliveryCountry
              expireAt filedAt confirmedAt 
              paidBy remarks
              createdAt updatedAt deletedAt
              details {
                id productId brandId quantity
                price tax amount
                condition userId
                createdAt updatedAt deletedAt
                remarks
                fulfilments {
                  id reference pic type source state tracking
                  remarks userId createdAt updatedAt deletedAt
                }
              }
            }
          }`,
          variables: { tenderInput: marshalledTenderInput }
        }
      });

      let data = result.data;

      const createTender = data.createTender;
      if (createTender) {
        dispatch({
          type: SUBMIT_TENDER_FORM_SUCCESS, payload: {
            notify: true, noticeType: 'success', notice: 'Create tender successfully',
            filteredTenders: [...filteredTenders, createTender.id],
            tenders: { ...tenders, [createTender.id]: createTender }
          }
        });
        dispatch({ type: RESET_TENDER_INPUT });
        dispatch(push('/tender'));
      }
      else {
        dispatch({ type: SUBMIT_TENDER_FORM_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to create tender' } });
      }
      done();
    } catch (err) {
      dispatch({ type: SUBMIT_TENDER_FORM_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to create tender' } });
      done();
    }
  }
});

export const selectTenderVibes = createVibes({
  type: [
    SELECT_TENDER,
  ],
  latest: true,
  async process({ getState, action, client }, dispatch, done) {
    try {
      const { tenders, fulfilmentSuggestions } = getState().tender;
      const { traders } = getState().trader;
      const { products } = getState().catalog;

      const selectedTenderId = action.payload;
      const selectedTender = tenders[selectedTenderId];

      let customer = { id: null, name: null }
      if (isNaN(parseInt(selectedTender.customer))) {
        customer.name = selectedTender.customer;
      }
      else {
        customer.id = selectedTender.customer;
        customer.name = traders && traders[selectedTender.customer] && traders[selectedTender.customer].name;
      }

      let productIds = [];

      const tenderInput = {
        id: selectedTender.id,
        seller: selectedTender.seller,
        currency: selectedTender.currency,
        deliveryCountry: selectedTender.deliveryCountry,
        originalCountry: selectedTender.originalCountry,
        customer: customer,
        channel: selectedTender.channel,
        channelReference: selectedTender.channelReference,
        paidBy: selectedTender.paidBy,
        filedAt: selectedTender.filedAt,
        confirmedAt: selectedTender.confirmedAt,
        expireAt: selectedTender.expireAt,
        pic: selectedTender.pic,
        remarks: selectedTender.remarks,
        details: selectedTender.details.map(el => {
          productIds.push(el.productId);
          return {
            id: el.id,
            productId: el.productId,
            productDisplay: products && products[el.productId] && products[el.productId].reference,
            brandId: el.brandId,
            quantity: el.quantity,
            price: el.price,
            tax: el.tax,
            amount: el.amount,
            remarks: el.remarks,
            expanded: false,
            fulfilments: el.fulfilments.map(ff => {
              const suggestion = ff.remarks ? ff.remarks.selectedSuggestion && fulfilmentSuggestions && fulfilmentSuggestions.find(el => { return el.id === ff.remarks.selectedSuggestion }) : null;
              return {
                ...ff,
                selectedSuggestion: suggestion
              }
            })
          }
        })
      }

      if (productIds && productIds.length > 0) {
        dispatch({ type: LOAD_SUGGESTIONS, payload: { productIds, tiers: [1, 2, 3] } });
      }

      const selectedTdIdx = 0;

      localStorage.setItem('tenderInput', JSON.stringify(tenderInput));
      localStorage.setItem('selectedTdIdx', JSON.stringify(selectedTdIdx));
      dispatch({ type: POPULATE_TENDER, payload: { selectedTenderId, tenderInput, selectedTdIdx, deletedTenderDetails: [], disableTender: true } });
      done();
    } catch (err) {
      throw err;
    }
  }
});

export const recordDeletedTenderVibes = createVibes({
  type: [
    UPDATE_DELETE_TENDER_DETAIL,
  ],
  latest: true,
  async process({ getState, action, client }, dispatch, done) {
    try {
      const { tenderDetailId, idx } = action.payload;

      const { tenderInput, deletedTenderDetails } = getState().tender;

      if (tenderInput.id && tenderDetailId) {
        dispatch({ type: RECORD_DELETE_TENDER_DETAIL, payload: { deletedTenderDetails: [...deletedTenderDetails, tenderDetailId] } });
      }

      dispatch({ type: REMOVE_TENDER_DETAIL, payload: idx });
      done();
    } catch (err) {
      throw err;
    }
  }
});

export const updateTenderVibes = createVibes({
  type: [SAVE_UPDATED_TENDER],
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { tenderInput, tenderOnce, deletedTenderDetails, tenders } = getState().tender;
      
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      let marshalledTenderInput = {
        ...tenderInput,
        once: tenderOnce,
        currency: parseInt(tenderInput.currency),
        details: tenderInput.details && tenderInput.details.map(el => {
          let newEl = {
            ...el,
            fulfilments: el.fulfilments ? el.fulfilments.map(ff => {
              return {
                id: ff.id,
                remarks: ff.remarks,
                pic: ff.pic,
                selectedSuggestion: ff.remarks && ff.remarks.selectedSuggestion ? ff.remarks.selectedSuggestion.id : null,
              }
            }) : []
          }
          delete newEl.productDisplay;
          delete newEl.expanded;
          return newEl;
        }),
      };
      delete marshalledTenderInput.id

      const input = {
        tenderId: tenderInput.id,
        removed: deletedTenderDetails,
        updated: marshalledTenderInput
      }

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `mutation($input: updateTenderInput){
                    updateTender(input: $input){
                      id reference channel channelReference customer
                      seller userId pic 
                      state currency forexSet
                      tracking originalCountry deliveryCountry
                      expireAt filedAt confirmedAt 
                      paidBy remarks
                      createdAt updatedAt deletedAt
                      details {
                        id productId brandId quantity
                        price tax amount
                        condition userId
                        createdAt updatedAt deletedAt
                        remarks
                        fulfilments {
                          id reference pic type source state tracking
                          remarks userId createdAt updatedAt deletedAt
                        }
                      }
                    }
                  }`,
          variables: { input }
        }
      });

      let data = result.data;

      const updateTender = data.updateTender;
      if (updateTender) {
        dispatch({
          type: UPDATE_TENDER_FORM_SUCCESS, payload: {
            notify: true, noticeType: 'success', notice: 'Update tender successfully',
            tenders: {
              ...tenders,
              [updateTender.id]: {
                ...updateTender,
                expireAt: updateTender.expireAt ? moment(updateTender.expireAt).format("YYYY-MM-DD") : null,
                filedAt: updateTender.filedAt ? moment(updateTender.filedAt).format("YYYY-MM-DD") : null,
                confirmedAt: updateTender.confirmedAt ? moment(updateTender.confirmedAt).format("YYYY-MM-DD") : null,
                remarks: updateTender.remarks ? JSON.parse(updateTender.remarks) : null,
                sum: reduce(updateTender.details, (a, b) => { return a += b.amount }, 0),
                details: updateTender.details.map(el => {
                  return {
                    ...el,
                    remarks: el.remarks ? JSON.parse(el.remarks) : null,
                    fulfilments: el.fulfilments.map(ff => {
                      return {
                        ...ff,
                        remarks: ff.remarks ? JSON.parse(ff.remarks) : null,
                      }
                    })
                  }
                })
              }
            }
          }
        });
        dispatch({ type: RESET_TENDER_INPUT });
      }
      else {
        dispatch({ type: UPDATE_TENDER_FORM_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to update tender' } });
      }
      done();
    } catch (err) {
      dispatch({ type: UPDATE_TENDER_FORM_ERROR, payload: { notify: true, noticeType: 'error', notice: 'Fail to update tender' } });
      done();
    }
  }
});

export const handleDeleteFulfilmentVibes = createVibes({
  type: [
    TRIGGER_DELETE_FULFILMENT,
  ],
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { fulfilmentId, tenderDetailIdx, fulfilmentIdx } = action.payload;

      if (fulfilmentId) {

        const { token } = getState().auth;
        const { rayLens } = getState().system;

        const result = await axios.request({
          url: '/api',
          method: 'post',
          headers: {
            'Authorization': `Bearer ${token}`,
            rayLens
          },
          data: {
            query: `mutation($input: [ID]){
              deleteFulfilments(input: $input) 
            }`,
            variables: { input: [fulfilmentId] }
          }
        });
  
        let data = result.data;

        const deleteFulfilments = data.deleteFulfilments;

        if (deleteFulfilments) {
          dispatch({ type: DELETE_FULFILMENT_SUCCESS, payload: { tenderDetailIdx, fulfilmentIdx } });
          dispatch({ type: OPEN_TENDER_NOTICE, payload: { notify: true, noticeType: 'success', notice: 'Successful to delete fulfilment' } });
        }
        else {
          dispatch({ type: DELETE_FULFILMENT_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to delete fulfilment' } });
        }
      }
      else {
        dispatch({ type: DELETE_FULFILMENT_SUCCESS, payload: { tenderDetailIdx, fulfilmentIdx } });
      }
      done();
    } catch (err) {
      dispatch({ type: DELETE_FULFILMENT_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to delete fulfilment' } });
      done();
    }
  }
});

export const handleUpdateFulfilmentVibes = createVibes({
  type: [
    UPDATE_FULFILMENT,
  ],
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { tenderDetailIdx, fulfilmentIdx } = action.payload;
      const { tenderInput, fulfilmentSuggestions, selectedTenderId, tenders } = getState().tender;

      let selectedFulfilment = tenderInput.details && tenderInput.details[tenderDetailIdx] && tenderInput.details[tenderDetailIdx].fulfilments && tenderInput.details[tenderDetailIdx].fulfilments[fulfilmentIdx];

      const { token } = getState().auth;
      const { rayLens } = getState().system;

      let input = {
        id: selectedFulfilment.id,
        tenderDetailId: tenderInput.details && tenderInput.details[tenderDetailIdx] && tenderInput.details[tenderDetailIdx].id,
        remarks: { ...selectedFulfilment.remarks, selectedSuggestion: selectedFulfilment.selectedSuggestion && selectedFulfilment.selectedSuggestion.id },
        pic: selectedFulfilment.pic,
        // selectedSuggestion: selectedFulfilment.selectedSuggestion && selectedFulfilment.selectedSuggestion.id
      }

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `mutation($input: FulfilmentInput){
            updateFulfilment(input: $input){
              id reference pic type source state tracking
              remarks userId createdAt updatedAt deletedAt selectedSuggestion
            }
          }`,
          variables: { input }
        }
      });

      let data = result.data;

      const updateFulfilment = data.updateFulfilment;

      if (updateFulfilment) {
        // const detailProductId = tenderInput.details && tenderInput.details[tenderDetailIdx] && tenderInput.details[tenderDetailIdx].productId;
        // const suggestion = updateFulfilment.selectedSuggestion && detailProductId && fulfilmentSuggestions[detailProductId] && fulfilmentSuggestions[detailProductId].find(el => { return el.id === updateFulfilment.selectedSuggestion });
        // const suggestion = updateFulfilment.selectedSuggestion && fulfilmentSuggestions && fulfilmentSuggestions.find(el => { return el.id === updateFulfilment.selectedSuggestion });

        let resultRemarks = updateFulfilment.remarks ? JSON.parse(updateFulfilment.remarks) : null;
        const suggestion = resultRemarks ? resultRemarks.selectedSuggestion && fulfilmentSuggestions && fulfilmentSuggestions.find(el => { return el.id === resultRemarks.selectedSuggestion }) : null;
        const newTenderInput = {
          ...tenderInput,
          details: [
            ...tenderInput.details.slice(0, tenderDetailIdx),
            {
              ...tenderInput.details[tenderDetailIdx],
              fulfilments: [
                ...tenderInput.details[tenderDetailIdx].fulfilments.slice(0, fulfilmentIdx),
                {
                  ...updateFulfilment,
                  selectedSuggestion: suggestion,
                  remarks: updateFulfilment.remarks ? JSON.parse(updateFulfilment.remarks) : null
                },
                ...tenderInput.details[tenderDetailIdx].fulfilments.slice(fulfilmentIdx + 1),
              ]
            },
            ...tenderInput.details.slice(tenderDetailIdx + 1)
          ]
        }

        const newTenders = {
          ...tenders,
          [selectedTenderId]: {
            ...tenders[selectedTenderId],
            details: [
              ...tenders[selectedTenderId].details.slice(0, tenderDetailIdx),
              {
                ...tenders[selectedTenderId].details[tenderDetailIdx],
                fulfilments: [
                  ...tenders[selectedTenderId].details[tenderDetailIdx].fulfilments.slice(0, fulfilmentIdx),
                  {
                    ...updateFulfilment,
                    selectedSuggestion: suggestion,
                    remarks: updateFulfilment.remarks ? JSON.parse(updateFulfilment.remarks) : null
                  },
                  ...tenders[selectedTenderId].details[tenderDetailIdx].fulfilments.slice(fulfilmentIdx + 1),
                ]
              },
              ...tenders[selectedTenderId].details.slice(tenderDetailIdx + 1)
            ]
          }
        }

        dispatch({ type: UPDATE_FULFILMENT_SUCCESS, payload: { tenders: newTenders, tenderInput: newTenderInput, notify: true, noticeType: 'success', notice: 'Successful to update fulfilment' } });
      }
      else {
        dispatch({ type: UPDATE_FULFILMENT_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to update fulfilment' } });
      }
      done();
    }
    catch (err) {
      dispatch({ type: UPDATE_FULFILMENT_FAIL, payload: { notify: true, noticeType: 'error', notice: 'Fail to update fulfilment' } });
      done();
    }
  }
});
