import React from "react";
import * as PropTypes from 'prop-types';
import styles from "./brand.module.scss";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import {Checkbox} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";

export class BrandFilter extends React.Component {
    render() {
        const {selected, data, handleChange} = this.props;
        return (
            <div className={styles.root}>
                <FormControl className={styles.form}>
                    <InputLabel className={styles.label} classes={{shrink: styles.shrink}}>
                        Brand
                    </InputLabel>
                    <Select autoWidth
                            multiple
                            onChange={handleChange}
                            renderValue={() => (
                                (selected.length > 0) &&
                                <div className={styles['chip-bar']}>
                                    {selected.map(s => (
                                        <Chip className={styles.chip}
                                              label={data[s].code}
                                              variant="outlined"
                                              key={`chip_${s}`}/>
                                    ))}
                                </div>
                            )}
                            value={selected}
                            className={styles['select-ctrl']}
                            disableUnderline
                    >
                        {Object.keys(data).map(brand => (
                            <MenuItem className={styles['menu-item']} key={`brand-${brand}`} value={brand}>
                                <Checkbox checked={selected.indexOf(brand) > -1}/>
                                <ListItemText className={styles['menu-item-text']} primary={data[brand].name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}

BrandFilter.propTypes = {
    data: PropTypes.object.isRequired,
    selected: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired
};

