import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        border: '1px solid #ccc'
    },
    strongText: {
        fontSize: '1.1rem'
    }
})

export default function VendorInformation({vendorId, date, invoice}) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <p>Vendor ID: <strong className={classes.strongText}>{vendorId}</strong></p>
            <p>Date: <strong className={classes.strongText}>{date}</strong></p>
            <p>Invoice: <strong className={classes.strongText}>{invoice}</strong></p>
        </div>
    )
}
