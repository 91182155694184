import {
  PRINTING_SUCCESS,
  PRINTING_ERROR,
  CLOSE_PRINTING_NOTICE,
  UPDATE_SELECTION,
  LOAD_PRINTERS_SUCCESS,
  LOAD_PRINTERS_FAIL,
} from "../actions";

const initialState = {
  printingTypes: [
    {
      value: 'STK',
      label: 'Stock'
    },
    {
      value: 'DEL',
      label: 'Delivery'
    }
  ],
  printers: [],
  notify: false,
  noticeType: 'info',
  notice: '',
  selectedPrinter: '',
  selectedType: '',
};

export const printingReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRINTING_SUCCESS:
    case PRINTING_ERROR:
    case UPDATE_SELECTION:
    case LOAD_PRINTERS_SUCCESS:
    case LOAD_PRINTERS_FAIL:
      return { ...state, ...action.payload }
    case CLOSE_PRINTING_NOTICE:
      return { ...state, notify: false, noticeType: 'info', notice: '' }
    default:
      return state;
  }
};