import { useSelector } from 'react-redux'

export default function useVendorInformation() {
    const vendorInformation = useSelector(state => {
		const nullPurchaseOrder = {
			vendor: null,
			date: null,
			invoice: null,
			isConsigned: false,
			purchaseOrderId: null
		}
		if (state.purchase.stockInInput && state.purchase.stockInInput.vendor) return state.purchase.stockInInput
		
		const purchaseOrderId = state.purchase.selectedPurchaseId
		if (purchaseOrderId === -1) return nullPurchaseOrder
		const purchase = state.purchase.purchases[purchaseOrderId]
		if (!purchase) return nullPurchaseOrder
		return {
			purchaseId: purchase.id,
			vendor: purchase.vendor,
			invoice: purchase.reference,
			date: purchase.date,
			isConsigned: purchase.type === 'CO' ? true : false
		}
    })
    
    return vendorInformation
}