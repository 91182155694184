import React, { Component } from "react";
import { DatabaseRefresh, TableLarge } from "mdi-material-ui";
import { LegendView } from "../../components/legend/legend";
import moment from "moment";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "80%",
    height: "48px"
  },
  "footer-section": {
    display: "flex",
    marginRight: "1em",
    alignItems: "center"
  },
  "footer-item": {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    width: "16px",
    height: "16px",
    margin: "0 0.25em"
  }
});

export class FooterViewComponent extends Component {
  state = {
    begin: "",
    renderedTime: "",
    dataRenderedTime: ""
  };
  componentDidMount() {
    this.setState({ begin: moment() });
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      renderedTime: moment(this.state.begin).fromNow()
    });
    this.setState({
      dataRenderedTime: moment(this.props.dataRenderedTime).fromNow()
    });
  }

  timeDiff = (classes) => {
    return (
      <div className={classes["footer-section"]}>
        <div className={classes["footer-item"]}>
          <TableLarge className={classes.icon} />
          {this.state.renderedTime}
        </div>
        <div className={classes["footer-item"]}>
          <DatabaseRefresh className={classes.icon} />
          {this.state.dataRenderedTime}
        </div>
      </div>
    );
  };

  render() {
    const {
      showLegend,
      totalStockCount,
      totalGearUpCount,
      totalCost,
      classes
    } = this.props;
    return (
      <div className={classes.footer}>
        {this.timeDiff(classes)}
        <div className={classes.spacer} />
        <div className={classes["footer-section"]}>
          {showLegend && <LegendView />}
          <div className={classes["footer-item"]}>
            <div className={classes["footer-item"]}>
              Gear Up: {totalGearUpCount} Total: {totalStockCount} pcs @ DKK{" "}
              {Math.round(totalCost).toLocaleString("da")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const FooterView = withStyles(styles)(FooterViewComponent);
