import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import {createTheme, CssBaseline, MuiThemeProvider} from '@material-ui/core'
import {KeyScanner, DefaultDecoder} from '@cosmos/chaos'
import store, {history} from './store/index'
import {APP_INIT} from "./actions";
import {RayLayout} from "./RayLayout";
import {Splash} from "./containers/splash";
import {SnackbarProvider} from "notistack";

const MrzDecoder = (ctx, next) => {
    const {payload} = ctx;
    if (payload.length === 88 && payload[0] === 'P') {
        ctx.result = {mrz: payload}
    } else {
        next();
    }
};

const theme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Helvetica Neue',
            'Apple Color Emoji',
            'Arial',
            'sans-serif',
        ].join(','),
        fontSize: 12
    },
    spacing: 4,
    palette: {
        primary: {
            main: '#6495ed'
        },
        secondary: {
            main: '#ffb7c5',
        },
        heart: {
            secondHand: '#FFA500',
            consignment: '#9932CC',
            noUsed: '#1E90FF',
            used: '#FF1493'
        },
        common: {
            black: '#282828',
            white: '#fffafa'
        }
    },
    contrastThreshold: 3.2

});

store.dispatch({type: APP_INIT});

ReactDOM.render(
    <KeyScanner header={'\\(DI\\)|\\)DI\\='} decoders={[MrzDecoder, DefaultDecoder()]}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <MuiThemeProvider theme={theme}>
                    <SnackbarProvider anchorOrigin={{horizontal: "right", vertical: "top"}}>
                        <RayLayout/>
                        <CssBaseline/>
                        <Splash/>
                    </SnackbarProvider>
                </MuiThemeProvider>
            </ConnectedRouter>
        </Provider>
    </KeyScanner>,
    document.getElementById('root')
);

