import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './view.module.scss';
import {
    TableToolbar,
    DataGrid,
    authorized
} from '@cosmos/chaos';
import {
    Paper,
    LinearProgress,
    Input,
    Button
} from '@material-ui/core';
import { AutoSizer } from 'react-virtualized';
import { push } from 'connected-react-router';
import {
    UPDATE_SELECTED_RANGE,
    UPDATE_SELECTED_STATUS,
    UPDATE_SELECTED_TYPE,
    UPDATE_SEARCH_BAR,
    SWAP_MODE,
    INIT_PURCHASE_LOAD,
    INIT_TRADER_LOAD,
    CLOSE_STOCK_IN_NOTICE,
    REVIEW_PURCHASE
} from "../../actions";
import { ReviewPurchaseDialog } from './components/dialog/reviewPurchase';
import { FilterBar } from '../../components/tableFilter'
import { FocusDialog } from '../../components';
import { Route, Switch } from "react-router";
import { PurchaseForm } from "./form";
import { StockInForm } from '../stock';

class PurchaseComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orders: new Map(),
            loadTraders:false,
        }
    }

    componentDidMount() {   
        this.props.dispatch({ type: INIT_TRADER_LOAD, payload: { filter: { tier: 'EXTERNAL' } } }); //for vendor
    }

    componentDidUpdate(prevProps) {
        let prevObj= prevProps.traders;
        let obj=this.props.traders;
        if((prevObj !== obj) && !this.state.loadTraders) {
            this.setState({ loadTraders: true });
            this.props.dispatch({ type: INIT_PURCHASE_LOAD });
        }
    }

    updateSorting(key) {
        let orders = this.state.orders;
        if (orders.has(key)) {
            const dir = orders.get(key);
            if (dir === 'asc') {
                orders.set(key, 'desc');
            } else {
                orders.delete(key);
            }
        } else {
            orders.set(key, 'asc');
        }
        this.setState({ orders });
    }

    canCreatePO = authorized(['cosmos.po.create']);

    closeNotification = () => {
        this.props.dispatch({ type: CLOSE_STOCK_IN_NOTICE });
    };

    reviewPurchaseOrder = (id) => {
        this.props.dispatch({ type: REVIEW_PURCHASE, payload: id });
    }

    updateSearchText = (event) => {
        if (event.target.value !== undefined)
            this.props.dispatch({ type: UPDATE_SEARCH_BAR, payload: { searchText: event.target.value } });
    }

    handleStatusFilter = (e) => {
        this.props.dispatch({ type: UPDATE_SELECTED_STATUS, payload: { selectedStatuses: e.target.value } });
    };

    handleTypeFilter = (e) => {
        this.props.dispatch({ type: UPDATE_SELECTED_TYPE, payload: { selectedTypes: e.target.value } });
    };

    handleDateRangeFilter = range => {
        let dRange;
        if (range.start && range.end) {
            dRange = { start: range.start.utc(), end: range.end.utc() }
            dRange.start.startOf('day');
            dRange.end.endOf('day');
        } else if (range.start) {
            dRange = { start: range.start.utc(), end: null }
            dRange.start.startOf('day');
        } else if (range.end) {
            dRange = { start: null, end: range.end.utc() }
            dRange.end.endOf('day');
        } else {
            dRange = { start: null, end: null }
        }
        this.props.dispatch({ type: UPDATE_SELECTED_RANGE, payload: { selectedDateRange: dRange } });

    }

    togglePOType = () => {
        let updStatus = ["CLOSED"];
        if(this.props.isClosePO) {
            updStatus = [];
        }
        this.props.dispatch({ type: UPDATE_SELECTED_STATUS, payload: { selectedStatuses: updStatus } });
    }

    render() {
        const { rayLens, permission, purchases, filteredPurchases, loading, notice, noticeType, notify, purchaseStatusList, searchText, selectedStatuses, selectedTypes, selectedDateRange, isClosePO } = this.props;
        const { orders } = this.state;
        const purchaseTypes = {
            'CO': { label: 'Consignment', name: 'CONSIGNMENT' }, 
            'EX': { label: 'External', name: 'EXTERNAL' },
            'SI': { label: 'System Internal', name: 'SYSTEM INTERNAL' },
            'US': { label: 'Used Goods', name: 'USED GOODS' },
            'WI': { label: 'Walk In', name: 'WALK IN' }
        }

        const columns = [
            {
                name: 'indicator',
                header: '',
                width: 30,
            },
            {
                name: 'id',
                header: "ID",
                width: 80,
                onHeaderClick: () => {
                    this.updateSorting('id');
                },
            },
            {
                name: 'reference',
                header: "Reference",
                onHeaderClick: () => {
                    this.updateSorting('reference');
                },
            },
            {
                name: 'type',
                header: "Type",
                onHeaderClick: () => {
                    this.updateSorting('type');
                },
                cell: rowData => {
                    const typeDesc = { US: 'Used Goods', EX: 'External', CO: 'Consignment', WI: 'Walk In', SI: 'System Internal' };
                    return (
                        <div>
                            {typeDesc[rowData.type]}
                        </div>
                    )
                },
            },
            {
                name: 'date',
                header: "Date",
                onHeaderClick: () => {
                    this.updateSorting('date');
                },
            },
            {
                name: 'vendorName',
                header: "Vendor",
                onHeaderClick: () => {
                    this.updateSorting('vendorName');
                },
            },
            {
                name: 'statusName',
                header: "State",
                onHeaderClick: () => {
                    this.updateSorting('statusName');
                },
            },
            {
                name: 'createdAt',
                header: "Created At",
                onHeaderClick: () => {
                    this.updateSorting('createdAt');
                },
            }
        ];

        if (parseInt(rayLens) < 0) {
            columns.push(
                {
                    name: 'storeName',
                    header: "Store",
                    onHeaderClick: () => {
                        this.updateSorting('storeName');
                    },
                }
            )
        }

        const canCreatePO = this.canCreatePO(permission);
        let newItem = null;
        if (canCreatePO) {
            newItem = () => {
                this.props.dispatch({ type: SWAP_MODE, payload: false });
                this.props.dispatch(push('/orders/purchase/new'));
            }
        }

        let purchasesData = { ...purchases };
        purchasesData = Object.values(purchasesData);
        purchasesData = purchasesData.filter(el => {
            return filteredPurchases.indexOf(el.id) > -1
        });

        let actionBtns = () => (
            <div>
            {
                isClosePO ?
                <Button className={styles['btns']} variant="contained" color="default" onClick={this.togglePOType}>show opened po</Button> :
                <Button className={styles['btns']} variant="contained" color="primary" onClick={this.togglePOType}>show closed po</Button>
            }
            </div>
        );

        return (
            <Switch>
                <Route path="/orders/purchase/new">
                    <PurchaseForm />
                </Route>
                <Route path="/orders/purchase/stockIn">
                    <StockInForm />
                </Route>
                <Route path="/orders/purchase">
                    <div className={styles.purchase}>
                        <Paper className={styles.feature}>
                            <TableToolbar
                                numSelected={0}
                                title="Purchase"
                                styles={{ root: styles.toolbar, toolset: styles.toolset, messageBox: styles.messageBox }}
                                newItemTrigger={newItem}
                                searchBox={() => (
                                    <Input
                                        placeholder="ID / Reference"
                                        disableUnderline
                                        value={searchText}
                                        onChange={this.updateSearchText}
                                        className={styles['search-input']}
                                        fullWidth
                                    />
                                )}
                                filterBox={() => <FilterBar
                                    units={{
                                        status: {
                                            data: purchaseStatusList,
                                            selected: selectedStatuses,
                                            handleChange: this.handleStatusFilter
                                        },
                                        type: {
                                            data: purchaseTypes,
                                            selected: selectedTypes,
                                            handleChange: this.handleTypeFilter
                                        },
                                        dateRange: {
                                            range: selectedDateRange,
                                            handleChange: this.handleDateRangeFilter
                                        }
                                    }}
                                />}
                                toolBox={actionBtns}
                            />
                            {loading && <LinearProgress />}
                            <div className={styles.content}>
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <DataGrid
                                            data={purchasesData}
                                            columns={columns}
                                            width={width}
                                            maxHeight={height}
                                            height={height}
                                            includeHeaders={true}
                                            fixedRowCount={1}
                                            cellProps={{ style: { paddingLeft: 2, paddingRight: 4 } }}
                                            orderBy={orders}
                                            onCellClick={(column, rowData) => {
                                                this.reviewPurchaseOrder(rowData.id)
                                            }}
                                            classes={{
                                                sortableHeader: styles['header-sortable'],
                                                sortableHeaderActive: styles['header-sortable-active'],
                                                sortableHeaderIcon: styles['header-sortable-icon'],
                                                sortIndex: styles['header-sortable-index']
                                            }}
                                        />
                                    )}
                                </AutoSizer>
                            </div>
                        </Paper>
                        <FocusDialog open={notify} message={notice} variant={noticeType}
                            onClose={this.closeNotification} />
                        <ReviewPurchaseDialog />
                    </div>
                </Route>
            </Switch>
        )
    }
}

export const PurchaseView = connect(state => {
    let isClosePO = state.purchase.selectedStatuses.includes("CLOSED");
    return {
        permission: state.auth.permission,
        purchases: state.purchase.purchases,
        filteredPurchases: state.purchase.filteredPurchases,
        loading: state.purchase.loading,
        notify: state.purchase.notify,
        notice: state.purchase.notice,
        noticeType: state.purchase.noticeType,
        purchaseStatusList: state.purchase.purchaseStatusList,
        searchText: state.purchase.searchText,
        selectedStatuses: state.purchase.selectedStatuses,
        selectedTypes: state.purchase.selectedTypes,
        selectedDateRange: state.purchase.selectedDateRange,
        rayLens: state.system.rayLens,
        isClosePO: isClosePO,
        traders: state.trader.traders
    }
})(PurchaseComponent);