import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import styles from './component.module.scss';

export const TypeDialog = ({ dialogOpen, selectProforma, selectSales }) => (
  <Dialog maxWidth={"lg"} open={dialogOpen} className={styles['type-dialog']}>
    <DialogTitle>What type of sales is this? </DialogTitle>
    <DialogActions className={styles['btns']}>
      <Button onClick={selectProforma} color="primary" variant="outlined" size="small">
        Proforma
      </Button>
      <Button onClick={selectSales} color="primary" variant="contained" size="small" >
        Sales
      </Button>
    </DialogActions>
  </Dialog>
)