import shortid from 'shortid'
import { useState, useCallback } from 'react'

/**
 * @returns {[string, () => void]} [once, changeOnce]
 */
export default function useOnce() {
    const [once, setOnce] = useState(shortid.generate())
    const changeOnce = useCallback(() => setOnce(shortid.generate()), [setOnce])

    return [once, changeOnce]
}