import React, { useRef } from "react";
import { SelectModel, Input } from "../../../components/inputs";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { TrashCanOutline } from "mdi-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import NumberFormat from "react-number-format";

const useStyles = makeStyles({
  productTableLayout: {
    display: "grid",
    gridAutoRows: "1fr min-content",
    height: "100%",
    overflow: "hidden",
    gridAutoFlow: "row",
  },
  productTable: {
    height: "100%",
  },
  deleteButton: {
    padding: 0,
    outline: "none",
    border: "none",
    background: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableFooter: {
    display: "flex",
    paddingTop: "1em",
    paddingBottom: "1em",
    alignItems: "center",
    "& > *": {
      margin: "0 1em",
    },
    "& *:first-child": {
      marginRight: "auto",
    },
  },
});

export default function ProductTable({
  stocks,
  taxRate,
  setStocks,
  generateBlankStock,
  focusedIndex,
  setFocusedIndex,
  currency,
  priceIncludesTax = true,
  className,
}) {
  const handleStockChange = (index, name) => (e) => {
    try {
      e.persist();
    } catch (e) { }
    setStocks((prevStocks) => [
      ...prevStocks.slice(0, index),
      { ...prevStocks[index], [name]: e.target.value },
      ...prevStocks.slice(index + 1),
    ]);
  };

  const calUnitPrice = (index, msrp, discount, price) => {
    let newPrice = msrp * (1 - (discount / 100));
    if (price) {
      newPrice = price;
    }
    handleStockChange(index, "unitPrice")({ target: { value: newPrice } })
  };

  const addStock = () => {
    setStocks((prevStocks) => [...prevStocks, generateBlankStock()]);
    setFocusedIndex(stocks.length);
    if (bottomRef.current)
      setTimeout(() => bottomRef.current.scrollIntoView(), 0);
  };
  const deleteStock = (index) =>
    setStocks((prevStocks) => [
      ...prevStocks.slice(0, index),
      ...prevStocks.slice(index + 1),
    ]);
  const tableRef = useRef(null);
  const bottomRef = useRef(null);
  useOnClickOutside(tableRef, () => setFocusedIndex(-1));
  const classes = useStyles();
  return (
    <Paper className={`${className} ${classes.productTableLayout}`}>
      <TableContainer className={classes.productTable}>
        <Table size="small" stickyHeader ref={tableRef}>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>MSRP</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Tax</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Fulfil</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stocks.map((stock, index) => (
              <TableRow
                key={index}
                onClick={() => setFocusedIndex(index)}
                style={{
                  background:
                    index === focusedIndex
                      ? "rgba(0,0,0,0.1)"
                      : stock.isInvalid
                        ? "#fe7b7e"
                        : "inherit",
                }}
              >
                <TableCell>
                  {index === focusedIndex ? (
                    <button
                      onClick={() => deleteStock(index)}
                      className={classes.deleteButton}
                    >
                      <TrashCanOutline />
                    </button>
                  ) : (
                      index + 1
                    )}
                </TableCell>
                <TableCell style={{ minWidth: "200px" }}>
                  <SelectModel
                    value={stock.model}
                    onChange={(_, value, __) =>
                      handleStockChange(index, "model")({ target: { value } })
                    }
                  />
                </TableCell>
                <TableCell style={{ minWidth: "100px" }}>
                  <NumberFormat
                    placeholder="Price"
                    value={stock.unitPrice}
                    onValueChange={(val) => {
                      calUnitPrice(index, 0, 0, val.floatValue);
                    }}
                    thousandSeparator={","}
                    customInput={Input}
                  />
                </TableCell>
                <TableCell>
                  <NumberFormat
                    placeholder="Qty"
                    value={stock.quantity}
                    onValueChange={(val) =>
                      handleStockChange(
                        index,
                        "quantity"
                      )({ target: { value: val.floatValue } })
                    }
                    thousandSeparator={","}
                    customInput={Input}
                  />
                </TableCell>
                <TableCell>
                  <NumberFormat
                    placeholder="MSRP"
                    value={stock.msrp}
                    onFocus={e => e.target.select()}
                    onValueChange={(val) => {
                      let msrp = val.floatValue;
                      if (typeof (msrp) === 'undefined') {
                        msrp = 0;
                      }
                      handleStockChange(index, "msrp")({ target: { value: msrp } })
                      if (stock.discount && val.floatValue) {
                        calUnitPrice(index, msrp, stock.discount, 0);
                      }
                    }}
                    thousandSeparator={","}
                    customInput={Input}
                  />
                </TableCell>
                <TableCell>
                  <NumberFormat
                    placeholder="Discount"
                    value={stock.discount}
                    onFocus={e => e.target.select()}
                    onValueChange={(val) => {
                      let discount = val.floatValue;
                      if (typeof (discount) === 'undefined') {
                        discount = 0;
                      }
                      handleStockChange(index, "discount")({ target: { value: discount } })
                      if (stock.msrp && discount) {
                        calUnitPrice(index, stock.msrp, discount, 0);
                      }
                    }}
                    customInput={Input}
                  />
                </TableCell>
                <TableCell style={{ minWidth: "100px" }}>
                  <NumberFormat
                    placeholder="Amount"
                    value={(stock.unitPrice * stock.quantity).toString()}
                    disabled
                    thousandSeparator={","}
                    customInput={Input}
                  />
                </TableCell>
                <TableCell style={{ minWidth: "100px" }}>
                  <Input
                    placeholder="Tax"
                    value={
                      priceIncludesTax
                        ? (
                          stock.unitPrice * stock.quantity -
                          (stock.unitPrice * stock.quantity) /
                          (1 + taxRate / 100)
                        ).toString()
                        : 0
                    }
                    disabled
                  />
                </TableCell>
                <TableCell>
                  <Input
                    placeholder="Remarks"
                    value={stock.remarks}
                    onChange={handleStockChange(index, "remarks")}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    placeholder="Fulfilment"
                    value={stock.fulfilment}
                    onChange={handleStockChange(index, "fulfilment")}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow ref={bottomRef}></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.tableFooter}>
        <Button variant="contained" color="primary" onClick={addStock}>
          Add stock
        </Button>{" "}
        : <span></span>
        <p>
          Qty:{" "}
          <strong style={{ fontSize: "1.2rem" }}>
            {formatMoney(
              stocks.reduce(
                (total, currentStock) => total + currentStock.quantity,
                0
              ),
              0
            )}
          </strong>
        </p>
        <p>
          Total:{" "}
          <strong style={{ fontSize: "1.2rem" }}>
            {currency}{" "}
            {formatMoney(
              stocks.reduce(
                (total, currentStock) =>
                  total + currentStock.quantity * currentStock.unitPrice,
                0
              )
            )}
          </strong>
        </p>
      </div>
    </Paper>
  );
}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : "")
    );
  } catch (e) { }
}
