import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { AuthService } from '@cosmos/chaos';
import { createVibesMiddleware } from "@cosmos/vibes";
import { vibes } from "../vibes";
import { GraphQLClient } from '../vibes/graphql-request';
import { APP_API_EXTENSIONS, EXTENSION_RECEIVED } from "../actions";
import { Constants } from "../constants";
import createRootReducer from './root';
import Axios from "axios";

export const history = createBrowserHistory();
const baseURL = (process.env.NODE_ENV === 'development') ?
    process.env.REACT_APP_API_URL || '/v1/' :
    '/v1/'
const axios = Axios.create({baseURL});

axios.interceptors.request.use(config => {
    const token = store.getState().auth && store.getState().auth.token;
    if (token) config.headers.common['Authorization'] = `Bearer ${token}`;
    const lens = store.getState().system && store.getState().system.rayLens;
    if (lens) config.headers.common['rayLens'] = lens;
    return config;
});

axios.interceptors.response.use(res => {
    const { data, extensions, ...others } = res.data;
    if (extensions) {
        store.dispatch({ type: APP_API_EXTENSIONS, payload: extensions });
    }
    const next = { ...res, data, ...others }; //need others like error object
    return next;
});

const deps = {
    axios,
    client: new GraphQLClient(Constants.endpoint, {
        tokenInjector: () => {
            let jwtToken = store.getState().auth && store.getState().auth.token;
            let rayLens = store.getState().system && store.getState().system.rayLens;
            return {
                authorization: `Bearer ${jwtToken}`,
                rayLens: rayLens
            }
        },
        extensionHandler: (extensions) => {
            store.dispatch({ type: EXTENSION_RECEIVED, payload: extensions });
        }
    })
};

if (process.env.NODE_ENV === 'development') {
    AuthService.authDomain = process.env.REACT_APP_AUTH_DOMAIN;
}
const vibesMiddleware = createVibesMiddleware(vibes, deps);

const middleware = [
    vibesMiddleware,
    routerMiddleware(history),
];
const initialState = { auth: { system: 'cosmos' } };
const enhancers = [];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window['__REDUX_DEVTOOLS_EXTENSION__'];
    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
);

const store = createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
);

export default store