import React, { Component } from "react";
import styles from './inventorypocheck.module.scss';
import {
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    Input,
    DialogTitle,
    TextField,
    InputLabel,
    Switch
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider';
import { SmartBox } from '@cosmos/chaos';

export class InventoryPOCheckDialog extends Component {

    updateInputField = (value) => {
        this.props.updateField(value);
    }

    render() {
        const {
            dialogOpen,
            dialogOnClose,
            handleSubmit,
            referenceNo,
            updatePurchaseRef,
            traders,
            createVendor,
            stockInInput,
            ableSubmit
        } = this.props
       
        return (
            <div > 
                <Dialog
                    className={styles['dialog']}
                    open={dialogOpen}
                    onClose={dialogOnClose}
                    disableBackdropClick
                >
                    <div className={styles['heading']}>
                    <h3 className={styles['heading_text']}>Please Process by scanning PO Reference or filling Vendor, Date and Invoice Reference Number</h3>
                    </div>
                    <DialogTitle className={styles['title']}> Scan Purchase Reference </DialogTitle>
                    <DialogContent className={styles['content']}>
                        <Input
                            value={referenceNo ? referenceNo : ""}
                            fullWidth
                            onChange={updatePurchaseRef}
                        />
                    </DialogContent>
                    <div className={styles['divider_box']}>
                        <Divider />
                    </div>
                    <DialogTitle className={styles['title']}> Enter Following Information </DialogTitle>
                    <DialogContent className={styles['content']}>
                        <div className={styles['content_inner']}>
                            <InputLabel shrink>Vendor</InputLabel>
                            <SmartBox
                                suggestionProvider={val => {
                                    let filteredTraders = Object.values(traders);
                                    let count = 0;
                                    let result = filteredTraders.filter(el => {
                                        const keep = el.name.includes(val) && count < 10;
                                        if (keep) {
                                            count += 1;
                                        }
                                        return keep;
                                    }).map(el => ({ label: el.name, value: el.id, ...el }));
                                    return result;
                                }}
                                searchText={stockInInput.vendor && traders[stockInInput.vendor] ? traders[stockInInput.vendor].name : (stockInInput.vendor ? stockInInput.vendor : '')}
                                updateSearchText={val => {
                                    if (typeof val === 'object') {
                                        this.updateInputField({ vendor: val.id});
                                    }
                                    else {
                                        this.updateInputField({ vendor: val});
                                    }
                                }}
                                updateSelectedItem={val => {
                                    this.updateInputField({ vendor: val.id});
                                }}
                                onOuterClick={createVendor}
                            />
                        </div>
                        <TextField
                            id="invoice" label="Invoice"
                            value={(stockInInput && stockInInput.invoice) ? stockInInput.invoice : ''}
                            className={styles['content_inner']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.updateInputField({ invoice: e.target.value }) }}
                        />
                        <TextField
                            label="Date"
                            type="date"
                            value={(stockInInput && stockInInput.date) ? stockInInput.date : ''}
                            className={styles['content_inner']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { this.updateInputField({ date: e.target.value }) }}
                        />
                        <div className={styles['content_inner']}>
                        <InputLabel shrink>Consignment</InputLabel>
                        <Switch
                            checked={stockInInput && stockInInput.isConsigned ? stockInInput.isConsigned : false}
                            onChange={(e) => { this.updateInputField({ isConsigned: e.target.checked }) }}
                            value="consignment"
                        />
                        </div>
                    </DialogContent>
                    <DialogActions className={styles['buttons']}>
                        <Button
                            disabled={ableSubmit}
                            onClick={handleSubmit} color='primary' variant='contained' size='small'>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}