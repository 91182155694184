import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { generateInitialForm, generateInitialError, validateForm } from './functions/formServices'
import AutoComplete from '@material-ui/lab/Autocomplete'
import {Input} from '../../components/inputs'
import OrderDetails from './components/OrderDetails'
import Button from '@material-ui/core/Button'
import InputWithError from './components/InputWithError'
import { useSelector } from 'react-redux'
import shortid from 'shortid'
import ExecuteProformaDialog from './components/ExecuteProformaDialog'
import PrintBlankFormDialog from './components/PrintBlankFormDialog'
import {ConfirmDialog} from '../../components/dialog'
import SuccessCard from './components/SuccessCard'
import useHeaders from '../../hooks/useHeaders'
import PersonalInformationForm from './components/PersonalInformationForm'
import ProductDisplay from './components/ProductDisplay'
import useDialog from '../../hooks/useDialog'
import createPOandWI from './functions/createPOandWI'

const useStyles = makeStyles(theme => ({
    form: {
        display: 'grid',
        gridTemplateColumns: '250px 1fr 250px',
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        overflow: 'hidden'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        margin: '20px',
        padding: '20px',
        justifyContent: 'space-around'
    },
    personalInformation: {
        padding: '10px',
        paddingLeft: '20px',
        borderRight: '1px solid #ccc',
        overflow: 'scroll'
    },
    orderDetails: {
        padding: '20px',
        height: '100%',
        display: 'grid',
        overflow: 'scroll',
        gridTemplateRows: 'min-content auto min-content min-content',
        gridAutoFlow: 'row',
        borderRight: '1px solid #ccc'
    },
    orderDetailsHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    outlinedInput: {
        border: '1px solid #ccc'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button:not(:last-child)': {
            marginRight: '10px'
        }
    },
}))

export function WalkInComponent() {
    const [inputs, setInputs] = useState(generateInitialForm())
    const [isInitDialogOpen, setIsInitDialogOpen] = useState(false)

    useEffect(() => {
        const data = localStorage.getItem('walkinData')

        if (data !== JSON.stringify(generateInitialForm())) {
            setIsInitDialogOpen(true)
        }
    }, [])

    const initializeFormWithData = () => {
        const data = JSON.parse(localStorage.getItem('walkinData'))
        setInputs(data)
    }

    const handleChange = name => e => {
        try {
            e.persist()
        } catch (e) { // not all events emitted need to persist
        }

        setInputs(prevInputs => {
            const updatedInputs = {
                ...prevInputs,
                [name]: e.target.value
            }

            // make sure that error object is also updated correct if orderDetails changes
            if (updatedInputs.orderDetails.length !== prevInputs.orderDetails.length) {
                const updatedErrors = generateInitialError(updatedInputs.orderDetails.length)
                setFormErrors(prevErrors => ({ ...updatedErrors, ...prevErrors }))
            }

            localStorage.setItem('walkinData', JSON.stringify(updatedInputs))
            return updatedInputs
        })
    }

    const [displayedProduct, setDisplayedProduct] = useState(null)
    const showProductDetails = useCallback(product => {
        setDisplayedProduct(product)
    }, [])

    const headers = useHeaders()
    const [once, setOnce] = useState(shortid.generate())
    const [walkInData, setWalkInData] = useState(null)
    const [purchaseOrderData, setPurchaseOrderData] = useState(null)
    const [formErrors, setFormErrors] = useState(generateInitialError())
    const [isLoading, setIsLoading] = useState(false)

    const {openDialog: openErrorDialog, Dialog: ErrorDialog} = useDialog()

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);
        setFormErrors(generateInitialError(inputs.orderDetails.length))

        const validationError = validateForm(inputs)
        if (validationError) {
            setIsLoading(false)
            return setFormErrors(validationError)
        }

        // SUBMIT PROFORMA PURCHASE
        const {error, data} = await createPOandWI(inputs, once, headers)
        if (error) {
            setOnce(shortid.generate())
            setIsLoading(false)
            openErrorDialog(error.message)
            return
        }

        const { walkInPurchaseData, purchaseOrderData } = data
        setPurchaseOrderData(purchaseOrderData)
        setWalkInData(walkInPurchaseData)
    }

    const [isResetDialogOpen, setIsResetDialogOpen] = useState(false) // for resetting form
    const [isExecuteProformaDialogOpen, setIsExecuteProformaDialogOpen] = useState(false) // for executing proforma purchase order
    const [isBlankFormDialogOpen, setIsBlankFormDialogOpen] = useState(false)
    const currencies = useSelector(state => Object.values(state.system.currencies))
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <ConfirmDialog
                title={"Initialize data"}
                handleClose={() => setIsInitDialogOpen(false)}
                isOpen={isInitDialogOpen}
                onConfirm={initializeFormWithData}
            >
                Do you want to use previous saved inputs?
            </ConfirmDialog>
            <ConfirmDialog
                title={"Reset form"}
                handleClose={() => setIsResetDialogOpen(false)}
                isOpen={isResetDialogOpen}
                onConfirm={() => setInputs(generateInitialForm())}
            >
                Are you sure you want to reset form?
            </ConfirmDialog>
            <ErrorDialog />
            <ExecuteProformaDialog
                isOpen={isExecuteProformaDialogOpen}
                handleClose={() => setIsExecuteProformaDialogOpen(false)}
            />
            <PrintBlankFormDialog
                isOpen={isBlankFormDialogOpen} handleClose={() => setIsBlankFormDialogOpen(false)}
            />
            <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.personalInformation}>
                    <PersonalInformationForm onChange={handleChange} formErrors={formErrors} inputs={inputs} setFormErrors={setFormErrors} />
                </div>
                <div className={classes.orderDetails}>
                    <div className={classes.orderDetailsHeader}>
                        <h2>Order details</h2>
                        <InputWithError error={!!formErrors.currency} errorMessage={formErrors.currency} fullWidth={false}
                            render={props => (
                                <AutoComplete
                                    options={currencies}
                                    getOptionLabel={option => option.code || ''}
                                    onChange={(event, value, reason) => handleChange('currency')({ target: { value } })}
                                    value={inputs.currency}
                                    renderInput={params => <Input {...params} {...props} label="Currency" separateLabel />}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <OrderDetails
                            orderDetails={inputs.orderDetails}
                            currency={inputs.currency}
                            onChange={handleChange('orderDetails')}
                            showProductDetails={showProductDetails}
                            errors={formErrors.orderDetails}
                            updateErrors={newErrors => {
                                setFormErrors(prevErrors => ({
                                    ...prevErrors,
                                    orderDetails: newErrors
                                }))
                            }}
                        />
                    </div>
                    <div className={classes.actionButtons}>
                        <Button variant="contained" color="primary" type="submit" disabled={isLoading}>{!isLoading ? 'Submit' : 'Loading'}</Button>
                        <Button variant="contained" type="button" onClick={() => setIsResetDialogOpen(true)}>Reset Form</Button>
                        <Button variant="contained" type="button" onClick={() => setIsBlankFormDialogOpen(true)}>Print blank form</Button>
                        <Button variant="contained" type="button" onClick={() => setIsExecuteProformaDialogOpen(true)}>Scan label</Button>
                    </div>

                    <div className={classes.row}>
                        {walkInData ? (
                            <SuccessCard title="Walk In Purchase" {...walkInData} />
                        ) : null}
                        {walkInData ? (
                            <SuccessCard title="Proforma Purchase" {...purchaseOrderData} />
                        ) : null}
                    </div>
                </div>
                <div>
                    {displayedProduct && <ProductDisplay product={displayedProduct} />}
                </div>
            </form>
        </div>
    )
}