import React from "react";
import {
  Typography,
  Button,
} from "@material-ui/core";
import { Delete } from "mdi-material-ui";

class IndexCell extends React.Component {
  state = {
    hover: false
  }

  hoverOn = () => {
    this.setState({ hover: true });
  }

  hoverOff = () => {
    this.setState({ hover: false });
  }

  render() {
    const { index, handleDeleteRow, readOnly } = this.props;
    const { hover } = this.state;
    return (
      <div onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
        <Button color="primary" size="small" onClick={handleDeleteRow} disabled={readOnly}>
          {hover ? <Delete /> : <Typography >
            {index + 1}
          </Typography>}
        </Button> 
      </div>
    )
  }

}

export default IndexCell;
