import React, { Component } from "react";
import { DialogContent, Button, Dialog, DialogActions, } from "@material-ui/core";
import { Slider } from "../slider";

class ImageDialog_ extends Component {

  render() {
    const { dialogOpen, closeDialog, dataUris } = this.props;
    return (
      <Dialog maxWidth={"lg"} open={dialogOpen} onClose={closeDialog}>
        <DialogContent>
          <Slider assets={dataUris} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined" size="small" >
            close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const ImageDialog = ImageDialog_;