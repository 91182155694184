import React from 'react';
import { Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';

class TempImgDialog_ extends React.Component {

  render() {
    const { tempUri, tempTitle, dialogOpen, retakePhoto, closeDialog } = this.props;

    return (
      <Dialog maxWidth={"xl"} open={dialogOpen}>
        <DialogTitle>Preview of {tempTitle} photo </DialogTitle>
        <DialogContent>
          <img src={tempUri} height={720} width={1280} alt={'preview'} />
          <Typography variant="body2">If you want to replace this photo, please click on the Retake button.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color='primary' variant='outlined' size='small'>
            Close
          </Button>
          <Button onClick={retakePhoto} color='primary' variant='contained' size='small'>
            Retake
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const TempImgDialog = TempImgDialog_;