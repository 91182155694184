import React, { Component } from 'react';
import { IconButton, SnackbarContent } from "@material-ui/core";
import classNames from 'classnames';
import { CheckCircle, AlertCircle, Information, Alert, Close } from 'mdi-material-ui';
import * as PropTypes from "prop-types";
import { withStyles } from '@material-ui/styles';

const variantIcon = {
  success: CheckCircle,
  warning: Alert,
  error: AlertCircle,
  info: Information
};

const styles = theme => ({
  "icon": {
    fontSize: "20px"
  },
  "icon-variant": {
    opacity: "0.9",
    marginRight: "0.5em"
  },
  "notification-content": {
    "&.success": {
      backgroundColor: "#43a047"
    },
    "&.error": {
      backgroundColor: "#d32f2f"
    },
    "&.info": {
      backgroundColor: "#1976d2"
    },
    "&.warning": {
      backgroundColor: "#ffa000"
    }
  },
  "message": {
    display: "flex",
    alignItems: "center"
  }
})

export class Notification_ extends Component {
  render() {

    const { classes, message, className, variant, onClose, ...other } = this.props;
    const Icon = variantIcon[variant];

    return (
      <SnackbarContent
        className={classNames(variant, classes['notification-content'], className)}
        message={
          <span className={classes.message}>
            <Icon className={classNames(classes.icon, classes["icon-variant"])} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <Close className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    )
  }
}

export const Notification = withStyles(styles)(Notification_);

Notification.propTypes = {
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'info', 'error']).isRequired
};
