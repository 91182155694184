import React from "react";
import {
  ListItem,
  List,
  Typography,
  makeStyles
} from "@material-ui/core";
import classNames from 'classnames';

const useStyles = makeStyles(theme=>({

  root: {
    marginTop: "1rem"
  },
  header: {
      justifyContent: "space-between"
  },
  "flex-row": {
    display: "flex",
    flexDirection: "row"
  },
  "padding-0": {
    padding: 0
  },
  "flex-grow-1": {
    flex: 1
  },
  "header-inner": {
    background: "#33ab9f"
  },
  "heading-cell": {
    height: "1rem",
    fontWeight: "bold",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    "&:last-child": {
        borderRight: "1px solid black"
    }
  },
  "padding-1rem": {
    padding: "1rem"
  },
  idx: {
    width: "3rem"
  },
  product: {
    flex: 1
  },
  qty: {
    width: "2rem"
  },
  price: {
    width: "6rem"
  },
  tax: {
    width: "6rem"
  },
  amount: {
    width: "6rem"
  },
  remarks: {
    width: "10rem"
  }

}));

export const HeaderRow = () => {
  const styles = useStyles();
  return (
    <div className={classNames(styles['root'])}>
      <List className={classNames(styles["header"], styles["flex-row"], styles["padding-0"])}>
        <ListItem className={classNames(styles["flex-grow-1"], styles["padding-0"], styles["header-inner"])}>
          <Typography variant="subtitle1" className={classNames(styles["heading-cell"], styles["padding-1rem"], styles.idx)}> Item </Typography>
          <Typography variant="subtitle1" className={classNames(styles["heading-cell"], styles["padding-1rem"], styles.product)}> Product*</Typography>
          <Typography variant="subtitle1" className={classNames(styles["heading-cell"], styles["padding-1rem"], styles.qty)}> Qty*</Typography>
          <Typography variant="subtitle1" className={classNames(styles["heading-cell"], styles["padding-1rem"], styles.price)}> Price </Typography>
          <Typography variant="subtitle1" className={classNames(styles["heading-cell"], styles["padding-1rem"], styles.tax)}> Tax </Typography>
          <Typography variant="subtitle1" className={classNames(styles["heading-cell"], styles["padding-1rem"], styles.amount)}> Amount </Typography>
          <Typography variant="subtitle1" className={classNames(styles["heading-cell"], styles["padding-1rem"], styles.remarks)}> Remarks </Typography>
        </ListItem>
      </List>
    </div>
  )
}