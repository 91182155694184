import {
  INV_LOAD_BEGIN,
  INV_LOAD_SUCCESS,
  INV_LOAD_ERROR,
  OPEN_STOCKCHECK_NOTICE,
  CLOSE_STOCKCHECK_NOTICE,
  UPDATE_SEARCH_LABEL,
  SCAN_STOCK_CHECK,
  CLEAR_STOCK_LABEL,
  ADD_SCANNED_STOCK,
  UPDATE_SELECTED_DATE,
  UPDATE_HISTORY,
} from '../actions'

const initialState = {
  loading: false,
  notify: false,
  noticeType: 'info',
  notice: '',
  scannedStocks: [],
  searchLabel: '',
  selectedDateRange: { start: null, end: null },
  histories: [],
}

export const stockcheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case INV_LOAD_BEGIN:
      return { ...state, loading: true }
    case INV_LOAD_SUCCESS:
    case INV_LOAD_ERROR:
      return { ...state, loading: false }
    case UPDATE_SEARCH_LABEL:
    case SCAN_STOCK_CHECK:
    case CLEAR_STOCK_LABEL:
    case ADD_SCANNED_STOCK:
    case OPEN_STOCKCHECK_NOTICE:
    case UPDATE_SELECTED_DATE:
    case UPDATE_HISTORY:
      return { ...state, ...action.payload }
    case CLOSE_STOCKCHECK_NOTICE:
      return { ...state, notify: false, noticeType: 'info', notice: '' }
    default:
      return state
  }
}