import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

/**
 * Dialog that runs action if user confirms, or does nothing otherwise
 * @param {Object} props 
 * @param {function} props.handleClose - Function to run when closing dialog
 * @param {boolean} props.isOpen - Whether the dialog is open
 * @param {function} props.onConfirm - Function to run if user confirms
 * @param {string} props.title - Title of the dialog
 * @param {JSX.Element} props.children - Body content
 * @param {function} props.renderActions - ({handleConfirm, handleClose}) => JSX.Element, actions bar to render to replace the default yes/no buttons
 */
export function ConfirmDialog({ handleClose, isOpen, onConfirm, title, children, renderActions, ...props }) {
    const handleConfirm = () => {
        handleClose()
        onConfirm()
    }

    return (
        <Dialog onClose={handleClose} open={isOpen} {...props}>
            {title ? <DialogTitle>{title}</DialogTitle> : null}
            {children ? <DialogContent>{children}</DialogContent> : null}
            <DialogActions>
                {renderActions ? renderActions({ handleConfirm, handleClose }) : (
                    <>
                        <Button onClick={handleConfirm}>Yes</Button>
                        <Button onClick={handleClose}>No</Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    )
}

ConfirmDialog.propTypes = {
    handleClose: PropTypes.func,
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    body: PropTypes.string
}
