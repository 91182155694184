import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './customer.module.scss';
import {
  DF_UPDATE_FIELD,
  DF_UPDATE_INNER_FIELD,
  DF_PROCESS_CART,
  DF_UPDATE_CART_INNER_FIELD,
} from '../../../actions';
import {
  Typography,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

class CustomerInfoComponent extends Component {

  updateField = (field, value) => {
    this.props.dispatch({ type: DF_UPDATE_FIELD, payload: { [field]: value } });
  }

  updateCustomerField = (outerField, innerField, value) => {
    this.props.dispatch({ type: DF_UPDATE_INNER_FIELD, payload: { outerField, innerField, value }});
    this.props.dispatch({ type: DF_UPDATE_CART_INNER_FIELD, payload: { outerField, innerField, value }});
  }

  process = () => {
    this.props.dispatch({ type: DF_PROCESS_CART });
  }

  render() {
    const { nationalities, residenceStatus, orderInput, disableProcess, } = this.props;
    return (
      <div className={styles.customer}>
        <div className={styles['title']}>
          <Typography variant="h6">Customer Information</Typography>
        </div>

        <div className={styles['inputs']}>
          <div className={styles['field']}>
            <InputLabel className={styles.label}>
              Name
            </InputLabel>
            <TextField
              value={orderInput.customer && orderInput.customer.name ? orderInput.customer.name : ''}
              className={styles['input']}
              onChange={(e) => { this.updateCustomerField('customer', 'name', e.target.value) }}
            />
          </div>

          <div className={styles['field']}>
            <InputLabel className={styles.label}>
              Nationality
            </InputLabel>
            <Select
              className={styles.input}
              value={orderInput.nationality ? orderInput.nationality : ''}
              onChange={(e) => { this.updateField('nationality', e.target.value) }}
            >
              {
                nationalities && nationalities.map(nt => (
                  <MenuItem key={nt['code']} value={nt['name']}>{nt['name']}</MenuItem>
                ))
              }
            </Select>
          </div>

          <div className={styles['field']}>
            <InputLabel className={styles.label}>
              Passport Type
            </InputLabel>
            <TextField
              value={'Passport'}
              disabled={true}
              className={styles['input']}
            />
          </div>

          <div className={styles['field']}>
            <InputLabel className={styles.label}>
              Passport Number
            </InputLabel>
            <TextField
              value={orderInput.documentNumber ? orderInput.documentNumber : ''}
              className={styles['input']}
              onChange={(e) => { this.updateField('documentNumber', e.target.value) }}
            />
          </div>

          <div className={styles['field']}>
            <InputLabel className={styles.label}>
              Date of Birth
            </InputLabel>
            <TextField
              type="date"
              value={orderInput.dateOfBirth ? orderInput.dateOfBirth : ''}
              className={styles['input']}
              onChange={(e) => { this.updateField('dateOfBirth', e.target.value) }}
            />
          </div>

          <div className={styles['field']}>
            <InputLabel className={styles.label}>
              Residence Status
            </InputLabel>
            <Select
              className={styles.input}
              value={orderInput.residenceStatus ? orderInput.residenceStatus : ''}
              onChange={(e) => { this.updateField('residenceStatus', e.target.value) }}
            >
              {
                residenceStatus && residenceStatus.map(status => (
                  <MenuItem key={`status-${status}`} value={status}>{status}</MenuItem>
                ))
              }
            </Select>
          </div>

          <div className={styles['field']}>
            <InputLabel className={styles.label}>
              Date of Landing
            </InputLabel>
            <TextField
              type="date"
              value={orderInput.dateOfLanding ? orderInput.dateOfLanding : ''}
              className={styles['input']}
              onChange={(e) => { this.updateField('dateOfLanding', e.target.value) }}
            />
          </div>
        </div>

        <div className={styles['buttons']}>
          <Button variant="contained" size="small" className={styles['button']} onClick={this.process} disabled={disableProcess}>Process</Button>
        </div>

      </div >
    )
  }
}

export const CustomerInfo = connect(state => ({
  nationalities: state.system.nationalities,
  residenceStatus: state.dutyfree.residenceStatus,
  orderInput: state.dutyfree.orderInput,
  disableProcess: state.dutyfree.disableProcess,
}))(CustomerInfoComponent);