import React, { useState, useCallback } from "react";
import Button from "@material-ui/core/Button";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField
} from "@material-ui/core";
import useDialog from "../../../hooks/useDialog";
import {
  SELECT_MARKETING_IMPORT_BRAND,
  BULK_IMPORT_FILE_BEGIN,
  BULK_IMPORT_TEXT_BEGIN,
} from '../../../actions'
import BrandInput from '../BrandInput'
import { useDispatch, useSelector } from 'react-redux'

/**
 *
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.handleClose
 * @param {({data, error} : {data: any[], error: any[]}) => void} props.handleAddStock
 */
export default function GetInputDialog({
  isOpen,
  handleClose,
  handleInputDialog,
  handleConfirm,
  processing
}) {
  const [file, setFile] = useState(null);
  const [stringInput, setStringInput] = useState("");
  const { openDialog: openErrorDialog, Dialog: ErrorDialog } = useDialog();

  return (
    <div>
      <ErrorDialog />
      <Dialog onClose={handleClose} open={isOpen}>
        <DialogTitle>Add multiple supplies</DialogTitle>
        {isOpen && processing ? <div>Processing</div> :
          <><DialogContent>
            <div>
              <h3>Supported Headers</h3>
              <ul>
                <li>Reference (require): reference, model, product</li>
                <li>Price (require): price, net</li>
                <li>Quantity (require): quantity, qty</li>
                <li>Retail (optional): retail, msrp</li>
                <li>Discount (optional): dto, discount, less</li>
              </ul>
              <h3>Remarks</h3>
              <div>3P Temp only support exist products. Please create product before import.</div>
            </div>
            <div style={{ marginBottom: "2em" }}>
              <p style={{ marginBottom: 0 }}>
                <strong>Upload a file</strong>
              </p>
              <small>Supported types: .xls, .xlsx, .csv</small>
              <TextField
                type="file"
                fullWidth
                onChange={(e) => setFile(e.target.files[0])}
                inputProps={{ accept: ".xls,.xlsx,.csv" }}
              />
            </div>
            <div>
              <p>
                <strong>Copy data (Data must include headers)</strong>
              </p>
              <TextField
                multiline
                rows={5}
                fullWidth
                variant="outlined"
                value={stringInput}
                onChange={(e) => setStringInput(e.target.value)}
              />
            </div>
            <h2>Please select brand to import: </h2><BrandInput disabled={false} style={null} loading={false}
              onChange={(brand) => handleInputDialog(brand)} />

          </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleConfirm(stringInput, file)}
                disabled={!stringInput && !file}
              >
                Confirm
          </Button>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </>
        }
      </Dialog>
    </div>
  );
}
