import {
    SAVE_PRICE_SUPPLY,
    SAVE_BULK_SUPPLY_SUCCESS,
    SAVE_BULK_SUPPLY_FAIL,
    CLEAR_SAVED_SUPPLIES,
    CAPTURE_SUPPLIES,
    CAPTURE_SUPPLIES_SUCCESS,
    CAPTURE_SUPPLIES_FAIL,
    BULK_IMPORT_SET_TRADER,
    BULK_IMPORT_SET_CURRENCY,
} from '../actions'

const initialState = {
    savedSupplies: [],
    isLoading: false,
    error: null,
    vendor: {},
    currency: {}
}

export const bulkImportReducer = (state = initialState, action) => {
    switch (action.type) {
        case CAPTURE_SUPPLIES:
        case SAVE_PRICE_SUPPLY: {
            return {
                ...state,
                isLoading: true,
                error: null
            }
        }
        case CAPTURE_SUPPLIES_SUCCESS:
        case SAVE_BULK_SUPPLY_SUCCESS: {
            return {
                ...state,
                savedSupplies: [...state.savedSupplies, ...action.payload],
                isLoading: false,
                error: null
            }
        }
        case CAPTURE_SUPPLIES_FAIL:
        case SAVE_BULK_SUPPLY_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        }
        case CLEAR_SAVED_SUPPLIES: {
            return {
                ...state,
                savedSupplies: [],
            }
        }
        case BULK_IMPORT_SET_CURRENCY: {
            return {
                ...state,
                currency: action.payload,
            }
        }

        default:
            return state
    }
}