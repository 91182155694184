import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import { SearchComponent } from "./components/searchComponent";
import { CustomerComponent } from "./components/customer";
import { DeliveryComponent } from "./components/delivery";
import { CartComponent } from "./components/cartComponent";
import { DiscountComponent } from "./components/discountComponent";
import { TaxComponent } from "./components/taxComponent";
import { ControlComponent } from "./components/control";
import { CurrencyComponent } from "./components/currencyComponent";
import { IdentityComponent } from "./components/identityComponent";
import { connect } from "react-redux";
import {filter} from 'rxjs/operators'

import {
  TOGGLE_CART_TYPE_DIALOG,
  CART_CHECKOUT_BEGIN,
  LOAD_LATEST_RECEIPTS,
  CANCEL_RESERVED_DIALOG,
  CHECK_BIRTH,
  CLOSE_RESERVED_DIALOG,
  INIT_TRADER_LOAD,
  INIT_PRICE_LOAD,
  CART_ADD_ITEM_BEGIN,
  CART_NOTICE_DISMISS,
  CANCEL_INIT_CART,
  CONFIRM_INIT_CART,
  ASK_INIT_CART,
  SCAN_ACTION_CARD_BEGIN,
  VERIFY_DELIVERY_LABEL,
  VOID_ADD_ITEM
} from "../../actions";
import { useCmdCode } from "@cosmos/chaos";
import {
  PopulateDataDialog,
  FocusDialog,
  ReservedDialog
} from "../../components";
import { TypeDialog } from "./components/typeDialog";

const styles = theme => ({
  gridContainer: {
    display: "grid",
    padding: "1rem",
    "grid-template-columns": "4fr 1fr",
    "grid-template-rows": "0.5fr 1fr",
    "grid-template-areas": '"search side""section side"',
    height: "100vh",
    "grid-gap": "15px"
  },

  side: {
    "grid-area": "side",
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },

  section: {
    "grid-area": "section"
  },

  main: {
    "grid-area": "main"
  },
  search: {
    display: "flex",
    "align-items": "center",
    "grid-area": "search"
  },
  wrapper: {
    display: "grid",
    "grid-gap": "10px",
    "min-height": "100vh",
    "grid-template-rows": "4fr 2fr 3fr"
  },
  component: {
    margin: "0 0 1rem 0"
  }
});

const CartViewComponent = props => {
  const source = useCmdCode();
  
  const {
    classes,
    noticeType,
    notify,
    notice,
    initCartDialog,
    reservedDialog,
    reservedFor,
    reservation,
    typeDialog,
    voidMode,
    dispatch,
    products
  } = props;

  const handleReserveClose = () => {
    dispatch({ type: CANCEL_RESERVED_DIALOG });
    dispatch({ type: CHECK_BIRTH });
  };

  const handleReserveConfirm = () => {
    dispatch({ type: CLOSE_RESERVED_DIALOG });
    dispatch({ type: CHECK_BIRTH });
  };

  const closeNotification = () => {
    dispatch({ type: CART_NOTICE_DISMISS });
  };

  const closeInitDialog = () => {
    dispatch({ type: CANCEL_INIT_CART });
  };

  const confirmInit = () => {
    dispatch({ type: CONFIRM_INIT_CART });
  };

  const selectSales = () => {
    dispatch({
      type: TOGGLE_CART_TYPE_DIALOG,
      payload: { typeDialog: false }
    });
    // dispatch({ type: INIT_PAYSAFE }); //TODO: launch payment and execute cart
    // console.log("TODO: launch paysafe and execute cart here");
    dispatch({ type: CART_CHECKOUT_BEGIN, payload: {ProformaSales : false} });
  };

  const selectProforma = () => {
    dispatch({ type: CART_CHECKOUT_BEGIN, payload: {ProformaSales : true} });
  };

  useEffect(() => {
    dispatch({ type: INIT_TRADER_LOAD });
    dispatch({ type: INIT_PRICE_LOAD });
    dispatch({ type: LOAD_LATEST_RECEIPTS });

    if (localStorage.getItem("cartInput")) {
      if(Object.keys(products).length !== 0) {  // new code
        dispatch({ type: ASK_INIT_CART });
      }
    }
  },[dispatch, products]);


  useEffect(() => {
    const sub = source.subscribe(code=>{
      if (code.cmd) {
              switch (code.cmd) {
                case "LGS":
                  dispatch({
                    type: SCAN_ACTION_CARD_BEGIN,
                    payload: { search: code.val }
                  });
                  break;
                case "DEL":
                  dispatch({
                    type: VERIFY_DELIVERY_LABEL,
                    payload: code.val
                  });
                  break;
                default:
              }
            }

    });
    
    return () => {
      sub.unsubscribe();
    }

  },[dispatch, source]);

  useEffect(() => {

    const stkSub = source.pipe(
      filter(code => {
      return (code.cmd === 'STK');
    })).subscribe(code=>{
      
      let type = "";
        if(voidMode) {
          type = VOID_ADD_ITEM
        }
        else {
          type = CART_ADD_ITEM_BEGIN
        }

        dispatch({
          type: type,
          payload: { search: code.val }
        });
    });
    return () => {
      
      stkSub.unsubscribe();
    }
  },[dispatch, source, voidMode]);

    return (
      <div className={classes.gridContainer}>
        <section className={classes.search}>
          <SearchComponent />
        </section>
        <section className={classes.section}>
          <div className={classes.component}>
            <CartComponent />
          </div>
          <CurrencyComponent />
          <DiscountComponent />
          <TaxComponent />
          <ControlComponent/>
        </section>
        <aside className={classes.side}>
          <div className={classes.component}>
            <IdentityComponent />
          </div>
          <div className={classes.component}>
            <CustomerComponent />
          </div>
          <div className={classes.component}>
            <DeliveryComponent />
          </div>
        </aside>

        <FocusDialog
          open={notify}
          message={notice}
          variant={noticeType}
          onClose={closeNotification}
        />
        <ReservedDialog
          reservedDialog={reservedDialog}
          reservedFor={reservedFor}
          reservation={reservation}
          handleClose={handleReserveClose}
          handleConfirm={handleReserveConfirm}
        />
        <PopulateDataDialog
          dialogOpen={initCartDialog}
          closeDialog={closeInitDialog}
          handleConfirm={confirmInit}
        />
        <TypeDialog
          dialogOpen={typeDialog}
          selectSales={selectSales}
          selectProforma={selectProforma}
        />
      </div>
    );
}

export const CartView = connect(state => ({
  noticeType: state.pos.noticeType,
  notify: state.pos.notify,
  notice: state.pos.notice,
  initCartDialog: state.pos.initCartDialog,
  reservedDialog: state.pos.reservedDialog,
  reservedFor: state.pos.reservedFor,
  reservation: state.pos.reservation,
  typeDialog: state.pos.typeDialog,
  voidMode: state.pos.voidMode,
  products: state.catalog.products
}))(withStyles(styles)(CartViewComponent));
