import { createVibes } from "@cosmos/vibes";
import { CART_REMOVE_ITEM_BEGIN, CART_REMOVE_ITEM_SUCCESS, CART_REMOVE_ITEM_ERROR } from "../../actions";

export const removeCartVibes = createVibes({
  type: [CART_REMOVE_ITEM_BEGIN],
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      let idx = action.payload;
      const { goods, labels, prices, selected } = getState().pos;

      if (idx > goods.length) {
        dispatch({ type: CART_REMOVE_ITEM_ERROR, payload: { error: new Error('Index out of range') } });
      }
      else {
        const sele = (selected < idx) ? selected : (selected > idx) ? selected - 1 : 0;
        const gds = [...goods.slice(0, idx), ...goods.slice(idx + 1)];
        const labs = [...labels.slice(0, idx), ...labels.slice(idx + 1)];
        const pcs = [...prices.slice(0, idx), ...prices.slice(idx + 1)];
        dispatch({ type: CART_REMOVE_ITEM_SUCCESS, payload: { goods: gds, labels: labs, prices: pcs, selected: sele } });
      }
      done();
    } catch (err) {
      dispatch({ type: CART_REMOVE_ITEM_ERROR, payload: { error: err } });
      done();
    }
  }
});