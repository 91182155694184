import React, {Component} from "react";
import styles from "./dateRange.module.scss";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

export class DateRangeFilter extends Component {

    handleUpdate = name => (e)=>{
        let range = {...this.props.range};
        if(e.target.value){
            range[name] = moment(e.target.value);
        }
        else{
            range[name] = null;
        }
        this.props.handleChange(range);
    };

    render() {
        const {range} = this.props;
        return (
            <div className={styles.root}>
                <TextField
                    label="From"
                    type="date"
                    defaultValue={range.start && range.start.format('YYYY-MM-DD')}
                    className={styles.picker}
                    InputLabelProps={{shrink: true}}
                    onChange={this.handleUpdate('start')}
                    />
                <TextField
                    label="To"
                    type="date"
                    defaultValue={range.end && range.end.format('YYYY-MM-DD')}
                    className={styles.picker}
                    InputLabelProps={{shrink: true}}
                    onChange={this.handleUpdate('end')}
                />
            </div>
        )
    }
}