export const CART_ADD_ITEM_BEGIN = "@@cart/ADD_ITEM_BEGIN";
export const CART_ADD_ITEM_SUCCESS = "@@cart/ADD_ITEM_SUCCESS";
export const CART_ADD_ITEM_ERROR = "@@cart/ADD_ITEM_ERROR";
export const UPDATE_LABEL_LIST = "@@cart/UPDATE_LABEL_LIST";
export const UPDATE_POS_FIELD = "@@cart/UPDATE_POS_FIELD";
export const UPDATE_DUTY_FREE = "@@cart/UPDATE_DUTY_FREE";
export const UPDATE_DUTY_FREE_SUCCESS = "@@cart/UPDATE_DUTY_FREE_SUCCESS";
export const UPDATE_DUTY_FREE_ERROR = "@@cart/UPDATE_DUTY_FREE_ERROR";
export const UPDATE_DISC_RATE = "@@cart/UPDATE_DISC_RATE";
export const UPDATE_DISC_RATE_SUCCESS = "@@cart/UPDATE_DISC_RATE_SUCCESS";
export const UPDATE_DISC_RATE_ERROR = "@@cart/UPDATE_DISC_RATE_ERROR";
export const UPDATE_TAX_RATE_BEGIN = "@@cart/UPDATE_TAX_RATE_BEGIN";
export const UPDATE_TAX_RATE_SUCCESS = "@@cart/UPDATE_TAX_RATE_SUCCESS";
export const UPDATE_TAX_RATE_ERROR = "@@cart/UPDATE_TAX_RATE_ERROR";
export const UPDATE_TAX_MODE_BEGIN = "@@cart/UPDATE_TAX_MODE_BEGIN";
export const UPDATE_TAX_MODE_SUCCESS = "@@cart/UPDATE_TAX_MODE_SUCCESS";
export const UPDATE_TAX_MODE_ERROR = "@@cart/UPDATE_TAX_MODE_ERROR";
export const CART_CHECKOUT_BEGIN = '@@cart/CHECKOUT_BEGIN';
export const CART_CHECKOUT_SUCCESS = '@@cart/CHECKOUT_SUCCESS';
export const CART_CHECKOUT_ERROR = '@@cart/CHECKOUT_ERROR';
export const CART_RESET = '@@cart/CART_RESET';
export const UPDATE_POS_DISABLE_BOOL = '@@cart/UPDATE_POS_DISABLE_BOOL';
export const CART_REMOVE_ITEM_BEGIN = "@@cart/REMOVE_ITEM_BEGIN";
export const CART_REMOVE_ITEM_SUCCESS = '@@cart/REMOVE_ITEM_SUCCESS';
export const CART_REMOVE_ITEM_ERROR = '@@cart/REMOVE_ITEM_ERROR';
export const CART_SELECT_ITEM = "@@cart/SELECT_ITEM";
export const CART_NOTICE_OPEN = '@@cart/NOTICE_OPEN';
export const CART_NOTICE_DISMISS = '@@cart/NOTICE_DISMISS';
export const UPDATE_ITEM_PRICE = "@@cart/UPDATE_ITEM_PRICE";
export const UPDATE_ITEM_PRICE_SUCCESS = "@@cart/UPDATE_ITEM_PRICE_SUCCESS";
export const UPDATE_ITEM_PRICE_ERROR = "@@cart/UPDATE_ITEM_PRICE_ERROR";
export const UPDATE_IDV_DISC_RATE = "@@cart/UPDATE_IDV_DISC_RATE";
export const UPDATE_IDV_DISC_RATE_SUCCESS = "@@cart/UPDATE_IDV_DISC_RATE_SUCCESS";
export const UPDATE_IDV_DISC_RATE_ERR = "@@cart/UPDATE_IDV_DISC_RATE_ERR";
export const CART_UPDATE_TOTAL_SUCCESS = '@@cart/UPDATE_TOTAL_SUCCESS';
export const CART_UPDATE_TOTAL_ERROR = '@@cart/UPDATE_TOTAL_ERROR';
export const ASK_INIT_CART = '@@cart/ASK_INIT_CART';
export const CANCEL_INIT_CART = '@@cart/CANCEL_INIT_CART';
export const REINIT_CART = '@@cart/REINIT_CART';
export const CONFIRM_INIT_CART = '@@cart/CONFIRM_INIT_CART';
export const VERIFY_DELIVERY_LABEL = '@@cart/VERIFY_DELIVERY_LABEL';
export const DELIVERY_LABEL_SUCCESS = '@@cart/DELIVERY_LABEL_SUCCESS';
export const DELIVERY_LABEL_ERROR = '@@cart/DELIVERY_LABEL_ERROR';
export const SCAN_ACTION_CARD_BEGIN = '@@delivery/SCAN_ACTION_CARD_BEGIN';
export const SCAN_ACTION_CARD_SUCCESS = '@@delivery/SCAN_ACTION_CARD_SUCCESS';
export const SCAN_ACTION_CARD_FAILED = '@@delivery/SCAN_ACTION_CARD_FAILED';

export const POS_WARRANTY_DIALOG = '@@cart/POS_WARRANTY_DIALOG'; 
export const UPLOADED_WARRANTY_PHOTO='@@cart/UPLOADED_WARRANTY_PHOTO';
export const UPDATE_CARD_PROGRESS = '@@cart/UPDATE_CARD_PROGRESS';  
export const CARD_PROGRESS_COMPLETED = '@@cart/CARD_PROGRESS_COMPLETED';  
export const OPEN_WARRANTY_NOTICE = '@@cart/OPEN_WARRANTY_NOTICE';
export const SUBMIT_WARRANTY_BACK_PHOTO = '@@cart/SUBMIT_WARRANTY_BACK_PHOTO'; 
export const SUBMIT_WARRANTY_FRONT_PHOTO = '@@cart/SUBMIT_WARRANTY_FRONT_PHOTO'; 
export const TOGGLE_BIRTH_DIALOG = '@@cart/TOGGLE_BIRTH_DIALOG'; 
export const CHECK_BIRTH = '@@cart/CHECK_BIRTH'; 
export const CLEAR_BIRTH = '@@cart/CLEAR_BIRTH'; 

export const OPEN_RESERVED_DIALOG = '@@cart/OPEN_RESERVED_DIALOG'; 
export const CLOSE_RESERVED_DIALOG = '@@cart/CLOSE_RESERVED_DIALOG'; 
export const CANCEL_RESERVED_DIALOG = '@@cart/CANCEL_RESERVED_DIALOG'; 

export const GET_TEMP_IMAGE = '@@cart/GET_TEMP_IMAGE'; 
export const UPDATE_POS_DATAURI = '@@cart/UPDATE_POS_DATAURI'; 
export const OPEN_RESERVE_NOTICE = '@@cart/OPEN_RESERVE_NOTICE';
export const LOAD_LATEST_RECEIPTS = '@@cart/LOAD_LATEST_RECEIPTS';
export const LOAD_LATEST_RECEIPTS_SUCCESS = '@@cart/LOAD_LATEST_RECEIPTS_SUCCESS';
export const LOAD_LATEST_RECEIPTS_ERROR = '@@cart/LOAD_LATEST_RECEIPTS_ERROR';

export const OPEN_RECEIPT = '@@cart/OPEN_RECEIPT';
export const DISPLAY_RECEIPT_ERROR = '@@cart/DISPLAY_RECEIPT_ERROR';
export const TOGGLE_CART_TYPE_DIALOG = '@@cart/TOGGLE_CART_TYPE_DIALOG';
export const TOGGLE_CART_TYPE_DIALOG_ERROR = '@@cart/TOGGLE_CART_TYPE_DIALOG_ERROR';

export const CHANGE_CURRENCY = "@@cart/CHANGE_CURRENCY";
export const UPDATE_GOODS_SELECT = "@@cart/UPDATE_GOODS_SELECT";


export const CHANGE_VOID_MODE = "@@cart/CHANGE_VOID_MODE";
export const VOID_ADD_ITEM = "@@cart/VOID_ADD_ITEM";
export const VOID_ADD_ITEM_ERROR = "@@cart/VOID_ADD_ITEM_ERROR";
export const VOID_ADD_ITEM_SUCCESS = "@@cart/VOID_ADD_ITEM_SUCCESS";

export const enqueuePosNotice = (type, message) => ({ type: CART_NOTICE_OPEN, payload: { notice: message, noticeType: type } });

export const applyIdvDiscRateSuccess = payload => ({ type: UPDATE_IDV_DISC_RATE_SUCCESS, payload });

export const applyIdvDiscRateFail = payload => ({ type: UPDATE_IDV_DISC_RATE_ERR, payload });

export const applyDiscRateSuccess = payload => ({ type: UPDATE_DISC_RATE_SUCCESS, payload });

export const applyDiscRateFail = payload => ({ type: UPDATE_DISC_RATE_ERROR, payload });

export const applyTaxRateSuccess = payload => ({ type: UPDATE_TAX_RATE_SUCCESS, payload });

export const applyTaxRateFail = payload => ({ type: UPDATE_TAX_RATE_ERROR, payload });

export const applyTaxModeSuccess = payload => ({ type: UPDATE_TAX_MODE_SUCCESS, payload });

export const applyTaxModeFail = payload => ({ type: UPDATE_TAX_MODE_ERROR, payload });

export const updateTotalSuccess = payload => ({ type: CART_UPDATE_TOTAL_SUCCESS, payload });

export const updateTotalFail = payload => ({ type: CART_UPDATE_TOTAL_ERROR, payload });

export const updateItemPriceSuccess = payload => ({ type: UPDATE_ITEM_PRICE_SUCCESS, payload });

export const updateItemPriceFail = payload => ({ type: UPDATE_ITEM_PRICE_ERROR, payload });
