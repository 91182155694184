const generateInitialForm = (length=1) => {
    const orderDetails = Array(length)
    for (let i = 0; i < length; i++) {
        orderDetails[i] = generateOrderDetail()
    }
    return {
        name: '',
        id: '',
        phone: '',
        birthday: '',
        occupation: '',
        country: '',
        address1: '',
        address2: '',
        idImage: '',
        residentialStatus: 'local', // enum ['local', 'foreigner']
        paymentMethod: 'cash', // enum ['bank', 'cash']
        orderDetails,
        currency: '',
        identity: ''
    }
}

const generateOrderDetail = () => {
    return {
        product: '',
        price: '',
        condition: '',
        conditionDetails: {warranty: false, box: false, card: false, manual: false, tag: false}
    }
}

const generateInitialError = (length=1) => {
    const errors = generateInitialForm(length)
    for (const key of Object.keys(errors)) {
        if (key === 'orderDetails') {
            errors[key] = errors[key].map(orderDetail => {
                for (const key of Object.keys(orderDetail)) {
                    orderDetail[key] = null
                }
                return orderDetail
            })
        } else {
            errors[key] = null;
        }
    }

    return errors
}

const validateForm = form => {
    let isValid = true

    const orderDetailLength = form.orderDetails.length
    const errors = generateInitialError(orderDetailLength)
    const requiredInputs = [
        'name',
        'id',
        'phone',
        'birthday',
        'occupation',
        'country',
        'address1',
        'address2',
        'residentialStatus',
        // 'idImage',
        'currency'
    ]

    for (const field of requiredInputs) {
        if (form[field] === '' || form[field] === null || form[field] === undefined) {
            errors[field] = `Required field`
            isValid = false
        }
    }

    // check order details error
    for (let i = 0; i < form.orderDetails.length; i++) {
        const orderDetail = form.orderDetails[i]

        const requiredFields = ['product', 'price']

        for (const field of requiredFields) {
            if (!orderDetail[field]) {
                errors.orderDetails[i][field] = 'Required field'
                isValid = false
            }
        }

        const price = Number(orderDetail.price)

        if (Number.isNaN(price) || typeof price !== 'number') {
            errors.orderDetails[i].price = 'Price is invalid'
            isValid = false
        }

        if (price < 0) {
            errors.orderDetails[i].price = 'Price should not be negative'
            isValid = false
        }
    }

    if (isValid) {
        return null
    }

    return errors;
}

export {
    generateInitialForm,
    generateOrderDetail,
    generateInitialError,
    validateForm
}