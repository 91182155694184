
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import * as _ from 'lodash';
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import VendorInformation from "./VendorInformation";
import ProductTable from "./ProductTable";
import DisplayStockInformation from "./DisplayStockInformation";
import useOnce from "../../../hooks/useOnce";
import useDialog from "../../../hooks/useDialog";
import { useHistory } from "react-router-dom";
import { ConfirmDialog } from '../../../components/dialog';
import {
  ASK_INIT_MARKETING_UPCOMING,
  CANCEL_INIT_MARKETING_UPCOMING,
  CONFIRM_INIT_MARKETING_UPCOMING,
  RESET_INIT_MARKETING_UPCOMING,
  THIRD_PARTY_CREATE_PO_SUCCESS,
  THIRD_PARTY_UPCOMING_RESET,
  INIT_UPCOMING_LOAD_COMPLETE,
  INIT_UPCOMING_LOAD_RESET,
  THIRD_PARTY_CREATE_PO_BEGIN,
  THIRD_PARTY_RESET,
} from "../../../actions";
import { calcTaxInclusivePriceWithRatio, calcTaxExclusivePriceWithRatio } from '../../../utils/compute';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "auto 1fr 300px",
    gridTemplateRows: "1fr min-content",
    gridTemplateAreas: `
        'vendorInformation productTable displayStockInformation'
        'footer footer footer'
        `,
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    gridColumn: "1/-1",
    gridGap: "1em",
  },
  vendorInformation: {
    padding: "1rem",
    gridArea: "vendorInformation",
  },
  productTable: {
    gridArea: "productTable",
    height: "100%",
    padding: "1em",
  },
  displayStockInformation: {
    gridArea: "displayStockInformation",
    overflow: "scroll",
  },
  footer: {
    gridArea: "footer",
    textAlign: "right",
    padding: "1em",
    borderTop: "1px solid #ccc",
  },
}));

const generateBlankVendorInformation = () => ({
  default: true,
  country: "",
  currency: "",
  vendor: "",
  expectedArrivalDate: "",
  taxRate: 0,
  recipientCompany: "",
  priceIncludesTax: true,
});

export const generateBlankStock = (initialData) => ({
  model: "",
  quantity: 0,
  unitPrice: 0,
  fulfilment: "",
  remarks: "",
  msrp: 0,
  discount: 0,
  ...initialData,
});

export default function CreateMarketingSupplyComponent() {
  const dispatch = useDispatch();
  const [once, changeOnce] = useOnce();
  const history = useHistory();
  const error = useSelector(state => state.thirdPartyComing.error);
  const isMounted = useRef(false);
  const [vendorInformation, setVendorInformation] = useState(generateBlankVendorInformation());

  const handleVendorInformationChange = (name) => (e) => {
    setVendorInformation((prev) => ({ ...prev, default: false, [name]: e.target.value }));
  };

  const upcomingVendorInput = useSelector(state => state.marketing.upcomingVendorInput);
  const upcomingStocksInput = useSelector(state => state.marketing.upcomingStocksInput);
  const loadStorage = useSelector(state => state.marketing.loadStorage);
  const loadStorageComplete = useSelector(state => state.marketing.loadStorageComplete);
  const initUpcomingDialog = useSelector(state => state.marketing.initUpcomingDialog);
  const [dialogAction, setActionDialog] = useState(null);

  const [stocks, setStocks] = useState([generateBlankStock()]);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [stockToDisplay, setStockToDisplay] = useState(null);

  useEffect(() => {
    if (stocks[focusedIndex] && stocks[focusedIndex].model)
      setStockToDisplay(stocks[focusedIndex]);
  }, [focusedIndex, stocks]);

  const {
    openDialog: openSuccessDialog,
    Dialog: SubmitFormSuccessDialog,
  } = useDialog("success");

  const {
    openDialog: openErrorDialog,
    Dialog: SubmitFormErrorDialog,
  } = useDialog("error", () => {
    changeOnce();
  });

  const handleSubmit = () => {
    const error = validateForm({ vendorInformation, stocks });
    if (error) {
      return openErrorDialog(error);
    }

    //this is the graphql input
    const purchaseInput = {
      isSupply: true,
      storeId: vendorInformation.recipientCompany.id,
      expectedArrivalDate: vendorInformation.expectedArrivalDate,
      state: "CONFIRMED",
      vendor: vendorInformation.vendor.id,
      contact: vendorInformation.vendor.phone,
      type: "EXTERNAL",
      taxRate: vendorInformation.country.vat,
      once,
      currency: vendorInformation.currency.id,
      purchaseDetails: stocks.map((stock) => {

        if (vendorInformation.priceIncludesTax) {
          stock.amount = stock.unitPrice //amount is price with tax
          const { taxValue, preTaxPrice } = calcTaxInclusivePriceWithRatio(stock.amount, parseInt(vendorInformation.taxRate.toString()), 0, 1, 2);
          stock.price = preTaxPrice;
          stock.tax = taxValue;
        } else {
          stock.price = stock.unitPrice;
          const { taxValue, amount } = calcTaxExclusivePriceWithRatio(stock.price, parseInt(vendorInformation.taxRate.toString()), 0, 1, 2)
          stock.tax = taxValue;
          stock.amount = amount;
        }

        //price compute here
        return {
          brandId: stock.model.brand.id,
          productId: stock.model.product.id,
          unitPrice: stock.price,
          quantityExpected: stock.quantity,
          remarks: stock.remarks,
          amount: stock.amount,
          tax: stock.tax,
          //fulfilment: stock.fulfilment
        }
      }),
    };
    dispatch({ type: THIRD_PARTY_CREATE_PO_BEGIN, payload: { purchaseInput } })
  };

  useEffect(() => {
    const lsVendor = localStorage.getItem('vendorInfoUpcomingInvoice');
    const lsSupplies = localStorage.getItem('purchaseInfoUpcoming');
    if ((lsVendor && lsVendor !== generateBlankVendorInformation()) || (lsSupplies && lsSupplies !== JSON.stringify(generateBlankStock()))) {
      dispatch({ type: ASK_INIT_MARKETING_UPCOMING });
    }
  }, []);

  useEffect(() => {
    if (error) {
      openErrorDialog(error)
    };
  }, [error, openErrorDialog, vendorInformation, stocks]);

  useEffect(() => {

  }, [stocks, vendorInformation]);

  useEffect(() => {
    if (isMounted.current) {
      if (!vendorInformation.default || !('default' in vendorInformation)) localStorage.setItem('vendorInfoUpcomingInvoice', JSON.stringify(vendorInformation));
      if (stocks.length !== 0) localStorage.setItem('purchaseInfoUpcoming', JSON.stringify(stocks));
    } else {
      isMounted.current = true;
    }
  }, [stocks, vendorInformation]);

  const isSuccess = useSelector(state => state.thirdPartyComing.createdPO)

  useEffect(() => {
    let timeoutHandler;
    if (isSuccess) {
      dispatch({ type: RESET_INIT_MARKETING_UPCOMING });
      openSuccessDialog("Success");
      timeoutHandler = setTimeout(() => {
        dispatch({ type: THIRD_PARTY_UPCOMING_RESET });
        history.push({
          pathname: "/marketing/supplies",
          state: { section: 20201 },
        });
      }, 1000);
    }
    return () => clearTimeout(timeoutHandler);
  }, [isSuccess, openSuccessDialog, history, vendorInformation, dispatch]);

  useEffect(() => {
    if (dialogAction) {
      if (dialogAction === "close") {
        dispatch({ type: CANCEL_INIT_MARKETING_UPCOMING });
        setVendorInformation(generateBlankVendorInformation());
        setStocks([]);
      } else if (dialogAction === "confirm") {
        dispatch({ type: CONFIRM_INIT_MARKETING_UPCOMING });
      }
      setActionDialog(null)
    }
  }, [dialogAction, dispatch])

  //load initial 
  useEffect(() => {
    if (loadStorage && !loadStorageComplete) {
      if (upcomingVendorInput) setVendorInformation(upcomingVendorInput)
      if (upcomingStocksInput && upcomingStocksInput.length > 0) setStocks(upcomingStocksInput);
      dispatch({ type: INIT_UPCOMING_LOAD_COMPLETE });
    }
  }, [upcomingVendorInput, upcomingStocksInput, initUpcomingDialog, dispatch, loadStorageComplete, loadStorage]);

  const classes = useStyles();
  const isLoading = useSelector(state => state.thirdPartyComing.isLoading)

  return (
    <div className={classes.root}>
      <ConfirmDialog
        title={"Initialize data"}
        handleClose={() => setActionDialog("close")}
        isOpen={initUpcomingDialog}
        onConfirm={() => setActionDialog("confirm")}
      >
        Do you want to use previous saved inputs?
      </ConfirmDialog>
      <Paper>
        <VendorInformation
          {...vendorInformation}
          onChange={handleVendorInformationChange}
          className={classes.vendorInformation}
        />
      </Paper>
      <ProductTable
        stocks={stocks}
        setStocks={setStocks}
        generateBlankStock={generateBlankStock}
        focusedIndex={focusedIndex}
        setFocusedIndex={(index) => setFocusedIndex(index)}
        taxRate={(vendorInformation && vendorInformation.taxRate) || 0}
        currency={
          (vendorInformation && vendorInformation.currency) ? vendorInformation.currency.code : ""
        }
        className={classes.productTable}
        priceIncludesTax={(vendorInformation && vendorInformation.priceIncludesTax) || true}
      />
      <Paper>
        <DisplayStockInformation
          product={stockToDisplay ? stockToDisplay.model.product : null}
          className={classes.displayStockInformation}
        />
      </Paper>
      <div className={classes.footer}>
        <Button onClick={() => {
          dispatch({ type: INIT_UPCOMING_LOAD_RESET });
          history.goBack()
        }
        }>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Submit
        </Button>
      </div>
      <SubmitFormErrorDialog />
      <SubmitFormSuccessDialog />
    </div>
  );

};

function validateForm({ vendorInformation, stocks }) {
  if (!vendorInformation.country) return "Country required";
  if (!vendorInformation.currency) return "Currency required";
  if (!vendorInformation.vendor) return "Vendor required";
  if (!vendorInformation.expectedArrivalDate)
    return "Expected Arrival Date required";
  let stockError = null;
  stocks.forEach((stock) => {
    if (!stock.model) stockError = "Model required";
    if (Number.isNaN(stock.quantity)) stockError = "Invalid quantity";
    if (stock.quantity === parseInt("0"))
      stockError = "Quantity cannot be zero";
    if (Number.isNaN(stock.unitPrice)) stockError = "Invalid price";
    if (stock.unitPrice <= parseInt("0")) stockError = "Price cannot be zero";
  });

  return stockError;
}
