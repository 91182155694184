import { createVibes } from "@cosmos/vibes";
import { UPDATE_TAX_RATE_BEGIN, UPDATE_TAX_MODE_BEGIN, applyTaxRateSuccess, applyTaxModeSuccess, applyTaxModeFail, applyTaxRateFail } from "../../actions";
import { Abacus } from "@cosmos/harmony";

export const applyTaxRateVibes = createVibes({
  type: [UPDATE_TAX_RATE_BEGIN],
  latest: true,
  processOptions: {
    successType: applyTaxRateSuccess,
    failType: applyTaxRateFail
  },
  async process({ getState }) {
    try {
      const { countries, stores, rayLens } = getState().system;
      const oriPrices = getState().price.prices;
      const { goods, taxMode } = getState().pos; 
      let newTaxRate;
      if(taxMode === "NA") {
          newTaxRate = 0;
      }
      else {
          newTaxRate = countries && stores && stores[rayLens] && stores[rayLens].countryId && countries[stores[rayLens].countryId] ? countries[stores[rayLens].countryId].vat : 0;
      }
      
      let pcs = [];
      goods.map(gd => {
        let oriSellPrice;
        if (gd.priceAdjustment === null || gd.priceAdjustment === undefined) {
          oriSellPrice = oriPrices[gd.productId] ? oriPrices[gd.productId].price : 0;
        }
        else {
          oriSellPrice = gd.priceAdjustment;
        }
        let calculatedOutput = Abacus.calcPriceWithRatio(oriSellPrice, 1, gd.discountRate, newTaxRate, 2);
        pcs.push(calculatedOutput);
        return gd;
      });
      return { taxRate: newTaxRate, prices: pcs };
    } catch (err) {
      return { error: err }
    }
  }
});


export const applyTaxModeVibes = createVibes({
  type: [UPDATE_TAX_MODE_BEGIN],
  latest: true,
  processOptions: {
    successType: applyTaxModeSuccess,
    failType: applyTaxModeFail
  },
  async process({ getState,action }) {
    try {
      const { countries, stores, rayLens } = getState().system;
      // const { goods } = getState().pos;  // original code
      const { goods, prices } = getState().pos;
      const oriPrices = getState().price.prices;
      let pcs = [];
      const taxMode = action.payload.select;
      let newTaxRate;
      if(taxMode === "NA") {
        newTaxRate = 0;
      }
      else {  
        newTaxRate = countries && stores && stores[rayLens] && stores[rayLens].countryId && countries[stores[rayLens].countryId] ? countries[stores[rayLens].countryId].vat : 0;
      }
      // goods.map(gd => {  // original code
      goods.map((gd, idx )=> {
        let oriSellPrice;
        if(!gd.priceAdjustment && prices[idx].amount) {
          oriSellPrice = prices[idx].amount
        }else {
          // orignal code - start
          if (gd.priceAdjustment === null || gd.priceAdjustment === undefined) {
            oriSellPrice = oriPrices[gd.productId] ? oriPrices[gd.productId].price : 0;
          }
          else {
            oriSellPrice = gd.priceAdjustment;
          }
          // orignal code - end
        }
        let calculatedOutput = Abacus.calcPriceWithRatio(oriSellPrice, 1, gd.discountRate, newTaxRate, 2);
        pcs.push(calculatedOutput);
        return gd;
      });
      return { taxRate: newTaxRate, prices: pcs, taxMode: taxMode };
    } catch (err) {
      return { error: err }
    }
  }
});