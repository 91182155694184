import React from 'react'
import Switch from './Switch'
import { useSelector, useDispatch } from 'react-redux'
import { UPDATE_PO_STOCK_INPUT } from "../../../../actions";

export default function VendorInformation({ isConsigned, vendor, invoice, date, className }) {
    const traders = useSelector(state => state.trader.traders)
    const dispatch = useDispatch()
    const updateConsign = () => {
		let updatedConsignValue = isConsigned ? false : true;
		dispatch({ type: UPDATE_PO_STOCK_INPUT, payload: {isConsigned : updatedConsignValue} });
	}
    return (
        <div className={className}>
            <h2>{nullish(traders[vendor] ? traders[vendor].name : null, 'Vendor')}</h2>
            <p>{nullish(invoice, 'Invoice Reference')}</p>
            <p>{nullish(date, 'Invoice date')}</p>
            <Switch checked={isConsigned} onChange={updateConsign} label="Consignment" />
        </div>
    )
}

function nullish(value, defaultValue) {
    return value ? value : defaultValue
}
