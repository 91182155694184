import { createVibes } from "@cosmos/vibes";
import {loadCatalogSuccess, loadCatalogFail, LOAD_CATALOG_START} from "../../actions";
import {keyBy, reduce} from 'lodash';
import { AUTH_ATTEMPT_SUCCESS } from "@cosmos/chaos";

export const loadCatalogVibes = createVibes({
  type: [AUTH_ATTEMPT_SUCCESS],
  latest: true,
  async process({ getState, action, axios }, dispatch, done) {
    try {
      dispatch({type: LOAD_CATALOG_START});
      const variables = { filter: {} };

      const result = await axios.request({
        url: '/api',
        method: 'post',
        data: {
          query: `query loadCatalog($filter: ProductFilter){
            brands{
              id name code
              logos { fid type size }
            }
    
            products(filter: $filter) {
              id
              brandID
              name
              reference
              dialColor
              SKU
              assets {
                reference
              } 
            }
           }`,
          variables
        }
      });

      let data = result.data;

      // let payload = {};
      // if(data.brands){
      //   payload = {...payload, brands: reduce(data.brands, (r, d) => { r[d.id] = d; return r }, {})}
      // }
      // if(data.products){
      //   payload = {...payload, products: reduce(data.products, (r, d) => { r[d.id] = d; return r }, {})}
      // }
      dispatch(loadCatalogSuccess({
        brands: keyBy(data.brands, 'id'),
        products: keyBy(data.products, 'id')
      }));
    } catch (err) {
      dispatch(loadCatalogFail(err));
    } finally {
      done();
    }
  }
});
