import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import styles from './cellEdit.module.scss';
import classNames from 'classnames';
import InputBase from "@material-ui/core/InputBase/InputBase";

export class CellEdit extends Component {
    static defaultProps = { styles: {} };

    constructor(props) {
        super(props);
        this.state = {
            focus: false, enter: false
        };
    }

    handleFocus = () => {
        this.setState({ focus: true, enter: true });
        this.editor.focus();
    };

    handleBlur = () => {
        this.setState({ focus: false, enter: false })
    };

    render() {
        const { focus, enter } = this.state;
        const {
            displayValue,
            editValue,
            displayProps = {},
            editorProps = {},
            onChange,
            onEnterKey
        } = this.props;
        return (
            <div
                className={classNames(styles["number-box"], this.props.styles.container)}
                onClick={this.handleFocus}
            >
                <div className={classNames(styles.control, { focus })}>
                    <div
                        className={styles.display}
                    >
                        {
                            displayProps.prefix &&
                            (<div className={styles["front-ado"]}>
                                {displayProps.prefix}
                            </div>)
                        }
                        <NumberFormat value={displayValue}
                            displayType="text"
                            thousandSeparator={true}
                            renderText={value => (
                                <div className={styles["display-field"]}>
                                    {value}
                                </div>
                            )}
                        />
                        {
                            displayProps.suffix &&
                            (<div className={styles["end-ado"]}>
                                {displayProps.suffix}
                            </div>)
                        }
                    </div>
                    <div
                        className={classNames(styles.editor, { enter })}
                    >
                        {
                            editorProps.prefix &&
                            (<div className={styles["front-ado"]}>
                                {editorProps.prefix}
                            </div>)
                        }
                        <NumberFormat className={styles["editor-field"]}
                            value={editValue}
                            inputRef={(ref) => {
                                this.editor = ref
                            }}
                            onBlur={this.handleBlur}
                            thousandSeparator={true}
                            customInput={InputBase}
                            onValueChange={values => {
                                const { value } = values;
                                onChange(value);
                            }}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    onEnterKey(event.target.value);
                                    this.handleBlur();
                                }
                            }}
                        />
                        {
                            editorProps.suffix &&
                            (<div className={styles["end-ado"]}>
                                {editorProps.suffix}
                            </div>)
                        }
                    </div>
                </div>
            </div>
        )
    }
}