import React, {useEffect}  from "react";
import { connect, useDispatch } from 'react-redux';
import { Paper, TextField, List, ListItem, Typography, withStyles, MenuItem, Select } from '@material-ui/core';
import { UPDATE_POS_FIELD, UPDATE_GOODS_SELECT } from "../../../actions";

const styles = theme => ({
    customer: {
        flex: 1,
        display: "flex"
    },
    field: {
        minWidth: "10em",
        marginRight: "1.5em",
        marginLeft: "1.5em",
        flex: 1
    },
    content: {
        flex: 1,
        padding: "0 0 1em 0"
    },
    label: {
        minWidth: "5em",
        marginLeft: "1.5em"
    }
});

const CustomerComponent_ = props => {
    const { company, contact, isActionCodeApply, date, remarks, customer, classes } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: UPDATE_GOODS_SELECT, payload: { select: 'WATCHES' } });
    },[dispatch]);

    const updateCustomerField = (value) => {
        if(isActionCodeApply) {
            dispatch({ type: UPDATE_POS_FIELD, payload: { company: { ...company, ...value } } });
        }
        else {
            dispatch({ type: UPDATE_POS_FIELD, payload: { customer: { ...customer, ...value } } });
        }
        
    }

    const handleChange = e =>  { 
        dispatch({ type: UPDATE_GOODS_SELECT, payload: { select: e.target.value } });
    }

    const updateField = (value) => {
        dispatch({ type: UPDATE_POS_FIELD, payload: value });
    }

    return (
        <div className={classes.customer}>
                <Paper className = {classes.content}> 
                    <List>
                        {isActionCodeApply &&
                        <ListItem>
                            <Typography className={classes['label']}>{'Company'}</Typography>
                            <TextField aria-label="Company"
                                className={classes['field']} 
                                value={company && company.name ? company.name : ''}
                                onChange={(e) => { updateCustomerField({ name: e.target.value }) }}
                                disabled={!!(company && company.id)}
                            />
                        </ListItem>
                        } 
                        { !isActionCodeApply  && 
                        <ListItem>
                            <Typography className={classes['label']}>{'Customer'}</Typography>
                            <TextField aria-label="Customer"
                                value={customer ? customer.name : ''}
                                className={classes['field']}
                                onChange={(e) => { updateCustomerField({ name: e.target.value }) }}
                            />
                        </ListItem>
                        }
                        { !isActionCodeApply && 
                        <ListItem>
                            <Typography className={classes['label']}>{'Contact'}</Typography>
                            <TextField aria-label="Contact"
                                className={classes['field']} value={contact ? contact : ''}
                                onChange={(e) => { updateField({ contact: e.target.value }) }}
                            />
                        </ListItem>
                        }   
                        <ListItem>
                            <Typography className={classes['label']}>{'Date'}</Typography>
                            <TextField aria-label="Date"
                                className={classes['field']}
                                value={date ? date : ''}
                                onChange={(e) => { updateField({ date: e.target.value }) }}
                                type="date"
                            />
                        </ListItem>
                        <ListItem>
                            <Typography className={classes['label']}>{'Remarks'}</Typography>
                            <TextField aria-label="Remarks"
                                className={classes['field']} value={remarks ? remarks : ''}
                                onChange={(e) => { updateField({ remarks: e.target.value }) }}
                            />
                        </ListItem>
                        <ListItem>
                        <Typography className={classes['label']}>{'Goods'}</Typography>
                            <Select className={classes['field']} defaultValue = 'WATCHES' onChange={(e)=> {handleChange(e)}}>
                                <MenuItem value='WATCHES'>Watches</MenuItem>
                                <MenuItem value='ITEMS'>Items</MenuItem>
                            </Select>
                        </ListItem>
                    </List>
                </Paper>
        </div>
    );
}

export const CustomerComponent = connect(
    state => ({
        company: state.pos.company,
        customer: state.pos.customer,
        date: state.pos.date,
        isActionCodeApply: state.pos.isActionCodeApply,
        contact: state.pos.contact,
        remarks: state.pos.remarks,
        receipt: state.pos.receipt
    }))(withStyles(styles)(CustomerComponent_));