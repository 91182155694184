import {
  CART_ADD_ITEM_BEGIN,
  CART_ADD_ITEM_ERROR,
  CART_ADD_ITEM_SUCCESS,
  UPDATE_LABEL_LIST,
  CART_NOTICE_DISMISS,
  CART_NOTICE_OPEN,
  CART_REMOVE_ITEM_SUCCESS,
  CART_REMOVE_ITEM_ERROR,
  UPDATE_POS_DISABLE_BOOL,
  UPDATE_POS_FIELD,
  CART_RESET,
  CART_SELECT_ITEM,
  CART_CHECKOUT_BEGIN,
  CART_CHECKOUT_SUCCESS,
  CART_CHECKOUT_ERROR,
  UPDATE_IDV_DISC_RATE_SUCCESS,
  UPDATE_IDV_DISC_RATE_ERR,
  UPDATE_DISC_RATE_SUCCESS,
  UPDATE_DISC_RATE_ERROR,
  CART_UPDATE_TOTAL_SUCCESS,
  CART_UPDATE_TOTAL_ERROR,
  UPDATE_TAX_RATE_SUCCESS,
  UPDATE_TAX_RATE_ERROR,
  UPDATE_ITEM_PRICE_SUCCESS,
  UPDATE_ITEM_PRICE_ERROR,
  ASK_INIT_CART,
  REINIT_CART,
  CONFIRM_INIT_CART,
  UPDATE_DUTY_FREE_SUCCESS,
  UPDATE_DUTY_FREE_ERROR,
  DELIVERY_LABEL_SUCCESS,
  DELIVERY_LABEL_ERROR,
  SCAN_ACTION_CARD_SUCCESS,
  SCAN_ACTION_CARD_FAILED,
  POS_WARRANTY_DIALOG,
  UPLOADED_WARRANTY_PHOTO,
  UPDATE_CARD_PROGRESS,
  CARD_PROGRESS_COMPLETED,
  OPEN_WARRANTY_NOTICE,
  TOGGLE_BIRTH_DIALOG,
  OPEN_RESERVED_DIALOG,
  CLOSE_RESERVED_DIALOG,
  CANCEL_RESERVED_DIALOG,
  CLEAR_BIRTH,
  UPDATE_POS_DATAURI,
  OPEN_RESERVE_NOTICE,
  LOAD_LATEST_RECEIPTS_SUCCESS, LOAD_LATEST_RECEIPTS_ERROR, DISPLAY_RECEIPT_ERROR,
  TOGGLE_CART_TYPE_DIALOG,
  CHANGE_CURRENCY,
  CHANGE_VOID_MODE,
  VOID_ADD_ITEM,
  VOID_ADD_ITEM_ERROR,
  VOID_ADD_ITEM_SUCCESS,
  UPDATE_TAX_MODE_SUCCESS,
  UPDATE_GOODS_SELECT,
  TOGGLE_CART_TYPE_DIALOG_ERROR
} from "../actions";
import moment from "moment";
import * as shortId from "shortid";

const initialState = {
  loading: false,
  goods: [],
  labels: [],
  prices: [],
  selected: -1,
  customer: { id: null, name: '' },
  company: { id: null, name: '' },
  isActionCodeApply: false,
  tracking: null,
  once: shortId.generate(),
  submitted: false,
  disableCart: true,
  dutyFree: false,
  type: 0,
  taxRate: 0,
  discountRate: 0,
  discountValue: 0,
  date: moment().format("YYYY-MM-DD"),
  contact: null,
  receipt: null,
  remarks: null,
  notify: false,
  noticeType: "info",
  notice: "",
  tax: 0,
  total: 0,
  initCartDialog: false,
  delivery: { label: null },
  uploadProgress: -1,
  captureCardFront: false,
  captureCardBack: false,
  birthDialog: false,
  originId: null,
  reservedDialog: false,
  reservedFor: '',
  reservation: '',
  dataUri: '',
  latestCarts: [],
  typeDialog: false,
  currency: '',
  voidMode: false,
  voidGoods: [],
  voidLabels: [],
  voidPrices: [],
  selectGoods: ''
};

export const posReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GOODS_SELECT:
      return {...state, selectGoods: action.payload.select}
    case CART_ADD_ITEM_BEGIN:
      return { ...state, loading: true };
    case TOGGLE_BIRTH_DIALOG:
      return { ...state, birthDialog: !state.birthDialog };
    case CART_ADD_ITEM_SUCCESS:
      localStorage.setItem("cartInput", JSON.stringify({ ...state, ...action.payload, loading: false }));
      return { ...state, ...action.payload, loading: false };
    case CART_ADD_ITEM_ERROR:
      return {
        ...state,
        loading: false,
        notify: true,
        notice: action.payload,
        noticeType: "error"
      };
    case UPDATE_LABEL_LIST:
      localStorage.setItem("cartInput", JSON.stringify({ ...state, labels: [action.payload, ...state.labels] }));
      return { ...state, labels: [action.payload, ...state.labels] };
    case UPDATE_POS_FIELD:
    case UPDATE_IDV_DISC_RATE_SUCCESS:
    case UPDATE_IDV_DISC_RATE_ERR:
    case UPDATE_DISC_RATE_SUCCESS:
    case CART_REMOVE_ITEM_SUCCESS:
    case UPDATE_DISC_RATE_ERROR:
    case CART_REMOVE_ITEM_ERROR:
    case CART_SELECT_ITEM:
    case UPDATE_ITEM_PRICE_SUCCESS:
    case UPDATE_ITEM_PRICE_ERROR:
    case UPDATE_DUTY_FREE_SUCCESS:
    case UPDATE_DUTY_FREE_ERROR:
    case UPLOADED_WARRANTY_PHOTO:
      localStorage.setItem("cartInput", JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
    case UPDATE_TAX_RATE_SUCCESS:
    case UPDATE_TAX_MODE_SUCCESS:
      return { 
        ...state,
        ...action.payload,
        taxRate: action.payload.taxRate
      }

    case UPDATE_TAX_RATE_ERROR:
    case CART_UPDATE_TOTAL_SUCCESS:
    case CART_UPDATE_TOTAL_ERROR:
    case UPDATE_POS_DISABLE_BOOL:
    case POS_WARRANTY_DIALOG:
    case UPDATE_CARD_PROGRESS:
    case OPEN_WARRANTY_NOTICE:
    case CARD_PROGRESS_COMPLETED:
    case OPEN_RESERVED_DIALOG:
    case UPDATE_POS_DATAURI:
    case OPEN_RESERVE_NOTICE:
    case LOAD_LATEST_RECEIPTS_SUCCESS: 
    case LOAD_LATEST_RECEIPTS_ERROR: 
    case DISPLAY_RECEIPT_ERROR:
    case TOGGLE_CART_TYPE_DIALOG:
      return { ...state, ...action.payload };
    case TOGGLE_CART_TYPE_DIALOG_ERROR:
      return {
        ...state,
        notify: true,
        notice: "Please fill cutomer information",
        noticeType: "error"
      };
    case CLOSE_RESERVED_DIALOG:
      return { ...state, reservedDialog: false, reservedFor: '', reservation: '' };
    case CART_RESET:
    case REINIT_CART:
      localStorage.removeItem("cartInput");
      return {
        ...state,
        loading: false,
        dataUri: '',
        goods: [],
        labels: [],
        prices: [],
        selected: -1,
        customer: { id: null, name: '' },
        company: { id: null, name: '' },
        isActionCodeApply: false,
        tracking: null,
        once: shortId.generate(),
        submitted: false,
        disableCart: true,
        dutyFree: false,
        type: 0,
        discountRate: 0,
        date: moment().format("YYYY-MM-DD"),
        contact: null,
        receipt: null,
        remarks: null,
        notify: false,
        noticeType: "info",
        notice: "",
        tax: 0,
        total: 0,
        initCartDialog: false,
        target: -2,
        delivery: { label: null },
        uploadProgress: -1,
        captureCardFront: false,
        captureCardBack: false,
        birthDialog: false,
        reservedDialog: false,
        reservedFor: '',
        reservation: '',
        latestCarts: [],
        typeDialog: false,
      };
    case CART_CHECKOUT_BEGIN:
      return { ...state, submitted: true };
    case CART_CHECKOUT_SUCCESS:
      localStorage.removeItem("cartInput");
      return { ...state, submitted: false };
    case CART_CHECKOUT_ERROR:
      return {
        ...state,
        submitted: false,
        once: shortId.generate(),
        error: action.payload
      };
    case CART_NOTICE_OPEN:
      return { ...state, notify: true, ...action.payload };
    case CART_NOTICE_DISMISS:
      return { ...state, notify: false };
    case ASK_INIT_CART:
      return { ...state, initCartDialog: true };
    case CONFIRM_INIT_CART:
      let storedData = JSON.parse(localStorage.getItem("cartInput"));
      return {
        ...state,
        loading: false,
        dataUri: '',
        goods: storedData.goods || [],
        labels: storedData.labels || [],
        prices: storedData.prices || [],
        selected: storedData.selected || -1,
        customer: storedData.customer || { id: null, name: '' },
        company: storedData.company || { id: null, name: '' },
        isActionCodeApply: storedData.isActionCodeApply,
        tracking: storedData.tracking || null,
        once: shortId.generate(),
        submitted: false,
        disableCart: storedData.disableCart || true,
        dutyFree: storedData.dutyFree || false,
        taxRate: storedData.taxRate || 0,
        discountRate: storedData.discountRate || 0,
        discountValue: storedData.discountValue || 0,
        date: storedData.date || moment().format("YYYY-MM-DD"),
        contact: storedData.contact || null,
        receipt: storedData.receipt || null,
        remarks: storedData.remarks || null,
        notify: false,
        noticeType: "info",
        notice: "",
        tax: storedData.tax || 0,
        total: storedData.total || 0,
        initCartDialog: false,
        target: -2,
        delivery: storedData.delivery || { label: null },
        captureCardFront: false,
        captureCardBack: false,
        uploadProgress: storedData.uploadProgress || -1,
        birthDialog: false,
        reservedDialog: false,
        reservedFor: '',
        reservation: '',
        latestCarts: [],
        typeDialog: false,
        voidLabels: storedData.voidLabels || [],
        voidGoods: storedData.voidGoods || [],
        voidPrices: storedData.voidPrices || [],
      };
    case DELIVERY_LABEL_SUCCESS:
      return {
        ...state,
        notify: true,
        notice: "The delivery label is applied successfully",
        noticeType: "success"
      };
    case DELIVERY_LABEL_ERROR:
      return {
        ...state,
        ...action.payload,
        notify: true,
        noticeType: "error"
      };
    case SCAN_ACTION_CARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        notify: true,
        notice: "The action code is applied",
        noticeType: "success"
      };
    case SCAN_ACTION_CARD_FAILED:
      return {
        ...state,
        notify: true,
        notice: "The action code is invalid",
        noticeType: "error"
      };
    case CANCEL_RESERVED_DIALOG:
      return {
        ...state,
        reservedDialog: false, reservedFor: '', reservation: '',
        goods: [{ ...state.goods[0], reservation: null }, ...state.goods.slice(1)],
      }
    case CLEAR_BIRTH:
      return {
        ...state,
        goods: [{ ...state.goods[0], birthYear: null, birthMonth: null, birthDate: null }, ...state.goods.slice(1)],
      }
    case CHANGE_CURRENCY:
      return {
          ...state,
          currency: action.payload
      };
    case CHANGE_VOID_MODE:
      return {
          ...state,
          voidMode: action.payload
      };
    case VOID_ADD_ITEM:
      return { ...state, loading: true };
    case VOID_ADD_ITEM_SUCCESS:
      return { 
        ...state, 
        voidLabels: [...action.payload.voidLabels], 
        voidGoods: [...action.payload.voidGoods], 
        voidPrices: [...action.payload.voidPrices],
        loading: false };
    case VOID_ADD_ITEM_ERROR:
        return {
        ...state,
        loading: false,
        notify: true,
        notice: action.payload,
        noticeType: "error"
      };
    default:
      return state;
  }
};
