export const DF_NOTICE_DISMISS = "@@df/DF_NOTICE_DISMISS";
export const DF_UPDATE_FIELD = "@@df/DF_UPDATE_FIELD";
export const DF_UPDATE_INNER_FIELD = "@@df/DF_UPDATE_INNER_FIELD";
export const DF_DISABLE_PROCESS = "@@df/DF_DISABLE_PROCESS";

export const DF_SCAN_CART_LABEL = "@@df/DF_SCAN_CART_LABEL";
export const DF_SCAN_CART_LABEL_FAIL = "@@df/DF_SCAN_CART_LABEL_FAIL";
export const DF_SCAN_CART_LABEL_SUCCESS = "@@df/DF_SCAN_CART_LABEL_SUCCESS";
export const DF_ADD_CART_LABEL = "@@df/DF_ADD_CART_LABEL";

export const DF_PROCESS_CART = "@@df/DF_PROCESS_CART";
export const SEARCH_DF_STOCK = "@@df/SEARCH_DF_STOCK";
export const DF_UPDATE_CART_FIELD = "@@df/DF_UPDATE_CART_FIELD";
export const DF_UPDATE_CART_INNER_FIELD = "@@df/DF_UPDATE_CART_INNER_FIELD";

export const DF_ADD_ITEM_FAIL = "@@df/DF_ADD_ITEM_FAIL";
export const DF_ADD_ITEM_SUCCESS = "@@df/DF_ADD_ITEM_SUCCESS";
export const UPDATE_DF_LABEL_LIST = "@@df/UPDATE_DF_LABEL_LIST";
export const OPEN_DF_RESERVED_DIALOG = '@@df/OPEN_DF_RESERVED_DIALOG';
export const CANCEL_DF_RESERVED_DIALOG = '@@df/CANCEL_DF_RESERVED_DIALOG';
export const CLOSE_DF_RESERVED_DIALOG = '@@df/CLOSE_DF_RESERVED_DIALOG';

export const UPDATE_BIRTH_FIELD = '@@df/UPDATE_BIRTH_FIELD';
export const DF_POS_WARRANTY_DIALOG = '@@df/DF_POS_WARRANTY_DIALOG';
export const UPLOADED_DF_WARRANTY_PHOTO = '@@df/UPLOADED_DF_WARRANTY_PHOTO';
export const UPDATE_DF_CARD_PROGRESS = '@@df/UPDATE_DF_CARD_PROGRESS';
export const DF_CARD_PROGRESS_COMPLETED = '@@df/DF_CARD_PROGRESS_COMPLETED';
export const OPEN_DF_WARRANTY_NOTICE = '@@df/OPEN_DF_WARRANTY_NOTICE';
export const SUBMIT_DF_WARRANTY_BACK_PHOTO = '@@df/SUBMIT_DF_WARRANTY_BACK_PHOTO';
export const SUBMIT_DF_WARRANTY_FRONT_PHOTO = '@@df/SUBMIT_W_DFARRANTY_FRONT_PHOTO';

export const TOGGLE_DF_BIRTH_DIALOG = '@@df/TOGGLE_DF_BIRTH_DIALOG';
export const DF_CLEAR_BIRTH = '@@df/DF_CLEAR_BIRTH';
export const UPDATE_DF_PRICE = '@@df/UPDATE_DF_PRICE';
export const REMOVE_DF_ITEM = '@@df/REMOVE_DF_ITEM';
export const DF_REMOVE_ITEM_ERROR = '@@df/DF_REMOVE_ITEM_ERROR';
export const DF_REMOVE_ITEM_SUCCESS = '@@df/DF_REMOVE_ITEM_SUCCESS';
export const SELECT_DF_ITEM = '@@df/SELECT_DF_ITEM';
export const SUBMIT_DF_ITEMS = '@@df/SUBMIT_DF_ITEMS';
export const SUBMIT_DF_ITEMS_SUCCESS = '@@df/SUBMIT_DF_ITEMS_SUCCESS';
export const SUBMIT_DF_ITEMS_FAIL = '@@df/SUBMIT_DF_ITEMS_FAIL';

export const OPEN_SAM_DIALOG = '@@df/OPEN_SAM_DIALOG';
export const CLOSE_SAM_DIALOG = '@@df/CLOSE_SAM_DIALOG';
export const SCANNED_MRZ = '@@df/SCANNED_MRZ';
export const SCANNED_MRZ_SUCCESS = '@@df/SCANNED_MRZ_SUCCESS';
export const SCANNED_MRZ_FAIL = '@@df/SCANNED_MRZ_FAIL';

export const DF_OPEN_RESERVE_NOTICE = '@@df/DF_OPEN_RESERVE_NOTICE';
export const TOGGLE_BLANK_DIALOG = '@@df/TOGGLE_BLANK_DIALOG';
export const PRINT_DF_BLANK_FORM = '@@df/PRINT_DF_BLANK_FORM';
export const PRINT_DF_BLANK_FORM_ERROR = '@@df/PRINT_DF_BLANK_FORM_ERROR';

export const DF_TOGGLE_PASSPORT_DIALOG = '@@df/DF_TOGGLE_PASSPORT_DIALOG';
export const DF_TOGGLE_VISA_DIALOG = '@@df/DF_TOGGLE_VISA_DIALOG';
export const DF_SUBMIT_PASSPORT = '@@df/DF_SUBMIT_PASSPORT';
export const DF_SUBMIT_VISA = '@@df/DF_SUBMIT_VISA';
export const UPLOADED_DF_PASSPORT_PHOTO = '@@df/UPLOADED_DF_PASSPORT_PHOTO';
export const UPLOADED_DF_VISA_PHOTO = '@@df/UPLOADED_DF_VISA_PHOTO';
export const DF_SHOW_PASSPORT = '@@df/DF_SHOW_PASSPORT';
export const DF_SHOW_VISA = '@@df/DF_SHOW_VISA';

export const OPEN_DF_NOTICE = '@@df/OPEN_DF_NOTICE';
export const DF_SHOW_TEMP_IMG = '@@df/DF_SHOW_TEMP_IMG';
export const DF_CLOSE_TEMP_DIALOG = '@@df/DF_CLOSE_TEMP_DIALOG';

export const TOGGLE_CDP_DIALOG = '@@df/TOGGLE_CDP_DIALOG';
export const DF_SUBMIT_CDP = '@@df/DF_SUBMIT_CDP';
export const UPLOADED_CDP_PHOTO = '@@df/UPLOADED_CDP_PHOTO';
export const SUBMIT_CDP_FAIL = '@@df/SUBMIT_CDP_FAIL';

export const INIT_PAYSAFE = '@@df/INIT_PAYSAFE';
export const PAYMENT_FAILED = '@@df/PAYMENT_FAILED';
export const PAYMENT_SUCCEED = '@@df/PAYMENT_SUCCEED';