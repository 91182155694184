import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './print.module.scss';
import {
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { UPDATE_SELECTION, SUBMIT_PRINTING, CLOSE_PRINTING_NOTICE, LOAD_PRINTERS } from "../../actions";
import { FocusDialog } from '../../components';

class PrintingComponent extends Component {
  componentDidMount() {
    this.props.dispatch({ type: LOAD_PRINTERS });
  }

  updateField = value => {
    this.props.dispatch({ type: UPDATE_SELECTION, payload: value})
  }

  submitPrinting = () => {
    this.props.dispatch({ type: SUBMIT_PRINTING, payload: this.state })
  }

  closeNotification = () => {
    this.props.dispatch({ type: CLOSE_PRINTING_NOTICE });
  }

  render() {
    const { printers, printingTypes, noticeType, notice, notify, selectedType, selectedPrinter } = this.props;

    return (
      <div className={styles.printing}>
        <Paper className={styles.feature}>
          <div className={styles.container}>
            <div className={styles.form}>
              <Typography variant="h6" color="inherit" className={styles['title']}>Select the printing specification</Typography>
              <div className={styles['fields']}>
                <FormControl>
                  <InputLabel shrink> Type </InputLabel>
                  <Select
                    className={styles['field']}
                    value={selectedType}
                    onChange={(e) => { this.updateField({ selectedType: e.target.value }) }}
                    name="type"
                  >
                    {printingTypes.map((el, index) => (
                      <MenuItem value={el.value} key={`type-${index}`}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel shrink> Printer </InputLabel>
                  <Select
                    className={styles['field']}
                    value={selectedPrinter}
                    onChange={(e) => { this.updateField({ selectedPrinter: e.target.value }) }}
                    name="printer"
                  >
                    {printers.map((el, index) => (
                      <MenuItem value={el.id} key={`printer-${index}`}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles['button-group']}>
                <Button color="primary" variant="contained"
                  onClick={this.submitPrinting}
                  disabled={selectedPrinter < 0 || selectedType === ''}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </Paper>
        <FocusDialog open={notify} message={notice} variant={noticeType} onClose={this.closeNotification}/>
      </div >
    )
  }
}

export const PrintingForm = connect(state => ({
  printingTypes: state.printing.printingTypes,
  printers: state.printing.printers,
  notify: state.printing.notify,
  notice: state.printing.notice,
  noticeType: state.printing.noticeType,
  selectedPrinter: state.printing.selectedPrinter,
  selectedType: state.printing.selectedType,
}))(PrintingComponent);