import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';

import {
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { ChevronRightCircle } from "mdi-material-ui";
import {
  CLOSE_PROCUREMENT_DRAWER,
  UPDATE_PROCUREMENT_STATE,
  LOAD_SUGGESTIONS,
  CHANGE_PROCUREMENT_SUPPLIES,
} from '../../../actions';
import { SmartBox } from '@cosmos/chaos';
import { withStyles } from '@material-ui/styles';


const styles = theme => ({
  "drawer-root": {
    flex: 1,
    display: "flex",
    overflowY: "scroll"
  },
  "drawer-content": {
    padding: "0.75rem",
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  "btn-row": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  field: {
    flex: 1
  },
  container: {
    flex: 1
  },
  "flex-row": {
    display: "flex",
    flexDirection: "row",
    marginBottom: "0.25rem"
  },
  "fulfilments": {
    display: "none",
    "&.expanded": {
      display: "block"
    }
  },
  "add-btn": {
    margin:" 0.5rem 0"
  },
  "detail": {
    padding: "0.5rem",
    border: "solid 1px lightgrey",
    borderRadius: "0.5rem",
    marginBottom: "1rem",
    "&.selected": {
      backgroundColor: "darken(white, 5)"
    }
  } 
})

class DrawerComponent_ extends Component {
  state = {
    selectedAlternative: null
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedProcurement && this.props.selectedProcurement.productId
      && (!prevProps.selectedProcurement || !prevProps.selectedProcurement.productId || this.props.selectedProcurement.productId !== prevProps.selectedProcurement.productId)) {
      this.props.dispatch({ type: LOAD_SUGGESTIONS, payload: { productIds: [this.props.selectedProcurement.productId], tiers: [2, 3] } });
      this.setState({ selectedAlternative: null });
    }
  }

  closeProcurementDrawer = () => {
    this.props.dispatch({ type: CLOSE_PROCUREMENT_DRAWER, payload: { procurementDrawer: false, selectedProcurementId: -1 } });
  }

  updateProcurementState = value => {
    this.props.dispatch({ type: UPDATE_PROCUREMENT_STATE, payload: value });
  }

  updateSelectedSupplies = value => {
    this.setState({ selectedAlternative: value });
  }

  submitAlternativeSupplies = () => {
    this.props.dispatch({ type: CHANGE_PROCUREMENT_SUPPLIES, payload: { selectedAlternative: this.state.selectedAlternative, procurementId: this.props.selectedProcurement.id } });
  }

  render() {
    const { procurementStateList, selectedProcurement, alternativeSupplies, height, width, classes } = this.props;
    const { selectedAlternative } = this.state;

    return (
      <div className={classes['drawer-root']} style={{width, height}}>
        <div className={classes['drawer-content']}>
          <div className={classes['btn-row']}>
            <IconButton onClick={this.closeProcurementDrawer}>
              < ChevronRightCircle color='primary' />
            </IconButton>
          </div>
          {
            selectedProcurement && (
              <div className={classes['container']}>
                <div className={classes['flex-row']}>
                  <TextField
                    id="productName" label="Model"
                    value={selectedProcurement.productName ? selectedProcurement.productName : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                  />
                </div>

                <div className={classes['flex-row']}>
                  <TextField
                    id="brandName" label="Brand"
                    value={selectedProcurement.brandName ? selectedProcurement.brandName : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    id="productReference" label="Reference"
                    value={selectedProcurement.productReference ? selectedProcurement.productReference : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                  />
                </div>

                <div className={classes['flex-row']}>
                  <TextField
                    id="createdAt" label="Created At"
                    value={selectedProcurement.createdAt ? selectedProcurement.createdAt : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                    type={'date'}
                  />
                  <TextField
                    id="expireAt" label="Expire At"
                    value={selectedProcurement.expireAt ? selectedProcurement.expireAt : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                    type={'date'}
                  />
                </div>

                <div className={classes['flex-row']}>
                  <TextField
                    id="price" label="Price"
                    value={selectedProcurement.price ? selectedProcurement.price : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    id="remarks" label="Remarks"
                    value={selectedProcurement.remarks ? selectedProcurement.remarks : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                  />
                </div>

                <div className={classes['flex-row']}>
                  <TextField
                    id="reservationPic" label="Person In Charge"
                    value={selectedProcurement.reservationPic ? selectedProcurement.reservationPic : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    id="reservationReference" label="Reservation Reference"
                    value={selectedProcurement.reservationReference ? selectedProcurement.reservationReference : ''}
                    className={classes['field']}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ readOnly: true }}
                  />
                </div>

                <div className={classes['flex-row']}>
                  <FormControl className={classes['field']}>
                    <InputLabel shrink>State </InputLabel>
                    <Select
                      value={isNaN(selectedProcurement.state) ? '' : selectedProcurement.state}
                      onChange={(e) => { this.updateProcurementState({ state: e.target.value }) }}
                      name="state"
                    >
                      {Object.values(procurementStateList).map((el, index) => (
                        <MenuItem value={el.id} key={`user_${index}`}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className={classes['flex-row']}>
                  {
                    selectedProcurement.state === 0 &&
                    (
                      <div className={classes['field']}>
                        <InputLabel shrink>Alternative Suggestion</InputLabel>
                        <SmartBox
                          suggestionProvider={val => {
                            let count = 0;
                            let result = alternativeSupplies && alternativeSupplies.filter(el => {
                              const keep = el.name.toUpperCase().replace(/[ .-/]/g, '').includes(val.toUpperCase().replace(/[ .-/]/g, '')) && count < 10;
                              if (keep) {
                                count += 1;
                              }
                              return keep;
                            }).map(el => ({ label: el.name, value: el.id, ...el }));
                            return result;
                          }}
                          searchText={selectedAlternative ? selectedAlternative.name : ''}
                          updateSearchText={val => {
                            if (typeof val === 'object') {
                              this.updateSelectedSupplies(val);
                            }
                            else {
                              this.updateSelectedSupplies(null);
                            }
                          }}
                          updateSelectedItem={val => {
                            this.updateSelectedSupplies(val);
                          }}
                          onOuterClick={this.submitAlternativeSupplies}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}


export const DrawerComponent = connect(state => {
  const { procurements, selectedProcurementId } = state.reservation;
  const { fulfilmentSuggestions } = state.tender;
  const selectedProcurement = procurements[selectedProcurementId];
  // const alternativeSupplies = selectedProcurement && selectedProcurement.productId && fulfilmentSuggestions[selectedProcurement.productId] ? fulfilmentSuggestions[selectedProcurement.productId] : [];
  const alternativeSupplies = fulfilmentSuggestions ? fulfilmentSuggestions : [];
  return {
    procurementStateList: state.reservation.procurementStateList,
    selectedProcurement: selectedProcurement,
    alternativeSupplies: alternativeSupplies,
  }
})(withStyles(styles)(DrawerComponent_));