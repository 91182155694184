import React, { useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AutoSizer } from 'react-virtualized'
import Input from '@material-ui/core/Input'
import { ProvisionConditionComponent } from './components/provisionCondition';
import {  DataGrid, TableToolbar, authorized, Authorization } from '@cosmos/chaos'
import { Paper, LinearProgress, Button, alpha, withStyles } from '@material-ui/core'
import { FocusDialog } from '../../components';
import { Image, CommentTextOutline } from "mdi-material-ui";
import moment from 'moment';
import {
    BRAND_FILTER_UPDATED,
    SUPPLIER_FILTER_UPDATED,
    INV_LOAD_BEGIN,
    PROVISION_FILTER_UPDATED,
    CONDITION_FILTER_UPDATED,
    HOLDER_FILTER_UPDATED,
    UPDATE_SEARCH_KEY,
    CLOSE_INVENTORY_NOTICE,
    CANCEL_INV_LOAD,
    BEGIN_CHANGE_LABEL,
    INIT_TRADER_LOAD,
    COUNTRY_FILTER_UPDATED,
    OPEN_STOCK_IMAGES,
    CLOSE_IMAGE_DIALOG,
    OPEN_PROD_IMAGES,
    INV_RELOAD_BEGIN,
} from '../../actions'
import { FilterBar } from '../../components/tableFilter';
import { ImageDialog } from '../../components/dialog';
import { FooterView } from './components/footer';
import { ChangeLabelDialog } from './components/dialog/inventory';
import { reduce } from 'lodash';
import NumberFormat from 'react-number-format';
import { push } from 'connected-react-router';

const styles = theme => ({
    spacer: {
        flex:1
    },
    inventory: {
        flexGrow: 1,
        display: "flex",
        padding: "0.5em"
    },
    feature: {
        flex: 1,
        display: "flex",
        flexDirection: "column"
    },
    toolset: {
        display: "flex"
    },
    messageBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: "0.5em"
    },
    toolbar: {
        backgroundColor: theme.palette.primary.light
    },
    content: {
        flex: 1
    },
    "action-button": {
        marginRight: "8px"
    },
    "search-input": {
        padding: "4px 4px 4px 60px"
    },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "80%",
        height: "48px"
    },
    icon: {
        width: "16px",
        height: "16px",
        margin: "0 0.25em"
    },
    price: {
        display: "flex",
        justifyContent: "space-between"
    },
    currency: {
        marginRight: "1em"
    },
    numbers: {
        marginRight: "1em"
    },
    "header-sortable" : {
        display: "flex",
        flexWrap: "wrap",
        color: "#333",
        justifyContent: "flex-start"
    },
    "header-sortable-active": {
        color: "#000",
        "&:headerSortableIndex": {
            display: "block"
        },
        "&.headerSortableIndex": {
            display: "block"
        },
        "&$headerSortableIndex": {
            display: "block"
        }
    },
    headerSortableIndex: {
        display: "none",
        fontSize: "80%"
    },
    "header-sortable-icon": {
        width: "12px",
        height: "12px",
        color: "#3333EE"
    },
    "birth-col": {
        display: "flex",
        flexDirection: "row"
    },
    "birth": {
        width: "2rem",
        textAlign: "center"
    },
    dataHighlight: {
        background: alpha(theme.palette.warning.light, 0.25)
    },
    "condition": {
        display: "flex"
    }
});

const InventoryComponent = props => {
    const dispatch = useDispatch();
    const [orders, setOrders] = useState(new Map());
    
    useEffect(()=> {
        dispatch({ type: CANCEL_INV_LOAD });
        dispatch({ type: INIT_TRADER_LOAD });
        dispatch({ type: INV_LOAD_BEGIN, payload: { filter: {} } });
        dispatch({ type: INV_RELOAD_BEGIN, payload: { filter: {} } });
    },[dispatch])

    const closeNotification = () => {
        dispatch({ type: CLOSE_INVENTORY_NOTICE });
    }

   const updateSorting = (key) => {
        if (orders.has(key)) {
            const dir = orders.get(key);
            if (dir === 'asc') {
                orders.set(key, 'desc');
            } else {
                orders.delete(key);
            }
        } else {
            orders.set(key, 'asc');
        }
        setOrders(orders);
    }

    const handleFilter = event => {
        const searchText = event.target.value
        if (searchText !== "undefined") {
            dispatch({ type: UPDATE_SEARCH_KEY, payload: searchText })
        }
    }

    const selectBrands = (e) => {
        dispatch({ type: BRAND_FILTER_UPDATED, payload: e.target.value })
    }

    const selectProvisions = (e) => {
        dispatch({ type: PROVISION_FILTER_UPDATED, payload: e.target.value })
    };
    const selectedSuppliers = (e) => {
        dispatch({ type: SUPPLIER_FILTER_UPDATED, payload: e.target.value })
    };
    const selectConditions = (e) => {
        dispatch({ type: CONDITION_FILTER_UPDATED, payload: e.target.value })
    };

    const selectHolders = (e) => {
        dispatch({ type: HOLDER_FILTER_UPDATED, payload: e.target.value })
    };

    const selectCountries = (e) => {
        dispatch({ type: COUNTRY_FILTER_UPDATED, payload: e.target.value })
    }

    const changeLabel = () => {
        dispatch({ type: BEGIN_CHANGE_LABEL });
    }

    const displayToolBox = () => (
        <Authorization allowed={['cosmos.inventory.changelabel']}>
            {({ permit }) => (permit(permission) &&
                <Button color="primary" variant="contained" onClick={changeLabel} className={classes['action-button']}>
                    Change Label
                </Button>
            )}
        </Authorization>
    )

    const showStockImages = (stockId) => {
        dispatch({ type: OPEN_STOCK_IMAGES, payload: { id: stockId } })
    }

    const showProdImages = (productId) => {
        dispatch({ type: OPEN_PROD_IMAGES, payload: { id: productId } })
    }

    const closeImageDialog = () => {
        dispatch({ type: CLOSE_IMAGE_DIALOG })
    }

    const ableStockIn = authorized(['cosmos.operation.%']);
    const ableViewCost = authorized(['cosmos.operation.%']);
    const ableViewLocation = authorized(['cosmos.operation.%']);

        const {
            currencies,
            permission,
            loading,
            inventory,
            brands,
            products,
            searchText,
            selectedBrands,
            selectedSupplier,
            selectedProvision,
            selectedCondition,
            selectedHolders,
            noticeType,
            notice,
            notify,
            totalCost,
            conditionList,
            provisionList,
            dataRenderedTime,
            traders,
            holderList,
            gearUpCount,
            selectedCountries,
            countryList,
            imageDialog,
            dataUris,
            classes
        } = props;

        const columns = [
            {
                name: 'stockAttachments',
                header: '',
                cell: rowData => {
                    if(rowData.attachments && rowData.attachments.length > 0)
                        return <Image onClick={() => { showStockImages(rowData.id) }} />
                },
                cellProps: {
                    style: { paddingLeft: 10 },
                }
            },
            {
                name: 'id',
                header: 'ID',
                onHeaderClick: () => {
                    updateSorting('id');
                },
            },
            {
                name: 'brandName',
                header: 'Brand',
                cell: rowData => (
                    <div>
                        {brands && brands[rowData.brandId] && brands[rowData.brandId].name}
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('brandName');
                }
            },
            {
                name: 'productAssets',
                header: '',
                cell: rowData => {
                    if(rowData.productId && products && products[rowData.productId] && products[rowData.productId].assets && products[rowData.productId].assets.length > 0 )
                        return <Image onClick={() => { showProdImages(rowData.productId) }} />
                }
            },
            {
                name: 'productName',
                header: 'Model',
                cell: rowData => (
                    <div>
                        {products && products[rowData.productId] && products[rowData.productId].name}
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('productName');
                },
            },
            {
                name: 'productReference',
                header: 'Reference',
                cell: rowData => (
                    <div>
                        {products && products[rowData.productId] && products[rowData.productId].reference}
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('productReference');
                }
            },
            {
                name: 'productColor',
                header: 'Color',
                cell: rowData => (
                    <div>
                        {products && products[rowData.productId] && products[rowData.productId].dialColor}
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('productReference');
                }
            },
            {
                name: 'comment',
                header: 'Comment',
                cell: rowData => (
                    <div>
                        <CommentTextOutline />
                    </div>
                )
            },
            {
                name: 'serial',
                header: 'Serial',
                onHeaderClick: () => {
                    updateSorting('serial');
                }
            },
            {
                name: 'state',
                header: 'Status',
                onHeaderClick: () => {
                    updateSorting('state');
                }
            },
            {
                name: 'condition',
                header: 'Cond.',
                cell: rowData => (
                    <div className={classes["condition"]}>
                        <ProvisionConditionComponent className={classes['root']} condition={parseInt(rowData.condition)} /> 
                        <ProvisionConditionComponent  className={classes['root']} provision={parseInt(rowData.provision)} />
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('condition');
                }
            },
            {
                name: 'cCode',
                header: 'CC',
                cell: rowData => (
                    <div>
                        {rowData.cCode}
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('cCode');
                }
            },
            {
                name: 'birth',
                header: 'Birthday',
                cell: rowData => (
                    <div className={classes["birth-col"]}>
                        <div> {rowData.birthYear && rowData.birthYear+"-"} </div>
                        <div> {rowData.birthMonth && rowData.birthMonth+"-" } </div>
                        <div> {rowData.birthDate && rowData.birthDate} </div>
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('birth');
                }
            },
            {
                name: 'storeName',
                header: 'Holder',
                cell: rowData => (
                    <div>
                        {holderList && holderList[rowData.storeId] && holderList[rowData.storeId].name}
                        {/* {ableViewLocation(permission) ? rowData.storeName : rowData.countryName} */}
                    </div>
                ),
                onHeaderClick: () => {
                    ableViewLocation(permission) ? updateSorting('storeName') : updateSorting('countryName');
                }
            },
            {
                name: 'supplier',
                header: 'Supplier',
                cell: rowData => (
                    <div>
                        {traders[rowData.supplier] ? traders[rowData.supplier].name : ''}
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('supplier');
                }
            },
            {
                name: 'rec',
                header: 'REC',
                cell: rowData => (
                    <div>
                        Rec
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('rec');
                }
            },
            {
                name: 'stockIn',
                header: 'Received At',
                cell: rowData => (
                    <div>
                        { rowData.stockIn ? moment(rowData.stockIn).format("YYYY-MM-DD") : null }
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('stockIn');
                }
            },
            {
                name: 'purchasedAt',
                header: 'Purchased At',
                cell: rowData => (
                    <div>
                        { rowData.purchasedAt ? moment(rowData.purchasedAt).format("YYYY-MM-DD") : null }
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('purchasedAt');
                }
            },
            {
                name: 'cost',
                header: 'Cost',
                cell: rowData => ableViewCost ?
                    (
                        <NumberFormat
                            value={rowData.cost}
                            displayType="text"
                            thousandSeparator={true}
                            renderText={value => (
                                <div className={classes.price}>
                                    <div className={classes.currency}>
                                        {currencies && currencies[rowData.storeCurrencyId] && rowData.cost
                                            ? currencies[rowData.storeCurrencyId].symbol : ""}
                                    </div>
                                    <div className={classes.numbers}>
                                        {value}
                                    </div>
                                </div>
                            )}
                        />
                    ):(<div></div>)
                ,
                onHeaderClick: () => {
                    updateSorting('cost')
                }
            },
            {
                name: 'location',
                header: 'Location',
                cell: rowData => (
                    <div>
                        {null}
                    </div>
                ),
                onHeaderClick: () => {
                    updateSorting('location');
                }
            }
            
        ]

        let newItem = null;
        if (ableStockIn(permission)) {
            newItem = () => { dispatch(push('/orders/purchase/stockin'));}
        }

        const units = {
            brand: {
                data: brands,
                selected: selectedBrands,
                handleChange: selectBrands
            },
            provision: {
                data: provisionList,
                selected: selectedProvision,
                handleChange: selectProvisions
            },
            condition: {
                data: conditionList,
                selected: selectedCondition,
                handleChange: selectConditions
            },
            supplier: {
                data: traders,
                selected: selectedSupplier,
                handleChange: selectedSuppliers
            }
        };

        if (ableViewLocation(permission)) {
            units.holder = {
                data: holderList,
                selected: selectedHolders,
                handleChange: selectHolders
            }
        }
        else {
            units.country = {
                data: countryList,
                selected: selectedCountries,
                handleChange: selectCountries
            }
        }

        return (
            <div className={classes.inventory}>
                <Paper className={classes.feature}>
                    <TableToolbar
                        numSelected={0}
                        title="Inventory"
                        classes={{ root: classes.toolbar, toolset: classes.toolset, messageBox: classes.messageBox }}
                        searchBox={() => (
                            <Input
                                placeholder="ID/ Serial/ Reference ..."
                                disableUnderline
                                value={searchText}
                                onChange={handleFilter}
                                className={classes['search-input']}
                                fullWidth
                            />
                        )}
                        filterBox={() =>
                            (
                                <FilterBar
                                    units={units}
                                />
                            )
                        }
                        newItemTrigger={newItem}
                        toolBox={displayToolBox}
                    />

                    <ChangeLabelDialog />

                    <ImageDialog dialogOpen={imageDialog} closeDialog={closeImageDialog} dataUris={dataUris} />

                    <FocusDialog open={notify} message={notice} variant={noticeType} onClose={closeNotification} />
                    {loading && <LinearProgress />}
                    <div className={classes.content}>
                        <AutoSizer>
                            {({ width, height }) => (
                                <DataGrid
                                    data={inventory}
                                    columns={columns}
                                    width={width}
                                    zebra
                                    resizable = {true}
                                    maxHeight={height}
                                    height={height}
                                    includeHeaders={true}
                                    fixedRowCount={1}
                                    cellProps={{
                                        style: { paddingLeft: 2, paddingRight: 4 }
                                    }}
                                    isCellHovered={(column, rowData, hoveredColumn, hoveredRowData, ) => {
                                        return (rowData && rowData.id === hoveredRowData.id)
                                    }}
                                    isCellHighlighted={(column, rowData) => {
                                        return rowData.reservationId ? classes.dataHighlight : ''
                                    }}
                                    footer={e => (
                                        <FooterView
                                            dataRenderedTime={dataRenderedTime}
                                            showLegend={true}
                                            totalStockCount={Object.values(inventory).length}
                                            totalGearUpCount={gearUpCount}
                                            totalCost={totalCost}
                                        />
                                    )}
                                    orderBy={orders}
                                    classes={{
                                        sortableHeader: classes['header-sortable'],
                                        sortableHeaderActive: classes['header-sortable-active'],
                                        sortableHeaderIcon: classes['header-sortable-icon'],
                                        sortIndex: classes.headerSortableIndex
                                    }}

                                />
                            )}
                        </AutoSizer>
                    </div>
                </Paper>
            
    
            </div >
        )
    }

export const InventoryView = connect(state => {
    const { stocks, stocksDetail, stocksExtra, filteredStocks } = state.inventory;
    const marshalledStocks = Object.values(stocks).filter(el => filteredStocks.indexOf(el.id) > -1).map(el => {
        return { ...el, ...stocksDetail[el.id], ...stocksExtra[el.id] };
    })
    
    let totalCost = reduce(marshalledStocks, (r, d) => { return r + d.cost }, 0)
    let gearUpCount = Object.values(marshalledStocks).filter(el => el.state === "GEARUP").length;
    
    return {
        inventory: marshalledStocks,
        totalCost: totalCost || 0,
        dataRenderedTime: state.inventory.dataRenderedTime,
        currencies: state.system.currencies,
        brands: state.catalog.brands,
        products: state.catalog.products,
        permission: state.auth.permission,
        loading: state.inventory.loading,
        // stockInDialogOpen: state.inventory.stockInDialogOpen,
        conditionList: state.inventory.conditionList,
        provisionList: state.inventory.provisionList,
        searchText: state.inventory.search,
        selectedBrands: state.inventory.brandFilter,
        selectedProvision: state.inventory.provisionFilter,
        selectedCondition: state.inventory.conditionFilter,
        selectedSupplier: state.inventory.supplierFilter,
        noticeType: state.inventory.noticeType,
        notify: state.inventory.notify,
        notice: state.inventory.notice,
        stocks: state.inventory.stocks,
        traders: state.trader.traders,
        selectedHolders: state.inventory.holderFilter,
        holderList: state.system.stores,
        gearUpCount: gearUpCount,
        selectedCountries: state.inventory.countryFilter,
        countryList: state.system.countries,
        imageDialog: state.inventory.imageDialog,
        dataUris: state.inventory.dataUris,
    }
})(withStyles(styles)(InventoryComponent));
