import React from 'react';
import {Redirect} from "react-router";
import {PrivateRoute} from "@cosmos/chaos/esm/Auth";
import {useSelector} from "react-redux";

export const MarketingComponent = () => {
    const features = useSelector(state=>state.system.features);
    const func = features.find(f=>f.name === 'marketing');
    const subs = func.features
        .map(f => features.find(fs=>fs.name === f))
        .filter(f=>(!!f));
    return (
        <>
            {subs.map(f => (
                <PrivateRoute
                    exact
                    key={`mrk-${f.name}`}
                    path={`${func.path}${f.path}`}
                    allowed={f.permission}
                    component={f.component}
                />
            ))}
            <Redirect to={`${func.path}/supplies`}/>
        </>
    )
};

export * from './supplies';