import { DataGrid } from "@cosmos/chaos";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { IMPORT_SUMMARY, SELECT_IMPORT_ITEM } from "../../actions/marketing";
import * as _ from 'lodash'

export const ImportSummaryComponent = ({ props }) => {
    const dispatch = useDispatch();
    const suppliesOverview = useSelector((state) => state.marketing.suppliesOverview);
    const traders = useSelector((state) => state.trader.traders);


    useEffect(() => {
        dispatch({ type: IMPORT_SUMMARY });
    }, [dispatch]);

    const selectImportItem = (importDate, traderId) => {
        dispatch({ type: SELECT_IMPORT_ITEM, payload: { importDate, traderId } });
    }

    const columns = [
        {
            name: "traderId",
            header: "Vendor",
            width: "25",
            cell: (rowData) => {
                const trader = traders[rowData.traderId];
                if (!trader) return rowData.traderId;
                return trader.name;
            },
        },
        {
            name: "date",
            header: "Import Date",
            width: "26",
            cell: (rowData) => {
                return (rowData["date"]);
            },
        },
        {
            name: "count",
            header: "Count",
            width: "18",
        },
        {
            name: "success",
            header: "Success",
            width: "18",
        },
        {
            name: "fail",
            header: "Fail",
            width: "18",
        },
    ];

    return (

        <AutoSizer>
            {({ width, height }) => (
                <DataGrid
                    width={width}
                    height={height}
                    data={suppliesOverview}
                    columns={columns}
                    includeHeaders={true}
                    fixedRowCount={1}
                    isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) => {
                        return (rowData && _.isEqual(rowData, hoveredRowData))
                    }}
                    zebra
                    onCellClick={(column, { rowData }) => {
                        selectImportItem(rowData.importDate, rowData.traderId);
                    }}
                />
            )}
        </AutoSizer>

    );
};
