import { createVibes } from "@cosmos/vibes";
import {
  FIND_GEAR_UP_STK,
  GEAR_UP_ITEM_SUCCESS,
  SAVE_GEAR_UP,
  RESET_GEAR_UP_FORM,
  GEAR_UP_REMOVE_ITEM_BEGIN,
  GEAR_UP_REMOVE_ITEM_SUCCESS,
  GEAR_UP_REMOVE_ITEM_ERROR,
  FIND_GEAR_DOWN_STK,
  GEAR_DOWN_ITEM_SUCCESS,
  SAVE_GEAR_DOWN,
  RESET_GEAR_DOWN_FORM,
  GEAR_DOWN_REMOVE_ITEM_BEGIN,
  GEAR_DOWN_REMOVE_ITEM_SUCCESS,
  GEAR_DOWN_REMOVE_ITEM_ERROR,
  OPEN_GEAR_NOTICE,
  INV_LOAD_BEGIN,
  UPDATE_GEAR_LABEL,
} from "../actions";

export const findGearStockVibes = createVibes({
  type: [FIND_GEAR_UP_STK, FIND_GEAR_DOWN_STK],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { search, state } = action.payload;
      const token = getState().auth.token;
      const labs = getState().gear.labels;
      const { rayLens } = getState().system;

      if (labs.indexOf(search) > -1) {
        dispatch({
          type: OPEN_GEAR_NOTICE,
          payload: { notify: true, notice: "Stock already exists in the list", noticeType: "error" }
        });
        done();
      }
      else {
        const query = await axios.request({
          url: `/STK/${search}`,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            rayLens
          }
        });

        if (query.status < 400) {
          if (query.status === 200) {
            if (query.request && query.request.response) {
              const response = JSON.parse(query.request.response);
              if (response.item) {
                const result = await axios.request({
                  url: '/api',
                  method: 'post',
                  headers: {
                    'Authorization': `Bearer ${token}`,
                    rayLens
                  },
                  data: {
                    query: `query($filter: SingleStockFilter) {
                      stock(filter: $filter)
                      {
                          id brandId productId serial state
                      }
                    }`,
                    variables: { filter: { id: response.item, state } }
                  }
                });

                let data = result.data;
                const stock = data.stock;

                if (stock) {
                  dispatch({ type: UPDATE_GEAR_LABEL, payload: search });
                  if (response.reservationId) {
                    dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "The stock is already reserved", noticeType: "warning" } });
                  }
                  switch (action.type) {
                    case FIND_GEAR_UP_STK:
                      const gearUpStocks = getState().gear.gearUpStocks;
                      dispatch({ type: GEAR_UP_ITEM_SUCCESS, payload: { gearUpStocks: [stock, ...gearUpStocks], selected: 0 } });
                      break;
                    case FIND_GEAR_DOWN_STK:
                      const gearDownStocks = getState().gear.gearDownStocks;
                      dispatch({ type: GEAR_DOWN_ITEM_SUCCESS, payload: { gearDownStocks: [stock, ...gearDownStocks], selected: 0 } });
                      break;
                    default:
                  }
                } else {
                  dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "The stock is invalid", noticeType: "error" } });
                }
              }
              else {
                dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "The stock is invalid", noticeType: "error" } });
              }
            }
            else {
              dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "The stock is invalid", noticeType: "error" } });
            }
          } else {
            dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "The label is not yet attached to any stock", noticeType: "error" } });
          }
        } else {
          dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "The scanned label is invalid", noticeType: "error" } });
        }
      }
      done();
    } catch (err) {
      dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "The scanned label is invalid", noticeType: "error" } });
      done();
    }
  }
});

export const gearUpVibes = createVibes({
  type: SAVE_GEAR_UP,
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { gearUpStocks } = getState().gear;
      const stockIds = gearUpStocks.map(el => el.id);

      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `mutation($stockIds: [ID]){
            stockGearUp(stockIds: $stockIds){
              id state
            }
          }`,
          variables: { stockIds }
        }
      });

      let data = result.data;

      let stockGearUp = data.stockGearUp;

      if (stockGearUp) {
        dispatch({ type: RESET_GEAR_UP_FORM });
        dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "Gear Up Successfully!", noticeType: "success" } });
        dispatch({ type: INV_LOAD_BEGIN, payload: { filter: { states: ["GEARUP"] } } });
      } else {
        dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "Fail to Update Gear Up!", noticeType: "error" } });
      }
      done();
    } catch (err) {
      dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "Fail to Update Gear Up!", noticeType: "error" } });
      done();
    }
  }
});

export const removeGearUpVibes = createVibes({
  type: [GEAR_UP_REMOVE_ITEM_BEGIN],
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      let idx = action.payload;
      const { gearUpStocks, selected, labels } = getState().gear;

      if (idx > gearUpStocks.length) {
        dispatch({ type: GEAR_UP_REMOVE_ITEM_ERROR, payload: { notify: true, notice: "Fail to remove item", noticeType: "error" } });
      } else {
        const sele = selected < idx ? selected : selected > idx ? selected - 1 : 0;
        const gup = [...gearUpStocks.slice(0, idx), ...gearUpStocks.slice(idx + 1)];
        const labs = [...labels.slice(0, idx), ...labels.slice(idx + 1)];
        dispatch({ type: GEAR_UP_REMOVE_ITEM_SUCCESS, payload: { gearUpStocks: gup, selected: sele, labels: labs } });
      }
      done();
    } catch (err) {
      dispatch({ type: GEAR_UP_REMOVE_ITEM_ERROR, payload: { notify: true, notice: err, noticeType: "error" } });
      done();
    }
  }
});

export const gearDownVibes = createVibes({
  type: SAVE_GEAR_DOWN,
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { gearDownStocks } = getState().gear;
      const stockIds = gearDownStocks.map(el => el.id);

      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `mutation($stockIds: [ID]){
            stockGearDown(stockIds: $stockIds){
              id state
            }
          }`,
          variables: { stockIds }
        }
      });

      let data = result.data;

      let stockGearDown = data.stockGearDown;

      if (stockGearDown) {
        dispatch({ type: RESET_GEAR_DOWN_FORM });
        dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "Gear Down Successfully!", noticeType: "success" } });
        dispatch({ type: INV_LOAD_BEGIN, payload: { filter: { states: ["GEARUP"] } } });
      } else {
        dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "Fail to Update Gear Down!", noticeType: "error" } });
      }
      done();
    } catch (err) {
      dispatch({ type: OPEN_GEAR_NOTICE, payload: { notify: true, notice: "Fail to Update Gear Down!", noticeType: "error" } });
      done();
    }
  }
});

export const removeGearDownVibes = createVibes({
  type: [GEAR_DOWN_REMOVE_ITEM_BEGIN],
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      let idx = action.payload;
      const { gearDownStocks, selected, labels } = getState().gear;

      if (idx > gearDownStocks.length) {
        dispatch({ type: GEAR_DOWN_REMOVE_ITEM_ERROR, payload: { notify: true, notice: "Fail to remove item", noticeType: "error" } });
      } else {
        const sele = selected < idx ? selected : selected > idx ? selected - 1 : 0;
        const gup = [...gearDownStocks.slice(0, idx), ...gearDownStocks.slice(idx + 1)];
        const labs = [...labels.slice(0, idx), ...labels.slice(idx + 1)];
        dispatch({ type: GEAR_DOWN_REMOVE_ITEM_SUCCESS, payload: { gearDownStocks: gup, selected: sele, labels: labs } });
      }
      done();
    } catch (err) {
      dispatch({ type: GEAR_DOWN_REMOVE_ITEM_ERROR, payload: { notify: true, notice: err, noticeType: "error" } });
      done();
    }
  }
});

