import AutoComplete from "@material-ui/lab/Autocomplete";
import {
  Checkbox,
  Button,
  Box,
  TextField
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../components/inputs";
import {
  CHANGE_TAX_SETTING,
  BULK_IMPORT_SET_TRADER,
  BULK_IMPORT_SET_CURRENCY,
  OPEN_IMPORT_SUPPLIES_DIALOG,
  UPDATE_IMPORT_COUNTRY,
  UPDATE_IMPORT_CURRENCY,
  UPDATE_IMPORT_PERIOD,
  UPDATE_IMPORT_SUPPLIER,
  UPDATE_IMPORT_TAX_MODE,
  UPDATE_IMPORT_TAX_RATE,
  SELECT_MARKETING_IMPORT_BRAND,
  TRADER_CREATE_NEW,
  TRADER_CLEAR_ERRORS,
} from "../../../actions";
import { BasicDialog } from "../../../components/dialog/BasicDialog";
import { Switch } from "../../../components/inputs";
import BrandInput from '../BrandInput';



const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default function VendorInformation({
  className,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { country, vendor, currency, permanent, brand, taxRate } = useSelector(state => state.marketing.import);
  const traders = useSelector(state => state.trader.traders)
  const error = useSelector(state => state.trader.error);
  const message = useSelector(state => state.trader.message);
  const recentlyAddedTrader = useSelector(state => state.trader.recentlyAdded);
  const countries = useSelector((state) => state.system.countries);
  const currencies = useSelector((state) => state.system.currencies);
  const [vendors, setVendors] = useState([]);
  const [createDialogSwitch, setCreateDialogSwitch] = useState(false);
  const vendorRef = useRef();
  const [newTrader, setNewTrader] = useState("");

  useEffect(() => {
    const currentCountry = parseInt(country ? country.id : 0);
    const selectedCountryVendors = Object.values(traders).filter(t => t.countryId === currentCountry)
    setVendors(selectedCountryVendors);
  }, [traders, country, setVendors]);

  // useEffect(() => {
  //   if (recentlyAddedTrader && traders) {
  //     dispatch({ type: UPDATE_IMPORT_SUPPLIER, payload: recentlyAddedTrader })
  //   }
  // }, [traders, dispatch, recentlyAddedTrader])

  return (
    <>
      <BasicDialog
        title={"Error"}
        open={!!error}
        fullScreen={false}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => {
          dispatch({ type: TRADER_CLEAR_ERRORS })
        }}
      >{error}
      </BasicDialog>
      <h2>
        Brand: </h2><BrandInput disabled={false} style={null} loading={false} value={brand}
        onChange={brand => dispatch({ type: SELECT_MARKETING_IMPORT_BRAND, payload: brand })} />
      <Switch checked={permanent} toggleChecked={(e) => dispatch({ type: UPDATE_IMPORT_PERIOD, payload: e.target.checked })} label="Permanent" />
      <div className={className}>
        <h1>Vendor Information</h1>
        <AutoComplete
          autoHighlight
          options={Object.values(countries)}
          getOptionLabel={(option) => option.name || ""}
          onChange={(event, value, reason) => {
            dispatch({ type: UPDATE_IMPORT_COUNTRY, payload: value || {} })
            //handleChange("country")({ target: { value } })
            if (value) {

              dispatch({ type: UPDATE_IMPORT_CURRENCY, payload: currencies[value.currencyId] })
              dispatch({ type: UPDATE_IMPORT_TAX_RATE, payload: value.vat })
            }
          }}
          value={country}
          renderInput={(params) => (
            <Input {...params} separateLabel label="Country" />
          )}
        />
        <AutoComplete
          //open={open}
          autoHighlight
          disabled={isEmpty(country)}
          options={Object.values(vendors)}
          getOptionLabel={(option) => option.name || ""}
          onChange={(event, value, reason) => {

            dispatch({ type: UPDATE_IMPORT_SUPPLIER, payload: value || {} })
            //handleChange("vendor")({ target: { value } })
          }}
          value={vendor}
          renderInput={(params) => (
            <Input {...params} separateLabel label="Vendor" inputRef={vendorRef} />
          )}
          noOptionsText={
            !isEmpty(country) ? <Box display="flex" justifyContent="space-between" alignItems="center">
              <span style={{ paddingRight: "5px" }}>Trader not found!</span>
              <Button
                variant="outlined"
                color="primary"
                onMouseDown={(e) => {
                  e.preventDefault();
                  //setCreateDialogSwitch(true);
                  dispatch({ type: TRADER_CREATE_NEW, payload: { name: vendorRef.current.value, countryName: country.name } })
                }} //onClick does not work
              >
                Add New Trader
        </Button>
            </Box> : "Select country first"
          }
        />
        <AutoComplete
          autoHighlight
          disabled={isEmpty(country)}
          options={Object.values(currencies)}
          getOptionLabel={(option) => option.code || ""}
          onChange={(event, value, reason) => {
            dispatch({ type: UPDATE_IMPORT_CURRENCY, payload: value || {} }) //this must be object
            //handleChange("currency")({ target: { value } })
          }}
          value={currency}
          renderInput={(params) => (
            <Input {...params} separateLabel label="Currency" />
          )}
        />
        <Input
          separateLabel
          value={taxRate}
          disabled={!country}
          onChange={(event, value, reason) => {
            dispatch({ type: UPDATE_IMPORT_TAX_RATE, payload: parseFloat(event.target.value) })
          }}
          label="Tax Rate"
          readOnly
        />

        <div><label>Is Price with Tax</label><Checkbox onChange={(event) => {
          dispatch({ type: UPDATE_IMPORT_TAX_MODE, payload: event.target.checked });
        }} /></div>


      </div>
    </>
  );
}
