import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TableToolbar } from '@cosmos/chaos';
import {
  Paper,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  withStyles
} from '@material-ui/core';
import { FocusDialog } from '../../components';
import {
  INIT_TRADER_LOAD,
  DEL_LOAD_BEGIN,
  CLOSE_DELIVERY_NOTICE,
  UPDATE_DEL_FIELD,
  UPDATE_TEXT_FIELD,
  UPDATE_DATE_RANGE,
  OPEN_BOX_OPEN_DIALOG,
  DISPLAY_DEL_RECEIPT,
} from '../../actions';
import { SendCheck, CheckBold, CubeSend, PackageVariantClosed, CubeScan, CheckAll, Cancel, AlertOutline } from 'mdi-material-ui';
import { FilterBar } from '../../components/tableFilter'
import { BoxOpenDialog } from './components/boxOpenDialog';
import { AutoSizer } from "react-virtualized";

const styles = theme => ({

  delivery:{
    flexGrow: 1,
    display: "flex",
    padding: "0.5em"
  },
  feature:{
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  toolset:{
    display: "flex"
  },
  messageBox:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "0.5em"
  },
  toolbar:{
    backgroundColor: theme.palette.primary.light
  },
  content:{
    flex: 1,
    display: "flex",
    flexWrap: "wrap"
  },
  card:{
    margin: "2em",
    width: "45em"
  },
  "card-content":{
    backgroundColor: "#e8eaf6",
    display: "flex",
    padding: 0
  },
  "content-arrangement":{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#9fa8da"
  },
  header:{
    padding: "1em",
    display: "flex",
    justifyContent: "space-between"
  },
  title:{
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  buttons:{
    display: "flex",
    justifyContent: "space-between"
  }, 
  button: {
    margin: "0.5em"
  },
  summary:{
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1em",
    marginBottom: "0.5em"
  },
  "item-container":{
    "flex": 1,
    backgroundColor: "#e8eaf6",
    display: "flex"
  },
  "left-part":{
    "flex": 1,
    display: "flex"
  },
  "left-container":{
    "flex": 1,
    padding: "1em",
    maxHeight: "30em",
    overflow: "scroll"
  },      
  field:{
    width: "15em",
    "&:last-child": {
      marginBottom: "0.5em"
    }
  },
  "right-part":{
    "flex": 2,
    display: "flex"
  },
  "right-container":{
    "flex": 1,
    padding: "1em",
    overflow: "scroll",
    borderRadius: "1em",
    backgroundColor: "#d1c4e9"
  },
  "delivery-item":{
    display: "flex",
    flexDirection: "column",
    marginBottom: "0.5em"
  }
})

class DeliveryComponent extends Component {

  componentDidMount() {
    this.props.dispatch({ type: INIT_TRADER_LOAD });
    this.props.dispatch({ type: DEL_LOAD_BEGIN, payload: { state: ['PREPARE', 'PACKED', 'TRANSIT', 'DELIVERED', 'BOXOPENED'] } });
  }

  closeNotification = () => {
    this.props.dispatch({ type: CLOSE_DELIVERY_NOTICE });
  }

  renderState = (state) => {
    switch (state) {
      case 'PREPARE':
        return (
          <div>
            <CubeScan color="inherit" /> {state}
          </div>
        );
      case 'PACKED':
        return (
          <div>
            <PackageVariantClosed color="inherit" /> {state}
          </div>
        );
      case 'TRANSIT':
        return (
          <div>
            <CubeSend color="inherit" /> {state}
          </div>
        );
      case 'DELIVERED':
        return (
          <div>
            <SendCheck color="inherit" /> {state}
          </div>
        );
      case 'CHECKIN':
        return (
          <div>
            <CheckBold color="inherit" /> {state}
          </div>
        );
      case 'COMPLETED':
        return (
          <div>
            <CheckAll color="inherit" /> {state}
          </div>
        );
      case 'CANCELLED':
        return (
          <div>
            <Cancel color="inherit" /> {state}
          </div>
        );
      case 'BOXOPENED':
        return (
          <div>
            <AlertOutline color="secondary" /> {state}
          </div>
        );
      default:
        return;
    }
  }

  updateField = (fieldName, value, did) => {
    this.props.dispatch({ type: UPDATE_DEL_FIELD, payload: { [fieldName]: value, id: did } });
  }

  updateTextField = (fieldName, value, did) => {
    this.props.dispatch({ type: UPDATE_TEXT_FIELD, payload: { data: { [fieldName]: value }, id: did } });
  }

  handleDateRangeFilter = range => {
    let dRange;
    if (range.start && range.end) {
      dRange = { start: range.start, end: range.end }
    }
    else if (range.start) {
      dRange = { start: range.start, end: null }
    }
    else if (range.end) {
      dRange = { start: null, end: range.end }
    }
    else {
      dRange = { start: null, end: null }
    }
    this.props.dispatch({ type: UPDATE_DATE_RANGE, payload: { selectedDateRange: dRange } });

    this.props.dispatch({
      type: DEL_LOAD_BEGIN, payload: {
        from: dRange.start ? dRange.start.clone().startOf('day').unix() : null,
        to: dRange.end ? dRange.end.clone().add(1, 'day').startOf('day').unix() : null
      }
    });
  }

  boxOpened = (did) => {
    this.props.dispatch({ type: OPEN_BOX_OPEN_DIALOG, payload: { boxOpenDialog: true, targetDeliveryId: did } });
  }

  removeExistedDoc = (did) => {
    this.props.dispatch({ type: UPDATE_DEL_FIELD, payload: { id: did } });
  }

  displayInvoice = (did) => {
    this.props.dispatch({ type: DISPLAY_DEL_RECEIPT, payload: { id: did }});
  }

  render() {
    const {
      loading,
      noticeType,
      notice,
      notify,
      deliveries,
      traders,
      currencies,
      products,
      couriers,
      docCouriers,
      selectedDateRange,
      classes
    } = this.props;

    return (
      <div className={classes.delivery}>
        <Paper className={classes.feature}>
          <TableToolbar
            numSelected={0}
            title="Delivery"
            styles={{ root: classes.toolbar, toolset: classes.toolset, messageBox: classes.messageBox }}
            filterBox={() => <FilterBar
              units={{
                dateRange: {
                  range: selectedDateRange,
                  handleChange: this.handleDateRangeFilter
                }
              }}
            />}
          />
          <BoxOpenDialog />
          <FocusDialog open={notify} message={notice} variant={noticeType} onClose={this.closeNotification} />
          {loading && <LinearProgress />}
          <div className={classes.content}>
            {
              deliveries && deliveries.map(del => (
                <Card className={classes.card} key={'delivery-' + del.id}>
                  <CardContent className={classes['card-content']}>
                    <div className={classes['content-arrangement']}>
                      <div className={classes['header']}>
                        <div className={classes['title']}>
                          <div>
                            <Typography variant='h6'>{del.reference}</Typography>
                          </div>
                          <div>
                            {this.renderState(del.state)}
                          </div>
                        </div>
                        <div className={classes['buttons']}>
                          <Button variant="contained" color="secondary" size="small" className={classes['button']} onClick={() => { this.boxOpened(del.id) }} disabled={del.state === 'CHECKIN' || del.state === 'COMPLETED' || del.state === 'BOXOPENED'}>Box Opened</Button>
                          <Button variant="contained" size="small" className={classes['button']} onClick={() => { this.removeExistedDoc(del.id) }} >New Invoice</Button>
                          <Button variant="contained" size="small" className={classes['button']} onClick={() => { this.displayInvoice(del.id) }} >Print Invoice</Button>
                        </div>
                      </div>
                      <div className={classes['summary']}>
                        <div>
                          <Typography variant='subtitle2'>
                            Qty: {del.goods && del.goods.length ? del.goods.length : 0}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant='subtitle2'>
                            Date: {del.date}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant='subtitle2'>
                            Amount: {currencies && del.goods && del.goods[0] && del.goods[0].sellCurrency && currencies[del.goods[0].sellCurrency] ? currencies[del.goods[0].sellCurrency].symbol : ''} {del.amount}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes['item-container']}>
                        <div className={classes['left-part']}>
                          <div className={classes['left-container']}>
                            <FormControl className={classes['field']}>
                              <InputLabel id={`sender-label-${del.id}`}>Sender</InputLabel>
                              <Select
                                labelId={`sender-label-${del.id}`}
                                id={`sender-${del.id}`}
                                value={del.sender}
                                onChange={(e) => this.updateField('sender', e.target.value, del.id)}
                              >
                                {
                                  traders && traders.map(td => (
                                    <MenuItem key={td.id} value={td.id}>{td.name}</MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>

                            <FormControl className={classes['field']}>
                              <InputLabel id={`receiver-label-${del.id}`}>Receiver</InputLabel>
                              <Select
                                labelId={`receiver-label-${del.id}`}
                                id={`receiver-${del.id}`}
                                value={del.receiver}
                                onChange={(e) => this.updateField('receiver', e.target.value, del.id)}
                              >
                                {
                                  traders && traders.map(td => (
                                    <MenuItem key={td.id} value={td.id}>{td.name}</MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>

                            <FormControl className={classes['field']}>
                              <InputLabel id={`courier-label-${del.id}`}>Courier</InputLabel>
                              <Select
                                labelId={`courier-label-${del.id}`}
                                id={`courier-${del.id}`}
                                value={del.courierId}
                                onChange={(e) => this.updateField('courierId', e.target.value, del.id)}
                              >
                                {
                                  couriers && couriers.map(td => (
                                    <MenuItem key={td.id} value={td.id}>{td.name}</MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>

                            <TextField
                              label="Tracking"
                              className={classes.field}
                              InputLabelProps={{ shrink: true }}
                              value={del.tracking ? del.tracking : ''}
                              onChange={(e) => { this.updateTextField('tracking', e.target.value, del.id) }}
                              onBlur={(e) => this.updateField('tracking', e.target.value, del.id)}
                            />

                            <FormControl className={classes['field']}>
                              <InputLabel id={`docCourier-label-${del.id}`}>Doc. Courier</InputLabel>
                              <Select
                                labelId={`docCourier-label-${del.id}`}
                                id={`docCourier-${del.id}`}
                                value={del.docCourierId}
                                onChange={(e) => this.updateField('docCourierId', e.target.value, del.id)}
                              >
                                {
                                  docCouriers && docCouriers.map(td => (
                                    <MenuItem key={td.id} value={td.id}>{td.name}</MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>

                            <TextField
                              label="Doc. Tracking"
                              className={classes.field}
                              InputLabelProps={{ shrink: true }}
                              value={del.docTracking ? del.docTracking : ''}
                              onChange={(e) => { this.updateTextField('docTracking', e.target.value, del.id) }}
                              onBlur={(e) => this.updateField('docTracking', e.target.value, del.id)}
                            />

                          </div>
                        </div>
                        <div className={classes['right-part']}>
                          <AutoSizer>
                            {({ width, height }) => (
                              <div className={classes['right-container']} style={{ width, height }}>
                                {
                                  del.goods && del.goods.map(gd => (
                                    <div className={classes['delivery-item']} key={`del-item-${gd.id}`}>
                                      <div>{products && gd.productId && products[gd.productId] ? `${products[gd.productId].reference} - ${gd.serial}` : gd.serial}</div>
                                      <div>{currencies && gd.sellCurrency && currencies[gd.sellCurrency] ? `${currencies[gd.sellCurrency].symbol} ${gd.sellAmount}` : gd.sellAmount} </div>
                                    </div>
                                  ))
                                }
                              </div>
                            )}
                          </AutoSizer>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))
            }
          </div>
        </Paper>
      </div >
    )
  }
}

export const DeliveryView = connect(state => {
  return {
    loading: state.delivery.loading,
    noticeType: state.delivery.noticeType,
    notify: state.delivery.notify,
    notice: state.delivery.notice,
    deliveries: Object.values(state.delivery.deliveries),
    traders: Object.values(state.trader.traders),
    currencies: state.system.currencies,
    products: state.catalog.products,
    couriers: state.delivery.couriers,
    docCouriers: state.delivery.docCouriers,
    selectedDateRange: state.delivery.selectedDateRange,
  }
}
)(withStyles(styles)(DeliveryComponent));
