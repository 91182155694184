export const EXTENSION_RECEIVED = "@@SYSTEM/EXTENSION_RECEIVED";
export const UPDATE_EXTENSION = "@@SYSTEM/UPDATE_EXTENSION";
export const UPDATE_RAY_STORE = "@@SYSTEM/UPDATE_RAY_STORE";

export const INIT_USER_LOAD = '@@system/INIT_USER_LOAD';
export const LOAD_USER_SUCCESS = '@@system/LOAD_USER_SUCCESS';
export const LOAD_USER_FAIL = '@@system/LOAD_USER_FAIL';

export const APP_INIT = '@@system/INIT';
export const APP_LOADED = '@@system/LOADED';
export const APP_UNLOAD = '@@system/UNLOAD';
export const APP_API_EXTENSIONS = '@@system/EXTENSIONS';
export const MENU_OP = "@@system/MENU_OP";
export const CHANGE_TAX_SETTING = "@@system/CHANGE_TAX_SETTING";

export const ENQUEUE_SNACKBAR = '@@system/ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = '@@system/CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = '@@system/REMOVE_SNACKBAR';

export const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key;

    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

export const closeSnackbar = key => ({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

export const removeSnackbar = key => ({
    type: REMOVE_SNACKBAR,
    key,
});