import {
  LOAD_TENDER, LOAD_TENDER_SUCCESS, LOAD_TENDER_ERROR,
  CLOSE_TENDER_NOTICE,
  UPDATE_TENDER_SEARCH_TEXT, UPDATE_FILTERED_TENDERS, UPDATE_SELECTED_PERSONIC,
  UPDATE_TENDER_INPUT, RESET_TENDER_INPUT, UPDATE_TD_INPUT, UPDATE_FF_INPUT,
  CREATE_TENDER_DETAIL, REMOVE_TENDER_DETAIL, SELECT_TENDER_DETAIL,
  UPDATE_TENDER_DISABLE_BOOL, ASK_INIT_TENDER, CANCEL_INIT_TENDER,
  SUBMIT_TENDER_FORM, SUBMIT_TENDER_FORM_SUCCESS, SUBMIT_TENDER_FORM_ERROR, CONFIRM_INIT_TENDER,
  POPULATE_TENDER, RECORD_DELETE_TENDER_DETAIL,
  SAVE_UPDATED_TENDER, UPDATE_TENDER_FORM_SUCCESS, UPDATE_TENDER_FORM_ERROR,
  OPEN_TENDER_DRAWER, CLOSE_TENDER_DRAWER, CREATE_FULFILMENT, DELETE_FULFILMENT_SUCCESS,
  LOAD_SUGGESTIONS_SUCCESS,
  // REMOVE_SELECTED_SUGGESTIONS, ADD_SELECTED_SUGGESTIONS, 
  DELETE_FULFILMENT_FAIL,
  UPDATE_FULFILMENT_FAIL, UPDATE_FULFILMENT_SUCCESS, OPEN_TENDER_NOTICE,
} from "../actions";
import * as shortId from 'shortid';

const initialState = {
  tenders: {},
  loading: false,
  filteredTenders: [],
  notify: false,
  noticeType: 'info',
  notice: '',
  searchText: '',
  selectedPersons: [],
  tenderInput: {},
  selectedTdIdx: -1,
  tenderOnce: shortId.generate(),
  tenderStates: { 0: 'Opened', 1: 'Pending', 2: 'Failed', 3: 'Settled', 4: 'Cancelled' },
  disableTender: true,
  submitted: false,
  initTenderDialog: false,
  selectedTenderId: -1,
  deletedTenderDetails: [],
  drawer: false,
  // fulfilmentSuggestions: {},
  fulfilmentSuggestions: [
    {
      id: 1,
      name: 'Own Stock'
    },
    {
      id: 2,
      name: 'System Stock'
    },
    {
      id: 3,
      name: 'Buy from Outside'
    }
  ], 
  // selectedSuggestions: [],  //suggestion ids
};

export const tenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TENDER:
      return { ...state, loading: true }
    case LOAD_TENDER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case LOAD_TENDER_ERROR:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case CLOSE_TENDER_NOTICE:
      return {
        ...state,
        notify: false,
        noticeType: 'info',
        notice: '',
      }
    case UPDATE_TENDER_SEARCH_TEXT:
    case UPDATE_FILTERED_TENDERS:
    case UPDATE_SELECTED_PERSONIC:
    case UPDATE_TENDER_DISABLE_BOOL:
    case SUBMIT_TENDER_FORM_SUCCESS:
    case UPDATE_TENDER_FORM_SUCCESS:
    case POPULATE_TENDER:
    case RECORD_DELETE_TENDER_DETAIL:
    case LOAD_SUGGESTIONS_SUCCESS:
    case DELETE_FULFILMENT_FAIL:
    case UPDATE_FULFILMENT_SUCCESS:
    case UPDATE_FULFILMENT_FAIL:
    case OPEN_TENDER_NOTICE:
      return { ...state, ...action.payload }
    case SUBMIT_TENDER_FORM:
    case SAVE_UPDATED_TENDER:
      return { ...state, ...action.payload, submitted: true }
    case SUBMIT_TENDER_FORM_ERROR:
    case UPDATE_TENDER_FORM_ERROR:
      return { ...state, ...action.payload, submitted: false, tenderOnce: shortId.generate() }
    case UPDATE_TENDER_INPUT:
      return { ...state, tenderInput: { ...state.tenderInput, ...action.payload } }
    case RESET_TENDER_INPUT:
    case CANCEL_INIT_TENDER:
      localStorage.removeItem('tenderInput');
      localStorage.removeItem('selectedTdIdx');
      localStorage.removeItem('submitted');
      localStorage.removeItem('disableTender');
      return {
        ...state, tenderInput: {}, tenderOnce: shortId.generate(), selectedTdIdx: -1, disableTender: true, submitted: false, initTenderDialog: false, selectedTenderId: -1, deletedTenderDetails: [], drawer: false,
        // selectedSuggestions: [], 
      }
    case CREATE_TENDER_DETAIL:
      return {
        ...state,
        tenderInput: {
          ...state.tenderInput,
          details: (state.tenderInput.details ? [...state.tenderInput.details, {}] : [{}])
        },
        selectedTdIdx: state.tenderInput.details ? state.tenderInput.details.length : 0,
      }
    case REMOVE_TENDER_DETAIL:
      return {
        ...state,
        selectedTdIdx: (state.selectedTdIdx < action.payload) ? state.selectedTdIdx : ((state.selectedTdIdx > action.payload) ? state.selectedTdIdx - 1 : ((state.selectedTdIdx === 0) ? 0 : state.selectedTdIdx - 1)),
        tenderInput: {
          ...state.tenderInput,
          details:
            [
              ...state.tenderInput.details.slice(0, action.payload),
              ...state.tenderInput.details.slice(action.payload + 1)
            ]
        }
      };
    case SELECT_TENDER_DETAIL:
      return {
        ...state,
        selectedTdIdx: (state.tenderInput.details && action.payload >= state.tenderInput.details.length) ? state.selectedTdIdx : action.payload
      }
    case UPDATE_TD_INPUT:
      return {
        ...state,
        tenderInput: {
          ...state.tenderInput,
          details: [
            ...state.tenderInput.details.slice(0, action.payload.index),
            { ...state.tenderInput.details[action.payload.index], ...action.payload.payload },
            ...state.tenderInput.details.slice(action.payload.index + 1)
          ]
        }
      };
    case UPDATE_FF_INPUT:
      return {
        ...state,
        tenderInput: {
          ...state.tenderInput,
          details: [
            ...state.tenderInput.details.slice(0, action.payload.index),
            {
              ...state.tenderInput.details[action.payload.index],
              fulfilments: [
                ...state.tenderInput.details[action.payload.index].fulfilments.slice(0, action.payload.fulfilmentIndex),
                {
                  ...state.tenderInput.details[action.payload.index].fulfilments[action.payload.fulfilmentIndex],
                  ...action.payload.payload
                },
                ...state.tenderInput.details[action.payload.index].fulfilments.slice(action.payload.fulfilmentIndex + 1)
              ]
            },
            ...state.tenderInput.details.slice(action.payload.index + 1)
          ]
        }
      };
    case ASK_INIT_TENDER:
      return { ...state, initTenderDialog: true };
    case CONFIRM_INIT_TENDER:
      let tenderInput = JSON.parse(localStorage.getItem('tenderInput'));
      let selectedTdIdx = JSON.parse(localStorage.getItem('selectedTdIdx'));
      let submitted = JSON.parse(localStorage.getItem('submitted'));
      let disableTender = JSON.parse(localStorage.getItem('disableTender'));
      return {
        ...state,
        loading: false,
        notify: false,
        noticeType: 'info',
        notice: '',
        tenderInput: tenderInput,
        selectedTdIdx: selectedTdIdx,
        tenderOnce: shortId.generate(),
        disableTender: disableTender,
        submitted: submitted,
        initTenderDialog: false,
        selectedTenderId: -1,
        deletedTenderDetails: [],
        drawer: false,
        // selectedSuggestions: [],
      }
    case OPEN_TENDER_DRAWER:
      return { ...state, drawer: true };
    case CLOSE_TENDER_DRAWER:
      return {
        ...state, drawer: false, tenderInput: {}, selectedTdIdx: -1, selectedTenderId: -1, deletedTenderDetails: [],
        // selectedSuggestions: [], 
      }
    case CREATE_FULFILMENT:
      return {
        ...state,
        tenderInput: {
          ...state.tenderInput,
          details: [
            ...state.tenderInput.details.slice(0, action.payload.tenderDetailIdx),
            {
              ...state.tenderInput.details[action.payload.tenderDetailIdx],
              quantity: state.tenderInput.details[action.payload.tenderDetailIdx].fulfilments ? state.tenderInput.details[action.payload.tenderDetailIdx].fulfilments.length + 1 : 1,
              fulfilments:
                state.tenderInput.details[action.payload.tenderDetailIdx].fulfilments ?
                  [...state.tenderInput.details[action.payload.tenderDetailIdx].fulfilments, {}]
                  : [{}]
            },
            ...state.tenderInput.details.slice(action.payload.tenderDetailIdx + 1)
          ]
        },
      }
    case DELETE_FULFILMENT_SUCCESS:
      return {
        ...state,
        tenderInput: {
          ...state.tenderInput,
          details:
            [
              ...state.tenderInput.details.slice(0, action.payload.tenderDetailIdx),
              {
                ...state.tenderInput.details[action.payload.tenderDetailIdx],
                quantity: state.tenderInput.details[action.payload.tenderDetailIdx].fulfilments ? state.tenderInput.details[action.payload.tenderDetailIdx].fulfilments.length - 1 : 0,
                fulfilments: [
                  ...state.tenderInput.details[action.payload.tenderDetailIdx].fulfilments.slice(0, action.payload.fulfilmentIdx),
                  ...state.tenderInput.details[action.payload.tenderDetailIdx].fulfilments.slice(action.payload.fulfilmentIdx + 1),
                ]
              },
              ...state.tenderInput.details.slice(action.payload.tenderDetailIdx + 1)
            ]
        },
        tenders: {
          ...state.tenders,
          [state.selectedTenderId]: {
            ...state.tenders[state.selectedTenderId],
            details:
            [
              ...state.tenders[state.selectedTenderId].details.slice(0, action.payload.tenderDetailIdx),
              {
                ...state.tenders[state.selectedTenderId].details[action.payload.tenderDetailIdx],
                quantity: state.tenders[state.selectedTenderId].details[action.payload.tenderDetailIdx].fulfilments ? state.tenders[state.selectedTenderId].details[action.payload.tenderDetailIdx].fulfilments.length - 1 : 0,
                fulfilments: [
                  ...state.tenders[state.selectedTenderId].details[action.payload.tenderDetailIdx].fulfilments.slice(0, action.payload.fulfilmentIdx),
                  ...state.tenders[state.selectedTenderId].details[action.payload.tenderDetailIdx].fulfilments.slice(action.payload.fulfilmentIdx + 1),
                ]
              },
              ...state.tenders[state.selectedTenderId].details.slice(action.payload.tenderDetailIdx + 1)
            ]
          }
        }
      };
    // case REMOVE_SELECTED_SUGGESTIONS:
      // let removedSuggestions = state.selectedSuggestions ? state.selectedSuggestions.map(el => el) : [];
      // return {
      //   ...state,
      //   selectedSuggestions: removedSuggestions.filter(el => { return el !== action.payload })
      // }
    //   break;
    // case ADD_SELECTED_SUGGESTIONS:
      // let addedSuggestions = state.selectedSuggestions ? state.selectedSuggestions.map(el => el) : [];
      // addedSuggestions.push(action.payload);
      // return {
      //   ...state,
      //   selectedSuggestions: addedSuggestions
      // }
      // break;
    default:
      return state;
  }
};