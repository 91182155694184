import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './form.module.scss';
import classNames from 'classnames';
import {
  Button,
  Typography,
} from '@material-ui/core';
import { InfoComponent, DetailComponent } from './components';
import { CREATE_VENDOR, CLOSE_CREATE_VENDOR, ASK_CREATE_VENDOR, UPDATE_PURCHASE_THEN_STOCKIN, RESET_PURCHASE_FORM, UPDATE_PD_REMARKS, UPDATE_PD_PACKAGE_REMARKS, CONFIRM_INIT_PURCHASE, CANCEL_INIT_PURCHASE, ASK_INIT_PURCHASE, CLOSE_PURCHASE_NOTICE, POPULATE_DISC_RATE, UPDATE_PO_INPUT, CREATE_PURCHASE_DETAIL, REMOVE_PURCHASE_DETAIL, UPDATE_PD_INPUT, SELECT_PURCHASE_DETAIL, CREATE_PURCHASE_ORDER_BEGIN, INIT_TRADER_LOAD, SWAP_MODE, SAVE_PURCHASE } from "../../actions";
import { push } from 'connected-react-router';
import { Authorization } from '@cosmos/chaos';
import { PopulateDataDialog } from '../../components';
import { InvoiceComponent } from './components/invoice';
import { CreateVendorDialog } from './components/dialog/createVendor';
import { FocusDialog } from '../../components';

class PurchaseFormComponent extends Component {

  componentDidMount() {
    if (localStorage.getItem('purchaseInput')) {
      this.props.dispatch({ type: ASK_INIT_PURCHASE });
    }
    this.props.dispatch({ type: INIT_TRADER_LOAD, payload: {filter: { tier: 'EXTERNAL'}} }); //for vendor
  }

  updateField = (value) => {
    this.props.dispatch({ type: UPDATE_PO_INPUT, payload: value });
  }

  updatePdRemarks = (value) => {
    this.props.dispatch({ type: UPDATE_PD_REMARKS, payload: value });
  }

  updatePdPackageRemarks = (value) => {
    this.props.dispatch({ type: UPDATE_PD_PACKAGE_REMARKS, payload: value });
  }

  populateDiscountRate = (value) => {
    this.props.dispatch({ type: POPULATE_DISC_RATE, payload: value });
  }

  handleCancel = () => {
    this.props.dispatch(push('/orders/purchase'));
    this.props.dispatch({ type: SWAP_MODE, payload: false });
    this.props.dispatch({ type: RESET_PURCHASE_FORM });
  }

  confirmPO = () => {
    this.props.dispatch({ type: CREATE_PURCHASE_ORDER_BEGIN, payload: {state: 'CONFIRMED'} });
  }

  createNewDetail = () => {
    this.props.dispatch({ type: CREATE_PURCHASE_DETAIL });
  }

  removeDetail = (idx) => {
    this.props.dispatch({ type: REMOVE_PURCHASE_DETAIL, payload: idx });
  }

  updatePDField = (value) => {
    this.props.dispatch({ type: UPDATE_PD_INPUT, payload: value });
  }

  selectDetail = (idx) => {
    this.props.dispatch({ type: SELECT_PURCHASE_DETAIL, payload: idx });
  }

  savePO = () => {
    this.props.dispatch({ type: CREATE_PURCHASE_ORDER_BEGIN, payload: {state: 'PENDING'} });
  }

  saveAndStockIn = () => {
    this.props.dispatch({ type: UPDATE_PURCHASE_THEN_STOCKIN });
  }

  closeNotification = () => {
    this.props.dispatch({ type: CLOSE_PURCHASE_NOTICE });
  };

  closeInitDialog = () => {
    this.props.dispatch({ type: CANCEL_INIT_PURCHASE });
  }

  confirmInit = () => {
    this.props.dispatch({ type: CONFIRM_INIT_PURCHASE });
  }

  editPO = () => {
    this.props.dispatch({ type: SAVE_PURCHASE });
  }

  initCreateVendor = () => {
    const vendorInput = this.props.purchaseInput.vendor;
    if(vendorInput && isNaN(vendorInput)){
      this.props.dispatch({ type: ASK_CREATE_VENDOR }); 
    }
  }

  closeCreateVendorDialog = () => {
    this.props.dispatch({ type: CLOSE_CREATE_VENDOR })
  }

  createVendor = () => {
    this.props.dispatch({ type: CREATE_VENDOR });
  }

  render() {
    const { askCreateVendor, countries, conditionOptions, editMode, permission, purchaseTypes, currencies, purchaseInput, traders, products, selectedPdIdx, notice, noticeType, notify, initPurchaseDialog, purchases, reviewedPurchaseId, disablePo } = this.props;
    return (
      <Authorization allowed={['cosmos.purchase.create']}>
        {({ permit }) => (permit(permission) &&
          <div className={styles.root}>
            <div className={styles.title}>
              <Typography variant="h6"> {editMode ? 'Edit Purchase Order' : 'Create Purchase Order'} </Typography>
            </div>
            <div className={classNames(styles['flex-row'], styles['flex-1'])}>
              <div className={classNames(styles['flex-column'], styles['left-container'])}>
                <div className={classNames(styles['flex-1'], styles['left-component'])}>
                  <InfoComponent
                    readOnly={false}
                    purchaseTypes={purchaseTypes}
                    currencies={currencies}
                    updateField={this.updateField}
                    purchaseInput={purchaseInput}
                    traders={traders}
                    populateDiscountRate={this.populateDiscountRate}
                    countries={countries}
                    createVendor={this.initCreateVendor}
                  />
                </div>
                <div className={classNames(styles['flex-1'], styles['left-component'])}>
                  <InvoiceComponent />
                </div>
              </div>
              <div className={classNames(styles['flex-column'], styles['right-container'])}>
                <DetailComponent
                  purchaseInput={purchaseInput}
                  createNewDetail={this.createNewDetail}
                  removeDetail={this.removeDetail}
                  products={products}
                  updatePDField={this.updatePDField}
                  updatePdRemarks={this.updatePdRemarks}
                  updatePdPackageRemarks={this.updatePdPackageRemarks}
                  selectDetail={this.selectDetail}
                  selectedPdIdx={selectedPdIdx}
                  readOnly={false}
                  currencySymbol={currencies[purchaseInput.currency] ? currencies[purchaseInput.currency].symbol : ""}
                  conditionOptions={conditionOptions}
                />
              </div>
            </div>
            <div className={classNames(styles['flex-row'], styles['flex-1'], styles['flex-end'], styles['button-group'])}>
              <Button color="primary" variant="outlined" className={styles['button']} onClick={this.handleCancel}>
                Cancel
              </Button>
              {!editMode &&
                <Button color="primary" variant="contained" className={styles['button']} onClick={this.savePO} disabled={disablePo}>
                  Save
                </Button>
              }
              {editMode && purchases[reviewedPurchaseId] && purchases[reviewedPurchaseId].restQuantity > 0 &&
                <Button color="primary" variant="contained" className={styles['button']} onClick={this.saveAndStockIn}>
                  Stock In edit mode // currently not in use
                </Button>
              }
              <Button color="primary" variant="contained" className={styles['button']} onClick={editMode ? this.editPO : this.confirmPO} disabled={disablePo}>
                {editMode ? 'Save' : 'Confirm'}
              </Button>


            </div>
            <FocusDialog open={notify} message={notice} variant={noticeType} onClose={this.closeNotification}/>
            <PopulateDataDialog
              dialogOpen={initPurchaseDialog}
              closeDialog={this.closeInitDialog}
              handleConfirm={this.confirmInit}
            />

            <CreateVendorDialog
              closeDialog={this.closeCreateVendorDialog}
              confirmCreation={this.createVendor}
              vendorName={purchaseInput.vendor}
              dialogOpen={askCreateVendor}
            />

          </div>
        )}
      </Authorization>
    )
  }
}

export const PurchaseForm = connect(state => ({
  permission: state.auth.permission,
  purchaseTypes: state.purchase.purchaseTypes,
  purchases: state.purchase.purchases,
  currencies: state.system.currencies,
  purchaseInput: state.purchase.purchaseInput,
  traders: state.trader.traders,
  products: state.catalog.products,
  selectedPdIdx: state.purchase.selectedPdIdx,
  notify: state.purchase.notify,
  notice: state.purchase.notice,
  noticeType: state.purchase.noticeType,
  initPurchaseDialog: state.purchase.initPurchaseDialog,
  editMode: state.purchase.editMode,
  reviewedPurchaseId: state.purchase.reviewedPurchaseId,
  conditionOptions: state.purchase.conditionOptions,
  disablePo: state.purchase.disablePo,
  countries: state.system.countries,
  askCreateVendor: state.purchase.askCreateVendor,
}))(PurchaseFormComponent);