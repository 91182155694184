export const LOAD_CATALOG_START = '@@catalog/LOAD_START';
export const LOAD_CATALOG_SUCCESS = "@@catalog/LOAD_SUCCESS";
export const LOAD_CATALOG_ERROR = "@@catalog/LOAD_ERROR";

export const APPEND_NEW_PRODUCT = "@@catalog/APPEND_NEW_PRODUCT";

export const loadCatalogSuccess = payload => ({ type: LOAD_CATALOG_SUCCESS, payload });
export const loadCatalogFail = err => ({ type: LOAD_CATALOG_ERROR, err });

export const LOAD_INFOS = '@@catalog/LOAD_INFOS';
export const UPDATE_INFOS = '@@catalog/UPDATE_INFOS';
export const LOAD_INFOS_ERROR= '@@catalog/LOAD_INFOS_ERROR';
export const INFOS_SEARCH = "@@catalog/INFO_SEARCH";
export const INFOS_LOADED = '@@catalog/INFOS_LOADED';
export const SELECT_INFOS_PRODUCT = "@@catalog/SELECT_INFOS_PRODUCT";
export const UPDATE_GTIN_TYPE = "@@catalog/UPDATE_GTIN_TYPE";
export const UPDATE_GTIN_VALUE = '@@catalog/UPDATE_GTIN_VALUE';
export const SUBMIT_GTIN = '@@catalog/SUBMIT_GTIN';
export const INFOS_MODE_CHANGE = '@@catalog/INFOS_MODE_CHANGE';
export const INFOS_BARCODE_UPDATE ='@@catalog/INFOS_BARCODE_UPDATE';
export const INFOS_LABEL_UPDATE = "@@catalog/INFOS_LABEL_UPDATE";
export const UPDATE_BARCODE = '@@catalog/UPDATE_BARCODE';
export const SUBMIT_BARCODE = "@@catalog/SUBMIT_BARCODE";
export const INFOS_DATA_UPDATE = "@@catalog/INFOS_DATA_UPDATE";
export const INFOS_DATA_ERROR = "@@catalog/INFOS_DATA_ERROR";
export const GTIN_SUCCESS = '@@catalog/GTIN_SUCCESS';
export const GTIN_ERROR = '@@catalog/GTIN_ERROR';
export const INFOS_MODE_PRIORITY ="@@catalog/INFOS_MODE_PRIORITY";
//TODO: fd