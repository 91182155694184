import { createVibes } from "@cosmos/vibes";
import { LOAD_TENDER, LOAD_TENDER_SUCCESS, LOAD_TENDER_ERROR } from "../../actions";
import { reduce } from 'lodash';
import moment from 'moment';

export const loadTenderVibes = createVibes({
  type: [LOAD_TENDER],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query {
            tenders { 
              id reference channel channelReference customer
              seller userId pic 
              state currency forexSet
              tracking originalCountry deliveryCountry
              expireAt filedAt confirmedAt 
              paidBy remarks
              createdAt updatedAt deletedAt
              details {
                id productId brandId quantity
                price tax amount
                condition userId
                createdAt updatedAt deletedAt
                remarks
                fulfilments {
                  id reference pic type source state tracking
                  remarks userId createdAt updatedAt deletedAt
                }
              }
            }
          }`
        }
      });

      let data = result.data;

      let payload = {
        tenders: reduce(data.tenders, (r, d) => {
          r[d.id] = {
            ...d,
            expireAt: d.expireAt ? moment(d.expireAt).format("YYYY-MM-DD") : null,
            filedAt: d.filedAt ? moment(d.filedAt).format("YYYY-MM-DD") : null,
            confirmedAt: d.confirmedAt ? moment(d.confirmedAt).format("YYYY-MM-DD") : null,
            remarks: d.remarks ? JSON.parse(d.remarks) : null,
            sum: reduce(d.details, (a, b) => {return a+= b.amount}, 0),
            details: d.details.map(el => {
              return {
                ...el,
                remarks: el.remarks ? JSON.parse(el.remarks) : null,
                fulfilments: el.fulfilments.map(ff => {
                  return {
                    ...ff,
                    remarks: ff.remarks ? JSON.parse(ff.remarks) : null,
                  }
                })
              }
            })
          };
          return r;
        }, {}),
        filteredTenders: data.tenders.map(el => el.id),
      };
      dispatch({ type: LOAD_TENDER_SUCCESS, payload: payload });
      done();
    } catch (err) {
      dispatch({ type: LOAD_TENDER_ERROR, payload: err });
      done();
    }
  }
});