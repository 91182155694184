import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DF_NOTICE_DISMISS,
  DF_SCAN_CART_LABEL,
  CANCEL_DF_RESERVED_DIALOG,
  CLOSE_DF_RESERVED_DIALOG,
  TOGGLE_DF_BIRTH_DIALOG,
  UPDATE_BIRTH_FIELD,
  DF_POS_WARRANTY_DIALOG,
  DF_CLEAR_BIRTH,
  SUBMIT_DF_WARRANTY_BACK_PHOTO,
  SUBMIT_DF_WARRANTY_FRONT_PHOTO,
  OPEN_SAM_DIALOG,
  SCANNED_MRZ,
  SEARCH_DF_STOCK,
  TOGGLE_BLANK_DIALOG,
  PRINT_DF_BLANK_FORM,
  DF_TOGGLE_PASSPORT_DIALOG,
  DF_SUBMIT_PASSPORT,
  DF_SHOW_PASSPORT,
  DF_CLOSE_TEMP_DIALOG,
  DF_SUBMIT_CDP,
  DF_TOGGLE_VISA_DIALOG,
  DF_SUBMIT_VISA,
  DF_SHOW_VISA,
  TOGGLE_CDP_DIALOG
} from "../../actions";
import { TableToolbar, withCmdCode } from "@cosmos/chaos";
import { FocusDialog } from "../../components";
import { withStyles, Paper, Button } from "@material-ui/core";
import { CustomerInfo, DutyFreeCart, SamDialog } from "./components";
import {
  ReservedDialog,
  BirthDialog,
  PhotoDialog,
  BlankFormDialog,
  TempImgDialog
} from "../../components/dialog";
import { Camera } from "mdi-material-ui";

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    padding: "0.5em"
  },
  feature: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  toolset: {
    display: "flex"
  },
  messageBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "0.5em"
  },
  toolbar: {
    backgroundColor: theme.palette.primary.light
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  "upper-content": {
    padding: "1em",
    alignSelf: "flex-end",
    display: "flex"
  },
  "action-btns": {
    marginRight: "1em"
  },
  "inner-content": {
    flex: 1,
    display: "flex",
    justifyContent: "space-around"
  },
  "shortcut-btn": {
    margin: "1em"
  },
  cart: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  customer: {
    display: "flex",
    flexDirection: "column"
  }
});

class DutyFreeViewComponent extends Component {
  componentDidMount() {
    this.cmdSource = this.props.source.subscribe(code => {
      if (code.mrz) {
        this.props.dispatch({ type: SCANNED_MRZ, payload: { code: code.mrz } });
      } else if (code.cmd) {
        switch (code.cmd) {
          case "SAM":
            this.props.dispatch({
              type: DF_SCAN_CART_LABEL,
              payload: { search: code.val }
            });
            break;
          case "STK":
            this.props.dispatch({
              type: SEARCH_DF_STOCK,
              payload: { search: code.val }
            });
            break;
          default:
        }
      }
    });
  }

  closeNotification = () => {
    this.props.dispatch({ type: DF_NOTICE_DISMISS });
  };

  handleReserveClose = () => {
    this.props.dispatch({ type: CANCEL_DF_RESERVED_DIALOG });
  };

  handleReserveConfirm = () => {
    this.props.dispatch({ type: CLOSE_DF_RESERVED_DIALOG });
  };

  toggleDialog = () => {
    this.props.dispatch({ type: DF_CLEAR_BIRTH });
    this.props.dispatch({ type: TOGGLE_DF_BIRTH_DIALOG });
  };

  updateField = (value, idx) => {
    let gds = this.props.goods.map(e => e);
    gds[idx] = { ...this.props.goods[idx], ...value };
    this.props.dispatch({ type: UPDATE_BIRTH_FIELD, payload: { goods: gds } });
  };

  handleNext = () => {
    this.props.dispatch({
      type: DF_POS_WARRANTY_DIALOG,
      payload: { captureCardFront: true }
    });
    this.props.dispatch({ type: TOGGLE_DF_BIRTH_DIALOG });
  };

  handleSubmitFront = image => {
    this.props.dispatch({
      type: SUBMIT_DF_WARRANTY_FRONT_PHOTO,
      payload: { dataUri: image, type: "STOCK" }
    });
  };

  handleSubmitBack = image => {
    this.props.dispatch({
      type: SUBMIT_DF_WARRANTY_BACK_PHOTO,
      payload: { dataUri: image, type: "STOCK" }
    });
  };

  openLabelDialog = () => {
    this.props.dispatch({
      type: OPEN_SAM_DIALOG,
      payload: { samDialog: true }
    });
  };

  openBlankFormDialog = () => {
    this.props.dispatch({
      type: TOGGLE_BLANK_DIALOG,
      payload: { blankFormDialog: true }
    });
  };

  closeBlankDialog = () => {
    this.props.dispatch({
      type: TOGGLE_BLANK_DIALOG,
      payload: { blankFormDialog: false }
    });
  };

  printBlankForm = number => {
    this.props.dispatch({
      type: PRINT_DF_BLANK_FORM,
      payload: { number: number, type: "duf" }
    });
    this.props.dispatch({
      type: TOGGLE_BLANK_DIALOG,
      payload: { blankFormDialog: false }
    });
  };

  openPassportDialog = () => {
    this.props.dispatch({
      type: DF_TOGGLE_PASSPORT_DIALOG,
      payload: { passportDialog: true }
    });
  };

  closePassportDialog = () => {
    this.props.dispatch({
      type: DF_TOGGLE_PASSPORT_DIALOG,
      payload: { passportDialog: false }
    });
  };

  handleSubmitPassport = image => {
    this.props.dispatch({
      type: DF_SUBMIT_PASSPORT,
      payload: { dataUri: image, type: "DUTYFREE" }
    });
  };

  openPassportImage = () => {
    const passportIdentifier =
      this.props.orderInput &&
      this.props.orderInput.attachments &&
      this.props.orderInput.attachments.passport;
    if (passportIdentifier) {
      this.props.dispatch({
        type: DF_SHOW_PASSPORT,
        payload: {
          type: "DUTYFREE",
          key: passportIdentifier,
          title: "passport"
        }
      });
    }
  };

  closeTempDialog = () => {
    this.props.dispatch({
      type: DF_CLOSE_TEMP_DIALOG,
      payload: { tempDialog: false }
    });
  };

  retakePhoto = () => {
    switch (this.props.tempTitle) {
      case "passport":
        this.openPassportDialog();
        break;
      case "visa":
        this.openVisaDialog();
        break;
      default:
    }
    this.closeTempDialog();
  };

  openVisaDialog = () => {
    this.props.dispatch({
      type: DF_TOGGLE_VISA_DIALOG,
      payload: { visaDialog: true }
    });
  };

  closeVisaDialog = () => {
    this.props.dispatch({
      type: DF_TOGGLE_VISA_DIALOG,
      payload: { visaDialog: false }
    });
  };

  handleSubmitVisa = image => {
    this.props.dispatch({
      type: DF_SUBMIT_VISA,
      payload: { dataUri: image, type: "DUTYFREE" }
    });
  };

  openVisaImage = () => {
    const visaIdentifier =
      this.props.orderInput &&
      this.props.orderInput.attachments &&
      this.props.orderInput.attachments.visaLanding;
    if (visaIdentifier) {
      this.props.dispatch({
        type: DF_SHOW_VISA,
        payload: { type: "DUTYFREE", key: visaIdentifier, title: "visa" }
      });
    }
  };

  handleSubmitCdp = image => {
    this.props.dispatch({
      type: DF_SUBMIT_CDP,
      payload: { dataUri: image, type: "DUTYFREE" }
    });
  };

  closeCdpDialog = () => {
    this.props.dispatch({
      type: TOGGLE_CDP_DIALOG,
      payload: { cdpDialog: false }
    });
  };

  render() {
    const {
      classes,
      noticeType,
      notify,
      notice,
      birthDialog,
      processCart,
      reservedDialog,
      reservedFor,
      reservation,
      goods,
      selected,
      monthOptions,
      captureCardFront,
      uploadProgress,
      captureCardBack,
      blankFormDialog,
      passportDialog,
      orderInput,
      tempDialog,
      tempTitle,
      tempUri,
      visaDialog,
      cdpDialog
    } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.feature}>
          <TableToolbar
            numSelected={0}
            title="Duty Free"
            styles={{
              root: classes.toolbar,
              toolset: classes.toolset,
              messageBox: classes.messageBox
            }}
          />
          <SamDialog />
          <ReservedDialog
            reservedDialog={reservedDialog}
            reservedFor={reservedFor}
            reservation={reservation}
            handleClose={this.handleReserveClose}
            handleConfirm={this.handleReserveConfirm}
          />
          <BirthDialog
            keyName={`birth_dialog`}
            dialogOpen={birthDialog && !notify}
            closeDialog={this.toggleDialog}
            updateField={this.updateField}
            goods={goods}
            selected={selected}
            monthOptions={monthOptions}
            handleNext={this.handleNext}
          />
          <PhotoDialog
            dialogOpen={!notify && captureCardFront}
            title={" - Card Front"}
            uploadProgress={uploadProgress}
            handleSubmit={this.handleSubmitFront}
            ableToCancel={false}
          />
          <PhotoDialog
            dialogOpen={!notify && captureCardBack}
            title={" - Card Back"}
            uploadProgress={uploadProgress}
            handleSubmit={this.handleSubmitBack}
            ableToCancel={false}
          />
          <PhotoDialog
            dialogOpen={!notify && passportDialog}
            title={" - Passport"}
            uploadProgress={uploadProgress}
            handleSubmit={this.handleSubmitPassport}
            ableToCancel={true}
            closeDialog={this.closePassportDialog}
          />
          <PhotoDialog
            dialogOpen={!notify && visaDialog}
            title={" - Visa"}
            uploadProgress={uploadProgress}
            handleSubmit={this.handleSubmitVisa}
            ableToCancel={true}
            closeDialog={this.closeVisaDialog}
          />
          <PhotoDialog
            dialogOpen={!notify && cdpDialog}
            title={" - Signed Customer Declaration Form"}
            uploadProgress={uploadProgress}
            handleSubmit={this.handleSubmitCdp}
            ableToCancel={true}
            closeDialog={this.closeCdpDialog}
          />
          <TempImgDialog
            dialogOpen={tempDialog}
            tempTitle={tempTitle}
            tempUri={tempUri}
            retakePhoto={this.retakePhoto}
            closeDialog={this.closeTempDialog}
          />
          <BlankFormDialog
            dialogOpen={blankFormDialog}
            handleCancel={this.closeBlankDialog}
            handlePrint={this.printBlankForm}
            docName={"Duty Free Form"}
          />
          <FocusDialog
            open={notify}
            message={notice}
            variant={noticeType}
            onClose={this.closeNotification}
          />
          <div className={classes["content"]}>
            <div className={classes["upper-content"]}>
              <div className={classes["action-btns"]}>
                <Button
                  onClick={
                    orderInput &&
                    orderInput.attachments &&
                    orderInput.attachments.passport
                      ? this.openPassportImage
                      : this.openPassportDialog
                  }
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  <Camera />
                  Passport
                </Button>
              </div>
              <div className={classes["action-btns"]}>
                <Button
                  onClick={
                    orderInput &&
                    orderInput.attachments &&
                    orderInput.attachments.visaLanding
                      ? this.openVisaImage
                      : this.openVisaDialog
                  }
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  <Camera />
                  Visa
                </Button>
              </div>
              <div className={classes["action-btns"]}>
                <Button
                  onClick={this.openLabelDialog}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  Enter Label
                </Button>
              </div>
            </div>
            <div className={classes["inner-content"]}>
              <div className={classes.customer}>
                <CustomerInfo />
              </div>
              {processCart && (
                <div className={classes.cart}>
                  <DutyFreeCart />
                </div>
              )}
            </div>
            <div className={classes["shortcut-btn"]}>
              <Button
                onClick={this.openBlankFormDialog}
                color="primary"
                variant="contained"
                size="small"
              >
                Blank Duty-Free
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export const DutyFreeView = connect(state => ({
  noticeType: state.dutyfree.noticeType,
  notify: state.dutyfree.notify,
  notice: state.dutyfree.notice,
  processCart: state.dutyfree.processCart,
  reservedDialog: state.dutyfree.reservedDialog,
  reservedFor: state.dutyfree.reservedFor,
  reservation: state.dutyfree.reservation,
  birthDialog: state.dutyfree.birthDialog,
  goods: state.dutyfree.goods,
  selected: state.dutyfree.selected,
  monthOptions: state.purchase.monthOptions,
  captureCardFront: state.dutyfree.captureCardFront,
  captureCardBack: state.dutyfree.captureCardBack,
  uploadProgress: state.dutyfree.uploadProgress,
  blankFormDialog: state.dutyfree.blankFormDialog,
  passportDialog: state.dutyfree.passportDialog,
  visaDialog: state.dutyfree.visaDialog,
  cdpDialog: state.dutyfree.cdpDialog,
  orderInput: state.dutyfree.orderInput,
  tempDialog: state.dutyfree.tempDialog,
  tempTitle: state.dutyfree.tempTitle,
  tempUri: state.dutyfree.tempUri
}))(withCmdCode(withStyles(styles)(DutyFreeViewComponent)));
