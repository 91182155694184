import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import downloadFile from '../functions/downloadFile'
import useHeaders from '../../../hooks/useHeaders'

const useStyles = makeStyles({
    successCard: {
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '10px 20px'
    },
    center: {
        margin: '20px auto 0',
        display: 'block'
    }
})

export default function SuccessCard({title, reference, id, downloadUrl}) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const headers = useHeaders()
    const download = async () => {
        setIsLoading(true)
        setError(null)
        const { error } = await downloadFile(downloadUrl, headers, `${title} - ${reference}`)
        if (error) {
            setError(error.message)
        }
        setIsLoading(false)
    }

    const classes = useStyles()
    return (
        <div className={classes.successCard}>
            <h2>{title}</h2>
            <p>Reference: <strong>{reference}</strong></p>
            <p>ID: <strong>{id}</strong></p>
            <Button 
                type="button" variant="contained" color="secondary" 
                onClick={download} disabled={isLoading} className={classes.center}
            >Download</Button>
            {error ? <h4>{error}</h4> : null}
        </div>
    )
}
