import React, { useState, useEffect, useRef } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import { TrashCanOutline } from "mdi-material-ui";
import { makeStyles } from "@material-ui/styles";
import InputWithError from "./InputWithError";
import { useSelector } from "react-redux";
import { SelectModel, Input } from "../../../components/inputs";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import capitalize from "../functions/capitalize";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const useStyles = makeStyles({
  formGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    margin: "0",
    "& *": {
      margin: "0",
      padding: "0.1px",
    },
  },
  deleteButton: {
    padding: "0.2em",
    margin: "0",
    width: "100%",
    background: "none",
    border: "none",
    outline: "none",
    textAlign: "center",
    "& :hover": {
      cursor: "pointer",
      background: "rgba(0,0,0,0.1)",
    },
    "& *": {
      padding: "0",
      margin: "0",
    },
  },
});

const AVAILABLE_CONDITIONS = [
  {
    label: "N",
    value: "new",
  },
  {
    label: "V",
    value: "never used",
  },
  {
    label: "B",
    value: "brand new",
  },
  {
    label: "U",
    value: "used",
  },
];

export default function OrderDetail({
  index,
  price,
  product,
  condition,
  conditionDetails,
  showProductDetails,
  deleteOrderDetail,
  updateOrderDetail,
  errors = {
    price: null,
    product: null,
    condition: null,
    conditionDetails: null,
  },
}) {
  const availableOptions = useSelector((state) => {
    const availableBrands = state.catalog.brands;
    const availableProducts = state.catalog.products;
    const result = Object.values(availableProducts).map((product) => ({
      brand: availableBrands[product.brandID],
      product,
    }));
    return result;
  });

  const update = (name) => (e) => {
    const updatedOrderDetail = {
      price,
      product,
      condition,
      conditionDetails: { ...conditionDetails },
    };

    if (name === "conditionDetails") {
      updatedOrderDetail[name] = {
        ...updatedOrderDetail[name],
        [e.target.value]: e.target.checked,
      };
    } else {
      updatedOrderDetail[name] = e.target.value;
    }

    updateOrderDetail(updatedOrderDetail);
  };

  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    if (isFocused) showProductDetails(product);
    return () => {
      showProductDetails(null);
    };
  }, [isFocused, product, showProductDetails]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsFocused(false));

  const classes = useStyles();
  return (
    <TableRow
      ref={ref}
      onClick={() => setIsFocused(true)}
      style={{ background: isFocused ? "#F0F0F0" : "inherit", border: "none" }}
    >
      <TableCell>
        <button
          type="button"
          onClick={() => deleteOrderDetail()}
          disabled={!isFocused}
          className={classes.deleteButton}
        >
          {isFocused ? (
            <TrashCanOutline style={{ color: "red" }} />
          ) : (
            <p>{index}</p>
          )}
        </button>
      </TableCell>
      <TableCell style={{ minWidth: "300px" }}>
        <InputWithError
          error={!!errors.product}
          errorMessage={errors.product}
          render={(props) => (
            <SelectModel
              options={availableOptions}
              onChange={(event, selectedProduct, reason) => {
                update("product")({ target: { value: selectedProduct } });
                showProductDetails(selectedProduct);
              }}
              value={product}
              {...props}
            />
          )}
        />
      </TableCell>
      <TableCell style={{ padding: "0", width: "200px" }}>
        <RadioGroup
          aria-label="Condition"
          value={condition}
          onChange={update("condition")}
          row
          margin="dense"
          className={classes.formGroup}
        >
          {AVAILABLE_CONDITIONS.map((condition, index) => (
            <FormControlLabel
              labelPlacement="end"
              key={index}
              style={{ transform: "scale(0.9)", padding: "1px" }}
              value={condition.value}
              control={
                <Tooltip title={capitalize(condition.value)}>
                  <Radio size="small" inputProps={{ style: { padding: 0 } }} />
                </Tooltip>
              }
              label={
                <small style={{ fontSize: "0.8em" }}>{condition.label}</small>
              }
            />
          ))}
        </RadioGroup>

        <FormGroup
          className={classes.formGroup}
          onChange={update("conditionDetails")}
        >
          {Object.entries(conditionDetails).map(([detail, value]) => (
            <FormControlLabel
              key={detail}
              labelPlacement="end"
              style={{ transform: "scale(0.9)", padding: "1px" }}
              control={
                <Tooltip title={capitalize(detail)}>
                  <Checkbox
                    size="small"
                    checked={conditionDetails[detail]}
                    value={detail}
                  />
                </Tooltip>
              }
              label={
                <small style={{ fontSize: "0.8em" }}>
                  {detail[0].toUpperCase()}
                </small>
              }
            />
          ))}
        </FormGroup>
      </TableCell>
      <TableCell style={{ paddingRight: "1em" }}>
        <InputWithError
          error={!!errors.price}
          errorMessage={errors.price}
          render={(props) => (
            <Input
              {...props}
              value={price}
              onChange={update("price")}
              placeholder="Price"
            />
          )}
        />
      </TableCell>
    </TableRow>
  );
}
