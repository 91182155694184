import { createVibes } from "@cosmos/vibes";
import {
  SEARCH_STOCK_BY_LABEL,
  SCAN_STOCK_CHECK,
  CLEAR_STOCK_LABEL,
  OPEN_STOCKCHECK_NOTICE,
  ADD_SCANNED_STOCK,
  REMOVE_EXPECTED_STOCK,
  UPDATE_SELECTED_DATE,
  UPDATE_HISTORY,
} from "../actions";
import moment from 'moment';

export const searchStockVibes = createVibes({
  type: [SEARCH_STOCK_BY_LABEL, SCAN_STOCK_CHECK],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    const { searchLabel, scannedStocks } = getState().stockcheck;

    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const data = await axios.request({
        url: `/stockcheck/${searchLabel}`,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          rayLens
        }
      });

      let newScannedStocks = scannedStocks.map(el => el);

      if (data.status === 200) {
        if (data.request && data.request.response) {
          const response = JSON.parse(data.request.response);
          if (response.stockId) {
            if (scannedStocks.map(el => String(el.id)).indexOf(String(response.stockId)) === -1) {
              const { stocks, stocksDetail } = getState().inventory;
              let newObj = { id: response.stockId, clearanceState: response.state, label: searchLabel };
              if (stocks[response.stockId]) {
                newObj = { ...newObj, ...stocks[response.stockId], ...stocksDetail[response.stockId] };
              }

              switch (response.state) {
                case -1:
                  dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'warning', notice: 'The stock is not belonged to this store. ' } });
                  break;
                case -2:
                  dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'The stock is not belonged to this store. ' } });
                  break;
                case -3:
                  dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'The stock is not yet stock in. ' } });
                  break;
                case -4:
                  dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'The stock is geared up currently. ' } });
                  break;
                case -5:
                  dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'The stock is repaired currently. ' } });
                  break;
                case -6:
                  dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'The stock should not be in the store. ' } });
                  break;
                case 0:
                default:
              }
              newScannedStocks.push(newObj);
              dispatch({ type: ADD_SCANNED_STOCK, payload: { scannedStocks: newScannedStocks } });

              let newStocks = { ...stocks };
              delete newStocks[response.stockId];
              let newStocksDetail = { ...stocksDetail };
              delete newStocksDetail[response.stockId];
              dispatch({ type: REMOVE_EXPECTED_STOCK, payload: { stocks: newStocks, stocksDetail: newStocksDetail } });
            }
            else {
              dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'warning', notice: 'The stock is scanned duplicately' } });
            }
          }
        }
        else {
          dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'The stock label is invalid' } });
        }
      }
      else {
        let newObj = { label: searchLabel };
        newScannedStocks.push(newObj);
        dispatch({ type: ADD_SCANNED_STOCK, payload: { scannedStocks: newScannedStocks } });
        dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'The stock label is invalid' } });
      }
      dispatch({ type: CLEAR_STOCK_LABEL, payload: { searchLabel: '' } });
      done();
    } catch (err) {
      let newScannedStocks = scannedStocks.map(el => el);
      let newObj = { label: searchLabel };
      newScannedStocks.push(newObj);
      dispatch({ type: ADD_SCANNED_STOCK, payload: { scannedStocks: newScannedStocks } });
      dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'The stock label is invalid' } });
      dispatch({ type: CLEAR_STOCK_LABEL, payload: { searchLabel: '' } });
      done();
    }

  }
});

export const filterHistoryVibes = createVibes({
  type: [UPDATE_SELECTED_DATE],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;
      const { selectedDateRange } = action.payload;
      const { brands, products } = getState().catalog;
      const { stores, users } = getState().system;

      const variables = {
        filter: {
          from: selectedDateRange.start ? selectedDateRange.start.clone().startOf('day').unix() : null, 
          to: selectedDateRange.end ? selectedDateRange.end.clone().add(1, 'day').startOf('day').unix() : null
        }
      }
    
      const data = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query($filter: StockClearanceFilter){
            stockClearance(filter: $filter) {
              id labelId stockId articleId userId
              storeId state expected 
              createdAt updatedAt deletedAt
              serial brandId productId storeId
            }
          }`,
          variables
        }
      });

      if (data.data && data.data.stockClearance) {
        let result = data.data.stockClearance;
        result = result.map(el => {
          return {
            ...el,
            brandName: el.brandId && brands[el.brandId] && brands[el.brandId].name,
            productName: el.productId && products[el.productId] && products[el.productId].name,
            productReference: el.productId && products[el.productId] && products[el.productId].reference,
            storeName: el.storeId && stores && stores[el.storeId] && stores[el.storeId].name,
            checker: users && el.userId && users[el.userId] && users[el.userId].name,
            updatedAt: el.updatedAt ? moment(el.updatedAt).utc().local().format("YYYY-MM-DD") : null,
          }
        });
        dispatch({ type: UPDATE_HISTORY, payload: { histories: result } });
      }
      else {
        dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'Fail to search the history ' } });
      }
      done();
    } catch (err) {
      dispatch({ type: OPEN_STOCKCHECK_NOTICE, payload: { notify: true, noticeType: 'error', notice: 'Fail to search the history ' } });
      done();
    }

  }
});
