import {
  DEL_LOAD_BEGIN,
  DEL_LOAD_SUCCESS,
  DEL_LOAD_ERROR,
  CLOSE_DELIVERY_NOTICE,
  UPDATE_DEL_FIELD_ERROR,
  UPDATE_DEL_FIELD_SUCCESS,
  UPDATE_TEXT_FIELD,
  UPDATE_DATE_RANGE,
  OPEN_BOX_OPEN_DIALOG,
  CANCEL_BOX_OPEN,
  DISPLAY_DEL_RECEIPT_ERROR,
} from '../actions'

const initialState = {
  loading: false,
  notify: false,
  noticeType: 'info',
  notice: '',
  deliveries: {},
  couriers: [],
  docCouriers: [],
  selectedDateRange: { start: null, end: null },
  boxOpenDialog: false,
  targetDeliveryId: null,
}

export const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEL_LOAD_BEGIN:
      return { ...state, loading: true }
    case DEL_LOAD_SUCCESS:
    case DEL_LOAD_ERROR:
      return { ...state, ...action.payload, loading: false }
    case CLOSE_DELIVERY_NOTICE:
      return { ...state, notify: false, noticeType: 'info', notice: '' }
    case UPDATE_DEL_FIELD_ERROR:
    case UPDATE_DEL_FIELD_SUCCESS:
    case UPDATE_DATE_RANGE:
    case OPEN_BOX_OPEN_DIALOG: 
    case CANCEL_BOX_OPEN:
    case DISPLAY_DEL_RECEIPT_ERROR:
      return { ...state, ...action.payload }
    case UPDATE_TEXT_FIELD:
      return { 
        ...state, 
        deliveries: {
          ...state.deliveries,
          [action.payload.id]: {
            ...state.deliveries[action.payload.id],
            ...action.payload.data
          }
        }
      }
    default:
      return state
  }
}