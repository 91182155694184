import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import { withCmdCode } from '@cosmos/chaos';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import getProforma from '../functions/getProforma'
import InputWithError from './InputWithError'
import CameraInput from './CameraInput'
import useHeaders from '../../../hooks/useHeaders'
import shortid from 'shortid'
import executeProforma from '../functions/executeProforma'

const useStyles = makeStyles({
    scanLabelForm: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        marginBottom: '20px'
    },
    scanLabelHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridGap: '20px'
    },
    textCenter: {
        '& *': {
            textAlign: 'center'
        }
    },
    submitButton: {
        marginTop: '20px'
    }
})

/**
 * Dialog to execute proforma purchase
 * @param {Object} props 
 * @param {boolean} props.isOpen 
 * @param {function} props.handleClose
 * @param {Object} props.source - Passed in by withCmdCode HOC
 */
const ExecuteProformaDialog = withCmdCode(function({isOpen, handleClose, source}) {
    const [label, setLabel] = useState('')
    const [once, setOnce] = useState(shortid.generate())
    const [invoiceIndicator, setInvoiceIndicator] = useState(null)
    const [errors, setErrors] = useState(generateInitialErrors())
    const [isExecuted, setIsExecuted] = useState(false)

    // on mount, subscribe to the source to listen to scanner 
    useEffect(() => {
        source.subscribe(code => {
            setLabel(code.val)
        })
    }, [source])

    const headers = useHeaders()

    const handleSubmit = async e => {
        e.preventDefault();
        setErrors(generateInitialErrors())

        const validationErrors = validateInputs(label, invoiceIndicator)
        if (validationErrors) {
            setOnce(shortid.generate())
            return setErrors(prevErrors => ({...prevErrors, ...validationErrors}))
        } 

        const {error: getProformaError, id} = await getProforma(label, headers)
        
        if (getProformaError) {
            setOnce(shortid.generate())
            return setErrors(prevErrors => ({
                ...prevErrors,
                label: getProformaError
            }))
        }

        const input = {id, invoiceIndicator, once}
        const {error: executeProformaError} = await executeProforma(input, headers)

        if (executeProformaError) {
            setOnce(shortid.generate())
            return setErrors(prevErrors => ({...prevErrors, executeProforma: executeProformaError.message}))
        }

        setIsExecuted(true)
    }

    const resetForm = () => {
        setLabel('')
        setOnce(shortid.generate())
        setInvoiceIndicator(null)
        setErrors(generateInitialErrors())
        setIsExecuted(false)
    }

    const classes = useStyles()
    return (
        <Dialog open={isOpen} onClose={handleClose}> 
            <div className={classes.scanLabelHeader}>
                <DialogTitle className={classes.scanLabelHeader}>
                    Scan Label or input into textbox
                </DialogTitle>
                <Button type="button" onClick={handleClose}>Close</Button>
            </div>
            
            <DialogContent>
                <form onSubmit={handleSubmit} className={classes.scanLabelForm}>
                    <InputWithError error={!!errors.label} errorMessage={errors.label} render={props => (
                        <TextField {...props} fullWidth value={label} onChange={e => setLabel(e.target.value)} variant="outlined" label="Label"/>
                    )} />
                    <InputWithError error={!!errors.invoiceIndicator} errorMessage={errors.invoiceIndicator} className={classes.textCenter} render={props => (
                        <CameraInput {...props} label={"Invoice Indicator"} value={invoiceIndicator} 
                            handleChange={e => setInvoiceIndicator(e.target.value)} uploadUrl={'/v1/upload/invoice'} handleError={error => setErrors(prevErrors => ({...prevErrors, invoiceIndicator: error}))}
                        />
                    )} />
                    {isExecuted ? (
                        <>
                            <h4 className={classes.textCenter}>Successfully confirmed purchase order. You may close this window now</h4> 
                            <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
                            <Button variant="contained" onClick={resetForm}>Scan Another Form</Button>
                        </>
                    ) : (
                        <Button variant="contained" color="primary" type="submit" className={classes.submitButton}>Submit</Button>
                    )}
                    {errors.executeProforma ? <p>{errors.executeProforma}</p> : null}
                </form>
            </DialogContent>
        </Dialog>
    )
})

export default ExecuteProformaDialog

const generateInitialErrors = () => ({label: null, invoiceIndicator: null, executeProforma: null})

const validateInputs = (label, invoiceIndicator) => {
    let errors = generateInitialErrors()
    let isValid = true

    if (!label) {
        errors.label = 'Required'
        isValid = false
    }

    if (!invoiceIndicator) {
        errors.invoiceIndicator = 'Required'
        isValid = false
    }

    if (isValid) {
        return null
    }

    return errors
}
