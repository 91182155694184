import React from "react";
import { makeStyles } from '@material-ui/core/styles'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const useStyles = makeStyles({
  root: {
    '& *': {
      background: '#33ab9f'
    }
  }
})

export const HeaderRow = () => {
  const classes = useStyles()
  return (
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell>Unit Price</TableCell>
          <TableCell>Qty</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Tax</TableCell>
          <TableCell>Discount</TableCell>
          <TableCell>Amount</TableCell>
        </TableRow>
      </TableHead>
  )
}