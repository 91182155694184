import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import useHeaders from '../../../hooks/useHeaders'
import capitalize from '../functions/capitalize'
import NoImagePlaceholder from '../../../components/NoImagePlaceholder'

const useStyles = makeStyles({
    root: {
        padding: '20px'
    }
})

export default function ProductDisplay({ product }) {
    const classes = useStyles()
    const [imageURLs, setImageURLs] = useState([])
    const headers = useHeaders()

    const mappedProduct = {
        brand: product.brand.name,
        name: product.product.name,
        SKU: product.product.SKU,
        reference: product.product.reference,
        dialColor: product.product.dialColor
    }

    useEffect(() => {
        async function fetchImages() {
            const assets = product.product.assets
            if (!assets) return
            try {
                const blobArray = await Promise.all(assets.map((asset, index) => axios.request({
                    url: `/file/product/${product.id}/${index}`,
                    method: 'get',
                    headers,
                    responseType: "blob"
                })
                ))
                const urls = blobArray.map(blob => new Blob([blob], { type: 'image/png' })).map(file => URL.createObjectURL(file))
                setImageURLs(urls)
            } catch (e) {
            }
        }
        fetchImages()
    }, [headers, product.id, product.product.assets])

    return (
        <div className={classes.root}>
            {imageURLs[0] ? <img src={imageURLs[0]} alt={product.assets[0]}/> : <NoImagePlaceholder/>}
            {renderProductFields(mappedProduct)}
        </div>
    )
}

function renderProductFields(product) {
    return product ? Object.entries(product).map(([key, value]) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }} key={key}>
            <p>{capitalize(key)}</p>
            <h2 style={{ textAlign: 'right' }}>{typeof value === 'object' ? JSON.stringify(value) : value}</h2>
        </div>
    )) : <p>No product selected</p>
}
