import {
    FerrisWheel,
    Printer,
    TextBoxPlusOutline,
    AlphaRCircleOutline,
    AlphaTCircleOutline,
    ClipboardCheck,
    History,
    TruckDelivery,
    Stamper,
    FileTableBoxMultiple,
    Account, Barcode,
} from "mdi-material-ui";
import {CartView} from "./containers/cart";
import {PurchaseForm, PurchaseView} from "./containers/purchase";
import {ReservationForm} from "./containers/reservation";
import {TenderForm, TenderView} from "./containers/tender";
import {GearView} from "./containers/gearHandler";
import {PrintingForm} from "./containers/printing";
import {StockInForm} from "./containers/stock";
import {AdminComponent} from "./containers/admin";
import {AccountingComponent} from "./containers/accounting";
import {OrdersComponent} from "./containers/orders";
import {CatalogComponent} from "./containers/catalog";
import {MarketingComponent} from "./containers/marketing";
import {InventoryComponent} from "./containers/inventory";
import {
    StockCheckComponent,
    HistoryComponent,
} from "./containers/stockcheck/index";
import {SuppliesComponent} from "./containers/marketing";
import {DeliveryView} from "./containers/delivery";
import {DutyFreeView} from "./containers/dutyfree";
import {WalkInComponent} from "./containers/walkInPurchase";
import CreateUpcomingSupply from "./containers/marketing/CreateUpcomingSupply";
import BulkAddSupplies from "./containers/marketing/BulkAddSupplies";
import ModifySupplyProvision from "./containers/marketing/ModifySupplyProvision"
import {SuppliesOverviewComponent} from "./containers/marketing/SuppliesOverview";
import {isAuthorized} from "@cosmos/chaos";
import MatchPOandStocks from './containers/matchPOandStocks'
import {IANUnit} from "./containers/catalog";
import {PriceRuleComponent} from "./containers/priceRule";

export const Constants = {
    get endpoint() {
        return `/v1/api/`;
    },
};

export const Functions = {
    admin: {
        path: "/admin",
        name: "admin",
        permission: ["cosmos.admin"],
        component: AdminComponent,
        features: [],
    },
    pos: {
        path: "/pos",
        name: "pos",
        exact: true,
        permission: ["cosmos.sales.pos"],
        component: CartView,
        features: [],
        // check in, check out
    },
    inventory: {
        path: "/inventory",
        name: "inventory",
        permission: ["cosmos.operation.inventory"],
        component: InventoryComponent,
        features: ["gear", "stationary", "stockcheck", "history"],
        // stock in, gear, consignment
    },
    orders: {
        path: "/orders",
        name: "orders",
        permission: ["cosmos.purchase.order"],
        component: OrdersComponent,
        features: [
            "purchase",
            "reservation",
            "tender",
            "delivery",
            "dutyfree",
            "walkin",
            'matchPOandStocks'
        ],
        // duty free, walk in
    },
    catalog: {
        path: "/catalog",
        name: "catalog",
        permission: ["cosmos.catalog"],
        component: CatalogComponent,
        features: [],
        // view and create product, mapping with external
    },
    marketing: {
        path: "/marketing",
        name: "marketing",
        permission: ["cosmos.marketing.*"],
        component: MarketingComponent,
        features: ["supplies", "createSupplyUpcoming", "bulkAddSupplies", "modifySupplyProvision", "suppliesOverview"],
        // price tag, eshop, ranking
    },
    accounting: {
        path: "/accounting",
        name: "accounting",
        permission: ["cosmos.accounting"],
        component: AccountingComponent,
        features: [],
    },
};

export const features = [
    {
        name: "pos",
        path: "/pos",
        menu: true,
        enabled: false,
        priority: 1,
        permission: ["cosmos.sales.pos"],
        component: CartView,
        features: [],
    },
    {
        name: "inventory",
        path: "/inventory",
        menu: true,
        enabled: false,
        priority: 2,
        permission: ["cosmos.operation.inventory"],
        component: InventoryComponent,
        features: ["gear", "stationary", "stockcheck", "history"],
    },
    {
        name: "orders",
        path: "/orders",
        menu: true,
        enabled: false,
        priority: 3,
        permission: ["cosmos.purchase.orders"],
        component: OrdersComponent,
        features: [
            "purchase",
            "reservation",
            "tender",
            "delivery",
            "dutyfree",
            "walkin",
            'matchPOandStocks'
        ],
    },
    {
        name: "catalog",
        path: "/catalog",
        menu: true,
        enabled: false,
        permission: ["cosmos.marketing.%"],
        component: CatalogComponent,
        features: ["ianUnit"]
    },
    {
        name: "marketing",
        path: "/marketing",
        menu: true,
        enabled: false,
        priority: 0,
        permission: ["cosmos.marketing.%"],
        component: MarketingComponent,
        features: ["supplies", "priceRule", "createSupplyUpcoming", "bulkAddSupplies", "modifySupplyProvision", "suppliesOverview"],
    },
    {
        name: "accounting",
        path: "/accounting",
        menu: true,
        enabled: false,
        priority: 4,
        permission: ["cosmos.accounting.%"],
        component: AccountingComponent,
        features: [],
    },
    {
        name: "admin",
        path: "/admin",
        menu: false,
        enabled: true,
        priority: -1,
        permission: ["cosmos.admin"],
        component: AdminComponent,
        features: [],
    },
    {
        name: "purchase",
        path: "/purchase",
        menu: false,
        enabled: false,
        icon: TextBoxPlusOutline,
        permission: ["cosmos.purchase.order"],
        component: PurchaseView,
    },
    {
        path: "/reservation",
        icon: AlphaRCircleOutline,
        menu: false,
        enabled: false,
        name: "reservation",
        permission: ["cosmos.purchase.reservation"],
        component: ReservationForm,
    },
    {
        path: "/tender",
        icon: AlphaTCircleOutline,
        menu: false,
        name: "tender",
        permission: ["cosmos.purchase.tender"],
        component: TenderView,
    },
    {
        path: "/delivery",
        icon: TruckDelivery,
        menu: false,
        enabled: false,
        name: "delivery",
        permission: ["cosmos.delivery"],
        component: DeliveryView,
    },
    {
        path: "/gear",
        icon: FerrisWheel,
        menu: false,
        enabled: false,
        name: "gear",
        permission: ["cosmos.operation.gear"],
        component: GearView,
    },
    {
        path: "/stationary",
        icon: Printer,
        menu: false,
        enabled: false,
        name: "stationary",
        permission: ["cosmos.stationary.label"],
        component: PrintingForm,
    },
    {
        path: "/stockcheck",
        icon: ClipboardCheck,
        menu: false,
        enabled: false,
        name: "stockcheck",
        permission: ["cosmos.operation.stockcheck"],
        component: StockCheckComponent,
    },
    {
        path: "/clearanceHistory",
        icon: History,
        menu: false,
        enabled: false,
        name: "history",
        permission: ["cosmos.clearanceHistory"],
        component: HistoryComponent,
    },
    {
        name: "create-purchase",
        path: "/purchase/create",
        menu: false,
        enabled: false,
        permission: ["cosmos.purchase.order"],
        component: PurchaseForm,
    },
    {
        name: "stockIn",
        path: "/orders/purchase/stockin",
        menu: false,
        enabled: false,
        permission: ["cosmos.purchase.order"],
        component: StockInForm,
    },
    {
        name: "create-tender",
        path: "/tender/create",
        menu: false,
        enabled: false,
        permission: ["cosmos.purchase.tender"],
        component: TenderForm,
    },
    {
        name: "supplies",
        path: "/supplies",
        icon: FileTableBoxMultiple,
        menu: false,
        enabled: false,
        permission: ["cosmos.operation.supply"],
        component: SuppliesComponent,
    },
    {
        name: "suppliesOverview",
        path: "/suppliesoverview",
        icon: FerrisWheel,
        menu: false,
        enabled: false,
        permission: ["cosmos.operation.supply"],
        component: SuppliesOverviewComponent,
    },
    {
        name: "priceRule",
        path: "/pricing",
        icon: ClipboardCheck,
        menu: false,
        enabled: true,
        permission: ["cosmos.operation.supply"],
        component: PriceRuleComponent,
    },
    {
        name: "createSupplyUpcoming", // the name of feature
        path: "/supplies/upcoming", // corresponding url of this feature
        menu: false, // show at the top menu?
        enabled: true, // Does this feature enabled
        hide: true, // Does this feature hide at the left side bar
        permission: ["cosmos.operation.supply"], // permission require to get in the feature screen
        component: CreateUpcomingSupply,
    },
    {
        name: "bulkAddSupplies", // the name of feature
        path: "/supplies/importsupplyoffers", // corresponding url of this feature
        menu: false, // show at the top menu?
        enabled: true, // Does this feature enabled
        hide: true, // Does this feature hide at the left side bar
        permission: ["cosmos.operation.supply"], // permission require to get in the feature screen
        component: BulkAddSupplies,
    },
    {
        name: "modifySupplyProvision",
        path: "/supplies/modifysupplyprovision", // corresponding url of this feature
        menu: false, // show at the top menu?
        enabled: true, // Does this feature enabled
        hide: false, // Does this feature hide at the left side bar,
        icon: Stamper,
        permission: ["cosmos.operation.supply"], // permission require to get in the feature screen
        component: ModifySupplyProvision,
    },
    {
        name: 'matchPOandStocks',
        path: "/matchwithstock",
        menu: false,
        enabled: true,
        permission: ['cosmos.sales.order'],
        icon: Stamper,
        component: MatchPOandStocks
    },
    {
        name: "dutyfree",
        path: "/dutyfree",
        menu: false,
        enabled: false,
        permission: ["cosmos.sales.dutyfree"],
        icon: Stamper,
        component: DutyFreeView,
    },
    {
        name: "walkin",
        path: "/walkin",
        permission: ["cosmos.purchase.walkin"],
        icon: Account,
        component: WalkInComponent,
    },
    {
        name: 'ianUnit',
        path: "/ian",
        permission: ["cosmos.marketing.%"],
        icon: Barcode,
        component: IANUnit,
    }
];

export const getEntryPoint = (features, permissions) => {
    return (
        features
            .filter((f) => f.menu)
            .map((f) => ({
                permission: f.permission,
                location: f.path,
                order: f.priority,
            }))
            .sort((a, b) => a.order - b.order)
            // .push({permission: "*", location: '/marketing'})
            .find((e) => isAuthorized(permissions, e.permission))
    );
};

export const Currencies = {
    1: {
        id: 1,
        code: "HKD",
        symbol: "HK$",
    },
    2: {
        id: 2,
        code: "USD",
        symbol: "USD",
    },
    3: {
        id: 3,
        code: "CHF",
        symbol: "CHF",
    },
    4: {
        id: 4,
        code: "EUR",
        symbol: "€",
    },
    5: {
        id: 5,
        code: "MYR",
        symbol: "RM",
    },
    6: {
        id: 6,
        code: "DKK",
        symbol: "DKK",
    },
    7: {
        id: 7,
        code: "JPY",
        symbol: "¥",
    },
    8: {
        id: 8,
        code: "CAD",
        symbol: "C$",
    },
    9: {
        id: 9,
        code: "NZD",
        symbol: "NZ$",
    },
    10: {
        id: 10,
        code: "TWD",
        symbol: "NT$",
    },
    11: {
        id: 11,
        code: "SGD",
        symbol: "S$",
    },
    12: {
        id: 12,
        code: "AED",
        symbol: "AED",
    },
    13: {
        id: 13,
        code: "MOP",
        symbol: "MOP$",
    },
    14: {
        id: 14,
        code: "VND",
        symbol: "VND",
    },
    15: {
        id: 15,
        code: "BHD",
        symbol: "BHD",
    },
    16: {
        id: 16,
        code: "QAR",
        symbol: "QAR",
    },
    20: {
        id: 20,
        code: "CNY",
        symbol: "CN¥",
    },
    22: {
        id: 22,
        code: "SEK",
        symbol: "SEK",
    },
    24: {
        id: 24,
        code: "GBP",
        symbol: "£",
    },
    26: {
        id: 26,
        code: "BND",
        symbol: "BND$",
    },
    28: {
        id: 28,
        code: "PLN",
        symbol: "zl",
    },
    30: {
        id: 30,
        code: "CZK",
        symbol: "Kč",
    },
};

export const Nationalities = [
    {code: "AFG", name: "Afghanistan"},
    {code: "ALB", name: "Albania"},
    {code: "DZA", name: "Algeria"},
    {code: "ASM", name: "American Samoa"},
    {code: "AND", name: "Andorra"},
    {code: "AGO", name: "Angola"},
    {code: "AIA", name: "Anguilla"},
    {code: "ATA", name: "Antarctica"},
    {code: "ATG", name: "Antigua and Barbuda"},
    {code: "ARG", name: "Argentina"},
    {code: "ARM", name: "Armenia"},
    {code: "ABW", name: "Aruba"},
    {code: "AUS", name: "Australia"},
    {code: "AUT", name: "Austria"},
    {code: "AZE", name: "Azerbaijan"},
    {code: "BHS", name: "Bahamas"},
    {code: "BHR", name: "Bahrain"},
    {code: "BGD", name: "Bangladesh"},
    {code: "BRB", name: "Barbados"},
    {code: "BLR", name: "Belarus"},
    {code: "BEL", name: "Belgium"},
    {code: "BLZ", name: "Belize"},
    {code: "BEN", name: "Benin"},
    {code: "BMU", name: "Bermuda"},
    {code: "BTN", name: "Bhutan"},
    {code: "BOL", name: "Bolivia"},
    {code: "BIH", name: "Bosnia and Herzegovina"},
    {code: "BWA", name: "Botswana"},
    {code: "BVT", name: "Bouvet Island"},
    {code: "BRA", name: "Brazil"},
    {code: "IOT", name: "British Indian Ocean Territory"},
    {code: "BRN", name: "Brunei Darussalam"},
    {code: "BGR", name: "Bulgaria"},
    {code: "BFA", name: "Burkina Faso"},
    {code: "BDI", name: "Burundi"},
    {code: "KHM", name: "Cambodia"},
    {code: "CMR", name: "Cameroon"},
    {code: "CAN", name: "Canada"},
    {code: "CPV", name: "Cape Verde"},
    {code: "CYM", name: "Cayman Islands"},
    {code: "CAF", name: "Central African Republic"},
    {code: "TCD", name: "Chad"},
    {code: "CHL", name: "Chile"},
    {code: "CHN", name: "China"},
    {code: "CXR", name: "Christmas Island"},
    {code: "CCK", name: "Cocos (Keeling) Islands"},
    {code: "COL", name: "Colombia"},
    {code: "COM", name: "Comoros"},
    {code: "COG", name: "Congo"},
    {code: "COK", name: "Cook Islands"},
    {code: "CRI", name: "Costa Rica"},
    {code: "CIV", name: "Côte d'Ivoire"},
    {code: "HRV", name: "Croatia"},
    {code: "CUB", name: "Cuba"},
    {code: "CYP", name: "Cyprus"},
    {code: "CZE", name: "Czech Republic"},
    {code: "PRK", name: "Democratic People's Republic of Korea"},
    {code: "COD", name: "Democratic Republic of the Congo"},
    {code: "DNK", name: "Denmark"},
    {code: "DJI", name: "Djibouti"},
    {code: "DMA", name: "Dominica"},
    {code: "DOM", name: "Dominican Republic"},
    {code: "TMP", name: "East Timor"},
    {code: "ECU", name: "Ecuador"},
    {code: "EGY", name: "Egypt"},
    {code: "SLV", name: "El Salvador"},
    {code: "GNQ", name: "Equatorial Guinea"},
    {code: "ERI", name: "Eritrea"},
    {code: "EST", name: "Estonia"},
    {code: "ETH", name: "Ethiopia"},
    {code: "FLK", name: "Falkland Islands (Malvinas)"},
    {code: "FRO", name: "Faeroe Islands"},
    {code: "FJI", name: "Fiji"},
    {code: "FIN", name: "Finland"},
    {code: "FRA", name: "France"},
    {code: "FXX", name: "France, Metropolitan"},
    {code: "GUF", name: "French Guiana"},
    {code: "PYF", name: "French Polynesia"},
    {code: "GAB", name: "Gabon"},
    {code: "GMB", name: "Gambia"},
    {code: "GEO", name: "Georgia"},
    {code: "DEU", name: "Germany"},
    {code: "GHA", name: "Ghana"},
    {code: "GIB", name: "Gibraltar"},
    {code: "GRC", name: "Greece"},
    {code: "GRL", name: "Greenland"},
    {code: "GRD", name: "Grenada"},
    {code: "GLP", name: "Guadeloupe"},
    {code: "GUM", name: "Guam"},
    {code: "GTM", name: "Guatemala"},
    {code: "GIN", name: "Guinea"},
    {code: "GNB", name: "Guinea-Bissau"},
    {code: "GUY", name: "Guyana"},
    {code: "HTI", name: "Haiti"},
    {code: "HMD", name: "Heard and McDonald Islands"},
    {code: "VAT", name: "Holy See (Vatican City State)"},
    {code: "HND", name: "Honduras"},
    {code: "HKG", name: "Hong Kong"},
    {code: "HUN", name: "Hungary"},
    {code: "ISL", name: "Iceland"},
    {code: "IND", name: "India"},
    {code: "IDN", name: "Indonesia"},
    {code: "IRN", name: "Iran, Islamic Republic of"},
    {code: "IRQ", name: "Iraq"},
    {code: "IRL", name: "Ireland"},
    {code: "ISR", name: "Israel"},
    {code: "ITA", name: "Italy"},
    {code: "JAM", name: "Jamaica"},
    {code: "JPN", name: "Japan"},
    {code: "JOR", name: "Jordan"},
    {code: "KAZ", name: "Kazakhstan"},
    {code: "KEN", name: "Kenya"},
    {code: "KIR", name: "Kiribati"},
    {code: "KWT", name: "Kuwait"},
    {code: "KGZ", name: "Kyrgyzstan"},
    {code: "LAO", name: "Lao People's Democratic Republic"},
    {code: "LVA", name: "Latvia"},
    {code: "LBN", name: "Lebanon"},
    {code: "LSO", name: "Lesotho"},
    {code: "LBR", name: "Liberia"},
    {code: "LBY", name: "Libyan Arab Jamahiriya"},
    {code: "LIE", name: "Liechtenstein"},
    {code: "LTU", name: "Lithuania"},
    {code: "LUX", name: "Luxembourg"},
    {code: "MDG", name: "Madagascar"},
    {code: "MWI", name: "Malawi"},
    {code: "MYS", name: "Malaysia"},
    {code: "MDV", name: "Maldives"},
    {code: "MLI", name: "Mali"},
    {code: "MLT", name: "Malta"},
    {code: "MHL", name: "Marshall Islands"},
    {code: "MTQ", name: "Martinique"},
    {code: "MRT", name: "Mauritania"},
    {code: "MUS", name: "Mauritius"},
    {code: "MYT", name: "Mayotte"},
    {code: "MEX", name: "Mexico"},
    {code: "FSM", name: "Micronesia, Federated States of"},
    {code: "MCO", name: "Monaco"},
    {code: "MNG", name: "Mongolia"},
    {code: "MSR", name: "Montserrat"},
    {code: "MAR", name: "Morocco"},
    {code: "MOZ", name: "Mozambique"},
    {code: "MMR", name: "Myanmar"},
    {code: "NAM", name: "Namibia"},
    {code: "NRU", name: "Nauru"},
    {code: "NPL", name: "Nepal"},
    {code: "NLD", name: "Netherlands, Kingdom of the"},
    {code: "ANT", name: "Netherlands Antilles"},
    {code: "NTZ", name: "Neutral Zone"},
    {code: "NCL", name: "New Caledonia"},
    {code: "NZL", name: "New Zealand"},
    {code: "NIC", name: "Nicaragua"},
    {code: "NER", name: "Niger"},
    {code: "NGA", name: "Nigeria"},
    {code: "NIU", name: "Niue"},
    {code: "NFK", name: "Norfolk Island"},
    {code: "MNP", name: "Northern Mariana Islands"},
    {code: "NOR", name: "Norway"},
    {code: "OMN", name: "Oman"},
    {code: "PAK", name: "Pakistan"},
    {code: "PLW", name: "Palau"},
    {code: "PAN", name: "Panama"},
    {code: "PNG", name: "Papua New Guinea"},
    {code: "PRY", name: "Paraguay"},
    {code: "PER", name: "Peru"},
    {code: "PHL", name: "Philippines"},
    {code: "PCN", name: "Pitcairn"},
    {code: "POL", name: "Poland"},
    {code: "PRT", name: "Portugal"},
    {code: "PRI", name: "Puerto Rico"},
    {code: "QAT", name: "Qatar"},
    {code: "KOR", name: "Republic of Korea"},
    {code: "MDA", name: "Republic of Moldova"},
    {code: "REU", name: "Réunion"},
    {code: "ROM", name: "Romania"},
    {code: "RUS", name: "Russian Federation"},
    {code: "RWA", name: "Rwanda"},
    {code: "SHN", name: "Saint Helena"},
    {code: "KNA", name: "Saint Kitts and Nevis"},
    {code: "LCA", name: "Saint Lucia"},
    {code: "SPM", name: "Saint Pierre and Miquelon"},
    {code: "VCT", name: "Saint Vincent and the Grenadines"},
    {code: "WSM", name: "Samoa"},
    {code: "SMR", name: "San Marino"},
    {code: "STP", name: "Sao Tome and Principe"},
    {code: "SAU", name: "Saudi Arabia"},
    {code: "SEN", name: "Senegal"},
    {code: "SYC", name: "Seychelles"},
    {code: "SLE", name: "Sierra Leone"},
    {code: "SGP", name: "Singapore"},
    {code: "SVK", name: "Slovakia"},
    {code: "SVN", name: "Slovenia"},
    {code: "SLB", name: "Solomon Islands"},
    {code: "SOM", name: "Somalia"},
    {code: "ZAF", name: "South Africa"},
    {code: "SGS", name: "South Georgia and the South Sandwich Island"},
    {code: "ESP", name: "Spain"},
    {code: "LKA", name: "Sri Lanka"},
    {code: "SDN", name: "Sudan"},
    {code: "SUR", name: "Suriname"},
    {code: "SJM", name: "Svalbard and Jan Mayen Islands"},
    {code: "SWZ", name: "Swaziland"},
    {code: "SWE", name: "Sweden"},
    {code: "CHE", name: "Switzerland"},
    {code: "SYR", name: "Syrian Arab Republic"},
    {code: "TWN", name: "Taiwan Province of China"},
    {code: "TJK", name: "Tajikistan"},
    {code: "THA", name: "Thailand"},
    {code: "MKD", name: "The former Yugoslav Republic of Macedonia"},
    {code: "TGO", name: "Togo"},
    {code: "TKL", name: "Tokelau"},
    {code: "TON", name: "Tonga"},
    {code: "TTO", name: "Trinidad and Tobago"},
    {code: "TUN", name: "Tunisia"},
    {code: "TUR", name: "Turkey"},
    {code: "TKM", name: "Turkmenistan"},
    {code: "TCA", name: "Turks and Caicos Islands"},
    {code: "TUV", name: "Tuvalu"},
    {code: "UGA", name: "Uganda"},
    {code: "UKR", name: "Ukraine"},
    {code: "ARE", name: "United Arab Emirates"},
    {
        code: "GBR",
        name: "United Kingdom of Great Britain and Northern Ireland Citizen",
    },
    {
        code: "GBD",
        name:
            "United Kingdom of Great Britain and Northern Ireland Dependent Territories Citizen",
    },
    {
        code: "GBN",
        name:
            "United Kingdom of Great Britain and Northern Ireland National (oversees)",
    },
    {
        code: "GBO",
        name:
            "United Kingdom of Great Britain and Northern Ireland Oversees Citizen",
    },
    {
        code: "GBP",
        name:
            "United Kingdom of Great Britain and Northern Ireland Protected Person",
    },
    {
        code: "GBS",
        name: "United Kingdom of Great Britain and Northern Ireland Subject",
    },
    {code: "TZA", name: "United Republic of Tanzania"},
    {code: "USA", name: "United States of America"},
    {code: "UMI", name: "United States of America Minor Outlying Islands"},
    {code: "URY", name: "Uruguay"},
    {code: "UZB", name: "Uzbekistan"},
    {code: "VUT", name: "Vanuatu"},
    {code: "VEN", name: "Venezuela"},
    {code: "VNM", name: "Viet Nam"},
    {code: "VGB", name: "Virgin Islands (Great Britian)"},
    {code: "VIR", name: "Virgin Islands (United States)"},
    {code: "WLF", name: "Wallis and Futuna Islands"},
    {code: "ESH", name: "Western Sahara"},
    {code: "YEM", name: "Yemen"},
    {code: "ZAR", name: "Zaire"},
    {code: "ZMB", name: "Zambia"},
    {code: "ZWE", name: "Zimbabwe"},
    {code: "UNO", name: "United Nations Organization Official"},
    {
        code: "UNA",
        name: "United Nations Organization Specialized Agency Official",
    },
    {code: "XAA", name: "Stateless (per Article 1 of 1954 convention)"},
    {
        code: "XXB",
        name:
            "Refugee (per Article 1 of 1951 convention, amended by 1967 protocol)",
    },
    {code: "XXC", name: "Refugee (non-convention)"},
    {code: "XXX", name: "Unspecified / Unknown"},
    {code: "UTO", name: "Utopian"},
];


export const ThirdPartyTypes = {
    "TEMP": 20401,
    "COMING": 20201
}