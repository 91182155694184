import React, { Component } from "react";
import { connect } from 'react-redux'
import styles from './changeLabel.module.scss';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Input,
  DialogTitle,
  Typography,
  Divider,
} from '@material-ui/core';
import {
  CLOSE_CHANGE_LABEL,
  SEARCH_BY_SERIAL,
  ENTER_NEW_LABEL,
  SUBMIT_CHANGE_LABEL,
} from '../../../../../actions';
import { Magnify } from 'mdi-material-ui';
import { Slider } from '../../../../../components/slider';
import { withCmdCode } from '@cosmos/chaos';
import classNames from 'classnames';

class ChangeLabelComponent extends Component {

  state = {
    searchedSerial: '',
    searchedLabel: '',
  }

  componentDidMount() {
    this.cmdSource = this.props.source.subscribe(code => {
      if (code.cmd) {
        switch (code.cmd) {
          case 'STK':
            this.props.dispatch({ type: ENTER_NEW_LABEL, payload: { newLabel: code.val } });
            break;
          default:
        }
      }
    });
  }

  closeDialog = () => {
    this.setState({ searchedLabel: '', searchedSerial: '' });
    this.props.dispatch({ type: CLOSE_CHANGE_LABEL });
  }

  updateSearchedSerial = (e) => {
    this.setState({ searchedSerial: e.target.value });
  }

  updateSearchedLabel = (e) => {
    this.setState({ searchedLabel: e.target.value });
  }

  searchStock = () => {
    this.props.dispatch({ type: SEARCH_BY_SERIAL, payload: { serial: this.state.searchedSerial } });
  }

  searchLabel = () => {
    this.props.dispatch({ type: ENTER_NEW_LABEL, payload: { newLabel: this.state.searchedLabel } });
  }

  handleSubmit = () => {
    this.setState({ searchedLabel: '', searchedSerial: '' });
    this.props.dispatch({ type: SUBMIT_CHANGE_LABEL });
  }

  render() {
    const { dialogOpen, product, searchedStock, newLabel, dataUris } = this.props;
    const { searchedSerial, searchedLabel } = this.state;

    return (
      <div >
        <Dialog className={styles['dialog']} open={dialogOpen} maxWidth="xl">
          <DialogTitle> Change Label </DialogTitle>
          <DialogContent>
            <div className={styles['content']}>
              <div className={styles['search-area']}>
                <Input
                  className={classNames(styles['search-bar'], { valid: (searchedStock !== null) })}
                  value={searchedSerial}
                  fullWidth
                  placeholder={'Search by serial'}
                  onChange={this.updateSearchedSerial}
                />
                <Button className={styles.btn} variant="contained" color="primary" onClick={this.searchStock}>
                  <Magnify />Search
              </Button>
              </div>

              <div className={styles['stock-part']}>
                <div className={styles['info-part']}>
                  <div className={styles['info']}>
                    <Typography variant="h6">Stock Info</Typography>
                    <Divider />
                    <Typography variant="subtitle2">Serial </Typography>
                    <Typography variant="body2">{searchedStock ? searchedStock.serial : ''}</Typography>
                  </div>
                  <div className={styles['info']}>
                    <Typography variant="h6">Model Info</Typography>
                    <Divider />
                    <Typography variant="subtitle2">Model </Typography>
                    <Typography variant="body2">{product ? product.name : ''}</Typography>
                    <Typography variant="subtitle2">Reference </Typography>
                    <Typography variant="body2">{product ? product.reference : ''}</Typography>
                    <Typography variant="subtitle2">Dial </Typography>
                    <Typography variant="body2">{product ? product.dialColor : ''}</Typography>
                  </div>
                </div>
                <div className={styles['image-part']}>
                  {
                    searchedStock && searchedStock.attachments && searchedStock.attachments.length > 0 &&
                    <Slider assets={dataUris} />
                  }
                </div>
              </div>

              {
                (searchedStock !== null) && (
                  <div className={styles['search-area']}>
                    <Input
                      className={classNames(styles['search-bar'], { valid: (newLabel !== null) })}
                      value={searchedLabel}
                      fullWidth
                      placeholder={'Enter new label'}
                      onChange={this.updateSearchedLabel}
                    />
                    <Button className={styles.btn} variant="contained" color="primary" onClick={this.searchLabel}>
                      <Magnify />Search
                    </Button>
                  </div>
                )
              }

            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color='primary' variant='outlined' size='small'>
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} disabled={(searchedStock === null) || (newLabel === null)} color='primary' variant='contained' size='small'>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export const ChangeLabelDialog = connect(state => {
  const products = state.catalog.products;
  const searchedStock = state.inventory.searchedStock;
  const product = products && searchedStock && searchedStock.productId && products[searchedStock.productId] ? products[searchedStock.productId] : null;
  return {
    dialogOpen: state.inventory.changeLabelDialog,
    product: product,
    searchedStock: searchedStock,
    newLabel: state.inventory.newLabel,
    dataUris: state.inventory.dataUris,
  }
}
)(withCmdCode(ChangeLabelComponent));
