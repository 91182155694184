import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    root: {
        margin: '0',
        marginBottom: '3px'
    }
})

/**
 * Wrapper for an input to handle error states
 * @param {Object} props
 * @param {boolean} props.error - Whether input is in an error state
 * @param {string} [props.errorMessage="Error"] - The error message to display
 * @param {function} props.render - The component to render
 * @param {boolean} [props.fullWidth=true] - Whether component takes up full width
 * @param {string} props.className - Classname to apply to component being rendered
 */
export default function InputWithError({error, errorMessage="Error", render, className, fullWidth=true, ...props}) {
    const classes = useStyles()

    return (
        <FormControl error={!!error} margin="dense" className={`${classes.root} ${className}`} size="small" fullWidth={fullWidth}>
            {render({error, ...props})}
            <FormHelperText>{error ? errorMessage : null}</FormHelperText>
        </FormControl>
    )
}

InputWithError.propTypes = {
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    render: PropTypes.func.isRequired,
    className: PropTypes.string
}