import React, { useState, useEffect } from 'react'
import splitCamelCase from '../functions/splitCamelCase'
import NoImagePlaceholder from '../../../components/NoImagePlaceholder'
import useHeaders from '../../../hooks/useHeaders'
import getImage from '../../../services/getImage'

export default function DisplayStock({ stock }) {
    const headers = useHeaders()
    const [images, setImages] = useState([])
    useEffect(() => {
        async function fetch() {
            const responses = await Promise.all([stock.watchFront, stock.cardFront, stock.cardBack].map(imageURL => getImage(`/images/${imageURL}`, headers))) // todo change url here
            setImages(responses.map(response => response.data))
        }
        fetch()
    }, [stock.watchFront, stock.cardFront, stock.cardBack, headers])

    const entriesToExclude = ['model', 'watchFront', 'cardFront', 'cardBack', 'conditionDetails']
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0,1fr))', padding: '2em', justifyContent: 'space-between' }}>
            <div style={{padding: '0.5em', wordWrap: 'break-word'}}>
                {Object.entries(stock).filter(([key, value]) => !entriesToExclude.includes(key)).map(([key, value]) => <div key={key}>{renderKeyValuePair(splitCamelCase(key), value)}</div>)}
                {renderItemCondition(stock.conditionDetails)}
            </div>
            <div>
                {images.map((image, index) => image ? <img key={image} src={image} alt={image} /> : <NoImagePlaceholder key={index} width="100%" margin={"1em"} />)}
            </div>
        </div>
    )
}

function renderKeyValuePair(key, value) {
    return <p key={key}><small>{key}</small>: <strong style={{fontSize: '1.5em'}}>{typeof value === 'string' ? value : JSON.stringify(value)}</strong></p>
}

function renderItemCondition(condition) {
    return Object.entries(condition).map(([key, value]) => renderKeyValuePair(splitCamelCase(key), value ? 'Yes' : 'No'))
}
