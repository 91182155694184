import React from 'react';
import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import {
  Fab
} from '@material-ui/core';
import { Plus } from "mdi-material-ui";
import { DetailRowComponent } from './detailRow';
import { HeaderRow } from './Header';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

const styles = {
  addDetailButton: {
    padding: '1em',
    textAlign: 'right'
  }
}

class DetailComponent_ extends Component {

  createDetail = () => {
    this.props.createNewDetail();
  }

  handleDeleteRow = (idx) => {
    this.props.removeDetail(idx);
  }

  handleSelectRow = (idx) => {
    this.props.selectDetail(idx);
  }

  render() {
    const { purchaseInput, products, updatePDField, updatePdRemarks, updatePdPackageRemarks, selectedPdIdx, readOnly, currencySymbol, conditionOptions, classes } = this.props;
    return (
      <div style={{padding: '1em'}}>
        <div className={classes.addDetailButton}>
          <Fab color="primary" size="small" onClick={this.createDetail} disabled={readOnly}>
            <Plus />
          </Fab>
        </div>
        <TableContainer style={{height: '400px'}}>
          <Table stickyHeader size="small">
            <HeaderRow />
            <TableBody>
              {
                purchaseInput.purchaseDetails && purchaseInput.purchaseDetails.map((product, idx) =>
                  (
                    <DetailRowComponent
                      key={`pd_${idx}`}
                      product={product}
                      idx={idx}
                      type={purchaseInput.type}
                      products={products}
                      handleDeleteRow={() => { this.handleDeleteRow(idx) }}
                      handleSelectRow={() => { this.handleSelectRow(idx) }}
                      updatePDField={updatePDField}
                      updatePdRemarks={updatePdRemarks}
                      updatePdPackageRemarks={updatePdPackageRemarks}
                      selectedPdIdx={selectedPdIdx}
                      readOnly={readOnly}
                      currencySymbol={currencySymbol}
                      conditionOptions={conditionOptions}
                    />
                  )
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }
}

export const DetailComponent = withStyles(styles)(DetailComponent_)