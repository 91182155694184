import React, {useState} from "react";
import {darken, alpha} from "@material-ui/core/styles";
import moment from "moment";
import {makeStyles} from "@material-ui/core";
import {ClockOutline} from 'mdi-material-ui';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: alpha(theme.palette.primary.light, 0.5),
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        marginRight: `${theme.spacing(1)}px`,
        borderRadius: `${theme.spacing(1)}px`,
        display: 'flex',
        alignItems: 'center'
    },
    display: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: `${theme.spacing(0.5)}px`
    },
    date: {
        color: darken(theme.palette.primary.dark, 0.5),
        fontWeight: 'bold',
        lineHeight: '1.25'
    },
    time: {
        color: darken(theme.palette.primary.dark, 0.5),
        fontWeight: 'bold',
        lineHeight: '1.25'
    }
}));

export function MiniDateTime(props) {
    const [date, setDate] = useState(moment().format('YYYY/MM/DD'));
    const [time, setTime] = useState(moment().format('HH:mm:ss'));
    const styles = useStyles();

    const tick = () => {
        const now = moment();
        setDate(now.format('YYYY/MM/DD'));
        setTime(now.format('HH:mm:ss'))
    };

    setInterval(tick, 1000);

    return (
        <div className={styles.root}>
            <ClockOutline/>
            <div className={styles.display}>
                <div className={styles.date}>{date}</div>
                <div className={styles.time}>{time}</div>
            </div>
        </div>
    )
}