import React from "react";
import {makeStyles} from "@material-ui/styles";
import {NavLink,useLocation} from "react-router-dom";
import {lighten} from "@material-ui/core/styles";
import {isAuthorized} from "@cosmos/chaos";
import { useSelector } from "react-redux";
import * as classNames from 'classnames';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    online: {
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
        borderRight: '1px solid #DEDEDE',
    },
    menuButton: {
        backgroundColor: lighten(theme.palette.primary.light, 0.3),
        margin: '4px',
        justifyContent: 'start',
        fontWeight: 'bold',
        '&.active': {
            fontWeight: 'bold',
            color: theme.palette.common.white,
            backgroundColor: lighten(theme.palette.primary.dark, 0.2)
        }
    },
    menuButtonIcon: {
        margin: '6px',
        marginRight: '10px'
    },
    adminMode: {
        backgroundColor: lighten(theme.palette.secondary.light, 0.5),
    },
}));

export const MainMenu = ({className}) => {
    const styles = useStyles();
    // const dispatch = useDispatch();
    const location = useLocation();
    const permission = useSelector(state=>state.auth.permission);
    const isAuth = useSelector(state=>state.auth.isAuthenticated);
    const clientVersion = useSelector(state=>state.system.client.version);
    const systemVersion = useSelector(state=>state.system.server.version);
    const rayLens = useSelector(state=>state.system.rayLens);
    const stores = useSelector(state=>state.system.stores,);
    const features = useSelector(state=>state.system.features);
    let sub;
    let path = (location.pathname) ? location.pathname.substr(1) : location.location.pathname.substr(1);
    console.log("PATH", path);
    if (path.indexOf('/') > -1) {
        sub = path.substr(path.indexOf('/') + 1);
        path = path.substr(0, path.indexOf('/'));
    }
    console.log('SUB', sub);
    const func = features.find(f=>f.name === path);
    const isDutyFreeStore = (stores[rayLens] && stores[rayLens].id === "2") ? true : false; // id 2 = 'MW Trade'
    return (
        <aside className={classNames(styles.root, className, isAuth && styles.online, (func && func.name === 'admin') && styles.adminMode)}>
            <section className={classNames(styles.container)}>
                {   
                    func &&
                    func.features
                        .map(f => features.find(fe=>fe.name === f))
                        .filter(f => !!f && isAuthorized(permission || [], ...f.permission) && !f.hide)
                        .filter(f => {
                            if (!isDutyFreeStore) {
                                return f.path !== '/dutyfree';
                            }   
                            return f;
                        })
                        .map(item => (
                            <Button key={item.name}
                                    selected={item.name === sub}
                                    component={NavLink}
                                    to={`${func.path}${item.path}`}
                                    className={styles.menuButton}
                                    exact={item.exact}
                                    fullWidth
                                    startIcon={item.icon && <item.icon/>}
                            >
                                {item.name}
                            </Button>
                        ))
                }
            </section>
            {false && <section
                className={classNames(styles.base)}>
                <div className={styles.sysInfo}>
                    <div className={styles.indicator}>
                    </div>
                    <div className={classNames(styles.display)}>
                        <div className={styles.version}>
                            <span>System:</span> {systemVersion}
                        </div>
                        <div className={styles.version}>
                            <span>Client:</span> {clientVersion}
                        </div>
                    </div>
                </div>
            </section>}
        </aside>
    )
};
