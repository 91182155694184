import React from 'react'
import InputWithError from './InputWithError'
import {Input} from '../../../components/inputs'
import CameraInput from './CameraInput'
import AutoComplete from '@material-ui/lab/Autocomplete'
import { Nationalities as countries } from '../../../constants'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    textCenter: {
        '& *': {
            textAlign: 'center'
        }
    }
})

export default function PersonalInformationForm({ formErrors, inputs, onChange, setFormErrors }) {
    const currentCountry = useSelector(state => {
        const currentStoreId = state.system.rayLens;
        const currentCountryId = state.system.stores[currentStoreId].countryId;
        const currentCountry = state.system.countries[currentCountryId].name;
        return currentCountry;
    })
    const classes = useStyles()
    return (
        <>
            <h2>Personal Information</h2>
            <InputWithError error={!!formErrors.name} errorMessage={formErrors.name}
                render={props => <Input {...props} separateLabel label="Name" value={inputs.name} onChange={onChange('name')} />}
            />
            <InputWithError error={!!formErrors.id} errorMessage={formErrors.id}
                render={props => <Input {...props} separateLabel label="ID" value={inputs.id} onChange={onChange('id')} />}
            />
            <InputWithError error={!!formErrors.phone} errorMessage={formErrors.phone}
                render={props => <Input {...props} separateLabel label="Phone" value={inputs.phone} onChange={onChange('phone')} />}
            />
            <InputWithError error={!!formErrors.birthday} errorMessage={formErrors.birthday}
                render={props => <Input {...props} separateLabel label="Birthday" value={inputs.birthday} onChange={onChange('birthday')} type="Date" />}
            />
            <InputWithError error={!!formErrors.occupation} errorMessage={formErrors.occupation}
                render={props => <Input {...props} separateLabel label="Occupation" value={inputs.occupation} onChange={onChange('occupation')} />}
            />
            <InputWithError error={!!formErrors.country} errorMessage={formErrors.country}
                render={props => (
                    <AutoComplete
                        options={countries}
                        getOptionLabel={option => option.name || ''}
                        onChange={(event, value, reason) => onChange('country')({ target: { value } })}
                        value={inputs.country}
                        renderInput={params => <Input {...params} {...props} separateLabel label="Country" />}
                    />
                )}
            />
            <InputWithError error={!!formErrors.address1} errorMessage={formErrors.address1}
                render={props => <Input {...props} separateLabel label="Address 1" value={inputs.address1} onChange={onChange('address1')} />}
            />
            <InputWithError error={!!formErrors.address2} errorMessage={formErrors.address2}
                render={props => <Input {...props} separateLabel label="Address 2" value={inputs.address2} onChange={onChange('address2')} />}
            />

            <InputWithError error={!!formErrors.residentialStatus} errorMessage={formErrors.residentialStatus}
                render={props => (
                    <RadioGroup
                        aria-label="Residential status"
                        value={inputs.residentialStatus}
                        onChange={onChange('residentialStatus')}
                        row
                    >
                        <FormControlLabel value="local" control={<Radio />} label="Local" />
                        <FormControlLabel value="foreigner" control={<Radio />} label="Foreigner" />
                    </RadioGroup>)}
            />

            <InputWithError error={!!formErrors.idImage} errorMessage={formErrors.idImage} className={classes.textCenter}
                render={props =>
                    <CameraInput
                        {...props} separateLabel label={"ID/Passport"} value={inputs.idImage} handleError={error => setFormErrors(prevErrors => ({ ...prevErrors, idImage: error }))}
                        uploadUrl={'/v1/upload/walkin'} handleChange={onChange('idImage')}
                    />
                }
            />

            {currentCountry === 'Japan' ? (
                <>
                    <h2>Payment method</h2>
                    <InputWithError error={!!formErrors.paymentMethod} errorMessage={formErrors.paymentMethod}
                        render={props => (
                            <RadioGroup row
                                aria-label="Payment method"
                                value={inputs.paymentMethod}
                                onChange={onChange('paymentMethod')}
                                className={classes.row}
                            >
                                <FormControlLabel value="cash" position="bottom" control={<Radio />} label="Cash" />
                                <FormControlLabel value="bank" position="bottom" control={<Radio />} label="Bank Transfer" />
                            </RadioGroup>
                        )}
                    />
                    <InputWithError error={!!formErrors.identity} errorMessage={formErrors.identity}
                        render={props => (
                            <Input {...props} label="Identity"
                                onChange={onChange('identity')}
                                separateLabel select value={inputs.identity}
                            >
                                <MenuItem value={''} disabled><em>Select a value</em></MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                            </Input>
                        )}
                    />
                </>
            ) : null}
        </>
    )
}
