import { createVibes } from "@cosmos/vibes";
import {
  INV_LOAD_SUCCESS,
  COUNTRY_FILTER_UPDATED,
  HOLDER_FILTER_UPDATED,
  UPDATE_SEARCH_KEY,
  BRAND_FILTER_UPDATED,
  SUPPLIER_FILTER_UPDATED,
  TO_FILTER_INV,
  FILTER_INV_SUCCESS,
  filterSuccess,
  PROVISION_FILTER_UPDATED,
  UPDATE_SEARCH_BAR,
  CONDITION_FILTER_UPDATED,
  TO_FILTER_PURCHASE,
  UPDATE_FILTERED_PURCHASES,
  UPDATE_SELECTED_TYPE,
  UPDATE_SELECTED_RANGE,
  FILTER_PURCHASES,
  UPDATE_TENDER_SEARCH_TEXT,
  TO_FILTER_TENDER,
  UPDATE_FILTERED_TENDERS,
  UPDATE_SELECTED_PERSONIC,
  UPDATE_RESERVATION_SEARCH_TEXT,
  TO_FILTER_RESERVATION,
  UPDATE_FILTERED_RESERVATION,
  UPDATE_SELECTED_PERSON,
  STATE_FILTER_UPDATED,
  UPDATE_SELECTED_STOCK_PERSON,
  UPDATE_SELECTED_STOCK_STATE,
  LOAD_RESERVATION_SUCCESS,
} from "../../actions";
import _ from "lodash";

export const dispatchFilterVibes = createVibes({
  type: [
    INV_LOAD_SUCCESS,
    UPDATE_SEARCH_KEY,
    COUNTRY_FILTER_UPDATED,
    HOLDER_FILTER_UPDATED,
    BRAND_FILTER_UPDATED,
    SUPPLIER_FILTER_UPDATED,
    CONDITION_FILTER_UPDATED,
    PROVISION_FILTER_UPDATED,
    UPDATE_SEARCH_BAR,
    UPDATE_SELECTED_TYPE,
    UPDATE_SELECTED_RANGE,
    FILTER_PURCHASES,
    UPDATE_TENDER_SEARCH_TEXT,
    UPDATE_SELECTED_PERSONIC,
    UPDATE_RESERVATION_SEARCH_TEXT,
    UPDATE_SELECTED_PERSON,
    STATE_FILTER_UPDATED,
    UPDATE_SELECTED_STOCK_PERSON,
    UPDATE_SELECTED_STOCK_STATE,
    LOAD_RESERVATION_SUCCESS,
  ],
  async process({ getState, action, client }, dispatch, done) {
    try {
      const states = getState();
      let orOperator = [];
      let andOperator = [];
      let binaryAndOperator = [];
      let dateAndOperator = [];
      let dataset = [];
      let keywords = [];
      let brandFilters = [];
      let supplierFilters = [];
      let provisionFilters = [];
      let conditionFilters = [];
      let holderFilters = [];
      let typeFilters = [];
      let personFilters = [];
      let reservationPersonFilters = [];
      let dateRangeFilter = {};
      let reservationStateFilter = [];
      let countryFilter = [];

      switch (action.type) {
        case INV_LOAD_SUCCESS:
        case UPDATE_SEARCH_KEY:
        case BRAND_FILTER_UPDATED:
        case SUPPLIER_FILTER_UPDATED:
        case PROVISION_FILTER_UPDATED:
        case CONDITION_FILTER_UPDATED:
        case HOLDER_FILTER_UPDATED:
        case COUNTRY_FILTER_UPDATED:
        case UPDATE_SELECTED_STOCK_PERSON:
        case UPDATE_SELECTED_STOCK_STATE:
          const stocks = states.inventory.stocks;
          const stocksDetail = states.inventory.stocksDetail;
          dataset = Object.values(stocks).map((stock) => {
            stock.serial =
              stocksDetail[stock.id] && stocksDetail[stock.id].serial;
            stock.condition =
              stocksDetail[stock.id] && stocksDetail[stock.id].condition;
            return stock;
          });

          keywords = _.compact(states.inventory.search.split(";"));
          brandFilters = states.inventory.brandFilter;
          supplierFilters = states.inventory.supplierFilter;
          holderFilters = states.inventory.holderFilter;
          countryFilter = states.inventory.countryFilter;
          provisionFilters = states.inventory.provisionFilter;
          conditionFilters = states.inventory.conditionFilter;
          reservationPersonFilters = states.inventory.selectedStockPics.map(
            (el) => parseInt(el)
          );
          reservationStateFilter = states.inventory.selectedStockStates;

          if (supplierFilters.length) {
            andOperator = [
              { key: "supplier", exactMatch: true, search: supplierFilters },
            ];
          }

          if (brandFilters.length) {
            andOperator = [
              { key: "brandId", exactMatch: true, search: brandFilters },
            ];
          }

          if (holderFilters.length) {
            andOperator = [
              { key: "storeId", exactMatch: true, search: holderFilters },
            ];
          }

          if (countryFilter.length) {
            andOperator = [
              { key: "countryId", exactMatch: true, search: countryFilter },
            ];
          }

          //TODO: check whether the provision should be bitwise integer instead of 0, 1, 2, 3
          if (provisionFilters.length) {
            andOperator = [
              { key: "provision", exactMatch: true, search: provisionFilters },
            ];
          }

          if (conditionFilters.length) {
            andOperator = [
              { key: "condition", exactMatch: true, search: conditionFilters },
            ];
          }

          if (reservationPersonFilters.length) {
            andOperator = [
              {
                key: "reservationPic",
                exactMatch: true,
                search: reservationPersonFilters,
              },
            ];
          }

          if (reservationStateFilter.length) {
            andOperator = [
              {
                key: "reservationState",
                exactMatch: true,
                search: reservationStateFilter,
              },
            ];
          }

          if (keywords.length) {
            orOperator = [
              { key: "id", exactMatch: false, search: keywords },
              { key: "serial", exactMatch: false, search: keywords },
              { key: "productReference", exactMatch: false, search: keywords },
            ];
          }
          if (
            brandFilters.length ||
            supplierFilters.length ||
            keywords.length ||
            provisionFilters.length ||
            conditionFilters.length ||
            reservationPersonFilters.length ||
            reservationStateFilter.length ||
            holderFilters.length ||
            countryFilter.length
          ) {
            dispatch({
              type: TO_FILTER_INV,
              payload: {
                dataset: dataset,
                andOperator: andOperator,
                orOperator: orOperator,
                binaryAndOperator: binaryAndOperator,
              },
            });
          } else {
            dispatch(
              filterSuccess({
                type: FILTER_INV_SUCCESS,
                payload: { filteredStocks: dataset.map((el) => el.id) },
              })
            );
          }
          done();
          break;
        case UPDATE_SEARCH_BAR:
        case UPDATE_SELECTED_TYPE:
        case UPDATE_SELECTED_RANGE:
        case FILTER_PURCHASES:
          dataset = Object.values(states.purchase.purchases);
          keywords = _.compact(states.purchase.searchText.split(";"));
          typeFilters = states.purchase.selectedTypes.map((el) => el);
          dateRangeFilter = states.purchase.selectedDateRange;
          if (keywords.length > 0) {
            orOperator = [
              { key: "id", exactMatch: false, search: keywords },
              { key: "reference", exactMatch: false, search: keywords },
            ];
          }

          if (typeFilters.length > 0) {
            andOperator = [
              { key: "type", exactMatch: true, search: typeFilters },
            ];
          }

          if (dateRangeFilter.start || dateRangeFilter.end) {
            dateAndOperator = [{ key: "date", search: dateRangeFilter }];
          }

          if (
            dateRangeFilter.start ||
            dateRangeFilter.end ||
            typeFilters.length > 0 ||
            keywords.length > 0
          ) {
            dispatch({
              type: TO_FILTER_PURCHASE,
              payload: {
                dataset: dataset,
                andOperator: andOperator,
                orOperator: orOperator,
                dateAndOperator: dateAndOperator,
              },
            });
          } else {
            dispatch(
              filterSuccess({
                type: UPDATE_FILTERED_PURCHASES,
                payload: { filteredPurchases: dataset.map((el) => el.id) },
              })
            );
          }
          done();
          break;
        case LOAD_RESERVATION_SUCCESS:
        case UPDATE_RESERVATION_SEARCH_TEXT:
        case STATE_FILTER_UPDATED:
        case UPDATE_SELECTED_PERSON:
          dataset = Object.values(states.reservation.procurements);
          keywords = _.compact(states.reservation.searchText.split(";"));
          reservationPersonFilters = states.reservation.selectedPersons.map(
            (el) => parseInt(el)
          );
          reservationStateFilter = states.reservation.selectedStates;

          if (keywords.length > 0) {
            orOperator = [
              { key: "productReference", exactMatch: false, search: keywords },
            ];
          }

          if (reservationPersonFilters.length) {
            andOperator = [
              {
                key: "pic",
                exactMatch: true,
                search: reservationPersonFilters,
              },
            ];
          }

          if (reservationStateFilter.length) {
            andOperator = [
              {
                key: "state",
                exactMatch: true,
                search: reservationStateFilter,
              },
            ];
          }

          if (
            keywords.length > 0 ||
            reservationPersonFilters.length > 0 ||
            reservationStateFilter.length
          ) {
            dispatch({
              type: TO_FILTER_RESERVATION,
              payload: {
                dataset: dataset,
                andOperator: andOperator,
                orOperator: orOperator,
              },
            });
          } else {
            dispatch(
              filterSuccess({
                type: UPDATE_FILTERED_RESERVATION,
                payload: { filteredProcurements: dataset.map((el) => el.id) },
              })
            );
          }
          done();
          break;
        case UPDATE_TENDER_SEARCH_TEXT:
        case UPDATE_SELECTED_PERSONIC:
          dataset = Object.values(states.tender.tenders);
          keywords = _.compact(states.tender.searchText.split(";"));
          personFilters = states.tender.selectedPersons.map((el) =>
            parseInt(el)
          );

          if (keywords.length > 0) {
            orOperator = [
              { key: "reference", exactMatch: false, search: keywords },
            ];
          }

          if (personFilters.length > 0) {
            andOperator = [
              { key: "pic", exactMatch: true, search: personFilters },
            ];
          }

          if (keywords.length > 0 || personFilters.length > 0) {
            dispatch({
              type: TO_FILTER_TENDER,
              payload: {
                dataset: dataset,
                andOperator: andOperator,
                orOperator: orOperator,
              },
            });
          } else {
            dispatch(
              filterSuccess({
                type: UPDATE_FILTERED_TENDERS,
                payload: { filteredTenders: dataset.map((el) => el.id) },
              })
            );
          }
          done();
          break;
        default:
      }
    } catch (err) {
      throw err;
    }
  },
});
