import axios from 'axios'

export default async (inputs, once, headers) => {
    let walkInData = {
        once
    }
    let purchaseOrderData = {
        once,
        type: 'WALKIN'
    }
    if (inputs) {
        const { name, id: idNumber, country, birthday, address1, address2, zip, city, phone, occupation, idImage: documentIndicator, residentialStatus: nationality } = inputs;
        walkInData = {
            supplier: {
                name,
                idNumber,
                country: country.name,
                birthday,
                address1,
                address2,
                zip,
                city,
                nationality,
                phone,
                occupation
            },
            documentIndicator,
            once,
        }

        const { phone: contact, currency } = inputs
        const purchaseDetails = inputs.orderDetails.map(orderDetail => ({
            brandId: orderDetail.product.brand.id,
            productId: orderDetail.product.product.id,
            unitPrice: Number.parseFloat(orderDetail.price),
            quantityExpected: 1,
            quantity: 1,
            remarks: { condition: orderDetail.condition, conditionDetails: orderDetail.conditionDetails }
        }))
        purchaseOrderData = {
            type: 'WALKIN',
            once,
            contact,
            currency: currency.id,
            purchaseDetails
        }
    }

    const query = `
        mutation createWalkIn($WalkInInput: WalkInPurchaseInput, $PurchaseInput: PurchaseInput){
            updateWalkInPurchase(input: $WalkInInput) {
                id
                proformaPurchaseId
                reference
            }
            updatePO(input: $PurchaseInput) {
                id
                reference
            }
        }
    `

    const variables = { WalkInInput: walkInData, PurchaseInput: purchaseOrderData }

    try {
        const response = await axios.request({
            url: '/v1/api',
            method: 'post',
            headers,
            data: {
                query,
                variables
            }
        })

        if (response.data.hasOwnProperty('errors')) {
            return { error: response.data.errors[0] }
        }

        const storeId = headers.rayLens
        const walkInPurchaseData = {
            ...response.data.data.updateWalkIn,
            downloadUrl: `/piv/${storeId}/${response.data.data.createWalkInPurchase.reference}`
        }
        const purchaseOrderData = {
            ...response.data.data.updatePO,
            downloadUrl: `/wpi/${storeId}/${response.data.data.updatePO.reference}`
        }

        return { data: { walkInPurchaseData, purchaseOrderData } }
    } catch (e) {
        const response = e.response

        if (!e.response) {
            return { error: { message: e.message } }
        }

        if (response.data.hasOwnProperty('errors')) {
            return { error: response.data.errors[0] }
        }

        return { error: { message: response.data } }
    }
}