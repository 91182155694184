import { useState } from 'react'

/**
 * 
 * @param {string} key - The key to store the value within localstorage
 * @param {any} initialValue - The initialValue
 * @returns [value, setValue] - Exactly like useState hook
 */
export default function useLocalStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
        const dataFromLocalStorage = window.localStorage.getItem(key)
        return dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : initialValue
    })

    const setValue = value => {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
    };

    return [storedValue, setValue]
}