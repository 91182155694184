import React, { useEffect, useState } from "react";
import {
    Paper,
    LinearProgress,
    Snackbar
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { PriceRuleTable } from "./PriceRuleTable";
import { PriceRuleView } from "./PriceRuleView";
import { 
    CLOSE_PRICE_RULE_NOTIFICATION,
} from "../../actions";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0.5em",
        display: "flex",
        flexDirection: "column",
    },
    contentGrid: {
        flex: 1,
        display: "flex",
    },
    leftGrid: {
        flex: 2,
    },
    rightGrid: {
        flex: 1,
    }
}));

export const PriceRuleComponent = () => {
    const styles = useStyles();
    const dispatch = useDispatch();

    const loading = useSelector(state => state.priceRule.loading);
    const notify = useSelector(state => state.priceRule.notify);
    const notice = useSelector(state => state.priceRule.notice);
    const noticeType = useSelector(state => state.priceRule.noticeType);

    const [msgType, setMsgType] = useState(noticeType);

    useEffect(() => {
        if (noticeType) {
            setMsgType(noticeType);
        }
    }, [noticeType]);

    return (
        <Paper className={styles.root}>
            {loading && <LinearProgress />}
            <div className={styles.contentGrid}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={notify}
                    onClose={(e) => dispatch({ type: CLOSE_PRICE_RULE_NOTIFICATION })}
                    autoHideDuration={6000}
                >
                    <Alert variant="filled" severity={msgType}>
                        {notice}
                    </Alert>
                </Snackbar>
                <div className={styles.leftGrid}>
                    <PriceRuleTable />
                </div>
                <div className={styles.rightGrid}>
                    <PriceRuleView />
                </div>
            </div>
        </Paper>
    );
};