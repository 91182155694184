import React from 'react';
import {useSelector} from "react-redux";
import {LinearProgress, makeStyles, Typography} from "@material-ui/core";
import {darken} from "@material-ui/core/styles";
import {App} from "../app";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 3000,
        backgroundColor: darken(theme.palette.common.white, 0.05)
    },
    hide: {
        display: 'none'
    },
    block: {
        height: '20vh',
        width: '24vw',
        margin: '30vh auto',
        boxShadow: '1px 1px 1px rgba(0,0,0,0.3)',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark,
        padding: '1em 2em',
        textAlign: 'center'
    },
    content: {
        backgroundColor: theme.palette.common.white,
        padding: '1em 2em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    }
}));

export const Splash = (props) => {
    const mode = useSelector(state => state.system.mode);
    return (
        <>
            {mode > 0
                ? <App />
                : <SplashScreen message="Initializing..."/>
            }
        </>
    );
};

const SplashScreen = ({message}) => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <div className={styles.block}>
                <div className={styles.header}>
                    <Typography variant={'h4'}>
                        Cosmos System
                    </Typography>
                </div>
                <LinearProgress variant="indeterminate"/>
                <div className={styles.content}>
                    <Typography variant={'h6'}>
                        {message}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

