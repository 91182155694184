import React, { useState, useEffect } from 'react'
import AddStockDialog from './components/AddStockDialog'
import Button from '@material-ui/core/Button'
import StockItem from './components/StockItem'
import DisplayStock from './components/DisplayStock'
import { makeStyles } from '@material-ui/core/styles'
import VendorInformation from './components/VendorInformation'
import { useSelector } from 'react-redux'
import useQuery from '../../hooks/useQuery'
import { CreateVendorDialog } from './components/dialogs/createVendor';
import { OPEN_PO_STOCK_IN_DIALOG, CLOSE_PO_STOCK_IN_DIALOG, RETRIEVE_PO_STOCKS, UPDATE_PO_STOCK_INPUT, CREATE_VENDOR, CLOSE_CREATE_VENDOR, ASK_CREATE_VENDOR, CLOSE_STOCK_IN_NOTICE, CONFIRM_INIT_STOCK_IN, CANCEL_INIT_STOCK_IN, ASK_INIT_STOCK_IN } from "../../actions";
import { InventoryPOCheckDialog } from './components/dialogs/inventory';
import { connect, useDispatch } from "react-redux";
import { PopulateDataDialog, FocusDialog } from "../../components";
import { useHistory } from 'react-router'
import Paper from '@material-ui/core/Paper'
import { TrashCan, FileEdit } from 'mdi-material-ui';
import useOnce from '../../hooks/useOnce'
import useDialog from '../../hooks/useDialog'
import useLocalStorage from '../../hooks/useLocalStorage'
import useVendorInformation from './functions/useVendorInformation'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'grid',
		gridTemplateColumns: '1fr 2fr',
		gridTemplateRows: 'auto 1fr min-content',
		gridTemplateAreas: `
			'vendorInformation showProduct' 
			'stockList showProduct'
			'footer showProduct'
		`,
		gridGap: '1em',
		justifyContent: 'space-around',
		height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
	},
	vendorInformation: {
		gridArea: 'vendorInformation',
		padding: '0 1em',
		'& > *': {
			padding: '0',
			margin: '0.1em'
		}
	},
	stockList: {
		gridArea: 'stockList',
		overflowY: 'auto',
		maxHeight: '100%',
		position: 'relative'
	},
	stockItem: {
		display: 'grid',
		gridTemplateColumns: '1fr min-content',
		padding: '0.7em',
		border: '1px solid #ccc',
		'&:hover': {
			cursor: 'pointer',
			background: 'rgba(0,0,0,0.1)'
		},
		'& *': {
			margin: 0
		}
	},
	focusedStockItem: {
		background: 'rgba(0,0,0,0.1)'
	},
	actionButtonContainer: {
		'& > *': {
			margin: '10px'
		}
	},
	productInformation: {
		gridArea: 'showProduct',
		overflowY: 'auto',
		maxHeight: '100%'
	},
	footer: {
		gridArea: 'footer',
		display: 'flex',
		padding: '10px',
		'& > *': {
			margin: '0 1em'
		},
		'& > *:first-child': {
			marginRight: 'auto'
		}
	}
}))

// stock: model, serial, condition (new, used, brand new), birthday, birthmonth, birthyear, conditionDetails: {warranty, box, cardCase, manual, tag}

const StockInForm_ = props => {
	const [stocks, setStocks] = useLocalStorage('stocks', [])
	const [isAddStockDialogOpen, setIsAddStockDialogOpen] = useState(false)
	const [isEditStockDialogOpen, setIsEditStockDialogOpen] = useState(false)
	const [purchaseReference, setPurchaseReference] = useState(null)
	const { openDialog: openErrorDialog, Dialog: ErrorDialog } = useDialog()
	const { openDialog: openSuccessDialog, Dialog: SuccessDialog } = useDialog('success')
	const dispatch = useDispatch();
	useEffect(() => {
		if ((JSON.parse(localStorage.getItem('stocks')) && JSON.parse(localStorage.getItem('stocks')).length > 0) || localStorage.getItem('stockInInputs')) {
			dispatch({ type: ASK_INIT_STOCK_IN });
		}
		dispatch({ type: OPEN_PO_STOCK_IN_DIALOG })
	}, [dispatch]);

	const closeStockInDialog = (value) => {
		dispatch({ type: CLOSE_PO_STOCK_IN_DIALOG })
	}

	const updatePurchaseRef = event => {
		setPurchaseReference(event.target.value)
	}

	const findPurchaseOrderQuery = `query ($filter: purchaseFilter){
		purchaseOrder(filter: $filter) {
		 id reference state
		}
	}`
	const { execute: searchForPurchaseOrder } = useQuery(findPurchaseOrderQuery, { purchaseOrder: null }, {}, false)
	const checkReferenceNo = async () => {
		dispatch({ type: RETRIEVE_PO_STOCKS, payload: purchaseReference ? purchaseReference : "" })

		if (!purchaseReference) return
		try {
			const { purchaseOrder } = await searchForPurchaseOrder({ filter: { reference: purchaseReference } })
			if (!purchaseOrder) {
				setIsAddStockDialogOpen(false)
			}
		} catch (e) {
			setIsAddStockDialogOpen(false)
		}
	};

	const updateField = (value) => {
		dispatch({ type: UPDATE_PO_STOCK_INPUT, payload: value });
	}

	const closeCreateVendorDialog = () => {
		dispatch({ type: CLOSE_CREATE_VENDOR })
	}

	const createVendor = () => {
		dispatch({ type: CREATE_VENDOR });
	}

	const closeNotification = () => {
		dispatch({ type: CLOSE_STOCK_IN_NOTICE });
	};

	const closeInitDialog = () => {
		dispatch({ type: CANCEL_INIT_STOCK_IN });
		setStocks([])
	}

	const confirmInit = () => {
		dispatch({ type: CONFIRM_INIT_STOCK_IN });
		dispatch({ type: CLOSE_PO_STOCK_IN_DIALOG })
	}

	const initCreateVendor = () => {
		const vendorInput = stockInInput.vendor;
		if (vendorInput && isNaN(vendorInput)) {
			dispatch({ type: ASK_CREATE_VENDOR });
		}
	}

	const [focusedIndex, setFocusedIndex] = useState(-1)
	useEffect(() => {
		setFocusedIndex(stocks.length - 1)
	}, [stocks.length])

	const submitStockInQuery = `mutation
	( $stockIn: StockInInput) {
	 stockIn(stockIn: $stockIn){
	   id
	   stockInDetails {
		 id
		 stockId
		 stockInId
		 state
	   }
	   articleId
	   serial
	   brandId
	   productId
	   supplier
	   provision
	   stockIn
	 }
	}`
	const { execute: executeQuery, isLoading: isSubmittingStockIn, data: { stockIn: stockInData }, error } = useQuery(submitStockInQuery, { stockIn: null }, {}, false)
	const [once, changeOnce] = useOnce()
	const vendorInformation = useVendorInformation()

	const isAuthenticated = useSelector(state => state.auth && state.auth.isAuthenticated)
	const submitStockIn = () => {
		if (!isAuthenticated) return openErrorDialog('Not authenticated')
		const stockInInput = {
			purchaseId: null,
			once,
			...vendorInformation,
			stocks: stocks.map(stock => ({
				brandId: stock.model.brand.id,
				productId: stock.model.product.id,
				price: stock.price,
				serial: stock.serial,
				label: stock.label,
				cardFront: stock.cardFront,
				cardBack: stock.cardBack,
				watchFront: stock.watchFront
			}))
		}
		executeQuery({ stockIn: stockInInput })
		changeOnce()
	}
	useEffect(() => {
		if (error) openErrorDialog(JSON.stringify(error))
	}, [error, openErrorDialog])
	const history = useHistory()
	useEffect(() => {
		let timeoutHandler;
		if (stockInData) {
			openSuccessDialog('Successfully stocked in')
			timeoutHandler = setTimeout(() => history.push('/inventory'), 1000)
		}

		return () => clearTimeout(timeoutHandler)
	}, [stockInData, history, openSuccessDialog])

	const classes = useStyles()
	const { notify, noticeType, notice, initStockInDialog, stockInDialogOpen, traders, purchaseInput, askCreateVendor, stockInInput } = props;
	return (
		<div>
			<ErrorDialog />
			<SuccessDialog />
			<AddStockDialog
				isOpen={isAddStockDialogOpen}
				handleClose={() => setIsAddStockDialogOpen(false)}
				addStock={stock => { setStocks(prevStocks => [...prevStocks, stock]); setIsAddStockDialogOpen(true) }}
				isCancellable={stocks.length < 1 ? false : true}
			/>
			<AddStockDialog //EditStockDialog
				isOpen={isEditStockDialogOpen}
				handleClose={() => setIsEditStockDialogOpen(false)}
				editStock={stock => setStocks(prevStocks => {
					const updatedStocks = JSON.parse(JSON.stringify(prevStocks))
					updatedStocks.splice(focusedIndex, 1, stock)
					return updatedStocks
				})}
				stockToEdit={stocks[focusedIndex]}
				startFromStep={1}
			/>
			<InventoryPOCheckDialog
				dialogOpen={stockInDialogOpen}
				dialogOnClose={closeStockInDialog}
				referenceNo={purchaseReference}
				handleSubmit={() => { checkReferenceNo(); setIsAddStockDialogOpen(true) }}
				updatePurchaseRef={updatePurchaseRef}
				updateField={updateField}
				traders={traders}
				purchaseInput={purchaseInput}
				stockInInput={stockInInput}
				createVendor={initCreateVendor}
				ableSubmit={((stockInInput.vendor && stockInInput.date && stockInInput.invoice) || purchaseReference) ? false : true}
			/>
			<CreateVendorDialog
				closeDialog={closeCreateVendorDialog}
				confirmCreation={createVendor}
				vendorName={stockInInput.vendor}
				dialogOpen={askCreateVendor}
			/>
			<FocusDialog open={notify} message={notice} variant={noticeType} onClose={closeNotification} />
			<PopulateDataDialog
				dialogOpen={initStockInDialog}
				closeDialog={closeInitDialog}
				handleConfirm={confirmInit}
			/>
			<div className={classes.root}>
				<Paper style={{ margin: '0.4em' }}>
					<VendorInformation {...vendorInformation} className={classes.vendorInformation} />
				</Paper>
				<Paper className={classes.stockList} style={{ margin: '0.4em' }}>
					{stocks.map((stock, index) => (
						<div onClick={() => setFocusedIndex(index)} key={index} className={`${classes.stockItem} ${focusedIndex === index ? classes.focusedStockItem : ''}`}>
							<StockItem {...stock} />
							<div className={classes.actionButtonContainer}>
								<Button size="small" variant="contained" color="primary" onClick={() => setIsEditStockDialogOpen(true)}><FileEdit /></Button>
								<Button size="small" variant="contained" color="secondary" onClick={() => setStocks(prevStocks => {
									const updatedStocks = JSON.parse(JSON.stringify(prevStocks))
									updatedStocks.splice(index, 1);
									return updatedStocks
								})}><TrashCan /></Button>
							</div>
						</div>
					))}
				</Paper>
				{stocks[focusedIndex] && (
					<Paper className={classes.productInformation} style={{ margin: '0.4em' }}>
						<DisplayStock stock={stocks[focusedIndex]} />
					</Paper>
				)}
				<Paper className={classes.footer} style={{ margin: '0.4em' }}>
					<Button variant="contained" onClick={() => setIsAddStockDialogOpen(true)}>Add stock</Button>
					<Button variant="contained" color="primary" onClick={submitStockIn} disabled={ stocks.length < 1 || isSubmittingStockIn || stockInData || !vendorInformation.vendor}>{isSubmittingStockIn ? 'Loading' : stockInData ? 'Success' : 'Submit'}</Button>
				</Paper>
			</div>
		</div>
	)
}

export const StockInForm = connect(
	state => ({
		notify: state.purchase.notify,
		notice: state.purchase.notice,
		noticeType: state.purchase.noticeType,
		initStockInDialog: state.purchase.initStockInDialog,
		stockInDialogOpen: state.purchase.stockInDialogOpen,
		traders: state.trader.traders,
		purchaseInput: state.purchase.purchaseInput,
		askCreateVendor: state.purchase.askCreateVendor,
		stockInInput: state.purchase.stockInInput ? state.purchase.stockInInput : {},
	}))((StockInForm_));
