import React from 'react';
import Input from "./Input";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        paddingTop: "5px"
    },
});


export const AutoComplete = ({ options, handleChange, disabled, value, label, optionLabel, optionValue }) => {

    const classes = useStyles();

    return (<Autocomplete
        className={classes.root}
        autoHighlight
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => option[optionLabel] || ""}
        getOptionSelected={(option, value) => option[optionValue] === value[optionValue]}
        onChange={handleChange}
        value={value || ""}
        renderInput={params => <TextField {...params} label={label} />}
    // renderInput={(params) => (
    //     <Input {...params} separateLabel label={label || ""} />
    // )}
    />)
}

AutoComplete.propTypes = {
    options: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
    label: PropTypes.string,
    optionLabel: PropTypes.string.isRequired,
    optionValue: PropTypes.string.isRequired,

}

AutoComplete.defaultProps = {
    label: "Label",
    optionLabel: "name",
    disabled: false,
    optionValue: "id",
}

export default AutoComplete
