import React, { useState, useEffect } from 'react'
import ProductTable from '../BulkAddSupplies/ProductTable'
import VendorInformation from '../BulkAddSupplies/VendorInformation'
import useDialog from '../../../hooks/useDialog'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import {
    useDispatch,
    useSelector
} from 'react-redux'
import { CAPTURE_SUPPLIES, SELECT_MARKETING_IMPORT_BRAND } from '../../../actions'
import BrandInput from '../BrandInput'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'grid',
        gridTemplateAreas: `
            'vendorInformation productTable'
            'footer footer'
        `,
        gridTemplateColumns: '1fr 2fr',
        gridTemplateRows: '1fr auto',
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
    vendorInformation: {
        gridArea: 'vendorInformation',
        padding: '2em'
    },
    productTable: {
        gridArea: 'productTable',
        overflow: 'scroll',
        height: '100%'
    },
    footer: {
        gridArea: 'footer',
        padding: '1em',
        textAlign: 'right'
    }
}))

const data = [
    {
        "id": 2,
        "traderId": 4,
        "productId": 425,
        "brandId": 2,
        "productReference": "Stringhbi",
        "quantity": 1,
        "validTo": "2020-05-20T02:36:34.000Z",
        "validFrom": "2020-05-20T02:36:14.000Z",
        "channelId": 20401,
        "forexSetId": 27510,
        "currencyId": 1,
        "price": 0,
        "tax": 0,
        "amount": 0,
        "discount": null,
        "msrp": 55,
        "code": null,
        "reference": "20200512123"
    },
    {
        "id": 4,
        "traderId": 4,
        "productId": 425,
        "brandId": 2,
        "productReference": "Stringhbi",
        "quantity": 1,
        "validTo": "2038-01-18T19:14:07.000Z",
        "validFrom": "2020-05-20T02:36:34.000Z",
        "channelId": 20401,
        "forexSetId": 27510,
        "currencyId": 1,
        "price": 0,
        "tax": 0,
        "amount": 0,
        "discount": null,
        "msrp": 23,
        "code": null,
        "reference": "20200512123"
    }
]


export default function ModifySupplyProvision() {
    const [cols] = useState(['ITEM', 'Product', 'Price', 'Quantity', 'MSRP', 'Discount'])
    const [supplies, setSupplies] = useState([])
    const [focusedIndex, setFocusedIndex] = useState(-1)

    const [vendorInformation, setVendorInformation] = useState({
        country: "",
        vendor: "",
        currency: "",
        taxRate: "",
        brand: null
    })

    // const failedSupplies = useSelector(state => state.marketing.failedSupplies)
    const vendors = useSelector(state => state.trader.traders)
    const currencies = useSelector(state => state.system.currencies)
    const countries = useSelector(state => state.system.countries)
    useEffect(() => {
        // const supplies = convertFailedSuppliesToProductTableFormat(failedSupplies)
        const supplies = convertFailedSuppliesToProductTableFormat(data) // currently on fake data
        setSupplies(supplies)

        const vendor = vendors[data[0].traderId]
        const currency = currencies[data[0].currencyId]
        let country = ""
        if (vendor) {
            country = countries[vendor.countryId]
        }
        setVendorInformation({
            country,
            vendor,
            currency,
            taxRate: 0
        })
    }, [currencies, vendors, countries])

    const handleVendorInformationChange = (name) => (e) => {
        try {
            e.persist();
        } catch (e) { }
        setVendorInformation((prev) => ({ ...prev, [name]: e.target.value }));
    };

    const dispatch = useDispatch()
    const handleSubmit = () => {
        const suppliesToCapture = convertProductTableFormatToSupplies(supplies)
        dispatch({
            type: CAPTURE_SUPPLIES, payload: {
                vendorInformation,
                suppliesToCapture
            }
        })
    }

    const capturedSupplies = useSelector(state => state.bulkImport.savedSupplies)
    const { openDialog: openSuccessDialog, Dialog: SuccessDialog } = useDialog('success')
    useEffect(() => {
        if (capturedSupplies.length > 0) openSuccessDialog('Successfully saved supplies')
    })

    const error = useSelector(state => state.bulkImport.error)
    const { openDialog: openErrorDialog, Dialog: ErrorDialog } = useDialog()
    useEffect(() => {
        if (error) openErrorDialog(error.message)
    }, [error, openErrorDialog])

    const classes = useStyles()
    const isLoading = useSelector(state => state.bulkImport.isLoading)
    const currentSelectedBrand = useSelector(state => state.marketing.import.brand)

    return (
        <div className={classes.root}>
            <div className={classes.vendorInformation}>
                <h2>Brand: </h2><BrandInput disabled={false} style={null} loading={false} value={currentSelectedBrand} onChange={brand => dispatch({ type: SELECT_MARKETING_IMPORT_BRAND, payload: brand })} />
                <VendorInformation
                    {...vendorInformation}
                    onChange={handleVendorInformationChange}
                />
            </div>
            <div className={classes.productTable}>
                <ProductTable
                    uploadCols={cols}
                    supplies={supplies}
                    setSupplies={setSupplies}
                    focusedIndex={focusedIndex}
                    setFocusedIndex={setFocusedIndex}
                />
            </div>
            <div className={classes.footer}>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>{isLoading ? 'Loading...' : 'Submit'}</Button>
            </div>
            <ErrorDialog />
            <SuccessDialog />
        </div>
    )
}

function convertFailedSuppliesToProductTableFormat(failedSupplies) {
    const supplies = failedSupplies.map(supply => ({
        0: supply.productReference ? supply.productReference : '',
        1: supply.price ? supply.price : 0,
        2: supply.quantity ? supply.quantity : 0,
        3: supply.msrp ? supply.msrp : 0,
        4: supply.discount ? supply.discount : 0
    }))
    return supplies
}

function convertProductTableFormatToSupplies(productTableItems) {
    return productTableItems.map(item => ({
        reference: item[0],
        price: item[1],
        quantity: item[2],
        msrp: item[3],
        discount: item[4]
    }))
}
