import {
  SELECT_PRICE_BRAND,
  SELECT_PRICE_CURRENCY,
  SELECT_PRICE_PRODUCT,
  SELECT_PRICE_PRICE,
  SELECT_PRICE_SECTOR,
  LOAD_SUPPLIES,
  LOAD_SUPPLIES_SUCCESS,
  LOAD_SUPPLIES_FAIL,
  IMPORT_SUMMARY,
  IMPORT_SUMMARY_SUCCESS,
  IMPORT_SUMMARY_FAIL,
  LOAD_SUPPLIES_PROVISIONS,
  LOAD_SUPPLIES_PROVISIONS_SUCCESS,
  LOAD_SUPPLIES_PROVISIONS_FAIL,
  SAVE_FAILED_SUPPLIES,
  UPDATE_SUPPLIES_SEARCH,
  RESET_SUPPLIES_PROVISONS_FILTER,
  SEARCH_SUPPLIES_APPLIED,
  SELECT_PRICE_QUANTITY,
  SELECT_PRICE_SUPPLIER,
  SAVE_PRICE_SUPPLY,
  SAVE_PRICE_SUPPLY_SUCCESS,
  SAVE_PRICE_SUPPLY_FAIL,
  SAVE_NEW_PRODUCT,
  SAVE_NEW_PRODUCT_SUCCESS,
  SAVE_NEW_PRODUCT_FAIL,
  ASK_INIT_MARKETING_UPCOMING,
  CANCEL_INIT_MARKETING_UPCOMING,
  CONFIRM_INIT_MARKETING_UPCOMING,
  RESET_INIT_MARKETING_UPCOMING,
  INIT_UPCOMING_LOAD_COMPLETE,
  INIT_UPCOMING_LOAD_RESET,
  SELECT_IMPORT_ITEM,
  SAVE_OVERVIEW_FILTER,
  LOAD_OVERVIEW_SUPPLIES,
  LOAD_OVERVIEW_SUPPLIES_SUCCESS,
  LOAD_OVERVIEW_SUPPLIES_FAIL,
  SELECT_MARKETING_IMPORT_BRAND,
  UPDATE_MARKETING_SECTION,
  OPEN_SUPPLY_DELETE_CONFIRM,
  OPEN_SUPPLY_RENEW_CONFIRM,
  REMOVE_SELECTED_SUPPLY_ROWS,
  UPDATE_SUPPLY_VALID_TO,
  EDIT_SUPPLY_RECORD,
  SELECT_TAX_RATE,
  OPEN_SUPPLY_NOTIFICATION,
  UPDATE_SUPPLIES_SELECTED,
  SUPPLY_UPDATED,
  SELECT_SUPPLY_REFRESH,
  SELECT_SUPPLY_REMOVE,
  UPDATE_IMPORT_SUPPLIER,
  UPDATE_IMPORT_COUNTRY,
  UPDATE_IMPORT_CURRENCY,
  UPDATE_IMPORT_TAX_MODE,
  UPDATE_IMPORT_PERIOD,
  UPDATE_IMPORT_TAX_RATE,
  BULK_IMPORT_ALERT_CLOSE,
  BULK_IMPORT_ALERT_OPEN,
  BULK_IMPORT_CLEAR_ERRORS,
  BULK_IMPORT_FILE_BEGIN,
  BULK_IMPORT_TEXT_BEGIN,
  BULK_IMPORT_DATA_FAIL,
  BULK_IMPORT_CREATE_MAP_SUCCESS,
  BULK_IMPORT_CREATE_MAP_FAIL,
  BULK_IMPORT_INPUT_CHANGE,
  BULK_IMPORT_DIALOG_OPEN,
  BULK_IMPORT_DIALOG_CLOSE,
  BULK_IMPORT_PROGRESS_UPDATE,
  BULK_IMPORT_SEND_API_BEGIN,
  BULK_IMPORT_SEND_API_SUCCESS,
  BULK_IMPORT_SEND_API_FAIL,
  TRADER_CREATE_NEW_SUCCESS
} from "../actions";
import { RESET_TARGET, SELECT_PRICE_AMOUNT, SELECT_SUPPLIES, SELECT_SUPPLY_CLEAR } from "../actions";

export const initialState = {
  loading: false,
  processing: false,
  error: false,
  searchText: null,
  sectors: [
    { value: 20201, label: "3P Coming" },
    { value: 1001, label: "3P Trust" },
    { value: 20401, label: "3P Temp" },
    { value: 501, label: "3P Market" },
  ],
  suppliesFilter: {},
  index: [],
  supplies: {},
  selected: [],
  canonical: {},
  target: {
    id: null,
    channelId: 20401,
    supplier: 30000,
    brandId: -1,
    productId: -1,
    currencyId: 1,
    quantity: 1,
    amount: null,
    price: null,
  },
  notice: null,
  noticeType: null,
  initUpcomingDialog: false,
  upcomingVendorInput: null,
  upcomingStocksInput: [],
  loadStorage: false,
  loadStorageComplete: false,
  overviewSupplies: [],
  overviewLoading: false,
  openDeleteConfirm: false,
  openRenewConfirm: false,
  isEdit: false,
  selectedForEdit: null,
  supplySuccessNotice: {
    isOpen: false,
    message: '',
    variant: 'success',
  },
  suppliesProvisions: [],
  import: {
    alerts: false,
    traderId: null,
    reference: null,
    brand: {},
    importDate: null,
    vendor: {},
    country: {},
    currency: {},
    taxRate: 0,
    withTax: false,
    permanent: false,
    supplies: [],
    processing: false,
    sending: false,
    captured: 0,
    dialog: false,
    imported: [],
    columns: { all: {}, matched: {} },
    errors: [],
  },
  failedSupplies: [],
  section: 20401,
};
export const marketingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_IMPORT_ITEM:
      return {
        ...state,
        import: { ...state.import, ...action.payload }
      }
    case SELECT_MARKETING_IMPORT_BRAND: {
      return {
        ...state,
        import: {
          ...state.import,
          brand: action.payload
        }
      }
    }
    case LOAD_SUPPLIES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUPPLIES_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case REMOVE_SELECTED_SUPPLY_ROWS:
      return {
        ...state,
        loading: false,
        supplies: state.supplies.filter(x => x.isSelected === false)
      };
    case LOAD_SUPPLIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case IMPORT_SUMMARY:
      return {
        ...state,
        loadingImports: true,
      };
    case IMPORT_SUMMARY_SUCCESS:
      return {
        ...state,
        loadingImports: false,
        suppliesOverview: action.payload,
      };
    case IMPORT_SUMMARY_FAIL:
      return {
        ...state,
        loadingImports: false,
      }
    case LOAD_SUPPLIES_PROVISIONS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUPPLIES_PROVISIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        suppliesProvisions: action.payload.suppliesProvisions,
      };
    case SAVE_FAILED_SUPPLIES:
      return {
        ...state,
        loading: false,
        failedSupplies: action.payload
      };
    case LOAD_SUPPLIES_PROVISIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SAVE_PRICE_SUPPLY:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case SAVE_PRICE_SUPPLY_SUCCESS:
      return {
        ...state,
        processing: false,
        // target: {
        //   ...state.target,
        //   price: null,
        // },
        ...action.payload
      };
    case SAVE_PRICE_SUPPLY_FAIL:
      return {
        ...state,
        processing: false,
        error: true,
      };
    case UPDATE_SUPPLIES_SEARCH:
      return {
        ...state,
        searchText: action.payload,
      };
    case RESET_SUPPLIES_PROVISONS_FILTER:
      return {
        ...state,
        suppliesProvisions: [],
        failedSupplies: [],
        import: {
          ...state.import,
          traderId: null,
          reference: null
        },
      };
    case SEARCH_SUPPLIES_APPLIED:
      return {
        ...state,
        index: action.payload,
      };
    case SELECT_PRICE_SECTOR:
      return {
        ...state,
        target: {
          ...state.target,
          channelId: action.payload,
        },
      };
    case SELECT_PRICE_SUPPLIER:
      return {
        ...state,
        target: {
          ...state.target,
          supplier: action.payload,
        },
      };
    case SELECT_PRICE_BRAND:
      return {
        ...state,
        isEdit: false,
        target: {
          ...state.target,
          brandId: action.payload,
        },
      };
    case SELECT_PRICE_PRODUCT:
      return {
        ...state,
        isEdit: false,
        target: {
          ...state.target,
          productId: action.payload,
        },
      };
    case SELECT_PRICE_CURRENCY:
      return {
        ...state,
        target: {
          ...state.target,
          currencyId: action.payload,
        },
      };
    case SELECT_PRICE_PRICE:
      return {
        ...state,
        target: {
          ...state.target,
          price: action.payload,
        },
      };
    case SELECT_PRICE_AMOUNT:
      return {
        ...state,
        target: {
          ...state.target,
          ...action.payload
        }
      }
    case SELECT_TAX_RATE:
      return {
        ...state,
        target: {
          ...state.target,
          taxRate: action.payload,
        },
      };
    case SELECT_PRICE_QUANTITY:
      return {
        ...state,
        target: {
          ...state.target,
          quantity: action.payload,
        },
      };
    case SAVE_NEW_PRODUCT:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case SAVE_NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        notice: "Successful to create product",
        noticeType: "success",
        processing: false,
        ableLoadCatalog: true,
      };
    case SAVE_NEW_PRODUCT_FAIL:
      return {
        ...state,
        notice: "Fail to create product",
        noticeType: "error",
        processing: false,
        ableLoadCatalog: false,
      };
    case ASK_INIT_MARKETING_UPCOMING:
      return { ...state, initUpcomingDialog: true }
    case CANCEL_INIT_MARKETING_UPCOMING:
      localStorage.removeItem('vendorInfoUpcomingInvoice');
      localStorage.removeItem('purchaseInfoUpcoming');
      return {
        ...state,
        initUpcomingDialog: false,
        upcomingVendorInput: null,
        upcomingStocksInput: [],
      }
    case CONFIRM_INIT_MARKETING_UPCOMING:
      return {
        ...state,
        initUpcomingDialog: false,
        loadStorage: true,
        upcomingVendorInput: JSON.parse(localStorage.getItem('vendorInfoUpcomingInvoice')),
        upcomingStocksInput: JSON.parse(localStorage.getItem('purchaseInfoUpcoming'))
      }
    case INIT_UPCOMING_LOAD_COMPLETE:
      return {
        ...state,
        loadStorageComplete: true,
      }
    case INIT_UPCOMING_LOAD_RESET:
      return {
        ...state,
        loadStorage: false,
        loadStorageComplete: false,
      }
    case RESET_INIT_MARKETING_UPCOMING:
      localStorage.removeItem('vendorInfoUpcomingInvoice');
      localStorage.removeItem('purchaseInfoUpcoming');
      return {
        ...state,
        upcomingVendorInput: null,
        upcomingStocksInput: [],
      }
    case SAVE_OVERVIEW_FILTER:
      return {
        ...state,
        suppliesFilter: action.payload,
      };
    case LOAD_OVERVIEW_SUPPLIES:
      return {
        ...state,
        overviewLoading: true,
      };
    case LOAD_OVERVIEW_SUPPLIES_SUCCESS:
      return {
        ...state,
        overviewLoading: false,
        overviewSupplies: action.payload,
      };
    case LOAD_OVERVIEW_SUPPLIES_FAIL:
      return {
        ...state,
        overviewLoading: false,
      };
    case UPDATE_MARKETING_SECTION:
      return {
        ...state,
        section: action.payload
      };
    case OPEN_SUPPLY_DELETE_CONFIRM:
      return {
        ...state,
        openDeleteConfirm: action.payload,
      };
    case OPEN_SUPPLY_RENEW_CONFIRM:
      return {
        ...state,
        openRenewConfirm: action.payload,
      };
    case UPDATE_SUPPLY_VALID_TO:
      return {
        ...state,
        supplies: action.payload,
      };
    case EDIT_SUPPLY_RECORD:
      return {
        ...state,
        isEdit: true,
        selectedForEdit: action.payload,
      }
    case OPEN_SUPPLY_NOTIFICATION:
      return {
        ...state,
        supplySuccessNotice: action.payload,
      }
    case RESET_TARGET:
      return {
        ...state,
        target: {
          channelId: 20401,
          supplier: 30000,
          brandId: -1,
          productId: -1,
          currencyId: 1,
          quantity: 1,
          price: null,
        }
      }
    case UPDATE_SUPPLIES_SELECTED:
      return {
        ...state,
        ...action.payload
      }
    case SELECT_SUPPLY_CLEAR:
      return {
        ...state,
        selected: []
      }
    case SELECT_SUPPLY_REMOVE:
    case SELECT_SUPPLY_REFRESH:
      return {
        ...state,
        loading: true
      }
    case SUPPLY_UPDATED:
      return {
        ...state,
        selected: [],
        loading: false,
        ...action.payload
      }
    case SELECT_SUPPLIES:
      return {
        ...state,
        target: {
          id: action.payload.id,
          channelId: action.payload.channelId,
          supplier: action.payload.traderId,
          brandId: action.payload.brandId,
          productId: action.payload.productId,
          currencyId: action.payload.currencyId,
          quantity: 1,
          price: action.payload.price,
          amount: action.payload.amount
        }
      }
    case UPDATE_IMPORT_COUNTRY:
      return {
        ...state,
        import: {
          ...state.import,
          country: action.payload
        }
      }
    case UPDATE_IMPORT_SUPPLIER:
      return {
        ...state,
        import: {
          ...state.import,
          vendor: action.payload
        }
      }
    case UPDATE_IMPORT_CURRENCY:
      return {
        ...state,
        import: {
          ...state.import,
          currency: action.payload
        }
      }
    case UPDATE_IMPORT_TAX_RATE:
      return {
        ...state,
        import: {
          ...state.import,
          taxRate: action.payload
        }
      }

    case UPDATE_IMPORT_TAX_MODE:
      return {
        ...state,
        import: {
          ...state.import,
          withTax: action.payload
        }
      }
    case UPDATE_IMPORT_PERIOD:
      return {
        ...state,
        import: {
          ...state.import,
          permanent: action.payload,
        }
      }
    case BULK_IMPORT_FILE_BEGIN:
      return {
        ...state,
        import: {
          ...state.import,
          processing: true
        }
      }
    case BULK_IMPORT_TEXT_BEGIN:
      return {
        ...state,
        import: {
          ...state.import,
          processing: true
        }
      }
    // case BULK_IMPORT_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     import: {
    //       ...state.import,
    //       processing: false,
    //       data: action.payload
    //     }
    //   }
    case BULK_IMPORT_DATA_FAIL:
      return {
        ...state,
        import: {
          ...state.import,
          processing: false,
          errors: action.payload
        }
      }
    case BULK_IMPORT_CREATE_MAP_SUCCESS:
      return {
        ...state,
        import: {
          ...state.import,
          columns: action.payload.mappedColumns,
          supplies: action.payload.supplies,
          processing: false,
          dialog: false,
        }
      }
    case BULK_IMPORT_CREATE_MAP_FAIL:
      return {
        ...state,
        import: {
          ...state.import,
          errors: action.payload,
          processing: false,
          dialog: false,
          alerts: true,
        }
      }
    case BULK_IMPORT_CLEAR_ERRORS:
      return {
        ...state,
        import: {
          ...state.import,
          errors: [],
          alerts: false,
        }
      }
    case BULK_IMPORT_ALERT_CLOSE:
      return {
        ...state,
        import: {
          ...state.import,
          alerts: false,
        }
      }
    case BULK_IMPORT_ALERT_OPEN:
      return {
        ...state,
        import: {
          ...state.import,
          alerts: true,
        }
      }
    case BULK_IMPORT_INPUT_CHANGE:
      return {
        ...state,
        import: {
          ...state.import,
          supplies: state.import.supplies.map((item, i) => i === action.payload.id ? { ...item, [action.payload.column]: action.payload.value } : item
          )
        }
      }
    case BULK_IMPORT_DIALOG_OPEN:
      return {
        ...state,
        import: {
          ...state.import,
          dialog: true,
          imported: [],
        }
      }
    case BULK_IMPORT_DIALOG_CLOSE:
      return {
        ...state,
        import: {
          ...state.import,
          dialog: false
        }
      }
    case BULK_IMPORT_SEND_API_BEGIN:
      return {
        ...state,
        import: {
          ...state.import,
          sending: true,
        }
      }
    case BULK_IMPORT_PROGRESS_UPDATE:
      return {
        ...state,
        import: {
          ...state.import,
          captured: state.import.captured + action.payload,
        }
      }
    case BULK_IMPORT_SEND_API_SUCCESS:
      return {
        ...state,

        import: {
          ...state.import,
          supplies: [],
          imported: action.payload,
          columns: { all: {}, matched: {} },
          sending: false,
          alerts: true,
          captured: 0,
        }

      }
    case BULK_IMPORT_SEND_API_FAIL:
      return {
        ...state,
        import: {
          ...state.import,
          sending: false,
          errors: action.payload,
          alerts: true,
        }
      }
    case TRADER_CREATE_NEW_SUCCESS:
      return {
        ...state,
        target: {
          ...state.target,
          supplier: action.payload.trader.id
        }
      }
    default:
      return state;
  }
};
