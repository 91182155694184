import matchReference from "./matchReference"


//how to fill in the missing items


//detect mapping for each columns
//lets fill up the data
const processInputData = (data = [], getState) => {
    //index of columns/
    //product 0

    //initialize mapping and find what name of the header should be mapped to each important field
    // data is { brand: "brand" , ref: "ref" }

    //check later if can find these two
    const columnsToMatch = {
        reference: null,
        price: null,
        quantity: null, //optional,
        discount: null, //optional
        msrp: null,
    }

    // if (data.length > 0) {
    //let isHeadersIncluded = true

    //headers = data[0].map(header => header.toString().toUpperCase().trim())
    const headers = Object.keys(data[0]);

    // if (headers.every(header => Number.isNaN(parseInt(header)))) {
    //isHeadersIncluded = true

    const dynamicCols = new Map();

    //add column for Item number and Product Details
    dynamicCols.set("Number[DEFAULT]", {
        display: true,
        name: "Item",
        label: "Item",
        width: '10px'
    });


    //PRODUCT DETAILS
    //cannot be same name as any header
    dynamicCols.set("PD[DEFAULT]", {
        display: true,
        name: "Product",
        label: "Product",
        width: 'auto'
    });

    headers.forEach((header, index) => {
        dynamicCols.set(header.toString(), {
            display: true,
            name: header,
            label: header,
            width: 'auto'
        });
    });

    dynamicCols.forEach((v, key, map) => {

        if (key.match(/reference|model|product|longdesc/i)) {

            map.set(key, {
                ...v, mapping: "reference"
            });
            columnsToMatch.reference = key
        }
        if (key.match(/net|price/i)) {
            map.set(key, {
                ...v, mapping: "price"
            });
            columnsToMatch.price = key
        }
        if (key.match(/hkr|retail/i)) {
            map.set(key, {
                ...v, mapping: "msrp"
            });
            columnsToMatch.msrp = key
        }
        if (key.match(/dto|discount|less/i)) {
            map.set(key, {
                ...v, mapping: "discount"
            });
            columnsToMatch.discount = key
        }
        if (key.match(/qty|quantity/i)) {
            map.set(key, {
                ...v, mapping: "quantity"
            });
            columnsToMatch.quantity = key
        }

    });

    //EXTRA SETTING IF NO PRICE BUT OTHER TWO HAVE
    if (!columnsToMatch.price && columnsToMatch.discount && columnsToMatch.msrp) {
        columnsToMatch.price = 'Price(AUTO)';
        dynamicCols.set(columnsToMatch.price, {
            display: true,
            name: 'Price(AUTO)',
            label: 'Price(AUTO)',
            width: 'auto'
        });
    }

    //#region  Infer columns using the rows if cannot find using headers
    // find reference by checking all rows if can find a product

    // if (allIndexes['referenceIndex'] === -1) {
    //     const reference = {};
    //     headers.forEach((header, index) => {

    //         if (!Object.values(allIndexes).includes(index)) {
    //             reference[index] = data.map((row) => row[index])
    //                 .filter(val => findModel(val))
    //                 .reduce((count, val) => count = count + 1, 0);
    //         };
    //     })
    //     const nonZero = {}
    //     for (const prop in reference) {
    //         if (reference[prop] !== 0) nonZero[prop] = reference[prop];
    //     }

    //     if (Object.values(nonZero).length === 1) allIndexes['referenceIndex'] = parseInt(Object.keys(nonZero)[0]);
    //     else if (Object.values(nonZero).length >= 2) {
    //         const idx = Object.keys(reference).reduce((previous, current) => reference[previous] > reference[current] ? previous : current);
    //         allIndexes['referenceIndex'] = parseInt(idx);
    //     }
    // }

    // find net price by finding the column that has more 2 zeros at the end
    // if (allIndexes['netPriceIndex'] === -1) {
    //     const net = {};
    //     headers.forEach((header, index) => {

    //         if (!Object.values(allIndexes).includes(index)) {
    //             net[index] = data.map((row) => row[index])
    //                 .filter(x => !!x)
    //                 .map(val => parseFloat(val.toString().replace(/[^\d.-]/g, '')))
    //                 .filter(val => Number(val) === val)
    //                 .map(val => val < 1 ? val * 100 : val)
    //                 .filter(val => /00$/.test(val))
    //                 .reduce((total, val) => total = total + 1, 0);
    //         };
    //     })
    //     const nonZero = {}
    //     for (const prop in net) {
    //         if (net[prop] !== 0) nonZero[prop] = net[prop];
    //     }

    //     if (Object.values(nonZero).length === 1) allIndexes['netPriceIndex'] = parseInt(Object.keys(nonZero)[0]);
    //     else if (Object.values(nonZero).length >= 2) {
    //         const idx = Object.keys(net).reduce((previous, current) => net[previous] > net[current] ? previous : current);
    //         allIndexes['netPriceIndex'] = parseInt(idx);
    //     }
    // }

    // if (allIndexes['quantityIndex'] === -1) {
    //     const quantity = {};
    //     headers.forEach((header, index) => {

    //         if (!Object.values(allIndexes).includes(index)) {
    //             quantity[index] = data.map((row) => row[index])
    //                 .filter(x => !!x)
    //                 .map(val => parseFloat(val.toString().replace(/[^\d.-]/g, '')))
    //                 .filter(val => Number(val) === val)
    //                 .filter(val => val >= 1 && val < 100) //assumption quantity less than 100
    //                 .reduce((total, val) => total = total + 1, 0);
    //         };
    //     })
    //     const nonZero = {}
    //     for (const prop in quantity) {
    //         if (quantity[prop] !== 0) nonZero[prop] = quantity[prop];
    //     }

    //     if (Object.values(nonZero).length === 1) allIndexes['quantityIndex'] = parseInt(Object.keys(nonZero)[0]);
    //     else if (Object.values(nonZero).length >= 2) {
    //         const idx = Object.keys(quantity).reduce((previous, current) => quantity[previous] > quantity[current] ? previous : current);
    //         allIndexes['quantityIndex'] = parseInt(idx);
    //     }
    // }
    //#endregion

    let errors = []
    //currently only these 2 are important

    //dont throw error here
    //just parse the data first
    // if (!columnsToMatch.reference) {
    //     //TODO throw error to user
    //     ///reference|model|product|longdesc/
    //     ///net|price/
    //     errors.push("Cannot find reference column based on your data. Please change the Header title to REFERENCE");
    // }

    // if (!columnsToMatch.price) {
    //     errors.push("Cannot find price column based on your data. Please change the Header title to PRICE");
    // }



    return {
        errors, mappedColumns: { matched: columnsToMatch, all: Object.fromEntries(dynamicCols.entries()) }, supplies: data
            //.filter(row => row.some(col => /[0-9]/.test(col))) // a header row contains at least 1 numerical row
            // .map(row => row.map((column, index) => {
            //     // remove currency symbols in retail price and net price
            //     if ((index === allIndexes['retailPriceIndex'] || index === allIndexes['netPriceIndex']) && typeof column !== 'number') {
            //         if (column) {
            //             const numericalValue = parseFloat(column.replace(/[^0-9.]/g, ''))
            //             if (Number.isNaN(numericalValue)) return column
            //             return numericalValue
            //         }
            //         else return column;
            //     }
            //     if (index === allIndexes['retailPriceIndex'] && column) return column.toString()
            //     return column
            // }))
            .map(row => {

                //if no data for that column then add in default value


                //if coming from excel it will convert it to float
                //if coming from copy/paste it will just be a string



                let productId = null;
                if (row.hasOwnProperty(columnsToMatch.reference)) {
                    productId = findProductIdByReference(row[columnsToMatch.reference], getState);
                }

                if (row[columnsToMatch.price]) {
                    row[columnsToMatch.price] = parseFloat(row[columnsToMatch.price].toString().replace(/[^0-9.]/g, '')) || 0;
                }

                if (row[columnsToMatch.msrp]) {
                    row[columnsToMatch.msrp] = parseFloat(row[columnsToMatch.msrp].toString().replace(/[^0-9.]/g, '')) || 0;
                }

                if (row[columnsToMatch.discount]) {
                    row[columnsToMatch.discount] = parseFloat(row[columnsToMatch.discount].toString().replace(/[^0-9.]/g, '')) || 0;
                    if (row[columnsToMatch.discount] < 1) row[columnsToMatch.discount] = row[columnsToMatch.discount] * 100;
                }

                if (columnsToMatch.price === 'Price(AUTO)') {
                    row[columnsToMatch.price] = row[columnsToMatch.msrp] * (1 - row[columnsToMatch.discount] / 100);
                }


                // let price = null;
                // if (!row.hasOwnProperty(mappedColumns.price)) {
                //     price = { ...row, [mappedColumns.price]: 0 }
                // }

                // //default quantity is 1
                // let quantity = null;
                // if (!row.hasOwnProperty(mappedColumns.quantity)) {
                //     quantity = { ...row, [mappedColumns.quantity]: 1 }
                // }

                // let msrp = null;
                // if (!row.hasOwnProperty(mappedColumns.msrp)) {
                //     msrp = { ...row, [mappedColumns.msrp]: 0 }
                // }

                // let discount = null;
                // if (!row.hasOwnProperty(mappedColumns.discount)) {
                //     discount = { ...row, [mappedColumns.discount]: 0 }
                // }



                //let supply = {}
                //let unknowns = {}
                //row is object { Brand: "brand" }
                // row.forEach((column, index) => {
                //     if (!Object.values(allIndexes).includes(index)) {
                //         unknowns[`unknown${index}`] = column
                //     }
                // })

                // supply.reference = getDefaultValue(row[allIndexes['referenceIndex']])
                // const discountNumberAndDot = parseFloat(row[allIndexes['discountIndex']] ? row[allIndexes['discountIndex']].toString().replace(/[^0-9.]/g, "") : 0);
                // supply.discount = getDefaultValue(discountNumberAndDot < 1 && discountNumberAndDot > 0 ? (discountNumberAndDot * 100).toFixed() : discountNumberAndDot, null)
                // supply.msrp = getDefaultValue(row[allIndexes['retailPriceIndex']], 0) //Math.floor(row[allIndexes['netPriceIndex']] / (1 - supply.discount / 100) * 100) / 100
                // supply.quantity = getDefaultValue(row[allIndexes['quantityIndex']], 1)
                // const { priceWithDiscount } = Abacus.calcPriceWithRatio(supply.msrp, 1, supply.discount, 0, 2);
                // supply.unitPrice = getDefaultValue(row[allIndexes['netPriceIndex']], supply.msrp > 0 && supply.discount > 0
                //     ? priceWithDiscount
                //     : 0)
                //const parseNonParse = { ...supply, ...unknowns };
                //return parseNonParse
                return { ...row, productId };
            })
    }
};

function generateBlankStock(initialData) {
    return {
        //model: "",
        reference: '',
        unitPrice: 0,
        quantity: 1,
        msrp: 0,
        discount: 0,
        ...initialData,
    }
}

function getDefaultValue(val, defaultVal = '') {
    return (val === null || val === undefined || val === '' || Number.isNaN(val)) ? defaultVal : val
}


const findProductIdByReference = (reference, getState) => {
    const { match } = matchReference();

    //const availableBrands = getState().catalog.brands;
    const productListMap = getState().catalog.products;

    const productArr = Object.values(productListMap);
    // const productWithBrand = Object.values(availableProducts).map((product) => ({
    //     brand: availableBrands[product.brandID],
    //     product,
    // }));

    let skuFilter = productArr.find((model) => match(model.SKU, reference));
    let refFilter = productArr.find((model) => match(model.reference, reference));

    let productDetails = null;
    if (skuFilter) {
        productDetails = skuFilter
    } else {
        if (refFilter) {
            productDetails = refFilter
        }
    }

    if (productDetails) return parseInt(productDetails.id);

    return null;
}

export default processInputData