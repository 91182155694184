import React, { Component } from "react";
import { Button, MobileStepper } from "@material-ui/core";
import { ArrowLeftBoldCircleOutline, ArrowRightBoldCircleOutline } from 'mdi-material-ui';
import styles from './slider.module.scss';

export class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      translateValue: 0,
    }
  }

  goPrevSlide = () => {
    if (this.state.currentIndex === 0) {
      return this.setState({
        currentIndex: this.props.assets.length - 1,
        translateValue: -(this.props.assets.length - 1) * this.slideWidth()
      })
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      translateValue: prevState.translateValue + this.slideWidth()
    }));
  };

  goNextSlide = () => {
    if (this.state.currentIndex === this.props.assets.length - 1) {
      return this.setState({
        currentIndex: 0,
        translateValue: 0
      })
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -(this.slideWidth())
    }));
  };

  slideWidth = () => {
    return 364
  };

  render() {
    let { assets } = this.props;
    return (
      <div className={styles.slider}>
        <div className={styles.wrapper} style={{
          transform: `translateX(${this.state.translateValue}px`,
          transition: 'transform ease-out 0.45s',
          width: (this.props.assets ? this.props.assets.length : 0) * this.slideWidth()
        }}>
          {
            this.props.assets &&
            this.props.assets.map((dataUri, i) => (
              <div key={`dataUri-${i}`} className={styles.slide}>
                <img src={dataUri} className={styles.picture} alt={`asset-${i}`}/>
              </div>
            ))
          }
        </div>
        <MobileStepper
          variant="dots"
          steps={assets.length}
          activeStep={this.state.currentIndex}
          position="static"
          nextButton={
            <Button size="small" color="primary" onClick={this.goNextSlide}>
              Next <ArrowRightBoldCircleOutline />
            </Button>
          }
          backButton={
            <Button size="small" color="primary" onClick={this.goPrevSlide}>
              <ArrowLeftBoldCircleOutline /> Back
            </Button>
          }
        />
      </div>
    );
  }
}


export default Slider;