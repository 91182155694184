import React from 'react';
import styles from './webcam.module.scss';
import Webcam from 'react-webcam';
import { Button } from '@material-ui/core';

class WebcamCapture extends React.Component {
  state = {
    imageSrc: null,
    videoConstraints: {
      width: { min: 1280, ideal: 3840, max: 3840 },
      height: { min: 720, ideal: 2160, max: 2160 },
    }
  }

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ imageSrc: imageSrc });
    this.props.updateImage(imageSrc);
  };

  retake = () => {
    this.setState({ imageSrc: null });
    this.props.updateImage(null);
  };

  render() {
    const { show } = this.props;
    const { imageSrc } = this.state;

    return (
      <div className={styles['webcam']}>
        {
          show &&
            imageSrc ?
            (<div className={styles['flex-column']}>
              <img className={styles['preview']} src={this.state.imageSrc} height={720} width={1280} alt={'preview'}/>
              <div className={styles['button']}>
                <Button onClick={this.retake} color='secondary' variant='outlined' size='small'>
                  Retake
                </Button>
              </div>
            </div>)
            :
            (<div className={styles['flex-column']}>
              <Webcam
                audio={false}
                ref={this.setRef}
                height={500}
                width={1000}
                screenshotFormat="image/jpeg"
                videoConstraints={this.state.videoConstraints}
                minScreenshotWidth={3840}
              />
              <div className={styles['button']}>
                <Button onClick={this.capture} color='primary' variant='contained' size='small'>
                  Capture
                </Button>
              </div>
            </div>)
        }
      </div>
    );
  }
}

export const WebcamComponent = WebcamCapture