export const DEL_LOAD_BEGIN= "@@del/DEL_LOAD_BEGIN";
export const DEL_LOAD_SUCCESS = '@@del/LOAD_SUCCESS';
export const DEL_LOAD_ERROR = '@@del/DEL_LOAD_ERROR';
export const CLOSE_DELIVERY_NOTICE = '@@del/CLOSE_DEL_NOTICE';

export const UPDATE_DEL_FIELD = '@@del/UPDATE_DEL_FIELD';
export const UPDATE_DEL_FIELD_SUCCESS = '@@del/UPDATE_DEL_FIELD_SUCCESS';
export const UPDATE_DEL_FIELD_ERROR = '@@del/UPDATE_DEL_FIELD_ERROR';

export const UPDATE_TEXT_FIELD = '@@del/UPDATE_TEXT_FIELD';
export const UPDATE_DATE_RANGE = '@@del/UPDATE_DATE_RANGE';

export const OPEN_BOX_OPEN_DIALOG = '@@del/OPEN_BOX_OPEN_DIALOG';
export const CANCEL_BOX_OPEN = '@@del/CANCEL_BOX_OPEN';
export const CONFIRM_BOX_OPEN = '@@del/CONFIRM_BOX_OPEN';

export const DISPLAY_DEL_RECEIPT = '@@del/DISPLAY_DEL_RECEIPT';
export const DISPLAY_DEL_RECEIPT_ERROR = '@@del/DISPLAY_DEL_RECEIPT_ERROR';
export const DISPLAY_DEL_RECEIPT_SUCCESS = '@@del/DISPLAY_DEL_RECEIPT_SUCCESS';








