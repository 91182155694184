/**
 * @param {string} reference
 */

const matchReference = () => {

    const match = (former, latter) => {
        if (!former || !latter) return false;
        //return former.toUpperCase().replace(/[ .-/]/g, '').includes(latter.toUpperCase().replace(/[ .-/]/g, ''))
        let cleanedFormer = former.toString().toUpperCase().replace(/[^0-9a-z]/gi, ''); //remove underscore, space, -, _, . only
        let cleanedLatter = latter.toString().toUpperCase().replace(/[^0-9a-z]/gi, '');

        if (cleanedFormer === cleanedLatter) {
            return cleanedFormer === cleanedLatter
        }

        // if(cleanedFormer.charAt(0) === "M") {
        //     let newCleanedFormer = setCharAt(cleanedFormer,0,'');
        //     return newCleanedFormer === cleanedLatter
        // }

        // Blancpain condition N06651O036042N055B => 6651364255B
        if (RegExp('^N0(\\d{4})O0(\\d{2})0(\\d{2})N0(\\w{3})$').test(cleanedFormer)) {
            [0, 0, 4, 4, 6, 8, 8].forEach(element => {
                return cleanedFormer = setCharAt(cleanedFormer, element, '')
            });
            return cleanedFormer === cleanedLatter;
        }

        if (RegExp('^N0(\\d{4})O0(\\d{2})0(\\d{2})N0(\\w{3})$').test(cleanedLatter)) {
            [0, 0, 4, 4, 6, 8, 8].forEach(element => {
                return cleanedLatter = setCharAt(cleanedLatter, element, '')
            });
            return cleanedFormer === cleanedLatter;
        }

        return false;

    }

    return { match };
};

export default matchReference;

function setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substr(0, index) + chr + str.substr(index + 1);
}
