import { DataTable } from "@cosmos/chaos/cjs/DataTable";
import { Button, Paper } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AutoSizer from "react-virtualized-auto-sizer";
import classNames from "classnames";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { ConfirmDialog } from '../../../components/dialog'
import {
    BULK_IMPORT_ALERT_CLOSE,
    BULK_IMPORT_CLEAR_ERRORS,
    BULK_IMPORT_DIALOG_OPEN,
    BULK_IMPORT_DIALOG_CLOSE,
    BULK_IMPORT_TEXT_BEGIN,
    BULK_IMPORT_FILE_BEGIN,
    BULK_IMPORT_SEND_API_BEGIN,
    CLEAR_SAVED_SUPPLIES,
    SELECT_MARKETING_IMPORT_BRAND,
} from '../../../actions'
import useDialog from "../../../hooks/useDialog";
import GetInputDialog from "./GetInputDialog";
import VendorInformation from "./VendorInformation";
import Skeleton from "@material-ui/lab/Skeleton";


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        padding: "0.5em",
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        gridTemplateRows: "1fr auto",
        gridTemplateAreas: `
        'vendorInformation productTable createProduct'
        'footer footer footer'
        `,
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        gridColumn: "1/-1",
        gridGap: "0.5em",
    },
    vendorInformation: {
        gridArea: 'vendorInformation',
        padding: '2em'
    },
    rowMatched: {
        backgroundColor: '#c8fde1',
    },
    productSKU: {
        fontWeight: "500",
    },
    productCell: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
    },
    productBox: {
        flexDirection: "column",
        minWidth: 0
    },
    productName: {
        fontSize: "70%",
        maxWidth: "240px",
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    productBrand: {
        fontSize: '110%',
        fontWeight: 800,
        marginRight: '0.5em'
    },
    productTable: {
        gridArea: "productTable",
    },
    reconed:{},
    recon: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "0.25em",
        "& span": {
            fontWeight: 800
        }
    },
    createProduct: {
        gridArea: "createProduct",
        padding: "1em",
    },
    footer: {
        gridArea: "footer",
        textAlign: "right",
        padding: "1em",
        borderTop: "1px solid #ccc",
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function BulkAddSupplies() {

    const { openDialog: openSuccessDialog, Dialog: SuccessDialog } = useDialog("success", () => dispatch({ type: BULK_IMPORT_ALERT_CLOSE }));
    const { openDialog: openErrorDialog, Dialog: ErrorDialog } = useDialog("error", () => dispatch({ type: BULK_IMPORT_CLEAR_ERRORS }));
    const dispatch = useDispatch();
    const history = useHistory();
    const products = useSelector(state => state.catalog.products);
    const alerts = useSelector(state => state.marketing.import.alerts);
    const failedSupplies = useSelector(state => state.marketing.failedSupplies);
    const mappedColumns = useSelector(state => state.marketing.import.columns.all);
    const matchedColumns = useSelector(state => state.marketing.import.columns.matched);
    const dialog = useSelector(state => state.marketing.import.dialog);
    const errors = useSelector(state => state.marketing.import.errors);
    const imported = useSelector(state => state.marketing.import.imported);
    const processing = useSelector(state => state.marketing.import.processing);
    const captured = useSelector(state => state.marketing.import.captured);
    const sending = useSelector(state => state.marketing.import.sending);
    const supplies = useSelector(state => state.marketing.import.supplies);
    const brands = useSelector(state=>state.catalog.brands);
    const [columns, setColumns] = useState([]);
    const [progress, setProgress] = React.useState(0);
    const classes = useStyles();

    //#region Handler methods
    const handleConfirmDialog = (stringInput, file) => {

        if (stringInput) {
            dispatch({ type: BULK_IMPORT_TEXT_BEGIN, payload: stringInput });
        }
        if (file) {
            dispatch({ type: BULK_IMPORT_FILE_BEGIN, payload: file });
        }
    };

    function isFormValid(otherInformation, supplies) {
        // const notEmptySupplyList = supplies.length > 0;
        // const otherInformationFilledUp = Object.values(otherInformation).every(
        //     (info) => info !== undefined && info !== null && info !== ""
        // );

        // let validRow = true;
        // let rowStatus = supplies.map(supply => {
        //     if (isSupplyValid(supply) === 2) {
        //         return true
        //     }
        //     return false
        // })
        // if (rowStatus.includes(true)) {
        //     validRow = false;
        // }
        //return notEmptySupplyList && otherInformationFilledUp && validRow;
        // if (Object.keys(vendor).length === 0) return false;
        // if (Object.keys(country).length === 0) return false;
        // if (Object.keys(brand).length === 0) return false;
        // if (Object.keys(currency).length === 0) return false;

        return true;
    }
    //#endregion

    //#region Side-effects
    const defineColumns = useCallback((data, mappedCols) => {

        //columns define what column is mapped to reference and price
        //object not array { Brand: "rolex", DTO: "50"}
        //mapping table from redux;
        const columns = new Map();

        columns.set("product", {
            name: 'productId',
            title: "Product",
            columnClass: classes.productCell,
            width: 480,
            cell: ({ className, rowData }) => {
                const p = products[rowData.productId];
                if (!p) return <Skeleton variant="rect" />;
                return (
                    <div
                        className={classNames(className, classes.productBox)}
                    >

                        <div className={classes.productSKU}>
                            <span className={classes.productBrand}>{(brands && brands[p.brandID].name)}</span>
                            {p.SKU}
                        </div>
                        <div className={classes.productName}>
                            {p.name}
                        </div>
                    </div>
                );
            },
        });

        const dynamicCols = new Map(Object.entries(data));
        for (let [k] of dynamicCols) {

            if (k !== "productId") {
                columns.set(k, { //item[0] is header 
                    title: k,
                    name: k,
                    type: "string",
                    width: 360
                });
            }
        }

        let unionCols = [...columns.values()];
        return unionCols;
    }, [products]);

    //one time run only
    useEffect(() => {
        dispatch({ type: BULK_IMPORT_DIALOG_OPEN })
    }, []);

    useEffect(() => {
        if (captured > 0) {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const calc = parseInt(parseFloat(captured / supplies.length) * 100);
                return Math.min(calc, 100);

            });
        }
    }, [captured]);

    useEffect(() => {
        if (Object.keys(mappedColumns).length > 0 && supplies.length > 0) {
            setColumns(defineColumns(supplies[0], mappedColumns));
        }
    }, [supplies, mappedColumns, defineColumns]);

    useEffect(() => {
        if (alerts && errors.length > 0) {
            openErrorDialog(errors);
        }
    }, [alerts, errors, openErrorDialog])

    useEffect(() => {
        if (!sending && alerts && imported.length > 0) {
            openSuccessDialog("Success");
        }
    }, [alerts, sending, imported, openSuccessDialog]);

    const [recon, setRecon] = useState({});
    useEffect(()=>{
        if(!supplies) setRecon([]);
        if(!brands) setRecon([]);
        if(!products) setRecon([]);
        if(supplies.length > 0) {
            const re = {};
            for(const s of supplies) {
                if(!s.productId) continue;
                const p = products[s.productId];
                if(p) {
                    const b = brands[p.brandID];
                    if(b) {
                        if(!re[b.name]) re[b.name] = 0;
                        re[b.name] ++;
                    }
                }
            }
            setRecon(re);
        }
    }, [supplies, brands, products, setRecon])

    //#endregion

    return (
        <Paper className={classes.root}>
            <div className={classes.vendorInformation}>
                {/* <AutoComplete options={[{ id: 1, name: "14 days", validTo: moment.utc().add(2, "week").toISOString() }, { id: 2, name: "forever", validTo: null }]} handleChange={handleValidity} value={otherInformation["validity"]} label="Validity" /> */}
                <VendorInformation />
                <List>
                    <ListItem>
                        <ListItemText>
                            Number of Offer
                                </ListItemText>
                        <ListItemText>{supplies.length}</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText className={classes.reconed}>
                            Number of Recognized
                            {
                                Object.keys(recon).map(k=>(
                                    <div className={classes.recon}><span>{k}</span>{recon[k]}</div>
                                ))
                            }
                        </ListItemText>
                    </ListItem>
                </List>
            </div>
            <div className={classes.dataTable}>
                {supplies.length > 0 && columns.length > 0 && <AutoSizer>
                    {({ width, height }) => (
                        <DataTable
                            data={supplies.map(row => {
                                return {
                                    ...row,
                                    [matchedColumns.msrp]: matchedColumns.msrp && row[matchedColumns.msrp].toFixed(2),
                                    [matchedColumns.price]: matchedColumns.price && row[matchedColumns.price].toFixed(2),
                                }
                            })}
                            columns={columns}
                            height={height}
                            width={width}
                            isRowHighlight={({ rowData }) => {
                                return rowData.productId && classes.rowMatched
                            }}
                        />
                    )}
                </AutoSizer>}
            </div>
            <div className={classes.createProduct}>
            </div>
            <div className={classes.footer}>
                <Button
                    onClick={() => dispatch({ type: BULK_IMPORT_DIALOG_OPEN })} //trigger 
                    className={classes.button}
                >
                    Reupload file
                </Button>
                <Button
                    className={classes.button}
                    onClick={() => history.goBack()}
                    color="secondary"
                    variant="contained"
                >
                    Cancel
                </Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch({ type: BULK_IMPORT_SEND_API_BEGIN })}
                    disabled={
                        //!isFormValid(otherInformation, supplies) ||
                        sending
                    }
                >
                    Submit
                </Button>
            </div>
            {
                failedSupplies.length === 0 && (<GetInputDialog
                    processing={processing}
                    handleInputDialog={brand => dispatch({ type: SELECT_MARKETING_IMPORT_BRAND, payload: brand })}
                    handleConfirm={handleConfirmDialog}
                    isOpen={dialog}
                    handleClose={() => dispatch({ type: BULK_IMPORT_DIALOG_CLOSE })} //trigger
                />)
            }
            <SuccessDialog />
            <ErrorDialog />
            <ConfirmDialog isOpen={sending} handleClose={() => null} maxWidth={"sm"}
                renderActions={({ handleConfirm, handleClose }) => (
                    <></>
                )}
            >
                Processing {captured} of {supplies.length} records
                <LinearProgress variant="determinate" value={progress} />
            </ConfirmDialog>
        </Paper >
    );
}


function convertFailedSuppliesToProductTableFormat(failedSupplies) {
    return failedSupplies.map(supply => ({
        0: supply.productReference ? supply.productReference : '',
        1: supply.price ? supply.price : 0,
        2: supply.quantity ? supply.quantity : 0,
        3: supply.msrp ? supply.msrp : 0,
        4: supply.discount ? supply.discount : 0
    }))
}

// function convertProductTableFormatToSupplies(productTableItems) {
//     return productTableItems.map(item => ({
//         reference: item[0],
//         price: item[1],
//         quantity: item[2],
//         msrp: item[3],
//         discount: item[4]
//     }))
// }