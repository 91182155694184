import { Abacus } from "@cosmos/harmony";


export const calcPriceWithRatioWithTax = (taxedPrice, taxRate) => {
    if (taxRate >= 1) taxRate = taxRate / 100;

    const taxValue = Abacus.round(taxedPrice * (taxRate / (1 + taxRate)), 2);

    const preTaxPrice = taxedPrice - taxValue;

    return { taxValue, preTaxPrice, taxedPrice, taxRate };

}

export const calcPriceWithRatioWOTax = (preTaxPrice, taxRate) => {
    if (taxRate >= 1) taxRate = taxRate / 100;

    const taxValue = Abacus.round(preTaxPrice * taxRate, 2);
    //taxedPrice or amount
    const amount = preTaxPrice + taxValue;

    return { taxValue, preTaxPrice, amount, taxRate };

}

//this is for tax-inclusive computation
export const calcTaxInclusivePriceWithRatio = (taxedPrice, taxRate, discountRate, quantity, precision) => {
    //logger.trace('Calc tax inclusive price for input', { taxedPrice, quantity, discountRate, taxRate, precision });
    if (taxRate > 1) taxRate = taxRate / 100;
    if (discountRate > 1) discountRate = discountRate / 100;

    const price = taxedPrice * quantity;
    const discountValue = Abacus.round(price * discountRate, precision);
    const priceWithDiscount = Abacus.round(price - discountValue, precision)

    const taxValue = Abacus.round(priceWithDiscount * (taxRate / (1 + taxRate)), precision);
    const preTaxPrice = Abacus.round(priceWithDiscount - taxValue, precision);

    return { taxValue, preTaxPrice, taxedPrice, taxRate, discountValue, priceWithDiscount };
}

//this is for tax-exclusive computation
export const calcTaxExclusivePriceWithRatio = (preTaxPrice, taxRate, discountRate, quantity, precision) => {
    //ogger.trace('Calc tax exclusive price for input', { preTaxPrice, quantity, discountRate, taxRate, precision });
    if (taxRate > 1) taxRate = taxRate / 100;
    if (discountRate > 1) discountRate = discountRate / 100;

    const price = preTaxPrice * quantity;
    const discountValue = Abacus.round(price * discountRate, precision);
    const priceWithDiscount = Abacus.round(price - discountValue, precision)

    const taxValue = Abacus.round(priceWithDiscount * taxRate, precision);
    //taxedPrice == amount
    const amount = Abacus.round(priceWithDiscount + taxValue, precision);

    return { taxValue, preTaxPrice, amount, taxRate, discountValue, priceWithDiscount };
}