import { createVibes } from "@cosmos/vibes";
import { UPDATE_ITEM_PRICE, updateItemPriceSuccess, updateItemPriceFail } from "../../actions";
import { Abacus } from "@cosmos/harmony";

export const updateItemPriceVibes = createVibes({
  type: [UPDATE_ITEM_PRICE],
  latest: true,
  processOptions: {
    successType: updateItemPriceSuccess,
    failType: updateItemPriceFail
  },
  async process({ getState, action }) {
    try {
      const { goods, prices, taxRate } = getState().pos;
      const oriPrices = getState().price.prices;
      const newPrice = parseFloat(action.payload.price.replace(/,/gi, ''));
      const idx = action.payload.idx;
      const selectedGood = { ...goods[idx] };

      let oriSellPrice;
      if (selectedGood.priceAdjustment === null || selectedGood.priceAdjustment === undefined) {
        oriSellPrice = oriPrices[selectedGood.productId] ? oriPrices[selectedGood.productId].price : 0;
      }
      else {
        oriSellPrice = selectedGood.priceAdjustment;
      }     
      let calculatedOutput = Abacus.calcPriceWithValue(oriSellPrice, 1, (oriSellPrice - newPrice), taxRate, 2);

      // new code - start
      calculatedOutput.discountRate = isNaN(calculatedOutput.discountRate) ? 0 : calculatedOutput.discountRate;
      calculatedOutput.discountValue = calculatedOutput.discountRate ? (oriSellPrice - newPrice) : 0;
      // new code - end

      let pcs = [...prices.slice(0, idx), calculatedOutput, ...prices.slice(idx + 1)];
      let gds = [...goods.slice(0, idx), { ...selectedGood, discountRate: calculatedOutput.discountRate * 100 }, ...goods.slice(idx + 1)];
      return { prices: pcs, goods: gds };
    } catch (err) {
      return { error: err }
    }
  }
});
