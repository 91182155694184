import { createVibes } from "@cosmos/vibes";
import {
  CART_ADD_ITEM_SUCCESS,
  CART_REMOVE_ITEM_SUCCESS,
  UPDATE_DISC_RATE_SUCCESS,
  UPDATE_IDV_DISC_RATE_SUCCESS,
  UPDATE_TAX_RATE_SUCCESS,
  UPDATE_ITEM_PRICE_SUCCESS,
  updateTotalSuccess,
  updateTotalFail,
  UPDATE_DUTY_FREE_SUCCESS,
  CONFIRM_INIT_CART,
  UPDATE_TAX_MODE_SUCCESS
} from "../../actions";
import { reduce } from 'lodash';

export const updateTotalVibes = createVibes({
  type: [
    CART_ADD_ITEM_SUCCESS,
    CART_REMOVE_ITEM_SUCCESS,
    UPDATE_IDV_DISC_RATE_SUCCESS,
    UPDATE_DISC_RATE_SUCCESS,
    UPDATE_TAX_RATE_SUCCESS,
    UPDATE_TAX_MODE_SUCCESS,
    UPDATE_ITEM_PRICE_SUCCESS,
    UPDATE_DUTY_FREE_SUCCESS,
    CONFIRM_INIT_CART,
  ],
  latest: true,
  debounce: 200,
  processOptions: {
    successType: updateTotalSuccess,
    failType: updateTotalFail
  },
  async process({ getState, action }) {
    try {
      const { prices, taxMode } = getState().pos;
      const tax = reduce(prices, (accumulator, currentValue) => { accumulator += parseFloat(currentValue.taxValue); return accumulator }, 0);
      const discountValue = reduce(prices, (accumulator, currentValue) => { accumulator += parseFloat(currentValue.discountValue); return accumulator }, 0);
      let total;
      if(taxMode && taxMode === 'RECLAIM2') {
       total = reduce(prices, (accumulator, currentValue) => { accumulator += parseFloat(currentValue.priceWithDiscount); return accumulator }, 0);
      }
      else {
        total = reduce(prices, (accumulator, currentValue) => { accumulator += parseFloat(currentValue.amount); return accumulator }, 0);
      }
      return { tax, total, discountValue };
    } catch (err) {
      return { error: err };
    }
  }
});