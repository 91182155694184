import { createVibes } from "@cosmos/vibes";
import { LOAD_LATEST_RECEIPTS, LOAD_LATEST_RECEIPTS_SUCCESS, LOAD_LATEST_RECEIPTS_ERROR } from "../../actions";
import { reduce } from 'lodash';

export const loadCartVibes = createVibes({
  type: [LOAD_LATEST_RECEIPTS],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(new Error('User does not login yet'));
    }
  },
  async process({ getState, axios }, dispatch, done) {
    try {
      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query($filter: CartFilter) {
            carts(filter: $filter) {
                id storeId reference date
              }
          }`,
          variables: {
            filter: {
              latestCartCount: 3
            }
          }
        }
      });

      let latestCarts = result.data && result.data.carts;

      if (latestCarts) {
        dispatch({
          type: LOAD_LATEST_RECEIPTS_SUCCESS, payload: {
            latestCarts: reduce(latestCarts, (r, d) => {
              r[d.id] = d;
              return r;
            }, {}
            )
          }
        });
      }
      else {
        dispatch({ type: LOAD_LATEST_RECEIPTS_ERROR });
      }
      done();
    }
    catch (err) {
      dispatch({ type: LOAD_LATEST_RECEIPTS_ERROR });
      done();
    }
  }
});
