import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ConfirmDialog } from '../../../../components/dialog'
import { SelectModel, Input } from '../../../../components/inputs'
import Checkbox from './Checkbox'
import DisplayProduct from '../DisplayProductImage'
import Button from '@material-ui/core/Button'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Tooltip from '@material-ui/core/Tooltip'
import CameraInput from './CameraInput'
import { makeStyles } from '@material-ui/core/styles'
import { withCmdCode } from '@cosmos/chaos'
import useDialog from '../../../../hooks/useDialog'
import useHeaders from '../../../../hooks/useHeaders'
import checkStockLabelExists from './checkStockLabelExists'
import NumberFormat from 'react-number-format'

const AVAILABLE_CONDITIONS = [
    {
        label: 'N',
        value: 'new'
    },
    {
        label: 'V',
        value: 'never used'
    },
    {
        label: 'B',
        value: 'brand new'
    },
    {
        label: 'U',
        value: 'used'
    }
]
const generateInitialState = () => ({
    model: null,
    price: '',
    serial: '',
    label: '',
    condition: '',
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    warranty: false,
    box: false,
    cardCase: false,
    manual: false,
    tag: false,
    watchFront: null,
    cardFront: null,
    cardBack: null
})


const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
    }
})
/**
 * 
 * @param {object} props
 * @param {any} [product] - (Optional) The product to use for the add stock dialog
 * @param {boolean} props.isOpen - Whether the dialog is open
 * @param {() => void} props.handleClose - Function to run to close the dialog
 * @param {(stock : any) => void} props.addStock - Function to run to add stock 
 * @param {(stock : any) => void} props.editStock - Function to run to update stock 
 * @param {number} [startFromStep=0] - Which step to start from, zero-indexed
 * @param {boolean} [isCancellable=true] - Whether to show the cancel button or not
 */
export default withCmdCode(function AddStockDialog({ source, isOpen, handleClose, addStock, stockToEdit, editStock, startFromStep = 0, isCancellable = true }) {
    const [stock, setStock] = useState(() => {
        if (stockToEdit) return { ...stockToEdit, ...stockToEdit.conditionDetails }
        return generateInitialState()
    })

    
    useEffect(() => {
        if (stockToEdit) return setStock({ ...stockToEdit, ...stockToEdit.conditionDetails })
        return setStock(generateInitialState())
    }, [stockToEdit])
    
    useEffect(() => {
        source.subscribe(code => setStock(prevStock => ({ ...prevStock, label: code.val })))
    }, [source])
    
    const handleChange = name => e => {
        try { e.persist() } catch (e) { } // not all events need persist
        setStock(prevStock => ({
            ...prevStock, [name]: e.target.value
        }))
    }
    
    const headers = useHeaders()
    const { openDialog: openErrorDialog, Dialog: ErrorDialog } = useDialog('error')
    const { openDialog: openSuccessDialog, Dialog: SuccessDialog } = useDialog('success')
    const [step, setStep] = useState(startFromStep)
    const validate = [
        async stockInput => {
            if (!stockInput.label) return 'Label required'
            const exists = await checkStockLabelExists(stockInput.label, headers)
            if (!exists) return 'Stock not found'
            return null
        },
        stockInput => !stockInput.model ? 'Model required' : null,
        stockInput => null
    ]
    const goToStep = async (stepToGoTo) => {
        const error = await validate[stepToGoTo - 1](stock)
        if (error) return openErrorDialog(error)
        else setStep(stepToGoTo)
    }
    const isScannedPurchaseReference = useSelector(state => Object.keys(state.purchase.stockInInput).length <= 1) // https://issue.ieplsg.com/issue/COSMOS-1602
    const handleAddStock = () => {
        const { model, serial, condition, birthDay, birthMonth, birthYear, warranty, box, cardCase, manual, tag, watchFront, cardFront, cardBack, label, price } = stock
        const updatedStock = {
            model,
            price,
            serial,
            condition,
            label,
            birthDay,
            birthMonth,
            birthYear,
            watchFront,
            cardFront,
            cardBack,
            conditionDetails: {
                warranty,
                box,
                cardCase,
                manual,
                tag
            }
        }
        if (isScannedPurchaseReference) delete updatedStock.price
        if (stockToEdit) editStock(updatedStock)
        else addStock(updatedStock)
        setStock(generateInitialState())
    }

    const classes = useStyles()
    const STEPS = [
        <div>
            <h2>Scan Label</h2>
            <Input label="Scan label" value={stock.label} onChange={handleChange('label')} />
        </div>,
        <div className={classes.root}>
            <div>
                <h2>Add Stock</h2>
                <SelectModel onChange={(e, value, r) => handleChange('model')({ target: { value } })} value={stock.model} />
                {!isScannedPurchaseReference && <NumberFormat
                    label="Price"
                    value={stock.price}
                    customInput={Input}
                    decimalScale={0}
                    fixedDecimalScale
                    thousandSeparator={true}
                    onValueChange={(value, e) => {
                        handleChange('price')({ target: { value: value.floatValue } });
                    }}
                />}
                <Input label={"Serial Number"} onChange={handleChange('serial')} value={stock.serial} />
                <RadioGroup
                    aria-label="Condition"
                    value={stock.condition}
                    onChange={handleChange('condition')}
                    row
                    margin='dense'
                    className={classes.formGroup}
                >
                    {AVAILABLE_CONDITIONS.map((condition, index) => (
                        <FormControlLabel labelPlacement="end"
                            key={index}
                            style={{ transform: 'scale(0.9)', padding: '1px' }}
                            value={condition.value}
                            control={<Tooltip title={capitalize(condition.value)}><Radio size="small" inputProps={{ style: { padding: 0 } }} /></Tooltip>}
                            label={<small style={{ fontSize: '0.8em' }}>{condition.label}</small>}
                        />
                    ))}
                </RadioGroup>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '0.5em' }}>
                    <Input type="number" label={"Birth Day"} onChange={handleChange('birthDay')} value={stock.birthDay} />
                    <Input label={"Birth Month"} onChange={handleChange('birthMonth')} value={stock.birthMonth} />
                    <Input type="number" label={"Birth Year"} onChange={handleChange('birthYear')} value={stock.birthYear} />
                </div>
                <Checkbox label="Warranty" checked={stock.warranty} onChange={handleChange('warranty')} />
                <Checkbox label="Box" checked={stock.box} onChange={handleChange('box')} />
                <Checkbox label="Card Case" checked={stock.cardCase} onChange={handleChange('cardCase')} />
                <Checkbox label="Manual" checked={stock.manual} onChange={handleChange('manual')} />
                <Checkbox label="Tag" checked={stock.tag} onChange={handleChange('tag')} />
            </div>
            {stock.model && <DisplayProduct product={stock.model} />}
        </div>,
        <div>
            <h2>Take pictures</h2>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <SuccessDialog />
                <CameraInput label="Watch Front" value={stock.watchFront} handleChange={e => { openSuccessDialog('Successfully uploaded image'); handleChange('watchFront')(e) }} uploadUrl={'/v1/upload/stock'} handleError={error => openErrorDialog(`Failed to upload photo: ${error}`)} />
                <CameraInput label="Card Front" value={stock.cardFront} handleChange={e => { openSuccessDialog('Successfully uploaded image'); handleChange('cardFront')(e) }} uploadUrl={'/v1/upload/stock'} handleError={error => openErrorDialog(`Failed to upload photo: ${error}`)} />
                <CameraInput label="Card Back" value={stock.cardBack} handleChange={e => { openSuccessDialog('Successfully uploaded image'); handleChange('cardBack')(e) }} uploadUrl={'/v1/upload/stock'} handleError={error => openErrorDialog(`Failed to upload photo: ${error}`)} />
            </div>
        </div>
    ]
    return (
        <>
            <ErrorDialog />
            <ConfirmDialog isOpen={isOpen} handleClose={() => { handleClose(); setStep(startFromStep) }} onConfirm={handleAddStock} fullWidth maxWidth={"md"}
                renderActions={({ handleConfirm, handleClose }) => (
                    <div>
                        {isCancellable && <Button onClick={handleClose}>Cancel</Button>}
                        {step === STEPS.length - 1 ? (
                            <Button variant="contained" color="primary" onClick={handleConfirm}>Submit</Button>
                        ) : (
                                <Button variant="contained" color="primary" onClick={() => goToStep(step + 1)}>Next</Button>
                            )}
                    </div>
                )}
            >
                {STEPS[step]}
            </ConfirmDialog>
        </>
    )
})

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
}
