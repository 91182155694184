import React, { Component } from "react";
import { DialogContent, FormControlLabel, Radio, RadioGroup, Button, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  "radio-group": {
    display: "flex",
    justifyContent: "space-evenly"
  }
})

class BlankFormDialog_ extends Component {

  constructor(props) {
    super(props);
    this.state = {
      number: "0"
    }
  }

  handleConfirm = () => {
    this.props.handlePrint(this.state.number);
    this.reset();
  }

  handleCancel = () => {
    this.props.handleCancel();
    this.reset();
  }

  reset() {
    this.setState({ number: 0 });
  }

  updateNumber = (e) => {
    this.setState({ number: e.target.value });
  }

  render() {
    const { dialogOpen, docName, classes } = this.props;
    return (
      <Dialog maxWidth={"lg"} open={dialogOpen} className={classes['blank']}>
        <DialogTitle>
          How many copies of {docName} do you want to print?
        </DialogTitle>

        <DialogContent>
          <div>
            <RadioGroup name="copies" value={this.state.number} onChange={this.updateNumber}>
                <div className={classes['radio-group']}>
                {["1", "2", "3"].map(opt => {
                  return (
                    <FormControlLabel key={opt} value={opt} control={<Radio />} label={opt} />
                  )
                })}
                </div>
            </RadioGroup>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCancel} color="primary" variant="outlined" size="small" >
            Cancel
          </Button>
          <Button onClick={this.handleConfirm} color="primary" variant="contained" size="small">
            Print
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const BlankFormDialog = withStyles(styles)(BlankFormDialog_);