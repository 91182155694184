export const LOAD_PRICE_RULES_BEGIN = '@@priceRule/LOAD_PRICE_RULES_BEGIN';
export const LOAD_PRICE_RULES_SUCCESS = "@@priceRule/LOAD_PRICE_RULES_SUCCESS";
export const EDIT_PRICE_RULE_VALUE = '@@priceRule/EDIT_PRICE_RULE_VALUE';
export const CLEAR_PRICE_RULE_VALUE = '@@priceRule/CLEAR_PRICE_RULE_VALUE';
export const SUBMIT_PRICE_RULE_BEGIN = '@@priceRule/SUBMIT_PRICE_RULE_BEGIN';
export const SUBMIT_PRICE_RULE_SUCCESS = '@@priceRule/SUBMIT_PRICE_RULE_SUCCESS';
export const SUBMIT_PRICE_RULE_FAIL = '@@priceRule/SUBMIT_PRICE_RULE_FAIL';
export const CLOSE_PRICE_RULE_NOTIFICATION = '@@priceRule/CLOSE_PRICE_RULE_NOTIFICATION';
export const UPDATE_PRICE_RULE_BOX_OPEN_STATUS = '@@priceRule/UPDATE_PRICE_RULE_BOX_OPEN_STATUS';
export const EDIT_PRICE_INFO_FILTER = '@@priceRule/EDIT_PRICE_INFO_FILTER';
export const SEARCH_PRODUCT_PRICE_TAG = '@@priceRule/SEARCH_PRODUCT_PRICE_TAG';
export const SEARCH_PRODUCT_PRICE_TAG_SUCCESS = '@@priceRule/SEARCH_PRODUCT_PRICE_TAG_SUCCESS';
export const SEARCH_PRODUCT_PRICE_TAG_FAIL = '@@priceRule/SEARCH_PRODUCT_PRICE_TAG_FAIL';
export const CLEAR_SEARCH_PRODUCT_PRICE_TAG = '@@priceRule/CLEAR_SEARCH_PRODUCT_PRICE_TAG';
export const UPDATE_APPLIED_PRICE_RULE = '@@priceRule/UPDATE_APPLIED_PRICE_RULE';

