import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  Input,
  LinearProgress,
  Button,
  Card,
  Typography,
} from '@material-ui/core';
import { DataGrid, TableToolbar } from '@cosmos/chaos';
import { AutoSizer } from 'react-virtualized';
import { FocusDialog } from '../../components';
import { SCAN_STOCK_CHECK, CLOSE_STOCKCHECK_NOTICE, CANCEL_INV_LOAD, INV_LOAD_BEGIN, UPDATE_SEARCH_LABEL, SEARCH_STOCK_BY_LABEL, } from '../../actions';
import { withCmdCode } from '@cosmos/chaos';
import { AlertOutline, CheckboxMarkedCircleOutline, History } from 'mdi-material-ui';
import { push } from 'connected-react-router';

import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  stockcheck: {
    flexGrow: 1,
    display: "flex",
    padding: "0.5em"
  },
  feature: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  "action-button": {
    float: "right",
    margin: "1rem"
  },
  toolset: {
    display: "flex"
  },
  messageBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    marginLeft: "0.5em"
  },
  toolbar: {
    backgroundColor: theme.palette.primary.light
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "row"
  },
  card: {
    flex: 1,
    margin: "0.75rem",
    display: "flex",
    flexDirection: "column"
  },
  title: {
    padding: "1rem"
  },
  table: {
    flex: 1
  },
  "error-icon": {
    color: "red"
  },
  "warn-icon": {
    color: "#ff9800"
  },
  "success-icon": {
    color: "#4caf50"
  },
  "spacer":{
    width: "2em"
  },
  "search-input":{
    padding: "6px 6px 6px 60px"
  },
  "header-sortable":{
    display: "flex",
    flexWrap: 'wrap',
    color: "# 333 ",
    justifyContent: "flex-start"
  },
  "header-sortable-index": {
    display: "none",
    fontSize: "80%"
  },
  "header-sortable-active": {
    color: "#000",
    "&.header-sortable-index":{
    display: "block",
    fontSize: "80%"
  }
  },
  "header-sortable-icon": {
  width: '12px',
  height: "12px",
  color: "#3333EE"
  }
})


class StockCheckComponent_ extends Component {
  state = {
    expectedOrders: new Map(),
    scannedOrders: new Map(),
  }

  componentDidMount() {
    this.props.dispatch({ type: CANCEL_INV_LOAD });
    this.props.dispatch({ type: INV_LOAD_BEGIN, payload: { filter: { isExpected: true } } });
    this.cmdSource = this.props.source.subscribe(code => {
      if (code.cmd) {
        switch (code.cmd) {
          case 'STK':
            this.props.dispatch({ type: SCAN_STOCK_CHECK, payload: { searchLabel: code.val } });
            break;
          default:
            break;
        }
      }
    });
  }

  updateSorting(key, orders) {
    if (orders.has(key)) {
      const dir = orders.get(key);
      if (dir === 'asc') {
        orders.set(key, 'desc');
      } else {
        orders.delete(key);
      }
    } else {
      orders.set(key, 'asc');
    }
    this.setState({ orders });
  }

  closeNotification = () => {
    this.props.dispatch({ type: CLOSE_STOCKCHECK_NOTICE });
  }

  checkHistory = () => {
    this.props.dispatch(push('/inventory/clearanceHistory'));
  }

  enterLabel = event => {
    this.props.dispatch({ type: UPDATE_SEARCH_LABEL, payload: { searchLabel: event.target.value } });
  }

  searchStock = () => {
    this.props.dispatch({ type: SEARCH_STOCK_BY_LABEL });
  }

  displayButtons = () => (
    <React.Fragment>
      <Button color="primary" variant="contained" onClick={this.checkHistory} className={this.props.classes['action-button']}>
        <History/> History
      </Button>
    </React.Fragment>
  )

  render() {
    const { loading, notify, notice, noticeType, expectedStocks, scannedStocks, searchLabel, classes } = this.props;
    const { expectedOrders, scannedOrders } = this.state;

    const expectedColumns = [
      {
        name: 'id',
        header: 'ID',
        onHeaderClick: () => {
          this.updateSorting('id', expectedOrders);
        },
        cellProps: {
          style: { paddingLeft: 20 },
        }
      },
      {
        name: 'brandName',
        header: 'Brand',
        onHeaderClick: () => {
          this.updateSorting('brandName', expectedOrders);
        }
      },
      {
        name: 'productName',
        header: 'Model',
        onHeaderClick: () => {
          this.updateSorting('productName', expectedOrders);
        },
      },
      {
        name: 'productReference',
        header: 'Reference',
        onHeaderClick: () => {
          this.updateSorting('productReference', expectedOrders);
        }
      },
      {
        name: 'serial',
        header: 'Serial',
        onHeaderClick: () => {
          this.updateSorting('serial', expectedOrders);
        }
      },
    ]

    const scannedColumns = [
      {
        name: 'clearanceState',
        header: 'Status',
        onHeaderClick: () => {
          this.updateSorting('clearanceState', scannedOrders);
        },
        cell: rowData => {
          switch (rowData.clearanceState) {
            case 0:
              return (<CheckboxMarkedCircleOutline className={classes['success-icon']} />)
            case -1:
              return (<AlertOutline className={classes['warn-icon']} />)
            default:
              return (<AlertOutline className={classes['error-icon']} />)
          }
        },
        cellProps: {
          style: { paddingLeft: 20 },
        }
      },
      {
        name: 'label',
        header: 'Label',
        onHeaderClick: () => {
          this.updateSorting('label', scannedOrders);
        },
      },
      {
        name: 'id',
        header: 'ID',
        onHeaderClick: () => {
          this.updateSorting('id', scannedOrders);
        },
      },
      {
        name: 'brandName',
        header: 'Brand',
        onHeaderClick: () => {
          this.updateSorting('brandName', scannedOrders);
        }
      },
      {
        name: 'productName',
        header: 'Model',
        onHeaderClick: () => {
          this.updateSorting('productName', scannedOrders);
        },
      },
      {
        name: 'productReference',
        header: 'Reference',
        onHeaderClick: () => {
          this.updateSorting('productReference', scannedOrders);
        }
      },
      {
        name: 'serial',
        header: 'Serial',
        onHeaderClick: () => {
          this.updateSorting('serial', scannedOrders);
        }
      },
    ]

    return (
      <div className={classes.stockcheck}>
        <Paper className={classes.feature}>
          <TableToolbar
            numSelected={0}
            title="Stockcheck"
            styles={{ root: classes.toolbar, toolset: classes.toolset, messageBox: classes.messageBox }}
            searchBox={() => (
              <Input
                placeholder="Label"
                disableUnderline
                value={searchLabel}
                onChange={this.enterLabel}
                className={classes['search-input']}
                fullWidth
                endAdornment={(
                  <Button color="primary" variant="contained" onClick={this.searchStock}>
                    Check
                  </Button>
                )}
              />
            )}
            toolBox={this.displayButtons}
          />

          <FocusDialog open={notify} message={notice} variant={noticeType} onClose={this.closeNotification} />

          {loading && <LinearProgress />}

          <div className={classes.content}>
            <Card className={classes.card}>
              <div className={classes.title}>
                <Typography variant='h6'>
                  Outstanding
                </Typography>
              </div>
              <div className={classes.table}>
                <AutoSizer>
                  {({ width, height }) => (
                    <DataGrid
                      data={expectedStocks}
                      columns={expectedColumns}
                      width={width}
                      maxHeight={height}
                      height={height}
                      includeHeaders={true}
                      fixedRowCount={1}
                      cellProps={{
                        style: { paddingLeft: 2, paddingRight: 4 },
                      }}
                      isCellHovered={(column, rowData, hoveredColumn, hoveredRowData, ) => {
                        return (rowData && rowData.id === hoveredRowData.id)
                      }}
                      orderBy={expectedOrders}
                      classes={{
                        sortableHeader: classes['header-sortable'],
                        sortableHeaderActive: classes['header-sortable-active'],
                        sortableHeaderIcon: classes['header-sortable-icon'],
                        sortIndex: classes['header-sortable-index']
                      }}

                    />
                  )}
                </AutoSizer>
              </div>
            </Card>
            <div className={classes.spacer}></div>
            <Card className={classes.card}>
              <div className={classes.title}>
                <Typography variant='h6'>
                  Scanned
                </Typography>
              </div>
              <div className={classes.table}>
                <AutoSizer>
                  {({ width, height }) => (
                    <DataGrid
                      data={scannedStocks}
                      columns={scannedColumns}
                      width={width}
                      maxHeight={height}
                      height={height}
                      includeHeaders={true}
                      fixedRowCount={1}
                      cellProps={{
                        style: { paddingLeft: 2, paddingRight: 4 },
                      }}
                      isCellHovered={(column, rowData, hoveredColumn, hoveredRowData, ) => {
                        return (rowData && rowData.id === hoveredRowData.id)
                      }}
                      orderBy={scannedOrders}
                      classes={{
                        sortableHeader: classes['header-sortable'],
                        sortableHeaderActive: classes['header-sortable-active'],
                        sortableHeaderIcon: classes['header-sortable-icon'],
                        sortIndex: classes['header-sortable-index']
                      }}
                    />
                  )}
                </AutoSizer>
              </div>
            </Card>
          </div>
        </Paper>
      </div >
    )
  }
}

export const StockCheckComponent = connect(state => {
  const { stocks, stocksDetail } = state.inventory;
  const marshalledExpectedStks = Object.values(stocks).map(el => {
    return { ...el, ...stocksDetail[el.id] };
  });

  return {
    loading: state.stockcheck.loading,
    notify: state.stockcheck.notify,
    notice: state.stockcheck.notice,
    noticeType: state.stockcheck.noticeType,
    expectedStocks: marshalledExpectedStks,
    scannedStocks: state.stockcheck.scannedStocks,
    searchLabel: state.stockcheck.searchLabel,
  }
})(withCmdCode(withStyles(styles)(StockCheckComponent_)));