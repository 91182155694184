import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    '@global': {
        'main#root': {
            width: '100vw',
            height: '100vh',
            display: 'grid',
            gridTemplateColumns: `[left] 48px [bar] 144px [aside] auto [side] 48px [right]`,
            gridTemplateRows: `[top] 48px [head] auto [foot] 48px [bottom]`
        }
    }
}), {name: 'RayLayout'});

export const RayLayout = (props) => {
    const {children = null} = props;
    useStyles();
    return <>{children}</>
};

RayLayout.propTypes = {
    children: PropTypes.node,
};

