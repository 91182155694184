import { createVibes } from "@cosmos/vibes";
import {
  CANCEL_INIT_CART, REINIT_CART
}
  from "../../actions";

export const reinitCartVibes = createVibes({
  type: CANCEL_INIT_CART,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState }, dispatch, done) {
    const { rayLens, stores, countries } = getState().system;
    const taxRate = stores && stores[rayLens] && stores[rayLens].countryId && countries && countries[stores[rayLens].countryId] ? countries[stores[rayLens].countryId].vat : 0;
    dispatch({ type: REINIT_CART, payload: taxRate });
    done();
  }
});
