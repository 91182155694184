import axios from 'axios'

/**
 * 
 * @param {string} url - The url for the image to get
 * @param {*} headers - Headers required for getting image. (Bearer auth)
 * @return {{data: string} | {error: string}} result.data is the URL for the image to display, result.error is the error message
 */
export default async (url, headers) => {
    try {
        const response = await axios.request({
            url,
            method: 'GET',
            headers,
            responseType: 'blob'
        })
    
        const contentType = response.headers['content-type']
        const blob = new Blob([response.data], {type: contentType})
        const fileUrl = window.URL.createObjectURL(blob)
        return {data: fileUrl}
    } catch(e) {
        return {error: e.message}
    }
}