import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import {
    Card,
    CardHeader,
    CardContent,
    makeStyles,
    LinearProgress,
    Button,
} from "@material-ui/core";
import {
    LOAD_SUPPLIES,
    LOAD_OVERVIEW_SUPPLIES,
    SAVE_OVERVIEW_FILTER
} from "../../../actions/marketing";
import classNames from "classnames";
import { lighten } from "@material-ui/core/styles";
import { DataGrid } from "@cosmos/chaos";
import { AutoSizer } from "react-virtualized";
import moment from "moment";
import * as _ from 'lodash';
import { Eye } from "mdi-material-ui";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttonClass: {
        '&:hover': {
            cursor: 'pointer',
            color: '#6495ed'
        }
    }
    // header: {},
    // headerOn: {
    //     backgroundColor: lighten(theme.palette.primary.light, 0.8),
    // },
    // item: {
    //     borderRadius: "0.25em",
    //     marginBottom: "0.25em",
    // },
    // "header-sortable" : {
    //     display: "flex",
    //     flexWrap: "wrap",
    //     color: "#333",
    //     justifyContent: "flex-start"
    // },
    // "header-sortable-active": {
    //     color: "#000",
    //     "&:headerSortableIndex": {
    //         display: "block"
    //     },
    //     "&.headerSortableIndex": {
    //         display: "block"
    //     },
    //     "&$headerSortableIndex": {
    //         display: "block"
    //     }
    // },
    // headerSortableIndex: {
    //     display: "none",
    //     fontSize: "80%"
    // },
    // "header-sortable-icon": {
    //     width: "12px",
    //     height: "12px",
    //     color: "#3333EE"
    // },
}));
export const SuppliesOverview = ({ className }) => {

    const dispatch = useDispatch();
    let history = useHistory();

    const traders = useSelector((state) => state.trader.traders);
    const currencies = useSelector((state) => state.system.currencies);
    const overviewSupplies = useSelector((state) => state.marketing.overviewSupplies);
    //const loading = useSelector((state) => state.marketing.overviewLoading);
    const [data, setData] = useState([]);
    // const [dirty, setDirty] = useState(false);
    // const [orders, setOrders] = useState(new Map());

    useEffect(() => {
        dispatch({ type: LOAD_OVERVIEW_SUPPLIES, payload: 20201 });
    }, [dispatch]);

    if ((overviewSupplies.length !== 0) && (data.length === 0)) {
        setData(overviewSupplies);
    }

    // const updateSorting = (key) => {

    //     if (orders.has(key)) {
    //         const dir = orders.get(key);
    //         if (dir === 'asc') {
    //             orders.set(key, 'desc');
    //         } else {
    //             orders.delete(key);
    //         }
    //     } else {
    //         orders.set(key, 'asc');
    //     }
    //     setOrders(orders);      
    // }

    const columns = [
        {
            name: "reference",
            header: "PO Reference",
            width: "15%",
        },
        {
            name: "traderId",
            header: "Supplier",
            width: "15%",
            cell: (rowData) => {
                let name = null;
                if (Object.keys(traders).length > 0 && rowData.vendor) {
                    name = traders[rowData.vendor].name;
                }
                return name;
            },
        },
        {
            name: "price",
            header: "Price",
            width: "15%",
            cell: (rowData) => {
                const c = currencies[rowData.currency];
                const s = c ? c.symbol : "";
                return (
                    <NumberFormat
                        value={rowData["totalPrice"] || ""}
                        prefix={`${s} `}
                        displayType="text"
                        thousandSeparator
                    />
                );
            },
            // onHeaderClick: () => {
            //     updateSorting('price');
            // },
        },
        {
            name: "quantity",
            header: "Quantity",
            width: "10%",
            cell: (rowData) => {
                return rowData["enteredQuantity"];
            },
        },
        {
            name: "enteredDate",
            header: "Entered Date",
            width: "15%",
            cell: (rowData) => {
                return moment.utc(rowData["createdAt"]).local().format("YYYY-MM-DD HH:mm:ss");
            },
        },
        {
            name: "expectedArrivalDate",
            header: "Arrival Date",
            width: "15%",
            cell: (rowData) => {
                return moment.utc(rowData["expectedArrivalDate"]).local().fromNow();
            },
        },
        {
            name: "action",
            header: "Action",
            width: "15%",
            cell: (rowData) => {
                return <Button variant="outlined" color="primary" className={classNames(styles.buttonClass)}
                    onClick={() => {
                        let filterData = {
                            traderId: rowData["vendor"],
                            validFrom: moment.utc(rowData["createdAt"]).startOf("day").toISOString(),
                            validTo: moment.utc(rowData["expiryDate"]).startOf("day").toISOString(),
                        }
                        dispatch({ type: SAVE_OVERVIEW_FILTER, payload: filterData });
                        history.push('/marketing/supplies', { payload: filterData });
                    }}
                >View Supplies</Button>
            },
        },
    ];


    const styles = useStyles();
    return (
        <>
            {/* <Card>
                <CardHeader
                    className={classNames(
                        styles.header,
                        // dirty && styles.headerOn
                    )}
                    title={"Overview"}
                />
                <CardContent className={styles.content}>
                    {loading && <LinearProgress />} */}
            <AutoSizer>
                {({ width, height }) => (
                    <DataGrid
                        style={{ flex: 1 }}
                        width={width}
                        height={height}
                        data={data}
                        columns={columns}
                        includeHeaders={true}
                        fixedRowCount={1}
                        zebra
                        isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) => {
                            return (rowData && _.isEqual(rowData, hoveredRowData))
                        }}
                        onCellClick={(event, root) => {
                            let filterData = {
                                traderId: root.rowData["vendor"],
                                validFrom: moment.utc(root.rowData["createdAt"]).startOf("day").toISOString(),
                                validTo: moment.utc(root.rowData["expiryDate"]).startOf("day").toISOString(),
                            }
                            dispatch({ type: SAVE_OVERVIEW_FILTER, payload: filterData });
                        }}
                    // orderBy={orders}
                    // classes={{
                    //     // sortableHeader: styles['header-sortable'],
                    //     // sortableHeaderActive: styles['header-sortable-active'],
                    //     // sortableHeaderIcon: styles['header-sortable-icon'],
                    //     // sortIndex: styles.headerSortableIndex
                    // }}
                    />
                )}
            </AutoSizer>
            {/* </CardContent>
            </Card> */}
        </>
    );
};
