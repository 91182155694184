import {
    LOAD_TRADER_SUCCESS,
    LOAD_TRADER_ERROR,
    APPEND_NEW_TRADER,
    TRADER_CREATE_NEW_SUCCESS,
    TRADER_CREATE_NEW_FAIL,
    TRADER_CLEAR_ERRORS
} from "../actions";

const initialState = {
    traders: {},
    loading: false,
    error: null,
    message: null,
    recentlyAdded: null,

};

export const traderReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_TRADER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case LOAD_TRADER_ERROR:
            return {
                ...state,
                ...action.payload,
                loading: false
            };

        case APPEND_NEW_TRADER:
            return {
                ...state,
                traders: {
                    ...state.traders,
                    [action.payload.id]: action.payload
                }
            }
        case TRADER_CREATE_NEW_SUCCESS:
            return {
                ...state,
                traders: {
                    ...state.traders,
                    [action.payload.trader.id]: action.payload.trader
                },
                recentlyAdded: action.payload.trader,
                message: action.payload.message
            }
        case TRADER_CREATE_NEW_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case TRADER_CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                message: null
            }
        default:
            return state;
    }
};