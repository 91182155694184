import { createVibes } from "@cosmos/vibes";
import { UPDATE_DUTY_FREE, UPDATE_DUTY_FREE_SUCCESS, UPDATE_DUTY_FREE_ERROR, enqueuePosNotice } from "../../actions";
import { Abacus } from "@cosmos/harmony";

export const applyDutyFreeVibes = createVibes({
  type: [UPDATE_DUTY_FREE],
  latest: true,
  async process({ getState, action }, dispatch, done) {
    try {
      const { dutyFree } = action.payload;
      const { customer } = getState().pos;
      const { rayLens, stores } = getState().system;
      let type = 0;

      if (dutyFree) {
        const { goods } = getState().pos;
        const oriPrices = getState().price.prices;

        let pcs = [];
        goods.map(gd => {
          let oriSellPrice;
          if (gd.priceAdjustment === null || gd.priceAdjustment === undefined) {
            oriSellPrice = oriPrices[gd.productId] ? oriPrices[gd.productId].price : 0;
          }
          else {
            oriSellPrice = gd.priceAdjustment;
          }
          let calculatedOutput = Abacus.calcPriceWithRatio(oriSellPrice, 1, gd.discountRate, 0, 2);
          pcs.push(calculatedOutput);
          return gd;
        });

        let cus = stores && customer.id !== null && stores[customer.id];
        let seller = stores && stores[rayLens];
        if (cus && seller) {
          if (cus.type === 0) {
            type = 1;   //personal
          }
          else {
            type = 2;   //company
          }
          if (cus.countryId && seller.countryId && cus.countryId === seller.countryId) {
            dispatch(enqueuePosNotice('warning', 'The customer is from the same country, please check if the sales should be duty free'));
          }
        }
        dispatch({ type: UPDATE_DUTY_FREE_SUCCESS, payload: { dutyFree, type, prices: pcs, taxRate: 0 } });
      }
      else {
        dispatch({ type: UPDATE_DUTY_FREE_SUCCESS, payload: { dutyFree, type } });
      }
      done();
    } catch (err) {
      dispatch({ type: UPDATE_DUTY_FREE_ERROR, payload: { error: err } });
      done();
    }
  }
});