import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

export default function Input({
  label,
  value,
  onChange,
  separateLabel,
  inputRef,
  ...props
}) {
  const classes = useStyles();
  return separateLabel ? (
    <div className={classes.root}>
      <label style={{ margin: "0 5px" }}>{label}</label>
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        fullWidth
        margin="dense"
        inputRef={inputRef}
        {...props}
      />
    </div>
  ) : (
      <TextField
        label={label}
        variant="standard"
        value={value}
        onChange={onChange}
        fullWidth
        margin="dense"
        inputRef={inputRef}
        {...props}
      />
    );
}
