import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';

class PopulateDataDialogComponent extends Component {

  render() {
    const { dialogOpen } = this.props;

    return (
      <Dialog
        maxWidth={"lg"}
        open={dialogOpen}
      >
        <DialogTitle>Do you want to continue with previous saved inputs? </DialogTitle>
        <DialogActions>
          <Button onClick={this.props.closeDialog} color='primary' variant='outlined' size='small'>
            No
          </Button>
          <Button onClick={this.props.handleConfirm} color='primary' variant='contained' size='small'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const PopulateDataDialog = PopulateDataDialogComponent;