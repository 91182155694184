import React, { Component } from 'react';
import styles from './gear.module.scss';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { GearDialog } from './components/dialog';
import {
    DataGrid, TableToolbar, Authorization,
} from '@cosmos/chaos';
import {
    INV_LOAD_BEGIN,
    FIND_GEAR_UP_STK,
    FIND_GEAR_DOWN_STK,
    RESET_GEAR_UP_FORM,
    RESET_GEAR_DOWN_FORM,
    TOGGLE_GEARUP_DIALOG,
    TOGGLE_GEARDOWN_DIALOG,
    GEAR_UP_REMOVE_ITEM_BEGIN,
    GEAR_DOWN_REMOVE_ITEM_BEGIN,
    SAVE_GEAR_UP,
    SAVE_GEAR_DOWN,
    CLOSE_GEAR_NOTICE,
    CANCEL_INV_LOAD,
    INIT_USER_LOAD
} from '../../actions';
import { Paper } from '@material-ui/core';
import { AutoSizer } from 'react-virtualized';
import { FocusDialog } from '../../components';

class GearComponent extends Component {

    state = {
        orders: new Map(),
    }

    componentDidMount() {
        this.props.dispatch({ type: CANCEL_INV_LOAD });
        this.props.dispatch({ type: INV_LOAD_BEGIN, payload: { filter: { states: ["GEARUP"] } } });
        this.props.dispatch({ type: INIT_USER_LOAD });
    }

    updateSorting(key) {
        let orders = this.state.orders;
        if (orders.has(key)) {
            const dir = orders.get(key);
            if (dir === 'asc') {
                orders.set(key, 'desc');
            } else {
                orders.delete(key);
            }
        } else {
            orders.set(key, 'asc');
        }
        this.setState({ orders });
    }

    closeNotification = () => {
        this.props.dispatch({ type: CLOSE_GEAR_NOTICE });
    }

    searchGearUpStock = (label) => {
        this.props.dispatch({ type: FIND_GEAR_UP_STK, payload: { search: label, state: 'HOLD' } });
    }

    closeGearUpDialog = () => {
        this.props.dispatch({ type: RESET_GEAR_UP_FORM });
    }

    toggleGearUpDialog = () => {
        this.props.dispatch({ type: TOGGLE_GEARUP_DIALOG });
    }

    removeGearUpItem = (idx) => {
        this.props.dispatch({ type: GEAR_UP_REMOVE_ITEM_BEGIN, payload: idx });
    };

    submitGearUp = () => {
        this.props.dispatch({ type: SAVE_GEAR_UP });
    }

    searchGearDownStock = (label) => {
        this.props.dispatch({ type: FIND_GEAR_DOWN_STK, payload: { search: label, state: 'GEARUP' } });
    }

    closeGearDownDialog = () => {
        this.props.dispatch({ type: RESET_GEAR_DOWN_FORM });
    }

    toggleGearDownDialog = () => {
        this.props.dispatch({ type: TOGGLE_GEARDOWN_DIALOG });
    }

    removeGearDownItem = (idx) => {
        this.props.dispatch({ type: GEAR_DOWN_REMOVE_ITEM_BEGIN, payload: idx });
    };

    submitGearDown = () => {
        this.props.dispatch({ type: SAVE_GEAR_DOWN });
    }

    render() {
        const { permission, gearUpState, gearDownState, marshalledStocks, notify, noticeType, notice, gearUpStocks, gearDownStocks } = this.props;
        const { orders } = this.state;
        const columns = [
            {
                name: 'id',
                header: 'ID',
                onHeaderClick: () => {
                    this.updateSorting('id');
                },
                cellProps: {
                    style: { paddingLeft: 20 },
                }
            },
            {
                name: 'brandName',
                header: 'Brand',
                onHeaderClick: () => {
                    this.updateSorting('brandName');
                }
            },
            {
                name: 'productName',
                header: 'Model',
                cell: rowData => (
                    <div className={styles["product-row"]}>
                        <div className={styles["scroll"]}>
                            {rowData.productName}
                        </div>
                    </div>
                ),
                onHeaderClick: () => {
                    this.updateSorting('productName');
                },
            },
            {
                name: 'productReference',
                header: 'Reference',
                cell: rowData => (
                    <div className={styles["product-row"]}>
                        <div className={styles["scroll"]}>
                            {rowData.productReference}
                        </div>
                    </div>
                ),
                onHeaderClick: () => {
                    this.updateSorting('productReference');
                }
            },
            {
                name: 'serial',
                header: 'Serial',
                onHeaderClick: () => {
                    this.updateSorting('serial');
                }
            },
            {
                name: 'storeName',
                header: 'Store',
                onHeaderClick: () => {
                    this.updateSorting('storeName');
                }
            },
            {
                name: 'operator',
                header: 'Operator',
                onHeaderClick: () => {
                    this.updateSorting('operator');
                }
            },

        ]

        let actionBtns = () => (
            <div>
                <Button className={styles['btns']} variant="contained" color="primary" onClick={this.toggleGearDownDialog}>gear down</Button>
                <Button className={styles['btns']} variant="contained" color="primary" onClick={this.toggleGearUpDialog}>gear up</Button>
            </div>
        );

        return (
            <Authorization allowed={['cosmos.gear.view']}>
                {({ permit }) => (permit(permission) &&
                    <div className={styles.gear}>
                        <Paper className={styles.feature}>
                            <TableToolbar
                                title="Stock Gear"
                                numSelected={0}
                                styles={{ root: styles.toolbar, toolset: styles.toolset, messageBox: styles.messageBox }}
                                toolBox={actionBtns}
                            />

                            <div className={styles.content}>
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <DataGrid
                                            data={marshalledStocks}
                                            columns={columns}
                                            width={width}
                                            maxHeight={height}
                                            height={height}
                                            includeHeaders={true}
                                            fixedRowCount={1}
                                            cellProps={{
                                                style: { paddingLeft: 2, paddingRight: 4 },
                                            }}
                                            isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) => {
                                                return (rowData && rowData.id === hoveredRowData.id)
                                            }}
                                            orderBy={orders}
                                            classes={{
                                                sortableHeader: styles['header-sortable'],
                                                sortableHeaderActive: styles['header-sortable-active'],
                                                sortableHeaderIcon: styles['header-sortable-icon'],
                                                sortIndex: styles['header-sortable-index']
                                            }}
                                        />
                                    )}
                                </AutoSizer>
                            </div>
                        </Paper>
                        <FocusDialog open={notify} message={notice} variant={noticeType} onClose={this.closeNotification}/>
                        <GearDialog
                            dialogOpen={gearUpState}
                            closeDialog={this.closeGearUpDialog}
                            handleSubmit={this.submitGearUp}
                            items={gearUpStocks}
                            searchStock={this.searchGearUpStock}
                            removeItem={this.removeGearUpItem}
                            title={"Scan Label to Gear Up"}
                        />
                        <GearDialog
                            dialogOpen={gearDownState}
                            closeDialog={this.closeGearDownDialog}
                            handleSubmit={this.submitGearDown}
                            items={gearDownStocks}
                            searchStock={this.searchGearDownStock}
                            removeItem={this.removeGearDownItem}
                            title={"Scan Label to Gear Down"}
                        />
                    </div >
                )}
            </Authorization>
        )
    }
}


export const GearView = connect(state => {
    const { stocks, stocksDetail, stocksExtra, filteredStocks } = state.inventory;
    const { users } = state.system; 
    const marshalledStocks = Object.values(stocks).filter(el => filteredStocks.indexOf(el.id) > -1).map(el => {
        return { 
            ...el, 
            ...stocksDetail[el.id], 
            ...stocksExtra[el.id], 
            operator: el.activities && !isNaN(el.activities.userId) && users && users[el.activities.userId] ? users[el.activities.userId].name : ''
        };
    })
    return {
        permission: state.auth.permission,
        notify: state.gear.notify,
        noticeType: state.gear.noticeType,
        notice: state.gear.notice,
        marshalledStocks: marshalledStocks,
        gearUpState: state.gear.gearUpState,
        gearDownState: state.gear.gearDownState,
        gearUpStocks: state.gear.gearUpStocks,
        gearDownStocks: state.gear.gearDownStocks,
    }
}
)(GearComponent);

