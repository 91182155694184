import React, { Component } from 'react';
import { Popper, Fade } from '@material-ui/core';
import styles from './popper.module.scss'

export class PhotoPopper extends Component {
    render() {
        const {
            openPhoto,
            anchorElPhoto,
            placementPhoto,
            assetReference,
            flip,
            preventOverflow,
            onMouseLeave
        } = this.props
        return (
            <div>
                <Popper
                    className={styles['popper']}
                    open={openPhoto}
                    anchorEl={anchorElPhoto}
                    placement={placementPhoto}
                    transition
                    modifiers={{
                        flip: {
                            enabled: flip,
                        },
                        preventOverflow: {
                            enabled: preventOverflow !== 'disabled',
                            boundariesElement:
                                preventOverflow === 'disabled' ? 'scrollParent' : preventOverflow,
                        },
                    }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={100}>
                            <img
                                src={assetReference}
                                className={styles['image']}
                                alt={'popper'}
                                onMouseLeave={onMouseLeave} />
                        </Fade>
                    )}

                </Popper>
            </div>
        )
    }
}