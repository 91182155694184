import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { DataGrid } from '@cosmos/chaos'
import classNames from 'classnames';

import {
  UPDATE_DF_PRICE,
  REMOVE_DF_ITEM,
  DF_UPDATE_CART_FIELD,
  SELECT_DF_ITEM,
  SUBMIT_DF_ITEMS,
} from '../../../actions';
import {
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import { NumberFormatCustom } from '../../../components';
import IndexCell from './indexCell';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({

  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "1em",
    padding: '1em',
    borderRadius: "0.5em",
    border: "1px solid black"
  },
  title: {
    margin: "0.5em",
    display: "flex",
    justifyContent: "space-between"
  },
  content: {
    margin: "0.5em",
    paddingTop: '1em',
    display: "flex",
    flexDirection: "column"
  },   
  "content-title": {
    display: "flex",
    justifyContent: "space-between"
  },
  "content-list": {
    flex: 1
  },
  'content-container': {
    margin: "0.5em",
    minHeight: "35em"
  },
  "idx-delete": {
    display: "none",
    "&.hover": {
      display: "block"
    }
  },
  "idx-text": {
    display: "block",
    "&.hover": {
      display: "none"
    }
  },
  "content-footer": {
      margin: "0.5em",
      display: "flex",
      justifyContent: "flex-end"
    }
});

class DutyFreeCartComponent extends Component {

  handleSubmit = () => {
    this.props.dispatch({ type: SUBMIT_DF_ITEMS });
  }

  updateCartField = (field, value) => {
    this.props.dispatch({ type: DF_UPDATE_CART_FIELD, payload: { [field]: value } })
  }

  handlePriceChange = (value, rowData) => {
    this.props.dispatch({ type: UPDATE_DF_PRICE, payload: { newValue: value, dataId: rowData.id } });
  }

  handleRemoveItem = (itemIndex) => {
    this.props.dispatch({ type: REMOVE_DF_ITEM, payload: { itemIndex } });
  }

  selectItem = (itemIndex) => {
    this.props.dispatch({ type: SELECT_DF_ITEM, payload: { selected: itemIndex } });
  }

  render() {
    const { cartInput, goods, selected, orderInput, classes } = this.props;

    const columns = [
      {
        header: '',
        cellProps: {
          style: { paddingLeft: 20 },
        },
        cell: (rowData) => (
          <IndexCell
            buttonClass={classNames(classes['idx-delete'])}
            textClass={classNames(classes['idx-text'])}
            index={goods.indexOf(rowData)}
            handleDeleteRow={() => { this.handleRemoveItem(goods.indexOf(rowData)) }}
          />
        ),
      },
      {
        name: 'label',
        header: 'Label',
      },
      {
        name: 'productName',
        header: 'Name',
      },
      {
        name: 'serial',
        header: 'Serial',
      },
      {
        name: 'refPrice',
        header: 'Ref. Price w/Tax',
      },
      {
        name: 'priceAfterDisc',
        header: 'Price w/Tax',
        cell: (rowData) => (
          <TextField
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            value={rowData.priceAfterDisc}
            onChange={(e) => { this.handlePriceChange(e.target.value, rowData) }}
          />
        ),
      },
    ];

    return (
      <div className={classes.root}>
        <div className={classes['title']}>
          <Typography variant="h6">Order Information</Typography>
          <div>
            <TextField
              label={"Create Date"}
              type="date"
              InputLabelProps={{ shrink: true }}
              value={cartInput.date ? cartInput.date : ''}
              onChange={(e) => { this.updateCartField('date', e.target.value) }}
            />
          </div>
        </div>
        <div className={classes['content']}>
          <div className={classes['content-title']}>
            <Typography variant="h6">Goods Information</Typography>
          </div>
          <div className={classes['content-list']}>
            <div className={classes['content-container']}>
              <AutoSizer>
                {({ width, height }) => (
                  <DataGrid
                    data={goods}
                    columns={columns}
                    width={width}
                    maxHeight={height}
                    height={height}
                    includeHeaders={true}
                    fixedRowCount={1}
                    cellProps={{
                      style: { paddingLeft: 2, paddingRight: 4 },
                    }}
                    isCellSelected={(column, rowData) => {
                      return selected !== -1 && goods.indexOf(rowData) === selected;
                    }}
                    onCellClick={(column, rowData) => {
                      this.selectItem(goods.indexOf(rowData));
                    }}
                  />
                )}
              </AutoSizer>
            </div>
          </div>

          <div className={classes['content-footer']}>
            <Button variant="contained" size="small" onClick={this.handleSubmit} disabled={goods.length === 0 || !orderInput || !orderInput.attachments || !orderInput.attachments.passport} >Submit </Button>
          </div>
        </div>
      </div >
    )
  }
}

export const DutyFreeCart = connect(state => {
  const { labels, prices } = state.dutyfree;
  const { products } = state.catalog;

  let marshalledGoods = state.dutyfree.goods.map((el, idx) => {
    return {
      ...el,
      label: labels[idx],
      refPrice: prices[idx] && prices[idx].price,
      priceAfterDisc: prices[idx] && prices[idx].priceAfterDisc,
      productName: products && products[el.productId] && products[el.productId].name
    }
  })
  return {
    cartInput: state.dutyfree.cartInput,
    goods: marshalledGoods,
    selected: state.dutyfree.selected,
    orderInput: state.dutyfree.orderInput,
  }
})(withStyles(styles)(DutyFreeCartComponent));