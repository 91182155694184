import { createVibes } from "@cosmos/vibes";
import { keyBy, chunk, flatten } from "lodash";
import moment from 'moment';
import Papa from 'papaparse';
import { read, utils } from "xlsx";
import {
    APPEND_NEW_PRODUCT,
    BULK_IMPORT_FILE_BEGIN,
    BULK_IMPORT_TEXT_BEGIN,
    BULK_IMPORT_DATA_SUCCESS,
    BULK_IMPORT_DATA_FAIL,
    BULK_IMPORT_CREATE_MAP_SUCCESS,
    BULK_IMPORT_CREATE_MAP_FAIL,
    BULK_IMPORT_PROGRESS_UPDATE,
    BULK_IMPORT_SEND_API_BEGIN,
    BULK_IMPORT_SEND_API_FAIL,
    BULK_IMPORT_SEND_API_SUCCESS,
    bulkImportSendApiFail,
    bulkImportSendApiSuccess,
    CAPTURE_SUPPLIES,
    IMPORT_SUMMARY,
    LOAD_OVERVIEW_SUPPLIES,
    loadOverviewSuppliesFail,
    loadOverviewSuppliesSuccess,
    LOAD_SUPPLIES,
    LOAD_SUPPLIES_SUCCESS,
    loadSuppliesFail,
    loadSuppliesSuccess,
    loadImportSummarySuccess,
    loadImportSummaryFail,
    LOAD_SUPPLIES_PROVISIONS,
    loadSuppliesProvisionsFail,
    loadSuppliesProvisionsSuccess,
    OPEN_SUPPLY_DELETE_CONFIRM,
    OPEN_SUPPLY_NOTIFICATION,
    OPEN_SUPPLY_RENEW_CONFIRM,
    REMOVE_SUPPLY,
    RENEW_SUPPLY,
    REMOVE_SELECTED_SUPPLY_ROWS,
    SAVE_BULK_SUPPLY_SUCCESS,
    SAVE_BULK_SUPPLY_FAIL,
    SAVE_NEW_PRODUCT,
    SAVE_NEW_PRODUCT_SUCCESS,
    SAVE_NEW_PRODUCT_FAIL,
    SAVE_PRICE_SUPPLY,
    SAVE_PRICE_SUPPLY_FAIL,
    SAVE_PRICE_SUPPLY_SUCCESS,
    saveSupplySuccess,
    saveSupplyFail,
    searchSuppliesApplied,
    SELECT_SUPPLY_REFRESH,
    SELECT_SUPPLY_REMOVE,
    SELECT_SUPPLY_ROW,
    SUPPLY_UPDATED,
    SUPPLY_OP_FAILED,
    UPDATE_SUPPLIES_SEARCH,
    UPDATE_SUPPLY_VALID_TO,
    UPDATE_SUPPLIES_SELECTED,
    SELECT_SUPPLY_ALL_ROWS,
    DESELECT_SUPPLY_ALL_ROWS,
    TRADER_CREATE_NEW,
    TRADER_CREATE_NEW_SUCCESS,
    TRADER_CREATE_NEW_FAIL
} from "../actions";
import { ThirdPartyTypes } from "../constants";
import parseFile from "../containers/marketing/BulkAddSupplies/parseFile";
import processInputData from "../containers/marketing/BulkAddSupplies/processInputData";
import { calcTaxInclusivePriceWithRatio, calcTaxExclusivePriceWithRatio } from '../utils/compute';

export const loadSuppliesVibes = createVibes({
    type: LOAD_SUPPLIES,
    debounce: 200,
    latest: true,
    processOptions: {
        successType: loadSuppliesSuccess,
        failType: loadSuppliesFail,
    },
    validate({ getState, action }, allow, reject) {
        const isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, action, axios }) {
        try {
            const variables = {
                filter: {},
            };
            if (action.payload) {
                variables.filter.channelId = action.payload.section;

                if (action.payload.traderId) {
                    variables.filter.traderId = action.payload.traderId;
                }
                if (action.payload.brandId) {
                    variables.filter.brandId = parseInt(action.payload.brandId);
                }
                if (action.payload.validFrom) {
                    variables.filter.validFrom = moment(action.payload.validFrom);
                }
                if (action.payload.validTo) {
                    variables.filter.validTo = moment(action.payload.validTo);
                }
            }
            const { data } = await axios.request({
                url: "/api",
                method: "post",
                data: {
                    query: `query loadSupplies($filter: SuppliesFilter) {
            supplies(filter: $filter) {
              id
              brandId
              productId
              traderId
              channelId
              currencyId
              price
              tax
              amount
              validTo
              validFrom
              msrp
              discount
              reference
              lastSeenAt
            }
          }
          `,
                    variables,
                },
            });
            return {
                supplies: keyBy(data.supplies, 'id'),
                index: data.supplies.map(r => r.id)
            };
        } catch (err) {
            throw err;
        }
    },
});

export const loadSuppliesProvisionsVibes = createVibes({
    type: LOAD_SUPPLIES_PROVISIONS,
    debounce: 200,
    latest: true,
    processOptions: {
        successType: loadSuppliesProvisionsSuccess,
        failType: loadSuppliesProvisionsFail,
    },
    validate({ getState, action }, allow, reject) {
        const isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, action, axios }) {
        try {
            const variables = action.payload

            const { data } = await axios.request({
                url: "/api",
                method: "post",
                data: {
                    query: `query ($traderId: Int!, $importDate: String!) {
                getSuppliesProvision(traderId: $traderId, importDate: $importDate) {
                  id
                  traderId
                  productId
                  brandId
                  productReference
                  quantity
                  validTo
                  validFrom
                  channelId
                  forexSetId
                  currencyId
                  price
                  tax
                  amount
                  discount
                  msrp
                  code
                  reference
                }
            }`,
                    variables: variables
                },
            });

            let suppliesProvisions = data.getSuppliesProvision;
            // let failedSupplies = [];
            // if (suppliesProvisions.length > 0) {
            //   failedSupplies = suppliesProvisions.filter(f => (!f.productId));
            // }
            return {
                suppliesProvisions: suppliesProvisions,
                // failedSupplies: failedSupplies
            };
        } catch (err) {
            throw err;
        }
    },
});


export const createTrader = createVibes({
    type: TRADER_CREATE_NEW,
    debounce: 200,
    latest: true,
    validate({ getState, action }, allow, reject) {
        const isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action)
        } else {
            reject(action);
        }
    },
    async process({ getState, action, axios }, dispatch, done) {
        try {
            const { nationalities } = getState().system;
            let isoCode;
            if (action.payload.isoCode) {
                isoCode = action.payload.isoCode;
            } else {
                const cc = nationalities.filter(v => v.name === action.payload.countryName);
                isoCode = cc[0] && cc[0].code;
                if (!isoCode) throw new Error(`country code for ${action.payload.countryName} cannot be found. Cannot create trader`)
            }

            const traderInput = {
                name: action.payload.name,
                type: 3,
                isoCode
            };

            const { data, errors } = await axios.request({
                url: "/api",
                method: "post",
                data: {
                    query: `mutation ($traderInput: TraderInput) {
                    createTrader(input: $traderInput) {
                      id name type isoCode address email phone reputation status handlerId kkkId countryId currencyId
                    }
                  }`,
                    variables: { traderInput },
                },
            });

            if (errors && errors.length > 0) throw errors[0].message;

            dispatch({ type: TRADER_CREATE_NEW_SUCCESS, payload: { trader: data.createTrader, message: `${data.createTrader.name} is successfully added to the system` } })

        } catch (error) {
            if (error.response) {
                dispatch({ type: TRADER_CREATE_NEW_FAIL, payload: error.response.data.errors[0].message })
            } else {
                //this one handles when there is error.request and error.message
                dispatch({ type: TRADER_CREATE_NEW_FAIL, payload: typeof error === "string" ? error : error.message })
            }
        } finally {
            done();
        }
    }

});




export const saveSupplyVibes = createVibes({
    type: SAVE_PRICE_SUPPLY,
    debounce: 200,
    latest: true,
    processOptions: {
        successType: saveSupplySuccess,
        failType: saveSupplyFail
    },
    validate({ getState, action }, allow, reject) {
        const isAuth = getState().auth && getState().auth.isAuthenticated;
        const form =
            getState().marketing.target.productId > 0 &&
            getState().marketing.target.currencyId > 0 &&
            getState().marketing.target.price > 0;
        if (isAuth && ((form || action.payload) || getState().marketing.selectedForEdit)) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, axios }) {
        const {
            id,
            supplier,
            productId,
            quantity,
            currencyId,
            price,
            amount,
            channelId,
        } = getState().marketing.target;

        const query = `mutation saveSupplyInfo($input:SuppliesInput) {
            supply:updateSupply(input: $input) {
                id productId traderId channelId currencyId price tax amount validTo
            }
        }`;

        const variables = {
            input: {
                trader: { id: supplier },
                productId,
                quantity,
                channelId,
                currencyId,
                price,
                amount
            }
        }
        const { data } = await axios.request({
            url: "/api",
            method: "post",
            data: {
                query,
                variables,
            },
        });

        data.supply.updated = true;
        const sp = getState().marketing.supplies;
        if (id) {

            const supplies = { ...sp };
            delete supplies[id];
            supplies[data.supply.id] = data.supply;
            const index = [...getState().marketing.index];
            index.splice(index.indexOf(id), 1);
            index.unshift(data.supply.id)

            return { supplies, index };
        } else {

            const supplies = { ...sp };
            supplies[data.supply.id] = data.supply;
            const index = [data.supply.id, ...getState().marketing.index];

            return { supplies, index };
        }
    },
});

export const refreshSuppliesVibes = createVibes({
    type: SELECT_SUPPLY_REFRESH,
    processOptions: {
        successType: SUPPLY_UPDATED,
        failType: SUPPLY_OP_FAILED
    },
    async process({ getState, axios }) {
        const selected = getState().marketing.selected.map(s => getState().marketing.supplies[s]);
        if (selected.length === 0) return;
        const query = `mutation saveSupplyInfo($input:SuppliesInput) {
            supply:updateSupply(input: $input) {
                id productId traderId channelId currencyId price tax amount validTo
            }
        }`;
        const supplies = { ...getState().marketing.supplies };
        const index = [...getState().marketing.index];
        for (const s of selected) {
            const variables = {
                input: {
                    trader: { id: s.traderId },
                    productId: s.productId,
                    quantity: 1,
                    channelId: s.channelId,
                    currencyId: s.currencyId,
                    price: s.price,
                    amount: s.amount
                }
            }
            const { data } = await axios.request({
                url: "/api",
                method: "post",
                data: {
                    query,
                    variables,
                },
            });
            data.supply.updated = true;
            delete supplies[s.id];
            supplies[data.supply.id] = data.supply;
            index.splice(index.indexOf(s.id), 1);
            index.unshift(data.supply.id)
        }
        return { supplies, index };
    }
});

export const removeSuppliesVibes = createVibes({
    type: SELECT_SUPPLY_REMOVE,
    processOptions: {
        successType: SUPPLY_UPDATED,
        failType: SUPPLY_OP_FAILED
    },
    async process({ getState, axios }) {
        const selected = getState().marketing.selected.map(s => getState().marketing.supplies[s]);
        if (selected.length === 0) return;
        const query = `mutation deleteSupplies($input: SuppliesInput) {
            removeSupply(input: $input)
        }`

        const supplies = { ...getState().marketing.supplies };
        const index = [...getState().marketing.index];
        for (const s of selected) {
            const variables = {
                input: {
                    trader: { id: s.traderId },
                    productId: s.productId,
                    channelId: s.channelId
                }
            }
            const { data } = await axios.request({
                url: "/api",
                method: "post",
                data: {
                    query,
                    variables,
                },
            });
            delete supplies[s.id];
            index.splice(index.indexOf(s.id), 1);
        }
        return { supplies, index };
    },
});

export const renewSuppliesVibes = createVibes({
    type: RENEW_SUPPLY,
    debounce: 200,
    latest: true,
    validate({ getState, action }, allow, reject) {
        const isAuth = getState().auth && getState().auth.isAuthenticated;
        const selectedRecords = getState().marketing.supplies.filter(s => s.isSelected == true).length > 0
        if (isAuth && selectedRecords) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, axios, action }, dispatch, done) {
        let suppliesToUpdate = getState().marketing.supplies.filter(s => s.isSelected == true).map(x => x.id);

        const query = `mutation renewSelectedSupplies($input:[Int]) {
            renewSupplies(input: $input)
        }`

        try {
            const variables = {
                input: suppliesToUpdate,
            };

            const { data } = await axios.request({
                url: "/api",
                method: "post",
                data: {
                    query,
                    variables,
                },
            });

            if (data.renewSupplies) {
                dispatch({ type: OPEN_SUPPLY_RENEW_CONFIRM, payload: false });
                dispatch({
                    type: OPEN_SUPPLY_NOTIFICATION,
                    payload: { isOpen: true, message: 'Renewal Successfull', variant: 'success' }
                });
            }

            const updatedDataSet = getState().marketing.supplies.map(x => {
                if (suppliesToUpdate.includes(x.id)) {
                    return { ...x, validTo: moment().add(2, 'weeks').format('YYYY-MM-DDTHH:mm:ss') }
                } else {
                    return x;
                }
            })
            dispatch({ type: UPDATE_SUPPLY_VALID_TO, payload: updatedDataSet });
            done();
        } catch (err) {
            done();
        }
    },
});

export const searchSupplies = createVibes({
    type: [
        UPDATE_SUPPLIES_SEARCH,
        LOAD_SUPPLIES_SUCCESS,
        SUPPLY_UPDATED,
        SAVE_PRICE_SUPPLY_SUCCESS,
    ],
    processOptions: {
        successType: searchSuppliesApplied,
    },
    async process({ getState }) {
        const searchText = getState().marketing.searchText;
        const products = getState().catalog.products;
        const supplies = getState().marketing.supplies;
        const index = getState().marketing.index;
        if (searchText && searchText.length > 2) {
            const filteredProducts = Object.values(products)
                .filter((p) => {
                    if (!p) return false;
                    if (!(p.reference)) console.log(p.id);
                    return p.reference.toUpperCase()
                        .replace(/[s.-/]/g, '')
                        .includes(searchText.toUpperCase().replace(/[\s.-/]/g, ''));
                })
                .map((p) => parseInt(p.id));
            const result = Object.values(supplies).filter(s => {
                return filteredProducts.includes(s.productId);
            })
            return result.map(s => s.id);
        } else {
            const kdx = Object.keys(supplies);
            if (index.length < kdx.length) {
                return kdx;
            } else {
                return index;
            }
        }
    },
});

export const saveNewProductVibes = createVibes({
    type: SAVE_NEW_PRODUCT,
    debounce: 200,
    latest: true,
    validate({ getState, action }, allow, reject) {
        const isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, action, client, axios }, dispatch, done) {
        try {
            // let payload = action.payload;
            let productInput = action.payload;
            // const { token } = getState().auth;
            // const { rayLens } = getState().system;
            // const modelInput = {
            //     brandID: payload.brandID,
            //     name: "name",
            //     collection: payload.collection
            // }
            //  //first create model
            // const result = await axios.request({
            //     url: '/api',
            //     method: 'post',
            //     headers: {
            //       'Authorization': `Bearer ${token}`,
            //       rayLens
            //     },
            //     data: {
            //         query: `mutation ($modelInput: ModelInput) {
            //                     createModel(input: $modelInput) {
            //                         id brandID name prefix collection family
            //                     }
            //                 }`,
            //         variables: { modelInput }
            //     }
            // }).then(modelRet => {
            //     let modelData = modelRet.data.createTrader;
            //     let productInput = {
            //         modelID: modelData.id,
            //         brandID: modelData.brandID,
            //         name: modelData.name,
            //         collection: modelData.collection,
            //         reference: modelData.reference
            //     }
            //    // second create product
            //     return axios.request({
            //         url: '/api',
            //         method: 'post',
            //         headers: {
            //           'Authorization': `Bearer ${token}`,
            //           rayLens
            //         },
            //         data: {
            //             query: `mutation ($productInput: ProductInput) {
            //                         createProduct(input: $productInput) {
            //                             id brandID modelID name collection subCollection reference alternative variance dialColor colors gems
            //                             waterResistance movementType dialType bandType caseShape bundled limited gender style function feature
            //                             related manufacturedFrom manufacturedTo box HS SKU description channelDescription case bezel dial
            //                             caliber band shipping external gtin source status material assets rawData sourceUrl image pair weight
            //                         }
            //                     }`,
            //             variables: { productInput }
            //         }
            //     }).then(productRet => {
            //         let productData = productRet.data.createProduct;
            //         return productData
            //     })
            // });

            // mock process
            // let createProduct = result;
            let createProduct = true;
            let newProduct = {
                id: "99999",
                brandID: parseInt(productInput.brandID),
                name: "Model ABC",
                reference: productInput.reference,
                dialColor: productInput.color,
                SKU: "SKU ABC",
                assets: [],
            };

            if (createProduct) {
                dispatch({ type: SAVE_NEW_PRODUCT_SUCCESS });
                dispatch({ type: APPEND_NEW_PRODUCT, payload: newProduct });
            } else {
                dispatch({ type: SAVE_NEW_PRODUCT_FAIL });
            }
            done();
        } catch (err) {
            dispatch({ type: SAVE_NEW_PRODUCT_FAIL });
            done();
        }
    },
});

export const loadOverviewSuppliesVibes = createVibes({
    type: LOAD_OVERVIEW_SUPPLIES,
    debounce: 200,
    latest: true,
    processOptions: {
        successType: loadOverviewSuppliesSuccess,
        failType: loadOverviewSuppliesFail,
    },
    validate({ getState, action }, allow, reject) {
        const isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, action, axios }) {
        try {
            const { data } = await axios.request({
                url: "/api",
                method: "post",
                data: {
                    query: `query ($filter: purchaseFilter) {
            purchaseOrders(filter: $filter) {
              id
              vendor
              totalPrice
              enteredQuantity
              createdAt
              storeId
              reference
              expectedArrivalDate
              state
              deletedAt
              currency
            }
          }`,
                    variables: {
                        filter: { status: 2 } //2 is for 3p coming
                    }
                }
            });
            let retData = data.purchaseOrders;
            if (!retData) {
                retData = [];
            }
            return retData;

        } catch (err) {
            throw err;
        }
    },
});

export const loadImportSummaryVibes = createVibes({
    type: [IMPORT_SUMMARY],
    debounce: 200,
    latest: true,
    processOptions: {
        successType: loadImportSummarySuccess,
        failType: loadImportSummaryFail,
    },
    validate({ getState, action }, allow, reject) {
        const isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, action, axios }) {
        try {
            // const { data } = await axios.request({
            //     url: "/api",
            //     method: "post",
            //     data: {
            //         query: `query{
            //     getImportedSupplies {
            //     traderId
            //       importDate
            //       count
            //       success
            //       fail
            //     }
            //   }`
            //     }
            // });
            // let finalImports = data.getImportedSupplies;
            //
            // finalImports = finalImports.map(el => {
            //     el.date = el.importDate;
            //     return el
            // });
            // return finalImports
        } catch (err) {
            throw err;
        }
    },
});


export const loadSuppliesDataFromText = createVibes({
    type: BULK_IMPORT_TEXT_BEGIN,
    async process({ action }, dispatch, done) {

        const result = Papa.parse(action.payload, {
            //delimiter: "",
            delimitersToGuess: [',', '\t', ' ', Papa.RECORD_SEP, Papa.UNIT_SEP],
            header: true,
            skipEmptyLines: true
        })
        if (result.errors.length > 0) {
            dispatch({ type: BULK_IMPORT_DATA_FAIL, payload: result.errors });
        }

        dispatch({ type: BULK_IMPORT_DATA_SUCCESS, payload: result.data });
        done();

    }
});
export const loadSuppliesDataFromFile = createVibes({
    type: BULK_IMPORT_FILE_BEGIN,
    async process({ action }, dispatch, done) {
        // const result = read(await readFile(action.payload), { type: 'binary' });
        // const wsName = result.SheetNames[0];
        // const ws = result.Sheets[wsName];
        // const parseResult = utils.sheet_to_json(ws);
        // if (parseResult.length === 0) {
        //     return new Error('No record found');
        // }
        // return parseResult;

        const loadedFile = await parseFile(action.payload);

        const result = read(loadedFile, { type: "binary" });
        const wsName = result.SheetNames[0];
        const ws = result.Sheets[wsName];
        const parsedResult = utils
            .sheet_to_json(ws) //, { header: 1 }
        //.filter((row) => row.length > 2);

        dispatch({ type: BULK_IMPORT_DATA_SUCCESS, payload: parsedResult });

        done();
        // let isValid = true;
        // if (parsedResult.length === 0) {
        //     isValid = false
        //     return { errors: [{ message: "Unable to import any data. Did you upload an empty file?" }] }
        // }
        // parsedResult.every((row) => {
        //     if (row.length <= 2) {
        //         isValid = false;
        //         cb({
        //             errors: [
        //                 {
        //                     message: "Not all rows have the same length.",
        //                 },
        //             ],
        //         });
        //         return false;
        //     }
        //     return true;
        // });

        // if (isValid) {
        //     cb({ data: parsedResult, errors: [] });
        // }

    }
});

export const importSuppliesTablePrepare = createVibes({
    //type: [BULK_IMPORT_FILE_SUCCESS, BULK_IMPORT_CREATE_MAP_BEGIN],
    type: BULK_IMPORT_DATA_SUCCESS,
    // processOptions: {
    //     successType: UPDATE_SUPPLIES_SELECTED
    // },
    process({ getState, action }, dispatch, done) {
        //const { payload } = action;

        //smart find what column the header belongs to

        const { mappedColumns, supplies, errors } = processInputData(action.payload, getState);

        //cannot find Reference or Price 
        if (errors.length > 0) {
            dispatch({ type: BULK_IMPORT_CREATE_MAP_FAIL, payload: errors });
        } else {
            dispatch({ type: BULK_IMPORT_CREATE_MAP_SUCCESS, payload: { mappedColumns, supplies } });
        }

        done();
    }
});

export const sendSuppliesVibes = createVibes({
    type: BULK_IMPORT_SEND_API_BEGIN,
    validate({ getState, action }, allow, reject) {
        const isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, action, axios }, dispatch, done) {
        const { vendor, supplies, brand: fallBackBrand, columns, currency, permanent, withTax, taxRate } = getState().marketing.import;
        const productsCatalog = getState().catalog.products;


        let data;
        try {

            if (!columns.matched.reference) { throw new Error("Cannot find reference/products column based on your data. Please change the Header title to REFERENCE"); }
            if (!columns.matched.price) { throw new Error("Cannot find price column based on your data. Please change the Header title to PRICE"); }

            //if (Object.keys(fallBackBrand).length < 1) { throw new Error("Required info missing: Please select Brand"); }
            if (Object.keys(vendor).length < 1) { throw new Error("Required info missing:  Please select Vendor"); }
            if (Object.keys(currency).length < 1) { throw new Error("Required info missing:  Please select Currency"); }

            var date = new Date();
            var formattedRef = moment(date).format("YYYYMMDD") + leftPad(randomIntInc(1, 100), 3);

            data = supplies
                .filter(supply => supply[columns.matched.price] > 0)
                .map((supply) => {
                    //if (!supply[columns.product]) return null;
                    const supplyInput = {
                        reference: formattedRef,
                        productReference: supply[columns.matched.reference].toString(),
                        productId: supply["productId"],
                        brandId: supply["productId"] //if there is a productId
                            ? productsCatalog[supply["productId"]].brandID
                            : Object.keys(fallBackBrand).length < 1 ? null : parseInt(fallBackBrand.id),
                        currencyId: parseInt(currency.id),
                        //validTo: vendorInformation.permanent ? moment.utc("2038-01-19 03:14:07").toISOString() : moment.utc().add(2, "week").toISOString()			
                        //...(!vendorInformation.permanent && { validTo: moment.utc().add(2, "week").toISOString() })
                        ...(!permanent && { validFor: 14 })
                    };

                    if (columns.matched.quantity) supplyInput.quantity = parseInt(supply[columns.matched.quantity]);
                    else { supplyInput.quantity = 1; }

                    if (columns.matched.msrp) supplyInput.msrp = parseFloat(supply[columns.matched.msrp])
                    if (columns.matched.discount) supplyInput.discount = parseFloat(Math.abs(supply[columns.matched.discount]))

                    //we dont compute the tax,price and amount in the server
                    if (withTax) {
                        supplyInput.amount = parseFloat(supply[columns.matched.price].toString()); //amount is price with tax
                        const { taxValue, preTaxPrice } = calcTaxInclusivePriceWithRatio(supplyInput.amount, parseInt(taxRate.toString()), 0, 1, 2);
                        supplyInput.price = preTaxPrice;
                        supplyInput.tax = taxValue;
                    } else {
                        supplyInput.price = parseFloat(supply[columns.matched.price].toString());
                        const { taxValue, amount } = calcTaxExclusivePriceWithRatio(supplyInput.price, parseInt(taxRate.toString()), 0, 1, 2)
                        supplyInput.tax = taxValue;
                        supplyInput.amount = amount;

                    }
                    return supplyInput;
                });
        } catch (err) {
            dispatch({ type: BULK_IMPORT_SEND_API_FAIL, payload: [err.message] });
            done();
        }

        try {


            const all = [];

            //handle the recognized items first
            all[0] = data.filter(supply => supply["productId"]);
            all[1] = data.filter(supply => !supply["productId"]);

            let updatedSupplies = [];

            const query = `mutation captureImport($trader: TraderInput!, $channelId: Int, $input: SuppliesProvisionInput) {
                captureSuppliesProvision(trader: $trader, channelId: $channelId, input: $input) {
                    id
                    traderId
                    productId
                    productReference
                    quantity
                    validTo
                    validFrom
                    channelId
                    forexSetId
                    currencyId
                    price
                    tax
                    amount
                    msrp
                    discount
                }}`

            for (let productNoProduct of all) {

                const chunks = chunk(productNoProduct, 100); //max size is 500

                for (let chunkData of chunks) {

                    const batch = chunkData.map(async supply => {

                        const variables = {
                            channelId: getState().marketing.target.channelId,
                            trader: vendor,
                            input: supply
                        }

                        const response = await axios.request({
                            url: '/api',
                            method: "post",
                            data: {
                                query,
                                variables,
                            },
                        });

                        return response.data.captureSuppliesProvision;
                    });

                    const result = await Promise.all(batch);

                    dispatch({ type: BULK_IMPORT_PROGRESS_UPDATE, payload: result.length });
                    await waitFor(100);

                    updatedSupplies.push(result);

                }
            }

            updatedSupplies = flatten(updatedSupplies);
            var successfulSupplies = updatedSupplies.filter(v => !!v);

            if (successfulSupplies.length === 0) throw new Error("Import failed")
            dispatch({ type: BULK_IMPORT_SEND_API_SUCCESS, payload: updatedSupplies });

        } catch (err) {
            dispatch({ type: BULK_IMPORT_SEND_API_FAIL, payload: err.message });
        }
        finally {
            done();
        }
    }
});


export const selectSupply = createVibes({
    type: SELECT_SUPPLY_ROW,
    processOptions: {
        successType: UPDATE_SUPPLIES_SELECTED
    },
    process({ getState, action }) {
        const { payload } = action;
        const selected = getState().marketing.selected;

        const idx = selected.indexOf(payload);
        if (idx === -1) {
            return { selected: [...selected, payload] };
        } else {
            // These two line cannot combine;
            const ac = selected.slice(0);
            ac.splice(idx, 1);
            return { selected: ac };
        }
    }
})

export const selectSupplyAll = createVibes({
    type: SELECT_SUPPLY_ALL_ROWS,
    processOptions: {
        successType: UPDATE_SUPPLIES_SELECTED
    },
    process({ getState, action }) {
        const { payload } = action;
        const selected = getState().marketing.selected.slice(0);
        for (const p of payload) {
            if (!selected.includes(p)) {
                selected.push(p);
            }
        }
        return { selected }
    }
})

export const deselectSupplyAll = createVibes({
    type: DESELECT_SUPPLY_ALL_ROWS,
    processOptions: {
        successType: UPDATE_SUPPLIES_SELECTED
    },
    process({ getState, action }) {
        const { payload } = action;
        const selected = getState().marketing.selected.slice(0);
        for (const p of payload) {
            const idx = selected.indexOf(p);
            selected.splice(idx, 1);
        }
        return { selected }
    }
});

function waitFor(timeout) {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}

function randomIntInc(low, high) {
    return Math.floor(Math.random() * (high - low + 1) + low);
}

function leftPad(str, length) {
    str = str == null ? "" : String(str);
    length = ~~length;
    let pad = "";
    let padLength = length - str.length;

    while (padLength--) {
        pad += "0";

        return pad + str;
    }

}
