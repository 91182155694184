import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import VendorInformation from './VendorInformation'
import MatchCard from './MatchCard'
import useOnce from '../../hooks/useOnce'
import useDialog from '../../hooks/useDialog'
import matchPurchaseOrders from './matchPurchaseOrders'
import { useSelector, useDispatch } from "react-redux";
import { MATCH_STOCK_IN_BEGIN, MATCH_STOCK_IN_RESET } from '../../actions'

export default function PurchaseOrderList() {
    const [vendorInfo, setVendorInfo] = useState(null)
    const [stocks, setStocks] = useState(null)
    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null)
    const [matches, setMatches] = useState([])
    const traders = useSelector(state => state.trader.traders);
    const dispatch = useDispatch()

    const { state } = useLocation()
    useEffect(() => {
        dispatch({ type: MATCH_STOCK_IN_RESET })
        const purchaseOrderDetails = state.purchaseOrder.purchaseDetails
        const stockDetails = state.stocks.reduce((stockInDetails, currentStock) => [...stockInDetails, ...currentStock.stockInDetails], [])

        setVendorInfo({
            vendorId: state.purchaseOrder.vendor,
            date: state.purchaseOrder.date,
            invoice: state.purchaseOrder.reference,
            purchaseId: state.purchaseOrder.id
        })

        const allStocks = {}
        stockDetails.forEach(stock => allStocks[stock.id] = stock)
        setStocks(allStocks)

        const allPurchaseOrderDetails = {}
        purchaseOrderDetails.forEach(pd => allPurchaseOrderDetails[pd.id] = pd)
        setPurchaseOrderDetails(allPurchaseOrderDetails)

        const matches = matchPurchaseOrders(purchaseOrderDetails, stockDetails)
        setMatches(matches)
    }, [state, traders, dispatch])

    const handleSelect = (stockId, purchaseId) => {
        return setMatches(prevMatches => prevMatches.map(match => {
            if (match.stock === stockId) {
                return { ...match, purchase: match.purchase === purchaseId ? null : purchaseId }
            }
            return match
        }))
    }

    // const { execute: executeMatchStockInMutation, isLoading, data, error } = useQuery(matchStockInMutation, { matchWithStockIn: null }, {}, false)
    const [once, changeOnce] = useOnce()
    const { openDialog: openErrorDialog, Dialog: ErrorDialog } = useDialog()
    const { openDialog: openSuccessDialog, Dialog: SuccessDialog } = useDialog('success')
    const matchStockIn = () => {
        const matchStockInData = {
            purchaseId: vendorInfo.purchaseId,
            once,
            stockInInput: matches.filter(match => !!match.purchase).map(match => ({
                purchaseDetailId: match.purchase,
                stockId: stocks[match.stock].stockId,
                stockInDetailId: stocks[match.stock].id
            }))
        }

        dispatch({ type: MATCH_STOCK_IN_BEGIN, payload: matchStockInData })
    }

    const error = useSelector(state => state.matchStockIn.error)
    useEffect(() => {
        if (error) {
            openErrorDialog(error.message)
            changeOnce()
        }
    }, [error, changeOnce, openErrorDialog])

    const isSuccess = useSelector(state => state.matchStockIn.matchedStocks)
    useEffect(() => {
        if (isSuccess) {
            openSuccessDialog(`Successfully matched stock in`)
        }
    }, [isSuccess, openSuccessDialog])

    const allPurchaseOrdersFulfilled = purchaseOrderDetails && Object.values(purchaseOrderDetails).every(purchaseOrderDetail => {
        const matchedStocksQuantity = matches.filter(match => match.purchase === purchaseOrderDetail.id).length
        const expectedMatchingQuantity = purchaseOrderDetail.quantityExpected
        return matchedStocksQuantity >= expectedMatchingQuantity
    })

    const history = useHistory()
    const isLoading = useSelector(state => state.matchStockIn.isLoading)
    if (!stocks || !purchaseOrderDetails) return <div>No purchase order/stock</div>
    return (
        <div>
            <h2 style={{ textAlign: 'center', fontSize: '1.5rem' }}>Purchase order</h2>
            <VendorInformation vendorId={traders[vendorInfo.vendorId] ? traders[vendorInfo.vendorId].name : ""} date={vendorInfo.date} invoice={vendorInfo.invoice} />
            {Object.values(purchaseOrderDetails).map(purchaseOrderDetail => {
                return <MatchCard
                    key={purchaseOrderDetail.id}
                    purchaseId={purchaseOrderDetail.id}
                    product={purchaseOrderDetail.productId}
                    quantity={purchaseOrderDetail.quantity}
                    quantityExpected={purchaseOrderDetail.quantityExpected}
                    unitPrice={purchaseOrderDetail.unitPrice}
                    discountRate={purchaseOrderDetail.discountRate}
                    eligibleStocks={Object.values(stocks).filter(stock => stock.productId === purchaseOrderDetail.productId)}
                    selectedStockIds={matches.filter(match => match.purchase === purchaseOrderDetail.id).map(match => match.stock)}
                    handleSelect={handleSelect}
                />
            })}
            <div style={{ padding: '1em' }}>
                <Button onClick={() => history.goBack()}>Back</Button>
                <Button variant="contained" color="primary" disabled={!allPurchaseOrdersFulfilled || isLoading} onClick={matchStockIn}>Continue</Button>
            </div>
            <ErrorDialog />
            <SuccessDialog />
        </div>
    )
}