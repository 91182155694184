import React, { useEffect, useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Input from "./Input";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

/**
 *
 * @param {Object} props
 * @param {(event, selectedProduct, reason) => void} props.onChange - Fired when option is selected. (event, selectedProduct, reason)
 * @param {any} props.value The current value of the input
 * @param {number} [props.maxShownOptions=50] The max number of shown options
 * @param {any} ...props
 */
export default function SelectModel({
  onChange,
  value,
  maxShownOptions = 20,
  notFoundMsg = "",
  newReference = "",
  availableOptions,
  ...props
}) {

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      // options={availableOptions.filter(
      //   (options) => options.brand && options.product
      // )}
      options={availableOptions}
      filterOptions={(options, { inputValue }) => {
        const value = inputValue.toLowerCase();
        return options
          .filter(
            (option) =>
              tryLowerCase(option.brand.name).includes(value) ||
              tryLowerCase(option.product.name).includes(value) ||
              tryLowerCase(option.product.SKU).includes(value)
          )
          .slice(0, maxShownOptions);
      }}
      getOptionLabel={(option) =>
        option ? `${option.product.SKU} - ${option.product.name}` : ""
      }
      renderOption={(option, { inputValue }) => {
        return <SelectProductCard product={option} inputValue={inputValue} />;
      }}
      onChange={onChange}
      value={value}
      renderInput={(params) => (
        <Input
          placeholder={newReference || "Model"}
          {...params}
          {...props}
          helperText={value && value.product ? value.product.SKU : notFoundMsg}
        />
      )}
    />
  );
}

function tryLowerCase(input) {
  if (!input) return "";
  return input.toLowerCase();
}

const useStyles = makeStyles({
  root: {
    margin: 0,
  },
  highlight: {
    background: "yellow",
    fontWeight: "bold",
  },
});

function SelectProductCard({ product, inputValue }) {
  const classes = useStyles();
  const productSKU = getHighlightedText(
    product.product.SKU,
    inputValue,
    classes.highlight
  );
  const brandName = getHighlightedText(
    product.brand.name,
    inputValue,
    classes.highlight
  );
  const productName = getHighlightedText(
    product.product.name,
    inputValue,
    classes.highlight
  );
  return (
    <div>
      <p className={classes.root}>
        <strong>{brandName}</strong> - {productSKU}
      </p>
      <small>
        <em>{productName}</em>
      </small>
    </div>
  );
}

function getHighlightedText(text, highlight, className) {
  if (!text || !highlight) return text;
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          className={
            part.toLowerCase() === highlight.toLowerCase() ? className : ""
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
}
