import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {removeSnackbar} from "../actions";
let displayed = [];
export const Notifier = () =>{
    const dispatch = useDispatch();
    const notifications = useSelector(state=>state.system.notifications || []);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    }
    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key=>id !== key)];
    }
    useEffect(()=>{
        for(const {key, message, options = {}, dismissed = false} of notifications) {
            if(dismissed) {
                closeSnackbar(key);
                continue;
            }
            if(displayed.includes(key)) return;
            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if(options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                }
            });
            storeDisplayed(key);
        }
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])
return <></>;
}