import {
  createVibes
} from "@cosmos/vibes";
import {
  CANCEL_INV_LOAD,
  INV_RELOAD_BEGIN,
  INV_LOAD_BEGIN,
  loadInvSuccess,
  loadInvFail
} from "../../actions";
import {
  reduce
} from 'lodash';

export const loadInventoryVibes = createVibes({
  type: [INV_LOAD_BEGIN],
  debounce: 300,
  latest: true,
  processOptions: {
    successType: loadInvSuccess,
    failType: loadInvFail
  },
  cancelType: CANCEL_INV_LOAD,
  validate({
    getState,
    action
  }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(loadInvFail(new Error('User does not login yet')));
    }
  },
  async process({
    getState,
    action,
    client,
    axios
  }) {
    try {
      const {
        token
      } = getState().auth;
      const {
        rayLens
      } = getState().system;
      const params = {
        filterBasic: {
          ...action.payload.filter,
          preset: 'BASIC'
        }
      };
      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query allstocks($filterBasic: StockFilter) {
                  basic:stocks(filter: $filterBasic) {
                    id brandId productId state articleId userId remarks supplier reservationId attachments {id} storeId
                  }
                }`,
          variables: params
        }
      });

      let stocks = result.data.basic;

      const payload = {
        stocks
      };

      return payload;
    } catch (err) {
      throw err;
    }
  }
});

export const reloadInventoryVibes = createVibes({
  type: [INV_RELOAD_BEGIN],
  debounce: 500,
  latest: true,
  processOptions: {
    successType: loadInvSuccess,
    failType: loadInvFail
  },
  cancelType: CANCEL_INV_LOAD,
  validate({
    getState,
    action
  }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(loadInvFail(new Error('User does not login yet')));
    }
  },
  async process({
    getState,
    action,
    client,
    axios
  }) {
    try {
      const {
        token
      } = getState().auth;
      const {
        rayLens
      } = getState().system;
      const params = {
        filterExtra: {
          ...action.payload.filter,
          preset: 'EXTRA'
        },
        filterDetail: {
          ...action.payload.filter,
          preset: 'DETAIL'
        }
      };

      const result = await axios.request({
        url: '/api',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${token}`,
          rayLens
        },
        data: {
          query: `query reloadstocks($filterExtra: StockFilter, $filterDetail: StockFilter) {
            extra:stocks(filter: $filterExtra) {
              id cost buyPrice sellPrice activities {id} paidAt sellTax sellCurrency sellAmount sellDiscount sellForexSet buyTax buyCurrency buyAmount buyDiscount buyForexSet costCurrency costForexSet
            }
            detail:stocks(filter: $filterDetail) {
              id cCode condition package provision serial birth birthYear birthMonth birthDate purchasedAt soldAt stockIn stockOut
            }
          }`,
          variables: params
        }
      });
      let stocksDetail = result.data.detail;
      let stocksExtra = result.data.extra;

      stocksDetail = reduce(stocksDetail, (key, obj) => {
        key[obj.id] = obj;
        return key;
      }, {})

      stocksExtra = reduce(stocksExtra, (key, obj) => {
        key[obj.id] = obj;
        return key;
      }, {})

      const payload = {
        stocksDetail,
        stocksExtra
      };
      return payload;
    } catch (err) {
      throw err;
    }
  }
});