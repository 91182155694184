import React from 'react';
import { Component } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { SmartBox } from '@cosmos/chaos';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    "flex-row": {
        display: "flex",
        flexDirection: "row"
    },
    "flex-column": {
        display: "flex",
        flexDirection: "column"
    },
    field: {
        margin: "0.5rem",
        flex: 1
    }
})

class InfoComponent_ extends Component {

    render() {
        const { tenderInput, updateField, currencies, users, traders, paymentChannels, countries, stores, classes } = this.props;

        return (
            <div className={classes['info']}>
                <div className={classes['flex-column']}>
                    <div className={classes['flex-row']}>
                        <div className={classes['field']}>
                            <InputLabel shrink required>Channel</InputLabel>
                            <SmartBox
                                suggestionProvider={val => {
                                    let count = 0;
                                    let result = Object.values(traders).filter(el => {
                                        const keep = el.name.includes(val) && count < 10;
                                        if (keep) {
                                            count += 1;
                                        }
                                        return keep;
                                    }).map(el => ({ label: el.name, value: el.id, ...el }));
                                    return result;
                                }}
                                searchText={tenderInput.channel && traders[tenderInput.channel] ? traders[tenderInput.channel].name : (tenderInput.channel ? tenderInput.channel : '')}
                                updateSearchText={val => {
                                    if (typeof val === 'object') {
                                        updateField({ channel: val.id, currency: val.currencyId, deliveryCountry: val.countryId, originalCountry: val.countryId, seller: val.handlerId });
                                    }
                                    else {
                                        updateField({ channel: val, currency: '', deliveryCountry: '', originalCountry: '', seller: null });
                                    }
                                }}
                                updateSelectedItem={val => {
                                    updateField({ channel: val.id, currency: val.currencyId, deliveryCountry: val.countryId, originalCountry: val.countryId, seller: val.handlerId });
                                }}
                            />
                        </div>
                        <TextField
                            id="channelReference" label="Channel Reference"
                            value={tenderInput.channelReference ? tenderInput.channelReference : ''}
                            className={classes['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { updateField({ channelReference: e.target.value }) }}
                        />
                    </div>
                    <div className={classes['flex-row']}>
                        <TextField
                            id="seller" label="Seller"
                            required
                            value={tenderInput.seller && stores[tenderInput.seller] ? stores[tenderInput.seller].name : ''}
                            className={classes['field']}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ readOnly: true, disabled: true }}
                        />
                        <FormControl className={classes['field']}>
                            <InputLabel shrink required> Currency </InputLabel>
                            <Select
                                value={tenderInput.currency ? tenderInput.currency : ''}
                                onChange={(e) => {
                                    updateField({ currency: e.target.value })
                                }}
                                name="currency"
                            >
                                {Object.values(currencies).map((el, index) => (
                                    <MenuItem value={el.id} key={`option_${index}`}>
                                        {el.symbol}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes['flex-row']}>
                        <TextField
                            id="customer" label="Customer"
                            required
                            value={tenderInput.customer ? tenderInput.customer.name : ''}
                            className={classes['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { updateField({ customer: { name: e.target.value } }) }}
                        />
                        <FormControl className={classes['field']}>
                            <InputLabel shrink>Delivery Country </InputLabel>
                            <Select
                                value={tenderInput.deliveryCountry ? tenderInput.deliveryCountry : ''}
                                onChange={(e) => { updateField({ deliveryCountry: e.target.value }) }}
                                name="deliveryCountry"
                            >
                                {Object.values(countries).map((el, index) => (
                                    <MenuItem value={el.id} key={`user_${index}`}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes['flex-row']}>
                        <FormControl className={classes['field']}>
                            <InputLabel shrink required>Paid By </InputLabel>
                            <Select
                                value={tenderInput.paidBy ? tenderInput.paidBy : ''}
                                onChange={(e) => { updateField({ paidBy: e.target.value }) }}
                                name="paidBy"
                            >
                                {Object.values(paymentChannels).map((el, index) => (
                                    <MenuItem value={el.id} key={`user_${index}`}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            id="filedAt" label="Filed At"
                            value={tenderInput.filedAt ? tenderInput.filedAt : ''}
                            type="date"
                            className={classes['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { updateField({ filedAt: e.target.value }) }}
                        />
                    </div>
                    <div className={classes['flex-row']}>
                        <TextField
                            id="confirmedAt" label="Confirmed At"
                            value={tenderInput.confirmedAt ? tenderInput.confirmedAt : ''}
                            type="date"
                            className={classes['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { updateField({ confirmedAt: e.target.value }) }}
                        />
                        <TextField
                            id="expireAt" label="Expire At"
                            value={tenderInput.expireAt ? tenderInput.expireAt : ''}
                            type="date"
                            className={classes['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { updateField({ expireAt: e.target.value }) }}
                            required
                        />
                    </div>
                    <div className={classes['flex-row']}>
                        <FormControl className={classes['field']}>
                            <InputLabel shrink required> Person In Charge </InputLabel>
                            <Select
                                value={tenderInput.pic ? tenderInput.pic : ''}
                                onChange={(e) => { updateField({ pic: e.target.value }) }}
                                name="pic"
                            >
                                {Object.values(users).map((el, index) => (
                                    <MenuItem value={el.id} key={`user_${index}`}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            id="remarks" label="Remarks"
                            value={tenderInput.remarks ? tenderInput.remarks.comments : ''}
                            className={classes['field']}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { updateField({ remarks: { comments: e.target.value } }) }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export const InfoComponent = withStyles(styles)(InfoComponent_)
