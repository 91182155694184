import React from 'react';

export const AccountingComponent = class Accounting extends React.Component {
    render() {
        return (
            <div>
                Accounting Module
            </div>
        )
    }
};