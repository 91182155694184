export const LOAD_SUPPLIES = '@@marketing/LOAD_SUPPLIES';
export const IMPORT_SUMMARY = '@@marketing/IMPORT_SUMMARY';
export const SELECT_PRICE_BRAND = '@@marketing/SELECT_PRICE_BRAND';
export const SELECT_PRICE_PRODUCT = '@@marketing/SELECT_PRICE_PRODUCT';
export const SELECT_PRICE_CURRENCY = '@@marketing/SELECT_PRICE_CURRENCY';
export const SELECT_PRICE_PRICE = '@@marketing/SELECT_PRICE_PRICE';
export const SELECT_PRICE_AMOUNT = '@@maketing/SELECT_PRICE_AMOUNT';
export const SELECT_TAX_RATE = '@@marketing/SELECT_TAX_RATE';
export const SAVE_PRICE_SUPPLY = '@@marketing/SAVE_PRICE_SUPPLY';
export const SAVE_PRICE_SUPPLY_SUCCESS = '@@marketing/SAVE_PRICE_SUPPLY_SUCCESS';
export const SAVE_BULK_SUPPLY_SUCCESS = '@@marketing/SAVE_BULK_SUPPLY_SUCCESS';
export const SAVE_PRICE_SUPPLY_FAIL = '@@marketing/SAVE_PRICE_SUPPLY_FAIL';
export const SAVE_BULK_SUPPLY_FAIL = '@@marketing/SAVE_BULK_SUPPLY_FAIL';
export const SELECT_PRICE_SECTOR = '@@marketing/SELECT_PRICE_SECTOR';
export const LOAD_SUPPLIES_SUCCESS = "@@marketing/LOAD_SUPPLIES_SUCCESS";
export const LOAD_SUPPLIES_FAIL = "@@marketing/LOAD_SUPPLIES_FAIL";
export const IMPORT_SUMMARY_SUCCESS = "@@marketing/IMPORT_SUMMARY_SUCCESS";
export const IMPORT_SUMMARY_FAIL = "@@marketing/IMPORT_SUMMARY_FAIL";
export const LOAD_SUPPLIES_PROVISIONS = '@@marketing/LOAD_SUPPLIES_PROVISIONS';
export const LOAD_SUPPLIES_PROVISIONS_SUCCESS = "@@marketing/LOAD_SUPPLIES_PROVISIONS_SUCCESS";
export const LOAD_SUPPLIES_PROVISIONS_FAIL = "@@marketing/LOAD_SUPPLIES_PROVISIONS_FAIL";
export const SAVE_FAILED_SUPPLIES = '@@marketing/SAVE_FAILED_SUPPLIES';
export const RESET_SUPPLIES_PROVISONS_FILTER = "@@marketing/RESET_SUPPLIES_PROVISONS_FILTER";
export const UPDATE_SUPPLIES_SEARCH = "@@marketing/UPDATE_SUPPLIES_SEARCH";
export const SEARCH_SUPPLIES_APPLIED = "@@marketing/SEARCH_SUPPLIES_APPLIED";
export const SELECT_PRICE_QUANTITY = "@@marketing/SELECT_PRICE_QUANTITY";
export const SELECT_PRICE_SUPPLIER = '@@marketing/SELECT_PRICE_SUPPLIER';
export const SAVE_NEW_PRODUCT = '@@marketing/SAVE_NEW_PRODUCT';
export const SAVE_NEW_PRODUCT_SUCCESS = '@@marketing/SAVE_NEW_PRODUCT_SUCCESS';
export const SAVE_NEW_PRODUCT_FAIL = '@@marketing/SAVE_NEW_PRODUCT_FAIL';
export const SELECT_IMPORT_ITEM = '@@marketing/SELECT_IMPORT_ITEM';
export const SELECT_MARKETING_IMPORT_BRAND = '@@marketing/SELECT_MARKETING_IMPORT_BRAND';
export const UPDATE_MARKETING_SECTION = "@@marketing/UPDATE_MARKETING_SECTION";
export const ASK_INIT_MARKETING_UPCOMING = '@@marketing/ASK_INIT_MARKETING_UPCOMING';
export const CANCEL_INIT_MARKETING_UPCOMING = '@@marketing/CANCEL_INIT_MARKETING_UPCOMING';
export const CONFIRM_INIT_MARKETING_UPCOMING = '@@marketing/CONFIRM_INIT_MARKETING_UPCOMING';
export const RESET_INIT_MARKETING_UPCOMING = '@@marketing/RESET_INIT_MARKETING_UPCOMING';
export const INIT_UPCOMING_LOAD_COMPLETE = '@@marketing/INIT_UPCOMING_LOAD_COMPLETE';
export const INIT_UPCOMING_LOAD_RESET = '@@marketing/INIT_UPCOMING_LOAD_RESET';
export const loadSuppliesSuccess = (payload) => ({ type: LOAD_SUPPLIES_SUCCESS, payload });
export const loadSuppliesFail = (payload) => ({ type: LOAD_SUPPLIES_FAIL, payload });
export const searchSuppliesApplied = (payload) => ({ type: SEARCH_SUPPLIES_APPLIED, payload });
export const saveSupplySuccess = (payload) => ({ type: SAVE_PRICE_SUPPLY_SUCCESS, payload });
export const saveSupplyFail = (payload) => ({ type: SAVE_PRICE_SUPPLY_FAIL, payload });
export const CAPTURE_SUPPLIES = '@@marketing/CAPTURE_SUPPLIES'
export const CAPTURE_SUPPLIES_SUCCESS = '@@marketing/CAPTURE_SUPPLIES_SUCCESS'
export const CAPTURE_SUPPLIES_FAIL = '@@marketing/CAPTURE_SUPPLIES_FAIL';

export const loadImportSummarySuccess = (payload) => ({ type: IMPORT_SUMMARY_SUCCESS, payload });
export const loadImportSummaryFail = (payload) => ({ type: IMPORT_SUMMARY_FAIL, payload });
export const bulkImportSendApiSuccess = (payload) => ({ type: BULK_IMPORT_SEND_API_SUCCESS, payload });
export const bulkImportSendApiFail = (payload) => ({ type: BULK_IMPORT_SEND_API_FAIL, payload, error: true });
export const CLEAR_SAVED_SUPPLIES = '@@marketing/CLEAR_SAVED_SUPPLIES';
export const SAVE_OVERVIEW_FILTER = '@@marketing/SAVE_OVERVIEW_FILTER';
export const LOAD_OVERVIEW_SUPPLIES = '@@marketing/LOAD_OVERVIEW_SUPPLIES';
export const LOAD_OVERVIEW_SUPPLIES_SUCCESS = "@@marketing/LOAD_OVERVIEW_SUPPLIES_SUCCESS";
export const LOAD_OVERVIEW_SUPPLIES_FAIL = "@@marketing/LOAD_OVERVIEW_SUPPLIES_FAIL";
export const BULK_IMPORT_SET_TRADER = '@@marketing/BULK_IMPORT_SET_TRADER';
export const BULK_IMPORT_SET_CURRENCY = '@@marketing/BULK_IMPORT_SET_CURRENCY';


export const loadSuppliesProvisionsSuccess = (payload) => ({ type: LOAD_SUPPLIES_PROVISIONS_SUCCESS, payload });
export const loadSuppliesProvisionsFail = (payload) => ({ type: LOAD_SUPPLIES_PROVISIONS_FAIL, payload });
export const loadOverviewSuppliesSuccess = (payload) => ({ type: LOAD_OVERVIEW_SUPPLIES_SUCCESS, payload });
export const loadOverviewSuppliesFail = (payload) => ({ type: LOAD_OVERVIEW_SUPPLIES_FAIL, payload });

export const BULK_IMPORT_ALERT_CLOSE = '@@marketing/BULK_IMPORT_ALERT_CLOSE';
export const BULK_IMPORT_ALERT_OPEN = '@@marketing/BULK_IMPORT_ALERT_OPEN';
export const BULK_IMPORT_CLEAR_ERRORS = '@@marketing/BULK_IMPORT_CLEAR_ERRORS';
export const BULK_IMPORT_PROGRESS_UPDATE = '@@marketing/BULK_IMPORT_PROGRESS_UPDATE';
export const BULK_IMPORT_SEND_API_BEGIN = '@@marketing/BULK_IMPORT_SEND_API_BEGIN';
export const BULK_IMPORT_SEND_API_SUCCESS = '@@marketing/BULK_IMPORT_SEND_API_SUCCESS';
export const BULK_IMPORT_SEND_API_FAIL = '@@marketing/BULK_IMPORT_SEND_API_FAIL';
export const BULK_IMPORT_DIALOG_OPEN = '@@marketing/BULK_IMPORT_DIALOG_OPEN';
export const BULK_IMPORT_INPUT_CHANGE = '@@marketing/BULK_IMPORT_INPUT_CHANGE';
export const BULK_IMPORT_DIALOG_CLOSE = '@@marketing/BULK_IMPORT_DIALOG_CLOSE';
export const BULK_IMPORT_FILE_BEGIN = '@@marketing/BULK_IMPORT_FILE_BEGIN';
export const BULK_IMPORT_TEXT_BEGIN = '@@marketing/BULK_IMPORT_TEXT_BEGIN';
export const BULK_IMPORT_DATA_SUCCESS = '@@marketing/BULK_IMPORT_DATA_SUCCESS';
export const BULK_IMPORT_DATA_FAIL = '@@marketing/BULK_IMPORT_DATA_FAIL';
export const BULK_IMPORT_CREATE_MAP_BEGIN = '@@marketing/BULK_IMPORT_CREATE_MAP_BEGIN';
export const BULK_IMPORT_CREATE_MAP_SUCCESS = '@@marketing/BULK_IMPORT_CREATE_MAP_SUCCESS';
export const BULK_IMPORT_CREATE_MAP_FAIL = '@@marketing/BULK_IMPORT_CREATE_MAP_FAIL';
export const THIRD_PARTY_CREATE_PO_BEGIN = '@@marketing/THIRD_PARTY_CREATE_PO_BEGIN'
export const THIRD_PARTY_CREATE_PO_SUCCESS = '@@marketing/THIRD_PARTY_CREATE_PO_SUCCESS'
export const THIRD_PARTY_CREATE_PO_FAIL = '@@marketing/THIRD_PARTY_CREATE_PO_FAIL'
export const THIRD_PARTY_UPCOMING_RESET = '@@marketing/THIRD_PARTY_UPCOMING_RESET'
export const SELECT_SUPPLY_ROW = '@@marketing/SELECT_SUPPLY_ROW'
export const SELECT_SUPPLY_ALL_ROWS = '@@marketing/SELECT_SUPPLY_ALL_ROWS'
export const DESELECT_SUPPLY_ALL_ROWS = '@@marketing/DESELECT_SUPPLY_ALL_ROWS'
export const OPEN_SUPPLY_DELETE_CONFIRM = '@@marketing/OPEN_SUPPLY_DELETE_CONFIRM'
export const OPEN_SUPPLY_RENEW_CONFIRM = '@@marketing/OPEN_SUPPLY_RENEW_CONFIRM'
export const REMOVE_SUPPLY = '@@marketing/REMOVE_SUPPLY'
export const RENEW_SUPPLY = '@@marketing/RENEW_SUPPLY'
export const REMOVE_SELECTED_SUPPLY_ROWS = '@@marketing/REMOVE_SUPPLY_ROW'
export const UPDATE_SUPPLY_VALID_TO = '@@marketing/UPDATE_SUPPLY_VALID_TO'
export const EDIT_SUPPLY_RECORD = '@@marketing/EDIT_SUPPLY_RECORD'
export const OPEN_SUPPLY_NOTIFICATION = '@@marketing/OPEN_SUPPLY_NOTIFICATION'
export const SELECT_SUPPLY_CLEAR = "@@marketing/SELECT_SUPPLY_CLEAR";
export const SELECT_SUPPLIES = '@@marketing/SELECT_SUPPLIES';
export const UPDATE_SUPPLIES_SELECTED = '@@marketing/UPDATE_SUPPLIES_SELECTED';
export const RESET_TARGET = '@@marketing/RESET_TARGET';
export const SELECT_SUPPLY_REFRESH = "@@marketing/SELECT_SUPPLY_REFRESH";
export const SELECT_SUPPLY_REMOVE = "@@marketing/SELECT_SUPPLY_REMOVE"
export const SUPPLY_UPDATED = '@@marketing/SUPPLY_UPDATED';
export const SUPPLY_OP_FAILED = '@@marketing/SUPPLY_OP_FAILED'
export const PARSE_SUPPLIES_OFFER = "@@marketing/PARSE_SUPPLIES_OFFER";
export const LOAD_SUPPLIES_OFFER = "@@marketing/LOAD_SUPPLIES_OFFER";
export const OPEN_IMPORT_SUPPLIES_DIALOG = "@@marketing/OPEN_IMPORT_DIALOG";
export const CLOSE_IMPORT_SUPPLIES_DIALOG = "@@marketing/CLOSE_IMPORT_DIALOG";
export const PROCESSED_IMPORT_SUPPLIES = "@@marketing/PROCESSED_IMPORT_SUPPLIES";
export const UPDATE_IMPORT_COUNTRY = '@@marketing/UPDATE_IMPORT_COUNTRY';
export const UPDATE_IMPORT_SUPPLIER = '@@marketing/UPDATE_IMPORT_SUPPLIER';
export const UPDATE_IMPORT_CURRENCY = '@@marketing/UPDATE_IMPORT_CURRENCY';
export const UPDATE_IMPORT_TAX_RATE = '@@marketing/UPDATE_IMPORT_TAX_RATE';
export const UPDATE_IMPORT_TAX_MODE = '@@marketing/UPDATE_IMPORT_TAX_MODE';
export const UPDATE_IMPORT_PERIOD = '@@marketing/UPDATE_IMPORT_PERIOD';
