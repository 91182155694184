import React, { Component } from "react";
import { connect } from 'react-redux';
import { Paper, TextField, List, ListItem, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    item: {
        flex: 1,
        display: "flex"
    },
    field: {
        minWidth: "10em",
        marginRight: "1.5em",
        marginLeft: "1.5em",
        flex: 1
    },
    content: {
        flex: 1,
        padding: "0 0 1em 0"
    },
    label: {
        minWidth: "5em",
        marginLeft: "1.5em"
    }
});

class IdentityComponent_ extends Component {

    render() {
        const { classes, cartPrices, goods, labels, product, selected } = this.props;

        const productID = (goods && goods.productId) ? goods.productId : '';
        const label = labels[selected] ? labels[selected] : ''
        const name = (product[productID] && product[productID].name) ? product[productID].name : '';
        const SKU = (product[productID] && product[productID].SKU) ? product[productID].SKU : '';
        const reference = (product[productID] && product[productID].reference) ? product[productID].reference : '';
        const stockPrice = (cartPrices[selected] && cartPrices[selected].price) ? cartPrices[selected].price : '';
        const serial = (goods && goods.serial) ? goods.serial : '';
        const birthday = (goods && (goods.birthYear && goods.birthMonth && goods.birthDate)) ? goods.birthDate + '/' + goods.birthMonth + '/' + goods.birthYear : '';

        return (
            <div className={classes.item}>
                <Paper className={classNames(classes.content)}>
                        <List >
                            <ListItem>
                                <Typography className={classes['label']}>{'Item Code'}</Typography>
                                <TextField aria-label="Item Code"
                                    className={classes['field']} value={label}
                                    disabled
                                />
                            </ListItem>
                            <ListItem>
                                <Typography className={classes['label']}>{'Name'}</Typography>
                                <TextField aria-label="Name"
                                    className={classes['field']} value={name}
                                    disabled
                                />
                            </ListItem>
                            <ListItem>
                                <Typography className={classes['label']}>{'SKU'}</Typography>
                                <TextField aria-label="SKU"
                                    className={classes['field']} value={SKU}
                                    disabled
                                />
                            </ListItem>
                            <ListItem>
                                <Typography className={classes['label']}>{'Reference'}</Typography>
                                <TextField aria-label="Reference"
                                    className={classes['field']}
                                    value={reference}
                                    disabled
                                />
                            </ListItem>
                            <ListItem>
                                <Typography className={classes['label']}>{'Stock Price'}</Typography>
                                <TextField aria-label="Stock Price"
                                    className={classes['field']}
                                    value={stockPrice}
                                    disabled
                                />
                            </ListItem>
                            <ListItem>
                                <Typography className={classes['label']}>{'Serial'}</Typography>
                                <TextField aria-label="Serial"
                                    disabled
                                    className={classes['field']}
                                    value={serial}
                                />
                            </ListItem>
                            <ListItem>
                                <Typography className={classes['label']}>{'Birthday'}</Typography>
                                <TextField aria-label="Birthday"
                                    disabled
                                    className={classes['field']}
                                    value={birthday}
                                />
                            </ListItem>
                        </List>
                </Paper>
            </div>
        );

    }
}

export const IdentityComponent = connect(
    state => ({
        cartPrices: state.pos.prices,
        goods: state.pos.goods[state.pos.selected],
        labels: state.pos.labels,
        product: state.catalog.products,
        selected: state.pos.selected,
    }))(withStyles(styles)(IdentityComponent_));