import React from 'react'
import IncompletePOandStockIn from './IncompletePOandStockIn'
import PurchaseOrderList from './PurchaseOrderList'
import StockInList from './StockInList'
import {useRouteMatch, Route} from 'react-router-dom'

export default function MatchPOandStocks() {
    const {url} = useRouteMatch()
    return (
        <div>
            <Route path={url} exact component={IncompletePOandStockIn} />
            <Route path={`${url}/purchaseorderlist`} component={PurchaseOrderList} />
            <Route path={`${url}/stockinlist`} component={StockInList} />
        </div>
    )
}
