import React from 'react'
import { TextField } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'

/**
 * @typedef {{id: string, name: string, code?: string, logos?: string}} Brand
 */

/**
 * @param {object} props
 * @param {Brand} props.value - Current value (brand object {id, name, code, logo})
 * @param {(selectedBrand: Brand) => void} props.onChange - Emitted on a new selected brand
 */
export default function BrandInput({ value, onChange, style, loading, disabled }) {
    const brands = useSelector(state => state.catalog.brands)

    return (
        Object.keys(brands).length > 0 &&

        <Autocomplete
            style={style || null}
            loading={loading || false}
            disabled={disabled || false}
            value={value || ''}
            options={Object.values(brands)}
            getOptionLabel={option => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value, reason) => {
                if (!value) return;
                onChange(value)
            }
            }
            renderInput={params => <TextField data-testid="input:brand" {...params} label="Brand" />}
        />
    )
}
