import { createVibes } from "@cosmos/vibes";
import { LOAD_SUGGESTIONS, LOAD_SUGGESTIONS_SUCCESS, LOAD_SUGGESTIONS_ERROR } from "../../actions";
// import { reduce } from 'lodash';

export const loadSuggestionVibes = createVibes({
  type: [LOAD_SUGGESTIONS],
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client }, dispatch, done) {
    try {
      //TODO: call this query to get the supplies data
      // let query = `query {
      //   supplies { 
      //     id 
      //   }
      // }`;
      // let variables = { };  //TODO: should get productId, tier
      // let data = await client.request(query, variables);
      let data = {
        // suggestions: [
        //   {
        //     id: 1,
        //     productId: 2380,
        //     name: "suggestion 2380",
        //   },
        //   {
        //     id: 2,
        //     productId: 1918,
        //     name: "suggestion 1918",
        //   },
        //   {
        //     id: 3,
        //     productId: 2216,
        //     name: "suggestion 2216",
        //   },
        //   {
        //     id: 4,
        //     productId: 2378,
        //     name: "suggestion 2378",
        //   },
        //   {
        //     id: 5,
        //     productId: 2380,
        //     name: "suggestion 2380 2",
        //   },
        //   {
        //     id: 6,
        //     productId: 2380,
        //     name: "suggestion 2380 3",
        //   },
        //   {
        //     id: 7,
        //     productId: 2380,
        //     name: "suggestion 2380 4",
        //   },
        //   {
        //     id: 8,
        //     productId: 2378,
        //     name: "suggestion 2378 2",
        //   },
        //   {
        //     id: 9,
        //     productId: 20,
        //     name: "suggestion 20 1",
        //   },
        //   {
        //     id: 10,
        //     productId: 20,
        //     name: "suggestion 20 2",
        //   },
        // ]
        suggestions: [
          {
            id: 1,
            name: 'Own Stock'
          },
          {
            id: 2,
            name: 'System Stock'
          },
          {
            id: 3,
            name: 'Buy from Outside'
          }
        ]
      }

      let payload = {
        // fulfilmentSuggestions: reduce(data.suggestions, (r, d) => { 
        //   if(!r[d.productId]){
        //     r[d.productId] = [];
        //   }
        //   r[d.productId].push(d);
        //   return r; 
        // }, {}),
        fulfilmentSuggestions: data.suggestions
      };

      dispatch({ type: LOAD_SUGGESTIONS_SUCCESS, payload });
      done();
    } catch (err) {
      dispatch({ type: LOAD_SUGGESTIONS_ERROR, payload: err });
      done();
    }
  }
});