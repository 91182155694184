import { createVibes } from "@cosmos/vibes";
import {
  SCAN_ACTION_CARD_BEGIN, SCAN_ACTION_CARD_SUCCESS, SCAN_ACTION_CARD_FAILED
} from "../../actions";

export const scanActionCardVibes = createVibes({
  type: SCAN_ACTION_CARD_BEGIN,
  debounce: 500,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    }
    else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const { stores } = getState().system;
      const { search } = action.payload;

      const { token } = getState().auth;
      const { rayLens } = getState().system;

      const query = await axios.request({
        url: `/code/${search}`,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          rayLens
        }
      });

      if (query.status < 400) {
        if (query.status === 200) {
          if (query.request && query.request.response) {
            const response = JSON.parse(query.request.response);
            let { targetId, originId } = response;
            if (!isNaN(targetId)) {
              dispatch({ type: SCAN_ACTION_CARD_SUCCESS, payload: { originId, isActionCodeApply: true, company: { id: targetId, name: stores[targetId].name } } });
            }
            else {
              dispatch({ type: SCAN_ACTION_CARD_FAILED });
            }
          }
          else {
            dispatch({ type: SCAN_ACTION_CARD_FAILED });
          }
        }
        else {
          dispatch({ type: SCAN_ACTION_CARD_FAILED });
        }
      }
      else {
        dispatch({ type: SCAN_ACTION_CARD_FAILED });
      }

      done();
    } catch (err) {
      dispatch({ type: SCAN_ACTION_CARD_FAILED });
      done();
    }
  }
});