import { MATCH_STOCK_IN_BEGIN, MATCH_STOCK_IN_FAIL, MATCH_STOCK_IN_SUCCESS, MATCH_STOCK_IN_RESET } from "../actions"

const initialState = {
    isLoading: false,
    matchedStocks: null,
    error: null
}

export const matchStockInReducer = (state = initialState, action) => {
    switch (action.type) {
        case MATCH_STOCK_IN_BEGIN:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case MATCH_STOCK_IN_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MATCH_STOCK_IN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                matchedStocks: action.payload
            }
        case MATCH_STOCK_IN_RESET:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}