import { createVibes } from "@cosmos/vibes";
import { MATCH_STOCK_IN_BEGIN, MATCH_STOCK_IN_SUCCESS, MATCH_STOCK_IN_FAIL } from '../actions'

export const matchStockInVibes = createVibes({
    type: [MATCH_STOCK_IN_BEGIN],
    debounce: 200,
    latest: true,
    validate({ getState, action }, allow, reject) {
        let isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, action, client, axios }, dispatch, done) {
        const query = `mutation matchWithStockIn($input: MatchWithStockInInput) {
            matchWithStockIn(input: $input) {
                id
                reference 
            }
        }`
        const input = action.payload
        const token = getState().auth.token

        try {
            const response = await axios.request({
                url: "/api",
                method: "post",
                data: {
                    query,
                    variables: { input },
                },
                header: {
                    authorization: `Bearer ${token}`
                }
            });

            if (response.data.matchWithStockIn) {
                dispatch({ type: MATCH_STOCK_IN_SUCCESS, payload: response.data.matchWithStockIn })
                done()
                return
            }

            if (response.request && response.request.response && JSON.parse(response.request.response).errors) {
                dispatch({ type: MATCH_STOCK_IN_FAIL, payload: JSON.parse(response.request.response).errors[0] })
                done()
                return
            } 

            dispatch({ type: MATCH_STOCK_IN_FAIL, payload: {message: 'Something went wrong'} })
        } catch(e) {
            dispatch({ type: MATCH_STOCK_IN_FAIL, payload: e })
        }
    }
});
