import { createVibes } from "@cosmos/vibes"
import {
    THIRD_PARTY_CREATE_PO_BEGIN,
    THIRD_PARTY_CREATE_PO_SUCCESS,
    THIRD_PARTY_CREATE_PO_FAIL
} from "../actions/marketing";

export const createThirdPartyVibes = createVibes({
    type: [THIRD_PARTY_CREATE_PO_BEGIN],
    latest: true,
    validate({ getState, action, client }, allow, reject) {
        let isAuth = getState().auth && getState().auth.isAuthenticated;
        if (isAuth) {
            allow(action);
        } else {
            reject(action);
        }
    },
    async process({ getState, action, client, axios }, dispatch, done) {
        const query = `mutation ($purchaseInput: PurchaseInput) {
            updatePO(input: $purchaseInput) {
              id
              storeId
              reference
              }
            }`

        const variables = action.payload
        const token = getState().auth.token

        try {
            const response = await axios.request({
                url: "/api",
                method: "post",
                data: {
                    query,
                    variables
                },
                header: {
                    authorization: `Bearer ${token}`
                }
            });

            if (response.data.updatePO) {
                dispatch({ type: THIRD_PARTY_CREATE_PO_SUCCESS, payload: response.data.updatePO })
                done()
                return
            }

            if (response.request && response.request.response && JSON.parse(response.request.response).errors) {
                dispatch({ type: THIRD_PARTY_CREATE_PO_FAIL, payload: JSON.parse(response.request.response).errors[0] })
                done()
                return
            }

            dispatch({ type: THIRD_PARTY_CREATE_PO_FAIL, payload: { message: 'Something went wrong' } })
        } catch (e) {
            dispatch({ type: THIRD_PARTY_CREATE_PO_FAIL, payload: e })
        }
    }
});
