import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Switch as MuiSwitch } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const useStyles = makeStyles({
    root: {
        paddingTop: "5px"
    },
});


export const Switch = ({ size, color, toggleChecked, checked, label }) => {

    const classes = useStyles();

    const SwitchComponent = () => {
        return (<MuiSwitch size={size} color={color} checked={checked} onChange={toggleChecked} />)
    }

    return (<FormGroup className={classes.root}>
        <FormControlLabel
            control={SwitchComponent()}
            label={label}
        />
    </FormGroup>)
}

Switch.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    toggleChecked: PropTypes.func.isRequired
}

Switch.defaultProps = {
    size: "medium",
    color: "primary",
    checked: true,
    label: " "
}

export default Switch