import { createVibes } from "@cosmos/vibes";
import {
  CART_ADD_ITEM_BEGIN,
  CART_ADD_ITEM_SUCCESS,
  CART_ADD_ITEM_ERROR,
  UPDATE_LABEL_LIST,
  TOGGLE_BIRTH_DIALOG,
  enqueuePosNotice,
  OPEN_RESERVED_DIALOG,
  CHECK_BIRTH,
  GET_TEMP_IMAGE,
  UPDATE_POS_DATAURI,
  OPEN_RESERVE_NOTICE,
} from "../../actions";
import { Abacus } from "@cosmos/harmony";

export const addCartVibes = createVibes({
  type: [CART_ADD_ITEM_BEGIN],
  debounce: 200,
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, client, axios }, dispatch, done) {
    try {
      const label = action.payload.search;
      let labs = getState().pos.labels;
      if (labs.indexOf(label) > -1) {
        dispatch({
          type: CART_ADD_ITEM_ERROR,
          payload: "The stock is already in cart",
        });
        done();
      } else {
        const { token } = getState().auth;
        const { rayLens } = getState().system;

        const query = await axios.request({
          url: `/STK/${label}`,
          method: "get",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            rayLens,
          },
        });

        if (query.status < 400) {
          if (query.status === 200) {
            if (query.request && query.request.response) {
              const response = JSON.parse(query.request.response);
              if (response.item) {
                const result = await axios.request({
                  url: "/api",
                  method: "post",
                  headers: {
                    Authorization: `Bearer ${token}`,
                    rayLens,
                  },
                  data: {
                    query: `query($filter: SingleStockFilter) {
                      stock(filter: $filter)
                      {
                        id brandId productId serial state birthYear birthMonth birthDate
                      }
                    }`,
                    variables: { filter: { id: response.item } },
                  },
                });

                let data = result.data;

                const stock = data.stock;
                if (stock) {
                  let sellPrice = 0;
                  if (!stock.priceAdjustment) {
                    //TODO: check if the client id should be 1000
                    try {
                      const result1 = await axios.request({
                        url: "/api",
                        method: "post",
                        headers: {
                          Authorization: `Bearer ${token}`,
                          rayLens,
                        },
                        data: {
                          query: `query($clientId: Int!, $productId: ID!, $filter: PriceTagFilter) {
                            priceTag(clientId: $clientId, productId: $productId, filter: $filter)
                            {
                                id productId forexSetId currencyId price tax amount discount
                            }
                          }`,
                          variables: {
                            clientId: 1000,
                            productId: stock.productId,
                            filter: { type: "SELL" },
                          },
                        },
                      });

                      let data1 = result1.data;

                      if (data1 && data1.priceTag) {
                        sellPrice = data1.priceTag.price;
                      }
                    } catch (err) {
                      dispatch(
                        enqueuePosNotice("warning", `Fail to get price tag.`)
                      );
                    }
                  } else {
                    sellPrice = stock.priceAdjustment;
                  }
                  let goods = [];
                  if (response.reservation) {
                    const { traders } = getState().trader;
                    let reservedForName = response.reservedFor;
                    if (!isNaN(parseInt(reservedForName))) {
                      if (traders[parseInt(reservedForName)]) {
                        reservedForName =
                          traders[parseInt(reservedForName)].name;
                      }
                    }
                    const payload = {
                      reservedDialog: true,
                      reservedFor: reservedForName,
                      reservation: response.reservation,
                    };
                    dispatch({ type: OPEN_RESERVED_DIALOG, payload });
                    goods = [
                      {
                        ...stock,
                        discountRate: getState().pos.discountRate,
                        reservation: response.reservation,
                      },
                      ...getState().pos.goods,
                    ];
                  } else {
                    if (response.proformaId) {
                      dispatch({
                        type: OPEN_RESERVE_NOTICE,
                        payload: {
                          notify: true,
                          notice: "This stock has been put in another cart.",
                          noticeType: "warning",
                        },
                      });
                    }
                    goods = [
                      {
                        ...stock,
                        discountRate: getState().pos.discountRate,
                        reservation: null,
                      },
                      ...getState().pos.goods,
                    ];
                    if (
                      response.actions &&
                      response.actions.indexOf("birth") > -1
                    ) {
                      dispatch({ type: TOGGLE_BIRTH_DIALOG });
                    }
                  }
                  let calculatedOutput = Abacus.calcPriceWithRatio(
                    sellPrice,
                    1,
                    getState().pos.discountRate,
                    getState().pos.taxRate,
                    2
                  );
                  const prices = [calculatedOutput, ...getState().pos.prices];
                  const selected = 0;
                  dispatch({ type: UPDATE_LABEL_LIST, payload: label });
                  dispatch({
                    type: CART_ADD_ITEM_SUCCESS,
                    payload: { goods, prices, selected },
                  });
                } else {
                  dispatch({
                    type: CART_ADD_ITEM_ERROR,
                    payload: "The stock is invalid",
                  });
                }
              } else {
                dispatch({
                  type: CART_ADD_ITEM_ERROR,
                  payload: "The stock is invalid",
                });
              }
            } else {
              dispatch({
                type: CART_ADD_ITEM_ERROR,
                payload: "The stock is invalid",
              });
            }
          } else {
            dispatch({
              type: CART_ADD_ITEM_ERROR,
              payload: "The label is not yet attached to any stock",
            });
          }
        } else {
          dispatch({ type: CART_ADD_ITEM_ERROR, payload: "Stock not found" });
        }
      }
      done();
    } catch (err) {
      dispatch({ type: CART_ADD_ITEM_ERROR, payload: "Stock not found" });
      done();
    }
  },
});

export const checkBirthVibes = createVibes({
  type: [CHECK_BIRTH],
  latest: true,
  async process({ getState }, dispatch, done) {
    const { goods } = getState().pos;
    if (
      goods &&
      goods[0] &&
      !goods[0].birthYear &&
      !goods[0].birtMonth &&
      !goods[0].birthDate
    ) {
      dispatch({ type: TOGGLE_BIRTH_DIALOG });
    }
    done();
  },
});

export const getTempImgVibes = createVibes({
  type: [GET_TEMP_IMAGE],
  latest: true,
  validate({ getState, action }, allow, reject) {
    let isAuth = getState().auth && getState().auth.isAuthenticated;
    if (isAuth) {
      allow(action);
    } else {
      reject(action);
    }
  },
  async process({ getState, action, axios }, dispatch, done) {
    const { indicator } = action.payload;
    const { token } = getState().auth;
    const { rayLens } = getState().system;

    try {
      const data = await axios.request({
        url: `upload/STOCK/${indicator}`,
        method: "get",
        headers: { Authorization: `Bearer ${token}`, rayLens },
        responseType: "blob",
      });
      const response = data.request && data.request.response;
      const file = new Blob([response], { type: "image/png" });
      let fileURL = URL.createObjectURL(file);
      dispatch({ type: UPDATE_POS_DATAURI, payload: { dataUri: fileURL } });
      done();
    } catch (e) {}
  },
});
